import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {usePanelCodes} from '../../utils/panel';
import {PanelCode, PanelProductCode, PANELS_DATA} from '../../utils/panels';
import DashboardPanelProductSelect from './DashboardPanelProductSelect';

interface Props {
  onUpdate?: (value: PanelCode) => void;
}

const DashboardPanelSelectInline = ({onUpdate}: Props) => {
  const {t} = useTranslation();
  const [productCode, setSetProductCode] = useState<PanelProductCode | null>(
    null
  );
  const panelCodes = usePanelCodes();

  const options = useMemo(() => {
    if (productCode) {
      return panelCodes.filter((i) =>
        PANELS_DATA[i].products.includes(productCode)
      );
    }

    return panelCodes;
  }, [productCode]);

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        p: 1,
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <Box p={2}>
        <DashboardPanelProductSelect
          value={productCode}
          onChange={(event) =>
            setSetProductCode(event.target.value as PanelProductCode | null)
          }
        />
      </Box>

      {options.length ? (
        <Table>
          <TableBody>
            {options.map((code) => (
              <TableRow
                key={code}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th">
                  <Typography color="primary">{t(`panels.${code}`)}</Typography>

                  <Typography color="gray">
                    {PANELS_DATA[code].products
                      .map((i) => t(`products.${i}`))
                      .join(', ')}
                  </Typography>
                </TableCell>

                <TableCell component="th" align="right">
                  <Button onClick={() => onUpdate?.(code)}>Select</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : null}

      {!options.length ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={1}
          minHeight={200}
          color="grey"
        >
          No panels
        </Box>
      ) : null}
    </Box>
  );
};

export default DashboardPanelSelectInline;
