import CircleIcon from '@mui/icons-material/Circle';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {SvgIconProps} from '@mui/material';

interface Props extends SvgIconProps {
  eventType: keyof typeof events;
}

const events = {
  'messages.nack': {
    Icon: CircleIcon,
    color: 'disabled',
  },
  'messages.read': {
    Icon: CircleIcon,
    color: undefined,
  },
  'messages.ack': {
    Icon: CircleIcon,
    color: 'primary',
  },
  'messages.emergency_response.ok': {
    Icon: CircleIcon,
    color: 'success',
  },
  'messages.emergency_response.injured_but_ok': {
    Icon: CircleIcon,
    color: 'warning',
  },
  'messages.emergency_response.injured_and_needs_help': {
    Icon: CircleIcon,
    color: 'error',
  },
  'communicationNodes.externalPower': {
    Icon: PowerSettingsNewIcon,
  },
} as const;

const EventIcon = ({eventType, ...props}: Props) => {
  const iconData = events[eventType];
  if (iconData) {
    const {Icon, ...iconProps} = iconData;
    return <Icon {...iconProps} {...props} />;
  }
  return null;
};

export default EventIcon;
