// eslint-disable-next-line simple-import-sort/imports
import LanguageIcon from '@mui/icons-material/Language';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Box,
  Card,
  CardContent,
  Link,
  TextField,
  IconButton,
} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';
import {Link as RouterLink, useParams, useNavigate} from 'react-router-dom';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  PasswordResetInputBody,
  PasswordResetResponse,
} from '../../interfaces/PasswordReset';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import reduxActions from '../../redux/actions';
import {useAppSelector, useAppDispatch} from '../../hooks/redux';
import reduxSelectors from '../../redux/selectors';

interface Props {
  onSubmitted?: () => void;
}

export const PasswordReset = ({onSubmitted}: Props) => {
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] =
    useState(false);
  const {token} = useParams();
  const navigate = useNavigate();
  const reduxDispatch = useAppDispatch();
  const auth = useAppSelector(reduxSelectors.auth.getAuth);

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async () => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/password/reset/${token}`;
      await API.post<PasswordResetResponse>(endpoint, formik.values);

      const message = 'Password changed successfully';
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();

      // remove password token from local storage and go to login page
      reduxActions.auth.clearAuthTokens(reduxDispatch);
      navigate('/auth/signin');
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      if (messages.includes('Token is expired or invalid')) {
        reduxActions.auth.clearAuthTokens(reduxDispatch);
        navigate('/auth/signin');
      }
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      //password: yup.string().required('Password is required'),
      new_password: yup
        .string()
        .required('New password is required')
        .notOneOf(
          [yup.ref('password')],
          'New password should not match your current password'
        ),
      password_confirmation: yup
        .string()
        .required('New password confiramtion is required')
        .oneOf(
          [yup.ref('new_password')],
          'New password does not match password confirmation'
        ),
    };

    return yup.object().shape(fields);
  }, []);

  const getFormikValues = (): Partial<PasswordResetInputBody> => ({
    //password: null,
    new_password: null,
    password_confirmation: null,
  });

  const formik = useFormik<Partial<PasswordResetInputBody>>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async () => {
      await submitData();
    },
  });

  return (
    <Box
      component="form"
      maxWidth={410}
      width="100%"
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardContent>
          <Box textAlign="center">
            <Box>
              <LanguageIcon sx={{fontSize: 40}} />
            </Box>

            <Box fontSize={24}>Password Reset</Box>

            <Box color="text.secondary">Set your new password</Box>
          </Box>

          {!!auth.passwordToken && (
            <Alert severity="warning" sx={{mt: 1}}>
              Your password expired and must be changed.
            </Alert>
          )}

          {/* <Box my={2}>
            <TextField
              value={formik.values.password ?? ''}
              fullWidth
              name="password"
              label="Password"
              type={isPasswordVisible ? 'text' : 'password'}
              error={!!formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    type="button"
                    onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                  >
                    {isPasswordVisible ? (
                      <VisibilityIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    ) : (
                      <VisibilityOffIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                ),
              }}
              onChange={formik.handleChange}
            />
          </Box> */}

          <Box my={2}>
            <TextField
              value={formik.values.new_password ?? ''}
              fullWidth
              name="new_password"
              label="New password"
              type={isNewPasswordVisible ? 'text' : 'password'}
              error={
                !!formik.touched.new_password && !!formik.errors.new_password
              }
              helperText={
                formik.touched.new_password && formik.errors.new_password
              }
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    type="button"
                    onClick={() =>
                      setIsNewPasswordVisible(!isNewPasswordVisible)
                    }
                  >
                    {isNewPasswordVisible ? (
                      <VisibilityIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    ) : (
                      <VisibilityOffIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                ),
              }}
              onChange={formik.handleChange}
            />
          </Box>

          <Box my={2}>
            <TextField
              value={formik.values.password_confirmation ?? ''}
              fullWidth
              name="password_confirmation"
              label="New password confirmation"
              type={isPasswordConfirmationVisible ? 'text' : 'password'}
              error={
                !!formik.touched.password_confirmation &&
                !!formik.errors.password_confirmation
              }
              helperText={
                formik.touched.password_confirmation &&
                formik.errors.password_confirmation
              }
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    type="button"
                    onClick={() =>
                      setIsPasswordConfirmationVisible(
                        !isPasswordConfirmationVisible
                      )
                    }
                  >
                    {isPasswordConfirmationVisible ? (
                      <VisibilityIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    ) : (
                      <VisibilityOffIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                ),
              }}
              onChange={formik.handleChange}
            />
          </Box>

          <Box mt={2}>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={submittedInProgress}
              type="submit"
            >
              Save Password
            </LoadingButton>

            {!auth.passwordToken && (
              <Box mt={1.5}>
                <Link
                  component={RouterLink}
                  underline="hover"
                  to="/auth/signin"
                >
                  Back to Log In
                </Link>
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
