import SendIcon from '@mui/icons-material/Send';
import {LoadingButton} from '@mui/lab';
import {Box, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  CommtracNodeMessage,
  CommtracNodeMessageInputBody,
} from '../../interfaces/CommtracNodeMessage';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  pk: number;
  onSubmitted?: () => void;
}

const CommtracNodeMessageCreate = ({pk, onSubmitted}: Props) => {
  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: CommtracNodeMessageInputBody) => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/commtrac-node/${pk}/message`;
      await API.post<CommtracNodeMessage>(endpoint, data);
      formik.setValues(getFormikValues);
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = () => ({
    message: null,
  });

  const formik = useFormik<CommtracNodeMessageInputBody>({
    initialValues: getFormikValues(),
    onSubmit: (values) => submitData(values),
  });

  return (
    <Box component="form" position="relative" onSubmit={formik.handleSubmit}>
      <TextField
        value={formik.values.message ?? ''}
        name="message"
        fullWidth
        placeholder="Type Message..."
        error={!!formik.touched.message && !!formik.errors.message}
        helperText={formik.touched.message && formik.errors.message}
        onChange={formik.handleChange}
        inputProps={{maxLength: 40}}
        InputProps={{
          endAdornment: (
            <LoadingButton
              variant="contained"
              disabled={!formik.values.message}
              loading={submittedInProgress}
              type="submit"
            >
              <SendIcon />
            </LoadingButton>
          ),
        }}
      />
    </Box>
  );
};

export default CommtracNodeMessageCreate;
