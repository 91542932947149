import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import _ from 'lodash';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {Actions} from '../../interfaces/Backend';
import {Map, MapListQuery, MapListResponse} from '../../interfaces/Map';
import reduxSelectors from '../../redux/selectors';
import AccessControl from '../common/AccessControl';
import {
  TableColumn,
  TableColumnActionsButton,
} from '../common/TableColumnActionsButton';
import {TableColumnSortByButton} from '../common/TableColumnSortByButton';
import {MapItemUpsertButton} from './buttons/MapItemUpsertButton';
import {MapItemDeleteButton} from './buttons/MaptemDeleteButton';

export interface MapListRef {
  fetch?: Function;
}

type MapListProps = {};

export const MapList = forwardRef<
  MapListRef,
  React.PropsWithChildren<MapListProps>
>((__, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
  }));

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const paperBg = isDarkMode ? '#222222' : '#FFF';

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<MapListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: MapListQuery) => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<MapListResponse>(`${apiBaseUrl}/map`, {
        params,
      });
      setFetchedData(resp.data);
    } catch (error: any) {
      setFetchedErrors(error.data);
    }

    setFetchedInProgress(false);
  };

  const actions = useAppSelector((app) => app.app.backend?.actions);
  useEffect(() => {
    const filteredActions = actions?.filter(
      (el) => el.action === Actions.FETCH_MAPS
    );
    if (filteredActions && filteredActions.length > 0) {
      fetchData(formik.values);
    }
  }, [actions]);

  /*************/
  /* data grid */
  /*************/
  const columns = useMemo<TableColumn[]>(
    () => [
      {field: 'id', headerName: 'id', sortable: true},
      {field: 'name', headerName: 'Name', sortable: true},
      {field: 'path', headerName: 'Path', sortable: true},
      {
        field: 'date',
        headerName: 'Date',
        sortable: true,
        valueGetter: (params: any) =>
          dayjs(params.row.date).format('MM-DD-YYYY HH:mm'),
      },
    ],
    []
  );

  const [shownColumnFields, setShownColumnFields] = useState<string[]>([]);

  const shownColumns = useMemo(
    () => columns.filter((i) => shownColumnFields.includes(i.field)),
    [columns, shownColumnFields]
  );

  const formik = useFormik<MapListQuery>({
    initialValues: {
      page: 0,
      limit: 25,
      order: 'id',
      dir: 'ASC',
      filter: null,
    },
    onSubmit: () => {},
  });

  const gridData = useMemo(() => {
    // filter
    const filteredItems = fetchedData?.items;

    // sort
    const sortedItems = _.orderBy(
      filteredItems,
      (i) => {
        const iteratees = columns?.find(
          (i) => i.field === formik.values.order
        )?.orderByIteratees;
        return iteratees?.(i) ?? i[formik.values.order as keyof Map];
      },
      formik.values.dir === 'DESC' ? 'desc' : 'asc'
    );

    // pagination
    const limit = formik.values.limit;
    const offset = formik.values.limit * formik.values.page;
    const pageItems = _.slice(sortedItems, offset, offset + limit);

    return pageItems;
  }, [fetchedData, formik.values]);

  const defaultShownColumnFields = useMemo(
    () =>
      _.chain(columns)
        .map('field')
        .filter((i) => !['id'].includes(i))
        .value(),
    [columns]
  );

  useEffect(() => {
    setShownColumnFields(defaultShownColumnFields);
  }, [columns, defaultShownColumnFields]);

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  return (
    <Paper
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        p: 3,
        bgcolor: paperBg,
        backgroundImage: 'none',
      }}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      {/* <Paper sx={{ p: 1, mb: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} display='flex' alignItems='center'>
            <Box>
              <Box component='span' fontWeight={600} mr={1}>
                Items selected:
              </Box>

              <Box component='span' fontWeight={600}>
                {selectedItemIds.length}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={9}>
            <Box textAlign='right'>
              <Button
                size='small'
                variant='contained'
                disabled={!selectedItemIds.length}
                sx={{ mr: 2 }}
              >
                Activate
              </Button>

              <Button
                size='small'
                variant='contained'
                disabled={!selectedItemIds.length}
                sx={{ mr: 2 }}
              >
                Deactivate
              </Button>

              <Button
                color='error'
                size='small'
                variant='contained'
                disabled={!selectedItemIds.length}
              >
                Delete
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper> */}

      <Box>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          count={+(fetchedData?.items.length ?? 0)}
          rowsPerPage={formik.values.limit}
          page={formik.values.page}
          onPageChange={(_event, page) => {
            formik.setFieldValue('page', page);
          }}
          onRowsPerPageChange={(event) => {
            formik.setFieldValue('limit', +event.target.value || 10);
            formik.setFieldValue('page', 0);
          }}
        />
      </Box>

      <Table sx={{minWidth: 650, th: {bgcolor: paperBg}}}>
        <TableHead>
          <TableRow>
            {/* <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={!!fetchedData && selectedItemIds.length > 0 && selectedItemIds.length < fetchedData.length}
                checked={!!fetchedData && fetchedData.length > 0 && selectedItemIds.length === fetchedData.length}
                onChange={() => toogleSelectAllItemIds()}
              />
            </TableCell> */}

            {shownColumns.map((column) => (
              <TableCell
                key={column.field}
                sx={{
                  minWidth: column.width,
                  whiteSpace: 'nowrap',
                  padding: '6px 16px',
                  '.actions': {opacity: 0, mr: -1.5},
                  '&:hover .actions': {opacity: 1},
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center">
                    <Box fontWeight={700}>
                      {column.headerName ??
                        _.capitalize(column.field.replaceAll('_', ' '))}
                    </Box>

                    <Box>
                      {formik.values.order === column.field && (
                        <TableColumnSortByButton
                          value={
                            formik.values.order === column.field
                              ? (formik.values.dir as 'ASC' | 'DESC')
                              : null
                          }
                          onChange={(value) => {
                            formik.setFieldValue('order', column.field);
                            formik.setFieldValue('dir', value);
                          }}
                        />
                      )}
                    </Box>
                  </Box>

                  <Box className="actions">
                    <TableColumnActionsButton
                      column={column}
                      columns={columns}
                      shownColumnFields={shownColumnFields}
                      onChangeShownColumnFields={setShownColumnFields}
                      onChangeSortBy={({sortDesc}: {sortDesc: boolean}) => {
                        formik.setFieldValue('order', column.field);
                        formik.setFieldValue('dir', sortDesc ? 'DESC' : 'ASC');
                      }}
                    />
                  </Box>
                </Box>
              </TableCell>
            ))}

            <TableCell
              align="right"
              sx={{width: 120, fontWeight: 600, padding: '6px 16px'}}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody sx={{overflow: 'auto'}}>
          {gridData?.map((row) => (
            <TableRow key={row.id}>
              {/* <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  checked={selectedItemIds.includes(row.ID)}
                  onChange={() => toogleSelectItemId(row.ID)}
                />
              </TableCell> */}

              {shownColumns.map((column) => (
                <TableCell key={column.field} sx={{padding: '6px 16px'}}>
                  {column.valueGetter
                    ? column.valueGetter({row})
                    : row[column.field as keyof Map]}
                </TableCell>
              ))}

              <TableCell align="right" sx={{padding: '6px 16px'}}>
                <AccessControl permissions={['post::/map']}>
                  <MapItemUpsertButton
                    pk={row.id}
                    item={row}
                    prefetch
                    component={IconButton}
                    componentProps={{
                      color: 'primary',
                      size: 'small',
                      disabled: true,
                    }}
                    onSubmitted={() => fetchData(formik.values)}
                  >
                    <EditIcon />
                  </MapItemUpsertButton>
                </AccessControl>

                <AccessControl permissions={['delete::/map/:id']}>
                  <MapItemDeleteButton
                    item={row}
                    prefetch
                    component={IconButton}
                    componentProps={{
                      color: 'error',
                      size: 'small',
                    }}
                    onDeleted={() => fetchData(formik.values)}
                  >
                    <DeleteOutlineOutlinedIcon />
                  </MapItemDeleteButton>
                </AccessControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Box>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
          count={+(fetchedData?.count ?? 0)}
          rowsPerPage={formik.values.limit}
          page={formik.values.page}
          onPageChange={(_event, page) => {
            formik.setFieldValue('page', page);
          }}
          onRowsPerPageChange={(event) => {
            formik.setFieldValue('limit', +event.target.value || 10);
            formik.setFieldValue('page', 0);
          }}
        />
      </Box>
    </Paper>
  );
});
