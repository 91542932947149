import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {Node} from '../../../interfaces/Node';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item?: Node;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onOpenItem?: Function;
  onOpenHistory?: Function;
  onDone?: () => void;
}

const NodeItemsEditButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onOpenItem,
  onOpenHistory,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem
          onClick={() => {
            if (item?.cn_id) {
              onOpenItem?.(item?.cn_id, 'cn', 'update');
            }
            if (item?.wifi_id) {
              onOpenItem?.(item.wifi_id, 'wifi', 'update');
            }
            setMenuAnchorEl(undefined);
          }}
        >
          Edit {item?.cn_id ? 'CN' : item?.wifi_id ? 'Wifi' : ''}
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (item?.cn_id) {
              onOpenItem?.(item?.cn_id, 'cn', 'view');
            }
            if (item?.wifi_id) {
              onOpenItem?.(item.wifi_id, 'wifi', 'view');
            }
            setMenuAnchorEl(undefined);
          }}
        >
          Info & Actions
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (item?.cn_id) {
              onOpenHistory?.(item?.cn_external_id, 'cn');
            }
            if (item?.wifi_id) {
              onOpenHistory?.(item?.wifi_id, 'wifi');
            }
            setMenuAnchorEl(undefined);
          }}
        >
          View History
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (item?.cn_id) {
              onOpenHistory?.(item?.cn_external_id, 'commtracNodeByCn');
            }
            if (item?.wifi_id) {
              onOpenHistory?.(item?.wifi_id, 'wifiLongTerm');
            }
            setMenuAnchorEl(undefined);
          }}
        >
          {item?.cn_id
            ? 'View Employee/Asset'
            : item?.wifi_id
              ? 'View Long Term History'
              : 'View Employee/Asset'}
        </MenuItem>
      </Menu>
    </>
  );
};

export default NodeItemsEditButton;
