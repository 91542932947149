import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Link,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  TopBottomInteractionsQuery,
  TopBottomInteractionsResponse,
} from '../../../interfaces/TopBottomInteractions';
import {getPadVadHumanReadable} from '../../../utils/address';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {useDashboardDrilldown} from '../../dashboards/DashboardContext';
import {TopEmployeeInteractionsData} from './data';

interface Props {
  config?: TopEmployeeInteractionsData;
  shiftId: number;
  onChange?: (value: TopEmployeeInteractionsData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'pk',
  'warning_times',
  'hazard_times',
  'remote_times',
  'total',
];

export interface TopEmployeeShiftGridRef {
  dataGridRef: DataGridRef | null;
  refresh: () => void;
}

const TopEmployeeShiftGrid = forwardRef(
  (
    {config, shiftId, onChange}: Props,
    ref?: ForwardedRef<TopEmployeeShiftGridRef>
  ) => {
    const shiftConfig = config?.shifts?.[shiftId] ?? {};

    /*********/
    /* fetch */
    /*********/

    const [fetchedData, setFetchedData] =
      useState<TopBottomInteractionsResponse>();
    const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
    const [fetchedInProgress, setFetchedInProgress] = useState(false);

    const fetchData = async (params: TopBottomInteractionsQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/top-bottom-interactions`;
        const resp = await API.get<TopBottomInteractionsResponse>(endpoint, {
          params: {
            ...params,
            input: JSON.stringify(params.input),
          },
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    };

    const params = useMemo<TopBottomInteractionsQuery>(
      () => ({
        zone_id: config?.params?.zone_id ?? null,
        shift_id: shiftId,
        date_start: config?.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
        date_end: config?.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
        human_type_report: config?.params?.human_type_report ?? null,
        dir: config?.params?.dir ?? null,
        input: config?.params?.input ?? {},
      }),
      [config?.params]
    );

    useEffect(() => {
      if (params) {
        setFetchedData(undefined);
        fetchData(params);
      }
    }, [params]);

    const drilldown = useDashboardDrilldown();

    /*************/
    /* data grid */
    /*************/
    const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
    const rows = fetchedData?.results ?? [];
    const dataGridRef = useRef<DataGridRef>(null);
    const columns: DataGridColumn<(typeof rows)[number]>[] = [
      {
        field: 'human_external_id',
        headerName: 'Employee ID',
        sortable: true,
        renderCell: ({row}) => (
          <Tooltip title="View Employee History">
            <Link
              sx={{cursor: 'pointer'}}
              onClick={() => {
                drilldown?.({
                  code: 'EmployeeHistoryReport',
                  chartType: 'bar',
                  filter: {...params, external_id: row.human_external_id},
                });
              }}
            >
              {getPadVadHumanReadable(row.human_external_id)}
            </Link>
          </Tooltip>
        ),
      },
      {
        field: 'human_type',
        headerName: 'Employee Type',
        sortable: true,
      },
      {
        field: 'warning_times',
        headerName: 'Warnings',
        sortable: true,
      },
      {
        field: 'hazard_times',
        headerName: 'Hazards',
        sortable: true,
      },
      {
        field: 'remote_times',
        headerName: 'Remote Stops',
        sortable: true,
      },
      {
        field: 'total',
        headerName: 'Total',
        sortable: true,
      },
      {
        field: 'first_name',
        headerName: 'First Name',
        sortable: true,
      },
      {
        field: 'last_name',
        headerName: 'Last Name',
        sortable: true,
      },
      {
        field: 'nickname',
        headerName: 'Nickname',
        sortable: true,
      },
    ];

    useEffect(() => {
      if (params.human_type_report) {
        setShownFields([
          'human_type',
          ...shownFields.filter((i) => i !== 'human_external_id'),
        ]);
      } else if (!params.human_type_report) {
        setShownFields([
          'human_external_id',
          ...shownFields.filter((i) => i !== 'human_type'),
        ]);
      }
    }, [params.human_type_report]);

    useImperativeHandle(ref, () => ({
      dataGridRef: dataGridRef.current,
      refresh: () => fetchData(params),
    }));

    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        gap={3}
        width="100%"
        overflow="hidden"
        position="relative"
      >
        <Backdrop
          open={fetchedInProgress}
          sx={{position: 'absolute', zIndex: 1199}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, idx) => (
          <Alert
            key={idx}
            severity="error"
            onClose={() => params && fetchData(params)}
          >
            {error}
          </Alert>
        ))}

        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          shownFields={shownFields}
          size="small"
          sortBy={
            shiftConfig?.order
              ? {
                  field: shiftConfig?.order,
                  dir: shiftConfig?.dir ?? 'asc',
                }
              : null
          }
          onSort={(v) => {
            onChange?.(
              update(config ?? {}, {
                shifts: {
                  $set: {
                    [shiftId]: {
                      ...shiftConfig,
                      order: v?.field,
                      dir: v?.dir,
                    },
                  },
                },
              })
            );
          }}
          onShownFieldsChange={setShownFields}
        />
      </Box>
    );
  }
);

export default TopEmployeeShiftGrid;
