import DataSaverOffSharpIcon from '@mui/icons-material/DataSaverOffSharp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PercentSharpIcon from '@mui/icons-material/PercentSharp';
import PieChartSharpIcon from '@mui/icons-material/PieChartSharp';
import PinOutlinedIcon from '@mui/icons-material/PinOutlined';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
import TocSharpIcon from '@mui/icons-material/TocSharp';
import {Button, Divider, Menu, MenuItem, Typography} from '@mui/material';
import React from 'react';

interface TemplateType {
  id: number;
  name: string;
  icon: React.ReactNode;
}

const templates: TemplateType[] = [
  {id: 1, name: 'Count', icon: <PinOutlinedIcon />},
  {id: 2, name: 'Numerator/Denominator', icon: <PercentSharpIcon />},
  {id: 3, name: 'Donut', icon: <DataSaverOffSharpIcon />},
  {id: 4, name: 'Pie', icon: <PieChartSharpIcon />},
  {id: 5, name: 'Line', icon: <SsidChartRoundedIcon />},
  {id: 6, name: 'Table', icon: <TocSharpIcon />},
];

export const MetricTemplateSelector = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selected, setSelected] = React.useState(templates[0]);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{textTransform: 'none'}}
        startIcon={selected.icon}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {selected.name}
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Typography m={1}>Widget type</Typography>
        <Divider />
        {templates.map((item) => (
          <MenuItem
            selected={item.id === selected.id}
            value={item.id}
            key={item.id}
            sx={{py: 2, '.MuiSvgIcon-root': {mr: 1.5}}}
            onClick={() => {
              setSelected(item);
              handleClose();
            }}
          >
            {item.icon}
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
