import {MenuItem, TextField, TextFieldProps} from '@mui/material';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'name'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: number;
  onChange?: (value?: number) => void;
};

export const EmployeeTypeSelect = (props: Props) => {
  return (
    <TextField
      {...props}
      select
      onChange={(v) => {
        props.onChange?.(Number(v.target.value));
      }}
    >
      <MenuItem value={0}>Employee</MenuItem>
      <MenuItem value={1}>Employee Type</MenuItem>
    </TextField>
  )
};
