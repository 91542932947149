import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';

import API from '../api/axios';

interface UseCrudItemParams<T> {
  pk?: number | string;
  item?: T;
  endpointBase?: string;
  endpointFetch?: string;
  endpointPatch?: string;
  endpointPost?: string;
  prefetch?: boolean;
  onFetch?: Function;
  onPatch?: Function;
  onPost?: Function;
  onSubmit?: Function;
  onUpdate?: Function;
  onSubmitted?: Function;
}

// eslint-disable-next-line func-style
export function useCrudItem<T>(params: UseCrudItemParams<T>) {
  const [item, setItem] = useState(params.item);
  const [errorsFetch, setErrorsFetch] = useState<string[]>([]);
  const [errorsSubmit, setErrorsSubmit] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const isCreated = useMemo(() => !!params.pk, [params.pk]);
  const errors = useMemo(
    () => [...errorsFetch, ...errorsSubmit],
    [errorsFetch, errorsSubmit]
  );

  const handleSetItem = (newItem?: T) => {
    // set new item only if value !== newItem
    if (!_.isEqual(newItem, item)) {
      setItem(_.cloneDeep(newItem));
    }

    // call onUpdate only of newItem !== item
    if (!_.isEqual(newItem, params.item)) {
      params.onUpdate?.(_.cloneDeep(newItem));
    }
  };

  const onFetch =
    params.onFetch ??
    (async () => {
      setErrorsFetch([]);
      setIsFetching(true);

      try {
        if (isCreated) {
          const endpoint =
            params.endpointFetch ?? `${params.endpointBase}/${params.pk}`;
          const resp = await API.get<T>(endpoint);
          handleSetItem(resp.data);
        }
      } catch (e: any) {
        setErrorsFetch([
          e.response?.data?.message ??
          e.response?.data?.errors ??
          'There is an error'
        ]);
      }

      setIsFetching(false);
    });

  const onPatch =
    params.onPatch ??
    (async (values: any) => {
      setErrorsSubmit([]);
      const endpoint =
        params.endpointPatch ?? `${params.endpointBase}/${params.pk}`;
      try {
        const resp = await API.patch<T>(endpoint, values);
        handleSetItem(resp.data);
        params.onSubmitted?.(resp.data);
      } catch (e: any) {
        setErrorsSubmit([
          e.response?.data?.message ??
          e.response?.data?.errors ??
          'There is an error'
        ]);
      }
    });

  const onPost =
    params.onPost ??
    (async (values: any) => {
      setErrorsSubmit([]);
      const endpoint = params.endpointPost ?? `${params.endpointBase}`;
      try {
        const resp = await API.post<T>(endpoint, values);
        handleSetItem(resp.data);
        params.onSubmitted?.(resp.data);
      } catch (e: any) {
        setErrorsSubmit([
          e.response?.data?.message ??
          e.response?.data?.errors ??
          'There is an error'
        ]);
      }
    });

  const onSubmit =
    params.onSubmit ??
    (async (values: any) => {
      if (isCreated) {
        await onPatch(values);
      } else {
        await onPost(values);
      }
    });

  useEffect(() => {
    handleSetItem(params.item);
  }, [params.item]);

  useEffect(() => {
    if (params.prefetch ?? true) {
      onFetch();
    }
  }, [params.pk]);

  return {
    item,
    errors,
    errorsFetch,
    errorsSubmit,
    isFetching,
    handleSetItem,
    onFetch,
    onSubmit,
    onPatch,
    onPost,
  };
}
