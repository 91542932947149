import {Button, Menu, MenuItem} from '@mui/material';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';

export interface BaseObjectSelectProps {
  items: ({ id: number, name: string })[];
  nullLabel?: string;
  onChange?: (v: number) => void;
}

export const BaseObjectSelect = (props: BaseObjectSelectProps) => {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <Button
            variant="outlined"
            {...bindTrigger(popupState)}
          >
            {props.nullLabel || 'Select an Object'}
          </Button>
          <Menu {...bindMenu(popupState)}>
            {props.items?.map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => {
                  props.onChange?.(item.id);
                  popupState.close();
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </PopupState>
  );
};
