import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useMemo} from 'react';

import {useAppSelector} from '../../hooks/redux';
import {
  getIsCommtracExternalIdVisible,
  getIsMacAddressVisible,
} from '../../utils/commtrac-nodes';

interface Props {
  open?: boolean;
  wifiEnabled?: boolean | null;
  macAddress?: string | null;
  commtracExternalId?: number | null;
  onConfirm?: () => void;
  onClose?: () => void;
}

const AssetMachineReassignDialog = ({
  open = false,
  wifiEnabled,
  macAddress,
  commtracExternalId,
  onConfirm,
  onClose,
}: Props) => {
  const commtracNodes = useAppSelector(({assets}) => assets.commtrac_nodes);
  const isCommtracExternalIdVisible = getIsCommtracExternalIdVisible(
    true,
    wifiEnabled ?? null
  );
  const isMacAddressVisible = getIsMacAddressVisible(true, wifiEnabled ?? null);

  const commtracNodeToReassign = useMemo(() => {
    if (isCommtracExternalIdVisible && commtracExternalId) {
      return commtracNodes.find(
        (i) => i.commtrac_external_id === commtracExternalId
      );
    } else if (isMacAddressVisible && macAddress) {
      return commtracNodes.find((i) => i.mac_address === macAddress);
    }
  }, [
    commtracExternalId,
    macAddress,
    isCommtracExternalIdVisible,
    isMacAddressVisible,
  ]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Force Reassign</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {isMacAddressVisible
            ? `Mac Address ${macAddress} is assigned to another ${commtracNodeToReassign?.type}. Are you sure you want to reassign it to this asset?`
            : isCommtracExternalIdVisible
              ? `Network ID ${commtracExternalId} is assigned to another ${commtracNodeToReassign?.type}. Are you sure you want to reassign it to this asset?`
              : null}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <LoadingButton
          variant="contained"
          autoFocus
          onClick={onConfirm}
          color="warning"
        >
          Reassign
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssetMachineReassignDialog;
