import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Paper,
} from '@mui/material';
import React from 'react';

import {Zone} from '../../interfaces/Zone';

type AlarmGroupSectionsPropsType = {
  formik: any;
  sections: number[];
  sectionsList: Zone[];
  fieldHandle: (fieldName: string, itemId: number) => void;
};
export const AlarmGroupSections = React.memo(
  ({
    sectionsList,
    sections,
    fieldHandle,
    formik,
  }: AlarmGroupSectionsPropsType) => {
    return (
      <Paper sx={{p: 2, mb: 2}}>
        <Box fontSize={20} lineHeight={1.6} mb={2}>
          Select the sections you want included:
        </Box>

        <Grid container sx={{maxHeight: 300, overflowY: 'auto'}}>
          {sectionsList &&
            !!sectionsList.length &&
            sectionsList.map((item: Zone) => (
              <Grid item xs={4} key={item.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sections.includes(item.id)}
                      onChange={() => fieldHandle('sections', item.id)}
                    />
                  }
                  label={item.name}
                />
              </Grid>
            ))}
        </Grid>
        {formik.values.sections?.length > 0 &&
          formik.values.alarm_modules.length < 1 &&
          !!formik.touched.alarm_modules &&
          !!formik.errors.alarm_modules && (
            <FormHelperText
              error={
                !!formik.touched.alarm_modules && !!formik.errors.alarm_modules
              }
            >
              Selected section does not have any alarm modules.
            </FormHelperText>
          )}
      </Paper>
    );
  }
);
