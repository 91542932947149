// eslint-disable-next-line simple-import-sort/imports
import {useCallback} from 'react';

import {Permission} from '../../../utils/acl';
import {useAppSelector} from '../../../hooks/redux';
import {PanelProductCode} from '../../../utils/panels';
import reduxSelectors from '../../../redux/selectors';

export const getHasProduct = (
  enabledProducts: PanelProductCode[],
  products: PanelProductCode[]
) =>
  products.every((product: PanelProductCode) =>
    enabledProducts.includes(product)
  );

export const getHasPermission = (
  userPermissions: Permission[],
  permissions: Permission[]
) => permissions.every((i) => userPermissions.includes(i));

export const getHasRole = (userRole: number, roles: number[]) =>
  roles.includes(userRole);

export const useGetHasAccess = () => {
  const me = useAppSelector(({app}) => app.me);
  const enabledProducts = useAppSelector(reduxSelectors.assets.productsEnabled);

  return useCallback(
    ({
      roles,
      products,
      permissions,
      accessChecker,
    }: {
      roles?: number[];
      products?: PanelProductCode[];
      permissions?: Permission[];
      accessChecker?: () => boolean;
    }) => {
      if (!me) {
        return false;
      }

      if (products && !getHasProduct(enabledProducts, products)) {
        return false;
      }

      const userRole = me.type_id;
      const userPermissions = me.permissions;

      if (roles && !getHasRole(userRole, roles)) {
        return false;
      }

      if (permissions && !getHasPermission(userPermissions, permissions)) {
        return false;
      }

      if (accessChecker && !accessChecker()) {
        return false;
      }

      return true;
    },
    [me]
  );
};
