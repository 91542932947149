import dayjs from 'dayjs';
import _ from 'lodash';

import {eventsFiltersConfig} from '../components/selectors/EventTypeSelect';
import {EventSummaryProductValue} from '../interfaces/Event';
import {ALARM_MODULE_SOUNDS} from './alarm-modules';
import {AMS_SOUNDS} from './ams';
import {
  ASSET_EVENT_COLORS,
  ASSET_EVENT_SOUNDS,
  MINER_EVENT_COLORS,
  MINER_EVENT_SOUNDS,
} from './commtrac-nodes';
import {tweakLabelForMiner} from './macAddress';
import {CN_EVENT_COLORS, CN_EVENT_SOUNDS} from './nodes';
import {SAFEYE_NANO_EVENT_SOUNDS} from './safeyeNodes';

export const getEventDescription = (
  template: string,
  textReplacement: string[]
) => {
  if (!template) {
    return null;
  }

  if (!textReplacement || !textReplacement.length) {
    return tweakLabelForMiner(template);
  }
  return tweakLabelForMiner(
    template.replace(/(%s)\d{0,}/gim, (match) => {
      if (match === '%s') {
        return textReplacement[0];
      }
      const [, index] = match.split('%s');

      return textReplacement[+index - 1] ? textReplacement[+index - 1] : '';
    })
  );
};

export const getLastSummaryEventDescription = (
  product: EventSummaryProductValue | undefined,
  ams: EventSummaryProductValue | undefined | null
) => {
  const ams_recent = ams?.recent
    ? dayjs(ams?.recent?.date)?.toDate().getTime()
    : null;
  const product_recent = product?.recent
    ? dayjs(product?.recent?.date)?.toDate().getTime()
    : null;

  if (ams_recent && product_recent) {
    if (ams_recent > product_recent) {
      const date = dayjs(ams?.recent?.date).format('YYYY-MM-DD HH:mm:ss');
      const desc = getEventDescription(
        ams?.recent?.text_template ?? '',
        ams?.recent?.text_replacements ?? ['']
      );
      return `${date} - ${desc}`;
    } else {
      const date = dayjs(product?.recent?.date).format('YYYY-MM-DD HH:mm:ss');
      const desc = getEventDescription(
        product?.recent?.text_template ?? '',
        product?.recent?.text_replacements ?? ['']
      );
      return `${date} - ${desc}`;
    }
  } else if (ams_recent && !product_recent) {
    const date = dayjs(ams?.recent?.date).format('YYYY-MM-DD HH:mm:ss');
    const desc = getEventDescription(
      ams?.recent?.text_template ?? '',
      ams?.recent?.text_replacements ?? ['']
    );
    return `${date} - ${desc}`;
  } else if (product_recent && !ams_recent) {
    const date = dayjs(product?.recent?.date).format('YYYY-MM-DD HH:mm:ss');
    const desc = getEventDescription(
      product?.recent?.text_template ?? '',
      product?.recent?.text_replacements ?? ['']
    );
    return `${date} - ${desc}`;
  }

  return 'Events';
};

export const getLastSummaryEventProduct = (
  product: EventSummaryProductValue | undefined,
  ams: EventSummaryProductValue | undefined | null
) => {
  const ams_recent = ams?.recent
    ? dayjs(ams?.recent?.date)?.toDate().getTime()
    : null;
  const product_recent = product?.recent
    ? dayjs(product?.recent?.date)?.toDate().getTime()
    : null;

  if (ams_recent && product_recent) {
    if (ams_recent > product_recent) {
      return ams;
    } else {
      return product;
    }
  } else if (ams_recent && !product_recent) {
    return ams;
  } else if (product_recent && !ams_recent) {
    return product;
  }

  return undefined;
};

export const getCompanyProductStatusByType = (typeToFind: number) => {
  return _.chain(eventsFiltersConfig)
    .filter(group =>
      _.some(group.ids, { type: typeToFind }) // Check if the group contains the type
    )
    .map(group => ({
      type: typeToFind,
      product: group.product || "", // Get the product if exists
    }))
    .find() // Get the first matching object
    .value();
};

export const CONNECT_EVENT_SOUNDS: {
  [key: number]: string | null;
} = {
  ...CN_EVENT_SOUNDS,
  ...ASSET_EVENT_SOUNDS,
  ...MINER_EVENT_SOUNDS,
  ...ALARM_MODULE_SOUNDS,
  ...AMS_SOUNDS,
};

export const CONNECT_EVENT_COLORS: {
  [key: number]: string | null;
} = {
  ...CN_EVENT_COLORS,
  ...ASSET_EVENT_COLORS,
  ...MINER_EVENT_COLORS,
};

export const HAZARD_AI_EVENT_SOUNDS: {[key: number]: string} = {
  ...SAFEYE_NANO_EVENT_SOUNDS,
};
