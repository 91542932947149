import AddIcon from '@mui/icons-material/Add';
import {
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import React from 'react';

interface Props {
  type: string;
  field: string;
}

export const DatabaseFieldRow = ({type, field}: Props) => {
  return (
    <Grid item xs={12}>
      <ListItem
        dense
        disablePadding
        secondaryAction={
          <IconButton size="small">
            <AddIcon fontSize="small" />
          </IconButton>
        }
      >
        <ListItemButton>
          <ListItemText primary={field} secondary={type} />
        </ListItemButton>
      </ListItem>
      <Divider sx={{opacity: 0.2}} />
    </Grid>
  );
};
