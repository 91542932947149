import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
// import {JsonEditor} from 'jsoneditor-react'; TODO: remove from dependencies
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import {apiBaseUrl} from '../../api/urls';
import {useCrudItem} from '../../hooks/useCridItem';
import {
  SubscriptionReport,
  SubscriptionReportInput,
} from '../../interfaces/SubscriptionReport';
import {SubscriptionReportCode, SubscriptionReportFilter} from '../../interfaces/SubscriptionReportType';
import reduxSelectors from '../../redux/selectors';
import AssetMachineSelect from '../asset-machine/AssetMachineSelect';
import {AcknowledgedTypeSelect} from '../dashboard-panels/ConnectView/AcknowledgedTypeSelect';
import {ClearedTypeSelect} from '../dashboard-panels/ConnectView/ClearedTypeSelect';
import {ZoneChangeCheckbox} from '../dashboard-panels/MachineHistoryReport/ZoneChangeCheckbox';
import {EmployeeTypeSelect} from '../dashboard-panels/TopEmployeeInteractions/EmployeeTypeSelect';
import {AssetStatusSelect} from '../selectors/AssetStatusSelect';
import {CommtracNodeStatusSelect} from '../selectors/CommtracNodeStatusSelect';
import {EventTypeSelect} from '../selectors/EventTypeSelect';
import {MachineTypeSelect} from '../selectors/MachineTypeSelect';
import {ShiftSelect} from '../selectors/ShiftSelect';
import {ZonesSelect} from '../selectors/ZonesSelect';

const inputValidationSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required'),

  report_code: yup.string().nullable().required('Field is required'),
});

const getInput = (item?: SubscriptionReport): SubscriptionReportInput => ({
  name: item?.name ?? '',
  report_code: item?.report_code ?? null,
  filters: item?.filters ?? {},
});

interface SubscriptionReportItemUpsertProps {
  pk?: number;
  item?: SubscriptionReport;
  onCancel?: Function;
  onSubmitted?: Function;
}

export const SubscriptionReportItemUpsert: React.FC<
  SubscriptionReportItemUpsertProps
> = ({pk, item, onCancel, onSubmitted}) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const assetMachines = useMemo(() => {
    return assets.asset_machines.filter(
      (m) => m.status === 'active' && m.external_id
    );
  }, [assets.asset_machines])

  const [filters, setFilters] = useState<string[]>([]);

  const crud = useCrudItem<SubscriptionReport>({
    pk,
    item,
    endpointBase: `${apiBaseUrl}/subscription-report`,
    onSubmitted,
  });

  const formik = useFormik({
    initialValues: getInput(item),
    validationSchema: inputValidationSchema,
    onSubmit: (values) => crud.onSubmit(values),
  });

  useEffect(() => {
    const newInput = getInput?.(crud.item);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(getInput?.(crud.item));
    }
  }, [crud.item]);

  // Show filters
  useEffect(() => {
    if (formik.values.report_code) {
      setFilters(SubscriptionReportFilter[formik.values.report_code as SubscriptionReportCode]);
    } else {
      setFilters([]);
    }
    // Reset filters value
    formik.setFieldValue('filters', {});
  }, [formik.values.report_code])

  const handleFilter = (name: string, value: any) => {
    const current = formik.values.filters;
    if (_.isNull(value) || _.isUndefined(value)) {
      delete current[name];
    } else {
      current[name] = value;
    }
    formik.setFieldValue('filters', current);
  };

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Backdrop open={crud.isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!assets.subscriptionReportType.length && (
        <Alert severity="error" sx={{mb: 2}}>
          You have no subscription reports available
        </Alert>
      )}

      {crud.errors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      {!!assets.subscriptionReportType.length && (
        <>
          <Box my={3}>
            <TextField
              value={formik.values.name}
              fullWidth
              name="name"
              label="Name"
              type="text"
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
            />
          </Box>

          <Box my={3}>
            <TextField
              value={formik.values.report_code}
              name="report_code"
              select
              fullWidth
              label="Report"
              error={
                !!formik.touched.report_code && !!formik.errors.report_code
              }
              helperText={
                formik.touched.report_code && formik.errors.report_code
              }
              onChange={formik.handleChange}
            >
              {assets.subscriptionReportType.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          {formik.values.report_code && filters.length > 0 && (
            <>
              <Divider sx={{my: 2}}>FILTERS</Divider>

              {filters.map((filter) => (
                <>
                  {['shift_id'].includes(filter) && (
                    <Box my={3}>
                      <ShiftSelect
                        fullWidth
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['zone_id'].includes(filter) && (
                    <Box my={3}>
                      <ZonesSelect
                        fullWidth
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['section_ids'].includes(filter) && (
                    <Box my={3}>
                      <ZonesSelect
                        multiple
                        fullWidth
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['type_id'].includes(filter) && (
                    <Box my={3}>
                      <MachineTypeSelect
                        fullWidth
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['external_id'].includes(filter) && (
                    <Box my={3}>
                      <AssetMachineSelect
                        fullWidth
                        assetMachines={assetMachines}
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['human_type_report'].includes(filter) && (
                    <Box my={3}>
                      <EmployeeTypeSelect
                        fullWidth
                        value={formik.values.filters[filter] ? 1 : 0}
                        onChange={(v) => handleFilter(filter, v === 1)}
                      />
                    </Box>
                  )}

                  {['communication_node_status', 'wifi_point_status'].includes(filter) && (
                    <Box my={3}>
                      <CommtracNodeStatusSelect
                        fullWidth
                        label={filter === 'wifi_point_status' ? 'Wifi Status' : 'Node Status' }
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['miner_status', 'asset_status'].includes(filter) && (
                    <Box my={3}>
                      <AssetStatusSelect
                        fullWidth
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['type'].includes(filter) && (
                    <Box my={3}>
                      <EventTypeSelect
                        size="medium"
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v.map((v) => Number(v)))}
                      />
                    </Box>
                  )}

                  {['acknowledged'].includes(filter) && (
                    <Box my={3}>
                      <AcknowledgedTypeSelect
                        fullWidth
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['cleared'].includes(filter) && (
                    <Box my={3}>
                      <ClearedTypeSelect
                        fullWidth
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v)}
                      />
                    </Box>
                  )}

                  {['zone_change'].includes(filter) && (
                    <Box my={3}>
                      <ZoneChangeCheckbox
                        value={formik.values.filters[filter]}
                        onChange={(v) => handleFilter(filter, v ? 1 : 0)}
                      />
                    </Box>
                  )}
                </>
              ))}
            </>
          )}
        </>
      )}

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>

        {!!assets.subscriptionReportType.length && (
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            sx={{ml: 1}}
            onClick={() => formik.handleSubmit()}
          >
            Submit
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
};
