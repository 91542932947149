import {createSelector} from 'reselect';

import {ReduxState} from '../store';

const authSelectors = {
  getAuth: createSelector([(state: ReduxState) => state.auth], (auth) => auth),

  getLoggedIn: createSelector(
    [(state: ReduxState) => state.auth],
    (auth) => !!auth?.refreshToken
  ),
};

export default authSelectors;
