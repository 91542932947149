import {Box} from '@mui/material';

interface Props {
  messages: string[];
}

const SnackbarMessages = ({messages}: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {messages.map((message, idx) => (
        <Box key={idx}>{message}</Box>
      ))}
    </Box>
  );
};

export default SnackbarMessages;
