import CellTowerIcon from '@mui/icons-material/CellTower';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {CommunicationNode} from '../../interfaces/CommunicationNodeV2';
import AccessControl from '../common/AccessControl';
import CommunicationNodeItemActivationModal from './CommunicationNodeItemActivationModal';
import CommunicationNodeItemMakeSecondaryModal from './CommunicationNodeItemMakeSecondaryModal';
import CommunicationNodeItemPurgeModal from './CommunicationNodeItemPurgeModal';
import CommunicationNodeItemResetModal from './CommunicationNodeItemResetModal';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: CommunicationNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
}

const CommunicationNodeItemActionsButton = <
  T extends ComponentType = typeof Button,
>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [action, setAction] = useState<
    'reset' | 'activation' | 'purgeDevice' | 'purgeData' | 'makeSecondary'
  >();

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <AccessControl
          permissions={['patch::/node/:id/communication-node/reset']}
        >
          <MenuItem
            onClick={() => {
              setAction('reset');
              setAnchorEl(null);
            }}
          >
            <RestartAltIcon fontSize="small" sx={{mr: 1.5}} />
            Reset
          </MenuItem>
        </AccessControl>
        <AccessControl permissions={['patch::/node/:id/communication-node']}>
          <MenuItem
            onClick={() => {
              setAction('activation');
              setAnchorEl(null);
            }}
          >
            {item.status === 'active' ? (
              <>
                <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                DeActivate
              </>
            ) : (
              <>
                <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                Activate
              </>
            )}
          </MenuItem>
        </AccessControl>
        {item.node_type === 1 ? (
          <AccessControl
            permissions={['patch::/node/:id/communication-node/make_secondary']}
          >
            <MenuItem
              onClick={() => {
                setAction('makeSecondary');
                setAnchorEl(null);
              }}
            >
              <CellTowerIcon fontSize="small" sx={{mr: 1.5}} /> Make Secondary
            </MenuItem>
          </AccessControl>
        ) : null}
        <AccessControl permissions={['post::/purge']}>
          <MenuItem
            onClick={() => {
              setAction('purgeData');
              setAnchorEl(null);
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
            Purge Transactions
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAction('purgeDevice');
              setAnchorEl(null);
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
            Purge Device
          </MenuItem>
        </AccessControl>
      </Menu>

      {action === 'reset' ? (
        <CommunicationNodeItemResetModal
          item={item}
          open={action === 'reset'}
          onClose={() => setAction(undefined)}
          onSubmitted={() => setAction(undefined)}
        />
      ) : action === 'activation' ? (
        <CommunicationNodeItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : action && ['purgeDevice', 'purgeData'].includes(action) ? (
        <CommunicationNodeItemPurgeModal
          item={item}
          action={action === 'purgeData' ? 'data' : 'device'}
          open={['purgeDevice', 'purgeData'].includes(action)}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : action === 'makeSecondary' ? (
        <CommunicationNodeItemMakeSecondaryModal
          item={item}
          open={action === 'makeSecondary'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : null}
    </>
  );
};

export default CommunicationNodeItemActionsButton;
