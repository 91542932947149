export default {
  all: 'All',
  core: 'Core',
  connect: 'Connect',
  wifi: 'WiFi',
  belts: 'Belts',
  proximity: 'Proximity',
  ams: 'AMS',
  shaft_clearance: 'Shaft Clearance',
  ventilation: 'Ventilation',
  hazard: 'HazardAI',
  alarm: 'Alarm',
};
