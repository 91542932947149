import SendIcon from '@mui/icons-material/Send';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {Box} from '@mui/system';
import {useFormik} from 'formik';
import {isEqual} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  BroadcastMessage,
  BroadcastMessageInputBody,
  BroadcastMessegeItemResponse,
} from '../../interfaces/BroadcastMessage';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  pk?: number;
  item?: BroadcastMessage;
  prefetch?: boolean;
  onCancel?: Function;
  onSubmitted?: (v: BroadcastMessage) => void;
}

const getFormikValues = (
  item?: BroadcastMessage
): BroadcastMessageInputBody => ({
  text: item?.text ?? null,
});

const BroadcastMessageUpsert = ({
  pk,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<
    BroadcastMessegeItemResponse | undefined
  >(item);
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<BroadcastMessegeItemResponse>(
        `${apiBaseUrl}/commtrac-broadcast-message/${pk}`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      setFetchedErrors([message]);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }

    return () => {
      setFetchedData(undefined);
      setFetchedErrors([]);
    };
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: BroadcastMessageInputBody) => {
    setSubmittedInProgress(true);

    try {
      const endpoint = pk
        ? `${apiBaseUrl}/commtrac-broadcast-message/${pk}`
        : `${apiBaseUrl}/commtrac-broadcast-message`;
      const resp = await API.post<BroadcastMessage>(endpoint, data);
      const message = `Broadcast message ${pk ? 'updated' : 'sent'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      text: yup.string().nullable().required('Field is required'),
    };

    return yup.object().shape(fields);
  }, [pk]);

  const formik = useFormik<BroadcastMessageInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      console.log('values', values);
      await submitData(values);
    },
  });

  useEffect(() => {
    const newFormikValues = getFormikValues(fetchedData);
    if (!isEqual(newFormikValues, formik.values)) {
      formik.setValues(newFormikValues);
    }
  }, [fetchedData]);

  return (
    <Box component="form" position="relative" onSubmit={formik.handleSubmit}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{my: 2}}>
          {error}
        </Alert>
      ))}

      <Box mb={3}>
        <TextField
          value={formik.values.text}
          name="text"
          fullWidth
          placeholder="Type Broadcast Message..."
          error={!!formik.touched.text && !!formik.errors.text}
          helperText={formik.touched.text && formik.errors.text}
          onChange={formik.handleChange}
          inputProps={{maxLength: 40}}
          InputProps={{
            endAdornment: (
              <LoadingButton
                variant="contained"
                disabled={!formik.values.text}
                loading={submittedInProgress}
                type="submit"
              >
                <SendIcon />
              </LoadingButton>
            ),
          }}
        />
      </Box>

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>
      </Box>
    </Box>
  );
};

export default BroadcastMessageUpsert;
