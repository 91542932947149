import {Box, Button, Modal, Tooltip} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import AlarmUpdateStatusForm from './AlarmUpdateStatusForm';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  type: 'enable' | 'disable';
  component?: T;
  disabled?: boolean;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  forceOpen?: boolean;
  preSelected?: number[];
  onSubmitted?: () => void;
  onClose?: () => void;
  messageId?: number;
  externalId?: string;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
};

const AlarmUpdateStatusButton = <T extends ComponentType>({
  type,
  component,
  disabled,
  componentProps,
  children,
  forceOpen,
  preSelected,
  onSubmitted,
  onClose,
  messageId,
  externalId,
}: Props<T>) => {
  const Component = component ?? Button;

  const [isOpened, setIsOpened] = useState(false);
  useEffect(() => {
    if (forceOpen === true) {
      setIsOpened(true);
    }
  }, [forceOpen]);
  return (
    <>
      <Tooltip title={type === 'enable' ? 'Enable Alarm' : 'Disable Alarm'}>
        <Component
          {...componentProps}
          disabled={disabled}
          onClick={() => setIsOpened(true)}
        >
          {children}
        </Component>
      </Tooltip>

      {isOpened ? (
        <Modal
          open={isOpened}
          onClose={() => {
            onClose?.();
            setIsOpened(false);
          }}
        >
          <Box sx={style}>
            <AlarmUpdateStatusForm
              type={type}
              onSubmitted={onSubmitted}
              preSelected={preSelected}
              onClose={() => {
                onClose?.();
                setIsOpened(false);
              }}
              messageId={messageId}
              externalId={externalId}
            />
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default AlarmUpdateStatusButton;
