import {Box, Container, Paper, Typography} from '@mui/material';
import React from 'react';

import {EmptyWidgetPreviewPanel} from './EmptyWidgetPreviewPanel';
import {MetricTemplateSelector} from './MetricTemplateSelector';

export const WidgetMetricEditPanel = () => {
  return (
    <Container sx={{display: 'flex', flexDirection: 'column'}}>
      <Box>
        <MetricTemplateSelector />
      </Box>
      <Box
        component={Paper}
        sx={{
          flex: 1,
          mt: 2,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography width="100%" variant="h4" p={2}>
          My widget
        </Typography>
        <EmptyWidgetPreviewPanel />
        <Typography variant="h6" p={2}>
          description
        </Typography>
      </Box>
    </Container>
  );
};
