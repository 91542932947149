import {Box, Button, Grid, Typography} from '@mui/material';
import _ from 'lodash';
import {useMemo, useRef} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import AccessControl from '../../components/common/AccessControl';
import {SubscriptionItemUpsertButton} from '../../components/subscription/buttons/SubscriptionItemUpsertButton';
import {
  SubscriptionList,
  SubscriptionListRef,
} from '../../components/subscription/SubscriptionList';
import {useAppSelector} from '../../hooks/redux';
import {DashboardLayout} from '../../layouts/DashboardLayout';
import {getQueryParamNumber} from '../../utils/router';

const SubscriptionListPage: React.FC = () => {
  const listRef = useRef<SubscriptionListRef | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;
  const connect_enabled = !!company?.commtrac_enabled;
  const proximity_enabled = !!company?.proximity_enabled;

  const queryParams = useMemo(() => {
    const search = new URLSearchParams(location.search);
    return {
      limit: getQueryParamNumber(search, 'limit'),
      page: getQueryParamNumber(search, 'page'),
    };
  }, [location.search]);

  const onUpdate = (values: any) => {
    const query: any = {};

    if (!_.isNil(values.limit)) {
      query.limit = values.limit;
    }

    if (!_.isNil(values.page)) {
      query.page = values.page;
    }

    if (!_.isNil(values.name)) {
      query.name = values.name;
    }

    const search = new URLSearchParams(query).toString();
    navigate({pathname: location.pathname, search});
  };

  return (
    <DashboardLayout>
      <AccessControl permissions={['get::/subscription']}>
        <Grid container spacing={3}>
          <Grid item xs={9} md={12} lg={12}>
            <Box p={2} display="flex" flexDirection="column">
              <Grid container>
                <Grid item xs={9}>
                  <Typography
                    id="modal-modal-title"
                    component="h1"
                    variant="h5"
                    sx={{mb: 2}}
                  >
                    Schedule
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  {!(
                    hazard_ai_enabled &&
                    !connect_enabled &&
                    !proximity_enabled
                  ) && (
                    <AccessControl permissions={['post::/subscription']}>
                      <Box textAlign="right">
                        <SubscriptionItemUpsertButton
                          component={Button}
                          componentProps={{variant: 'outlined'}}
                          onSubmitted={() => listRef?.current?.fetch?.()}
                        >
                          Add Schedule
                        </SubscriptionItemUpsertButton>
                      </Box>
                    </AccessControl>
                  )}
                </Grid>
              </Grid>

              <SubscriptionList
                ref={listRef}
                queryParams={queryParams}
                onUpdate={onUpdate}
              />
            </Box>
          </Grid>
        </Grid>
      </AccessControl>
    </DashboardLayout>
  );
};

export default SubscriptionListPage;
