import {Container, Divider, Grid, TextField, Typography} from '@mui/material';
import React from 'react';

export const WidgetDetailsEditPanel = () => {
  return (
    <Container disableGutters>
      <Grid container>
        <Grid item xs={12}>
          <Typography p={2}>Widget details</Typography>
          <Divider />
        </Grid>
        <Grid item container spacing={4} p={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Title (top)"
              value="My widget"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Description (bottom)"
              value="description"
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
