import {Singin} from '../../components/auth/Singin';
import {AuthLayout} from '../../layouts/AuthLayout';

export const SigninPage = () => {
  return (
    <AuthLayout>
      <Singin />
    </AuthLayout>
  );
};
