import {PlayCircle} from '@mui/icons-material';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {Alert, Box, Button, Checkbox, IconButton} from '@mui/material';
import dayjs from 'dayjs';
import {debounce} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import usePrevious from '../../../hooks/usePrevious';
import {ExportField} from '../../../interfaces/Export';
import {HazardEventLog} from '../../../interfaces/HazardAILog';
import {
  HazardHeatmapReport,
  HazardHeatmapReportListResponse,
} from '../../../interfaces/HazardHeatmapReport';
import reduxSelectors from '../../../redux/selectors';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {HazardMap} from '../../common/HazardMap';
import {MapLatLangCoordinates, MapLayerId} from '../../common/Map';
import NumberTextField from '../../common/NumberTextField';
import {ResizableColumns} from '../../common/ResizableColumns';
import {HazardHeatmapReportDataTab} from './HazardHeatmapGrid';
import HeatmapExportButton from './HeatmapExportButton';
interface Props {
  centerPos: MapLatLangCoordinates;
  radius: number;
  openedItem: HazardHeatmapReportDataTab;
  // onOpenImage?: (image: string) => void;
  onClickMedia?: (heatmapId?: number) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'id',
  'safeye_node_id',
  'image',
  'machine_id',
  'machine_name',
  'type',
  'object',
  'zone',
  'latitude',
  'longitude',
  'speed',
  'confidence',
  'distance',
  'timestamp',
];

export interface HazardHeatmapEventLogReportData {
  mapLayers: MapLayerId[];
  mapLevel: number | null;
  selectedMachineId: number | null;
  params: {
    date_start: string | null;
    date_end: string | null;
    page: number;
    limit: number;
    order: string | null;
    dir: 'ASC' | 'DESC';
    safeye_node_id: (string | null)[] | null | undefined;
    object?: string | null;
    zone?: string | null;
    speed_min: number;
    speed_max: number;
    radius: number | null;
    latitude: number | null;
    longitude: number | null;
  };
  selectedIds: number[] | null;
  shownFields: string[];
  grid: {
    pageSize: number;
    page: number;
  };
  exportFields: ExportField[];
}

export const getHazardHeatmapEventLogReportData = (
  centerPos: MapLatLangCoordinates,
  radius: number,
  openedItem: HazardHeatmapReportDataTab
): HazardHeatmapEventLogReportData => {
  return {
    mapLayers: ['street', 'mine'],
    mapLevel: null,
    selectedMachineId: null,
    params: {
      safeye_node_id: openedItem.params.safeye_node_id ?? null,
      date_start: openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
      date_end: openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      page: openedItem.params?.page ?? 0,
      limit: openedItem.params?.limit ?? 25,
      order: null,
      object: openedItem?.params?.object ?? null,
      zone: openedItem?.params?.zone ?? null,
      dir: 'ASC',
      speed_min: openedItem.params?.speed_min ?? 0,
      speed_max: openedItem.params?.speed_max ?? 200,
      latitude: centerPos.lat,
      longitude: centerPos.lng,
      radius: radius,
    },
    shownFields: DEFAULT_SHOWN_FIELDS,
    selectedIds: null,
    grid: {
      page: 0,
      pageSize: 25,
    },
    exportFields: [],
  };
};

const HazardHeatmapEventLogs = ({
  centerPos,
  openedItem,
  radius,
  onClickMedia,
}: Props) => {
  const [config, setConfig] = useState<HazardHeatmapEventLogReportData | null>(
    null
  );
  const [rad, setRadius] = useState<number>(radius ?? 10000);

  useEffect(() => {
    setRadius(radius);
    setConfig(
      getHazardHeatmapEventLogReportData(centerPos, radius, openedItem)
    );
  }, [centerPos, openedItem, radius]);
  const assets = useSelector(reduxSelectors.assets.getAssets);

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const columns: DataGridColumn<HazardHeatmapReport>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'safeye_node_id',
      headerName: 'Safeye Node',
      sortable: true,
    },
    {
      field: 'image',
      headerName: 'Detection Video/Image',
      renderCell: ({row}) => {
        return (
          <Box width="100%" display="flex" justifyContent="center">
            <IconButton
              onClick={() => {
                onClickMedia?.(row.id);
              }}
            >
              <PlayCircle />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: 'machine_id',
      sortable: true,
      headerName: 'Machine ID',
      doNotExport: true,
      hidden: (config?.params.safeye_node_id?.length ?? 0) <= 1,
    },
    {
      field: 'machine_name',
      sortable: true,
      headerName: 'Machine Name',
      doNotExport: true,
      hidden: (config?.params.safeye_node_id?.length ?? 0) <= 1,
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.type}</Box>;
      },
    },
    {
      field: 'object',
      headerName: 'Object',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.object}</Box>;
      },
    },
    {
      field: 'zone',
      headerName: 'Zone',
      sortable: true,
      renderCell: ({row}) => {
        return <Box textTransform="capitalize">{row.zone}</Box>;
      },
    },
    {
      field: 'latitude',
      headerName: 'Latitude',
      sortable: true,
    },
    {
      field: 'longitude',
      headerName: 'Longitude',
      sortable: true,
    },
    {
      field: 'speed',
      headerName: 'Speed',
      sortable: true,
    },
    {
      field: 'confidence',
      headerName: 'Confidence',
      sortable: true,
    },
    {
      field: 'distance',
      headerName: 'Distance',
      sortable: true,
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      sortable: true,
    },
  ];
  const [fetchedData, setFetchedData] =
    useState<HazardHeatmapReportListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const params = useMemo<any>(
    () =>
      openedItem?.id
        ? {
            safeye_node_id: config?.params.safeye_node_id ?? null,
            date_start:
              config?.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end: config?.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: config?.params?.page ?? 0,
            limit: config?.params?.limit ?? 25,
            order: null,
            object: config?.params?.object ?? null,
            zone: config?.params?.zone ?? null,
            dir: 'ASC',
            speed_min: config?.params?.speed_min ?? 0,
            speed_max: config?.params?.speed_max ?? 200,
            latitude: config?.params?.latitude ?? null,
            longitude: config?.params?.longitude ?? null,
            radius: config?.params?.radius ?? null,
          }
        : null,
    [config]
  );

  const fetchData = useCallback(
    async (params: any) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/hazard-ai/detection-heat-map`;
        const resp = await API.get<any>(endpoint, {
          params,
        });

        setFetchedData({
          items: (resp?.data ?? [])?.map((_it: any) => {
            const machine_id =
              assets?.asset_machines?.find(
                (it) => `${it?.safeye_node_id}` === `${_it.safeye_node_id}`
              )?.id ?? 'Not defined';
            const machine_name =
              assets?.asset_machines?.find(
                (it) => `${it?.safeye_node_id}` === `${_it.safeye_node_id}`
              )?.name ?? 'Not defined';
            return {
              ..._it,
              machine_id,
              machine_name,
            };
          }),
        });
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      } finally {
        setFetchedInProgress(false);
      }
    },
    [params]
  );

  const rows = useMemo(() => fetchedData?.items ?? [], [fetchedData]);

  useEffect(() => {
    if (params && params?.radius > 0) {
      fetchData(params);
    }
  }, [params]);

  const shownFields = useMemo(() => {
    return config?.shownFields ?? DEFAULT_SHOWN_FIELDS;
  }, [config]);

  useEffect(() => {
    if (config) {
      setConfig({
        ...config,
        exportFields: columns
          .filter((col) => !col.doNotExport)
          .map((col) => ({
            field: col.field,
            label: col.headerName,
            hidden: config?.shownFields?.indexOf(col.field) === -1,
          })),
      });
    }
  }, [shownFields, config?.shownFields]);

  const handleChangeShownFields = (fields: string[]) => {
    if (config) {
      setConfig({
        ...config,
        shownFields: fields,
      });
    }
  };

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => config?.selectedIds?.includes(i.id)),
    [rows, config?.selectedIds]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (config) {
      if (config?.selectedIds?.includes(id)) {
        setConfig({
          ...config,
          selectedIds: config.selectedIds.filter((i) => i !== id),
        });
      } else {
        setConfig({
          ...config,
          selectedIds: [...(config.selectedIds ?? []), id],
        });
      }
    }
  };

  const selectAll = () => {
    if (config) {
      setConfig({
        ...config,
        selectedIds: rows?.map((i) => i.id) ?? [],
      });
    }
  };

  const unselectAll = () => {
    if (config) {
      setConfig({
        ...config,
        selectedIds: [],
      });
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      config?.selectedIds &&
      config?.selectedIds.length !== selectedRows.length
    ) {
      setConfig({
        ...config,
        selectedIds: selectedRows.map((i) => i.id),
      });
    }
  }, [config?.selectedIds, selectedRows]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  /************/
  /* map data */
  /************/
  const mapData = useMemo(() => {
    if (fetchedData?.items && config?.selectedIds?.length) {
      return fetchedData?.items.filter((i) =>
        config.selectedIds?.includes(i.id)
      );
    }
    return [];
  }, [fetchedData, config?.selectedIds, selectedItems]);

  const debouncedSetData = useCallback(
    debounce((newValue: number) => {
      if (config) {
        setConfig({
          ...config,
          params: {
            ...config.params,
            radius: newValue,
          },
        });
      }
    }, 500),
    [config]
  );

  useEffect(() => {
    debouncedSetData(rad);
  }, [rad]);
  return (
    <Box
      width="100%"
      flex={1}
      display="flex"
      flexDirection="column"
      overflow="hidden"
    >
      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}
      <Box
        overflow="hidden"
        height={70}
        minHeight="70px"
        sx={{
          overflowY: 'hidden',
        }}
      >
        <Box
          py={2}
          minWidth={550}
          height={70}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Box flexGrow={1} display="flex" height="100%">
            <NumberTextField
              fullWidth
              label="Radius (m)"
              value={rad}
              min={1}
              size="small"
              onChange={(event) => {
                setRadius(event ?? 1);
              }}
            />
          </Box>
          <HeatmapExportButton
            value={config}
            component={Button}
            componentProps={{
              color: 'primary',
              variant: 'outlined',
              size: 'small',
              sx: {
                minWidth: 40,
                width: 40,
                height: 40,
                padding: 1,
              },
            }}
          >
            <ImportExportIcon />
          </HeatmapExportButton>
        </Box>
        {/* </Box> */}
      </Box>
      <Box flex="1" height="100%" width="100%" display="flex" overflow="hidden">
        <ResizableColumns
          overflow="unset"
          height="100%"
          sx={{
            '>*': {
              height: '100% !important',
            },
          }}
          left={
            <>
              <HazardMap
                panel="heatmap_event_logs"
                hazardDetectionLogs={mapData as HazardEventLog[]}
                selectedMapLayers={['hazardAiLogs']}
                selectedLevel={config?.mapLevel}
                height="600px"
                minHeight="100%"
                minWidth={200}
                availableMapLayers={['hazardAiLogs']}
                onSelectLevel={(v) => {
                  if (config) {
                    setConfig({
                      ...config,
                      mapLevel: v,
                    });
                  }
                }}
                onClickMedia={onClickMedia}
              />
            </>
          }
        >
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            overflow="auto"
          >
            <DataGrid
              sx={{
                overflow: 'auto',
              }}
              ref={dataGridRef}
              rows={rows}
              columns={columns}
              size="small"
              pagination
              page={config?.grid.page}
              pageSize={config?.grid.pageSize}
              shownFields={shownFields}
              loading={fetchedInProgress}
              sxFooter={{
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
              }}
              footerStart={
                selectedItems.length ? (
                  <Box display="flex" alignItems="center" gap={3} px={1}>
                    <Box
                      display="flex"
                      gap={0.5}
                      alignItems="center"
                      height="100%"
                      whiteSpace="nowrap"
                    >
                      {selectedItems.length} selected
                    </Box>
                  </Box>
                ) : null
              }
              onShownFieldsChange={handleChangeShownFields}
              onPageChange={(v) => {
                if (config) {
                  setConfig({
                    ...config,
                    grid: {
                      page: v,
                      pageSize: config?.grid?.pageSize ?? 25,
                    },
                  });
                }
              }}
              onPageSizeChange={(v) => {
                if (config) {
                  setConfig({
                    ...config,
                    grid: {
                      page: config?.grid?.page ?? 1,
                      pageSize: v,
                    },
                  });
                }
              }}
            />
          </Box>
        </ResizableColumns>
      </Box>
    </Box>
  );
};

export default HazardHeatmapEventLogs;
