import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React from 'react';

export const WidgetStatusSelector = () => {
  const [panel, setPanel] = React.useState('2');

  const handleChange = (event: SelectChangeEvent) => {
    setPanel(event.target.value);
  };
  return (
    <Select value={panel} size="small" onChange={handleChange} autoWidth>
      <MenuItem value="1">All</MenuItem>
      <MenuItem value="2">Active</MenuItem>
      <MenuItem value="3">Inactive</MenuItem>
      <MenuItem value="4">Connected</MenuItem>
      <MenuItem value="5">Disconnected</MenuItem>
      <MenuItem value="6">Unacknowledged</MenuItem>
    </Select>
  );
};
