import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import reduxSelectors from '../../../redux/selectors';
import EmployeeAssetItemsPurgeEventsButton from './EmployeeAssetItemsPurgeEventsButton';
import EmployeeAssetItemsPurgeNodeButton from './EmployeeAssetItemsPurgeNodeButton';
import EmployeeItemsPurgeChatEventsButton from './EmployeeItemsPurgeChatEventsButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  employeeIds: number[];
  disabled?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const EmployeeAssetItemsPurgeButton = <
  T extends ComponentType = typeof Button,
>({
  employeeIds,
  disabled = false,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  const productsEnabled = useAppSelector(reduxSelectors.assets.productsEnabled);

  return (
    <>
      <Component
        {...componentProps}
        disabled={disabled}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <EmployeeAssetItemsPurgeNodeButton
          ids={employeeIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          All
        </EmployeeAssetItemsPurgeNodeButton>

        <EmployeeAssetItemsPurgeEventsButton
          ids={employeeIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Transactions
        </EmployeeAssetItemsPurgeEventsButton>

        {productsEnabled.includes('connect') && employeeIds.length ? (
          <EmployeeItemsPurgeChatEventsButton
            ids={employeeIds}
            component={MenuItem}
            onDone={() => {
              setMenuAnchorEl(undefined);
              onDone?.();
            }}
          >
            Chat
          </EmployeeItemsPurgeChatEventsButton>
        ) : null}
      </Menu>
    </>
  );
};

export default EmployeeAssetItemsPurgeButton;
