import {Box, Divider, Grid, Typography} from '@mui/material';

import AccessControl from '../../components/common/AccessControl';
import EventIcon from '../../components/EventIcon';
import {DashboardLayout} from '../../layouts/DashboardLayout';
import {EmployeeEmergencyResponse} from '../../utils/commtrac-nodes';
import {eventIconsLegacy} from '../../utils/event-icons';

export const AdminHelpPage = () => {
  return (
    <DashboardLayout>
      <AccessControl permissions={['get::/user']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box p={2} display="flex" flexDirection="column">
              <Grid container>
                <Grid item xs={9}>
                  <Typography
                    id="modal-modal-title"
                    component="h1"
                    variant="h5"
                    sx={{mb: 2}}
                  >
                    Administration - Help
                  </Typography>
                </Grid>
              </Grid>

              <Box display="flex" flexDirection="column" gap={2}>
                <Divider />
                <Box fontSize={20}>Nodes</Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.cnManyHeartbeat}</Box>
                  <Box>Communication Node Many Heartbeat</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.cnNoHeartbeat}</Box>
                  <Box>Communication Node No Heartbeat</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.cnFewHeartbeat}</Box>
                  <Box>Communication Node Few Heartbeat</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.wifiDisconnected}</Box>
                  <Box>WiFi Point Disconnected</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.cnUnregisteredNetwork}</Box>
                  <Box>Unregistered Network</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.cnAssignedMaster}</Box>
                  <Box>Assigned Master</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>
                    {eventIconsLegacy.nodes.cnConfigurationMessageFailed}
                  </Box>
                  <Box>Configuration Message Failed</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.cnAck}</Box>
                  <Box>ACK</Box>
                </Box>
                <Box display="flex" gap={2}>
                  <Box>{eventIconsLegacy.nodes.cnVersionChanged}</Box>
                  <Box>Node Version Changed</Box>
                </Box>
                <Divider />
                <Box fontSize={20}>Employees</Box>
                <Divider />
                <Box fontSize={20}>Assets</Box>
              </Box>

              <Box display="flex" flexDirection="column" gap={2}>
                <Divider />
                <Box fontSize={20}>Nodes</Box>
                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="communicationNodes.externalPower" />
                  </Box>
                  <Box>External Power</Box>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" gap={2}>
                <Divider />
                <Box fontSize={20}>Messages</Box>
                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="messages.nack" />
                  </Box>
                  <Box>NACK</Box>
                </Box>

                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="messages.read" />
                  </Box>
                  <Box>Read</Box>
                </Box>

                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="messages.ack" />
                  </Box>
                  <Box>ACK</Box>
                </Box>
              </Box>

              <Box display="flex" flexDirection="column" gap={2}>
                <Divider />
                <Box fontSize={20}>Emergency Broadcast Message Response</Box>
                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="messages.nack" />
                  </Box>
                  <Box>Not responded</Box>
                </Box>

                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="messages.emergency_response.ok" />
                  </Box>
                  <Box>{EmployeeEmergencyResponse.OK}</Box>
                </Box>

                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="messages.emergency_response.injured_but_ok" />
                  </Box>
                  <Box>{EmployeeEmergencyResponse.INJURED_BUT_OK}</Box>
                </Box>

                <Box display="flex" gap={2}>
                  <Box>
                    <EventIcon eventType="messages.emergency_response.injured_and_needs_help" />
                  </Box>
                  <Box>{EmployeeEmergencyResponse.INJURED_AND_NEEDS_HELP}</Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AccessControl>
    </DashboardLayout>
  );
};
