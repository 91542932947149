import {Checkbox, FormControlLabel, TextFieldProps} from '@mui/material';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'name'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: boolean;
  onChange?: (value?: boolean) => void;
};

export const ZoneChangeCheckbox = (props: Props) => {
  return (
    <FormControlLabel
      label={props.label ?? 'Zone Change Events'}
      sx={{whiteSpace: 'nowrap'}}
      control={
        <Checkbox
          checked={props.value}
          onChange={(event) => {
            props.onChange?.(event.target.checked);
          }}
        />
      }
    />
  )
};
