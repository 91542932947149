export interface TopEmployeeInteractionsData {
  refreshInterval?: number | null;
  params?: {
    zone_id?: number | null;
    date_start?: string | null;
    date_end?: string | null;
    human_type_report?: boolean | null;
    dir?: 'ASC' | 'DESC' | null;
    input?: {
      [key: number]: 0 | 1 | null | undefined;
    };
  };
  shifts?: {
    [key: number]: {
      shownFields?: string[];
      order?: string | null;
      dir?: 'asc' | 'desc' | null;
    };
  };
}

export const getTopEmployeeInteractionsData =
  (): TopEmployeeInteractionsData => ({
    params: {},
  });
