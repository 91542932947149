import {MenuItem, TextField, TextFieldProps} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {useAppSelector} from '../../hooks/redux';
import reduxSelectors from '../../redux/selectors';
import {PanelProductCode} from '../../utils/panels';

type Props = TextFieldProps & {
  value?: PanelProductCode | null;
};

const DashboardPanelProductSelect = (props: Props) => {
  const {t} = useTranslation();
  const options = useAppSelector(reduxSelectors.assets.productsEnabled);

  return (
    <TextField
      fullWidth
      label="Product"
      {...props}
      value={props.value ?? 'all'}
      select
      SelectProps={{
        MenuProps: {
          sx: {maxHeight: '300px'},
        },
      }}
      onChange={(event) => {
        event.target.value = (
          event.target.value === 'all' ? null : event.target.value
        ) as string;
        props.onChange?.(event);
      }}
    >
      <MenuItem value="all">All</MenuItem>

      {options.map((code) => (
        <MenuItem key={code} value={code}>
          {t(`products.${code}`)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default DashboardPanelProductSelect;
