export interface CompanySubMenu {
  name: string;
  page: string;
  depth: number;
  children: CompanySubMenu[];
  required_products?: (
    | 'connect_enabled'
    | 'proximity_enabled'
    | 'hazard_ai_enabled'
    | 'alarm_enabled'
  )[];
}

export const COMPANY_SUBMENU: CompanySubMenu[] = [
  {
    name: 'General',
    page: '',
    depth: 2,
    children: [
      {
        name: 'Company',
        page: '/admin/company-settings#company',
        depth: 4,
        children: [],
        required_products: [],
      },
      {
        name: 'Sections',
        page: '/admin/company-settings#sections',
        depth: 4,
        children: [],
        required_products: ['connect_enabled', 'proximity_enabled'],
      },
      {
        name: 'Sites',
        page: '/admin/company-settings#sites',
        depth: 4,
        children: [],
        required_products: ['hazard_ai_enabled'],
      },
      {
        name: 'System',
        page: '/admin/company-settings#system',
        depth: 4,
        children: [],
        required_products: [],
      },
    ],
  },
  {
    name: 'Employees',
    page: '',
    depth: 2,
    required_products: ['proximity_enabled'],
    children: [
      {
        name: 'Employees',
        page: '/admin/company-settings#employee',
        depth: 4,
        children: [],
        required_products: ['connect_enabled', 'proximity_enabled'],
      },
      {
        name: 'Employees Types',
        page: '/admin/company-settings#human-types',
        depth: 4,
        children: [],
        required_products: ['connect_enabled', 'proximity_enabled'],
      },
      {
        name: 'Employee Groups',
        page: '/admin/company-settings#groups',
        depth: 4,
        children: [],
        required_products: ['connect_enabled', 'proximity_enabled'],
      },
      {
        name: 'Shifts',
        page: '/admin/company-settings#shifts',
        depth: 4,
        children: [],
        required_products: ['connect_enabled', 'proximity_enabled'],
      },
      {
        name: 'Connect Devices',
        page: '/admin/company-settings#connect_devices',
        depth: 4,
        children: [],
        required_products: ['connect_enabled'],
      },
    ],
  },
  {
    name: 'Assets/Machines',
    page: '',
    depth: 2,
    children: [
      {
        name: 'Assets/Machines',
        page: '/admin/company-settings#machine',
        depth: 4,
        children: [],
        required_products: [
          'proximity_enabled',
          'hazard_ai_enabled',
          'connect_enabled',
        ],
      },
      {
        name: 'Asset/Machine Types',
        page: '/admin/company-settings#machine-types',
        depth: 4,
        children: [],
        required_products: [
          'proximity_enabled',
          'hazard_ai_enabled',
          'connect_enabled',
        ],
      },
      {
        name: 'Asset Inputs',
        page: '/admin/company-settings#machine-inputs',
        depth: 4,
        children: [],
        required_products: ['proximity_enabled'],
      },
      {
        name: 'Connect Devices',
        page: '/admin/company-settings#connect_devices',
        depth: 4,
        children: [],
        required_products: ['connect_enabled'],
      },
    ],
  },
  {
    name: 'Alarms',
    page: '',
    depth: 2,
    required_products: ['alarm_enabled'],
    children: [
      {
        name: 'Alarm Groups',
        page: '/admin/company-settings#alarm_groups',
        depth: 4,
        children: [],
        required_products: ['connect_enabled', 'proximity_enabled'],
      },
    ],
  },
];
