import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import SafeyeNodeItemsDevicesPurgeButton from './SafeyeNodeItemsDevicesPurgeButton';
import SafeyeNodeItemsTransactionsPurgeButton from './SafeyeNodeItemsTransactionsPurgeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  safeyeNodeIds?: number[];
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const SafeyeNodeItemsPurgeButton = <T extends ComponentType = typeof Button>({
  safeyeNodeIds,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;
  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <SafeyeNodeItemsDevicesPurgeButton
          safeyeNodeIds={safeyeNodeIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Devices
        </SafeyeNodeItemsDevicesPurgeButton>

        <SafeyeNodeItemsTransactionsPurgeButton
          component={MenuItem}
          safeyeNodeIds={safeyeNodeIds}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Transactions
        </SafeyeNodeItemsTransactionsPurgeButton>
      </Menu>
    </>
  );
};

export default SafeyeNodeItemsPurgeButton;
