import update from 'immutability-helper';
import _, {isEmpty} from 'lodash';
import {useMemo} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  AlarmLogTrackingReports,
  AlarmLogTrackingReportsData,
  getAlarmLogTrackingReportsData,
} from './AlarmLogTrackingReports';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}
export interface AlarmNodeTrackingReportsData {
  viewType: 'alarms';
  title: string;
  alarm_log: AlarmLogTrackingReportsData;
}

const getAlarmNodeTrackingReports = (): AlarmNodeTrackingReportsData => ({
  viewType: 'alarms',
  title: 'Alarm Log Tracking Reports',
  alarm_log: getAlarmLogTrackingReportsData(),
});

export const AlarmLogReports = ({value, onUpdate}: Props) => {
  const alarms = useAppSelector(({assets}) => assets.alarm_logs);
  const config = useMemo(() => {
    if (alarms?.length > 0) {
      let v = !isEmpty(value)
        ? (value as AlarmNodeTrackingReportsData)
        : getAlarmNodeTrackingReports();
      if (v?.alarm_log?.activeId) {
        const objectsToRemove = _.differenceBy(
          v?.alarm_log?.openedItems,
          alarms,
          'id'
        );
        if (objectsToRemove?.length > 0) {
          const openedItems = _.differenceBy(
            v?.alarm_log?.openedItems,
            objectsToRemove,
            'id'
          );
          const opentedItemIds = openedItems?.map((it) => it?.id);
          let activeId: number | undefined = v?.alarm_log?.activeId;
          if (!opentedItemIds?.includes(v?.alarm_log?.activeId)) {
            activeId =
              opentedItemIds?.length > 0 ? opentedItemIds[0] : undefined;
          }

          v = {
            ...v,
            alarm_log: {
              ...v.alarm_log,
              activeId: activeId,
              openedItems: openedItems,
            },
          };
        }
        return v;
      } else {
        return v;
      }
    } else {
      return getAlarmNodeTrackingReports();
    }
  }, [value, alarms]);

  // console.log(config.alarm_log)

  return (
    <AlarmLogTrackingReports
      value={config.alarm_log}
      onUpdate={(v) => {
        onUpdate?.(
          update(value ?? getAlarmNodeTrackingReports(), {
            alarm_log: {
              $set: v ?? getAlarmLogTrackingReportsData(),
            },
          })
        );
      }}
    />
  );
};
