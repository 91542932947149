import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useMemo} from 'react';

import {
  DashboardEntity,
  DashboardPanelData,
} from '../../../interfaces/Dashboard';
import {
  AMSEModuleCalibrationHistory,
  AMSEModuleCalibrationHistoryData,
  getAMSEModuleCalibrationHistoryData,
} from './AMSEModuleCalibrationHistory';

interface Props {
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value?: DashboardPanelData) => void;
}

export interface AMSEModuleNodeCalibrationHistoryData {
  viewType: 'emodule';
  title: string;
  amsEmoduleCalibration: AMSEModuleCalibrationHistoryData;
}

const getAMSEModuleCalibrationHistory =
  (): AMSEModuleNodeCalibrationHistoryData => ({
    viewType: 'emodule',
    title: 'eModule Calibration History',
    amsEmoduleCalibration: getAMSEModuleCalibrationHistoryData(),
  });

export const AMSEModuleCalibration = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as AMSEModuleNodeCalibrationHistoryData)
        : getAMSEModuleCalibrationHistory(),
    [value]
  );

  return (
    <AMSEModuleCalibrationHistory
      value={config.amsEmoduleCalibration}
      onUpdate={(v) => {
        onUpdate?.(
          update(value ?? getAMSEModuleCalibrationHistory(), {
            amsEmoduleCalibration: {
              $set: v ?? getAMSEModuleCalibrationHistoryData(),
            },
          })
        );
      }}
    />
  );
};
