import {useGetHasAccess} from '../components/common/AccessControl/utils';
import {useAppSelector} from '../hooks/redux';
import {panelCodesEnabledSelect} from '../redux/assets/selectors';
import {PANELS_DATA} from './panels';

export const usePanelCodes = () => {
  const panelCodesEnabled = useAppSelector(panelCodesEnabledSelect);
  const getHasAccess = useGetHasAccess();

  return panelCodesEnabled.filter((panelCode) => {
    const {permissions, roles, accessChecker} = PANELS_DATA[panelCode];
    return getHasAccess({permissions, roles, accessChecker});
  });
};
