/* eslint-disable complexity */
import _ from 'lodash';

interface CompanySettingSections {
  title: string;
  key?: string;
  items: string[];
}

interface FieldConfiguration {
  type: 'number' | 'string';
  options?: {
    value: string | number;
    label: string;
  }[];
}

const CompanySettingsFields = {
  controlRoomIsMonitored: 'global.control_room_is_monitored',
  passwordExpiresIn: 'global.password_expire_in',

  pruneHistoryText: 'prune-history.text',
  pruneHistoryLocation: 'prune-history.location',
  pruneHistoryCheckInOut: 'prune-history.check_in_out',
  pruneHistoryHeartbeat: 'prune-history.heartbeat',
  pruneHistoryProximity: 'prune-history.proximity',
  pruneHistoryEvents: 'prune-history.events',
  pruneHistoryAmsEvents: 'prune-history.ams-events',
  pruneHistoryAmsStatistic: 'prune-history.ams-statistic',
  pruneHistoryEnv: 'prune-history.env',
  pruneHistoryWifiStatistic: 'prune-history.wifi-statistic',
  pruneHistoryWifiEvents: 'prune-history.wifi-events',
  pruneHistoryBeltEnvironmentalHistory:
    'prune-history.belt_environmental_history',
  pruneHistoryVentilationFanEnvironmentalHistory:
    'prune-history.ventilation_fan_environmental_history',
  pruneHistoryVentilationFanControlHistory:
    'prune-history.ventilation_fan_control_history',

  smtpIsSmtp: 'smtp.is_smtp',
  smtpHost: 'smtp.host',
  smtpPort: 'smtp.port',
  smtpUsername: 'smtp.username',
  smtpPassword: 'smtp.password',
  smtpAuth: 'smtp.auth',
  smtpSsl: 'smtp.ssl',
  smtpFrom: 'smtp.from',
  smtpFromEmail: 'smtp.from_email',

  logMessages: 'log.messages',
  logMprocessor: 'log.mprocessor',
  logTcpserver: 'log.tcpserver',
  logMthread: 'log.mthread',
  logEvent: 'log.event',
  logMqttProximity: 'log.mqtt_proximity',

  logPruneDbLog: 'log.prune-db-log',
  logPruneDbCronlog: 'log.prune-db-cronlog',
};

const COMPANY_SETTINGS_FIELDS_BY_SECTIONS = {
  global: [
    CompanySettingsFields.controlRoomIsMonitored,
    CompanySettingsFields.passwordExpiresIn,
  ],
  pruneHistoryConnect: [
    CompanySettingsFields.pruneHistoryText,
    CompanySettingsFields.pruneHistoryLocation,
    CompanySettingsFields.pruneHistoryCheckInOut,
    CompanySettingsFields.pruneHistoryHeartbeat,
  ],
  pruneHistoryProximity: [CompanySettingsFields.pruneHistoryProximity],
  pruneHistoryGeneral: [CompanySettingsFields.pruneHistoryEvents],
  pruneHistoryAMS: [
    CompanySettingsFields.pruneHistoryAmsEvents,
    CompanySettingsFields.pruneHistoryAmsStatistic,
    CompanySettingsFields.pruneHistoryEnv,
  ],
  pruneHistoryWifi: [
    CompanySettingsFields.pruneHistoryWifiStatistic,
    CompanySettingsFields.pruneHistoryWifiEvents,
  ],
  pruneHistoryBelt: [
    CompanySettingsFields.pruneHistoryBeltEnvironmentalHistory,
  ],
  pruneHistoryVentilation: [
    CompanySettingsFields.pruneHistoryVentilationFanEnvironmentalHistory,
    CompanySettingsFields.pruneHistoryVentilationFanControlHistory,
  ],
  smtpSettings: [
    CompanySettingsFields.smtpIsSmtp,
    CompanySettingsFields.smtpHost,
    CompanySettingsFields.smtpPort,
    CompanySettingsFields.smtpUsername,
    CompanySettingsFields.smtpPassword,
    CompanySettingsFields.smtpAuth,
    CompanySettingsFields.smtpSsl,
    CompanySettingsFields.smtpFrom,
    CompanySettingsFields.smtpFromEmail,
  ],
  logSettings: [
    CompanySettingsFields.logMessages,
    CompanySettingsFields.logMprocessor,
    CompanySettingsFields.logTcpserver,
    CompanySettingsFields.logMthread,
    CompanySettingsFields.logEvent,
    CompanySettingsFields.logMqttProximity,
  ],
  pruneDBLogSettings: [
    CompanySettingsFields.logPruneDbLog,
    CompanySettingsFields.logPruneDbCronlog,
  ],
};

const fieldConfiguration = (
  fieldName: string
): FieldConfiguration | undefined => {
  switch (fieldName) {
    case CompanySettingsFields.passwordExpiresIn: {
      return {
        type: 'number',
        options: [
          {value: 30, label: '30'},
          {value: 60, label: '60'},
          {value: 90, label: '90'},
          {value: 120, label: '120'},
          {value: 180, label: '180'},
          {value: 360, label: '360'},
          {value: 0, label: 'Never'},
        ],
      };
    }
    case CompanySettingsFields.pruneHistoryText:
    case CompanySettingsFields.pruneHistoryLocation:
    case CompanySettingsFields.pruneHistoryCheckInOut:
    case CompanySettingsFields.pruneHistoryHeartbeat:
    case CompanySettingsFields.pruneHistoryProximity:
    case CompanySettingsFields.pruneHistoryEvents:
    case CompanySettingsFields.pruneHistoryAmsEvents:
    case CompanySettingsFields.pruneHistoryAmsStatistic:
    case CompanySettingsFields.pruneHistoryEnv:
    case CompanySettingsFields.pruneHistoryWifiStatistic:
    case CompanySettingsFields.pruneHistoryWifiEvents:
    case CompanySettingsFields.pruneHistoryBeltEnvironmentalHistory:
    case CompanySettingsFields.pruneHistoryVentilationFanEnvironmentalHistory:
    case CompanySettingsFields.pruneHistoryVentilationFanControlHistory:
    case CompanySettingsFields.logPruneDbLog:
    case CompanySettingsFields.logPruneDbCronlog:
    case CompanySettingsFields.smtpPort: {
      return {
        type: 'number',
      };
    }
    case CompanySettingsFields.logMessages:
    case CompanySettingsFields.logMprocessor:
    case CompanySettingsFields.logTcpserver:
    case CompanySettingsFields.logMthread:
    case CompanySettingsFields.logEvent:
    case CompanySettingsFields.logMqttProximity: {
      return {
        type: 'number',
        options: [
          {value: 0, label: 'Disabled'},
          {value: 1, label: 'Enabled'},
        ],
      };
    }
    case CompanySettingsFields.controlRoomIsMonitored:
    case CompanySettingsFields.smtpIsSmtp: {
      return {
        type: 'number',
        options: [
          {value: 0, label: 'No'},
          {value: 1, label: 'Yes'},
        ],
      };
    }
    case CompanySettingsFields.smtpHost:
    case CompanySettingsFields.smtpUsername:
    case CompanySettingsFields.smtpPassword:
    case CompanySettingsFields.smtpFrom:
    case CompanySettingsFields.smtpFromEmail: {
      return {
        type: 'string',
      };
    }
    case CompanySettingsFields.smtpAuth: {
      return {
        type: 'string',
        options: [
          {value: 'login', label: 'Login'},
          {value: 'noAuth', label: 'No Auth'},
        ],
      };
    }
    case CompanySettingsFields.smtpSsl: {
      return {
        type: 'string',
        options: [
          {value: 'tls', label: 'TLS'},
          {value: 'ssl', label: 'SSL'},
          {value: 'none', label: 'None'},
        ],
      };
    }
  }
};

const COMPANY_PRUNE_HISTORY_SECTIONS = [
  {
    title: 'General',
    key: 'core',
    products: ['core'],
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneHistoryGeneral,
  },
  {
    title: 'Connect',
    key: 'connect',
    products: ['connect'],
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneHistoryConnect,
  },
  {
    title: 'Proximity',
    key: 'proximity',
    products: ['proximity'],
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneHistoryProximity,
  },
  {
    title: 'AMS',
    key: 'ams',
    products: ['ams'],
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneHistoryAMS,
  },
  {
    title: 'Wifi',
    key: 'wifi',
    products: ['connect'],
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneHistoryWifi,
  },
  {
    title: 'Belt',
    key: 'belts',
    products: ['belts'],
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneHistoryBelt,
  },
  {
    title: 'Ventilation',
    key: 'ventilation',
    products: ['ventilation'],
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneHistoryVentilation,
  },
] as const;

const COMPANY_SETTINGS_SECTIONS: CompanySettingSections[] = [
  {title: 'Global', items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.global},
  {
    title: 'SMTP Settings',
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.smtpSettings,
  },
  {
    title: 'Log Settings',
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.logSettings,
  },
  {
    title: 'Prune DB Log Settings',
    items: COMPANY_SETTINGS_FIELDS_BY_SECTIONS.pruneDBLogSettings,
  },
];

const SETTING_FIELDS = _.flatMap(COMPANY_SETTINGS_FIELDS_BY_SECTIONS);

export {
  COMPANY_PRUNE_HISTORY_SECTIONS,
  COMPANY_SETTINGS_SECTIONS,
  fieldConfiguration,
  SETTING_FIELDS,
};
