import * as yup from 'yup';

import {getMinMessage} from './consts';

export const communicationNodeBaseInputSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required').min(1).max(50),
  status: yup.string().nullable().required('Field is required'),
  zone_id: yup.number().nullable().required('Field is required'),
  pos_lat: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-90)
    .max(90),
  pos_long: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
});

export const communicationNodeCreateInputSchema =
  communicationNodeBaseInputSchema.shape({
    node_type: yup.number().nullable().required('Field is required'),
    external_id: yup
      .number()
      .integer()
      .min(1, getMinMessage(1))
      .required('Field is required'),
  });

export const communicationNodeUpdateInputSchema =
  communicationNodeBaseInputSchema.shape({
    external_id: yup
      .number()
      .integer()
      .min(1, getMinMessage(1))
      .required('Field is required'),
  });

export const communicationNodeAckInputSchema =
  communicationNodeBaseInputSchema.shape({
    node_type: yup.number().nullable().required('Field is required'),
  });
