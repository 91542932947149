import CableIcon from '@mui/icons-material/Cable';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  AdminCommtracNodeListQuery,
  AdminCommtracNodeListResponse,
  CommtracNode,
} from '../../interfaces/CommtracNode';
import reduxSelectors from '../../redux/selectors';
import {getHumanReadable} from '../../utils/macAddress';
import AccessControl from '../common/AccessControl';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
import CommtracNodeItemAssetReassignButton from './CommtracNodeItemAssetReassignButton';
import CommtracNodeItemMinerReassignButton from './CommtracNodeItemMinerReassignButton';

interface Props {}

export interface CommtracNodeListRef {
  fetch?: () => void;
  dataGridRef: MutableRefObject<DataGridRef | null>;
}

const CommtracNodeList = forwardRef<CommtracNodeListRef, Props>((__, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
    dataGridRef,
  }));

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const paperBg = isDarkMode ? '#222222' : '#FFF';
  const productsEnabled = useAppSelector(reduxSelectors.assets.productsEnabled);

  const statusOptions: {
    value: AdminCommtracNodeListQuery['status'];
    label: string;
  }[] = [
    {value: 'all', label: 'All'},
    {value: 'active', label: 'Active'},
    {value: 'inactive', label: 'Inactive'},
  ];

  const typeOptions: {
    value: AdminCommtracNodeListQuery['type'];
    label: string;
  }[] = [
    {value: 'all', label: 'All'},
    {value: 'miner', label: 'Employee'},
    {value: 'asset', label: 'Asset'},
  ];

  const assignmentOptions: {
    value: AdminCommtracNodeListQuery['assignment'];
    label: string;
  }[] = [
    {value: 'all', label: 'All'},
    {value: 'unassigned', label: 'Unassigned'},
  ];

  const acknowledgeOptions: {
    value: AdminCommtracNodeListQuery['acknowledge'];
    label: string;
  }[] = [
    {value: 'all', label: 'All'},
    {value: 'acknowledged', label: 'Acknowledged'},
    {value: 'unacknowledged', label: 'Unacknowledged'},
  ];

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<AdminCommtracNodeListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  //
  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );

  const fetchData = async (params: AdminCommtracNodeListQuery) => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<AdminCommtracNodeListResponse>(
        `${apiBaseUrl}/admin-commtrac-node`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<CommtracNode>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Edit',
      sxHeader: {textAlign: 'right'},
      sxCell: {textAlign: 'right'},
      renderCell: ({row}) => {
        return (
          <Box display="flex" gap={1} justifyContent="end">
            {row.commtrac_ack === '1' && row.type === 'miner' ? (
              <AccessControl
                permissions={['patch::/commtrac-node/:id(\\d+)/miner/reassign']}
              >
                {' '}
                <CommtracNodeItemMinerReassignButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'warning',
                  }}
                  onSubmitted={() => {
                    fetchData(formik.values);
                  }}
                >
                  <CableIcon />
                </CommtracNodeItemMinerReassignButton>
              </AccessControl>
            ) : null}
            {row.commtrac_ack === '1' && row.type === 'asset' ? (
              <AccessControl
                permissions={['patch::/commtrac-node/:id(\\d+)/asset/reassign']}
              >
                {' '}
                <CommtracNodeItemAssetReassignButton
                  item={row}
                  component={IconButton}
                  componentProps={{
                    color: 'warning',
                  }}
                  onSubmitted={() => {
                    fetchData(formik.values);
                  }}
                >
                  <CableIcon />
                </CommtracNodeItemAssetReassignButton>
              </AccessControl>
            ) : null}
          </Box>
        );
      },
    },
    {
      field: 'name',
      sortable: true,
    },
    {
      field: 'status',
      sortable: true,
      renderCell: ({row}) => {
        return row.status === 'active' ? (
          <ToggleOnIcon color="success" />
        ) : (
          <ToggleOffIcon color="error" />
        );
      },
    },
    {
      field: 'type',
      sortable: true,
      valueGetter: ({row}) => {
        return row.type === 'miner' ? 'Employee' : 'Asset';
      },
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => {
        if (
          row.type === 'miner' &&
          row.commtrac_external_id &&
          minerAddressMask
        ) {
          // eslint-disable-next-line no-bitwise
          return row.commtrac_external_id & minerAddressMask;
        }

        return row.commtrac_external_id;
      },
    },
    {
      field: 'mac_address',
      headerName: 'MAC-address',
      sortable: true,
      hidden: !productsEnabled.includes('connect'),
      valueGetter: ({row}) => {
        if (row.mac_address) {
          return getHumanReadable(row.mac_address);
        }
      },
    },
    {
      field: 'wifi_enabled',
      headerName: 'WIFI enabled',
      sortable: true,
      renderCell: ({row}) => {
        return row.wifi_enabled ? (
          <ToggleOnIcon color="success" />
        ) : (
          <ToggleOffIcon color="error" />
        );
      },
    },
  ];

  const formik = useFormik<AdminCommtracNodeListQuery>({
    initialValues: {
      page: 0,
      limit: 25,
      order: 'id',
      dir: 'ASC',
      status: 'all',
      type: 'all',
      assignment: 'all',
      acknowledge: 'all',
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  return (
    <Paper
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        p: 3,
        bgcolor: paperBg,
        backgroundImage: 'none',
      }}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" height="100%">
        <Box pb={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="left"
            height={40}
            width={200}
            gap={2}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height={20}
              gap={2}
            >
              <Box flexGrow={1} display="flex" width="200px" height="100%">
                <TextField
                  fullWidth
                  label="Status"
                  select
                  SelectProps={{
                    multiple: false,
                  }}
                  value={formik.values.status}
                  onChange={(el) => {
                    console.log(el.target.value);
                    formik.setFieldValue('status', el.target.value);

                    if (el.target.value === 'all') {
                      formik.setFieldValue('filter', null);
                    } else {
                      formik.setFieldValue(
                        'filter',
                        JSON.stringify({
                          type: 'and',
                          filter: [
                            {
                              field: 'status',
                              op: 'in',
                              val: [el.target.value],
                            },
                          ],
                        })
                      );
                    }
                  }}
                >
                  {statusOptions.map((option) => (
                    <MenuItem
                      key={option.value ?? ''}
                      value={option.value ?? ''}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box flexGrow={1} display="flex" width="200px" height="100%">
                <TextField
                  fullWidth
                  label="Type"
                  select
                  SelectProps={{
                    multiple: false,
                  }}
                  value={formik.values.type}
                  onChange={(el) => {
                    formik.setFieldValue('type', el.target.value);

                    if (el.target.value === 'all') {
                      formik.setFieldValue('filter', null);
                    } else {
                      formik.setFieldValue(
                        'filter',
                        JSON.stringify({
                          type: 'and',
                          filter: [
                            {
                              field: 'type',
                              op: 'in',
                              val: [el.target.value],
                            },
                          ],
                        })
                      );
                    }
                  }}
                >
                  {typeOptions.map((option) => (
                    <MenuItem
                      key={option.value ?? ''}
                      value={option.value ?? ''}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box flexGrow={1} display="flex" width="200px" height="100%">
                <TextField
                  fullWidth
                  label="Assignment"
                  select
                  SelectProps={{
                    multiple: false,
                  }}
                  value={formik.values.assignment}
                  onChange={(el) => {
                    formik.setFieldValue('assignment', el.target.value);
                  }}
                >
                  {assignmentOptions.map((option) => (
                    <MenuItem
                      key={option.value ?? ''}
                      value={option.value ?? ''}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box flexGrow={1} display="flex" width="200px" height="100%">
                <TextField
                  fullWidth
                  label="Acknowledge"
                  select
                  SelectProps={{
                    multiple: false,
                  }}
                  value={formik.values.acknowledge}
                  onChange={(el) => {
                    formik.setFieldValue('acknowledge', el.target.value);
                  }}
                >
                  {acknowledgeOptions.map((option) => (
                    <MenuItem
                      key={option.value ?? ''}
                      value={option.value ?? ''}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
          </Box>
        </Box>
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          pagination
          paginationMode="server"
          size="small"
          sx={{
            backgroundColor: 'transparent',
            th: {
              bgcolor: paperBg,
            },
          }}
          sortBy={{
            field: formik.values.order,
            dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
          }}
          sortingMode="server"
          page={formik.values.page}
          pageSize={formik.values.limit}
          rowCount={fetchedData?.count}
          onPageChange={(v) => formik.setFieldValue('page', v)}
          onPageSizeChange={(v) => {
            formik.setFieldValue('limit', v);
            formik.setFieldValue('page', 0);
          }}
          onSort={(v) => {
            if (v) {
              formik.setFieldValue('order', v.field);
              formik.setFieldValue('dir', v.dir.toUpperCase());
            }
          }}
        />
      </Box>
    </Paper>
  );
});

export default CommtracNodeList;
