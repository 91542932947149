import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {SketchPicker} from 'react-color';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {CONFIGURATION_SCOPES} from '../../constants/settings';
import {useAppDispatch} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {COLORED_ICONS} from '../../utils/icons';
import {tweakLabelForMiner} from '../../utils/macAddress';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import {ColorSelect} from '../selectors/ColorSelect';
import {SoundSelect} from '../selectors/SoundSelect';

interface ConfigurationCommtracValues {
  [key: string]: string | number | [number, string][] | null;
}

const CONFIGURATION_SECTIONS = [
  'hazard-ai',
  'hazard-ai-color',
  'hazard-ai-sound',
  'hazard-ai-heatmap',
];

const FIELDS_BY_SECTIONS = {
  communicationStatus: ['hazard-ai.hazard_ai_no_heartbeat_period'],
  autoRefresh: ['hazard-ai.hazard_ai_autorefresh_rate'],

  soundGlobal: [
    'hazard-ai.sound_new_event',
    'hazard-ai.hazard_ai_sound_interval',
  ],

  eventSound: [
    'hazard-ai-sound.am_sound_801',
    'hazard-ai-sound.am_sound_802',
    'hazard-ai-sound.am_sound_803',
    'hazard-ai-sound.am_sound_804',
    'hazard-ai-sound.am_sound_805',
    'hazard-ai-sound.am_sound_806',
  ],

  eventColor: [
    'hazard-ai-color.ha_color_801',
    'hazard-ai-color.ha_color_802',
    'hazard-ai-color.ha_color_803',
    'hazard-ai-color.ha_color_804',
    'hazard-ai-color.ha_color_805',
    'hazard-ai-color.ha_color_806',
  ],

  heatmapSettings: [
    'hazard-ai-heatmap.density_radius',
    'hazard-ai-heatmap.blur',
    'hazard-ai-heatmap.radius',
    'hazard-ai-heatmap.max',
    'hazard-ai-heatmap.color_gradients',
  ],
};

const FIELDS = _.flatMap(FIELDS_BY_SECTIONS);

const getFormikValues = (
  item?: ConfigurationResponse
): ConfigurationCommtracValues => ({
  ..._.chain(FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse
): ConfigurationCommtracValues => ({
  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

export interface AdminHazardAIConfigProps {
  disabled?: boolean;
  scope?: CONFIGURATION_SCOPES;
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface AdminHazardAIConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const AdminHazardAIConfig = forwardRef<
  AdminHazardAIConfigRef,
  React.PropsWithChildren<AdminHazardAIConfigProps>
>(
  (
    {scope, disabled, onChangeSubmittedInProgress, onChangeResetInProgress},
    ref
  ) => {
    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();

    const apiUrl = `${apiBaseUrl}/${scope === CONFIGURATION_SCOPES.USER ? 'user-' : ''}configuration`;

    /*******/
    /* ref */
    /*******/
    useImperativeHandle(ref, () => ({
      fetch: () => fetchData(),
      submit: () => formik.handleSubmit(),
      reset: () => handleResetData(),
      clear: () => formik.setValues(getFormikValuesClear(fetchedData)),
    }));

    const reduxDispatch = useAppDispatch();

    /*********/
    /* fetch */
    /*********/
    const [fetchedData, setFetchedData] = useState<ConfigurationResponse>();
    const [fetchedErrors, setFetchedErrors] = useState<string[]>();
    const [fetchedInProgress, setFetchedInProgress] = useState(false);

    const fetchData = async () => {
      setFetchedInProgress(true);

      try {
        const params = {section: JSON.stringify(CONFIGURATION_SECTIONS)};
        const resp = await API.get<ConfigurationResponse>(apiUrl, {params});
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    };

    useEffect(() => {
      fetchData();
      return () => {
        setFetchedData(undefined);
        setFetchedErrors(undefined);
      };
    }, []);

    /**********/
    /* submit */
    /**********/

    const [submittedInProgress, setSubmittedInProgress] = useState(false);

    const submitData = async (data: ConfigurationInput) => {
      setSubmittedInProgress(true);
      try {
        const resp = await API.post<ConfigurationResponse>(apiUrl, data);
        setFetchedData(resp.data);
        reduxDispatch(reduxActions.app.fetchMyConfigurations);
        enqueueSnackbar('Configuration successfully updated', {
          variant: 'success',
          action: CloseSnackbarAction,
        });
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        enqueueSnackbar(<SnackbarMessages messages={messages} />, {
          variant: 'error',
          action: CloseSnackbarAction,
        });
      }
      setSubmittedInProgress(false);
    };

    /**********/
    /* reset */
    /**********/

    const [resetInProgress, setResetInProgress] = useState(false);

    const resetData = async (data: ConfigurationInput) => {
      setResetInProgress(true);

      try {
        const resp = await API.patch<ConfigurationResponse>(apiUrl, data);
        setFetchedData(resp.data);
        reduxDispatch(reduxActions.app.fetchMyConfigurations);
        enqueueSnackbar('Configuration successfully reset', {
          variant: 'success',
          action: CloseSnackbarAction,
        });
      } catch (error: any) {
        const message = error?.response?.data?.message ?? 'There is an error';
        enqueueSnackbar(message, {
          variant: 'error',
          action: CloseSnackbarAction,
        });
      }
      setResetInProgress(false);
    };

    const handleResetData = async () => {
      const input: ConfigurationInput = {
        section: CONFIGURATION_SECTIONS,
        configuration: _.map(formik.values, (value, key) => ({
          section: key.split('.')[0],
          name: key.split('.')[1],
          value,
        })),
      };
      await resetData(input);
    };

    /*********/
    /* input */
    /*********/
    const inputValidationSchema = yup.object();

    const formik = useFormik({
      initialValues: getFormikValues(fetchedData),
      validationSchema: inputValidationSchema,
      onSubmit: async (values) => {
        const input: ConfigurationInput = {
          section: CONFIGURATION_SECTIONS,
          configuration: _.map(values, (value, key) => ({
            section: key.split('.')[0],
            name: key.split('.')[1],
            value,
          })),
        };
        await submitData(input);
      },
    });

    useEffect(() => {
      const newInput = getFormikValues(fetchedData);
      if (!_.isEqual(newInput, formik.values)) {
        formik.setValues(newInput);
      }
    }, [fetchedData]);

    /************/
    /* keysData */
    /************/
    const keysData = useMemo(
      () => ({
        ..._.chain(fetchedData)
          .keyBy((i) => `${i.section}.${i.name}`)
          .mapValues()
          .value(),
      }),
      [fetchedData]
    );

    /************/
    /* colors   */
    /************/
    const [colorPickerIndex, setColorPickerIndex] = useState<number>(-1);
    const [colors, setColors] = useState<[number, string][]>([]);

    useEffect(() => {
      setColors(
        (keysData?.['hazard-ai-heatmap.color_gradients']?.value as unknown as [
          number,
          string,
        ][]) ?? []
      );
    }, [keysData]);

    const handleColorChange = (
      intensity: number,
      color: string,
      index: number
    ) => {
      colors[index] = [intensity, color];
      handleColorsChange(colors);
    };

    const handleColorsChange = (colors: [number, string][]) => {
      setColors(colors);
      formik.setValues({
        ...formik.values,
        'hazard-ai-heatmap.color_gradients': colors,
      });
    };

    // other
    useEffect(() => {
      onChangeSubmittedInProgress?.(submittedInProgress);
    }, [submittedInProgress]);

    useEffect(() => {
      onChangeResetInProgress?.(resetInProgress);
    }, [resetInProgress]);

    const calcColorPickerBackdropHeight = () => {
      const innerBox = document.querySelector(
        '.MuiModal-root > .MuiBox-root > .MuiBox-root'
      );
      return !innerBox ? 'auto' : `${innerBox.clientHeight + 48}px`;
    };

    return (
      <>
        <Box position="relative">
          <Backdrop open={fetchedInProgress}>
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors?.map((error, index) => (
            <Alert key={index} severity="error" sx={{mb: 2}}>
              {error}
            </Alert>
          ))}

          <Grid container spacing={4}>
            <Grid item xs={12} lg={6} xl={6}>
              <Paper sx={{p: 3, mb: 3}}>
                <Box fontSize={20} lineHeight={1.6} mb={3}>
                  Auto Refresh Settings
                </Box>

                <Grid container spacing={4}>
                  {FIELDS_BY_SECTIONS.autoRefresh.map((name) => {
                    return (
                      <Grid key={name} item xs={12} lg={6}>
                        <TextField
                          fullWidth
                          label={tweakLabelForMiner(t(keysData[name]?.label))}
                          value={formik.values[name] ?? 'None'}
                          name={name}
                          size="small"
                          type="number"
                          select
                          disabled={disabled}
                          onChange={(event) => {
                            const value =
                              event.target.value === 'None'
                                ? null
                                : event.target.value;
                            formik.setValues({...formik.values, [name]: value});
                          }}
                        >
                          {['None', 30, 60, 120].map((i) => (
                            <MenuItem key={i ?? ''} value={i ?? ''}>
                              {i ?? 'None'}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6} xl={6}>
              <Paper sx={{p: 3, mb: 3}}>
                <Box fontSize={20} lineHeight={1.6} mb={3}>
                  Communication Status
                </Box>

                <Grid container spacing={4}>
                  {FIELDS_BY_SECTIONS.communicationStatus.map((name) => {
                    return (
                      <Grid key={name} item xs={12} lg={6}>
                        <Box key={name}>
                          <TextField
                            fullWidth
                            label={t(keysData[name]?.label)}
                            value={formik.values[name] ?? ''}
                            name={name}
                            type="number"
                            size="small"
                            disabled={disabled}
                            onChange={(event) =>
                              formik.setValues({
                                ...formik.values,
                                [name]: event.target.value,
                              })
                            }
                          />
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid container spacing={4}>
            <Grid item xs={12} lg={6} xl={6}>
              <Paper sx={{p: 3}}>
                <Box fontSize={20} lineHeight={1.6} mb={3}>
                  Default Event Sounds
                </Box>

                <Box mb={3}>
                  <Box fontSize={16} lineHeight={1.6} mb={3}>
                    Global
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      {FIELDS_BY_SECTIONS.soundGlobal
                        .slice(0, 1)
                        .map((name) => (
                          <Box key={name} mb={2}>
                            <SoundSelect
                              fullWidth
                              label={tweakLabelForMiner(
                                t(keysData[name]?.label)
                              )}
                              value={formik.values[name] ?? ''}
                              name={name}
                              size="small"
                              disabled={disabled}
                              onChange={(event) =>
                                formik.setValues({
                                  ...formik.values,
                                  [name]: event.target.value,
                                })
                              }
                            />
                          </Box>
                        ))}
                    </Grid>

                    <Grid item xs={6}>
                      {FIELDS_BY_SECTIONS.soundGlobal.slice(1).map((name) => (
                        <Box key={name} mb={2}>
                          <TextField
                            fullWidth
                            label={t(keysData[name]?.label)}
                            value={formik.values[name] ?? 'None'}
                            name={name}
                            type="number"
                            size="small"
                            select
                            disabled={disabled}
                            onChange={(event) => {
                              const value =
                                event.target.value === 'None'
                                  ? null
                                  : event.target.value;
                              formik.setValues({
                                ...formik.values,
                                [name]: value,
                              });
                            }}
                          >
                            {['None', 10, 15, 30, 60, 120].map((i) => (
                              <MenuItem key={i ?? ''} value={i ?? ''}>
                                {i ?? 'None'}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Box>
                      ))}
                    </Grid>
                  </Grid>
                </Box>

                <Box>
                  <Box fontSize={16} lineHeight={1.6} mb={3}>
                    Safeye Nodes
                  </Box>

                  <Grid container spacing={3}>
                    {FIELDS_BY_SECTIONS.eventSound.map((name) => (
                      <Grid key={name} item xs={6}>
                        <SoundSelect
                          fullWidth
                          label={tweakLabelForMiner(t(keysData[name]?.label))}
                          value={formik.values[name] ?? ''}
                          name={name}
                          size="small"
                          disabled={disabled}
                          onChange={(event) =>
                            formik.setValues({
                              ...formik.values,
                              [name]: event.target.value,
                            })
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Paper>
            </Grid>

            <Grid item xs={12} lg={6} xl={6}>
              <Paper sx={{p: 3, mb: 3}}>
                <Box fontSize={20} lineHeight={1.6} mb={3}>
                  Default Event Colors
                </Box>

                <Box>
                  <Grid container spacing={3}>
                    {FIELDS_BY_SECTIONS.eventColor.map((name) => (
                      <Grid key={name} item xs={6}>
                        <ColorSelect
                          fullWidth
                          label={t(keysData[name]?.label)}
                          value={formik.values[name] ?? ''}
                          name={name}
                          size="small"
                          disabled={disabled}
                          icons={COLORED_ICONS.communicationNodes}
                          onChange={(event) =>
                            formik.setValues({
                              ...formik.values,
                              [name]: event.target.value,
                            })
                          }
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Paper>

              <Paper sx={{p: 3}}>
                <Box fontSize={20} lineHeight={1.6} mb={3}>
                  Default Heatmap Settings
                </Box>

                <Box mb={3}>
                  <Grid container spacing={3}>
                    {FIELDS_BY_SECTIONS.heatmapSettings
                      .filter(
                        (name) => name !== 'hazard-ai-heatmap.color_gradients'
                      )
                      .map((name) => {
                        return (
                          <Grid key={name} item xs={6}>
                            <NumberTextField
                              fullWidth
                              label={t(keysData[name]?.label)}
                              value={Number(
                                ((formik.values[name] as number) ?? 0).toFixed(
                                  name === 'hazard-ai-heatmap.max' ? 3 : 0
                                )
                              )}
                              min={0}
                              step={
                                name === 'hazard-ai-heatmap.max' ? 0.001 : 1
                              }
                              decimalPlaces={
                                name === 'hazard-ai-heatmap.max' ? 3 : 0
                              }
                              size="small"
                              onChange={(value) => {
                                formik.setValues({
                                  ...formik.values,
                                  [name]: value,
                                });
                              }}
                            />
                          </Grid>
                        );
                      })}
                  </Grid>
                </Box>
                <Divider />
                <Box mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={1} />
                    <Grid item xs={5}>
                      Intensity
                    </Grid>
                    <Grid item xs={6}>
                      Color
                    </Grid>
                    {colors?.map((intCol: [number, string], index) => (
                      <>
                        <Grid item xs={1}>
                          <IconButton
                            size="small"
                            sx={{height: 40, width: 40}}
                            onClick={() =>
                              handleColorsChange(
                                colors.filter((c) => c !== intCol)
                              )
                            }
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Grid>
                        <Grid item xs={5}>
                          <NumberTextField
                            fullWidth
                            size="small"
                            max={1}
                            min={0}
                            step={0.1}
                            value={Number(intCol[0].toFixed(1))}
                            onChange={(v) =>
                              handleColorChange(v as number, intCol[1], index)
                            }
                          />
                        </Grid>
                        <Grid item xs={6} position="relative">
                          <Box
                            width={36}
                            height={36}
                            borderRadius={18}
                            onClick={() => setColorPickerIndex(index)}
                            sx={{
                              backgroundColor: intCol[1],
                              cursor: 'pointer',
                            }}
                          />
                          {colorPickerIndex === index && (
                            <>
                              <Backdrop
                                open
                                onClick={() => setColorPickerIndex(-1)}
                                sx={{
                                  height: calcColorPickerBackdropHeight(),
                                  backgroundColor: 'transparent',
                                  zIndex: 1,
                                }}
                              />
                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: '45px',
                                  zIndex: 2,
                                }}
                              >
                                <SketchPicker
                                  color={intCol[1]}
                                  onChangeComplete={(color) =>
                                    handleColorChange(
                                      intCol[0],
                                      color.hex,
                                      index
                                    )
                                  }
                                />
                              </Box>
                            </>
                          )}
                        </Grid>
                      </>
                    ))}
                    <Grid item xs={12}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          handleColorsChange([...colors, [1, '#f00']])
                        }
                      >
                        <AddIcon />
                        Add New Row
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
);
