import {Alert, AlertProps} from '@mui/material';

interface Props extends AlertProps {}

const AlertFullSize = (props: Props) => {
  return (
    <Alert
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      {...props}
    />
  );
};

export default AlertFullSize;
