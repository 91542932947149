import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  TextField,
  useTheme,
} from '@mui/material';
import {Box} from '@mui/system';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import Plot from 'react-plotly.js';
import {useSelector} from 'react-redux';
import {useComponentSize} from 'react-use-size';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {
  MachineInteractionGanttResponse,
  MachineInteractionQuery,
} from '../../../interfaces/MachineInteraction';
import {activeMachineInputs} from '../../../redux/assets/selectors';
import AssetMachineSelect from '../../asset-machine/AssetMachineSelect';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {usePanelFilter} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {MachineInteractionReportData} from './data';

interface Props {
  value?: MachineInteractionReportData;
  onUpdate?: (value: MachineInteractionReportData) => void;
  onPrint?: () => void;
}

const MachineInteractionReportGantt = ({
  value,
  onUpdate,
  onPrint = () => {},
}: Props) => {
  const machineInputs = useSelector(activeMachineInputs);

  const {filter: config, setFilter: setConfig} = usePanelFilter();

  const assetHumans = useAppSelector(({assets}) =>
    assets.asset_humans.filter((i) => i.status === 'active' && i.external_id)
  );
  const assetMachines = useAppSelector(({assets}) =>
    assets.asset_machines.filter((i) => i.status === 'active' && i.external_id)
  );
  const printTheme = useAppSelector(({app}) => app.printTheme);

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<MachineInteractionGanttResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: MachineInteractionQuery) => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<MachineInteractionGanttResponse>(
        `${apiBaseUrl}/report/machine-interaction-gantt`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  const params = useMemo<MachineInteractionQuery>(() => {
    const inputsOn = Object.keys(config.params?.input || {})
      .map((id) => Number(id))
      .filter((id) => config.params?.input?.[id] === 1);
    const inputsOff = Object.keys(config.params?.input || {})
      .map((id) => Number(id))
      .filter((id) => config.params?.input?.[id] === 0);
    let inputs: number[] = [];
    if (inputsOff.length > 0) {
      inputs = machineInputs
        .map((input) => input.id)
        .filter((v) => !inputsOff.includes(v));
    }
    if (inputsOn.length > 0) {
      inputs = inputsOn;
    }

    return {
      date_start:
        config.params?.date_start ??
        value?.params?.date_start ??
        dayjs().format('YYYY-MM-DD'),
      date_end:
        config.params?.date_end ??
        value?.params?.date_end ??
        dayjs().format('YYYY-MM-DD'),
      machines: config.params?.machines ?? value?.params?.machines ?? [],
      humans: config.params?.humans ?? value?.params?.humans ?? [],
      section_id:
        config.params?.section_id ?? value?.params?.section_id ?? null,
      exclude_miners:
        config.params?.exclude_miners ?? value?.params?.exclude_miners ?? 1,
      input: config.params?.input ?? value?.params?.input ?? {},
      inputs,
    };
  }, [config]);

  const selectedAssetMachine = useMemo(
    () =>
      params.machines
        ? assetMachines.find(
            (i) => i.external_id && params.machines.includes(i.external_id)
          )
        : null,
    [params.machines]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
      onUpdate?.({...config, params});
    }
  }, [params]);

  // type 1
  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };
  const {ref, height, width} = useComponentSize();
  const theme = useTheme();

  const fetchedEvents = useMemo(
    () =>
      fetchedData?.X.reduce((result: any, item: any) => {
        const event = _.startCase(item[fetchedData.X_DECODE.EVENT]);

        result[event] = result[event] || {
          event,
          color:
            event === 'Hazard'
              ? 'red'
              : event === 'Warning'
                ? 'yellow'
                : 'purple',
          // CHART_COLORS[_.size(result)],
        };
        return result;
      }, {}),
    [fetchedData]
  );

  const fetchedTasks = useMemo(
    () =>
      fetchedData?.Y.reduce((result: any, item: any) => {
        const itemObj = _.mapValues(fetchedData.Y_DECODE, (i) => item[i]);
        result[itemObj.CATEGORY] = itemObj;
        return result;
      }, {}),
    [fetchedData]
  );

  const fetchedEventItems = useMemo(
    () =>
      fetchedData?.X.map((item: any) => {
        const event = _.startCase(item[fetchedData.X_DECODE.EVENT]);
        const startTime = item[fetchedData.X_DECODE.START_TIME];
        const endTime = item[fetchedData.X_DECODE.END_TIME];
        const category = item[fetchedData.X_DECODE.CATEGORY];

        const categoryName =
          fetchedTasks[category].NAME ??
          `${_.capitalize(fetchedTasks[category].EVENT_TYPE)} ${fetchedTasks[category].EXTERNAL_ID}`;
        console.log(event);
        return {
          event,
          x: [
            dayjs(startTime / 1000)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs(endTime / 1000)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
          y: [categoryName, categoryName],
          color: fetchedEvents[event]?.color,
        };
      }).filter((i: any) => !hiddenKeys.includes(`${i.event}`)),
    [fetchedData, hiddenKeys]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <Box display={printTheme ? 'none' : 'flex'} flexDirection="column" px={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(params.date_start).toDate(),
                  dayjs(params.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      params: {
                        date_start: {
                          $set: dayjs(v[0] ?? undefined).format('YYYY-MM-DD'),
                        },
                        date_end: {
                          $set: dayjs(v[1] ?? undefined).format('YYYY-MM-DD'),
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <AssetMachineSelect
              value={selectedAssetMachine?.id}
              assetMachines={assetMachines}
              size="small"
              label="Machine"
              fullWidth
              nullLabel="All Machines"
              sx={{minWidth: 200}}
              onChange={(_, item) => {
                setConfig(
                  update(config ?? {}, {
                    params: {
                      machines: {
                        $set: item?.external_id ? [item?.external_id] : [],
                      },
                    },
                  })
                );
              }}
            />

            <TextField
              value={
                params.exclude_miners ? 'exclude' : params.humans?.[0] ?? -1
              }
              name="human_external_id"
              select
              size="small"
              fullWidth
              label="Employee"
              sx={{minWidth: 200}}
              onChange={(e) => {
                const v = e.target.value as any;
                if (v === 'exclude') {
                  setConfig(
                    update(config, {
                      params: {
                        exclude_miners: {
                          $set: 1,
                        },
                        humans: {
                          $set: [],
                        },
                      },
                    })
                  );
                } else {
                  setConfig(
                    update(config, {
                      params: {
                        exclude_miners: {
                          $set: 0,
                        },
                        humans: {
                          $set: v === -1 ? [] : [v],
                        },
                      },
                    })
                  );
                }
              }}
            >
              <MenuItem key="exclude" value="exclude">
                No Humans
              </MenuItem>
              <MenuItem key={-1} value={-1}>
                All Humans
              </MenuItem>
              {assetHumans.map((option) => (
                <MenuItem
                  key={option.external_id ?? ''}
                  value={option.external_id ?? ''}
                >
                  {option.nickname ?? `Employee #${option.external_id}`}
                </MenuItem>
              ))}
            </TextField>

            <ZoneSelect
              value={params.section_id ?? null}
              nullLabel="All Sections"
              size="small"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setConfig(
                  update(config ?? {}, {
                    params: {
                      section_id: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />

            <MachineInputSelectV2
              value={params?.input}
              label="Inputs"
              size="small"
              onChange={(v) => {
                setConfig(
                  update(config, {
                    params: {
                      input: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Button size="small" onClick={() => params && fetchData(params)}>
                <RefreshIcon />
              </Button>

              <AutoRefreshSelect
                value={config.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Button size="small" onClick={onPrint}>
                <PrintIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>
      <Box display={printTheme ? 'flex' : 'none'} sx={{my: 4}}>
        {dayjs().format('MM/DD/YY, hh:mm a')}
        {' - '}
        Machine Interaction Report
      </Box>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, idx) => (
        <Alert key={idx} severity="error" onClose={() => fetchData(params)}>
          {error}
        </Alert>
      ))}

      {fetchedData && (
        <Box textAlign="center">
          Warning Count: {fetchedData?.STAT.warning}, Hazard Count:{' '}
          {fetchedData?.STAT.hazard}, Remote Stop Count:{' '}
          {fetchedData?.STAT.stop}
        </Box>
      )}

      <Box
        ref={ref}
        display="flex"
        height="100%"
        width="100%"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
      >
        {fetchedEventItems && !fetchedInProgress ? (
          <Box
            display="flex"
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
          >
            <Box key={hiddenKeys.join('.')} height="100%">
              <Plot
                data={fetchedEventItems.map(({x, y, color, event}: any) => ({
                  type: 'scatter',
                  x,
                  y,
                  name: event,
                  line: {width: 10, color},
                  showlegend: false,
                }))}
                // data={_.map(fetchedSeriesCoords, ({ x, y, fillcolor }, name, i) => ({
                //   fill: "toself",
                //   fillcolor,
                //   mode: 'none',
                //   showlegend: true,
                //   type: "scatter",
                //   visible: true,
                //   connectgaps: true,
                //   name,
                //   x,
                //   y,
                // }))}
                layout={{
                  width: width - 200,
                  height,
                  hovermode: 'closest',
                  margin: {
                    l: 10,
                    b: 10,
                    r: 10,
                    t: 30,
                  },
                  plot_bgcolor: 'transparent',
                  xaxis: {
                    automargin: true,
                    gridcolor: 'transparent',
                    color: theme.palette.text.secondary,
                    linecolor: 'transparent',
                    ticks: '',
                    title: {standoff: 15},
                    zerolinecolor: 'transparent',
                    zerolinewidth: 2,
                    type: 'date',
                    range: [
                      dayjs(params.date_start).format('YYYY-MM-DD'),
                      dayjs(params.date_end).add(1, 'd').format('YYYY-MM-DD'),
                    ],
                    autorange: false,
                  },
                  yaxis: {
                    automargin: true,
                    gridcolor: 'transparent',
                    color: theme.palette.text.secondary,
                    title: {standoff: 15},
                    zerolinecolor: 'transparent',
                    zerolinewidth: 2,
                    type: 'category',
                    // tickmode: 'array',
                    // tickvals: _.map(fetchedTasks, ((i: any) => i.CATEGORY)),
                    // ticktext: _.map(fetchedTasks, ((i: any) => i.NAME)),
                  },
                  legend: {
                    bgcolor: 'transparent',
                    font: {
                      color: theme.palette.text.secondary,
                    },
                  },
                }}
                config={{
                  displaylogo: false,
                  setBackground: () => 'transparent',
                  autosizable: false,
                }}
              />
            </Box>

            <Box width="200px">
              {_.map(fetchedEvents, (item, key) => (
                <Box
                  key={key}
                  sx={{
                    color: item.color,
                    borderColor: item.color,
                    borderLeft: '10px solid',
                    pl: 1,
                    mb: 2,
                    lineHeight: 1,
                    cursor: 'pointer',
                    opacity: !hiddenKeys.includes(key) ? 1 : 0.4,
                  }}
                  onClick={() => toggleKey(key)}
                >
                  {item.event}
                </Box>
              ))}
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            height={50}
            alignItems="center"
            justifyContent="center"
          >
            {fetchedInProgress ? 'Loading...' : 'No data to display'}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MachineInteractionReportGantt;
