import {CommtracNode} from '../interfaces/CommtracNode';
import {
  getAssetBatteryPercentage,
  getAtestBatteryPercentage,
  getCamBatteryPercentage,
  getMc2BatteryPercentage,
  getMinerBatteryPercentage,
  getTagsBatteryPercentage,
  getWifiBatteryPercentage,
} from '../utils/commtrac-nodes';
import {useGetCommtracNodeType} from './get-commtrac-node-type';

export const useGetCommtracNodeBatteryPercentage = () => {
  const getCommtracNodeType = useGetCommtracNodeType();
  const getCommtracNodeBatteryPercentage = (item: CommtracNode) => {
    const type = getCommtracNodeType(item);
    if (
      typeof item.commtrac_battery_voltage !== 'number' ||
      (type === 'CAM' && !item.cam_monitor_battery)
    ) {
      return null;
    } else if (item.wifi_enabled && item.min_voltage && item.max_voltage) {
      return getWifiBatteryPercentage(
        item.commtrac_battery_voltage,
        item.min_voltage,
        item.max_voltage
      );
    } else if (type === 'TAG') {
      return getTagsBatteryPercentage(item.commtrac_battery_voltage);
    } else if (type === 'CAM') {
      return getCamBatteryPercentage(item.commtrac_battery_voltage);
    } else if (item.atest === '1') {
      return getAtestBatteryPercentage(item.commtrac_battery_voltage);
    } else if (item.mc2_flag === 1) {
      return getMc2BatteryPercentage(item.commtrac_battery_voltage);
    } else if (item.type === 'miner') {
      return getMinerBatteryPercentage(item.commtrac_battery_voltage);
    } else if (item.type === 'asset') {
      return getAssetBatteryPercentage(item.commtrac_battery_voltage);
    }
    return null;
  };
  return getCommtracNodeBatteryPercentage;
};
