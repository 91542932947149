import {PasswordResetRequest} from '../../components/auth/PasswordResetRequest';
import {AuthLayout} from '../../layouts/AuthLayout';

export const PasswordResetRequestPage = () => {
  return (
    <AuthLayout>
      <PasswordResetRequest />
    </AuthLayout>
  );
};
