import {createSelector} from 'reselect';

import {AdminPageState} from './types';

export const humanGroupTabSelect = createSelector(
  [(state: any) => state.admin],
  (admin: AdminPageState) => admin.humanGroupTab
);

export const humanGroupListSelect = createSelector(
  [humanGroupTabSelect],
  (state) => state.groupList
);

export const humanGroupTabLoading = createSelector(
  [humanGroupTabSelect],
  (state) => state.loading
);

export const humanGroupSelectedTypes = createSelector(
  [humanGroupTabSelect],
  (state) => state.selectedTypes
);

export const humanGroupSelectedSections = createSelector(
  [humanGroupTabSelect],
  (state) => state.selectedSections
);

export const humanGroupEmployees = createSelector(
  [humanGroupTabSelect],
  (state) => {
    return state.employees;
  }
);
