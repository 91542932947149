import {MenuItem, TextField, TextFieldProps} from '@mui/material';

import {Location, LOCATIONS} from '../../constants/location';

type Props = TextFieldProps & {
  value?: Location | null;
};

const LocationSelect = (props: Props) => {
  return (
    <TextField {...props} value={props.value ?? ''} select>
      {LOCATIONS.map((i) => (
        <MenuItem key={i.key} value={i.key}>
          {i.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default LocationSelect;
