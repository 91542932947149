import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';

type T = number | null | undefined;

export const useRefreshInterval = (refresh: () => void, interval: T) => {
  const [refreshInterval, setRefreshInterval] = useState(interval);
  useEffect(() => {
    setRefreshInterval(interval);
  }, [interval]);
  const refRefreshInterval = useRef<any>(null);
  useEffect(() => {
    if (!refreshInterval) {
      if (refRefreshInterval.current) {
        clearInterval(refRefreshInterval.current);
      }
      return;
    }

    refRefreshInterval.current = setInterval(() => {
      refresh();
    }, refreshInterval);

    return () => {
      clearInterval(refRefreshInterval.current);
    };
  }, [refreshInterval, refresh]);

  return [refreshInterval, setRefreshInterval] as [
    T,
    Dispatch<SetStateAction<T>>,
  ];
};
