import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Paper,
} from '@mui/material';
import React, {forwardRef, useEffect, useImperativeHandle} from 'react';
import {useSelector} from 'react-redux';

import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {HumanGroupGridItem} from '../../interfaces/HumanGroup';
import {fetchHumanGroups} from '../../redux/admin/actions';
import {
  humanGroupListSelect,
  humanGroupTabLoading,
} from '../../redux/admin/selectors';
import reduxSelectors from '../../redux/selectors';
import DataGrid, {DataGridColumn} from '../common/DataGrid';
import {GroupItemDeleteButton} from '../human-groups/buttons/GroupItemDeleteButton';
import {GroupItemUpsertButton} from '../human-groups/buttons/GroupItemUpsertButton';

export interface EmployeeGroupListRef {
  fetch?: Function;
}

export const EmployeeGroupList = forwardRef<
  EmployeeGroupListRef,
  React.PropsWithChildren
  // eslint-disable-next-line no-empty-pattern
>((_, ref) => {
  const dispatch = useAppDispatch();
  const groupsList = useSelector(humanGroupListSelect);
  const loading = useSelector(humanGroupTabLoading);

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const paperBg = isDarkMode ? '#222222' : '#FFF';

  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
  }));

  const fetchData = async () => {
    setTimeout(() => dispatch(fetchHumanGroups()), 250);
  };

  useEffect(() => {
    dispatch(fetchHumanGroups());
  }, []);

  const columns: DataGridColumn<HumanGroupGridItem>[] = [
    {
      field: 'id',
      sortable: true,
      headerName: 'ID',
    },
    {field: 'name', headerName: 'Group Names', sortable: true},
    {field: 'sections_cc', headerName: 'Sections', sortable: true},
    {field: 'types_cc', headerName: 'Roles', sortable: true},
    {
      field: 'additions_deletions_cc',
      headerName: 'Additions/Deletions',
      sortable: true,
      renderCell: ({row}) => {
        const result = [];

        if (!row.additions_deletions_cc) {
          return '';
        }

        if (row.additions_deletions_cc.dynamic?.length > 0) {
          return (
            <Box color="blue">{row.additions_deletions_cc.dynamic[0]}</Box>
          );
        }
        if (row.additions_deletions_cc.additions.length > 0) {
          result.push(
            <Box key="green" color="green" overflow="hidden">
              {row.additions_deletions_cc.additions.join(',')}
            </Box>
          );
        }
        if (row.additions_deletions_cc.deletions.length > 0) {
          result.push(
            <Box key="red" color="red" overflow="hidden">
              {row.additions_deletions_cc.deletions.join(',')}
            </Box>
          );
        }
        return result;
      },
    },
    {field: 'notify_alarm', sortable: true},
    {field: 'notify_alert', sortable: true},
    {
      field: 'actions',
      type: 'actions',
      sxHeader: {textAlign: 'right'},
      sxCell: {textAlign: 'right'},
      renderCell: ({row}) => (
        <>
          <GroupItemUpsertButton
            itemId={row.id}
            groupName={row.name}
            component={IconButton}
            componentProps={{
              color: 'primary',
              size: 'small',
            }}
            onSubmitted={() => fetchData()}
          >
            <EditIcon />
          </GroupItemUpsertButton>

          <GroupItemDeleteButton
            itemId={row.id}
            component={IconButton}
            componentProps={{
              color: 'error',
              size: 'small',
            }}
            onDeleted={() => fetchData()}
          >
            <DeleteOutlineOutlinedIcon />
          </GroupItemDeleteButton>
        </>
      ),
    },
  ];

  return (
    <Paper
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        p: 3,
        bgcolor: paperBg,
        backgroundImage: 'none',
      }}
    >
      <Backdrop open={loading === 'pending'} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <DataGrid
        rows={groupsList}
        columns={columns}
        loading={loading === 'pending'}
        pagination
        size="small"
        sx={{
          bgcolor: 'transparent',
          th: {
            bgcolor: paperBg,
          },
        }}
      />
    </Paper>
  );
});
