import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {CommunicationNode} from '../../interfaces/CommunicationNodeV2';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item?: CommunicationNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onOpen?: (type: 'cn' | 'commtracNodeByCn') => void;
}

const CommunicationNodeItemHistoryButton = <
  T extends ComponentType = typeof Button,
>({
  // item,
  component,
  componentProps,
  children,
  onOpen,
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            onOpen?.('cn');
            setAnchorEl(null);
          }}
        >
          View History
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpen?.('commtracNodeByCn');
            setAnchorEl(null);
          }}
        >
          View Employee/Asset
        </MenuItem>
      </Menu>
    </>
  );
};

export default CommunicationNodeItemHistoryButton;
