import {Button, ButtonGroup} from '@mui/material';
import update from 'immutability-helper';
import isEmpty from 'lodash/isEmpty';
import {useMemo} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {DashboardPanelActionsSlot} from '../DashboardPanelActionsSlot';
import {
  CommtracNodeByCnTrackingReports,
  CommtracNodeByCnTrackingReportsData,
  getCommtracNodeByCnTrackingReportsData,
} from './CommtracNodeByCnTrackingReports';
import {
  CommunicationNodeHistoryReports,
  CommunicationNodeHistoryReportsData,
  getCommunicationNodeHistoryReportsData,
} from './CommunicationNodeHistoryReports';
import {
  getWifiPointHistoryReportsData,
  WifiPointHistoryReports,
  WifiPointHistoryReportsData,
} from './WifiPointHistoryReports';
import {
  getWifiPointLongTermHistoryReportsData,
  WifiPointLongTermHistoryReports,
  WifiPointLongTermHistoryReportsData,
} from './WifiPointLongTermHistoryReports';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

export interface NodeTrackingReportsData {
  viewType: 'cn' | 'wifi' | 'commtracNodeByCn' | 'wifiLongTerm';
  title: string;
  cn: CommunicationNodeHistoryReportsData;
  wifi: WifiPointHistoryReportsData;
  wifiLongTerm: WifiPointLongTermHistoryReportsData;
  commtracNodeByCn: CommtracNodeByCnTrackingReportsData;
}

const getNodeTrackingReportsData = (): NodeTrackingReportsData => ({
  viewType: 'cn',
  title: 'Communication Nodes Tracking Reports',
  cn: getCommunicationNodeHistoryReportsData(),
  wifi: getWifiPointHistoryReportsData(),
  wifiLongTerm: getWifiPointLongTermHistoryReportsData(),
  commtracNodeByCn: getCommtracNodeByCnTrackingReportsData(),
});

export const NodeTrackingReports = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as NodeTrackingReportsData)
        : getNodeTrackingReportsData(),
    [value]
  );
  return (
    <>
      <DashboardPanelActionsSlot>
        <ButtonGroup sx={{minWidth: 410}}>
          <Button
            size="small"
            variant={config.viewType === 'cn' ? 'contained' : 'outlined'}
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'cn',
                  },
                })
              );
            }}
          >
            CN
          </Button>

          <Button
            size="small"
            variant={
              config.viewType === 'commtracNodeByCn' ? 'contained' : 'outlined'
            }
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'commtracNodeByCn',
                  },
                })
              );
            }}
          >
            Objects by CN
          </Button>

          <Button
            size="small"
            variant={config.viewType === 'wifi' ? 'contained' : 'outlined'}
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'wifi',
                  },
                })
              );
            }}
          >
            WiFi
          </Button>

          <Button
            size="small"
            variant={
              config.viewType === 'wifiLongTerm' ? 'contained' : 'outlined'
            }
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'wifiLongTerm',
                  },
                })
              );
            }}
          >
            WiFi Long Term
          </Button>
        </ButtonGroup>
      </DashboardPanelActionsSlot>

      {config.viewType === 'cn' ? (
        <CommunicationNodeHistoryReports
          value={config.cn}
          onUpdate={(v) => {
            onUpdate?.(
              update(!value ? getNodeTrackingReportsData() : value, {
                cn: {
                  $set: v ?? getCommunicationNodeHistoryReportsData(),
                },
                wifi: {
                  $set: value?.wifi ?? getWifiPointHistoryReportsData(),
                },
                wifiLongTerm: {
                  $set:
                    value?.wifiLongTerm ??
                    getWifiPointLongTermHistoryReportsData(),
                },
                commtracNodeByCn: {
                  $set:
                    value?.commtracNodeByCn ??
                    getCommtracNodeByCnTrackingReportsData(),
                },
                viewType: {
                  $set: 'cn',
                },
              })
            );
          }}
        />
      ) : config.viewType === 'wifi' ? (
        <WifiPointHistoryReports
          value={config.wifi}
          onUpdate={(v) => {
            onUpdate?.(
              update(value, {
                wifi: {
                  $set: v ?? getWifiPointHistoryReportsData(),
                },
                cn: {
                  $set: value?.cn ?? getCommunicationNodeHistoryReportsData(),
                },
                wifiLongTerm: {
                  $set:
                    value?.wifiLongTerm ??
                    getWifiPointLongTermHistoryReportsData(),
                },
                commtracNodeByCn: {
                  $set:
                    value?.commtracNodeByCn ??
                    getCommtracNodeByCnTrackingReportsData(),
                },
                viewType: {
                  $set: 'wifi',
                },
              })
            );
          }}
        />
      ) : config.viewType === 'commtracNodeByCn' ? (
        <CommtracNodeByCnTrackingReports
          value={config.commtracNodeByCn}
          onUpdate={(v) => {
            onUpdate?.(
              update(value, {
                commtracNodeByCn: {
                  $set: v ?? getCommtracNodeByCnTrackingReportsData(),
                },
                cn: {
                  $set: value?.cn ?? getCommunicationNodeHistoryReportsData(),
                },
                wifi: {
                  $set: value?.wifi ?? getWifiPointHistoryReportsData(),
                },
                wifiLongTerm: {
                  $set:
                    value?.wifiLongTerm ??
                    getWifiPointLongTermHistoryReportsData(),
                },
                viewType: {
                  $set: 'commtracNodeByCn',
                },
              })
            );
          }}
        />
      ) : config.viewType === 'wifiLongTerm' ? (
        <WifiPointLongTermHistoryReports
          value={config.wifiLongTerm}
          onUpdate={(v) => {
            onUpdate?.(
              update(value, {
                wifiLongTerm: {
                  $set: v ?? getWifiPointLongTermHistoryReportsData(),
                },
                cn: {
                  $set: value?.cn ?? getCommunicationNodeHistoryReportsData(),
                },
                wifi: {
                  $set: value?.wifi ?? getWifiPointHistoryReportsData(),
                },
                commtracNodeByCn: {
                  $set:
                    value?.commtracNodeByCn ??
                    getCommtracNodeByCnTrackingReportsData(),
                },
                viewType: {
                  $set: 'wifiLongTerm',
                },
              })
            );
          }}
        />
      ) : null}
    </>
  );
};
