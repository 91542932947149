import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';

import {useAppSelector} from '../../hooks/redux';

interface Props {}

export const MyPermissionsConfig = (__: Props) => {
  const myPermissions = useAppSelector(({app}) => app.me?.permissions);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Box fontWeight={600} fontSize={16}>
              Rule
            </Box>
          </TableCell>

          <TableCell align="right">
            <Box fontWeight={600} fontSize={16}>
              Allowed
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>

      <TableBody>
        {myPermissions?.map((permissionId) => (
          <TableRow key={permissionId}>
            <TableCell>{permissionId}</TableCell>

            <TableCell align="right">
              <CheckIcon color="success" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
