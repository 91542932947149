import {ListItem} from '@mui/material';
import {ReactNode, useState} from 'react';

import {CompanyListModal} from '../CompanyListModal';

interface Props {
  component?: React.FC;
  componentProps?: any;
  children?: ReactNode;
}

export const CompanyListButton = ({
  component = ListItem,
  componentProps = {},
  children,
}: Props) => {
  const Component = component;
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <CompanyListModal open={isOpened} onClose={closeModal} />
    </>
  );
};
