import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import AlarmModuleItemsPurgeEventsButton from './AlarmModuleItemsPurgeEventsButton';
import AlarmModuleItemsPurgeNodeButton from './AlarmModuleItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  alarmModuleIds: number[];
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const AlarmModulesPurgeButton = <T extends ComponentType = typeof Button>({
  alarmModuleIds,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <AlarmModuleItemsPurgeNodeButton
          alarmModuleIds={alarmModuleIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Devices
        </AlarmModuleItemsPurgeNodeButton>

        <AlarmModuleItemsPurgeEventsButton
          alarmModuleIds={alarmModuleIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Transactions
        </AlarmModuleItemsPurgeEventsButton>
      </Menu>
    </>
  );
};

export default AlarmModulesPurgeButton;
