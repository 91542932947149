import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  SxProps,
  Theme,
} from '@mui/material';
import _ from 'lodash';
import {useState} from 'react';

export type TableColumn<T = string> = {
  field: T;
  sortable?: boolean;
  headerName?: string;
  headerGetter?: Function;
  width?: number;
  sx?: SxProps<Theme>;
  maxWidth?: number;
  valueGetter?: Function;
  orderByIteratees?: Function;
};

type TableColumnActionsButtonProps = {
  column: TableColumn;
  columns: TableColumn[];
  shownColumnFields: string[];
  onChangeSortBy?: Function;
  onChangeShownColumnFields?: Function;
};

export const TableColumnActionsButton: React.FC<
  TableColumnActionsButtonProps
> = ({
  column,
  columns,
  shownColumnFields,
  onChangeSortBy,
  onChangeShownColumnFields,
}) => {
  const [firstAnchorEl, setFirstAnchorEl] = useState<HTMLElement | null>(null);
  const [secondAnchorEl, setSecondAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const firstOpen = Boolean(firstAnchorEl);
  const secondOpen = Boolean(secondAnchorEl);

  return (
    <Box>
      <IconButton
        size="small"
        sx={{p: 0.5}}
        onClick={(event) => setFirstAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={firstAnchorEl}
        open={firstOpen}
        onBackdropClick={() => setFirstAnchorEl(null)}
      >
        {column.sortable && (
          <MenuItem
            onClick={() => {
              onChangeSortBy?.({sortDesc: false});
              setSecondAnchorEl(null);
              setFirstAnchorEl(null);
            }}
          >
            Sort Ascending
          </MenuItem>
        )}

        {column.sortable && (
          <MenuItem
            onClick={() => {
              onChangeSortBy?.({sortDesc: true});
              setSecondAnchorEl(null);
              setFirstAnchorEl(null);
            }}
          >
            Sort Descending
          </MenuItem>
        )}

        <MenuItem onClick={(event) => setSecondAnchorEl(event.currentTarget)}>
          Columns
        </MenuItem>

        <Menu
          key={shownColumnFields.length}
          anchorEl={secondAnchorEl}
          open={secondOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onBackdropClick={() => {
            setSecondAnchorEl(null);
            setFirstAnchorEl(null);
          }}
        >
          <Box px={2}>
            {columns.map((column) => (
              <Box key={column.field}>
                <FormControlLabel
                  label={
                    column.headerName ??
                    _.capitalize(column.field.replaceAll('_', ' '))
                  }
                  control={
                    <Checkbox
                      checked={shownColumnFields.includes(column.field)}
                      size="small"
                      onChange={(event, value) => {
                        if (value) {
                          onChangeShownColumnFields?.([
                            ...shownColumnFields,
                            column.field,
                          ]);
                        } else {
                          onChangeShownColumnFields?.(
                            shownColumnFields.filter((i) => i !== column.field)
                          );
                        }
                      }}
                    />
                  }
                />
              </Box>
            ))}
          </Box>
        </Menu>
      </Menu>
    </Box>
  );
};
