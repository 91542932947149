import {Dispatch} from 'redux';

import {AuthState} from './types';

export enum Actions {
  SetAuthTokens = 'SetAuthTokens',
}

const authActions = {
  setAuthTokens: (dispatch: Dispatch, data: Partial<AuthState>) => {
    if (data.accessToken) {
      window.localStorage.setItem('accessToken', data.accessToken);
    }

    if (data.refreshToken) {
      window.localStorage.setItem('refreshToken', data.refreshToken);
    }

    if (data.passwordToken) {
      window.localStorage.setItem('passwordToken', data.passwordToken);
    }

    return dispatch({type: Actions.SetAuthTokens, data});
  },

  clearAuthTokens: (dispatch: Dispatch) => {
    window.localStorage.removeItem('accessToken');
    window.localStorage.removeItem('refreshToken');
    window.localStorage.removeItem('passwordToken');

    const data: AuthState = {
      accessToken: null,
      refreshToken: null,
      passwordToken: null,
    };

    return dispatch({type: Actions.SetAuthTokens, data});
  },
};

export default authActions;
