import update from 'immutability-helper';
import {createContext, PropsWithChildren, useContext} from 'react';

import {
  DashboardEntity,
  DashboardPanel,
  PanelFilter,
} from '../../../interfaces/Dashboard';
import {Throw} from '../../../utils/exceptions';

const ReaderContext = createContext<DashboardEntity | null>(null);
const WriterContext = createContext<((action: Action) => void) | null>(null);
const FilterContext = createContext<{
  filter: PanelFilter;
  setFilter: any;
}>({filter: {}, setFilter: ''});

interface SetPanel {
  type: 'SetPanel';
  payload: DashboardPanel;
}

type Action = SetPanel;

const reducer = (state: DashboardEntity, action: Action): DashboardEntity => {
  switch (action.type) {
    case 'SetPanel': {
      return update(state, {
        panel: {$set: action.payload},
      });
    }
  }
};

interface Props extends PropsWithChildren {
  value: DashboardEntity;
  filter: {
    filter: PanelFilter;
    setFilter: any;
  };
  onChange?: (data: DashboardEntity) => void;
}

export const DashboardEntityProvider = ({
  children,
  value,
  onChange,
  filter,
}: Props) => {
  return (
    <ReaderContext.Provider value={value}>
      <WriterContext.Provider value={(a) => onChange?.(reducer(value, a))}>
        <FilterContext.Provider value={filter}>
          {children}
        </FilterContext.Provider>
      </WriterContext.Provider>
    </ReaderContext.Provider>
  );
};

export const useDashboardEndity = () =>
  [
    useContext(ReaderContext) ?? Throw(new Error('context not present')),
    useContext(WriterContext) ?? Throw(new Error('context not present')),
  ] as const;

export const usePanel = () => {
  const [entity, dispatchEntity] = useDashboardEndity();
  return [
    entity.panel,
    (panel: DashboardPanel) =>
      dispatchEntity({type: 'SetPanel', payload: panel}),
  ] as const;
};

export const usePanelFilter = () => useContext(FilterContext);
