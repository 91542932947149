import {Box, Paper} from '@mui/material';
import _ from 'lodash';
import React, {useEffect} from 'react';
import RGL, {Layout, WidthProvider} from 'react-grid-layout';

import {Widget} from '../../types';
import {SimpleProgressMetric} from './SimpleProgressMetric';
import {SingleMetric} from './SingleMetric';

const ReactGridLayout = WidthProvider(RGL);

interface Props {
  widgets: Widget[];
  editMode?: boolean;
  layout?: Layout[];
  onDeleteWidget?: (widgetId: number) => void;
  onLayoutChanged?: (layout: any) => void;
}

export const WidgetContainer = ({
  widgets,
  editMode,
  layout,
  onDeleteWidget = () => {},
  onLayoutChanged = () => {},
}: Props) => {
  const renderMetric = (widget: Widget) => {
    switch (widget.type) {
      case 'single':
        return (
          <SingleMetric
            {...widget}
            onDelete={editMode ? () => onDeleteWidget(widget.id) : undefined}
          />
        );
      case 'simple-progress':
        return (
          <SimpleProgressMetric
            {...widget}
            onDelete={editMode ? () => onDeleteWidget(widget.id) : undefined}
          />
        );
      default:
        break;
    }
  };

  const generateDOM = () => {
    return _.map(_.range(widgets?.length), (i) => {
      return (
        <Box
          component={Paper}
          key={i}
          sx={{
            position: 'relative',
            '.react-resizable-handle': {
              position: 'absolute',
              right: 0,
              bottom: 0,
              borderColor: 'gray',
            },
          }}
        >
          {renderMetric(widgets[i])}
        </Box>
      );
    });
  };

  const generateDefaultLayout = (items: Widget[]) => {
    return _.map(items, (item, i) => {
      const y = 1;
      return {
        x: (i * 3) % 12,
        y: 1,
        w: 3,
        h: y,
        i: `${i}`,
        id: item.id,
      };
    });
  };

  useEffect(() => {
    if (widgets?.length && !layout?.length) {
      onLayoutChanged(generateDefaultLayout(widgets));
    }
  }, [widgets, layout]);

  return (
    <ReactGridLayout
      layout={layout}
      isDraggable={editMode}
      isBounded={false}
      isResizable={editMode}
      isDroppable={editMode}
      autoSize
      onLayoutChange={onLayoutChanged}
    >
      {generateDOM()}
    </ReactGridLayout>
  );
};
