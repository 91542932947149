import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import Battery2BarIcon from '@mui/icons-material/Battery2Bar';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import Battery5BarIcon from '@mui/icons-material/Battery5Bar';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import {useMemo} from 'react';

interface Props {
  iconType:
    | 'batteryFull'
    | 'batteryHigh'
    | 'batteryMid'
    | 'batteryLow'
    | 'batteryEmpty';
  color?: 'error' | 'success';
}

const ICONS = {
  0: Battery0BarIcon,
  25: Battery2BarIcon,
  50: Battery3BarIcon,
  75: Battery5BarIcon,
  100: BatteryFullIcon,
};

export const AMSBatteryIcon = ({iconType, color}: Props) => {
  const icon = useMemo(() => {
    let index: keyof typeof ICONS = 0;

    if (iconType === 'batteryFull') {
      index = 100;
    } else if (iconType === 'batteryHigh') {
      index = 75;
    } else if (iconType === 'batteryMid') {
      index = 50;
    } else if (iconType === 'batteryLow') {
      index = 25;
    }

    const Battery = ICONS[index];

    return <Battery color={color} />;
  }, [iconType]);

  return icon;
};
