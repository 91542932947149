import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  MachineSummaryReportItem,
  MachineSummaryReportQuery,
  MachineSummaryReportResponse,
} from '../../../interfaces/MachineSummaryReport';
import reduxSelectors from '../../../redux/selectors';
import {getBooleanValue} from '../../../utils/boolean';
import {saveFile} from '../../../utils/file';
import {isPresent} from '../../../utils/type-guards';
import AssetMachineSelect from '../../asset-machine/AssetMachineSelect';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {
  usePanel,
  usePanelFilter,
} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {HumanMachineZoneSelect} from '../../selectors/HumanMachineZoneSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {ShiftSelect} from '../../selectors/ShiftSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'machine_name',
  'human_name',
  'personal_id',
  'zone',
  'date',
];

export interface MachineSummaryReportData {
  refreshInterval?: number | null;
  params?: {
    page?: number;
    limit?: number;
    order?: string | null;
    dir?: 'ASC' | 'DESC';
    external_id?: number | null;
    human_only?: boolean | null;
    shift_id?: number | null;
    machine_zones?: number[] | null;
    human_zones?: number[] | null;
    date_start?: string | null;
    date_end?: string | null;
    input?: {
      [key: number]: 0 | 1 | null | undefined;
    };
  };
}

export const getMachineSummaryReportData = (): MachineSummaryReportData => ({
  params: {
    human_only: true,
  },
});

export const MachineSummaryReport = (props: Props) => {
  const [panel] = usePanel();
  const {filter: config, setFilter: setConfig} = usePanelFilter();

  const assetMachines = useAppSelector(({assets}) =>
    assets.asset_machines.filter((i) => i.status === 'active' && i.external_id)
  );

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<MachineSummaryReportResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<MachineSummaryReportQuery>(() => ({
    page: config.params?.page ?? props.value?.params?.page ?? 0,
    limit: config.params?.limit ?? props.value?.params?.limit ?? 25,
    order: config.params?.order ?? props.value?.params?.order ?? null,
    dir: config.params?.dir ?? props.value?.params?.dir ?? null,
    external_id: config.params?.external_id ?? props.value?.params?.external_id ?? null,
    human_only: config.params?.human_only ?? props.value?.params?.human_only ?? false,
    shift_id: config.params?.shift_id ?? props.value?.params?.shift_id ?? null,
    machine_zones: config.params?.machine_zones ?? props.value?.params?.machine_zones ?? [],
    human_zones: config.params?.human_zones ?? props.value?.params?.human_zones ?? [],
    date_start: config.params?.date_start ?? props.value?.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
    date_end: config.params?.date_end ?? props.value?.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
    input: config.params?.input ?? props.value?.params?.input ?? {},
  }), [config]);

  const fetchData = useCallback(
    async (params: MachineSummaryReportQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/machine-summary`;
        const resp = await API.get<MachineSummaryReportResponse>(endpoint, {
          params: {
            ...params,
            input: JSON.stringify(params.input),
          },
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  const selectedAssetMachine = useMemo(
    () =>
      params.external_id
        ? assetMachines.find((i) => i.external_id === params.external_id)
        : null,
    [params.external_id]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
      props.onUpdate?.({...config, params});
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const machineInputs = useAppSelector(({assets}) =>
    assets.machine_inputs.filter((i) => i.status === 'active')
  );
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<MachineSummaryReportItem>[] = [
    {
      field: 'machine_name',
      headerName: 'Machine',
      sortable: true,
    },
    {
      field: 'human_name',
      headerName: 'Human',
      sortable: true,
    },
    {
      field: 'personal_id',
      headerName: 'Employee ID',
      sortable: true,
    },
    {
      field: 'zone',
      headerName: 'Alert Type',
      sortable: true,
    },
    {
      field: 'date',
      headerName: 'Event Time',
      sortable: true,
    },
    ...machineInputs.map((i) => ({
      field: `input_${i.id}`,
      headerName: i.name,
      sortable: true,
      valueGetter: (params: any) => {
        const v = params.row[`input_${i.id}`];
        return getBooleanValue(v, 'On', 'Off');
      },
    })),
    {
      field: 'gen1',
      headerName: 'G1',
      sortable: true,
    },
    {
      field: 'gen2',
      headerName: 'G2',
      sortable: true,
    },
    {
      field: 'gen3',
      headerName: 'G3',
      sortable: true,
    },
    {
      field: 'gen4',
      headerName: 'G4',
      sortable: true,
    },
    {
      field: 'maintenance',
      headerName: 'Maintenance',
      sortable: true,
    },
    {
      field: 'comms',
      headerName: 'Comms',
      sortable: true,
    },
  ];

  /*********/
  /* export */
  /*********/
  const [exportInProgress, setExportInProgress] = useState(false);

  const {shifts} = useAppSelector(reduxSelectors.assets.getAssets);

  const submitExport = async (params: MachineSummaryReportQuery) => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/report/machine-summary-export`;
      const resp = await API.get(endpoint, {
        params: {
          ...params,
          input: JSON.stringify(params.input),
          export_type: 'line',
        },
        responseType: 'blob',
      });

      const fileNameParts = [
        'reportMachineSummary',
        `${params.date_start}__${params.date_end}`,
        shifts.find((i) => i.id === params.shift_id)?.name?.replace(' ', '-'),
        selectedAssetMachine?.name?.replace(' ', '-'),
        machineInputs
          .map((i) => {
            const v = params.input?.[i.id];
            if (v === 1) {
              return `${i.name.replace(' ', '-')}-is-on`;
            } else if (v === 0) {
              return `${i.name.replace(' ', '-')}-is-off`;
            }
          })
          .filter(isPresent)
          .join('_'),
      ].filter(isPresent);

      const fileName = `${fileNameParts.join('__')}.csv`;

      saveFile(resp.data, fileName);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setExportInProgress(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={1}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(params?.date_start).toDate(),
                  dayjs(params?.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        date_start: {
                          $set: v?.[0]
                            ? dayjs(v?.[0]).format('YYYY-MM-DD')
                            : undefined,
                        },
                        date_end: {
                          $set: v?.[0]
                            ? dayjs(v?.[1]).format('YYYY-MM-DD')
                            : undefined,
                        },
                        page: {
                          $set: 0,
                        },
                      },
                      /*params: {
                        $set: {
                          ...config.params,
                          date_start: v?.[0] ? dayjs(v?.[0]).format("YYYY-MM-DD") : undefined,
                          date_end: v?.[0] ? dayjs(v?.[1]).format("YYYY-MM-DD") : undefined,
                          page: 0,
                        },
                      },*/
                    })
                  );
                }}
              />
            </Box>

            <AssetMachineSelect
              value={selectedAssetMachine?.id}
              size="small"
              label="Machine"
              fullWidth
              nullLabel="All Machines"
              assetMachines={assetMachines}
              sx={{minWidth: 200}}
              onChange={(_, item) => {
                setConfig?.(
                  update(config, {
                    params: {
                      external_id: {
                        $set: item?.external_id,
                      },
                      page: {
                        $set: 0,
                      },
                    },
                    /*params: {
                      $set: {
                        ...config.params,
                        external_id: item?.external_id,
                        page: 0,
                      },
                    },*/
                  })
                );
              }}
            />

            <FormControlLabel
              label="Human Events Only"
              sx={{whiteSpace: 'nowrap'}}
              control={
                <Checkbox
                  checked={!!params?.human_only}
                  onChange={(event) => {
                    setConfig?.(
                      update(config, {
                        params: {
                          human_only: {
                            $set: event.target.checked,
                          },
                          page: {
                            $set: 0,
                          },
                        },
                        /*params: {
                          $set: {
                            ...config.params,
                            human_only: event.target.checked,
                            page: 0,
                          },
                        },*/
                      })
                    );
                  }}
                />
              }
            />

            <ShiftSelect
              value={params?.shift_id}
              size="small"
              fullWidth
              nullLabel="All Shifts"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      shift_id: {
                        $set: v,
                      },
                      page: {
                        $set: 0,
                      },
                    },
                    /*params: {
                      $set: {
                        ...config.params,
                        shift_id: v,
                        page: 0,
                      },
                    },*/
                  })
                );
              }}
            />

            <Box minWidth={200}>
              <MachineInputSelectV2
                value={params?.input}
                label="Inputs"
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        input: {
                          $set: v,
                        },
                        page: {
                          $set: 0,
                        },
                      },
                      /*params: {
                        $set: {
                          ...config.params,
                          input: v,
                          page: 0,
                        },
                      },*/
                    })
                  );
                }}
              />
            </Box>

            <HumanMachineZoneSelect
              value={{
                machine: params?.machine_zones ?? null,
                human: params?.human_zones ?? null,
              }}
              showMachine={!params?.human_only}
              size="small"
              label="Zones"
              fullWidth
              sx={{minWidth: 200}}
              onChangeMachine={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      machine_zones: {
                        $set: v,
                      },
                      page: {
                        $set: 0,
                      },
                    },
                    /*params: {
                      $set: {
                        ...config.params,
                        machine_zones: v,
                        page: 0,
                      },
                    },*/
                  })
                );
              }}
              onChangeHuman={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      human_zones: {
                        $set: v,
                      },
                      page: {
                        $set: 0,
                      },
                    },
                    /*params: {
                      $set: {
                        ...config.params,
                        human_zones: v,
                        page: 0,
                      },
                    },*/
                  })
                );
              }}
            />
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Tooltip title="Reload">
                <LoadingButton
                  size="small"
                  loading={fetchedInProgress}
                  variant="outlined"
                  onClick={() => params && fetchData(params)}
                >
                  <RefreshIcon />
                </LoadingButton>
              </Tooltip>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Tooltip title="Export to Excel">
                <LoadingButton
                  size="small"
                  loading={exportInProgress}
                  variant="outlined"
                  onClick={() => submitExport(params)}
                >
                  <FileDownloadIcon />
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Print">
                <Button onClick={() => dataGridRef.current?.printTable()}>
                  <PrintIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        shownFields={shownFields}
        pagination
        paginationMode="server"
        size="small"
        sortBy={
          params?.order
            ? {
                field: params?.order,
                dir: params?.dir === 'DESC' ? 'desc' : 'asc',
              }
            : null
        }
        sortingMode="server"
        page={params?.page}
        pageSize={params?.limit}
        rowCount={fetchedData?.count}
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        onPageChange={(v) => {
          setConfig?.(
            update(config, {
              params: {
                page: {
                  $set: v,
                },
              },
              /*params: {
                $set: {
                  ...config.params,
                  page: v,
                },
              },*/
            })
          );
        }}
        onPageSizeChange={(v) => {
          setConfig?.(
            update(config, {
              params: {
                page: {
                  $set: 0,
                },
                limit: {
                  $set: v,
                },
              },
              /*params: {
                $set: {
                  ...config.params,
                  page: 0,
                  limit: v,
                },
              },*/
            })
          );
        }}
        onSort={(v) => {
          if (v) {
            setConfig?.(
              update(config, {
                params: {
                  order: {
                    $set: v.field,
                  },
                  dir: {
                    $set: v.dir === 'desc' ? 'DESC' : 'ASC',
                  },
                },
                /*params: {
                  $set: {
                    ...config.params,
                    order: v.field,
                    dir: v.dir === "desc" ? "DESC" : "ASC",
                  },
                },*/
              })
            );
          }
        }}
        onShownFieldsChange={setShownFields}
      />
    </Box>
  );
};
