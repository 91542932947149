import dayjs from './dayjs';

export const getMsFromS = (v?: number | null | undefined) => (v ? v * 1000 : v);

export const getDaysArrayBetween = (startDate: string, endDate: string) => {
  const dateArray: string[] = [];
  if (startDate === endDate) {
    return Array.from(Array(25).keys()).map(
      (i) => `${i < 10 ? `0${i}` : i}:00`
    );
  }

  let currentDate = dayjs.utc(startDate);
  const stopDate = dayjs.utc(endDate);
  while (currentDate <= stopDate) {
    dateArray.push(dayjs(currentDate).format('YYYY-MM-DD'));
    currentDate = dayjs(currentDate).add(1, 'days');
  }
  return dateArray;
};
