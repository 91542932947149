export const LOCATIONS = [
  {
    key: 'Underground',
    name: 'Underground',
  },
  {
    key: 'Surface',
    name: 'Surface',
  },
] as const;

export type Location = (typeof LOCATIONS)[number]['key'];
