import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {Dashboard} from '../../interfaces/Dashboard';
import reduxActions from '../../redux/actions';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  item: Dashboard;
  onSubmitted?: () => void;
  onClose?: () => void;
}

const DashboardItemPrimaryModal = ({
  item,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const reduxDispatch = useAppDispatch();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionName = !item.is_primary ? 'Set as Primary' : 'Unset as Primary';

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      if (!item.is_primary) {
        await API.post(`${apiBaseUrl}/dashboard/${pk}/primary`);
      } else {
        await API.delete(`${apiBaseUrl}/dashboard/${pk}/primary`);
      }
      const message = `Dashboard successfully ${!item.is_primary ? 'set' : 'unset'} as primary`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      reduxDispatch(reduxActions.assets.fetchCompany);
      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{actionName}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName.toLowerCase()} this dashboard?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(item.id)}
          color="warning"
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DashboardItemPrimaryModal;
