/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-bitwise */
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Tab,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import {isNull} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import {ConfigurationResponse} from '../../../interfaces/Configuration';
import {amsSensorAddressMask} from '../../../interfaces/GasMonitoringNode';
import reduxSelectors from '../../../redux/selectors';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import AMSEModuleActionsButton from './AMSEModuleActionsButton';

interface Props {
  pk: number;
  item?: AMSEModuleNode;
  prefetch?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onPurged?: () => void;
  onSubmitted?: () => void;
  onFetched?: (item: AMSEModuleNode) => void;
  onOpenHistory?: (
    id: number,
    type: 'amsEmoduleSensorHistory' | 'amsEmoduleCalibration'
  ) => void;
}

type TabValue = 'general' | 'sensing' | 'status';
interface TabItem {
  value: TabValue;
  label: string;
}

const tabs: TabItem[] = [
  {
    value: 'general',
    label: 'General',
  },

  {
    value: 'status',
    label: 'Status',
  },
];

const units = ['%', 'LEL', 'PPM'];

const AMSEModuleNodeItemView = ({
  pk,
  item,
  prefetch,
  onCancel,
  onEdit,
  onPurged,
  onFetched,
  onSubmitted,
  onOpenHistory,
}: Props) => {
  /*********/
  /* fetch */
  /*********/

  const [activeTab, setActiveTab] = useState('general');

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);

  const ams_nodes = assets.ams_nodes;

  const [fetchedData, setFetchedData] = useState<AMSEModuleNode | undefined>(
    cloneDeep(item)
  );

  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AMSEModuleNode>(
        `${apiBaseUrl}/ams/emodule/${pk}`
      );
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  const [fetchedAMSConfiguration, setFetchedAMSConfiguration] =
    useState<ConfigurationResponse>();

  const fetchAMSConfiguration = async () => {
    try {
      const params = {section: JSON.stringify(['ams', 'ams-color'])};
      const configurationResp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        {params}
      );
      setFetchedAMSConfiguration(configurationResp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
  };

  useEffect(() => {
    fetchAMSConfiguration();
  }, [pk]);

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}

      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.serial_number ?? ''}
              label="e-Module Serial Number"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData?.commtrac_external_id
                  ? fetchedData?.commtrac_external_id & amsSensorAddressMask
                  : ''
              }
              label="Sensor Network ID"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData
                  ? ams_nodes?.find(
                      (it) =>
                        it?.commtrac_external_id ===
                        fetchedData?.commtrac_external_id
                    )?.name ?? null
                  : ' '
              }
              label="Associated Sensor"
              size="small"
              disabled
              fullWidth
            />
            <ZoneSelect
              value={
                fetchedData
                  ? ams_nodes?.find(
                      (it) =>
                        it?.commtrac_external_id ===
                        fetchedData?.commtrac_external_id
                    )?.zone_id ?? null
                  : null
              }
              label="Section"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData
                  ? fetchedData?.status === 'active'
                    ? 'Active'
                    : 'Inactive'
                  : ''
              }
              label="Status"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData?.date_installation
                  ? dayjs(fetchedData?.date_installation).format('YYYY-MM-DD')
                  : ''
              }
              label="Installation Date"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData?.date_uninstallation
                  ? dayjs(fetchedData?.date_uninstallation).format('YYYY-MM-DD')
                  : ''
              }
              label="Uninstallation Date"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="status" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={
                isNull(fetchedData?.type)
                  ? 'None'
                  : typeIdLabels?.[fetchedData?.type ?? 0]
              }
              label="Type"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.value ?? 0}
              label="Value"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={units[(fetchedData?.units ?? 1) - 1]}
              label="Units"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData?.date_calibration
                  ? dayjs(fetchedData?.date_calibration).format('YYYY-MM-DD')
                  : ''
              }
              label="Calibration Date"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.calibration_value ?? ''}
              label="Calibration Value"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData?.alarm_level
                  ? fetchedData?.alarm_level
                  : fetchedAMSConfiguration?.find(
                      (it) =>
                        it?.name ===
                        `ams_${fetchedData?.type}_value_alarm_level`
                    )?.value
              }
              label="Alarm Level"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={
                fetchedData?.alert_level
                  ? fetchedData?.alert_level
                  : fetchedAMSConfiguration?.find(
                      (it) =>
                        it?.name ===
                        `ams_${fetchedData?.type}_value_alert_level`
                    )?.value
              }
              label="Alert Level"
              size="small"
              disabled
              fullWidth
            />
            {(Number(fetchedData?.type) === 20 ||
              Number(fetchedData?.type) === 40) && (
              <>
                <TextField
                  value={
                    fetchedData?.upper_alarm_level
                      ? fetchedData?.upper_alarm_level
                      : fetchedAMSConfiguration?.find(
                          (it) =>
                            it?.name ===
                            `ams_${fetchedData?.type}_value_alarm_level_blast_mode`
                        )?.value
                  }
                  label="Alarm Level (Blast Mode)"
                  size="small"
                  disabled
                  fullWidth
                />

                <TextField
                  value={
                    fetchedData?.upper_alert_level
                      ? fetchedData?.upper_alert_level
                      : fetchedAMSConfiguration?.find(
                          (it) =>
                            it?.name ===
                            `ams_${fetchedData?.type}_value_alert_level_blast_mode`
                        )?.value
                  }
                  label="Alert Level (Blast Mode)"
                  size="small"
                  disabled
                  fullWidth
                />
              </>
            )}
          </Box>
        </TabPanel>
      </TabContext>

      <Box display="flex" justifyContent="space-between">
        {item && (
          <AMSEModuleActionsButton
            item={item}
            onPurged={onPurged}
            onOpenHistory={onOpenHistory}
            onClose={onCancel}
            onSubmitted={() => {
              fetchData();
              onSubmitted?.();
            }}
          />
        )}

        <Box display="flex" justifyContent="end" gap={0.5} marginLeft="auto">
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <Button onClick={() => onEdit()}>Edit e-Module</Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default AMSEModuleNodeItemView;
