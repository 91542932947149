export const getBooleanValue = (
  value: boolean | number | string | null | undefined,
  falseValue: string | number | boolean = 'No',
  trueValue: string | number | boolean = 'Yes'
) => {
  if (value === true || value === 1 || value === '1' || value === 'true') {
    return trueValue;
  } else if (
    value === false ||
    value === 0 ||
    value === '0' ||
    value === 'false'
  ) {
    return falseValue;
  }
};
