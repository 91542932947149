import {AppBar, Box, Card} from '@mui/material';
import {ReactNode, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import Status from '../components/common/Status';
import {useAppSelector} from '../hooks/redux';
import reduxSelectors from '../redux/selectors';

interface Props {
  children: ReactNode;
}

export const AuthLayout = ({children}: Props) => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(reduxSelectors.auth.getLoggedIn);
  const auth = useAppSelector(reduxSelectors.auth.getAuth);

  useEffect(() => {
    if (auth.passwordToken) {
      navigate(`/auth/password-reset/${auth.passwordToken}`);
    } else if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn, auth.passwordToken]);

  return (
    <Box pt="64px" height="100vh">
      <AppBar
        position="absolute"
        sx={{
          background: (theme) =>
            theme.palette.mode === 'dark' ? theme.palette.grey[900] : '#FFF',
        }}
      />

      <Box
        minHeight="calc(100% - 30px)"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
      <Card sx={{textAlign: 'right'}}>
        <Box sx={{height: '30px'}}>
          <Status />
        </Box>
      </Card>
    </Box>
  );
};
