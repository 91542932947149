/* eslint-disable complexity */
import CellTowerIcon from '@mui/icons-material/CellTower';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {Box, Button, IconButton, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  CommunicationNode,
  CommunicationNodeCreateInputBody,
} from '../../interfaces/CommunicationNodeV2';
import {communicationNodeCreateInputSchema} from '../../scheme/yup/communication-node';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../common/Map';
import SnackbarMessages from '../common/SnackbarMessages';
import CommunicationNodeTypeSelect from '../selectors/CommunicationNodeTypeSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: () => void;
  onSubmitted?: (item: CommunicationNode) => void;
}

const CommunicationNodeItemCreate = ({
  locationCoordinates,
  isActiveModal,
  onCancel,
  onSubmitted,
}: Props) => {
  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: CommunicationNodeCreateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/node/communication-node`;
      const resp = await API.post<CommunicationNode>(endpoint, data);
      const message = `Communication Node successfully created`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (): CommunicationNodeCreateInputBody => ({
    name: null,
    status: 'active',
    external_id: null,
    node_type: null,
    zone_id: null,
    pos_lat: locationCoordinates?.lat
      ? +locationCoordinates.lat.toFixed(6)
      : null,
    pos_long: locationCoordinates?.lng
      ? +locationCoordinates.lng.toFixed(6)
      : null,
  });

  const formik = useFormik<CommunicationNodeCreateInputBody>({
    initialValues: getFormikValues(),
    validationSchema: communicationNodeCreateInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    if (isActiveModal && locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.pos_lat !== newPosLat ||
        formik.values.pos_long !== newPosLong
      ) {
        formik.setFieldValue('pos_lat', newPosLat);
        formik.setFieldValue('pos_long', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <CellTowerIcon sx={{color: 'primary.main'}} />
          <Box fontSize={24}>Create Communication Node</Box>
        </Box>
        {onCancel ? (
          <IconButton onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name ?? ''}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.external_id ?? ''}
          label="Network ID"
          size="small"
          name="external_id"
          fullWidth
          error={!!formik.touched.external_id && !!formik.errors.external_id}
          helperText={formik.touched.external_id && formik.errors.external_id}
          onChange={formik.handleChange}
        />
        <CommunicationNodeTypeSelect
          value={formik.values.node_type}
          label="Type"
          size="small"
          name="node_type"
          fullWidth
          error={!!formik.touched.node_type && !!formik.errors.node_type}
          helperText={formik.touched.node_type && formik.errors.node_type}
          onChange={formik.handleChange}
        />
        <ZoneSelect
          value={formik.values.zone_id}
          label="Section"
          size="small"
          fullWidth
          error={!!formik.touched.zone_id && !!formik.errors.zone_id}
          helperText={formik.touched.zone_id && formik.errors.zone_id}
          onChange={(v) => formik.setFieldValue('zone_id', v)}
        />
        <TextField
          value={formik.values.pos_lat ?? ''}
          label="Latitude (click on map or enter manually)"
          size="small"
          name="pos_lat"
          type="number"
          fullWidth
          error={!!formik.touched.pos_lat && !!formik.errors.pos_lat}
          helperText={formik.touched.pos_lat && formik.errors.pos_lat}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.pos_long ?? ''}
          label="Longitude (click on map or enter manually)"
          size="small"
          name="pos_long"
          type="number"
          fullWidth
          error={!!formik.touched.pos_long && !!formik.errors.pos_long}
          helperText={formik.touched.pos_long && formik.errors.pos_long}
          onChange={formik.handleChange}
        />
        <TextField
          value={300}
          label="Report Frequency (s)"
          size="small"
          disabled
          fullWidth
        />
      </Box>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunicationNodeItemCreate;
