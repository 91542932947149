import {SPEED_FORMATS} from '../../constants/common';
import {Actions} from './actions';
import {AppAction, AppState} from './types';

const fetchStateFromLocalstorage = () => {
  return {
    companyId: +(window.localStorage.getItem('companyId') || 0) || null,
  };
};

export const getAppInitialState: () => AppState = () => ({
  me: null,
  activeDraggableModal: null,
  myConfigurations: null,
  playedEventIds: [],
  muteSounds: false,
  printTheme: false,
  ...fetchStateFromLocalstorage(),
  backend: null,
  activedDashboard: null,
  inSubmittingInSavingDashboard: false,
  speedFormat: SPEED_FORMATS[0],
});

const appReducers = (state?: AppState, action?: AppAction) => {
  state = state ?? getAppInitialState();
  switch (action?.type) {
    case Actions.SetApp:
      return {...state, ...action.data};

    default:
      return state;
  }
};

export default appReducers;
