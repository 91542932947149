import {Grid, Paper} from '@mui/material';
import React from 'react';

import {DashboardLayout} from '../../layouts/DashboardLayout';
import {WidgetAvailableFieldsPanel} from './components/WidgetAvailableFieldsPanel';
import {WidgetDetailsEditPanel} from './components/WidgetDetailsEditPanel';
import {WidgetEditorTopBar} from './components/WidgetEditorTopBar';
import {WidgetMetricEditPanel} from './components/WidgetMetricEditPanel';

export const IndicatorWidgetEditor = () => {
  return (
    <DashboardLayout>
      <Grid
        container
        spacing={2}
        flex={1}
        display="flex"
        flexDirection="column"
      >
        <Grid item container>
          <WidgetEditorTopBar />
        </Grid>
        <Grid item container flex={1}>
          <Grid item xs={2.5} container component={Paper}>
            <WidgetAvailableFieldsPanel />
          </Grid>
          <Grid item container xs={7}>
            <WidgetMetricEditPanel />
          </Grid>
          <Grid item xs={2.5} component={Paper}>
            <WidgetDetailsEditPanel />
          </Grid>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
