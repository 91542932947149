import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Box, Divider, IconButton, Tooltip} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import ModalFixed from '../common/ModalFixed';
import ObjectsByCommunicationNodeTrackingExportExcel from '../dashboard-panels/CommtracNodeTrackingReports/ObjectsByCommunicationNodeTrackingExportExcel';
import {CommtracNodesReportData} from '../dashboard-panels/ConnectView/CommtracNodesReport';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  value?: CommtracNodesReportData;
  onSubmitted?: () => void;
}

const ObjectsByCommunicationNodeTrackingExportExcelButton = <
  T extends ComponentType,
>({
  // component,
  componentProps,
  children,
}: Props<T>) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Tooltip title="Export">
        <FileDownloadIcon {...componentProps} onClick={() => setIsOpened(true)}>
          {children}
        </FileDownloadIcon>
      </Tooltip>

      {isOpened ? (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" width="100%" gap={1.5}>
                <FileDownloadIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Export History</Box>
              </Box>
              <IconButton onClick={() => setIsOpened(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              position="relative"
              gap={3}
            >
              <Divider>Export</Divider>
              <ObjectsByCommunicationNodeTrackingExportExcel
                value={{
                  ...componentProps,
                  exportFields: [],
                  shownFields: [],
                  selectedIds: componentProps?.external_id,
                  params: {...componentProps},
                }}
              />
            </Box>
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default ObjectsByCommunicationNodeTrackingExportExcelButton;
