import {Assignment, CopyAllRounded, ShareOutlined} from '@mui/icons-material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import StarIcon from '@mui/icons-material/Star';
import {Button, Menu, MenuItem, Typography} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';
import {Link as RouterLink} from 'react-router-dom';

import {useAppSelector} from '../../hooks/redux';
import {Dashboard} from '../../interfaces/Dashboard';
import {checkPrivilegeAction} from '../../utils/dashboard';
import AccessControl from '../common/AccessControl';
import ModalFixed from '../common/ModalFixed';
import DashboardItemAssignItemModal from './DashboardItemAssignItemModal';
import DashboardItemDeleteModal from './DashboardItemDeleteModal';
import DashboardItemDuplicateModal from './DashboardItemDuplicateModal';
import DashboardItemFavoriteModal from './DashboardItemFavouriteModal';
import DashboardItemPrimaryModal from './DashboardItemPrimaryModal';
import DashboardItemShareModal from './DashboardItemShareModal';
import DashboardItemUpsert from './DashboardItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: Dashboard;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  isFetching: boolean;
  onSubmitted?: () => void;
  onDeleted?: () => void;
}

type Action =
  | 'update'
  | 'primary'
  | 'favourite'
  | 'delete'
  | 'duplicate'
  | 'assign'
  | 'share';

const DashboardItemActionsButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  isFetching,
  onSubmitted,
  onDeleted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [action, setAction] = useState<Action>();
  const me = useAppSelector(({app}) => app.me);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <Typography
          component={RouterLink}
          sx={{textDecoration: 'none'}}
          color="inherit"
          to={`/dashboards/${item.id}`}
        >
          <MenuItem>
            <OpenInBrowserIcon fontSize="small" sx={{mr: 1.5}} /> Open
          </MenuItem>
        </Typography>

        <AccessControl
          permissions={['patch::/dashboard/:id(\\d+)']}
          accessChecker={() => checkPrivilegeAction(me, item, 'EDIT')}
        >
          <MenuItem
            onClick={() => {
              setAction('update');
              setAnchorEl(null);
            }}
          >
            <EditIcon fontSize="small" sx={{mr: 1.5}} /> Edit
          </MenuItem>
        </AccessControl>

        {!item.is_primary ? (
          <AccessControl permissions={['post::/dashboard/:id(\\d+)/primary']}>
            <MenuItem
              onClick={() => {
                setAction('primary');
                setAnchorEl(null);
              }}
            >
              <BookmarkIcon fontSize="small" sx={{mr: 1.5}} /> Set as Primary
            </MenuItem>
          </AccessControl>
        ) : (
          <AccessControl permissions={['delete::/dashboard/:id(\\d+)/primary']}>
            <MenuItem
              onClick={() => {
                setAction('primary');
                setAnchorEl(null);
              }}
            >
              <BookmarkIcon fontSize="small" sx={{mr: 1.5}} /> Unset as Primary
            </MenuItem>
          </AccessControl>
        )}

        {!item.is_favourite ? (
          <AccessControl permissions={['post::/dashboard/:id(\\d+)/favourite']}>
            <MenuItem
              onClick={() => {
                setAction('favourite');
                setAnchorEl(null);
              }}
            >
              <StarIcon fontSize="small" sx={{mr: 1.5}} /> Set as Favorite
            </MenuItem>
          </AccessControl>
        ) : (
          <AccessControl
            permissions={['delete::/dashboard/:id(\\d+)/favourite']}
          >
            <MenuItem
              onClick={() => {
                setAction('favourite');
                setAnchorEl(null);
              }}
            >
              <StarIcon fontSize="small" sx={{mr: 1.5}} /> Unset as Favorite
            </MenuItem>
          </AccessControl>
        )}
        <AccessControl
          permissions={['patch::/dashboard/:id(\\d+)']}
          accessChecker={() => checkPrivilegeAction(me, item, 'SHARE')}
        >
          <MenuItem
            onClick={() => {
              setAction('share');
              setAnchorEl(null);
            }}
          >
            <ShareOutlined fontSize="small" sx={{mr: 1.5}} /> Share
          </MenuItem>
        </AccessControl>
        <AccessControl
          permissions={['delete::/dashboard/:id(\\d+)']}
          accessChecker={() => checkPrivilegeAction(me, item, 'DELETE')}
        >
          <MenuItem
            onClick={() => {
              setAction('delete');
              setAnchorEl(null);
            }}
          >
            <DeleteIcon fontSize="small" sx={{mr: 1.5}} /> Delete
          </MenuItem>
        </AccessControl>
        <AccessControl permissions={['post::/dashboard']}>
          <MenuItem
            onClick={() => {
              setAction('duplicate');
              setAnchorEl(null);
            }}
          >
            <CopyAllRounded fontSize="small" sx={{mr: 1.5}} /> Duplicate
          </MenuItem>
        </AccessControl>
        <AccessControl
          accessChecker={() => me?.type_id === 1 && item.user_id === me.id}
        >
          <MenuItem
            onClick={() => {
              setAction('assign');
              setAnchorEl(null);
            }}
          >
            <Assignment fontSize="small" sx={{mr: 1.5}} /> Assign To Product
          </MenuItem>
        </AccessControl>
      </Menu>

      <ModalFixed
        open={action === 'update'}
        onClose={() => setAction(undefined)}
      >
        <DashboardItemUpsert
          pk={item.id}
          item={item}
          onSubmitted={() => {
            onSubmitted?.();
            setAction(undefined);
          }}
          onClose={() => setAction(undefined)}
        />
      </ModalFixed>
      <DashboardItemPrimaryModal
        open={action === 'primary'}
        item={item}
        onSubmitted={onSubmitted}
        onClose={() => setAction(undefined)}
      />
      <ModalFixed
        open={action === 'duplicate'}
        onClose={() => setAction(undefined)}
      >
        <DashboardItemDuplicateModal
          item={item}
          onSubmitted={onSubmitted}
          onClose={() => setAction(undefined)}
        />
      </ModalFixed>
      <ModalFixed
        open={action === 'assign'}
        onClose={() => setAction(undefined)}
      >
        <DashboardItemAssignItemModal
          pk={item.id}
          item={item}
          onSubmitted={onSubmitted}
          onClose={() => setAction(undefined)}
        />
      </ModalFixed>

      <DashboardItemFavoriteModal
        open={action === 'favourite'}
        item={item}
        onSubmitted={onSubmitted}
        onClose={() => setAction(undefined)}
      />

      <DashboardItemDeleteModal
        open={action === 'delete'}
        item={item}
        onDeleted={onDeleted}
        onClose={() => setAction(undefined)}
      />

      <ModalFixed
        open={action === 'share'}
        onClose={() => setAction(undefined)}
      >
        <DashboardItemShareModal
          item={item}
          isEditing={false}
          isFetching={isFetching}
          onSubmitted={onSubmitted}
          onClose={() => setAction(undefined)}
        />
      </ModalFixed>
    </>
  );
};

export default DashboardItemActionsButton;
