import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  ActivationReasonType,
  AlarmLogNode,
} from '../../interfaces/AlarmLogNode';
import {alarmModulEnableDisableValidationSchema} from '../../scheme/yup/alarm-module';
import {networkIdFromMacAddress} from '../../utils/macAddress';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import AlarmUpdateStatusAlarmList from './AlarmUpdateStatusAlarmList';
import AlarmUpdateStatusGroupList from './AlarmUpdateStatusGroupList';
import AlarmUpdateStatusZoneList from './AlarmUpdateStatusZoneList';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  type: 'enable' | 'disable';
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  preSelected?: number[];
  onClose?: () => void;
  onSubmitted?: () => void;
  messageId?: number;
  externalId?: string;
}

const AVAILABLE_MODULE = [
  {
    value: 'selected',
    label: 'Selected',
  },
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'group',
    label: 'Group',
  },
  {
    value: 'section',
    label: 'Section',
  },
] as const;

const ALARM_TYPES = [
  {
    value: 'alarm',
    label: 'Alarm',
  },
  {
    value: 'warning',
    label: 'Warning',
  },
] as const;

type AlarmStatusInputBody = {
  type: 'enable' | 'disable';
  mode: 'alarm' | 'warning';
  module: 'selected' | 'all' | 'group' | 'section';
  zones: number[];
  groups: number[];
  alarm_modules: number[];
  reason: ActivationReasonType['key'] | null;
  reason_note?: string | null;
  module_all?: boolean;
  message_id?: number;
};

const AlarmUpdateStatusForm = <T extends ComponentType>({
  type,
  preSelected,
  onClose,
  onSubmitted,
  messageId,
  externalId,
}: Props<T>) => {
  console.log(messageId);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const alarmLogs = useAppSelector(({assets}) => assets.alarm_logs);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const matchedAlarmLog = useMemo(() => {
    if (externalId && alarmLogs) {
      return alarmLogs?.find(
        (it) =>
          networkIdFromMacAddress(it?.mac_address ?? '')?.toString() ===
          externalId
      );
    } else {
      return null;
    }
  }, [alarmLogs, externalId]);

  const formik = useFormik<AlarmStatusInputBody>({
    initialValues: {
      type: type,
      mode: 'alarm',
      module: 'selected',
      zones: [],
      groups: [],
      alarm_modules: matchedAlarmLog ? [matchedAlarmLog.id] : [],
      reason: null,
      reason_note: null,
      message_id: messageId,
    },
    validationSchema: alarmModulEnableDisableValidationSchema,
    onSubmit: async (data) => {
      setSubmittedInProgress(true);
      try {
        let payload = {};
        if (data.module === 'all') {
          payload = {
            ...payload,
            module_all: true,
          };
        } else if (data.module === 'selected') {
          payload = {
            ...payload,
            module_ids: data.alarm_modules,
            module_all: false,
          };
        } else if (data.module === 'group') {
          payload = {
            ...payload,
            group_ids: data.groups,
            module_all: false,
          };
        } else if (data.module === 'section') {
          payload = {
            ...payload,
            zone_ids: data.zones,
            module_all: false,
          };
        }
        if (type === 'enable') {
          payload = {
            ...payload,
            alarm: data.mode === 'alarm',
          };

          payload = {
            ...payload,
            reason: data.reason,
            reason_note: data.reason_note ?? null,
            // message_id: data.message_id,
          };

          // Should be considered with Roman
          try {
            if (data?.message_id) {
              const requestBody = {
                ids: {
                  alarm: [data.message_id],
                },
              };
              const endpoint = `${apiBaseUrl}/event/acknowledge`;
              await API.patch<AlarmLogNode>(endpoint, requestBody);
            }
          } catch (err) {
            console.log(err);
          }
          //

          const resp = await API.patch(
            `${apiBaseUrl}/alarm-module/alarm/enable`,
            payload
          );
          const successMessage =
            resp?.data?.message ?? 'Succeed in enabling module.';
          enqueueSnackbar(successMessage, {
            variant: 'success',
            action: (key) => (
              <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
            ),
          });
        } else {
          const resp = await API.patch(
            `${apiBaseUrl}/alarm-module/alarm/disable`,
            payload
          );
          const successMessage =
            resp?.data?.message ?? 'Succeed in disabling module.';
          enqueueSnackbar(successMessage, {
            variant: 'success',
            action: (key) => (
              <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
            ),
          });
        }
        onSubmitted?.();
        onClose?.();
      } catch (error) {
        const messages = getMessagesFromApiError(error);
        setSubmitErrors(messages);
      } finally {
        setSubmittedInProgress(false);
      }
    },
  });

  const activationReason: ActivationReasonType[] = useMemo(() => {
    if (formik.values.mode === 'warning') {
      return [
        {
          key: 'emergency',
          label: 'Warning',
        },
        {
          key: 'other',
          label: 'Other',
        },
      ];
    } else {
      return [
        {
          key: 'evacuate',
          label: 'Evacuate',
        },
        {
          key: 'other',
          label: 'Other',
        },
      ];
    }
  }, [formik.values.mode]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        {matchedAlarmLog ? (
          <Typography variant="h6" component="h2" flex={1}>
            Enable Alarm Module [{matchedAlarmLog.name}]
          </Typography>
        ) : (
          <Typography variant="h6" component="h2" flex={1}>
            {type === 'enable' ? 'Enable Alarm' : 'Disable Alarm'}
          </Typography>
        )}

        <IconButton
          onClick={() => {
            onClose?.();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {submitErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Paper sx={{p: 2, px: 3, mb: 2}}>
        <Grid container width="100%">
          <FormControl
            sx={{
              width: '100%',
            }}
          >
            <Box fontSize={16} mb={2}>
              Select the modules you want apply:
            </Box>
            <RadioGroup
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                gap: 3,
              }}
              defaultValue="selected"
              name="radio-buttons-group"
              onChange={(e) => {
                formik.setFieldValue('module', e.target.value);
              }}
            >
              {AVAILABLE_MODULE.map((it, index: number) => (
                <FormControlLabel
                  key={index}
                  value={it.value}
                  control={<Radio />}
                  label={it.label}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </Paper>
      {type === 'enable' && (
        <Paper sx={{p: 2, px: 3, mb: 2}}>
          <Grid container width="100%">
            <FormControl
              sx={{
                width: '100%',
              }}
            >
              <Box fontSize={16} mb={2}>
                Select the Alarm Type:
              </Box>
              <FormHelperText
                error={!!formik.touched.mode && !!formik.errors.mode}
              >
                {formik.touched.mode && formik.errors.mode}
              </FormHelperText>
              <RadioGroup
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 3,
                }}
                defaultValue="alarm"
                name="alarm-type-groups"
                onChange={(e) => {
                  formik.setFieldValue('mode', e.target.value);
                }}
              >
                {ALARM_TYPES.map((it, index: number) => (
                  <FormControlLabel
                    key={index}
                    value={it.value}
                    control={<Radio />}
                    label={it.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Paper>
      )}
      {formik.values.module === 'section' && (
        <AlarmUpdateStatusZoneList
          formik={formik}
          onSelectItems={(sectionIds: number[]) => {
            formik.setFieldValue('zones', sectionIds);
          }}
        />
      )}
      {formik.values.module === 'group' && (
        <>
          <AlarmUpdateStatusGroupList
            formik={formik}
            onSelectItems={(sectionIds: number[]) => {
              formik.setFieldValue('groups', sectionIds);
            }}
          />
        </>
      )}
      {(formik.values.module === 'all' ||
        formik.values.module === 'selected') && (
        <AlarmUpdateStatusAlarmList
          formik={formik}
          preSelected={preSelected}
          onSelectItems={(sectionIds: number[]) => {
            formik.setFieldValue('alarm_modules', sectionIds);
          }}
        />
      )}
      {type === 'enable' && (
        <Autocomplete
          value={
            activationReason.find((i) => i.key === formik.values.reason) ?? null
          }
          fullWidth
          options={activationReason}
          isOptionEqualToValue={(option, value) => option.key === value?.key}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Reason"
              size="small"
              error={!!formik.touched.reason && !!formik.errors.reason}
              helperText={formik.touched.reason && formik.errors.reason}
            />
          )}
          onChange={(event, value) =>
            formik.setFieldValue('reason', value?.key)
          }
        />
      )}
      {formik.values.reason === 'other' && (
        <TextField
          value={formik.values.reason_note}
          label="Description"
          size="small"
          name="reason_note"
          multiline
          rows={3}
          fullWidth
          error={!!formik.touched.reason_note && !!formik.errors.reason_note}
          helperText={formik.touched.reason_note && formik.errors.reason_note}
          onChange={formik.handleChange}
        />
      )}
      <Box display="flex" justifyContent="end" gap={0.5}>
        <Button
          onClick={() => {
            onClose?.();
          }}
        >
          Cancel
        </Button>

        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ml: 1}}
          >
            {type === 'enable' ? 'Enable' : 'Disable'}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AlarmUpdateStatusForm;
