import {Box, Modal, ModalProps} from '@mui/material';

interface Props extends ModalProps {
  width?: number;
}

const ModalFixed = ({width = 700, ...props}: Props) => {
  return (
    <Modal {...props}>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        width={width}
        bgcolor="background.paper"
        border="1px solid #9b9b9b"
        boxShadow={24}
        overflow="auto"
        maxHeight="90%"
        sx={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        {props.children}
      </Box>
    </Modal>
  );
};

export default ModalFixed;
