import * as yup from 'yup';

import {
  getIsCommtracExternalIdVisible,
  //getIsEmployeeAtestVisible,
  getIsEmployeeHasMotionSensorVisible,
  getIsEmployeeMc2FlagVisible,
  //getIsEmployeeSensorNumberVisible,
  getIsMacAddressVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  //getIsWifiEnabledVisible,
} from '../../utils/commtrac-nodes';
import {
  getMaxLengthMessage,
  getMaxMessage,
  getMinMessage,
  nameMessage,
} from './consts';
import {
  macAddressValidator,
  maxDescriptionLength,
  maxNameLength,
  maxNetworkId,
  maxPersonIdLength,
  maxProximityId,
  nameValidator,
} from './utils';

export const assetHumanBaseInputSchema = yup.object().shape({
  is_proximity: yup.boolean().required(),
  is_commtrac: yup.boolean().nullable(),
  wifi_enabled: yup.boolean().nullable().required(),
  nickname: yup
    .string()
    .nullable()
    .required('Field is required')
    .matches(nameValidator.regex, nameMessage)
    .min(1)
    .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
  first_name: yup
    .string()
    .nullable()
    .required('Field is required')
    .matches(nameValidator.regex, nameMessage)
    .min(1)
    .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
  last_name: yup
    .string()
    .nullable()
    .required('Field is required')
    .matches(nameValidator.regex, nameMessage)
    .min(1)
    .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
  birth_date: yup.date().typeError('Field should be valid date').nullable(),
  description: yup
    .string()
    .nullable()
    .min(1)
    .max(maxDescriptionLength, getMaxLengthMessage(maxDescriptionLength)),
  type_id: yup.number().nullable().required('Field is required'),
  zone_id: yup.number().nullable().required('Field is required'),
  shift_id: yup.number().nullable().required('Field is required'),
  status: yup.string().nullable().required('Field is required'),
  personal_id: yup
    .string()
    .nullable()
    .min(1)
    .max(maxPersonIdLength, getMaxLengthMessage(maxPersonIdLength)),
  external_id: yup
    .number()
    .nullable()
    .integer()
    .min(1, getMinMessage(1))
    .max(maxProximityId, getMaxMessage(maxProximityId))
    .when(['is_proximity'], {
      is: (is_proximity: boolean) => is_proximity,
      then: (schema) => schema.required('Field is required'),
    }),
  mc2_flag: yup
    .number()
    .integer()
    .min(0)
    .max(1)
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsEmployeeMc2FlagVisible(is_commtrac, wifi_enabled),
      then: (schema) => schema.required('Field is required'),
    }),
  /*atest: yup
    .number()
    .integer()
    .min(0)
    .max(1)
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsEmployeeAtestVisible(is_commtrac, wifi_enabled),
      then: (schema) => schema.required('Field is required'),
    }),*/
  /*sensors_number: yup
    .number()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled', 'atest'], {
      is: (
        is_commtrac: boolean | null,
        wifi_enabled: boolean | null,
        atest: 0 | 1 | null
      ) => getIsEmployeeSensorNumberVisible(is_commtrac, wifi_enabled, atest),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(4, getMaxMessage(4))
          .nullable()
          .required('Field is required'),
    }),*/
  has_motion_sensor: yup
    .number()
    .integer()
    .min(0)
    .max(1)
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsEmployeeHasMotionSensorVisible(is_commtrac, wifi_enabled),
      then: (schema) => schema.required('Field is required'),
    }),
  min_voltage: yup
    .number()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsMinVoltageVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMaxMessage(100 / 10))
          .required('Field is required'),
    }),
  max_voltage: yup
    .number()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsMaxVoltageVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMaxMessage(100 / 10))
          .required('Field is required'),
    }),
});

export const assetHumanCreateInputSchema = assetHumanBaseInputSchema.shape({
  commtrac_external_id: yup
    .number()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsCommtracExternalIdVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(1, getMinMessage(1))
          .max(maxNetworkId, getMaxMessage(maxNetworkId))
          .required('Field is required'),
    }),
  mac_address: yup
    .string()
    .nullable()
    .when(['is_commtrac', 'wifi_enabled'], {
      is: (is_commtrac: boolean | null, wifi_enabled: boolean | null) =>
        getIsMacAddressVisible(is_commtrac, wifi_enabled),
      then: (schema) =>
        schema
          .matches(
            macAddressValidator.regex,
            macAddressValidator.message.replace('%1', 'Mac Address')
          )
          .required('Field is required'),
    }),
});

export const assetHumanUpdateInputSchema = assetHumanBaseInputSchema.shape({});

export const assetHumanUpdateNetworkInputSchema = yup.object().shape({
  wifi_enabled: yup.boolean().nullable(),
  commtrac_external_id: yup
    .number()
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsCommtracExternalIdVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(1, getMinMessage(1))
          .max(maxNetworkId, getMaxMessage(maxNetworkId))
          .required('Field is required'),
    }),
  mac_address: yup
    .string()
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsMacAddressVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .matches(
            macAddressValidator.regex,
            macAddressValidator.message.replace('%1', 'Mac Address')
          )
          .required('Field is required'),
    }),
});
