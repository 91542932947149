import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  CheckoutStationDetailsItem,
  CheckoutStationDetailsListQuery,
  CheckoutStationDetailsListResponse,
} from '../../../interfaces/CheckoutStationDetails';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {ShiftSelect} from '../../selectors/ShiftSelect';

interface Props {
  value?: CheckoutStationDetailsReportGridData;
  onChange?: (value: CheckoutStationDetailsReportGridData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'id',
  'battery_voltage',
  'test_time',
  'external_id',
  'serial_number',
  'name',
  'firmware_version',
  'failure_reason',
  'test_type',
  'retry_count',
];

export interface CheckoutStationDetailsReportGridData {
  refreshInterval?: number | null;
  shownFields: string[];
  params: {
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
    date_start?: string;
    date_end?: string;
    shift_id?: number | null;
  };
}

export const getCheckoutStationDetailsReportGridData =
  (): CheckoutStationDetailsReportGridData => ({
    shownFields: DEFAULT_SHOWN_FIELDS,
    params: {},
  });

const CheckoutStationDetailsReportGrid = ({value, onChange}: Props) => {
  const config = useMemo(
    () => value ?? getCheckoutStationDetailsReportGridData(),
    [value]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<CheckoutStationDetailsListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<CheckoutStationDetailsListQuery>(
    () => ({
      date_start: config.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
      date_end: config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      page: config.params?.page ?? 0,
      limit: config.params?.limit ?? 25,
      order: config.params?.order ?? 'id',
      dir: config.params?.dir ?? 'DESC',
      shift_id: config.params?.shift_id ?? null,
    }),
    [config.params]
  );

  const fetchData = useCallback(
    async (params: CheckoutStationDetailsListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const resp = await API.get<CheckoutStationDetailsListResponse>(
          `${apiBaseUrl}/report/checkout-details-grid`,
          {params}
        );
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, config.refreshInterval);

  /*************/
  /* data grid */
  /*************/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<CheckoutStationDetailsItem>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'battery_voltage',
      headerName: 'Battery Voltage',
      sortable: true,
    },
    {
      field: 'test_time',
      headerName: 'Test Time',
      sortable: true,
    },
    {
      field: 'external_id',
      headerName: 'External ID',
      sortable: true,
    },
    {
      field: 'serial_number',
      headerName: 'Serial Number',
      sortable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'firmware_version',
      headerName: 'Firmware Version',
      sortable: true,
    },
    {
      field: 'failure_reason',
      headerName: 'Failure Reason',
      sortable: true,
    },
    {
      field: 'test_type',
      headerName: 'Test Type',
      sortable: true,
    },
    {
      field: 'retry_count',
      headerName: 'Retry Count',
      sortable: true,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        py={2}
      >
        <Box flexGrow={2} display="flex">
          <Box minWidth={400} mr={1}>
            <DateRangeSelect
              value={[
                dayjs(params.date_start).toDate(),
                dayjs(params.date_end).toDate(),
              ]}
              size="small"
              onChange={(v) => {
                onChange?.(
                  update(config, {
                    params: {
                      date_start: {
                        $set: v?.[0]
                          ? dayjs(v?.[0]).format('YYYY-MM-DD')
                          : undefined,
                      },
                      date_end: {
                        $set: v?.[1]
                          ? dayjs(v?.[1]).format('YYYY-MM-DD')
                          : undefined,
                      },
                    },
                  })
                );
              }}
            />
          </Box>

          <ShiftSelect
            value={config.params?.shift_id}
            size="small"
            nullLabel="All Shifts"
            fullWidth
            sx={{width: 200}}
            onChange={(v) => {
              onChange?.(
                update(config, {
                  params: {
                    shift_id: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>

        <Box display="flex">
          <ButtonGroup>
            <Button size="small" onClick={() => params && fetchData(params)}>
              <RefreshIcon />
            </Button>

            <AutoRefreshSelect
              value={config.refreshInterval ?? null}
              onChange={(v) => {
                onChange?.(
                  update(config, {
                    refreshInterval: {
                      $set: v,
                    },
                  })
                );
              }}
            />

            <Button
              size="small"
              onClick={() => dataGridRef.current?.printTable()}
            >
              <PrintIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Backdrop
        open={fetchedInProgress}
        sx={{position: 'absolute', zIndex: 1199}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        shownFields={shownFields}
        pagination
        paginationMode="server"
        size="small"
        sortBy={
          params?.order
            ? {
                field: params?.order,
                dir: params?.dir === 'DESC' ? 'desc' : 'asc',
              }
            : null
        }
        sortingMode="server"
        page={params?.page}
        pageSize={params?.limit}
        rowCount={fetchedData?.count}
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        onPageChange={(v) => {
          onChange?.(
            update(config, {
              params: {
                page: {
                  $set: v,
                },
              },
            })
          );
        }}
        onPageSizeChange={(v) => {
          onChange?.(
            update(config, {
              params: {
                page: {
                  $set: 0,
                },
                limit: {
                  $set: v,
                },
              },
            })
          );
        }}
        onSort={(v) => {
          onChange?.(
            update(config, {
              params: {
                order: {
                  $set: v?.field,
                },
                dir: {
                  $set: v?.dir === 'desc' ? 'DESC' : 'ASC',
                },
              },
            })
          );
        }}
        onShownFieldsChange={setShownFields}
      />
    </Box>
  );
};

export default CheckoutStationDetailsReportGrid;
