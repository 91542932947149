import {Box, CircularProgress, Grid, Typography} from '@mui/material';
import React from 'react';

import {BaseMetric, BaseMetricProps} from './BaseMetric';

interface Props extends BaseMetricProps {
  value: number | string;
  color?: string;
}

export const SimpleProgressMetric = ({
  value,
  color = 'success',
  ...rest
}: Props) => {
  return (
    <BaseMetric {...rest}>
      <Grid item xs={12} textAlign="center">
        <Box position="relative" display="inline-flex" sx={{py: 2}}>
          <CircularProgress
            variant="determinate"
            value={Number(value)}
            size={90}
            sx={{color}}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6">{`${Math.round(Number(value))}%`}</Typography>
          </Box>
        </Box>
      </Grid>
    </BaseMetric>
  );
};
