import {Button, ButtonGroup} from '@mui/material';
import {t} from 'i18next';
import {useEffect, useState} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DashboardPanelActionsSlot} from '../DashboardPanelActionsSlot';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import MachineOverviewReportBar from './MachineOverviewReportBar';
import MachineOverviewReportLine from './MachineOverviewReportLine';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export const MachineOverviewReport = (props: Props) => {
  const [panel] = usePanel();

  const [chartType, setChartType] = useState<"bar" | "line">(props.value?.chartType ?? "bar");

  useEffect(() => {
    props?.onUpdate?.({...props.value, chartType});
  }, [chartType]);

  return (
    <>
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <DashboardPanelActionsSlot>
        <ButtonGroup>
          <Button
            size="small"
            variant={chartType === 'bar' ? 'contained' : 'outlined'}
            onClick={() => setChartType("bar")}
          >
            Bar
          </Button>

          <Button
            size="small"
            variant={chartType === 'line' ? 'contained' : 'outlined'}
            onClick={() => setChartType("line")}
          >
            Graph
          </Button>
        </ButtonGroup>
      </DashboardPanelActionsSlot>

      {chartType === 'bar' ? (
        <MachineOverviewReportBar
          value={props.value}
          onUpdate={props.onUpdate}
        />
      ) : chartType === 'line' ? (
        <MachineOverviewReportLine
          value={props.value}
          onUpdate={props.onUpdate}
        />
      ) : null}
    </>
  );
};
