import AddIcon from '@mui/icons-material/Add';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PrintIcon from '@mui/icons-material/Print';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Typography} from '@mui/material';
import {useRef, useState} from 'react';

import AccessControl from '../../components/common/AccessControl';
import UserImportExportButton from '../../components/users/UserImportExportButton';
import UserItemUpsertButton from '../../components/users/UserItemUpsertButton';
import {UserList, UserListRef} from '../../components/users/UserList';
import {DashboardLayout} from '../../layouts/DashboardLayout';

const AdminUserListPage = () => {
  const listRef = useRef<UserListRef | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <DashboardLayout>
      <AccessControl permissions={['get::/user']}>
        <Box
          display="flex"
          flexDirection="column"
          p={2}
          gap={0.5}
          height="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              id="modal-modal-title"
              component="h1"
              variant="h5"
              sx={{mb: 2}}
            >
              Administration - Users
            </Typography>

            <Box
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="end"
            >
              <LoadingButton
                color="primary"
                loading={isRefreshing}
                sx={{p: 1, minWidth: 0}}
                onClick={async () => {
                  setIsRefreshing(true);
                  await listRef?.current?.fetch?.();
                  setIsRefreshing(false);
                }}
              >
                <RestartAltIcon />
              </LoadingButton>

              <LoadingButton
                color="primary"
                sx={{p: 1, minWidth: 0}}
                onClick={() =>
                  listRef.current?.dataGridRef.current?.printTable()
                }
              >
                <PrintIcon />
              </LoadingButton>

              <AccessControl permissions={['post::/user']}>
                <UserItemUpsertButton
                  component={Button}
                  componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
                  onSubmitted={() => listRef?.current?.fetch?.()}
                >
                  Add User
                </UserItemUpsertButton>
              </AccessControl>

              <UserImportExportButton
                component={Button}
                componentProps={{
                  variant: 'outlined',
                  startIcon: <ImportExportIcon />,
                }}
                onSubmitted={() => listRef?.current?.fetch?.()}
              >
                Import/Export
              </UserImportExportButton>
            </Box>
          </Box>

          <UserList ref={listRef} />
        </Box>
      </AccessControl>
    </DashboardLayout>
  );
};

export default AdminUserListPage;
