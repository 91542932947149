import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {GridCloseIcon} from '@mui/x-data-grid';
import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  DashboardEntity,
  DashboardPanelData,
} from '../../../interfaces/Dashboard';
import {HazardEventLog} from '../../../interfaces/HazardAILog';
import ModalFixed from '../../common/ModalFixed';
import HazardAIDetectionLogReport, {
  getHazardDectionAIReportData,
  HazardDetectionAIReportData,
} from './HazardAIDetectionLogReport';
interface Props {
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value?: DashboardPanelData) => void;
}

interface HazardAIDetectionLogData {
  viewType: 'hazard_ai_detection_log';
  title: string;
  hazard_ai_detection_log: HazardDetectionAIReportData;
}

const getHazardAIDetectionLogData = (): HazardAIDetectionLogData => ({
  viewType: 'hazard_ai_detection_log',
  title: 'HazardAI Detection Log',
  hazard_ai_detection_log: getHazardDectionAIReportData(),
});
export const HazardAIDectionLog = ({value, entities, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as HazardAIDetectionLogData)
        : getHazardAIDetectionLogData(),
    [value]
  );
  const [aiLogId, setAILogId] = useState<number | null | undefined>();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isFetchingMedia, setIsFetchingMedia] = useState(false);
  const [fetchedMedia, setFetchedMedia] = useState<HazardEventLog | null>(null);
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const fetchMedia = useCallback(
    async (id: number) => {
      if (id) {
        try {
          setIsFetchingMedia(true);
          const endpoint = `${apiBaseUrl}/hazard-ai/detection-event/${id}`;
          const resp = await API.get<any>(endpoint);
          setFetchedMedia(resp?.data ?? null);
        } catch (error: any) {
          const messages = getMessagesFromApiError(error);
          setFetchedErrors(messages);
        } finally {
          setIsFetchingMedia(false);
        }
      }
    },
    [aiLogId]
  );

  const hasConnectView = useMemo(
    () =>
      entities?.map((e) => e.panel?.code).includes('ConnectView') ||
      entities?.map((e) => e.panel?.code).includes('HazardAI'),
    [entities]
  );

  useEffect(() => {
    if (aiLogId) {
      fetchMedia(aiLogId);
    }
  }, [aiLogId]);

  return (
    <>
      <HazardAIDetectionLogReport
        value={config.hazard_ai_detection_log}
        hasConnectView={hasConnectView}
        onUpdate={(v) => {
          onUpdate?.(
            update(value ?? getHazardAIDetectionLogData(), {
              hazard_ai_detection_log: {
                $set: v ?? getHazardDectionAIReportData(),
              },
            })
          );
        }}
        onClickMedia={(id?: number) => {
          setAILogId(id);
          setIsOpened(true);
        }}
      />
      {isOpened && aiLogId ? (
        <ModalFixed open={isOpened} width={window?.innerWidth - 100}>
          <Box
            p={0}
            sx={{
              display: 'flex',
              maxHeight: '90vh',
              height: '100vh',
              img: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                objectPosition: 'center',
                objectFit: 'contain',
              },
              video: {
                width: '100%',
                height: '100%',
                objectPosition: 'center',
                objectFit: 'contain',
              },
            }}
          >
            <IconButton
              onClick={() => setIsOpened(false)}
              sx={{
                zIndex: 1,
                position: 'absolute',
                right: 10,
                top: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <GridCloseIcon />
            </IconButton>
            <Box width="100%" height="100%">
              {fetchedErrors?.map((error, index) => (
                <Alert key={index} severity="error" sx={{mb: 2}}>
                  {error}
                </Alert>
              ))}
              {!isFetchingMedia && fetchedMedia && fetchedMedia.image && (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                  }}
                  display="flex"
                  dangerouslySetInnerHTML={{__html: fetchedMedia.image}}
                ></Box>
              )}
            </Box>
            <Backdrop
              open={isFetchingMedia}
              sx={{position: 'absolute', zIndex: 1199}}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};
