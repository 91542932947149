import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import isEqual from 'lodash/isEqual';
import {useEffect, useMemo, useState} from 'react';

import {isPresent} from '../../utils/type-guards';
import {DateSelect} from './DateSelect';

type Props = {
  labels?: [string, string];
  value?: [Date | null, Date | null];
  disabled?: boolean;
  size?: 'small' | 'medium';
  maxDays?: number;
  maxDate?: Date | null;
  onChange?: (value: [Date | null, Date | null]) => any;
};

export const DateRangeSelect = ({
  labels,
  value,
  disabled,
  size,
  maxDays = 90,
  maxDate = dayjs().toDate(),
  onChange,
}: Props) => {
  const initialStartDate = useMemo(
    () =>
      dayjs(value?.[0] ?? undefined)
        .startOf('day')
        .valueOf(),
    [value?.[0]]
  );
  const initialEndDate = useMemo(
    () =>
      dayjs(value?.[1] ?? undefined)
        .startOf('day')
        .valueOf(),
    [value?.[1]]
  );
  const initialDays = useMemo(
    () => dayjs(initialEndDate).diff(initialStartDate, 'day'),
    [initialStartDate, initialEndDate]
  );
  const initialIsValidRange = useMemo(
    () => initialDays >= 0 && initialDays <= maxDays,
    [initialDays, maxDays]
  );

  const [startDate, setStartDate] = useState<number | null>(initialStartDate);
  const [endDate, setEndDate] = useState<number | null>(initialEndDate);
  const days = useMemo(
    () => dayjs(endDate ?? undefined).diff(startDate ?? undefined, 'day'),
    [startDate, endDate]
  );
  const isValidRange = useMemo(
    () => days >= 0 && days <= maxDays,
    [days, maxDays]
  );

  const closeAdjustPopup = () => {
    if (initialIsValidRange) {
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
    } else {
      setStartDate(dayjs().valueOf());
      setEndDate(dayjs().valueOf());
    }
  };

  useEffect(() => {
    setStartDate(initialStartDate);
    setEndDate(initialEndDate);
  }, [initialStartDate, initialEndDate]);

  useEffect(() => {
    if (isValidRange) {
      const newValue: [Date | null, Date | null] = [
        dayjs(startDate).toDate(),
        dayjs(endDate).toDate(),
      ];
      if (!isEqual(value, newValue)) {
        onChange?.(newValue);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <DateSelect
              value={value?.[0]}
              disabled={disabled}
              label={labels?.[0] ?? 'Start time'}
              renderInput={{
                size,
                fullWidth: true,
              }}
              maxDate={maxDate}
              onChange={(v) => setStartDate(v?.valueOf() ?? null)}
            />
          </Grid>

          <Grid item xs={6}>
            <DateSelect
              value={value?.[1]}
              disabled={disabled}
              renderInput={{
                size,
                fullWidth: true,
              }}
              label={labels?.[0] ?? 'End time'}
              maxDate={maxDate}
              onChange={(v) => setEndDate(v?.valueOf() ?? null)}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      <Dialog
        open={!isValidRange}
        onClose={() => closeAdjustPopup()}
        disableEnforceFocus
      >
        {days > 0 ? (
          <>
            <DialogTitle>Adjust Date</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Date interval is greater than {maxDays} days
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  setStartDate(
                    dayjs(endDate).subtract(maxDays, 'day').valueOf()
                  );
                  setEndDate(dayjs(endDate ?? undefined).valueOf());
                }}
              >
                Adjust Start Date
              </Button>

              <Button
                onClick={() => {
                  setStartDate(dayjs(startDate ?? undefined).valueOf());
                  setEndDate(
                    dayjs
                      .min(
                        [
                          dayjs(startDate).add(maxDays, 'day'),
                          maxDate ? dayjs(maxDate) : null,
                        ].filter(isPresent)
                      )
                      .valueOf()
                  );
                }}
              >
                Adjust End Date
              </Button>

              <Button onClick={() => closeAdjustPopup()}>Cancel</Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle>Error Occured</DialogTitle>

            <DialogContent>
              <DialogContentText>
                End date should exceed start date. Please input correct date
                range.
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={() => closeAdjustPopup()}>OK</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
