import { CopyAllRounded } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { SnackbarKey, useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import API, { getMessagesFromApiError } from "../../api/axios";
import { apiBaseUrl } from "../../api/urls";
import {
  Dashboard,
  DashboardUpsertInputBody,
} from "../../interfaces/Dashboard";
import { CloseSnackbarButton } from "../common/CloseSnackbarButton";
import SnackbarMessages from "../common/SnackbarMessages";

interface Props {
  pk?: number;
  item?: Dashboard;
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: (item: Dashboard) => void;
}

const DashboardItemDuplicateModal = ({
  pk,
  item,
  prefetch,
  onClose,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const navigation = useNavigate();
  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<Dashboard>(`${apiBaseUrl}/dashboard/${pk}`);
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: DashboardUpsertInputBody) => {
    setSubmittedInProgress(true);
    try {
      const payload = {
        name: data.name
      };
      const endpoint = `${apiBaseUrl}/dashboard/${item?.id}/duplicate`;
      const resp = await API.post<Dashboard>(endpoint, payload);
      const message = `Dashboard successfully duplicated.`;
      enqueueSnackbar(message, {
        variant: "success",
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });

      onSubmitted?.(resp.data);
      navigation(`/dashboards/${resp?.data?.id}`);
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: "error",
        action: (key: SnackbarKey | undefined) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object().shape({
    name: yup.string().nullable().required("Field is required"),
  });

  const getFormikValues = (item?: Dashboard): DashboardUpsertInputBody => ({
    name: item?.name ?? null,
  });

  const formik = useFormik<DashboardUpsertInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" width="100%" gap={1.5}>
          <CopyAllRounded sx={{ color: "primary.main" }} />
          <Box fontSize={24}>Duplicate Dashboard</Box>
        </Box>
        {onClose ? (
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Backdrop open={fetchedInProgress} sx={{ position: "absolute" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{" "}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name ?? ""}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
      </Box>
      <Box display="flex" justifyContent="end" gap={0.5}>
        {onClose ? <Button onClick={() => onClose()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ ml: 1 }}
          >
            Duplicate
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardItemDuplicateModal;
