import {PasswordReset} from '../../components/auth/PasswordReset';
import {AuthLayout} from '../../layouts/AuthLayout';

export const PasswordResetPage = () => {
  return (
    <AuthLayout>
      <PasswordReset />
    </AuthLayout>
  );
};
