import {CommunicationNode} from '../../interfaces/CommunicationNodeV2';
import {MapLatLangCoordinates} from '../common/Map';
import CommunicationNodeItem from './CommunicationNodeItem';
import CommunicationNodeItemCreate from './CommunicationNodeItemCreate';

interface Props {
  pk?: number;
  item?: CommunicationNode;
  mode?: 'view' | 'update' | 'ack';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (externalId: number, type: 'cn' | 'commtracNodeByCn') => void;
}

const CommunicationNodeItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  locationCoordinates,
  isActiveModal,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <CommunicationNodeItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      locationCoordinates={locationCoordinates}
      isActiveModal={isActiveModal}
      onClose={onClose}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
      onOpenHistory={onOpenHistory}
    />
  ) : (
    <CommunicationNodeItemCreate
      locationCoordinates={locationCoordinates}
      isActiveModal={isActiveModal}
      onCancel={onClose}
      onSubmitted={onSubmitted}
    />
  );
};

export default CommunicationNodeItemUpsert;
