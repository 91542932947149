import CloseIcon from '@mui/icons-material/Close';
import {Box, BoxProps} from '@mui/material';

import TruncatedText from './TruncantedText';

interface Props extends BoxProps {
  name?: string | null;
  onClose?: () => void;
}

const TabLabel = ({name, onClose, ...boxProps}: Props) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      maxWidth="100%"
      gap={1}
      {...boxProps}
    >
      <TruncatedText>{name}</TruncatedText>
      {onClose ? (
        <CloseIcon
          fontSize="small"
          onClick={(event) => {
            event.stopPropagation();
            onClose?.();
          }}
        />
      ) : null}
    </Box>
  );
};

export default TabLabel;
