import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {WifiPointLongTermHistoryListQuery} from '../../../interfaces/WifiPointLongTermHistory';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props {
  value?: WifiPointLongTermHistoryListQuery;
}

const WifiPointLongTermTrackingExportExcel = ({value}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const exportFieldOptions = [
    {field: 'id', label: 'ID', hidden: false},
    {field: 'serial_number', label: 'Serial Number', hidden: false},
    {field: 'date', label: 'Date', hidden: false},
    {field: 'min', label: 'Min', hidden: false},
    {field: 'max', label: 'Max', hidden: false},
    {field: 'med', label: 'Median', hidden: false},
    {field: 'avg', label: 'Average', hidden: false},
  ];

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);

    try {
      const params = {
        export: 'excel',
        fields,
        id: value?.ID,
        date_start: value?.date_start,
        date_end: value?.date_end,
      };

      const resp = await API.get(
        `${apiBaseUrl}/node/${value?.ID}/wifi-point/long-events`,
        {
          params,
          responseType: 'blob',
        }
      );
      saveFile(
        resp.data,
        `events_${params?.date_start ?? dayjs().format('YYYY-MM-DD')}_${params.date_end ?? dayjs().format('YYYY-MM-DD')}.xlsx`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => checkFields()}
        >
          Export to Excel
        </LoadingButton>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={exportFieldOptions}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={1}
                height="auto"
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default WifiPointLongTermTrackingExportExcel;
