import CloseIcon from '@mui/icons-material/Close';
import GroupIcon from '@mui/icons-material/Group';
import {Box, Divider, IconButton} from '@mui/material';
import {useEffect, useMemo, useRef, useState} from 'react';

import {useAppSelector} from '../../hooks/redux';
import AccessControl from '../common/AccessControl';
import AssetHumanGroupMessageCreate from './AssetHumanGroupMessageCreate';
import AssetHumanGroupMessageList, {
  AssetHumanGroupMessageListRef,
} from './AssetHumanGroupMessageList';

interface Props {
  groupId?: number | null;
  onClose?: () => void;
}

const AssetHumanGroupChat = ({groupId: initialGroupId, onClose}: Props) => {
  const [groupId, setGroupId] = useState(initialGroupId);
  const assetHumans = useAppSelector(({assets}) => assets.asset_human_groups);
  const assetHuman = useMemo(
    () => (groupId ? assetHumans.find((i) => i.id === groupId) : null),
    [assetHumans, groupId]
  );

  const listRef = useRef<AssetHumanGroupMessageListRef>(null);

  useEffect(() => {
    if (groupId !== initialGroupId) {
      setGroupId(initialGroupId);
    }
  }, [initialGroupId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <GroupIcon sx={{color: 'primary.main'}} />
          <Box fontSize={24}>
            {assetHuman
              ? `${assetHuman.name} (${assetHuman.employees?.split(',').length} emp.)`
              : 'All Groups'}
          </Box>
        </Box>
        {onClose ? (
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <AssetHumanGroupMessageList
        ref={listRef}
        groupId={groupId}
        onChangeGroupId={setGroupId}
      />
      <Divider />
      <AccessControl permissions={['post::/group-message']}>
        <AssetHumanGroupMessageCreate
          onSubmitted={() => listRef?.current?.fetch()}
        />
      </AccessControl>
      <Box
        className="dragHandle"
        position="absolute"
        width="100%"
        bottom={0}
        left={0}
        height={10}
        sx={{cursor: 'grab'}}
      />
    </Box>
  );
};

export default AssetHumanGroupChat;
