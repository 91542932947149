import AddIcon from '@mui/icons-material/Add';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Typography} from '@mui/material';
import {useRef, useState} from 'react';

import AccessControl from '../../components/common/AccessControl';
import ProximityImportList from '../../components/proximity/ProximityImportList';
import ProximityImportUploadButton from '../../components/proximity/ProximityImportUploadButton';
import {UserListRef} from '../../components/users/UserList';
import {DashboardLayout} from '../../layouts/DashboardLayout';

const AdminProximityImportListPage = () => {
  const listRef = useRef<UserListRef | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <DashboardLayout>
      <AccessControl permissions={['get::/user']}>
        <Box
          display="flex"
          flexDirection="column"
          p={2}
          gap={0.5}
          height="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              id="modal-modal-title"
              component="h1"
              variant="h5"
              sx={{mb: 2}}
            >
              Administration - Proximity Imports
            </Typography>

            <Box
              display="flex"
              gap={1}
              alignItems="center"
              justifyContent="end"
            >
              <LoadingButton
                color="primary"
                loading={isRefreshing}
                sx={{p: 1, minWidth: 0}}
                onClick={async () => {
                  setIsRefreshing(true);
                  await listRef?.current?.fetch?.();
                  setIsRefreshing(false);
                }}
              >
                <RestartAltIcon />
              </LoadingButton>

              {/* <LoadingButton
                color="primary"
                sx={{p: 1, minWidth: 0}}
                onClick={() => listRef.current?.dataGridRef.current?.printTable()}
              >
                <PrintIcon />
              </LoadingButton> */}

              <AccessControl permissions={['post::/user']}>
                <ProximityImportUploadButton
                  component={Button}
                  componentProps={{variant: 'outlined', startIcon: <AddIcon />}}
                  onSubmitted={() => listRef?.current?.fetch?.()}
                >
                  Import File
                </ProximityImportUploadButton>
              </AccessControl>
            </Box>
          </Box>

          <ProximityImportList ref={listRef} />
        </Box>
      </AccessControl>
    </DashboardLayout>
  );
};

export default AdminProximityImportListPage;
