import {useEffect, useMemo} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import DashboardItem from '../../components/dashboards/DashboardItem';
import {useAppSelector} from '../../hooks/redux';
import {DashboardLayout} from '../../layouts/DashboardLayout';

const DashboardItemPage = () => {
  const {id: idParam} = useParams();
  const company = useAppSelector(({assets}) => assets.company);
  const navigate = useNavigate();

  const id = useMemo(() => {
    if (idParam === 'primary') {
      return company?.primary_dashboard?.id ?? null;
    } else if (idParam) {
      return +idParam;
    }
    return null;
  }, [idParam, company?.primary_dashboard?.id]);

  useEffect(() => {
    if (company && idParam === 'primary' && !id) {
      console.log('company, idParam, id', company, idParam, id);
      navigate('/dashboards');
    }
  }, [company, idParam, id]);

  return (
    <DashboardLayout>
      {id ? (
        <DashboardItem key={id} pk={+id} primary={idParam === 'primary'} />
      ) : null}
    </DashboardLayout>
  );
};

export default DashboardItemPage;
