import {HumanGroupEmployee} from '../interfaces/HumanGroup';

export const getEmployessIdsByField = (
  fieldName: string,
  id: number,
  employees: HumanGroupEmployee[]
) => {
  const key = fieldName === 'types' ? 'type_id' : 'zone_id';
  const ids: number[] = [];
  employees.forEach((item) => {
    if (item[key] === id) {
      ids.push(item.id);
    }
  });
  return ids;
};
