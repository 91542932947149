import * as yup from 'yup';

import {
  getMaxLengthMessage,
  getMaxMessage,
  getMinMessage,
  nameMessage,
} from './consts';
import {
  maxDescriptionLength,
  maxNameLength,
  maxProximityId,
  nameValidator,
} from './utils';

export const safeyeNodeBaseInputSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .min(1)
    .max(maxDescriptionLength, getMaxLengthMessage(maxDescriptionLength)),
  name: yup
    .string()
    .matches(nameValidator.regex, nameMessage)
    .required('Field is required')
    .min(1)
    .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
  status: yup.string().required().oneOf(['active', 'inactive']),
  type: yup.string().required().oneOf(['controller', 'display', 'hub']),
});

export const safeyeNodeInputSchema = safeyeNodeBaseInputSchema.shape({
  is_unassigned: yup.boolean(),
  is_existing_node: yup
    .string()
    .oneOf(['YES', 'NO'])
    .when('is_unassigned', {
      is: false,
      then: (schema) => schema.required('Field is required'),
    }),
  asset_machine_id: yup
    .number()
    .nullable()
    .when('is_existing_node', {
      is: 'YES',
      then: (schema) =>
        schema.required(
          'Asset Machine ID is required in case Node already exist'
        ),
    }),
  site_id: yup
    .number()
    .integer()
    .when('is_existing_node', {
      is: 'NO',
      then: (schema) =>
        schema.required('Site ID is required in case Node doesnt exist yet'),
    })
    .min(1, getMinMessage(1))
    .max(maxProximityId, getMaxMessage(maxProximityId)),
  type_id: yup
    .number()
    .nullable()
    .when('is_existing_node', {
      is: 'NO',
      then: (schema) =>
        schema.required('Type ID is required in case Node doesnt exist yet'),
    }),
  safeye_node_id: yup.string().required('Field is required'),
});

export const safeyeNodeUpdateInputSchema = safeyeNodeBaseInputSchema.shape({
  safeye_node_id: yup.string().required('Field is required'),
});

export const safeyeNodeAckInputSchema = safeyeNodeBaseInputSchema.shape({
  is_existing_node: yup
    .string()
    .oneOf(['YES', 'NO'])
    .required('Field is required'),
  asset_machine_id: yup
    .number()
    .nullable()
    .when('is_existing_node', {
      is: 'YES',
      then: (schema) =>
        schema.required(
          'Asset Machine ID is required in case Node already exist'
        ),
    }),
  type_id: yup
    .number()
    .nullable()
    .when('is_existing_node', {
      is: 'NO',
      then: (schema) =>
        schema.required('Type ID is required in case Node doesnt exist yet'),
    }),
  site_id: yup
    .number()
    .integer()
    .nullable()
    .when('is_existing_node', {
      is: 'NO',
      then: (schema) =>
        schema.required('Site ID is required in case Node doesnt exist yet'),
    })
    .min(1, getMinMessage(1))
    .max(maxProximityId, getMaxMessage(maxProximityId)),
  machine_name: yup
    .string()
    .when('is_existing_node', {
      is: 'NO',
      then: (schema) => schema.required('Machine name is required'),
    })
    .min(1, getMinMessage(1)),
});

export const SafeyeNodeAssignInputSchema = yup.object().shape({
  asset_machine_id: yup
    .number()
    .nullable()
    .required('Asset Machine ID is required in case Node already exist'),
});
