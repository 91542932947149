import RefreshIcon from '@mui/icons-material/Refresh';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, IconButton, Tooltip} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {useRefreshInterval} from '../../hooks/refreshInterval';
import {
  CommtracNodeMessageListQueryParams,
  CommtracNodeMessageListResponse,
} from '../../interfaces/CommtracNodeMessage';
import {EmployeeEmergencyResponse} from '../../utils/commtrac-nodes';
import AccessControl from '../common/AccessControl';
import {AutoRefreshSelect} from '../common/AutoRefreshSelect';
import EventIcon from '../EventIcon';
import {DateRangeSelect} from '../selectors/DateRangeSelect';
import CommtracNodeMessageClearModal from './CommtracNodeMessageClearModal';

interface Props {
  pk: number;
}

export interface CommtracNodeMessageListRef {
  fetch: () => void;
}

const CommtracNodeMessageList = forwardRef<CommtracNodeMessageListRef, Props>(
  ({pk}, ref) => {
    useImperativeHandle(ref, () => ({
      fetch: () => fetchData(formik.values),
    }));

    const chatRef = useRef<HTMLDivElement>();

    /*********/
    /* fetch */
    /*********/
    const [fetchedData, setFetchedData] =
      useState<CommtracNodeMessageListResponse>();
    const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
    const [fetchedInProgress, setFetchedInProgress] = useState(false);

    const fetchData = async (params: CommtracNodeMessageListQueryParams) => {
      setFetchedInProgress(true);
      try {
        const resp = await API.get<CommtracNodeMessageListResponse>(
          `${apiBaseUrl}/commtrac-node/${pk}/message`,
          {params}
        );
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setTimeout(() => {
        if (chatRef.current) {
          chatRef.current.scrollTo(0, chatRef.current.scrollHeight);
        }
      }, 100);
      setFetchedInProgress(false);
    };

    /*********/
    /* input */
    /*********/
    const getFormikValues = () => ({
      date_start: dayjs().format('YYYY-MM-DD'),
      date_end: dayjs().format('YYYY-MM-DD'),
    });

    const formik = useFormik<CommtracNodeMessageListQueryParams>({
      initialValues: getFormikValues(),
      onSubmit: (values) => {
        fetchData(values);
      },
    });

    useEffect(() => {
      formik.handleSubmit();
    }, [formik.values]);

    const [refreshInterval, setRefreshInterval] = useRefreshInterval(
      formik.handleSubmit,
      30000
    );

    const [isShownClearModal, setIsShownClearModal] = useState(false);
    const minerAddressMask = useAppSelector(
      ({assets}) => assets.constants?.miner.address_mask
    );

    const getNetworkId = useCallback(
      (externalId: number) =>
        // eslint-disable-next-line no-bitwise
        minerAddressMask ? externalId & minerAddressMask : null,
      [minerAddressMask]
    );

    return (
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" gap={2}>
          <DateRangeSelect
            value={[
              dayjs(formik.values.date_start).toDate(),
              dayjs(formik.values.date_end).toDate(),
            ]}
            size="small"
            onChange={(value) => {
              formik.setFieldValue(
                'date_start',
                dayjs(value[0] ?? undefined).format('YYYY-MM-DD')
              );
              formik.setFieldValue(
                'date_end',
                dayjs(value[1] ?? undefined).format('YYYY-MM-DD')
              );
            }}
          />
          <Box display="flex">
            <Tooltip title="Refresh">
              <LoadingButton
                color="primary"
                loading={fetchedInProgress}
                sx={{p: 0.5, minWidth: 0}}
                onClick={() => fetchData(formik.values)}
              >
                <RefreshIcon />
              </LoadingButton>
            </Tooltip>
            <AutoRefreshSelect
              value={refreshInterval ?? null}
              variant="text"
              onChange={(value) => setRefreshInterval(value)}
            />
            <AccessControl permissions={['delete::/commtrac-node/:id/message']}>
              <Tooltip title="Clear History">
                <IconButton
                  color="error"
                  onClick={() => setIsShownClearModal(true)}
                >
                  <RemoveCircleIcon />
                </IconButton>
              </Tooltip>
            </AccessControl>
          </Box>
        </Box>
        <Box>
          {fetchedErrors.map((error, idx) => (
            <Alert key={idx} severity="error">
              {error}
            </Alert>
          ))}
        </Box>
        <Box
          ref={chatRef}
          p={2}
          border={1}
          position="relative"
          height={350}
          overflow="auto"
        >
          {fetchedData?.items.length ? (
            <Box width="100%" display="flex" flexDirection="column" gap={2}>
              {fetchedData?.items.map((item, idx) => (
                <Box
                  key={idx}
                  display="flex"
                  flexDirection="column"
                  alignItems={item.src_name === 'Surface' ? 'end' : 'start'}
                  gap={0.5}
                >
                  <Box
                    fontSize={12}
                    display="flex"
                    gap={0.5}
                    justifyContent="center"
                  >
                    <Box display="flex" alignItems="center">
                      {item.answer === 'NACK' ? (
                        <Tooltip title="Not responded">
                          <Box display="flex" alignItems="center">
                            <EventIcon
                              eventType="messages.nack"
                              fontSize="inherit"
                            />
                          </Box>
                        </Tooltip>
                      ) : item.answer === 'ACK' ? (
                        <Tooltip title={`Network Ack at ${item.date_ack}`}>
                          <Box display="flex" alignItems="center">
                            <EventIcon
                              eventType="messages.ack"
                              fontSize="inherit"
                            />
                          </Box>
                        </Tooltip>
                      ) : item.answer === 'READ' ? (
                        <Tooltip title={`Read at ${item.date_read}`}>
                          <Box display="flex" alignItems="center">
                            <EventIcon
                              eventType="messages.read"
                              fontSize="inherit"
                            />
                          </Box>
                        </Tooltip>
                      ) : item.answer === 'ANS' ? (
                        <Tooltip
                          title={
                            item.broadcast_status === '1'
                              ? `${EmployeeEmergencyResponse.OK} (${item.date_broadcast_response})`
                              : item.broadcast_status === '2'
                                ? `${EmployeeEmergencyResponse.INJURED_BUT_OK} (${item.date_broadcast_response})`
                                : item.broadcast_status === '3'
                                  ? `${EmployeeEmergencyResponse.INJURED_AND_NEEDS_HELP} (${item.date_broadcast_response})`
                                  : `Unknown broadcast response`
                          }
                        >
                          <Box display="flex" alignItems="center">
                            <EventIcon
                              eventType={
                                item.broadcast_status === '1'
                                  ? `messages.emergency_response.ok`
                                  : item.broadcast_status === '2'
                                    ? `messages.emergency_response.injured_but_ok`
                                    : `messages.emergency_response.injured_and_needs_help`
                              }
                              fontSize="inherit"
                            />
                          </Box>
                        </Tooltip>
                      ) : null}
                    </Box>
                    <Box>
                      {item.src_name} {'->'} {item.dst_name} (
                      {getNetworkId(item.destination_external_id)}) ({item.date}
                      )
                    </Box>
                  </Box>
                  <Box
                    bgcolor={(theme) =>
                      item.src_name === 'Surface'
                        ? theme.palette.info.main
                        : theme.palette.secondary.main
                    }
                    color={(theme) =>
                      item.src_name === 'Surface'
                        ? theme.palette.info.contrastText
                        : theme.palette.secondary.contrastText
                    }
                    p={1}
                    borderRadius={1}
                    display="inline-flex"
                    ml={1.75}
                  >
                    <Box display="flex" flexDirection="column" gap={0.5}>
                      <Box>{item.message}</Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              height="100%"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {fetchedInProgress ? 'Loading...' : 'No messages'}
            </Box>
          )}
        </Box>

        <CommtracNodeMessageClearModal
          pk={pk}
          open={isShownClearModal}
          onSubmitted={() => {
            setIsShownClearModal(false);
            fetchData(formik.values);
          }}
          onClose={() => setIsShownClearModal(false)}
        />
      </Box>
    );
  }
);

export default CommtracNodeMessageList;
