import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tab,
  Typography,
} from '@mui/material';
import {FC, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

import {
  AdminAmsConfig,
  AdminAmsConfigRef,
} from '../../components/admin/AdminAmsConfig';
import {AdminBeltsConfig} from '../../components/admin/AdminBeltsConfig';
import {AdminCommtracConfig} from '../../components/admin/AdminCommtracConfig';
import {AdminHazardAIConfig} from '../../components/admin/AdminHazardAIConfig';
import {AdminShaftClearenceConfig} from '../../components/admin/AdminShaftClearanceConfig';
import {AdminVentilationConfig} from '../../components/admin/AdminVentilationConfig';
import AccessControl from '../../components/common/AccessControl';
import {useAppSelector} from '../../hooks/redux';
import {DashboardLayout} from '../../layouts/DashboardLayout';
import {rules} from '../../utils/acl';

export const AdminProductsPage: FC = () => {
  const company = useAppSelector(({assets}) => assets.company);
  const me = useAppSelector(({app}) => app.me);
  const permissions = useAppSelector(({app}) => app.me?.permissions);
  const canEdit =
    !!permissions?.includes('post::/configuration') &&
    !!me?.type_id &&
    rules.canConfigurationProducts.includes(me?.type_id);
  const [isShownResetDialog, setIsShownResetDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const amsRef = useRef<AdminAmsConfigRef | null>(null);
  const beltsRef = useRef<null>(null);
  const shaftClearenceRef = useRef<null>(null);
  const ventilationRef = useRef<null>(null);
  const connectRef = useRef<null>(null);

  const tabs = [
    {
      label: 'AMS',
      value: 'ams',
      enabled: !!company?.ams_enabled,
      component: (
        <AdminAmsConfig
          ref={amsRef}
          disabled={!canEdit}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: amsRef,
    },
    {
      label: 'Belts',
      value: 'belts',
      enabled: !!company?.belt_enabled,
      component: (
        <AdminBeltsConfig
          ref={beltsRef}
          disabled={!canEdit}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: beltsRef,
    },
    {
      label: 'Shaft Clearens',
      value: 'shaft-clearens',
      enabled: !!company?.shaft_enabled,
      component: (
        <AdminShaftClearenceConfig
          ref={shaftClearenceRef}
          disabled={!canEdit}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: shaftClearenceRef,
    },
    {
      label: 'Ventilation',
      value: 'ventilation',
      enabled: !!company?.ventilation_enabled,
      component: (
        <AdminVentilationConfig
          ref={ventilationRef}
          disabled={!canEdit}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: ventilationRef,
    },
    {
      label: 'Connect',
      value: 'commtrac',
      enabled: !!company?.commtrac_enabled,
      component: (
        <AdminCommtracConfig
          ref={connectRef}
          disabled={!canEdit}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: connectRef,
    },
    {
      label: 'HazardAI',
      value: 'hazard_ai',
      enabled: !!company?.hazard_ai_enabled,
      component: (
        <AdminHazardAIConfig
          ref={connectRef}
          disabled={!canEdit}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: connectRef,
    },
  ];

  const enabledTabs = tabs.filter((i) => i.enabled);
  const location = useLocation();
  const navigate = useNavigate();
  const activeTab = useMemo(
    () =>
      enabledTabs.find((i) => `#${i.value}` === location.hash) ??
      enabledTabs[0],
    [enabledTabs, location.hash]
  );

  return (
    <DashboardLayout>
      <AccessControl permissions={['get::/configuration']}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box p={2} display="flex" flexDirection="column">
              <Grid container mb={2}>
                <Grid item xs={9}>
                  <Typography
                    id="modal-modal-title"
                    component="h1"
                    variant="h5"
                    sx={{mb: 2}}
                  >
                    Administration - Products
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <AccessControl
                    permissions={['patch::/configuration']}
                    roles={rules.canConfigurationProducts}
                  >
                    <Box textAlign="right">
                      <LoadingButton
                        variant="text"
                        loading={isResetting}
                        onClick={() => setIsShownResetDialog(true)}
                        sx={{mr: 2, px: 0, minWidth: 'auto'}}
                      >
                        <SettingsBackupRestoreIcon />
                      </LoadingButton>

                      <Dialog
                        open={isShownResetDialog}
                        onClose={() => setIsShownResetDialog(false)}
                      >
                        <DialogTitle>Load Factory Defaults</DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            Are you sure you want to load factory defaults?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={() => setIsShownResetDialog(false)}>
                            Cancel
                          </Button>
                          <LoadingButton
                            loading={isResetting}
                            onClick={async () => {
                              await activeTab?.ref?.current?.reset?.();
                              setIsShownResetDialog(false);
                            }}
                          >
                            Reset
                          </LoadingButton>
                        </DialogActions>
                      </Dialog>

                      <LoadingButton
                        variant="text"
                        onClick={() => activeTab?.ref?.current?.clear?.()}
                        sx={{mr: 2, px: 0, minWidth: 'auto'}}
                      >
                        <SettingsSuggestIcon />
                      </LoadingButton>

                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        onClick={() => activeTab?.ref?.current?.submit?.()}
                      >
                        Save All Changes
                      </LoadingButton>
                    </Box>
                  </AccessControl>
                </Grid>
              </Grid>

              <Box>
                <TabContext value={activeTab?.value}>
                  <Box>
                    <TabList variant="fullWidth">
                      {enabledTabs.map((tab) => (
                        <Tab
                          key={tab.value}
                          label={tab.label}
                          value={tab.value}
                          onClick={() =>
                            navigate({...location, hash: tab.value})
                          }
                        />
                      ))}
                    </TabList>
                  </Box>

                  {enabledTabs.map((tab) => (
                    <TabPanel key={tab.value} value={tab.value}>
                      {tab.component}
                    </TabPanel>
                  ))}
                </TabContext>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </AccessControl>
    </DashboardLayout>
  );
};
