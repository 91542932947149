import {Container, Divider, Grid, Typography} from '@mui/material';
import React from 'react';

import {sampleDBFields} from '../data';
import {DatabaseFieldRow} from './DatabaseFieldRow';

export const WidgetAvailableFieldsPanel = () => {
  return (
    <Container disableGutters>
      <Grid container>
        <Grid item xs={12}>
          <Typography p={2}>Available fields</Typography>
          <Divider />
        </Grid>
        <Grid item container>
          {sampleDBFields.map((item, index) => (
            <DatabaseFieldRow {...item} key={index} />
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};
