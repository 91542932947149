import {CloseFullscreen, OpenInFull} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {GridCloseIcon} from '@mui/x-data-grid';
import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  DashboardEntity,
  DashboardPanelData,
} from '../../../interfaces/Dashboard';
import {HazardHeatmapReport} from '../../../interfaces/HazardHeatmapReport';
import reduxSelectors from '../../../redux/selectors';
import {defaultHeatmapConfig} from '../../../utils/heatmap';
import {MapLatLangCoordinates} from '../../common/Map';
import ModalFixed from '../../common/ModalFixed';
import HazardHeatmapEventLogs from './HazardHeatmapEventLogs';
import HazardHeatmapGrid, {
  getHazardHeatMapReportData,
  HazardHeatmapReportData,
  HazardHeatmapReportDataTab,
} from './HazardHeatmapGrid';
interface Props {
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value?: DashboardPanelData) => void;
}
interface HazardHeatMapViewData {
  viewType: 'hazard_ai_heatmap';
  title: string;
  hazard_ai_heatmap: HazardHeatmapReportData;
}

const getHazardHeatMapViewData = (): HazardHeatMapViewData => ({
  viewType: 'hazard_ai_heatmap',
  title: 'Hazard Heatmap',
  hazard_ai_heatmap: getHazardHeatMapReportData(),
});

export const HazardHeatMapReports = ({value, entities, onUpdate}: Props) => {
  const isOpenAwayFromConnectView = !entities
    ?.map((e) => e.name)
    .includes('HazardAI');
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as HazardHeatMapViewData)
        : getHazardHeatMapViewData(),
    [value]
  );

  const [heatmapId, setHeatmapId] = useState<number | null | undefined>();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const [isFullView, setIsFullView] = useState<boolean>(false);
  const [isLogPanelOpened, setIsLogPanelOpened] = useState<boolean>(false);
  const [logPanelQuery, setLogPanelQuery] = useState<{
    centerPos: MapLatLangCoordinates;
    radius: number;
    item: HazardHeatmapReportDataTab;
  } | null>(null);
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const [isFetchingMedia, setIsFetchingMedia] = useState(false);
  const [fetchedMedia, setFetchedMedia] = useState<HazardHeatmapReport | null>(
    null
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);

  const fetchMedia = useCallback(
    async (id: number) => {
      if (id) {
        try {
          setIsFetchingMedia(true);
          const endpoint = `${apiBaseUrl}/hazard-ai/detection-event/${id}`;
          const resp = await API.get<any>(endpoint);
          console.log(resp);
          setFetchedMedia(resp?.data ?? null);
        } catch (error: any) {
          const messages = getMessagesFromApiError(error);
          setFetchedErrors(messages);
        } finally {
          setIsFetchingMedia(false);
        }
      }
    },
    [heatmapId]
  );

  useEffect(() => {
    if (!window.localStorage.getItem('heatmap_config')) {
      const defaultConfig = assets.safeye_nodes?.map((it) => {
        return {
          id: it?.id,
          confidence: defaultHeatmapConfig(),
          speed: defaultHeatmapConfig(),
          distance: defaultHeatmapConfig(),
        };
      });
      window.localStorage.setItem(
        'heatmap_config',
        JSON.stringify([
          ...defaultConfig,
          {
            id: -1,
            confidence: defaultHeatmapConfig(),
            speed: defaultHeatmapConfig(),
            distance: defaultHeatmapConfig(),
          },
        ])
      );
    }
  }, [assets.safeye_nodes]);

  useEffect(() => {
    if (heatmapId) {
      fetchMedia(heatmapId);
    }
  }, [heatmapId]);

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      flex="1"
      overflow="auto"
    >
      <HazardHeatmapGrid
        value={config.hazard_ai_heatmap}
        isOpenAwayFromConnectView={isOpenAwayFromConnectView}
        onOpenEventList={(pos, radius, openedItem) => {
          console.log(radius);
          if (pos && openedItem) {
            setLogPanelQuery({
              centerPos: pos,
              radius: radius ?? 10000,
              item: openedItem,
            });
            setIsLogPanelOpened(true);
          }
        }}
        onUpdate={(v) => {
          console.log(v);
          onUpdate?.(
            update(value ?? getHazardHeatMapViewData(), {
              hazard_ai_heatmap: {
                $set: v ?? getHazardHeatMapReportData(),
              },
            })
          );
        }}
        onClickMedia={(e?: number) => {
          setHeatmapId(e);
          setIsOpened(true);
        }}
      />
      {isOpened && heatmapId ? (
        <ModalFixed open={isOpened} width={window?.innerWidth - 100}>
          <Box
            p={0}
            sx={{
              display: 'flex',
              maxHeight: '90vh',
              height: '100vh',
              img: {
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                objectPosition: 'center',
                objectFit: 'contain',
              },
              video: {
                width: '100%',
                height: '100%',
                objectPosition: 'center',
                objectFit: 'contain',
              },
            }}
          >
            <IconButton
              onClick={() => {
                setHeatmapId(null);
                setFetchedMedia(null);
                setIsOpened(false);
              }}
              sx={{
                zIndex: 1,
                position: 'absolute',
                right: 10,
                top: 10,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              }}
            >
              <GridCloseIcon />
            </IconButton>
            <Box width="100%" height="100%">
              {fetchedErrors?.map((error, index) => (
                <Alert key={index} severity="error" sx={{mb: 2}}>
                  {error}
                </Alert>
              ))}
              {!isFetchingMedia && fetchedMedia && fetchedMedia.image && (
                <Box
                  sx={{height: '100%', width: '100%'}}
                  display="flex"
                  dangerouslySetInnerHTML={{__html: fetchedMedia.image}}
                ></Box>
              )}
            </Box>
            <Backdrop
              open={isFetchingMedia}
              sx={{position: 'absolute', zIndex: 1199}}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </Box>
        </ModalFixed>
      ) : null}

      {isLogPanelOpened && logPanelQuery && (
        <ModalFixed
          width={isFullView ? window.innerWidth : 1000}
          sx={{
            '>.MuiBox-root': {
              overflow: 'hidden',
              display: 'flex',
              height: isFullView ? '100vh' : 'unset',
              maxHeight: isFullView ? '100vh' : '90%',
              '#panel-detection-log': {
                height: 'inherit',
              },
            },
          }}
          open={isLogPanelOpened}
        >
          <Box
            p={3}
            width="100%"
            minHeight={700}
            display="flex"
            overflow="hidden"
            flexDirection="column"
            id="panel-detection-log"
          >
            <Box
              display="flex"
              alignItems="center"
              marginLeft="auto"
              marginBottom="10px"
            >
              <IconButton
                onClick={() => {
                  setIsFullView(!isFullView);
                }}
              >
                {!isFullView && <OpenInFull />}
                {isFullView && <CloseFullscreen />}
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsLogPanelOpened(false);
                  setLogPanelQuery(null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            {logPanelQuery && (
              <HazardHeatmapEventLogs
                centerPos={logPanelQuery.centerPos}
                openedItem={logPanelQuery.item}
                radius={logPanelQuery.radius}
                onClickMedia={(e?: number) => {
                  setHeatmapId(e);
                  setIsOpened(true);
                }}
              />
            )}
          </Box>
        </ModalFixed>
      )}
    </Box>
  );
};
