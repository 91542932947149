import * as yup from 'yup';

import {
  getIsWifiIpVisible,
  getIsWifiMacRadio1Visible,
  getIsWifiMacRadio2Visible,
  getIsWifiMacSwitchVisible,
} from '../../utils/nodes';
import {macAddressValidator} from './utils';

export const wifiPointBaseInputSchema = yup.object().shape({
  type: yup.string().nullable().required('Field is required'),
  name: yup.string().nullable().required('Field is required').min(1).max(50),
  status: yup.string().nullable().required('Field is required'),
  zone: yup.number().nullable().required('Field is required'),
  latitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-90)
    .max(90),
  longitude: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
  ip: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: any) => getIsWifiIpVisible(type),
      then: (schema) => schema.required('Field is required'),
    }),
  mac_radio_1: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: any) => getIsWifiMacRadio1Visible(type),
      then: (schema) =>
        schema
          .matches(
            macAddressValidator.regex,
            macAddressValidator.message.replace('%1', 'radio 1 mac-address')
          )
          .required('Field is required'),
    }),
  mac_radio_2: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: any) => getIsWifiMacRadio2Visible(type),
      then: (schema) =>
        schema
          .matches(
            macAddressValidator.regex,
            macAddressValidator.message.replace('%1', 'radio 2 mac-address')
          )
          .required('Field is required'),
    }),
  mac_switch: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: any) => getIsWifiMacSwitchVisible(type),
      then: (schema) =>
        schema
          .matches(
            macAddressValidator.regex,
            macAddressValidator.message.replace('%1', 'switch mac-address')
          )
          .required('Field is required'),
    }),
  serial_number: yup
    .string()
    .nullable()
    .required('Field is required')
    .min(1)
    .max(50),
});

export const wifiPointCreateInputSchema = wifiPointBaseInputSchema.shape({
  type: yup.string().nullable().required('Field is required'),
});

export const wifiPointUpdateInputSchema = wifiPointBaseInputSchema.shape({});

export const wifiPointAckInputSchema = wifiPointBaseInputSchema.shape({});
