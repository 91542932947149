import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React from 'react';

export const WidgetTypeSelector = () => {
  const [panel, setPanel] = React.useState('1');

  const handleChange = (event: SelectChangeEvent) => {
    setPanel(event.target.value);
  };
  return (
    <Select value={panel} size="small" onChange={handleChange} autoWidth>
      <MenuItem value="1">Node</MenuItem>
      <MenuItem value="2">Employee</MenuItem>
      <MenuItem value="3">Asset</MenuItem>
      <MenuItem value="4">Event</MenuItem>
      <MenuItem value="5">Emergency Broadcast</MenuItem>
      <MenuItem value="6">Alarm</MenuItem>
    </Select>
  );
};
