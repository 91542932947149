import {Button} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {CommunicationNode} from '../../../interfaces/CommunicationNodeV2';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import ModalDraggable from '../../common/ModalDraggable';
import AMSEModuleItemUpsert from './AMSEModuleItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: CommunicationNode;
  mode?: 'view' | 'update' | 'ack';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (externalId: number, type: 'cn' | 'commtracNodeByCn') => void;
  onOpen?: () => void;
  onClose?: Function;
}

const AMSEModuleItemUpsertButton = <T extends ComponentType = typeof Button>({
  pk,
  mode,
  prefetch,
  component,
  componentProps,
  children,
  onSubmitted,
  onOpen,
  onClose,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component
        {...componentProps}
        onClick={() => {
          setIsOpened(true);
          onOpen?.();
        }}
      >
        {children}
      </Component>

      {isOpened ? (
        <ModalDraggable open={isOpened}>
          {({isActive}) => (
            <AMSEModuleItemUpsert
              pk={pk}
              // item={item}
              mode={mode}
              prefetch={prefetch}
              isActiveModal={isActive}
              onClose={() => {
                setIsOpened(false);
                onClose?.();
              }}
              onSubmitted={() => {
                onSubmitted?.();
                if (!pk) {
                  setIsOpened(false);
                  onClose?.();
                }
              }}
            />
          )}
        </ModalDraggable>
      ) : null}
    </>
  );
};

export default AMSEModuleItemUpsertButton;
