import {Alert, AlertTitle, Box, Button} from '@mui/material';
import {FallbackProps} from 'react-error-boundary';

const ErrorBoundaryFallback = ({error, resetErrorBoundary}: FallbackProps) => (
  <Box>
    <Alert
      color="error"
      action={
        <Button color="inherit" size="small" onClick={resetErrorBoundary}>
          Reset
        </Button>
      }
    >
      <AlertTitle>Something went wrong</AlertTitle>
      {error.message}
    </Alert>
  </Box>
);

export default ErrorBoundaryFallback;
