import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import AssetMachineItemsPurgeEventsButton from './AssetMachineItemsPurgeEventsButton';
import AssetMachineItemsPurgeNodeButton from './AssetMachineItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  ids: number[];
  disabled?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const AssetMachineItemsPurgeButton = <T extends ComponentType = typeof Button>({
  ids,
  disabled = false,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  return (
    <>
      <Component
        {...componentProps}
        disabled={disabled}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <AssetMachineItemsPurgeNodeButton
          ids={ids}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          All
        </AssetMachineItemsPurgeNodeButton>

        <AssetMachineItemsPurgeEventsButton
          ids={ids}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Transactions
        </AssetMachineItemsPurgeEventsButton>
      </Menu>
    </>
  );
};

export default AssetMachineItemsPurgeButton;
