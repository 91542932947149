import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import {Button, Popover} from '@mui/material';
import {Box} from '@mui/system';
import dayjs from 'dayjs';
import React from 'react';
import {DateRangePicker as Picker} from 'react-date-range';

const formatPickerDate = (date: Date) => dayjs(date).format('YYYY-MM-DD');

interface Props {
  date: {startDate: Date; endDate: Date};
  onChange: (date: {startDate: Date; endDate: Date}) => void;
}

export const DateRangePicker = ({date, onChange}: Props) => {
  const [value, setValue] = React.useState<{startDate: Date; endDate: Date}>(
    date
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Button variant="outlined" onClick={handleClick}>
        {formatPickerDate(date.startDate)} ~ {formatPickerDate(date.endDate)}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box display="flex" flexDirection="column">
          <Picker
            months={2}
            ranges={[{...value, key: 'selection'}]}
            moveRangeOnFirstSelection={false}
            direction="horizontal"
            onChange={(e) => {
              setValue({
                startDate: dayjs(e?.selection?.startDate!).toDate(),
                endDate: dayjs(e?.selection?.endDate!).toDate(),
              });
            }}
          />
          <Box
            sx={{
              p: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
            }}
          >
            <Button sx={{mr: 2}} onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onChange(value);
                handleClose();
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};
