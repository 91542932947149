import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {Box, Button, LinearProgress, Paper, TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';

const CHUNK_SIZE = 1048576 * 3; // 3MB

export const AdminGeoFence: React.FC = () => {
  const {enqueueSnackbar} = useSnackbar();

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chunkIndex, setChunkIndex] = useState<number>(0);

  const totalChunkCount = useMemo(
    () => (file ? Math.ceil(file.size / CHUNK_SIZE) : 0),
    [file]
  );
  const progress = useMemo(
    () =>
      file ? Math.round(((chunkIndex * CHUNK_SIZE) / file?.size) * 100) : 0,
    [file?.size, chunkIndex]
  );
  const currentChunk = useMemo(
    () =>
      isSubmitting
        ? file?.slice(chunkIndex * CHUNK_SIZE, (chunkIndex + 1) * CHUNK_SIZE)
        : null,
    [file, chunkIndex, isSubmitting]
  );

  const uploadChunk = async () => {
    const actualFileName = fileName;

    const form = new FormData();
    if (currentChunk) {
      form.append('map', currentChunk, 'map');
    }

    try {
      await API.post(`${apiBaseUrl}/map`, form, {
        params: {fileName: actualFileName, chunkIndex, totalChunkCount},
      });
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {variant: 'error'});
      finishUpload();
      return;
    }

    if (isSubmitting && actualFileName === fileName) {
      setChunkIndex(chunkIndex + 1);
    }
  };

  const startUpload = async () => {
    setFileName(Math.random().toString(36));
    setChunkIndex(0);
    setIsSubmitting(true);
  };

  const finishUpload = () => {
    setIsSubmitting(false);
    setChunkIndex(0);
    setFile(null);
  };

  useEffect(() => {
    if (currentChunk?.size) {
      uploadChunk();
    } else if (isSubmitting) {
      enqueueSnackbar(`Map successfully uploaded`, {variant: 'success'});
      finishUpload();
    }
  }, [currentChunk]);

  return (
    <Paper sx={{p: 3}}>
      <Box position="relative" border="1px solid" borderColor="grey.600">
        <Box
          p={2}
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" height="100px">
            <Box>
              <Box>
                <CloudUploadIcon fontSize="large" />
              </Box>

              <Box>{file ? file.name : 'Choose map to upload'}</Box>
            </Box>

            {!file && (
              <TextField
                type="file"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                }}
                InputProps={{
                  inputProps: {sx: {height: '100%', cursor: 'pointer'}},
                  sx: {height: '100%'},
                }}
                onChange={(event: any) => setFile(event.target.files[0])}
              />
            )}
          </Box>

          {isSubmitting || progress >= 100 ? (
            <Box width="100%">
              <Box mb={1}>{progress}%</Box>

              <Box mb={3}>
                <LinearProgress variant="determinate" value={progress ?? 0} />
              </Box>

              <Box>
                {progress < 100 ? (
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={() => finishUpload()}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => finishUpload()}
                  >
                    Finish
                  </Button>
                )}
              </Box>
            </Box>
          ) : (
            <Box>
              {!!file && (
                <Box textAlign="center">
                  <Box>
                    <Button
                      variant="contained"
                      sx={{mr: 2}}
                      onClick={startUpload}
                    >
                      Upload
                    </Button>

                    <Button onClick={() => setFile(null)}>Clear</Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};
