import {ListItem, Modal, Typography} from '@mui/material';
import {Box} from '@mui/system';
import React, {ReactNode, useMemo, useState} from 'react';

import {AlarmGroup} from '../../interfaces/AlarmLogNode';
import {AlarmGroupItemUpsert} from './AlarmGroupItemUpsert';

interface AlarmGroupItemUpsertButtonProps {
  item?: AlarmGroup;
  groupName?: string;
  component?: React.FC;
  componentProps?: any;
  onSubmitted?: () => void;
  children?: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
};

export const AlarmGroupItemUpsertButton: React.FC<
  AlarmGroupItemUpsertButtonProps
> = ({
  item,
  component = ListItem,
  componentProps = {},
  children,
  onSubmitted,
}) => {
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);
  const isCreated = useMemo(() => !!item, [item]);
  const title = useMemo(
    () => `${isCreated ? 'Edit' : 'Create'} Group`,
    [isCreated]
  );

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Modal open={isOpened} onClose={closeModal}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{mb: 2}}>
            {title}
          </Typography>
          <AlarmGroupItemUpsert
            item={item}
            onCancel={closeModal}
            onSubmitted={() => {
              onSubmitted?.();
              closeModal();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
