import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  pk: number;
  action: 'checkIn' | 'checkOut';
  onSubmitted?: () => void;
  onClose?: () => void;
}

const ActionNames = {
  checkIn: 'Force Check In',
  checkOut: 'Force Check Out',
} as const;

const CommtracNodeItemMinerCheckInOutModal = ({
  pk,
  action,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionName = ActionNames[action];

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      const endpoint = `${apiBaseUrl}/commtrac-node/${pk}/miner/${action === 'checkIn' ? 'force_check_in' : 'force_check_out'}`;
      await API.patch(endpoint);
      const message = `Employee successfully ${action === 'checkIn' ? 'Checked In' : 'Checked Out'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{actionName} Employee</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName} this employee?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(pk)}
          color="warning"
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommtracNodeItemMinerCheckInOutModal;
