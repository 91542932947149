import {IndicatorPanel, Widget} from './types';

export const samplePanels: Widget[] = [
  {
    id: 1,
    title: 'All Nodes',
    value: 7,
    label: 'nodes',
    type: 'single',
  },
  {
    id: 2,
    title: 'Wifi Nodes',
    value: 4,
    label: 'nodes',
    type: 'single',
  },
  {
    id: 3,
    title: 'Underground Employees',
    value: 3,
    label: 'employees',
    type: 'single',
  },
  {
    id: 4,
    title: 'Employees Checked-In Status',
    value: 80,
    type: 'simple-progress',
    color: 'green',
  },
  {
    id: 5,
    title: 'Employees',
    value: '1/2',
    label: 'Active/Inactive',
    type: 'single',
  },
  {
    id: 6,
    title: 'Low Battery Event',
    value: 1,
    label: 'events',
    type: 'single',
  },
  {
    id: 7,
    title: 'Acknowledged Events',
    value: 8,
    label: 'events',
    type: 'single',
  },
  {
    id: 8,
    title: 'Emergency Broadcast',
    value: 1,
    label: 'nodes',
    type: 'single',
  },
  {
    id: 9,
    title: 'Active Alarms',
    value: 4,
    label: 'alarms',
    type: 'single',
  },
  {
    id: 10,
    title: 'Asset Checked-In Status',
    value: 20,
    type: 'simple-progress',
    color: 'red',
  },
  {
    id: 11,
    title: 'Alarm Resolved',
    value: '2/4',
    label: 'Active/Inactive',
    type: 'single',
  },
];

export const sampleDBFields = [
  {type: 'number', field: 'id'},
  {type: 'number', field: 'external_id'},
  {type: 'string', field: 'machine_name'},
  {type: 'number', field: 'type_id'},
  {type: 'number', field: 'zone_id'},
];

export const sampleIndicatorPanels: IndicatorPanel[] = [
  {id: 0, name: 'My Custom Panel 1', widgets: samplePanels?.slice(0, 4)},
  {id: 1, name: 'My Custom Panel 2'},
];
