import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  ProximityImport,
  ProximityImportListQuery,
  ProximityImportListResponse,
} from '../../interfaces/ProximityImport';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
import ProximityImportDeleteButton from './ProximityImportDeleteButton';

interface Props {}

export interface UserListRef {
  fetch?: Function;
  dataGridRef: MutableRefObject<DataGridRef | null>;
}

const DEFAULT_SHOWN_FIELDS = [
  'file_name',
  'scheduled_date',
  'time_took',
  'reporting_from',
  'reporting_to',
  'stat_file_line_count',
  'stat_file_line_valid_count',
  'stat_event_pad_count',
  'stat_event_battery_count',
  'stat_event_generator_count',
];

const getFormattedNumber = (n: number) =>
  ['0', n].join('').slice(Math.min(-`${n}`.length, -2));

const ProximityImportList = forwardRef<UserListRef, Props>((__, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
    dataGridRef,
  }));

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<ProximityImportListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: ProximityImportListQuery) => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<ProximityImportListResponse>(
        `${apiBaseUrl}/proximity/import`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  /*************/
  /* data grid */
  /*************/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<ProximityImport>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'file_name',
      headerName: 'Filename',
      sortable: true,
    },
    {
      field: 'scheduled_date',
      headerName: 'Import Started',
      sortable: true,
    },
    {
      field: 'finished_date',
      headerName: 'Imported At',
      sortable: true,
    },
    {
      field: 'time_took',
      headerName: 'Time Took',
      sortable: false,
      valueGetter: ({row}) => {
        if (row.finished_date && row.scheduled_date) {
          const finishedDate = dayjs(row.finished_date);
          const scheduledDate = dayjs(row.scheduled_date);
          const diff = finishedDate.valueOf() - scheduledDate.valueOf();
          const seconds = Math.floor(Math.abs(diff / 1000));
          const minutes = Math.floor(Math.abs(seconds) / 60);
          const hours = Math.floor(Math.abs(minutes) / 60);

          const h = getFormattedNumber(hours);
          const m = getFormattedNumber(minutes % 60);
          const s = getFormattedNumber(seconds % 60);

          return `${diff < 0 ? '-' : ''}${h}:${m}:${s}`;
        }
      },
    },
    {
      field: 'reporting_from',
      headerName: 'Reporting From',
      sortable: true,
    },
    {
      field: 'reporting_to',
      headerName: 'Reporting To',
      sortable: true,
    },
    {
      field: 'stat_file_line_count',
      headerName: 'Source Line',
      sortable: true,
    },
    {
      field: 'stat_file_line_valid_count',
      headerName: 'Valid Line',
      sortable: true,
    },
    {
      field: 'stat_event_timestamp_ommit',
      headerName: 'Ommitted Timestamp',
      sortable: true,
    },
    {
      field: 'stat_event_special_ommit',
      headerName: 'Ommitted Specials',
      sortable: true,
    },
    {
      field: 'stat_event_debug_ommit',
      headerName: 'Ommitted Debug',
      sortable: true,
    },
    {
      field: 'stat_event_pad_count',
      headerName: 'PAD Events',
      sortable: true,
    },
    {
      field: 'stat_event_battery_count',
      headerName: 'Battery Events',
      sortable: true,
    },
    {
      field: 'stat_event_generator_count',
      headerName: 'Generator Events',
      sortable: true,
    },
    {
      field: 'event_startup_count',
      headerName: 'Startup Events',
      sortable: true,
    },
    {
      field: 'event_io_startup_count',
      headerName: 'Startup IO Events',
      sortable: true,
    },
    {
      field: 'event_config_startup_count',
      headerName: 'Startup Config Events',
      sortable: true,
    },
    {
      field: 'event_pad_gone',
      headerName: 'PAD Gone Events',
      sortable: true,
    },
    {
      field: 'stat_machine_distinct',
      headerName: 'Machines',
      sortable: true,
    },
    {
      field: 'stat_human_distinct',
      headerName: 'Employees',
      sortable: true,
    },
    {
      field: 'stat_memory_used',
      headerName: 'Memory Used',
      sortable: true,
    },
    {
      field: 'error_checksum',
      headerName: 'Checksum Errors',
      sortable: true,
    },
    {
      field: 'error_future_date',
      headerName: 'Future Date Error',
      sortable: true,
    },
    {
      field: 'error_swapped_dates',
      headerName: 'Swapped Dates Error',
      sortable: true,
    },
    {
      field: 'error_battery_without_pad',
      headerName: 'Battery Without PAD Error',
      sortable: true,
    },
    {
      field: 'error_wrong_event_order',
      headerName: 'Wrong Event Order Error',
      sortable: true,
    },
    {
      field: 'error_zone_mismatch_machine',
      headerName: 'Zone Mismatch Machine Error',
      sortable: true,
    },
    {
      field: 'error_empty_pad_id',
      headerName: 'Empty PAD ID Error',
      sortable: true,
    },
    {
      field: 'binary_block_invalid',
      headerName: 'Block Invalid',
      sortable: true,
    },
    {
      field: 'binary_block_invalid_length',
      headerName: 'Block Length Invalid',
      sortable: true,
    },
    {
      field: 'binary_block_invalid_payload_length',
      headerName: 'Block Payload Invalid Length',
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Rollback',
      sxHeader: {textAlign: 'right'},
      sxCell: {textAlign: 'right'},
      renderCell: ({row}) => (
        <>
          <Tooltip
            title="Rollback all events imported from selected file"
            arrow
          >
            <Box>
              <ProximityImportDeleteButton
                item={row}
                component={IconButton}
                componentProps={{
                  color: 'error',
                  size: 'small',
                }}
                onDeleted={() => fetchData(formik.values)}
              >
                <RemoveCircleIcon />
              </ProximityImportDeleteButton>
            </Box>
          </Tooltip>
        </>
      ),
    },
  ];

  const formik = useFormik<ProximityImportListQuery>({
    initialValues: {
      page: 0,
      limit: 25,
      order: 'id',
      dir: 'DESC',
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  return (
    <Paper
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        shownFields={shownFields}
        pagination
        paginationMode="server"
        size="small"
        sortBy={{
          field: formik.values.order,
          dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
        }}
        sortingMode="server"
        page={formik.values.page}
        pageSize={formik.values.limit}
        rowCount={fetchedData?.count}
        onPageChange={(v) => formik.setFieldValue('page', v)}
        onPageSizeChange={(v) => {
          formik.setFieldValue('limit', v);
          formik.setFieldValue('page', 0);
        }}
        onSort={(v) => {
          if (v) {
            formik.setFieldValue('order', v.field);
            formik.setFieldValue('dir', v.dir.toUpperCase());
          }
        }}
        onShownFieldsChange={setShownFields}
      />
    </Paper>
  );
});

export default ProximityImportList;
