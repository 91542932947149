import {MenuItem, TextField, TextFieldProps} from '@mui/material';

import {Status, STATUSES} from '../../constants/status';

type Props = TextFieldProps & {
  value?: Status | null;
};

const StatusSelect = (props: Props) => {
  return props.disabled ? (
    <TextField
      {...props}
      label="Status"
      value={STATUSES.find((item) => item.key === props.value)?.name ?? ''}
    >
      {STATUSES.map((item) => (
        <MenuItem key={item.key} value={item.key}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  ) : (
    <TextField {...props} value={props.value ?? ''} select>
      {STATUSES.map((item) => (
        <MenuItem key={item.key} value={item.key}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default StatusSelect;
