export enum ALARM_MODULE_TYPES {
  ALARM_NO_HEARTBEAT = 701,
  ALARM_NEW_MODULE_DETECTED = 702,
  ALARM_ENABLED_ALARM_TYPES = 703,
  ALARM_ENABLED_WARNING_TYPES = 704,
  ALARM_BUTTON_PRESS_ACTION_REQUORED = 705,
  ALARM_BUTTON_PRESS_AUTOMATED = 706,
}

export const ALARM_MODULE_COLORS: {
  [value in ALARM_MODULE_TYPES]: string | null;
} = {
  [ALARM_MODULE_TYPES.ALARM_NO_HEARTBEAT]: 'alarm-color.am_color_701',
  [ALARM_MODULE_TYPES.ALARM_NEW_MODULE_DETECTED]: 'alarm-color.am_color_702',
  [ALARM_MODULE_TYPES.ALARM_ENABLED_ALARM_TYPES]: 'alarm-color.am_color_703',
  [ALARM_MODULE_TYPES.ALARM_ENABLED_WARNING_TYPES]: 'alarm-color.am_color_704',
  [ALARM_MODULE_TYPES.ALARM_BUTTON_PRESS_ACTION_REQUORED]:
    'alarm-color.am_color_705',
  [ALARM_MODULE_TYPES.ALARM_BUTTON_PRESS_AUTOMATED]: 'alarm-color.am_color_706',
};

export const ALARM_MODULE_SOUNDS: {
  [value in ALARM_MODULE_TYPES]: string | null;
} = {
  [ALARM_MODULE_TYPES.ALARM_NO_HEARTBEAT]: 'alarm-sound.am_sound_701',
  [ALARM_MODULE_TYPES.ALARM_NEW_MODULE_DETECTED]: 'alarm-sound.am_sound_702',
  [ALARM_MODULE_TYPES.ALARM_ENABLED_ALARM_TYPES]: 'alarm-sound.am_sound_703',
  [ALARM_MODULE_TYPES.ALARM_ENABLED_WARNING_TYPES]: 'alarm-sound.am_sound_704',
  [ALARM_MODULE_TYPES.ALARM_BUTTON_PRESS_ACTION_REQUORED]:
    'alarm-sound.am_sound_705',
  [ALARM_MODULE_TYPES.ALARM_BUTTON_PRESS_AUTOMATED]: 'alarm-sound.am_sound_706',
};
