import {Box} from '@mui/material';
import {
  Context,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {createPortal} from 'react-dom';

interface State {
  node?: Element | DocumentFragment;
  setNode(node: Element | DocumentFragment | undefined): void;
}

const useSlot = (SlotContext: Context<State | undefined>) => {
  const slot = useContext(SlotContext);
  const domNode = slot?.node;

  return useCallback(
    ({children}: PropsWithChildren) => {
      if (domNode) {
        return createPortal(children, domNode);
      } else {
        return null;
      }
    },
    [domNode]
  );
};

const useSlotArea = (SlotContext: Context<State | undefined>) => {
  const slot = useContext(SlotContext);

  const slotRef = useCallback(
    (node: Element | null) => slot?.setNode(node ?? undefined),
    [slot?.setNode]
  );

  if (!slot) {
    return undefined;
  }

  return slotRef;
};

export const SlotProvider = ({
  children,
  SlotContext,
}: PropsWithChildren & {SlotContext: Context<State>}) => {
  const [node, setNode] = useState<State['node']>(undefined);
  const value = useMemo(() => ({node, setNode}), [node, setNode]);

  return <SlotContext.Provider value={value}>{children}</SlotContext.Provider>;
};

export const createSlot = () => {
  const SlotContext = createContext<State | undefined>(undefined);

  return [
    ({children}: PropsWithChildren) => {
      const [node, setNode] = useState<State['node']>(undefined);
      const value = useMemo(() => ({node, setNode}), [node, setNode]);

      return (
        <SlotContext.Provider value={value}>{children}</SlotContext.Provider>
      );
    },

    () => {
      const slotRef = useSlotArea(SlotContext);

      return (slotRef && <Box display="contents" ref={slotRef} />) || null;
    },

    ({children}: PropsWithChildren) => {
      const Wrapper = useSlot(SlotContext);
      return <Wrapper>{children}</Wrapper>;
    },
  ] as const;
};
