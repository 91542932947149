import {MenuItem, TextField, TextFieldProps} from '@mui/material';

import {COMMUNICATION_NODE_TYPES} from '../../constants/node';

type Props = TextFieldProps & {
  value?: number | null;
};

const CommunicationNodeTypeSelect = (props: Props) => {
  return props.disabled ? (
    <TextField
      {...props}
      value={
        COMMUNICATION_NODE_TYPES.find((i) => i.id === props.value)?.name ?? ''
      }
    />
  ) : (
    <TextField {...props} value={props.value ?? ''} select>
      {COMMUNICATION_NODE_TYPES.map((i) => (
        <MenuItem key={i.id} value={i.id}>
          {i.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default CommunicationNodeTypeSelect;
