import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import {Button, Divider, Grid, Typography} from '@mui/material';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router-dom';

import {DateRangePicker} from './DateRangePicker';
import {PanelSelector} from './PanelSelector';
import {WidgetSectionSelector} from './WidgetSectionSelector';
import {WidgetStatusSelector} from './WidgetStatusSelector';
import {WidgetTypeSelector} from './WidgetTypeSelector';

export const WidgetEditorTopBar = () => {
  const navigate = useNavigate();

  return (
    <Grid container spacing={1}>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item xs container alignItems="center" spacing={1}>
          <Grid item xs="auto">
            <Typography>Select panel:</Typography>
          </Grid>
          <Grid item>
            <PanelSelector />
          </Grid>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            startIcon={<SaveIcon />}
            sx={{textTransform: 'none'}}
          >
            Save to panel
          </Button>
          <Button
            variant="outlined"
            startIcon={<SaveIcon />}
            sx={{textTransform: 'none', mx: 1}}
          >
            Save to library
          </Button>

          <Button
            variant="text"
            sx={{textTransform: 'none'}}
            onClick={() => navigate('/indicator/panel')}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <Divider sx={{width: '100%'}} />
      </Grid>
      <Grid item container spacing={2}>
        <Grid item container xs spacing={1} alignItems="center">
          <Grid item>
            <Typography>Common filters: </Typography>
          </Grid>
          <Grid item>
            <WidgetTypeSelector />
          </Grid>
          <Grid item>
            <WidgetStatusSelector />
          </Grid>
          <Grid item>
            <WidgetSectionSelector />
          </Grid>
        </Grid>
        <Grid item container xs="auto" spacing={1}>
          <Grid item>
            <DateRangePicker
              onChange={() => {}}
              date={{
                startDate: dayjs().subtract(4, 'D').toDate(),
                endDate: new Date(),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              startIcon={<RefreshIcon />}
              sx={{textTransform: 'none'}}
            >
              Refresh
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <Divider sx={{width: '100%'}} />
      </Grid>
    </Grid>
  );
};
