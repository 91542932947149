import {Autocomplete, TextField, TextFieldProps} from '@mui/material';
import {isNull} from 'lodash';
import isNil from 'lodash/isNil';
import {useMemo} from 'react';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: string | null;
  nullLabel?: string;
  onChange?: (value?: string | null) => void;
};

export const HeatmapZoneSelector = ({
  value,
  disabled,
  fullWidth,
  size,
  onChange,
  ...props
}: Props) => {
  const options = useMemo(() => {
    return [
      {
        id: 1,
        value: 'hazard',
        name: 'Hazard',
      },
      {
        id: 2,
        value: 'warning',
        name: 'Warning',
      },
      {
        id: 3,
        value: 'id',
        name: 'ID',
      },
    ];
  }, []);

  const selectedOptionValue = isNull(value) ? null : value;
  const selectedOption =
    options.find((i) => i.value === selectedOptionValue) ?? null;

  return disabled ? (
    <TextField
      {...props}
      value={options.find((i) => i.value === value)?.name ?? ''}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
    />
  ) : (
    <Autocomplete
      value={selectedOption ?? undefined}
      disabled={disabled}
      fullWidth={fullWidth}
      options={options}
      size={size}
      disableClearable={false}
      isOptionEqualToValue={(o, v) => o.value === v?.value}
      getOptionLabel={(o) => o.name ?? ''}
      renderInput={(params) => (
        <TextField label="Zone" {...props} {...params} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      onChange={(_, option) => {
        const v =
          !option || isNil(option.value) || option?.id === undefined
            ? null
            : option.value;
        onChange?.(v);
      }}
    />
  );
};
