import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
} from '@mui/material';
import {ReactNode, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {Subscription} from '../../../interfaces/Subscription';

interface Props {
  item: Subscription;
  prefetch?: boolean;
  component?: React.FC;
  componentProps?: any;
  onDeleted?: Function;
  children?: ReactNode;
}

export const SubscriptionItemDeleteButton = ({
  item,
  component = ListItem,
  componentProps = {},
  onDeleted,
  children,
}: Props) => {
  const Component = component;

  const [newItem] = useState<Subscription>(item);
  const [isOpened, setIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  const submit = async () => {
    setIsSubmitting(true);

    try {
      await API.delete(`${apiBaseUrl}/subscription/${newItem.id}`);
      onDeleted?.();
      closeModal();
    } catch (e: any) {
      console.log(e);
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Delete Subscription</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>

          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            autoFocus
            onClick={() => submit()}
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
