/* eslint-disable complexity */
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useGetAssetHumanType} from '../../hooks/get-commtrac-node-type';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  AssetHuman,
  AssetHumanCreateInputBody,
} from '../../interfaces/AssetHuman';
import reduxActions from '../../redux/actions';
import {assetHumanCreateInputSchema} from '../../scheme/yup/asset-human';
import {
  maxNetworkId,
  maxPersonIdLength,
  maxProximityId,
} from '../../scheme/yup/utils';
import {
  getIsCommtracExternalIdVisible,
  getIsEmployeeAtestVisible,
  getIsEmployeeHasMotionSensorVisible,
  getIsEmployeeMc2FlagVisible,
  getIsEmployeeSensorNumberVisible,
  getIsMacAddressVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsWifiEnabledVisible,
} from '../../utils/commtrac-nodes';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import {AssetHumanTypeSelect} from '../selectors/AssetHumanTypeSelect';
import {DateSelect} from '../selectors/DateSelect';
import {ShiftSelect} from '../selectors/ShiftSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import AssetHumanReassignDialog from './AssetHumanReassignDialog';

interface Props {
  onCancel?: () => void;
  onSubmitted?: (item: AssetHuman) => void;
}

type CreateInputBody = AssetHumanCreateInputBody & {
  is_commtrac: boolean | null;
  is_proximity: boolean;
};

const AssetHumanItemCreate = ({onCancel, onSubmitted}: Props) => {
  const reduxDispatch = useAppDispatch();
  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: CreateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/asset-human`;
      const resp = await API.post<AssetHuman>(endpoint, {
        ...data,
        birth_date: data.birth_date
          ? dayjs(data.birth_date).format('YYYY-MM-DD')
          : null,
        //is_commtrac: undefined,
        wifi_enabled: data.wifi_enabled,
        commtrac_external_id: isCommtracExternalIdVisible
          ? data.commtrac_external_id
          : undefined,
        mc2_flag: isEmployeeMc2FlagVisible ? data.mc2_flag : undefined,
        atest: isEmployeeAtestVisible ? data.atest : undefined,
        sensors_number: isEmployeeSensorNumberVisible
          ? data.sensors_number
          : undefined,
        has_motion_sensor: isEmployeeHasMotionSensorVisible
          ? data.has_motion_sensor
          : undefined,
        mac_address: isMacAddressVisible ? data.mac_address : undefined,
        min_voltage: isMinVoltageVisible ? data.min_voltage : undefined,
        max_voltage: isMaxVoltageVisible ? data.max_voltage : undefined,
      });
      const message = `Employee successfully created`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAssetHumans);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (): CreateInputBody => ({
    is_commtrac: false,
    is_proximity: false,
    nickname: null,
    first_name: null,
    last_name: null,
    birth_date: null,
    description: '',
    type_id: null,
    zone_id: null,
    shift_id: null,
    status: null,
    personal_id: null,
    external_id: null,
    wifi_enabled: false,
    commtrac_external_id: null,
    cam_monitor_battery: 0,
    mc2_flag: 0,
    atest: 0,
    sensors_number: null,
    has_motion_sensor: 0,
    mac_address: null,
    min_voltage: null,
    max_voltage: null,
    force_reassign: false,
  });

  const formik = useFormik<CreateInputBody>({
    initialValues: getFormikValues(),
    validationSchema: assetHumanCreateInputSchema,
    onSubmit: async (values) => {
      console.log(formik.errors);
      if (commtracNodeToReassign && !values.force_reassign) {
        setIsReassignDialogOpened(true);
      } else {
        await submitData(values);
      }
    },
  });

  const isWifiEnabledVisible = getIsWifiEnabledVisible(
    formik.values.is_commtrac
  );
  const isCommtracExternalIdVisible = getIsCommtracExternalIdVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isEmployeeMc2FlagVisible = getIsEmployeeMc2FlagVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isEmployeeAtestVisible =
    false &&
    getIsEmployeeAtestVisible(
      formik.values.is_commtrac,
      formik.values.wifi_enabled
    );
  const isEmployeeSensorNumberVisible = getIsEmployeeSensorNumberVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled,
    formik.values.atest
  );
  const isEmployeeHasMotionSensorVisible = getIsEmployeeHasMotionSensorVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isMacAddressVisible = getIsMacAddressVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isMinVoltageVisible = getIsMinVoltageVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isMaxVoltageVisible = getIsMaxVoltageVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );

  /*********************/
  /* commtrac reassign */
  /*********************/

  const [isReassignDialogOpened, setIsReassignDialogOpened] = useState(false);
  const commtracNodes = useAppSelector(({assets}) => assets.commtrac_nodes);
  const minerAddressFlag = useAppSelector(
    ({assets}) => assets.constants?.miner.address_flag
  );
  const company = useAppSelector(({assets}) => assets.company);

  const commtracNodeToReassign = useMemo(() => {
    if (
      isCommtracExternalIdVisible &&
      formik.values.commtrac_external_id &&
      minerAddressFlag
    ) {
      const commtracExternalid =
        // eslint-disable-next-line no-bitwise
        formik.values.commtrac_external_id | minerAddressFlag;
      return commtracNodes.find(
        (i) => commtracExternalid === i.commtrac_external_id
      );
    } else if (isMacAddressVisible && formik.values.mac_address) {
      return commtracNodes.find(
        (i) => formik.values.mac_address === i.mac_address
      );
    }
  }, [
    formik.values.commtrac_external_id,
    formik.values.mac_address,
    minerAddressFlag,
    isCommtracExternalIdVisible,
    isMacAddressVisible,
  ]);

  const getAsssetHumanType = useGetAssetHumanType();
  const assetHumanType = getAsssetHumanType({
    commtrac_external_id: formik.values.commtrac_external_id,
    wifi_enabled: formik.values.wifi_enabled ? 1 : 0,
    atest: '0',
    mc2_flag: formik.values.mc2_flag,
    sensors_number: 0,
  });

  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        position="relative"
        gap={3}
        p={3}
        onSubmit={formik.handleSubmit}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            className="dragHandle"
            display="flex"
            alignItems="center"
            width="100%"
            gap={1.5}
            sx={{cursor: 'grab'}}
          >
            <PersonIcon sx={{color: 'primary.main'}} />
            <Box fontSize={24}>Create Employee</Box>
          </Box>
          {onCancel ? (
            <IconButton onClick={() => onCancel()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          {company?.commtrac_enabled ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                Connect enabled employee
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.is_commtrac}
                      onChange={() => {
                        formik.setFieldValue('is_commtrac', true);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.is_commtrac}
                      onChange={() => {
                        formik.setFieldValue('is_commtrac', false);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          <TextField
            value={formik.values.nickname}
            label="Nickname"
            size="small"
            name="nickname"
            fullWidth
            error={!!formik.touched.nickname && !!formik.errors.nickname}
            helperText={formik.touched.nickname && formik.errors.nickname}
            onChange={formik.handleChange}
          />

          <TextField
            value={formik.values.first_name}
            label="First Name"
            size="small"
            name="first_name"
            fullWidth
            error={!!formik.touched.first_name && !!formik.errors.first_name}
            helperText={formik.touched.first_name && formik.errors.first_name}
            onChange={formik.handleChange}
          />

          <TextField
            value={formik.values.last_name}
            label="Last Name"
            size="small"
            name="last_name"
            fullWidth
            error={!!formik.touched.last_name && !!formik.errors.last_name}
            helperText={formik.touched.last_name && formik.errors.last_name}
            onChange={formik.handleChange}
          />

          <DateSelect
            value={formik.values.birth_date}
            renderInput={{
              label: 'Date of Birth',
              size: 'small',
              fullWidth: true,
              error: !!formik.touched.birth_date && !!formik.errors.birth_date,
              helperText: formik.touched.birth_date && formik.errors.birth_date,
            }}
            onChange={(value) => formik.setFieldValue('birth_date', value)}
          />

          <TextField
            value={formik.values.description}
            label="Description"
            size="small"
            name="description"
            multiline
            rows={3}
            fullWidth
            error={!!formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
            onChange={formik.handleChange}
          />

          <AssetHumanTypeSelect
            value={formik.values.type_id}
            label="Job Type"
            size="small"
            fullWidth
            error={!!formik.touched.type_id && !!formik.errors.type_id}
            helperText={formik.touched.type_id && formik.errors.type_id}
            onChange={(v) => formik.setFieldValue('type_id', v)}
          />

          <ZoneSelect
            value={formik.values.zone_id}
            label="Assigned Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />

          <ShiftSelect
            value={formik.values.shift_id}
            label="Assigned Shift"
            size="small"
            fullWidth
            error={!!formik.touched.shift_id && !!formik.errors.shift_id}
            helperText={formik.touched.shift_id && formik.errors.shift_id}
            onChange={(v) => formik.setFieldValue('shift_id', v)}
          />

          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />

          <TextField
            value={formik.values.personal_id}
            label="Employee ID"
            size="small"
            name="personal_id"
            fullWidth
            error={!!formik.touched.personal_id && !!formik.errors.personal_id}
            helperText={formik.touched.personal_id && formik.errors.personal_id}
            inputProps={{maxLength: maxPersonIdLength}}
            onChange={(v) =>
              formik.setFieldValue('personal_id', v.target.value || null)
            }
          />

          {isWifiEnabledVisible ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                WIFI enabled
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.wifi_enabled}
                      onChange={() => {
                        formik.setFieldValue('wifi_enabled', true);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.wifi_enabled}
                      onChange={() => {
                        formik.setFieldValue('wifi_enabled', false);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {isCommtracExternalIdVisible ? (
            <NumberTextField
              value={formik.values.commtrac_external_id}
              min={1}
              max={maxNetworkId}
              label="Network ID"
              size="small"
              name="commtrac_external_id"
              fullWidth
              error={
                !!formik.touched.commtrac_external_id &&
                !!formik.errors.commtrac_external_id
              }
              helperText={
                formik.touched.commtrac_external_id &&
                formik.errors.commtrac_external_id
              }
              onChange={(v) => formik.setFieldValue('commtrac_external_id', v)}
            />
          ) : null}

          {isCommtracExternalIdVisible && commtracNodeToReassign ? (
            <Alert color="warning">
              Network ID {formik.values.commtrac_external_id} is assigned to
              another {commtracNodeToReassign.type}. Are you sure you want to
              reassign it to this employee?
            </Alert>
          ) : null}

          {company?.commtrac_enabled && assetHumanType === 'CAM' ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                Monitor Battery
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.cam_monitor_battery}
                      onChange={() => {
                        formik.setFieldValue('cam_monitor_battery', 1);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.cam_monitor_battery}
                      onChange={() => {
                        formik.setFieldValue('cam_monitor_battery', 0);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {isEmployeeMc2FlagVisible ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">MC2</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.mc2_flag}
                      onChange={() => {
                        formik.setFieldValue('mc2_flag', 1);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.mc2_flag}
                      onChange={() => {
                        formik.setFieldValue('mc2_flag', 0);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {isEmployeeAtestVisible ? (
            <TextField
              value={formik.values.atest}
              label="ATest"
              size="small"
              name="atest"
              select
              fullWidth
              error={!!formik.touched.atest && !!formik.errors.atest}
              helperText={formik.touched.atest && formik.errors.atest}
              onChange={formik.handleChange}
            >
              {[
                {value: 1, name: 'Yes'},
                {value: 0, name: 'No'},
              ].map((i) => (
                <MenuItem key={i.name} value={i.value}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isEmployeeSensorNumberVisible ? (
            <TextField
              value={formik.values.sensors_number}
              label="Number of Sensors"
              size="small"
              name="sensors_number"
              select
              fullWidth
              error={
                !!formik.touched.sensors_number &&
                !!formik.errors.sensors_number
              }
              helperText={
                formik.touched.sensors_number && formik.errors.sensors_number
              }
              onChange={formik.handleChange}
            >
              {[0, 1, 2, 3, 4].map((i) => (
                <MenuItem key={i} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isEmployeeHasMotionSensorVisible ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                Has a Motion Sensor
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.has_motion_sensor}
                      onChange={() => {
                        formik.setFieldValue('has_motion_sensor', 1);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.has_motion_sensor}
                      onChange={() => {
                        formik.setFieldValue('has_motion_sensor', 0);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {isMacAddressVisible ? (
            <TextField
              value={formik.values.mac_address}
              label="Mac Address"
              size="small"
              name="mac_address"
              fullWidth
              error={
                !!formik.touched.mac_address && !!formik.errors.mac_address
              }
              helperText={
                formik.touched.mac_address && formik.errors.mac_address
              }
              onChange={formik.handleChange}
            />
          ) : null}

          {isMacAddressVisible && commtracNodeToReassign ? (
            <Alert color="warning">
              Mac Address {formik.values.mac_address} is assigned to another{' '}
              {commtracNodeToReassign.type}. Are you sure you want to reassign
              it to this employee?
            </Alert>
          ) : null}

          {isMinVoltageVisible ? (
            <NumberTextField
              value={
                formik.values.min_voltage
                  ? formik.values.min_voltage / 10
                  : formik.values.min_voltage
              }
              decimalPlaces={1}
              min={0}
              max={10}
              step={0.1}
              label="Min Voltage"
              size="small"
              name="min_voltage"
              fullWidth
              error={
                !!formik.touched.min_voltage && !!formik.errors.min_voltage
              }
              helperText={
                formik.touched.min_voltage && formik.errors.min_voltage
              }
              onChange={(v) =>
                formik.setFieldValue('min_voltage', v ? v * 10 : v)
              }
            />
          ) : null}

          {isMaxVoltageVisible ? (
            <NumberTextField
              value={
                formik.values.max_voltage
                  ? formik.values.max_voltage / 10
                  : formik.values.max_voltage
              }
              decimalPlaces={1}
              min={0}
              max={10}
              step={0.1}
              label="Max Voltage"
              size="small"
              name="max_voltage"
              fullWidth
              error={
                !!formik.touched.max_voltage && !!formik.errors.max_voltage
              }
              helperText={
                formik.touched.max_voltage && formik.errors.max_voltage
              }
              onChange={(v) =>
                formik.setFieldValue('max_voltage', v ? v * 10 : v)
              }
            />
          ) : null}

          {company?.proximity_enabled ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                Proximity enabled employee
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.is_proximity}
                      onChange={() => {
                        formik.setFieldValue('is_proximity', true);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.is_proximity}
                      onChange={() => {
                        formik.setFieldValue('is_proximity', false);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {formik.values.is_proximity ? (
            <NumberTextField
              value={formik.values.external_id}
              min={1}
              max={maxProximityId}
              label="Proximity ID"
              size="small"
              name="external_id"
              fullWidth
              error={
                !!formik.touched.external_id && !!formik.errors.external_id
              }
              helperText={
                formik.touched.external_id && formik.errors.external_id
              }
              onChange={(v) => formik.setFieldValue('external_id', v)}
            />
          ) : null}

          {company?.commtrac_enabled ? (
            <TextField
              value={300}
              label="Report Frequency (s)"
              size="small"
              disabled
              fullWidth
            />
          ) : null}
        </Box>

        <Box display="flex" justifyContent="end" gap={1}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
            >
              Create
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      {isReassignDialogOpened && (
        <AssetHumanReassignDialog
          open={isReassignDialogOpened}
          wifiEnabled={formik.values.wifi_enabled}
          macAddress={formik.values.mac_address}
          commtracExternalId={formik.values.commtrac_external_id}
          onClose={() => setIsReassignDialogOpened(false)}
          onConfirm={() => {
            submitData({
              ...formik.values,
              force_reassign: true,
            });
            setIsReassignDialogOpened(false);
          }}
        />
      )}
    </>
  );
};

export default AssetHumanItemCreate;
