import ImportExportIcon from '@mui/icons-material/ImportExport';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import _ from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {CommunicationNodeStatus} from '../../../constants/status';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {NodeListResponse} from '../../../interfaces/Node';
import {
  OpenedEntity,
  OpenedEntityMode,
  OpenedEntityType,
} from '../../../utils/connect-view-panel';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {DataGridRef} from '../../common/DataGrid';
import ModalDraggable from '../../common/ModalDraggable';
import CommunicationNodeItemUpsert from '../../communication-node/CommunicationNodeItemUpsert';
import {
  usePanel,
} from '../../dashboards/entities/DashboardEntityContext';
import NodeImportExportButton from '../../nodes/NodeImportExportButton';
import {CommtracNodeStatusSelect} from '../../selectors/CommtracNodeStatusSelect';
import {NodeTypeSelect} from '../../selectors/NodeTypeSelect';
import {ZonesSelect} from '../../selectors/ZonesSelect';
import WifiPointItemUpsert from '../../wifi-point/WifiPointItemUpsert';
import {
  getNodesReportData,
  NodesReportData,
} from '../ConnectView/NodesReport';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {CnWifiNodesGrid} from './CnWifiNodesGrid';

export interface CnWifiNodestData {
  viewType: 'nodes';
  nodes: NodesReportData & { refreshInterval?: number };
}

const getViewData = (): CnWifiNodestData => ({
  viewType: 'nodes',
  nodes: getNodesReportData(),
});

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
  onOpenHistory?: (
    id: number | string,
    type:
      | 'cn'
      | 'wifi'
      | 'wifiLongTerm'
      | 'commtracNodeByCn'
      | 'networkDiagnostics'
  ) => void;
}

export const CnWifiNodes = (props: Props) => {
  const gridRef = useRef<DataGridRef>(null);

  const [panel] = usePanel();

  const viewData = _.isEmpty(props.value) ? getViewData() : props.value as CnWifiNodestData;
  const [config, setConfig] = useState<NodesReportData & { refreshInterval?: number }>(viewData.nodes);

  const [fetchedData, setFetchedData] = useState<NodeListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = useCallback(async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const params = {
        ...config.params,
        standby: viewData.viewType !== 'nodes',
      };
      const resp = await API.get<NodeListResponse>(`${apiBaseUrl}/node`, {
        params,
      });
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  }, [config.params]);

  useEffect(() => {
    fetchData();
  }, [config.params]);

  useEffect(() => {
    props.onUpdate?.(
      update(viewData, {
        nodes: {
          $set: config,
        },
      })
    );
  }, [config]);

  useRefreshInterval(fetchData, config.refreshInterval);

  /*****************/
  /* opened modals */
  /*****************/
  const [openedCommunicationNodes, setOpenedCommunicationNodes] =
    useState<OpenedEntity>({});
  const [openedWifiPoints, setOpenedWifiPoints] =
    useState<OpenedEntity>({});

  const handleOpenItem = (
    id: number,
    entity: OpenedEntityType,
    mode?: OpenedEntityMode
  ) => {
    if (entity === 'cn') {
      if (!(id in openedCommunicationNodes)) {
        setOpenedCommunicationNodes({
          ...openedCommunicationNodes,
          [id]: {mode, id},
        });
      }
    } else if (entity === 'wifi') {
      if (!(id in openedWifiPoints)) {
        setOpenedWifiPoints({
          ...openedWifiPoints,
          [id]: {mode, id},
        });
      }
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="100%"
      >
        <DashboardPanelTitleSlot>
          {t(`panels.${panel?.code}`)}
        </DashboardPanelTitleSlot>

        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* Errors */}
        {fetchedErrors.map((error, idx) => (
          <Alert
            key={idx}
            severity="error"
            onClose={() => fetchData()}
          >
            {error}
          </Alert>
        ))}

        {/* Filters */}
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          py={1}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              columnGap={1}
            >
              <NodeTypeSelect
                size="small"
                sx={{minWidth: 150}}
                value={config.params.node_type?.[0] ?? 'all'}
                onChange={(v) => {
                  const nodeType = v as any === 'all' ? [] : [v];
                  setConfig(
                    update(config, {
                      params: {
                        node_type: {
                          $set: nodeType as any[],
                        },
                      },
                    })
                  );
                }}
              />
              <CommtracNodeStatusSelect
                size="small"
                sx={{minWidth: 150}}
                value={config.params.communication_node_status || config.params.wifi_point_status}
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      params: {
                        communication_node_status: {
                          $set: v as unknown as CommunicationNodeStatus,
                        },
                        wifi_point_status: {
                          $set: v as unknown as CommunicationNodeStatus,
                        },
                      },
                    })
                  );
                }}
              />
              <ZonesSelect
                size="small"
                sx={{minWidth: 150}}
                value={config.params.section_ids?.[0]}
                nullLabel="All Sections"
                onChange={(v) => {
                  const sectionIds = v ? [+v] : [];
                  setConfig(
                    update(config, {
                      params: {
                        section_ids: {
                          $set: sectionIds,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <Box display="flex">
              <ButtonGroup>
                <NodeImportExportButton
                  value={config}
                  component={Button}
                  componentProps={{color: 'primary'}}
                  onSubmitted={() => {}}
                >
                  <ImportExportIcon />
                </NodeImportExportButton>

                <Button
                  onClick={fetchData}
                >
                  <RefreshIcon />
                </Button>

                <AutoRefreshSelect
                  value={config.refreshInterval ?? null}
                  onChange={(v) => {
                    setConfig(
                      update(config, {
                        refreshInterval: {
                          $set: v as number,
                        },
                      })
                    );
                  }}
                />

                <Button
                  onClick={() => gridRef.current?.printTable()}
                >
                  <PrintIcon />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>

        <CnWifiNodesGrid
          ref={gridRef}
          data={fetchedData}
          config={config}
          loading={fetchedInProgress}
          onChange={(v) => {
            setConfig(
              update(config, {
                $set: v
              })
            );
          }}
          onOpenHistory={props.onOpenHistory}
          onOpenItem={handleOpenItem}
        />

        {_.values(openedCommunicationNodes).map(({id, mode}) => (
          <ModalDraggable key={`cn-${id}`} open={true}>
            {({isActive}) => (
              <CommunicationNodeItemUpsert
                pk={id}
                mode={mode === 'update' || mode === 'ack' ? mode : 'view'}
                isActiveModal={isActive}
                onPurged={() => {
                  fetchData();
                  setOpenedCommunicationNodes(
                    update(openedCommunicationNodes, {$unset: [id]})
                  );
                }}
                onOpenHistory={(externalId, type) => {
                  props.onOpenHistory?.(externalId, type);
                  setOpenedCommunicationNodes(
                    update(openedCommunicationNodes, {$unset: [id]})
                  );
                }}
                onSubmitted={() => {
                  fetchData();
                }}
                onClose={() => {
                  setOpenedCommunicationNodes(
                    update(openedCommunicationNodes, {$unset: [id]})
                  );
                }}
              />
            )}
          </ModalDraggable>
        ))}

        {_.values(openedWifiPoints).map(({id, mode}) => (
          <ModalDraggable key={`wifi-${id}`} open={true}>
            {({isActive}) => (
              <WifiPointItemUpsert
                pk={id}
                mode={mode === 'update' || mode === 'ack' ? mode : 'view'}
                isActiveModal={isActive}
                onPurged={() => {
                  fetchData();
                  setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
                }}
                onOpenHistory={(type) => {
                  props.onOpenHistory?.(id, type);
                  setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
                }}
                onSubmitted={() => {
                  fetchData();
                }}
                onClose={() => {
                  setOpenedWifiPoints(update(openedWifiPoints, {$unset: [id]}));
                }}
              />
            )}
          </ModalDraggable>
        ))}

      </Box>
    </>
  )
};
