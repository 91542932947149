import DeleteIcon from '@mui/icons-material/Delete';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';

import {apiBaseUrl} from '../../api/urls';
import {useCrudList} from '../../hooks/useCrudList';
import {
  SubscriptionReport,
  SubscriptionReportListQuery,
  SubscriptionReportListResponse,
} from '../../interfaces/SubscriptionReport';
import {SubscriptionReportDeleteDialog} from './SubscriptionReportDeleteDialog';

interface SubscriptionReportSelectTableProps {
  value?: {
    id: number;
    name: string;
  }[];
  updateRequired?: boolean;
  onUpdate?: VoidFunction;
  onSelect?: Function;
}

const SubscriptionReportSelectTable: React.FC<
  SubscriptionReportSelectTableProps
> = ({value, updateRequired, onUpdate, onSelect}) => {
  const selectedIds = useMemo(() => value?.map((i) => i.id) ?? [], [value]);

  const [listParams, setListParams] = useState<SubscriptionReportListQuery>({
    name: null,
    limit: 10,
    page: 0,
  });
  const [reportForDeletion, setReportForDeletion] =
    useState<Readonly<SubscriptionReport>>();

  const crud = useCrudList<SubscriptionReportListResponse>({
    endpointBase: `${apiBaseUrl}/subscription-report`,
  });

  const formik = useFormik({
    initialValues: listParams,
    onSubmit: (values) => setListParams(values),
  });

  useEffect(() => {
    if (!_.isEqual(listParams, formik.values)) {
      formik.setValues(listParams);
    }

    crud.onFetch(listParams);
  }, [listParams]);

  useEffect(() => {
    if (!_.isEqual(listParams, formik.values)) {
      formik.handleSubmit();
    }
  }, [formik.values]);

  useEffect(() => {
    if (updateRequired) {
      if (listParams.page !== 0) {
        formik.setFieldValue('page', 0);
      } else {
        crud.onFetch(listParams);
      }
      onUpdate?.();
    }
  }, [updateRequired]);

  const handleShowDeleteModal = (item: SubscriptionReport) => {
    setReportForDeletion(item);
  };

  const handleDelete = () => {
    crud.onFetch(listParams);
    setReportForDeletion(undefined);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Backdrop
          open={crud.isFetching}
          sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {crud.errorsFetch.map((error, index) => (
          <Alert key={index} severity="error" sx={{mb: 2}}>
            {error}
          </Alert>
        ))}

        {crud.data && (
          <Box display="flex" width="100%" justifyContent="end">
            <TablePagination
              component={Box}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[10, 20, 50, 100]}
              colSpan={3}
              count={crud.data.count}
              rowsPerPage={formik.values.limit ?? 10}
              page={formik.values.page ?? 0}
              onPageChange={(event, page) => {
                formik.setFieldValue('page', page);
              }}
              onRowsPerPageChange={(event) => {
                formik.setFieldValue('limit', +event?.target.value);
                formik.setFieldValue('page', 0);
              }}
            />
          </Box>
        )}

        <Table sx={{minWidth: 650}}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {crud.data?.items.map((item) => (
              <TableRow
                key={item.id}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell component="th">
                  <Typography color="primary">
                    {item.name ?? `Report ${item.id}`}
                    <Button
                      disabled={selectedIds.includes(item.id)}
                      onClick={() => handleShowDeleteModal(item)}
                      color="error"
                      sx={{p: 0, ml: 0.5, minWidth: 'auto'}}
                    >
                      <DeleteIcon />
                    </Button>
                  </Typography>

                  {!!Object.keys(item.filters).length && (
                    <Box>
                      <Box component="span" mr={1}>
                        Filters:
                      </Box>
                      <Box component="span">
                        {_.map(item.filters, (v, k) => `${k}=${v}`).join(', ')}
                      </Box>
                    </Box>
                  )}
                </TableCell>

                <TableCell component="th" align="right" width={150}>
                  <Button
                    disabled={selectedIds.includes(item.id)}
                    onClick={() => onSelect?.(item)}
                  >
                    Select
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {crud.data && (
          <Box display="flex" width="100%" justifyContent="end">
            <TablePagination
              component={Box}
              labelRowsPerPage="Items per page"
              rowsPerPageOptions={[10, 20, 50, 100]}
              colSpan={3}
              count={crud.data.count}
              rowsPerPage={formik.values.limit ?? 10}
              page={formik.values.page ?? 0}
              onPageChange={(event, page) => {
                formik.setFieldValue('page', page);
              }}
              onRowsPerPageChange={(event) => {
                formik.setFieldValue('limit', +event?.target.value);
                formik.setFieldValue('page', 0);
              }}
            />
          </Box>
        )}
      </TableContainer>

      {!!reportForDeletion && (
        <SubscriptionReportDeleteDialog
          subscriptionReport={reportForDeletion}
          onDelete={handleDelete}
          onClose={() => setReportForDeletion(undefined)}
        />
      )}
    </>
  );
};

export default SubscriptionReportSelectTable;
