import update from 'immutability-helper';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from 'react';

import {
  DashboardDrilldownAction,
  DashboardEntity,
  DashboardFilter,
  DashboardUpsertInputBody,
} from '../../interfaces/Dashboard';
import {Throw} from '../../utils/exceptions';

const ReaderContext = createContext<DashboardUpsertInputBody | null>(null);
const WriterContext = createContext<((action: Action) => void) | null>(null);
const FilterContext = createContext<{
  filter: DashboardFilter;
  setFilter: Dispatch<SetStateAction<DashboardFilter>> | null;
}>({filter: {}, setFilter: null});
const DrilldownContext = createContext<
  (action: DashboardDrilldownAction) => void
>(() => {});

interface Set {
  type: 'Set';
  payload: DashboardUpsertInputBody;
}

interface SetEntity {
  type: 'SetEntity';
  payload: DashboardEntity;
}

interface DeleteEntity {
  type: 'DeleteEntity';
  payload: string;
}

type Action = Set | SetEntity | DeleteEntity;

const reducer = (
  state: DashboardUpsertInputBody,
  action: Action
): DashboardUpsertInputBody => {
  switch (action.type) {
    case 'Set': {
      return action.payload;
    }
    case 'SetEntity': {
      const idx =
        state.data?.entities?.findIndex(
          (item) => item.id === action.payload.id
        ) ?? -1;
      if (idx >= 0) {
        return update(state, {
          data: {
            entities: {
              [idx]: {$set: action.payload},
            },
          },
        });
      } else {
        return state;
      }
    }
    case 'DeleteEntity': {
      const idx =
        state.data?.entities?.findIndex((item) => item.id === action.payload) ??
        -1;
      if (idx >= 0) {
        return update(state, {
          data: {
            entities: {
              $splice: [[idx, 1]],
            },
          },
        });
      } else {
        return state;
      }
    }
  }
};

interface Props extends PropsWithChildren {
  value: DashboardUpsertInputBody;
  filter: {
    filter: DashboardFilter;
    setFilter: Dispatch<SetStateAction<DashboardFilter>>;
  };
  onChange?: (data: DashboardUpsertInputBody) => void;
  drilldown: (action: DashboardDrilldownAction) => void;
}

export const DashboardProvider = ({
  children,
  value,
  onChange,
  filter,
  drilldown,
}: Props) => {
  return (
    <ReaderContext.Provider value={value}>
      <WriterContext.Provider
        value={(action) => onChange?.(reducer(value, action))}
      >
        <FilterContext.Provider value={filter}>
          <DrilldownContext.Provider value={drilldown}>
            {children}
          </DrilldownContext.Provider>
        </FilterContext.Provider>
      </WriterContext.Provider>
    </ReaderContext.Provider>
  );
};

export const useDashboard = () =>
  [
    useContext(ReaderContext) ?? Throw(new Error('context not present')),
    useContext(WriterContext) ?? Throw(new Error('context not present')),
  ] as const;

export const useDashboardFilter = () => useContext(FilterContext);
export const useDashboardDrilldown = () => useContext(DrilldownContext);
