import {Button, ButtonGroup} from '@mui/material';
import update from 'immutability-helper';
import isEmpty from 'lodash/isEmpty';
import {useMemo} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {DashboardPanelActionsSlot} from '../DashboardPanelActionsSlot';
import {
  AssetTrackingReports,
  AssetTrackingReportsData,
  getAssetTrackingReportsData,
} from './AssetTrackingReports';
import {
  EmployeeTrackingReports,
  EmployeeTrackingReportsData,
  getEmployeeTrackingReportsData,
} from './EmployeeTrackingReports';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

export interface CommtracNodeTrackingReportsData {
  viewType: 'employees' | 'assets';
  title: string;
  employees: EmployeeTrackingReportsData;
  assets: AssetTrackingReportsData;
}

const getCommtracNodeTrackingReports = (): CommtracNodeTrackingReportsData => ({
  viewType: 'employees',
  title: 'Communication Nodes Tracking Reports',
  employees: getEmployeeTrackingReportsData(),
  assets: getAssetTrackingReportsData(),
});

export const CommtracNodeTrackingReports = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as CommtracNodeTrackingReportsData)
        : getCommtracNodeTrackingReports(),
    [value]
  );
  return (
    <>
      <DashboardPanelActionsSlot>
        <ButtonGroup sx={{minWidth: 180}}>
          <Button
            size="small"
            variant={config.viewType === 'employees' ? 'contained' : 'outlined'}
            onClick={() => {
              onUpdate?.(
                update(value ?? getCommtracNodeTrackingReports(), {
                  viewType: {
                    $set: 'employees',
                  },
                })
              );
            }}
          >
            Employees
          </Button>

          <Button
            size="small"
            variant={config.viewType === 'assets' ? 'contained' : 'outlined'}
            onClick={() => {
              onUpdate?.(
                update(value ?? getCommtracNodeTrackingReports(), {
                  viewType: {
                    $set: 'assets',
                  },
                })
              );
            }}
          >
            Assets
          </Button>
        </ButtonGroup>
      </DashboardPanelActionsSlot>

      {config?.viewType === 'assets' ? (
        <AssetTrackingReports
          value={config.assets}
          onUpdate={(v) => {
            onUpdate?.(
              update(value ?? getCommtracNodeTrackingReports(), {
                assets: {
                  $set: v ?? getAssetTrackingReportsData(),
                },
                employees: {
                  $set: value?.employees ?? getEmployeeTrackingReportsData(),
                },
                viewType: {
                  $set: 'assets',
                },
              })
            );
          }}
        />
      ) : config?.viewType === 'employees' ? (
        <EmployeeTrackingReports
          value={config.employees}
          onUpdate={(v) => {
            onUpdate?.(
              update(value ?? getCommtracNodeTrackingReports(), {
                employees: {
                  $set: v ?? getEmployeeTrackingReportsData(),
                },
                assets: {
                  $set: value?.assets ?? getAssetTrackingReportsData(),
                },
                viewType: {
                  $set: 'employees',
                },
              })
            );
          }}
        />
      ) : null}
    </>
  );
};
