import ApartmentIcon from '@mui/icons-material/Apartment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Divider, IconButton, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector} from '../../hooks/redux';
import {Company} from '../../interfaces/Company';
import reduxActions from '../../redux/actions';
import {CompanyItemDeleteDialog} from './CompanyitemDeleteDialog';
import {CompanyItemPurgeDialog} from './CompanyitemPurgeDialog';
import {CompanyItemStatusDialog} from './CompanyitemStatusDialog';
import {CompanyItemUpsertModal} from './CompanyitemUpsertModal';

interface Props {
  item: Company;
  onDeleted?: () => void;
  onSubmitted?: () => void;
}

export const CompanyActionsMenu = ({item, onDeleted, onSubmitted}: Props) => {
  const reduxDispatch = useDispatch();
  const currentCompanyId = useAppSelector(({app}) => app.companyId);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(menuAnchorEl);
  const [isOpenedDelete, setIsOpenedDelete] = useState(false);
  const [isOpenedStatus, setIsOpenedStatus] = useState(false);
  const [isOpenedUpsert, setIsOpenedUpsert] = useState(false);
  const [isOpenedPurge, setIsOpenedPurge] = useState(false);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpened}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onBackdropClick={() => setMenuAnchorEl(null)}
      >
        {currentCompanyId !== item.id ? (
          <MenuItem
            onClick={() => {
              reduxActions.app.setApp(reduxDispatch, {companyId: item.id});
              reduxActions.assets.clearAssets(reduxDispatch);
            }}
          >
            <ApartmentIcon fontSize="small" sx={{mr: 2}} />
            Set as Current
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            setIsOpenedUpsert(true);
            setMenuAnchorEl(null);
          }}
        >
          <EditIcon fontSize="small" sx={{mr: 2}} />
          Edit
        </MenuItem>

        <Divider />

        {item.status === 'active' ? (
          <MenuItem
            onClick={() => {
              setIsOpenedStatus(true);
              setMenuAnchorEl(null);
            }}
          >
            <CloseIcon fontSize="small" sx={{mr: 2}} />
            Deactivate
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              setIsOpenedStatus(true);
              setMenuAnchorEl(null);
            }}
          >
            <CheckIcon fontSize="small" sx={{mr: 2}} />
            Activate
          </MenuItem>
        )}

        <MenuItem
          disabled
          onClick={() => {
            setIsOpenedPurge(true);
            setMenuAnchorEl(null);
          }}
        >
          <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 2}} />
          Purge
        </MenuItem>

        <MenuItem
          onClick={() => {
            setIsOpenedDelete(true);
            setMenuAnchorEl(null);
          }}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" sx={{mr: 2}} />
          Delete
        </MenuItem>
      </Menu>

      <CompanyItemUpsertModal
        pk={item.id}
        item={item}
        prefetch
        open={isOpenedUpsert}
        onClose={() => setIsOpenedUpsert(false)}
        onSubmitted={onSubmitted}
      />

      <CompanyItemDeleteDialog
        item={item}
        open={isOpenedDelete}
        onClose={() => setIsOpenedDelete(false)}
        onDeleted={onDeleted}
      />

      <CompanyItemStatusDialog
        item={item}
        open={isOpenedStatus}
        onClose={() => setIsOpenedStatus(false)}
        onSubmitted={onSubmitted}
      />

      <CompanyItemPurgeDialog
        item={item}
        open={isOpenedPurge}
        onClose={() => setIsOpenedPurge(false)}
        onSubmitted={onSubmitted}
      />
    </>
  );
};
