import {Box} from '@mui/material';

interface Props {
  timestamp?: string | null;
}

const CommtracNodeHistoryTooltipContent = ({timestamp}: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box>{timestamp}</Box>
    </Box>
  );
};

export default CommtracNodeHistoryTooltipContent;
