import {LoadingButton} from '@mui/lab';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  InputProps,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import {closeSnackbar, enqueueSnackbar} from 'notistack';
import {useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {Dashboard} from '../../interfaces/Dashboard';
import reduxSelectors from '../../redux/selectors';
import {Role, RoleNames} from '../../utils/acl';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  item: Dashboard | undefined;
  isFetching?: boolean;
  isEditing: boolean;
  onSelectRole?: (roles: number[]) => void;
  onSubmitted?: () => void;
  onClose?: () => void;
}

const DashboardItemShareModal = ({
  isFetching,
  item,
  isEditing,
  onSelectRole,
  onSubmitted,
  onClose,
}: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const me = useAppSelector(({app}) => app.me);
  const roleKeys = _.keys(Role).filter((key) => isNaN(Number(key))) as Array<
    keyof typeof Role
  >;
  const [selectedRoles, setSelectedRoles] = useState<number[]>([
    ...(item?.visible_to_super_user ? [Role.superUser] : []),
    ...(item?.visible_to_strata_manager ? [Role.strataManager] : []),
    ...(item?.visible_to_client_manager ? [Role.clientManager] : []),
    ...(item?.visible_to_client_viewer ? [Role.clientViewer] : []),
  ]);

  const handleSelectShareToRole: InputProps['onChange'] = (event: any) => {
    setSelectedRoles(event.target.value);
    onSelectRole?.(event.target.value);
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitData = async (selectedRoles: number[]) => {
    setIsSubmitting(true);
    try {
      const endpoint = `${apiBaseUrl}/dashboard/${item?.id}/share-to-user-type`;
      await API.patch<Dashboard>(endpoint, {type: selectedRoles});
      onSubmitted?.();
      const successMessage = `Dashboard shared to ${_.includes(selectedRoles, Role.superUser) ? 'Super Users, ' : ' '} ${_.includes(selectedRoles, Role.strataManager) ? 'Strata Managers, ' : ' '} ${_.includes(selectedRoles, Role.clientManager) ? 'Client Managers, ' : ' '} ${_.includes(selectedRoles, Role.clientViewer) ? 'Client Viewers' : ''}`;
      enqueueSnackbar(successMessage, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const [isSubmittingUnassign, setIsSubmittingUnassign] = useState(false);

  const submitUnAssignment = async (user_id: number) => {
    setIsSubmittingUnassign(true);
    try {
      const endpoint = `${apiBaseUrl}/dashboard/${item?.id}/share`;
      await API.patch(endpoint, {
        share_user_id: user_id,
        share: false,
      });
      onSubmitted?.();
      const successMessage = `User has been unassigned successfully`;
      enqueueSnackbar(successMessage, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } finally {
      setIsSubmittingUnassign(false);
    }
  };

  return (
    <Box p={3}>
      <Backdrop open={isSubmittingUnassign || (isFetching ?? false)}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {(item?.dashboard_shared_with?.length ?? 0) > 0 && (
        <Box
          mb={3}
          pb={2}
          borderBottom="1px solid #777777AA"
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          columnGap={1}
          rowGap={1}
        >
          <Typography>Shared with: </Typography>
          {item?.dashboard_shared_with?.map((it) => (
            <Chip
              label={
                assets?.users?.find((user) => user?.id === it?.user_id)?.name
              }
              key={it.id}
              sx={{
                borderRadius: '5px',
              }}
              onDelete={() => {
                submitUnAssignment(it.user_id);
              }}
            ></Chip>
          ))}
        </Box>
      )}
      <Box>
        <Typography fontSize="20px" mb={2}>
          Share with:
        </Typography>
        <TextField
          fullWidth
          label="Select Share Role Type"
          value={selectedRoles}
          select
          name="share_user_role"
          type="number"
          size="small"
          SelectProps={{
            multiple: true,
          }}
          onChange={handleSelectShareToRole}
        >
          {roleKeys.map(
            (type) =>
              (me?.type_id ?? 0) <= Role[`${type}`] &&
              Role[`${type}`] >= (item?.user_owner.type_id ?? 0) && (
                <MenuItem key={type} value={Role[`${type}`]}>
                  {RoleNames[type]}
                </MenuItem>
              )
          )}
        </TextField>
        {!isEditing && (
          <Box display="flex" gap={2} mt={3}>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              autoFocus
              type="submit"
              color="primary"
              onClick={() => submitData(selectedRoles)}
            >
              Submit
            </LoadingButton>
            <Button variant="outlined" onClick={() => onClose?.()}>
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DashboardItemShareModal;
