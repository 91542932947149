import {InputProps, MenuItem, TextField, TextFieldProps} from '@mui/material';

import {NODE_TYPES} from '../../constants/node';

export const NodeTypeSelect: React.FC<TextFieldProps> = (props) => {
  const types = [
    { key: 'all', name: 'All' },
    ...NODE_TYPES
  ];

  const handleChange: InputProps['onChange'] = (event: any) => {
    const value = event.target.value !== 'all' ? event.target.value : undefined;
    props.onChange?.(value);
  };

  return props.disabled ? (
    <TextField
      {...props}
      value={
        types.find((i) => i.key === props.value)?.name ?? ''
      }
    />
  ) : (
    <TextField
      {...props}
      label={props.label ?? 'Node Type'}
      value={props.value ?? ''}
      select
      onChange={handleChange}
    >
      {types.map((i) => (
        <MenuItem key={i.key} value={i.key}>
          {i.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
