import _ from 'lodash';
import {ParsedUrlQuery} from 'querystring';
import {URLSearchParams} from 'url';

export const getQueryParamNumberArray = (
  query: ParsedUrlQuery,
  key: string
) => {
  const queryParam = (
    query[key] instanceof Array ? query[key]?.[0] : query[key] ?? ''
  ) as string;

  return queryParam
    .split('.')
    .filter((i) => i)
    .map((i) => +i)
    .filter((i) => !_.isNil(i) && !_.isNaN(i));
};

export const getQueryParamNumber = (query: URLSearchParams, key: string) => {
  const queryParam = query.get(key);
  if (queryParam) {
    return !_.isNaN(+queryParam) ? +queryParam : null;
  }

  return null;
};

export const getQueryParamString = (
  query: URLSearchParams,
  key: string,
  options?: (string | null)[]
) => {
  const queryParam = query.get(key);

  if (queryParam && options) {
    return options.includes(queryParam) ? queryParam : null;
  }

  return queryParam;
};

export const getQueryParamBoolean = (query: URLSearchParams, key: string) => {
  const queryParam = query.get(key);
  return queryParam === 'false' ? false : queryParam === 'true' ? true : null;
};
