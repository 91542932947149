import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {Box} from '@mui/system';
import {camelCase, startCase, upperFirst} from 'lodash';
import {useState} from 'react';

import {DataGridColumn, DataGridRow, DataGridSortBy, DataGridSortDir} from '.';

interface Props<T extends DataGridRow> {
  column: DataGridColumn<T>;
  columns: DataGridColumn<T>[];
  shownFields: string[];
  sortBy?: DataGridSortBy | null;
  onSort?: (dir: DataGridSortDir) => void;
  onShownFieldsChange?: (fields: string[]) => void;
}

const titleCase = (value: string) =>
  upperFirst(startCase(camelCase(value)).toLowerCase());

export const DataGridColumnActionsButton = <T extends DataGridRow>({
  column,
  columns,
  shownFields,
  sortBy,
  onSort,
  onShownFieldsChange,
}: Props<T>) => {
  const [firstAnchorEl, setFirstAnchorEl] = useState<HTMLElement | null>(null);
  const [secondAnchorEl, setSecondAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const firstOpen = Boolean(firstAnchorEl);
  const secondOpen = Boolean(secondAnchorEl);
  const configurableColumns = columns.filter((i) => i.hideable !== false);

  return (
    <Box>
      <IconButton
        size="small"
        sx={{p: 0.5}}
        onClick={(event) => setFirstAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>

      <Menu
        anchorEl={firstAnchorEl}
        open={firstOpen}
        onBackdropClick={() => setFirstAnchorEl(null)}
      >
        {column.sortable ? (
          <Box>
            <MenuItem
              disabled={sortBy?.field === column.field && sortBy?.dir === 'asc'}
              onClick={() => {
                onSort?.('asc');
                setFirstAnchorEl(null);
              }}
            >
              Sort Ascending
            </MenuItem>

            <MenuItem
              disabled={
                sortBy?.field === column.field && sortBy?.dir === 'desc'
              }
              onClick={() => {
                onSort?.('desc');
                setFirstAnchorEl(null);
              }}
            >
              Sort Descending
            </MenuItem>
          </Box>
        ) : null}

        <MenuItem onClick={(event) => setSecondAnchorEl(event.currentTarget)}>
          Columns
        </MenuItem>

        <Menu
          key={shownFields.join('-')}
          anchorEl={secondAnchorEl}
          open={secondOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onBackdropClick={() => {
            setSecondAnchorEl(null);
            setFirstAnchorEl(null);
          }}
        >
          <Box px={2}>
            {configurableColumns.map((column) => (
              <Box key={column.field}>
                <FormControlLabel
                  label={column.headerName ?? titleCase(column.field)}
                  disabled={column.hideable === false}
                  sx={{display: 'block'}}
                  control={
                    <Checkbox
                      checked={shownFields.includes(column.field)}
                      size="small"
                      onChange={(_event, value) => {
                        const newShownFields = value
                          ? [...shownFields, column.field]
                          : shownFields.filter((i) => i !== column.field);

                        if (newShownFields.length) {
                          onShownFieldsChange?.(newShownFields);
                        } else {
                          onShownFieldsChange?.(columns.map((i) => i.field));
                        }
                      }}
                    />
                  }
                />
              </Box>
            ))}
          </Box>
        </Menu>
      </Menu>
    </Box>
  );
};
