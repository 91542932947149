import {Button} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {AlarmLogNode} from '../../interfaces/AlarmLogNode';
import {MapLatLangCoordinates} from '../common/Map';
import ModalDraggable from '../common/ModalDraggable';
import AlarmItemUpsert from './AlarmItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: AlarmLogNode;
  mode?: 'view' | 'update' | 'chat';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: () => void;
  onOpen?: () => void;
  onClose?: Function;
}

const AlarmItemUpsertButton = <T extends ComponentType = typeof Button>({
  pk,
  item,
  mode,
  prefetch,
  locationCoordinates,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
  onOpenHistory,
  onOpen,
  onClose,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);
  return (
    <>
      <Component
        {...componentProps}
        onClick={() => {
          setIsOpened(true);
          onOpen?.();
        }}
      >
        {children}
      </Component>

      {isOpened ? (
        <ModalDraggable open={isOpened}>
          {({isActive}) => (
            <AlarmItemUpsert
              pk={pk}
              item={item}
              mode={mode}
              prefetch={prefetch}
              locationCoordinates={locationCoordinates}
              isActiveModal={isActive}
              onClose={() => {
                setIsOpened(false);
                onClose?.();
              }}
              onSubmitted={() => {
                onSubmitted?.();
                if (!pk) {
                  setIsOpened(false);
                  onClose?.();
                }
              }}
              onPurged={onPurged}
              onOpenHistory={onOpenHistory}
            />
          )}
        </ModalDraggable>
      ) : null}
    </>
  );
};

export default AlarmItemUpsertButton;
