export const getMinMessage = (min: number) =>
  `The minimum value for this field is ${min}`;
export const getMaxMessage = (max: number) =>
  `The maximum value for this field is ${max}`;
export const getMinLengthMessage = (min: number) =>
  `The minimum length for this field is ${min}`;
export const getMaxLengthMessage = (max: number) =>
  `The maximum length for this field is ${max}`;
export const nameMessage =
  "This field should only contain letters, numbers, spaces and special characters: .'-_#()";
