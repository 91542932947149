import {LoadingButton} from '@mui/lab';
import {Button, Modal, TextField, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {useFormik} from 'formik';
import {enqueueSnackbar} from 'notistack';
import {useState} from 'react';
import * as yup from 'yup';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';

interface AdminSmtpTestEmailDialogProps {
  opened: boolean;
  smtpData: Partial<ConfigurationInput>;
  onClose: VoidFunction;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 2,
  overflow: 'auto',
  maxHeight: '100%',
};

const emailInputValidationSchema = yup.object().shape({
  test_email: yup
    .string()
    .required('Field is required')
    .email('Please enter valid email address'),
});

export const AdminSmtpTestEmailDialog: React.FC<
  AdminSmtpTestEmailDialogProps
> = ({opened, smtpData, onClose}) => {
  const reduxDispatch = useAppDispatch();
  const [submittedInProgress, setSubmittedInProgress] =
    useState<boolean>(false);

  const emailFormik = useFormik({
    initialValues: {test_email: ''},
    validationSchema: emailInputValidationSchema,
    onSubmit: async ({test_email}) => {
      await submitData({
        section: smtpData.section,
        configuration: [
          ...(smtpData.configuration || []),
          {section: 'smtp', name: 'test_email', value: test_email},
        ],
      });
      onClose();
    },
  });

  const submitData = async (data: Partial<ConfigurationInput>) => {
    setSubmittedInProgress(true);

    try {
      await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar(
        'SMTP configuration updated and test email sent successfully',
        {
          variant: 'success',
          action: CloseSnackbarAction,
        }
      );
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  return (
    <Modal open={opened} onClose={onClose}>
      <Box sx={style} component="form" onSubmit={emailFormik.handleSubmit}>
        <Typography variant="h6" component="h2" sx={{mb: 2}}>
          Send Test Email
        </Typography>

        <Box mb={3}>
          <TextField
            value={emailFormik.values.test_email}
            fullWidth
            name="test_email"
            label="Test Email"
            type="text"
            error={
              !!emailFormik.touched.test_email &&
              !!emailFormik.errors.test_email
            }
            helperText={
              emailFormik.touched.test_email && emailFormik.errors.test_email
            }
            onChange={emailFormik.handleChange}
          />
        </Box>

        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={onClose}>Cancel</Button>

          <LoadingButton
            sx={{ml: 1}}
            type="submit"
            variant="contained"
            loading={submittedInProgress}
            onSubmit={() => emailFormik.handleSubmit()}
          >
            Save and send test email
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
