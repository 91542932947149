import {WifiPoint} from '../../interfaces/WifiPoint';
import {MapLatLangCoordinates} from '../common/Map';
import WifiPointItem from './WifiPointItem';
import WifiPointItemCreate from './WifiPointItemCreate';

interface Props {
  pk?: number;
  item?: WifiPoint;
  mode?: 'view' | 'update' | 'ack';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (type: 'wifi' | 'wifiLongTerm') => void;
}

const WifiPointItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  locationCoordinates,
  isActiveModal,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <WifiPointItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      locationCoordinates={locationCoordinates}
      isActiveModal={isActiveModal}
      onClose={onClose}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
      onOpenHistory={onOpenHistory}
    />
  ) : (
    <WifiPointItemCreate
      locationCoordinates={locationCoordinates}
      isActiveModal={isActiveModal}
      onCancel={onClose}
      onSubmitted={onSubmitted}
    />
  );
};

export default WifiPointItemUpsert;
