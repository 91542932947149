import {LoadingButton} from '@mui/lab';
import {Button, Modal, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {enqueueSnackbar} from 'notistack';
import {useState} from 'react';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {SubscriptionReport} from '../../interfaces/SubscriptionReport';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';

interface SubscriptionReportDeleteDialogProps {
  subscriptionReport: Readonly<SubscriptionReport>;
  onDelete: VoidFunction;
  onClose: VoidFunction;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 2,
  overflow: 'auto',
  maxHeight: '100%',
};

export const SubscriptionReportDeleteDialog: React.FC<
  SubscriptionReportDeleteDialogProps
> = ({subscriptionReport, onDelete, onClose}) => {
  const [deleting, setDeleting] = useState(false);

  const handleDelete = async () => {
    setDeleting(true);

    try {
      await API.delete(
        `${apiBaseUrl}/subscription-report/${subscriptionReport.id}`
      );
      enqueueSnackbar('Subscription report deleted successfully', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onDelete();
      onClose();
    } catch (error: any) {
      const message = error?.response?.data?.errors?.[0] ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setDeleting(false);
  };

  return (
    <Modal open onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" sx={{mb: 2}}>
          Delete Subscription Report
        </Typography>

        <Box mb={3}>
          Are you sure you want to delete subscription report "
          {subscriptionReport.name}"?
        </Box>

        <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
          <Button onClick={onClose}>Cancel</Button>

          <LoadingButton
            sx={{ml: 1}}
            type="submit"
            variant="contained"
            color="error"
            loading={deleting}
            onClick={handleDelete}
          >
            Delete
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};
