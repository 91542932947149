import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {LoadingButton} from '@mui/lab';
import {Autocomplete, Box, Button, IconButton, TextField} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {ProximityImportUploadBody} from '../../interfaces/ProximityImport';
import reduxSelectors from '../../redux/selectors';
import {phpTimezoneOptions} from '../../utils/intl';
import AssetMachineSelect from '../asset-machine/AssetMachineSelect';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import {DateSelect} from '../selectors/DateSelect';

interface Props {
  onSubmitted?: () => void;
  onCancel?: () => void;
}

const proximityImportUploadSchema = yup.object().shape({
  file: yup.mixed().required('File is required'),
  timezone: yup.string().required('Timezone is required'),
});

const ProximityImportUpload = ({onSubmitted, onCancel}: Props) => {
  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: ProximityImportUploadBody) => {
    setSubmittedInProgress(true);
    try {
      const formData = new FormData();
      data.file && formData.set('file[]', data.file);
      data.timezone && formData.set('timezone', data.timezone);
      data.external_id &&
        formData.set('external_id', data.external_id.toString());
      data.date_start && formData.set('date_start', data.date_start);
      data.date_end && formData.set('date_end', data.date_end);

      const endpoint = `${apiBaseUrl}/proximity/import`;
      await API.post(endpoint, formData);
      const message = `Proximity Import queued successfully`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const currentCompany = useAppSelector((state) => state.assets.company);
  const getFormikValues = (): ProximityImportUploadBody => ({
    file: null,
    timezone: currentCompany?.timezone ?? null,
    external_id: null,
    date_start: null,
    date_end: null,
  });

  const formik = useFormik<ProximityImportUploadBody>({
    initialValues: getFormikValues(),
    validationSchema: proximityImportUploadSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  const assets = useAppSelector(reduxSelectors.assets.getAssets);

  const assetMachines = useMemo(
    () => assets.asset_machines.filter((i) => i.external_id),
    [assets.asset_machines]
  );

  const selectedAssetMachine = useMemo(
    () =>
      formik.values.external_id
        ? assetMachines.find((i) => i.external_id === formik.values.external_id)
        : null,
    [formik.values.external_id, assetMachines]
  );

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <Box fontSize={24}>Import File</Box>
        </Box>
        {onCancel ? (
          <IconButton onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>

      <Box display="flex" flexDirection="column" gap={3}>
        <Box
          position="relative"
          border="1px solid"
          borderColor={
            formik.touched.file && formik.errors.file
              ? 'error.main'
              : 'grey.600'
          }
        >
          <Box
            p={2}
            textAlign="center"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <Box display="flex" alignItems="center" height="100px">
              <Box>
                <Box>
                  <CloudUploadIcon fontSize="large" />
                </Box>

                <Box>
                  {formik.values.file
                    ? formik.values.file.name
                    : 'Select a log file to upload'}
                </Box>
              </Box>

              {!formik.values.file && (
                <TextField
                  type="file"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    opacity: 0,
                  }}
                  InputProps={{
                    inputProps: {
                      sx: {
                        height: '100%',
                        cursor: 'pointer',
                      },
                    },
                    sx: {height: '100%'},
                  }}
                  onChange={(event: any) =>
                    formik.setFieldValue('file', event.target.files[0])
                  }
                />
              )}
            </Box>
            <Box>
              {!!formik.values.file && (
                <Box textAlign="center">
                  <Box>
                    <Button onClick={() => formik.setFieldValue('file', null)}>
                      Clear
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Autocomplete
          value={
            phpTimezoneOptions.find((i) => i.name === formik.values.timezone) ??
            null
          }
          fullWidth
          options={phpTimezoneOptions}
          size="small"
          isOptionEqualToValue={(option, value) => option.name === value?.name}
          getOptionLabel={(option) => option.fullName}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Timezone"
              error={!!formik.touched.timezone && !!formik.errors.timezone}
              helperText={formik.touched.timezone && formik.errors.timezone}
            />
          )}
          onChange={(event, value) =>
            formik.setFieldValue('timezone', value?.name)
          }
        />

        <AssetMachineSelect
          value={selectedAssetMachine?.id ?? null}
          size="small"
          label="Override Machine ID"
          fullWidth
          nullLabel=""
          assetMachines={assetMachines}
          onChange={(_, item) => {
            console.log('item?.external_id', item?.external_id);
            formik.setFieldValue('external_id', item?.external_id ?? null);
          }}
        />

        <DateSelect
          value={
            formik.values.date_start
              ? dayjs(formik.values.date_start).toDate()
              : null
          }
          renderInput={{
            label: 'Start Date',
            size: 'small',
            fullWidth: true,
          }}
          onChange={(v) =>
            formik.setFieldValue(
              'date_start',
              v ? dayjs(v).format('YYYY-MM-DD') : null
            )
          }
        />

        <DateSelect
          value={
            formik.values.date_end
              ? dayjs(formik.values.date_end).toDate()
              : null
          }
          renderInput={{
            label: 'End Date',
            size: 'small',
            fullWidth: true,
          }}
          onChange={(v) =>
            formik.setFieldValue(
              'date_end',
              v ? dayjs(v).format('YYYY-MM-DD') : null
            )
          }
        />
      </Box>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Upload
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ProximityImportUpload;
