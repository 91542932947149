import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Link,
  Radio,
  Tooltip,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Bar,
  BarChart,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {usePrint} from '../../../hooks/print';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  EmployeeOverviewReportItem,
  EmployeeOverviewReportQuery,
  EmployeeOverviewReportResponse,
} from '../../../interfaces/EmployeeOverviewReport';
import reduxSelectors from '../../../redux/selectors';
import {getPadVadHumanReadable} from '../../../utils/address';
import {CHART_COLORS} from '../../../utils/colors';
import {saveFile} from '../../../utils/file';
import {isPresent} from '../../../utils/type-guards';
import AssetMachineSelect from '../../asset-machine/AssetMachineSelect';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {useDashboardDrilldown} from '../../dashboards/DashboardContext';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {ShiftSelect} from '../../selectors/ShiftSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'external_id',
  'first_name',
  'last_name',
  'type_name',
  'section',
  'zone_type_id',
  'zone_monitor',
  'zone_silent',
  'zone_warning1',
  'zone_warning2',
  'zone_hazard',
  'zone_remote',
  'battery_voltage',
];

const ALERT_TYPES = [
  {
    key: 'zone_type_id',
    label: 'ID Zone',
  },
  {
    key: 'zone_monitor',
    label: 'Monitor Zone',
  },
  {
    key: 'zone_silent',
    label: 'Silent Zone',
  },
  {
    key: 'zone_warning1',
    label: 'Warning Zone 1',
  },
  {
    key: 'zone_warning2',
    label: 'Warning Zone 2',
  },
  {
    key: 'zone_hazard',
    label: 'Hazard Zone',
  },
  {
    key: 'zone_remote',
    label: 'Remote Stop',
  },
];

export interface EmployeeOverviewReportData {
  refreshInterval?: number | null;
  params?: {
    page?: number;
    limit?: number;
    order?: string | null;
    dir?: 'ASC' | 'DESC';
    external_id?: number | null;
    shift_id?: number | null;
    date_start?: string | null;
    date_end?: string | null;
    input?: {
      [key: number]: 0 | 1 | null | undefined;
    };
  };
}

export const getEmployeeOverviewReportData =
  (): EmployeeOverviewReportData => ({
    params: {},
  });

export const EmployeeOverviewReport = (props: Props) => {
  const [panel] = usePanel();
  const [config, setConfig] = useState(
    !isEmpty(props.value) ? props.value : getEmployeeOverviewReportData()
  );

  const assets = useAppSelector(reduxSelectors.assets.getAssets);
  const assetMachines = useMemo(
    () => assets.asset_machines.filter((i) => i.external_id),
    [assets.asset_machines]
  );

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<EmployeeOverviewReportResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<EmployeeOverviewReportQuery>(
    () => ({
      page: config.params?.page ?? 0,
      limit: config.params?.limit ?? 25,
      order: config.params?.order ?? null,
      dir: config.params?.dir ?? null,
      external_id: config.params?.external_id ?? null,
      shift_id: config.params?.shift_id ?? null,
      date_start: config.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
      date_end: config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      input: config.params?.input ?? {},
    }),
    [config]
  );

  const fetchData = useCallback(
    async (params: EmployeeOverviewReportQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/human-overview`;
        const resp = await API.get<EmployeeOverviewReportResponse>(endpoint, {
          params: {
            ...params,
            input: JSON.stringify(params.input),
          },
        });
        setFetchedData(resp.data);
        setSelectedExternalId(resp.data.items?.[0]?.external_id);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  const selectedAssetMachine = useMemo(
    () =>
      params.external_id
        ? assetMachines.find((i) => i.external_id === params.external_id)
        : null,
    [params.external_id]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
      props.onUpdate?.({...props.value, params});
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  const drilldown = useDashboardDrilldown();

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<EmployeeOverviewReportItem>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      headerName: '',
      renderCell: ({row}) => (
        <Radio
          color="primary"
          checked={row.external_id === selectedExternalId}
          onChange={() => setSelectedExternalId(row.external_id)}
        />
      ),
    },
    {
      field: 'external_id',
      headerName: 'External ID',
      sortable: true,
      renderCell: ({row}) => (
        <Tooltip title="View Employee History">
          <Link
            sx={{cursor: 'pointer'}}
            onClick={() => {
              drilldown?.({
                code: 'EmployeeHistoryReport',
                chartType: 'bar',
                filter: {...params, external_id: row.external_id},
              });
            }}
          >
            {getPadVadHumanReadable(row.external_id)}
          </Link>
        </Tooltip>
      ),
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      sortable: true,
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      sortable: true,
    },
    {
      field: 'type_name',
      headerName: 'Type',
      sortable: true,
    },
    {
      field: 'section',
      headerName: 'Section',
      sortable: true,
    },
    ...ALERT_TYPES.map((i) => ({
      field: i.key,
      headerName: i.label,
      sortable: true,
    })),
    {
      field: 'battery_voltage',
      headerName: 'Battery',
      sortable: true,
    },
  ];

  /*********/
  /* chart */
  /*********/
  const theme = useTheme();
  const [selectedExternalId, setSelectedExternalId] = useState<number>();
  const selectedItem = useMemo(
    () => fetchedData?.items?.find((i) => i.external_id === selectedExternalId),
    [fetchedData, selectedExternalId]
  );
  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  const visibleAlertTypes = ALERT_TYPES.filter((i) =>
    shownFields.includes(i.key)
  );

  const chartRef = useRef<HTMLDivElement>();
  const [printChart] = usePrint(chartRef.current);
  const printTheme = useAppSelector(({app}) => app.printTheme);

  useEffect(() => {
    setHiddenKeys([]);
  }, [shownFields]);

  /*********/
  /* export */
  /*********/
  const [exportInProgress, setExportInProgress] = useState(false);

  const {shifts, machine_inputs: machineInputs} = useAppSelector(
    reduxSelectors.assets.getAssets
  );

  const submitExport = async (params: EmployeeOverviewReportQuery) => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/report/human-overview-export`;
      const resp = await API.get(endpoint, {
        params: {
          ...params,
          input: JSON.stringify(params.input),
          export_type: 'line',
        },
        responseType: 'blob',
      });

      const fileNameParts = [
        'reportEmployeesOverview',
        `${params.date_start}__${params.date_end}`,
        shifts.find((i) => i.id === params.shift_id)?.name?.replace(' ', '-'),
        selectedAssetMachine?.name?.replace(' ', '-'),
        machineInputs
          .map((i) => {
            const v = params.input?.[i.id];
            if (v === 1) {
              return `${i.name.replace(' ', '-')}-is-on`;
            } else if (v === 0) {
              return `${i.name.replace(' ', '-')}-is-off`;
            }
          })
          .filter(isPresent)
          .join('_'),
      ].filter(isPresent);

      const fileName = `${fileNameParts.join('__')}.csv`;

      saveFile(resp.data, fileName);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setExportInProgress(false);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column" pb={1}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(config.params?.date_start).toDate(),
                  dayjs(config.params?.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          date_start: v?.[0]
                            ? dayjs(v?.[0]).format('YYYY-MM-DD')
                            : undefined,
                          date_end: v?.[0]
                            ? dayjs(v?.[1]).format('YYYY-MM-DD')
                            : undefined,
                          page: 0,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <AssetMachineSelect
              value={selectedAssetMachine?.id}
              size="small"
              label="Machine"
              fullWidth
              nullLabel="All Machines"
              assetMachines={assetMachines}
              sx={{minWidth: 200}}
              onChange={(_, item) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...config.params,
                        external_id: item?.external_id,
                        page: 0,
                      },
                    },
                  })
                );
              }}
            />

            <ShiftSelect
              value={config.params?.shift_id}
              size="small"
              fullWidth
              nullLabel="All Shifts"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...config.params,
                        shift_id: v,
                        page: 0,
                      },
                    },
                  })
                );
              }}
            />

            <Box minWidth={200}>
              <MachineInputSelectV2
                value={config.params?.input}
                label="Inputs"
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          input: v,
                          page: 0,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Tooltip title="Reload">
                <LoadingButton
                  size="small"
                  loading={fetchedInProgress}
                  variant="outlined"
                  onClick={() => params && fetchData(params)}
                >
                  <RefreshIcon />
                </LoadingButton>
              </Tooltip>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Tooltip title="Export to Excel">
                <LoadingButton
                  size="small"
                  loading={exportInProgress}
                  variant="outlined"
                  onClick={() => submitExport(params)}
                >
                  <FileDownloadIcon />
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Print">
                <Button onClick={() => dataGridRef.current?.printTable()}>
                  <PrintIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <Box overflow="hidden" height="100%">
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          height="100%"
          gap={3}
        >
          <Box height="50%" minHeight={200}>
            <DataGrid
              ref={dataGridRef}
              rows={rows}
              columns={columns}
              loading={fetchedInProgress}
              shownFields={shownFields}
              pagination
              paginationMode="server"
              size="small"
              sortBy={
                params?.order
                  ? {
                      field: params?.order,
                      dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                    }
                  : null
              }
              sortingMode="server"
              page={params?.page}
              pageSize={params?.limit}
              rowCount={fetchedData?.total}
              sxFooter={{
                bgcolor: (theme) =>
                  theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
              }}
              onPageChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...config.params,
                        page: v,
                      },
                    },
                  })
                );
              }}
              onPageSizeChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      $set: {
                        ...config.params,
                        page: 0,
                        limit: v,
                      },
                    },
                  })
                );
              }}
              onSort={(v) => {
                if (v) {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          order: v.field,
                          dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                        },
                      },
                    })
                  );
                }
              }}
              onShownFieldsChange={setShownFields}
              onRowClick={(row) => setSelectedExternalId(row.external_id)}
            />
          </Box>

          <Box height="50%" minHeight={200}>
            <Box
              ref={chartRef}
              overflow="hidden"
              position="relative"
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="100%"
              width="100%"
            >
              <Box
                height={printTheme ? '500px' : '100%'}
                width={printTheme ? '700px' : '100%'}
              >
                <Box
                  position="absolute"
                  top={0}
                  right={0}
                  zIndex={1}
                  displayPrint="none"
                >
                  <ButtonGroup>
                    <Button size="small" onClick={() => printChart()}>
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={selectedItem ? [selectedItem] : []}>
                    <XAxis dataKey="name" />
                    <YAxis>
                      <Label value="Number of Times" dx={-20} angle={-90} />
                    </YAxis>
                    <RechartsTooltip
                      shared={false}
                      labelStyle={{
                        color: theme.palette.text.secondary,
                      }}
                      contentStyle={{
                        backgroundColor: theme.palette.background.default,
                      }}
                      cursor={false}
                    />
                    <Legend onClick={(item) => toggleKey(item.dataKey)} />

                    {visibleAlertTypes.map(({key, label}, index) => (
                      <Bar
                        key={key}
                        dataKey={key}
                        name={label}
                        hide={hiddenKeys.includes(key)}
                        label={{
                          position: 'top',
                          fill: theme.palette.text.secondary,
                        }}
                        fill={CHART_COLORS[index]}
                        isAnimationActive={!printTheme}
                      />
                    ))}
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
