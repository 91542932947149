import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import {
  Box,
  IconButton,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@mui/material';
import dayjs from 'dayjs';
import {camelCase, get, orderBy, startCase, upperFirst} from 'lodash';
import {
  createRef,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import Draggable from 'react-draggable';

import {usePrint} from '../../../hooks/print';
import TruncatedText from '../TruncantedText';
import {DataGridColumnActionsButton} from './DataGridActionsButton';
import {
  clearTransform,
  DraggableLayout,
  handleDrag,
  handleDragStop,
} from './Draggable/utils';

interface Props<T extends DataGridRow> {
  rows: T[];
  columns: DataGridColumn<T>[];
  sortBy?: DataGridSortBy | null;
  sortingMode?: 'client' | 'server';
  pagination?: boolean;
  paginationMode?: 'client' | 'server';
  page?: number;
  pageSize?: number;
  rowCount?: number;
  shownFields?: string[];
  loading?: boolean;
  footerStart?: ReactNode;
  size?: 'small' | 'medium';
  showHeader?: boolean;
  sx?: SxProps<Theme>;
  sxHeader?: SxProps<Theme>;
  getSxTr?: (row: T) => SxProps<Theme> | undefined;
  sxTh?: SxProps<Theme>;
  sxFooter?: SxProps<Theme>;
  onPageSizeChange?: (pageSize: number) => void;
  onPageChange?: (page: number) => void;
  onSort?: (sortBy?: DataGridSortBy) => void;
  onShownFieldsChange?: (shownFields: string[]) => void;
  onRowClick?: (row: T) => void;
  draggable?: boolean;
  draggableField?: string;
  draggableClass?: string;
  draggableCallback?: (result: any) => void;
}

export interface DataGridRef {
  printTable: () => void;
  tableRef: MutableRefObject<HTMLTableElement | null>;
}

const isValueType = (value?: DataGridValue) =>
  value instanceof Date ||
  value === null ||
  value === undefined ||
  ['number', 'string', 'boolean'].includes(typeof value);

const titleCase = (value: string) =>
  upperFirst(startCase(camelCase(value)).toLowerCase());

export interface DataGridRow {
  [x: string]: any;
}

export type DataGridValue = string | number | Date | null | undefined | boolean;
export type ColumnType = 'link' | 'icon';
export type DataGridSortDir = 'desc' | 'asc';

export interface DataGridSortBy {
  field: string;
  dir: DataGridSortDir;
}

export interface DataGridColumn<T extends DataGridRow> {
  field: string;
  headerName?: string;
  sortable?: boolean;
  sortModel?: {
    field: string;
    sort?: 'asc' | 'desc';
  }[];
  type?: 'select' | 'actions';
  width?: number;
  hideable?: boolean;
  hidden?: boolean;
  sxCell?: SxProps<Theme>;
  getSxCell?: (params: RenderCellParams<T>) => SxProps<Theme>;
  sxHeader?: SxProps<Theme>;
  renderHeader?: (params: RenderHeaderParams) => ReactNode;
  renderCell?: (params: RenderCellParams<T>) => ReactNode;
  valueGetter?: (params: ValueGetterParams<T>) => DataGridValue | undefined;
  valueFormatter?: (params: ValueFormatterParams<T>) => string;
  doNotExport?: boolean;
}

export interface RenderHeaderParams {
  field: string;
  headerName?: string;
}

export interface RenderCellParams<T extends DataGridRow> {
  row: T;
  field: string;
  value?: DataGridValue;
  valueFormatter?: (params: ValueFormatterParams<T>) => string;
}

export interface ValueGetterParams<T extends DataGridRow> {
  row: T;
  field: string;
}

export interface ValueFormatterParams<T extends DataGridRow> {
  row: T;
  field: string;
  value?: DataGridValue;
}

export const defaultValueFormatter = <T extends DataGridRow>({
  value,
}: ValueFormatterParams<T>) => {
  if (value instanceof Date) {
    return dayjs(value).format('YYYY-DD-MM HH:mm');
  }

  return value;
};

export const defaultRenderCell = <T extends DataGridRow>({
  row,
  field,
  valueFormatter,
  value,
}: RenderCellParams<T>) => {
  const formattedValue = (valueFormatter ?? defaultValueFormatter)({
    field,
    row,
    value,
  });
  return (
    <TruncatedText
      title={formattedValue?.toString()}
      sx={{
        '@media print': {
          whiteSpace: 'normal',
        },
      }}
    >
      {formattedValue}
    </TruncatedText>
  );
};

export const defaultRenderHeader = ({
  field,
  headerName,
}: RenderHeaderParams) => {
  const title = headerName ?? titleCase(field);
  return (
    <TruncatedText
      title={title}
      fontWeight={600}
      sx={{
        '@media print': {
          whiteSpace: 'normal',
        },
      }}
    >
      {title}
    </TruncatedText>
  );
};

const canFieldBeHidable = (field: string) =>
  !['select', 'actions'].includes(field);

const canFieldBePrintable = (field: string) =>
  !['select', 'actions'].includes(field);

const DataGrid = forwardRef(
  <T extends DataGridRow>(
    {
      columns,
      rows,
      sortBy: externalSortBy,
      sortingMode,
      pagination,
      paginationMode = 'client',
      page: externalPage,
      pageSize: externalPageSize,
      rowCount: externalRowCount,
      shownFields: externalShownFields,
      loading,
      footerStart,
      size = 'small',
      showHeader = true,
      sx,
      sxHeader,
      getSxTr,
      sxTh,
      sxFooter,
      onPageSizeChange,
      onPageChange,
      onSort,
      onShownFieldsChange,
      onRowClick,
      draggable,
      draggableField,
      draggableClass,
      draggableCallback,
    }: Props<T>,
    ref?: ForwardedRef<DataGridRef>
  ) => {
    columns = columns.filter((i) => !i.hidden);
    /**
     * shown fields
     */

    const columnsCanBeHidable = useMemo(
      () => columns.filter((i) => canFieldBeHidable(i.field)),
      [columns]
    );

    const fieldsCanBeHidable = useMemo(
      () => columnsCanBeHidable.map((i) => i.field),
      [columnsCanBeHidable]
    );

    const [internalShownFields, setInternalShownFields] = useState(
      externalShownFields ?? fieldsCanBeHidable
    );

    const shownFields = useMemo(() => {
      if (externalShownFields !== undefined) {
        return externalShownFields;
      }
      return internalShownFields;
    }, [externalShownFields, internalShownFields]);

    const handleShowFields = (fields: string[]) => {
      setInternalShownFields(fields);
      onShownFieldsChange?.(fields);
    };

    const shownColumns = useMemo(
      () =>
        columns.filter(
          (i) => !canFieldBeHidable(i.field) || shownFields.includes(i.field)
        ),
      [columns, shownFields]
    );

    /**
     * data
     */

    const data = useMemo(
      () =>
        rows.map((row) => ({
          row,
          columnValues: shownColumns.map(
            ({valueGetter, field}): DataGridValue => {
              const v = valueGetter
                ? valueGetter({row, field})
                : get(row, field);
              return isValueType(v) ? v : '-';
            }
          ),
        })),
      [rows, shownColumns]
    );

    /**
     * sorting
     */

    const [internalSortBy, setInternalSortBy] = useState<
      DataGridSortBy | undefined | null
    >(externalSortBy);

    const sortBy = useMemo(() => {
      if (sortingMode === 'server') {
        return externalSortBy;
      }
      return externalSortBy !== undefined ? externalSortBy : internalSortBy;
    }, [sortingMode, externalSortBy, internalSortBy]);

    const sortByColumnIndex = useMemo(() => {
      if (sortBy) {
        return shownColumns.findIndex((i) => i.field === sortBy.field);
      }
    }, [shownColumns, sortBy]);

    const sortedData = useMemo(() => {
      if (sortingMode === 'server') {
        return data;
      }
      if (sortByColumnIndex !== undefined && sortByColumnIndex >= 0) {
        return orderBy(
          data,
          (i) => i.columnValues[sortByColumnIndex] ?? '',
          sortBy?.dir
        );
      }
      return data;
    }, [data, sortBy, sortByColumnIndex]);

    const handleSort = (field?: string, dir?: 'desc' | 'asc') => {
      const newDir =
        dir ??
        (sortBy?.dir === 'asc' && field === sortBy.field ? 'desc' : 'asc');

      const newSortBy: DataGridSortBy | undefined = field
        ? {
            field,
            dir: newDir,
          }
        : undefined;

      setInternalSortBy(newSortBy);
      onSort?.(newSortBy);
    };

    /**
     * pagination
     */

    const [internalPage, setInternalPage] = useState(externalPage ?? 0);
    const [internalPageSize, setInternalPageSize] = useState(
      externalPageSize ?? 25
    );

    const page = useMemo(() => {
      if (paginationMode === 'server') {
        return externalPage ?? 0;
      }
      return externalPage !== undefined ? externalPage ?? 0 : internalPage;
    }, [paginationMode, externalPage, internalPage]);

    const pageSize = useMemo(() => {
      if (paginationMode === 'server') {
        return externalPageSize ?? 10;
      }
      return externalPageSize !== undefined
        ? externalPageSize ?? 10
        : internalPageSize;
    }, [paginationMode, externalPageSize, internalPageSize]);

    const pageData = useMemo(() => {
      if (paginationMode === 'server') {
        return sortedData;
      }
      return sortedData.slice(pageSize * page, pageSize * page + pageSize);
    }, [paginationMode, page, pageSize, sortedData]);

    const rowCount = useMemo(() => {
      if (paginationMode === 'server') {
        return externalRowCount ?? 0;
      }
      return sortedData.length;
    }, [paginationMode, externalRowCount, sortedData]);

    const handlePageChange = (v: number) => {
      setInternalPage(v);
      onPageChange?.(v);
    };

    const handlePageSizeChange = (v: number) => {
      setInternalPageSize(v);
      onPageSizeChange?.(v);
      setInternalPage(0);
    };

    const maxPage = Math.floor(rowCount / pageSize);

    useEffect(() => {
      if (maxPage < page) {
        setTimeout(() => {
          handlePageChange(maxPage);
        });
      }
    }, [maxPage, page]);

    /**
     * print
     */

    const tableRef = useRef(null);
    const [printTable] = usePrint(tableRef.current);

    /**
     * ref
     */

    useImperativeHandle(ref, () => ({
      printTable,
      tableRef,
    }));

    const tableBodyRef = useRef(null);

    const draggableLayout = useMemo<DraggableLayout>(() => {
      const l: DraggableLayout = [];
      let order = 0;

      const p = draggableField
        ? pageData.filter((d) => d.row[draggableField])
        : pageData;

      p.forEach((item, index: number) => {
        l.push({
          position: order++,
          index,
          item,
          ref: createRef<HTMLTableRowElement>(),
        });
      });

      return l;
    }, [pageData]);

    useEffect(() => {
      if (draggable) {
        clearTransform(draggableLayout);
      }
    }, [draggableLayout]);

    return (
      <Box
        sx={{
          height: '100%',
          overflow: 'hidden',
          bgcolor: (theme) => theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
          ...sx,
        }}
      >
        <TableContainer
          sx={{
            overflow: data.length ? 'auto' : 'hidden',
            bgcolor: 'inherit',
            height: data.length ? '100%' : undefined,
          }}
        >
          <Table
            ref={tableRef}
            size={size}
            stickyHeader
            sx={{
              bgcolor: 'inherit',
            }}
          >
            {showHeader ? (
              <TableHead
                sx={{
                  bgcolor: 'inherit',
                  ...sxHeader,
                }}
              >
                <TableRow sx={{bgcolor: 'inherit'}}>
                  {shownColumns.map((column) => (
                    <TableCell
                      key={`header-column-${column.field.toString()}`}
                      width={column.width}
                      padding={
                        column.type === 'select' ? 'checkbox' : undefined
                      }
                      sx={
                        {
                          ...{
                            maxWidth: 300,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            fontWeight: 600,
                            bgcolor: 'inherit',
                            lineHeight: 1.1,
                            '&:hover .title': {
                              maxWidth: 'calc(100% - 12px)',
                              overflow: 'hidden',
                            },
                            '&:hover .actions': {
                              opacity: 1,
                            },
                            '&:hover .filter': {
                              opacity: 0,
                            },
                            '@media print': {
                              minWidth: 'auto',
                              whiteSpace: 'normal',
                              px: 1.5,
                              display: !canFieldBePrintable(column.field)
                                ? 'none'
                                : undefined,
                            },
                          },
                          ...sxTh,
                          ...column.sxHeader,
                        } as SxProps<Theme>
                      }
                    >
                      {column.type &&
                      ['select', 'actions'].includes(column.type) ? (
                        (column.renderHeader ?? defaultRenderHeader)({
                          field: column.field,
                          headerName: column.headerName,
                        })
                      ) : (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box
                            className="title"
                            display="flex"
                            alignItems="center"
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              py={0.5}
                            >
                              {(column.renderHeader ?? defaultRenderHeader)({
                                field: column.field,
                                headerName: column.headerName,
                              })}
                            </Box>
                          </Box>

                          <Box className="filter" mr={-2}>
                            {sortBy?.field === column.field ? (
                              <IconButton
                                sx={{minWidth: 'auto', p: 0.5, ml: 0.5}}
                                color="primary"
                                onClick={() => handleSort(column.field)}
                              >
                                {sortBy.dir === 'desc' ? (
                                  <ArrowDropDownTwoToneIcon fontSize="small" />
                                ) : (
                                  <ArrowDropUpTwoToneIcon fontSize="small" />
                                )}
                              </IconButton>
                            ) : null}
                          </Box>

                          <Box
                            className="actions"
                            position="absolute"
                            right={0}
                            sx={{opacity: 0}}
                          >
                            <DataGridColumnActionsButton
                              column={column}
                              columns={columnsCanBeHidable}
                              shownFields={shownFields}
                              sortBy={sortBy}
                              onSort={(v) => handleSort(column.field, v)}
                              onShownFieldsChange={handleShowFields}
                            />
                          </Box>
                        </Box>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            ) : null}

            <TableBody ref={tableBodyRef}>
              {pageData.map((i, rowIdx) => (
                <Draggable
                  disabled={!draggable}
                  key={rowIdx}
                  nodeRef={tableRef}
                  axis="y"
                  handle={draggableClass ?? '.draggable-handle'}
                  onDrag={(e, ui) => {
                    handleDrag(e, ui, draggableLayout, rowIdx);
                  }}
                  onStop={(e, ui) =>
                    draggableCallback?.(handleDragStop(e, ui, draggableLayout))
                  }
                  position={{x: 0, y: 0}}
                  //defaultPosition={{ x: 0, y: 0 }}
                >
                  <TableRow
                    ref={
                      draggableLayout.filter((el) => el.index === rowIdx)[0]
                        ?.ref
                    }
                    key={rowIdx}
                    hover
                    sx={getSxTr?.(i.row)}
                    onClick={() => onRowClick?.(i.row)}
                  >
                    {shownColumns.map((column, columnIdx) => (
                      <TableCell
                        key={`header-row-${rowIdx}-${column.field.toString()}`}
                        padding={
                          column.type === 'select' ? 'checkbox' : undefined
                        }
                        width={column.width}
                        sx={{
                          maxWidth: 300,
                          color: 'inherit',
                          '@media print': {
                            minWidth: 'auto',
                            px: 1.5,
                            display: !canFieldBePrintable(column.field)
                              ? 'none'
                              : undefined,
                          },
                          ...(column.sxCell ||
                            column.getSxCell?.({
                              field: column.field,
                              row: i.row,
                              value: i.columnValues[columnIdx],
                              valueFormatter: column.valueFormatter,
                            })),
                        }}
                      >
                        {(column.renderCell ?? defaultRenderCell)({
                          field: column.field,
                          row: i.row,
                          value: i.columnValues[columnIdx],
                          valueFormatter: column.valueFormatter,
                        })}
                      </TableCell>
                    ))}
                  </TableRow>
                </Draggable>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {!data.length ? (
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? 'Loading...' : 'No data'}
          </Box>
        ) : null}

        {pagination || footerStart ? (
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pl={1}
            sx={sxFooter}
          >
            <Box flexGrow={1}>{footerStart}</Box>

            {pagination ? (
              <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]}
                count={rowCount}
                rowsPerPage={pageSize}
                page={page}
                onPageChange={(_event, v) => handlePageChange(v)}
                onRowsPerPageChange={(event) =>
                  handlePageSizeChange(+event.target.value || 10)
                }
              />
            ) : null}
          </Box>
        ) : null}
      </Box>
    );
  }
) as <T extends DataGridRow>(
  props: Props<T> & {ref?: ForwardedRef<DataGridRef>}
) => JSX.Element;

export default DataGrid;
