import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalProps,
  Stack,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {useEffect} from 'react';
import * as yup from 'yup';

import {useIndicatorPanel} from '../../contexts';
import {IndicatorPanel} from '../../types';

interface Props extends Omit<ModalProps, 'children'> {
  panel?: IndicatorPanel;
}

export const EditIndicatorPanelDialog = ({panel, ...rest}: Props) => {
  const {createPanel} = useIndicatorPanel();
  const formik = useFormik({
    initialValues: {name: panel?.name || ''},
    validationSchema: yup
      .object()
      .shape({name: yup.string().required('Field is required')}),
    onSubmit: (values) => {
      createPanel(values.name);
      rest?.onClose?.({}, 'backdropClick');
    },
  });

  useEffect(() => {
    if (rest?.open) {
      formik.resetForm();
    }
  }, [rest?.open]);

  return (
    <Dialog maxWidth="xs" fullWidth {...rest}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <DialogTitle>New Indicator Panel</DialogTitle>
        <DialogContent>
          <Stack sx={{py: 1}}>
            <TextField
              label="Panel name"
              name="name"
              autoFocus
              fullWidth
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => rest?.onClose?.({}, 'backdropClick')}>
            Cancel
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
