import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
} from '@mui/material';
import {useTheme} from '@mui/system';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {useEffect, useMemo, useRef, useState} from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {usePrint} from '../../../hooks/print';
import {useAppSelector} from '../../../hooks/redux';
import {
  CheckoutStationDetailsChartQuery,
  CheckoutStationDetailsChartReponse,
} from '../../../interfaces/CheckoutStationDetails';
import reduxSelectors from '../../../redux/selectors';
import {CHART_COLORS} from '../../../utils/colors';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {ShiftSelect} from '../../selectors/ShiftSelect';

interface Props {
  value?: CheckoutStationDetailsReportChartData;
  onChange?: (value: CheckoutStationDetailsReportChartData) => void;
}

export interface CheckoutStationDetailsReportChartData {
  refreshInterval?: number | null;
  params: {
    date_start?: string;
    date_end?: string;
    shift_id?: number | null;
  };
}

export const getCheckoutStationDetailsReportChartData =
  (): CheckoutStationDetailsReportChartData => ({
    params: {},
  });

const CheckoutStationDetailsReportChart = ({value, onChange}: Props) => {
  const config = useMemo(
    () => value ?? getCheckoutStationDetailsReportChartData(),
    [value]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<CheckoutStationDetailsChartReponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: CheckoutStationDetailsChartQuery) => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<CheckoutStationDetailsChartReponse>(
        `${apiBaseUrl}/report/checkout-details-chart`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  const params = useMemo<CheckoutStationDetailsChartQuery>(
    () => ({
      date_start: config.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
      date_end: config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      shift_id: config.params?.shift_id ?? null,
    }),
    [config.params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  /*********/
  /* chart */
  /*********/
  const theme = useTheme();
  const {shifts} = useAppSelector(reduxSelectors.assets.getAssets);

  const dataset = fetchedData;

  const bars = useMemo(() => {
    const activeShifts = shifts.filter(
      (i) =>
        i.status === 'active' && (!params.shift_id || i.id === params.shift_id)
    );

    return activeShifts.flatMap((i) => [
      {
        key: `shift_${i.id}_a`,
        label: `Broken Coil at ${i.name}`,
      },
      {
        key: `shift_${i.id}_b`,
        label: `Switch 1 Timeout at ${i.name}`,
      },
      {
        key: `shift_${i.id}_c`,
        label: `Switch 2 Timeout at ${i.name}`,
      },
    ]);
  }, [dataset, shifts]);

  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  /*********/
  /* print */
  /*********/

  const chartRef = useRef();
  const [printChart] = usePrint(chartRef.current);
  const printTheme = useAppSelector(({app}) => app.printTheme);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        py={2}
      >
        <Box flexGrow={2} display="flex">
          <Box minWidth={400} mr={1}>
            <DateRangeSelect
              value={[
                dayjs(params.date_start).toDate(),
                dayjs(params.date_end).toDate(),
              ]}
              size="small"
              onChange={(v) => {
                onChange?.(
                  update(config, {
                    params: {
                      date_start: {
                        $set: v?.[0]
                          ? dayjs(v?.[0]).format('YYYY-MM-DD')
                          : undefined,
                      },
                      date_end: {
                        $set: v?.[1]
                          ? dayjs(v?.[1]).format('YYYY-MM-DD')
                          : undefined,
                      },
                    },
                  })
                );
              }}
            />
          </Box>

          <ShiftSelect
            value={config.params?.shift_id}
            size="small"
            nullLabel="All Shifts"
            fullWidth
            sx={{width: 200}}
            onChange={(v) => {
              onChange?.(
                update(config, {
                  params: {
                    shift_id: {
                      $set: v,
                    },
                  },
                })
              );
            }}
          />
        </Box>

        <Box display="flex">
          <ButtonGroup>
            <Button size="small" onClick={() => params && fetchData(params)}>
              <RefreshIcon />
            </Button>

            <AutoRefreshSelect
              value={config.refreshInterval ?? null}
              onChange={(v) => {
                onChange?.(
                  update(config, {
                    refreshInterval: {
                      $set: v,
                    },
                  })
                );
              }}
            />

            <Button size="small" onClick={() => printChart()}>
              <PrintIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Backdrop
        open={fetchedInProgress}
        sx={{position: 'absolute', zIndex: 1199}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <Box
        ref={chartRef}
        overflow="hidden"
        position="relative"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        {fetchedData?.length ? (
          <Box
            height={printTheme ? '500px' : '100%'}
            width={printTheme ? '700px' : '100%'}
          >
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={dataset}>
                <XAxis dataKey="group_field" />
                <YAxis />
                <Tooltip
                  shared={false}
                  labelStyle={{
                    color: theme.palette.text.secondary,
                  }}
                  contentStyle={{
                    backgroundColor: theme.palette.background.default,
                  }}
                  cursor={false}
                />
                <Legend onClick={(item) => toggleKey(item.dataKey)} />

                {bars.map(({key, label}, index) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    name={label}
                    hide={hiddenKeys.includes(key)}
                    label={{
                      position: 'top',
                      fill: theme.palette.text.secondary,
                    }}
                    fill={CHART_COLORS[index]}
                    isAnimationActive={!printTheme}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </Box>
        ) : (
          <Box
            display="flex"
            height="100%"
            alignItems="center"
            justifyContent="center"
          >
            {fetchedInProgress ? 'Loading...' : 'No data to display'}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CheckoutStationDetailsReportChart;
