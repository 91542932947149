import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useMemo} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  AlarmHistoryTrackingReports,
  AlarmTrackingReportsData,
  getAlarmTrackingReportsData,
} from './AlarmHistoryTrackingReports';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}
export interface AlarmNodeTrackingReportsData {
  viewType: 'alarms';
  title: string;
  alarm: AlarmTrackingReportsData;
}

const getAlarmNodeTrackingReports = (): AlarmNodeTrackingReportsData => ({
  viewType: 'alarms',
  title: 'Alarm Log Tracking Reports',
  alarm: getAlarmTrackingReportsData(),
});

export const AlarmHistoryReports = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as AlarmNodeTrackingReportsData)
        : getAlarmNodeTrackingReports(),
    [value]
  );

  return (
    <AlarmHistoryTrackingReports
      value={config.alarm}
      onUpdate={(v) => {
        onUpdate?.(
          update(value ?? getAlarmNodeTrackingReports(), {
            alarm: {
              $set: v ?? getAlarmTrackingReportsData(),
            },
          })
        );
      }}
    />
  );
};
