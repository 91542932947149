import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  MachineStatusReportItem,
  MachineStatusReportQuery,
  MachineStatusReportResponse,
} from '../../../interfaces/MachineStatusReport';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {
  DataGridColumn,
  DataGridRef,
  defaultValueFormatter,
  RenderCellParams,
} from '../../common/DataGrid';
import TruncatedText from '../../common/TruncantedText';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'name',
  'zone_name',
  'type_name',
  'external_id',
  'status',
  'last_update',
  'gen1_comm',
  'gen2_comm',
  'gen3_comm',
  'gen4_comm',
  'gen1_lowvoltage',
  'gen2_lowvoltage',
  'gen3_lowvoltage',
  'gen4_lowvoltage',
  'version_master',
  'version_data',
  'version_pingecho',
  'version_gen1',
  'version_gen2',
  'version_gen3',
  'version_gen4',
];

export interface MachineStatusReportData {
  refreshInterval?: number | null;
  params?: {
    page?: number;
    limit?: number;
    order?: string | null;
    dir?: 'ASC' | 'DESC';
  };
}

export const getMachineStatusReportData = (): MachineStatusReportData => ({
  params: {},
});

const getGenValue = (v?: string | null) => {
  if (v === 'yes') {
    return 'low';
  } else if (v === 'no') {
    return 'ok';
  }
  return v;
};

const renderGenCell = (
  params: RenderCellParams<MachineStatusReportItem>,
  title: string
) => {
  const formattedValue = defaultValueFormatter(params);
  return (
    <Tooltip
      title={
        <Box fontSize={14}>
          {title}:{' '}
          <Box component="span" fontWeight={600}>
            {formattedValue}
          </Box>
        </Box>
      }
    >
      <Box>
        <TruncatedText
          title={formattedValue?.toString()}
          sx={{
            '@media print': {
              whiteSpace: 'normal',
            },
          }}
        >
          {formattedValue}
        </TruncatedText>
      </Box>
    </Tooltip>
  );
};

export const MachineStatusReport = (__props: Props) => {
  const [panel] = usePanel();
  const [config, setConfig] = useState(getMachineStatusReportData);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<MachineStatusReportResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<MachineStatusReportQuery>(
    () => ({
      page: config.params?.page ?? 0,
      limit: config.params?.limit ?? 25,
      order: config.params?.order ?? null,
      dir: config.params?.dir ?? null,
    }),
    [config]
  );

  const fetchData = useCallback(
    async (params: MachineStatusReportQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/machine-status`;
        const resp = await API.get<MachineStatusReportResponse>(endpoint, {
          params,
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<MachineStatusReportItem>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'zone_name',
      headerName: 'Section',
      sortable: true,
    },
    {
      field: 'type_name',
      headerName: 'Type',
      sortable: true,
    },
    {
      field: 'external_id',
      headerName: 'Proximity ID',
      sortable: true,
    },
    {
      field: 'status',
      sortable: true,
    },
    {
      field: 'last_update',
      headerName: 'Last Seen',
      sortable: true,
    },
    {
      field: 'gen1_comm',
      headerName: 'G1',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen1_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #1 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen2_comm',
      headerName: 'G2',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen2_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #2 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen3_comm',
      headerName: 'G3',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen3_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #3 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen4_comm',
      headerName: 'G4',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen4_comm),
      renderCell: (params) => renderGenCell(params, 'Generator #4 Comm Status'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'fail' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen1_lowvoltage',
      headerName: 'FV1',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen1_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #1 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen2_lowvoltage',
      headerName: 'FV2',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen2_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #2 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen3_lowvoltage',
      headerName: 'FV3',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen3_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #3 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'gen4_lowvoltage',
      headerName: 'FV4',
      sortable: true,
      valueGetter: (params) => getGenValue(params.row.gen4_lowvoltage),
      renderCell: (params) =>
        renderGenCell(params, 'Generator #4 Feedback Voltage'),
      getSxCell: ({value}) => ({
        fontWeight: value === 'low' ? 'bold' : 'normal',
      }),
    },
    {
      field: 'version_master',
      sortable: true,
    },
    {
      field: 'version_data',
      sortable: true,
    },
    {
      field: 'version_pingecho',
      sortable: true,
    },
    {
      field: 'version_gen1',
      headerName: 'Version Gen 1',
      sortable: true,
    },
    {
      field: 'version_gen2',
      headerName: 'Version Gen 2',
      sortable: true,
    },
    {
      field: 'version_gen3',
      headerName: 'Version Gen 3',
      sortable: true,
    },
    {
      field: 'version_gen4',
      headerName: 'Version Gen 4',
      sortable: true,
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={1}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column" pb={2}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1} />

          <Box display="flex">
            <ButtonGroup>
              <Button size="small" onClick={() => params && fetchData(params)}>
                <RefreshIcon />
              </Button>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Button
                size="small"
                onClick={() => dataGridRef.current?.printTable()}
              >
                <PrintIcon />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        shownFields={shownFields}
        pagination
        paginationMode="server"
        size="small"
        sortBy={
          params?.order
            ? {
                field: params?.order,
                dir: params?.dir === 'DESC' ? 'desc' : 'asc',
              }
            : null
        }
        sortingMode="server"
        page={params?.page}
        pageSize={params?.limit}
        rowCount={fetchedData?.total}
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        getSxTr={(row) => {
          if (
            row.gen1 === 'fail' ||
            row.gen2 === 'fail' ||
            row.gen3 === 'fail' ||
            row.gen4 === 'fail' ||
            row.gen1_lowvoltage === 'low' ||
            row.gen2_lowvoltage === 'low' ||
            row.gen3_lowvoltage === 'low' ||
            row.gen4_lowvoltage === 'low'
          ) {
            return (theme) => ({
              color: theme.palette.error.main,
              bgcolor:
                theme.palette.mode === 'dark'
                  ? undefined
                  : '#febfbe !important',
            });
          }
        }}
        onPageChange={(v) => {
          setConfig?.(
            update(config, {
              params: {
                $set: {
                  ...config.params,
                  page: v,
                },
              },
            })
          );
        }}
        onPageSizeChange={(v) => {
          setConfig?.(
            update(config, {
              params: {
                $set: {
                  ...config.params,
                  page: 0,
                  limit: v,
                },
              },
            })
          );
        }}
        onSort={(v) => {
          if (v) {
            setConfig?.(
              update(config, {
                params: {
                  $set: {
                    ...config.params,
                    order: v.field,
                    dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                  },
                },
              })
            );
          }
        }}
        onShownFieldsChange={setShownFields}
      />
    </Box>
  );
};
