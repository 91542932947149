import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, IconButton, Tooltip} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {CommtracNode} from '../../interfaces/CommtracNode';
import ModalFixed from '../common/ModalFixed';
import CommtracNodeItemMinerAcknowledge from './CommtracNodeItemMinerAcknowledge';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: CommtracNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: (item: CommtracNode) => void;
}

const CommtracNodeItemMinerAcknowledgeButton = <T extends ComponentType>({
  component,
  componentProps,
  children,
  onSubmitted,
  ...props
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Tooltip title="Acknowledge">
        <Component {...componentProps} onClick={() => setIsOpened(true)}>
          {children}
        </Component>
      </Tooltip>

      {isOpened ? (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                className="dragHandle"
                display="flex"
                alignItems="center"
                width="100%"
                gap={1.5}
              >
                <EditIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Acknowledge Employee</Box>
              </Box>
              <IconButton onClick={() => setIsOpened(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <CommtracNodeItemMinerAcknowledge
              {...props}
              onSubmitted={(item) => {
                onSubmitted?.(item);
                setIsOpened(false);
              }}
              onCancel={() => setIsOpened(false)}
            />
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default CommtracNodeItemMinerAcknowledgeButton;
