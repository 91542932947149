import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React from 'react';

export const WidgetSectionSelector = () => {
  const [panel, setPanel] = React.useState('2');

  const handleChange = (event: SelectChangeEvent) => {
    setPanel(event.target.value);
  };
  return (
    <Select value={panel} size="small" onChange={handleChange} autoWidth>
      <MenuItem value="1">All Sections</MenuItem>
      <MenuItem value="3">Underground</MenuItem>
      <MenuItem value="4">Surface</MenuItem>
      <MenuItem value="2">Starter section</MenuItem>
    </Select>
  );
};
