import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {AssetMachine} from '../../interfaces/AssetMachine';
import reduxActions from '../../redux/actions';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  item: AssetMachine;
  action: 'device' | 'data';
  onSubmitted?: () => void;
  onClose?: () => void;
}

export const PurgeActionLabels = {
  device: 'Device',
  data: 'Transactions',
} as const;

const AssetMachineItemPurgeModal = ({
  item,
  action,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const reduxDispatch = useAppDispatch();

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionLabel = PurgeActionLabels[action];
  const actionName = `Purge ${actionLabel}`;

  const submit = async (pk: number | null) => {
    if (!pk) {
      const message = 'Asset has no device to purge';
      enqueueSnackbar(message, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsSubmitting(true);
    try {
      await API.post(`${apiBaseUrl}/purge`, {
        commtrac_node_ids: [pk],
        action: action === 'device' ? 'device' : 'data',
      });
      const message = `Asset ${actionLabel} successfully purged`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
      onClose?.();
      reduxDispatch(reduxActions.assets.fetchAssetMachines);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>Asset: {actionName}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName} of this asset?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(item.commtrac_node_id)}
          color="warning"
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssetMachineItemPurgeModal;
