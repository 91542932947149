import {AlarmLogNode} from '../../interfaces/AlarmLogNode';
import {MapLatLangCoordinates} from '../common/Map';
import AlarmItem from './AlarmItem';
import AlarmItemCreate from './AlarmItemCreate';

interface Props {
  pk?: number;
  item?: AlarmLogNode;
  mode?: 'view' | 'update' | 'chat' | 'ack';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (item: AlarmLogNode, type: 'alarm' | 'alarm_log') => void;
}

const AlarmItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  locationCoordinates,
  isActiveModal,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <AlarmItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      onClose={onClose}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
      onOpenHistory={onOpenHistory}
      locationCoordinates={locationCoordinates}
      isActiveModal={isActiveModal}
    />
  ) : (
    <AlarmItemCreate
      onCancel={onClose}
      onSubmitted={onSubmitted}
      locationCoordinates={locationCoordinates}
    />
  );
};

export default AlarmItemUpsert;
