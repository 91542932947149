import ImportExportIcon from '@mui/icons-material/ImportExport';
import {Button, ButtonProps, Tooltip} from '@mui/material';

export const AmsEmoduleSensorHistoryExportButton = (props: ButtonProps) => {
  const renderExportButton = () => (
    <Button
      {...props}
    >
      <ImportExportIcon />
    </Button>
  );

  return (
    <>
      {props.disabled && (
        renderExportButton()
      )}
      {!props.disabled && (
        <Tooltip title="Import/Export">
          {renderExportButton()}
        </Tooltip>
      )}
    </>
  );
};
