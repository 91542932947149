import appActions from './app/actions';
import assetsActions from './assets/actions';
import authActions from './auth/actions';

const reduxActions = {
  app: appActions,
  auth: authActions,
  assets: assetsActions,
};

export default reduxActions;
