import {MenuItem, TextField, TextFieldProps} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {usePanelCodes} from '../../utils/panel';
import {PanelCode} from '../../utils/panels';

type Props = TextFieldProps & {
  value?: PanelCode;
};

const DashboardPanelSelect = (props: Props) => {
  const {t} = useTranslation();
  const panelCodes = usePanelCodes();

  return (
    <TextField
      fullWidth
      label="Panel"
      {...props}
      value={props.value ?? null}
      select
    >
      {panelCodes.map((option) => (
        <MenuItem key={option} value={option}>
          {t(`panels.${option}`)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default DashboardPanelSelect;
