import {DashboardLayout} from '../../layouts/DashboardLayout';
import {MainPanel} from './components/MainPanel';
import {TopBar} from './components/TopBar';

export const IndicatorPanel = () => {
  return (
    <DashboardLayout>
      <TopBar />
      <MainPanel />
    </DashboardLayout>
  );
};
