import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Button,
  ButtonGroup,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import {Box} from '@mui/system';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty, sumBy} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {usePrint} from '../../../hooks/print';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  InteractionsPerMachineQuery,
  InteractionsPerMachineResponse,
} from '../../../interfaces/InteractionsPerMachine';
import {saveFile} from '../../../utils/file';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {useDashboardDrilldown} from '../../dashboards/DashboardContext';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export interface InteractionsPerMachineData {
  refreshInterval?: number | null;
  params?: {
    zone_id?: number | null;
    date_start?: string | null;
    date_end?: string | null;
    input?: {
      [key: number]: 0 | 1 | null | undefined;
    };
  };
}

export const getInteractionsPerMachineData =
  (): InteractionsPerMachineData => ({
    params: {},
  });

export const InteractionsPerMachine = (props: Props) => {
  const [panel] = usePanel();
  const [config, setConfig] = useState(
    !isEmpty(props.value) ? props.value : getInteractionsPerMachineData()
  );
  const [exportInProgress, setExportInProgress] = useState(false);
  const shifts = useAppSelector(({assets}) => assets.shifts);
  const asset_machines = useAppSelector(({assets}) => assets.asset_machines);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<InteractionsPerMachineResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<InteractionsPerMachineQuery>(
    () => ({
      zone_id: config.params?.zone_id ?? null,
      date_start: config.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
      date_end: config.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      input: config.params?.input ?? {},
    }),
    [config]
  );

  const fetchData = useCallback(
    async (params: InteractionsPerMachineQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/interactions-per-machine`;
        const resp = await API.get<InteractionsPerMachineResponse>(endpoint, {
          params: {
            ...params,
            input: JSON.stringify(params.input),
          },
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
      props.onUpdate?.({...props.value, params});
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  useRefreshInterval(() => fetchData(params), config?.refreshInterval);

  /*********/
  /* print */
  /*********/

  const tableRef = useRef<HTMLTableElement | null>(null);
  const [printTable] = usePrint(tableRef.current);

  const submitExport = async (params: InteractionsPerMachineQuery) => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/report/interactions-per-machine-export`;
      const resp = await API.get(endpoint, {
        params: {
          ...params,
          input: JSON.stringify(params.input),
        },
        responseType: 'blob',
      });
      const filename =
        resp?.headers['content-disposition']?.split('filename=')?.[1] ||
        'interaction_per_machine_report.xlsx';
      saveFile(resp.data, filename);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    } finally {
      setExportInProgress(false);
    }
  };

  const drilldown = useDashboardDrilldown();

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={3}
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(config.params?.date_start).toDate(),
                  dayjs(config.params?.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          date_start: v?.[0]
                            ? dayjs(v?.[0]).format('YYYY-MM-DD')
                            : undefined,
                          date_end: v?.[0]
                            ? dayjs(v?.[1]).format('YYYY-MM-DD')
                            : undefined,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <ZoneSelect
              value={config.params?.zone_id}
              size="small"
              nullLabel="All Sections"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setConfig?.(
                  update(config, {
                    params: {
                      zone_id: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />

            <Box width={200}>
              <MachineInputSelectV2
                value={config.params?.input}
                label="Inputs"
                size="small"
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      params: {
                        $set: {
                          ...config.params,
                          input: v,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
          </Box>

          <Box display="flex">
            <ButtonGroup>
              <Tooltip title="Reload">
                <LoadingButton
                  size="small"
                  loading={fetchedInProgress}
                  variant="outlined"
                  onClick={() => params && fetchData(params)}
                >
                  <RefreshIcon />
                </LoadingButton>
              </Tooltip>

              <AutoRefreshSelect
                value={config?.refreshInterval ?? null}
                onChange={(v) => {
                  setConfig?.(
                    update(config, {
                      refreshInterval: {
                        $set: v,
                      },
                    })
                  );
                }}
              />

              <Tooltip title="Export to Excel">
                <LoadingButton
                  size="small"
                  loading={exportInProgress}
                  variant="outlined"
                  onClick={() => submitExport(params)}
                >
                  <FileDownloadIcon />
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Print">
                <Button onClick={() => printTable()}>
                  <PrintIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <Box
        sx={{
          height: '100%',
          overflow: 'hidden',
          bgcolor: (theme) => theme.palette.background.default,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {fetchedData?.results ? (
          <TableContainer
            sx={{
              overflow: fetchedData.results.length ? 'auto' : 'hidden',
              bgcolor: 'inherit',
              height: fetchedData.results.length ? '100%' : undefined,
            }}
          >
            <Table
              ref={tableRef}
              size="small"
              stickyHeader
              sx={{
                bgcolor: 'inherit',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    rowSpan={2}
                    width={250}
                    sx={{
                      minWidth: 250,
                    }}
                  >
                    Equipment
                  </TableCell>
                  <TableCell
                    rowSpan={2}
                    width={100}
                    sx={{
                      minWidth: 100,
                    }}
                  >
                    ID
                  </TableCell>
                  <TableCell
                    colSpan={shifts.length}
                    align="center"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.300'
                          : 'grey.800',
                    }}
                  >
                    Warnings
                  </TableCell>
                  <TableCell
                    colSpan={shifts.length}
                    align="center"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.100'
                          : 'grey.700',
                    }}
                  >
                    Hazards
                  </TableCell>
                  <TableCell
                    colSpan={shifts.length}
                    align="center"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.300'
                          : 'grey.800',
                    }}
                  >
                    Remote Stops
                  </TableCell>
                </TableRow>
                <TableRow>
                  {shifts.map((i) => (
                    <TableCell
                      key={i.id}
                      align="center"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? 'grey.300'
                            : 'grey.800',
                      }}
                    >
                      {i.name}
                    </TableCell>
                  ))}
                  {shifts.map((i) => (
                    <TableCell
                      key={i.id}
                      align="center"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? 'grey.100'
                            : 'grey.700',
                      }}
                    >
                      {i.name}
                    </TableCell>
                  ))}
                  {shifts.map((i) => (
                    <TableCell
                      key={i.id}
                      align="center"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? 'grey.300'
                            : 'grey.800',
                      }}
                    >
                      {i.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody sx={{overflow: 'auto'}}>
                {fetchedData.results.map((item) => (
                  <TableRow key={item.machine_external_id}>
                    <TableCell>
                      <Tooltip title="View Machine Details Report">
                        <Link
                          sx={{cursor: 'pointer'}}
                          onClick={() => {
                            const type_id = asset_machines.filter(
                              (el) =>
                                el.external_id === +item.machine_external_id
                            )[0]?.type_id;
                            drilldown?.({
                              code: 'MachineDetailsReport',
                              chartType: 'bar',
                              filter: {...params, type_id: type_id},
                            });
                          }}
                        >
                          {item.machine_name}
                        </Link>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{item.machine_external_id}</TableCell>
                    {shifts.map((i) => (
                      <TableCell
                        key={i.id}
                        align="center"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? 'grey.300'
                              : 'grey.800',
                        }}
                      >
                        {+(item[`warning_times${i.id}`] || 0)}
                      </TableCell>
                    ))}
                    {shifts.map((i) => (
                      <TableCell
                        key={i.id}
                        align="center"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? 'grey.100'
                              : 'grey.700',
                        }}
                      >
                        {+(item[`hazard_times${i.id}`] || 0)}
                      </TableCell>
                    ))}
                    {shifts.map((i) => (
                      <TableCell
                        key={i.id}
                        align="center"
                        sx={{
                          backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                              ? 'grey.300'
                              : 'grey.800',
                        }}
                      >
                        {+(item[`remote_times${i.id}`] || 0)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell>Total</TableCell>
                  <TableCell />
                  {shifts.map((i) => (
                    <TableCell
                      key={i.id}
                      align="center"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? 'grey.300'
                            : 'grey.800',
                      }}
                    >
                      {sumBy(
                        fetchedData.results,
                        (item) => +(item[`warning_times${i.id}`] || 0)
                      )}
                    </TableCell>
                  ))}
                  {shifts.map((i) => (
                    <TableCell
                      key={i.id}
                      align="center"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? 'grey.100'
                            : 'grey.700',
                      }}
                    >
                      {sumBy(
                        fetchedData.results,
                        (item) => +(item[`hazard_times${i.id}`] || 0)
                      )}
                    </TableCell>
                  ))}
                  {shifts.map((i) => (
                    <TableCell
                      key={i.id}
                      align="center"
                      sx={{
                        backgroundColor: (theme) =>
                          theme.palette.mode === 'light'
                            ? 'grey.300'
                            : 'grey.800',
                      }}
                    >
                      {sumBy(
                        fetchedData.results,
                        (item) => +item[`remote_times${i.id}`]
                      )}
                    </TableCell>
                  ))}
                </TableRow>

                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell
                    colSpan={2}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.300'
                          : 'grey.800',
                    }}
                  >
                    Total Warnings:
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.300'
                          : 'grey.800',
                    }}
                  >
                    {sumBy(fetchedData.results, (item) =>
                      sumBy(shifts, (i) => +(item[`warning_times${i.id}`] || 0))
                    )}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.100'
                          : 'grey.700',
                    }}
                  >
                    Total Hazards:
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.100'
                          : 'grey.700',
                    }}
                  >
                    {sumBy(fetchedData.results, (item) =>
                      sumBy(shifts, (i) => +(item[`hazard_times${i.id}`] || 0))
                    )}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.300'
                          : 'grey.800',
                    }}
                  >
                    Total Remote Stops:
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? 'grey.300'
                          : 'grey.800',
                    }}
                  >
                    {sumBy(fetchedData.results, (item) =>
                      sumBy(shifts, (i) => +(item[`remote_times${i.id}`] || 0))
                    )}
                  </TableCell>
                </TableRow>

                {shifts.map((i) => (
                  <TableRow key={i.id}>
                    <TableCell>Total Interactions {i.name}</TableCell>
                    <TableCell>
                      {sumBy(
                        fetchedData.results,
                        (item) =>
                          +(item[`warning_times${i.id}`] || 0) +
                          +(item[`hazard_times${i.id}`] || 0) +
                          +(item[`remote_times${i.id}`] || 0)
                      )}
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell>Total Interactions For The Day</TableCell>
                  <TableCell>
                    {sumBy(fetchedData.results, (item) =>
                      sumBy(
                        shifts,
                        (i) =>
                          +(item[`warning_times${i.id}`] || 0) +
                          +(item[`hazard_times${i.id}`] || 0) +
                          +(item[`remote_times${i.id}`] || 0)
                      )
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Box
            display="flex"
            height={50}
            alignItems="center"
            justifyContent="center"
          >
            {!fetchedData?.results.length ? (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                {fetchedInProgress ? 'Loading...' : 'No data'}
              </Box>
            ) : null}
          </Box>
        )}
      </Box>
    </Box>
  );
};
