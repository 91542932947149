import {LoadingButton} from '@mui/lab';
import {useEffect, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useConfiguration} from '../../../hooks/configuration';
import {useAppDispatch} from '../../../hooks/redux';
import {ConfigurationResponseItem} from '../../../interfaces/Configuration';
import reduxActions from '../../../redux/actions';
import {getMSFromConfigurationRefreshPeriod} from '../../../utils/configuration';

interface Props {
  onDone?: () => void;
  refreshPeriod?: ConfigurationResponseItem | null;
}

export const AMSSensorToggleNonISDevices = ({onDone, refreshPeriod}: Props) => {
  // Dispatch
  const reduxDispatch = useAppDispatch();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  // Constants
  const amsVentilationFailure = useConfiguration('ams', 'ventilation_failure');
  const amsVentilationFailureValue = amsVentilationFailure
    ? amsVentilationFailure.value
    : null;
  const buttonText = `${isError ? 'Retry ' : ''} ${amsVentilationFailureValue === 0 ? 'Disable' : 'Enable'} non-IS Devices`;
  const defaultRefreshPeriod = 60 * 1000; // 1 minute
  const refreshPeriodValue =
    getMSFromConfigurationRefreshPeriod(refreshPeriod) || defaultRefreshPeriod;

  // Handlers
  const toggleNonISDevices = async () => {
    try {
      setIsLoading(true);
      setIsError(false);

      await API.patch(`${apiBaseUrl}/ams/ventilation_failure`, {
        disable: amsVentilationFailureValue === 0,
      });

      await reduxActions.app.fetchMyConfigurations(reduxDispatch);

      onDone?.();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }

      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  //Effects
  useEffect(() => {
    const interval = setInterval(async () => {
      try {
        setIsLoading(true);
        setIsError(false);

        await reduxDispatch(reduxActions.app.fetchMyConfigurations);
      } catch (error) {
        if (error instanceof Error) {
          console.error(error.message);
        } else {
          console.error(error);
        }

        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    }, refreshPeriodValue);

    return () => {
      clearInterval(interval);
    };
  }, [refreshPeriodValue]);

  if (amsVentilationFailureValue === null) {
    return null;
  }

  return (
    <LoadingButton
      onClick={toggleNonISDevices}
      variant="outlined"
      size="small"
      sx={{height: '100%'}}
      loading={isLoading}
    >
      {buttonText}
    </LoadingButton>
  );
};
