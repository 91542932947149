import keyBy from 'lodash/keyBy';
import {useMemo} from 'react';

import {findConfiguration} from '../utils/configuration';
import {useAppSelector} from './redux';

export const useConfigurations = () => {
  const myConfigurations = useAppSelector(({app}) => app.myConfigurations);
  const configurations = useMemo(
    () => keyBy(myConfigurations, (i) => `${i.section}.${i.name}`),
    [myConfigurations]
  );
  return configurations;
};

export const useConfiguration = (section: string, name: string) => {
  const myConfigurations = useAppSelector(({app}) => app.myConfigurations);
  const configuration = useMemo(
    () => findConfiguration(myConfigurations, section, name),
    [myConfigurations, name, section]
  );
  return configuration;
};
