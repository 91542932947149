import {Box} from '@mui/material';
import dayjs from 'dayjs';
import * as _ from 'lodash';
import {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import {
  AmsEmoduleSensorHistoryResponse,
} from "../../../interfaces/AmsEmoduleSensorHistory";
import reduxSelectors from '../../../redux/selectors';
import {AmsEmoduleSensorHistoryTabConfig} from './index';

interface AmsEmoduleSensorHistoryChartProps {
  data?: AmsEmoduleSensorHistoryResponse;
  config?: AmsEmoduleSensorHistoryTabConfig;
}

export const AmsEmoduleSensorHistoryChart = (props: AmsEmoduleSensorHistoryChartProps) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);

  const config = props.config || {};
  const multipleDays = config.params?.date_start !== config.params?.date_end;

  const chartData = useMemo(() => {
    return _.orderBy(props.data?.items.filter((i) => config.selectedIds?.includes(i.id)), 'date');
  }, [props.data?.items, config.selectedIds]);

  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);

  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  return (
    <Box
      bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
      height="100%"
    >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={chartData} margin={{left: 20, top: 40}}>
          <XAxis
            dataKey="date"
            tickFormatter={
              (v: string) => dayjs(v).format(
                multipleDays ? 'MM-DD-YYYY HH:mm' : 'HH:mm A'
              )
            }
          />
          <YAxis>
            <Label value="Sensor Value" dx={-30} angle={-90} />
          </YAxis>
          <RechartsTooltip
            labelStyle={{color: 'black'}}
            labelFormatter={
              (v: string) => dayjs(v).format(
                'ddd, MMM D, YYYY HH:mm:ss'
              )
            }
          />
          <CartesianGrid opacity={0.3} />
          <Legend onClick={(item) => toggleKey(item.dataKey)} />
          <Line dataKey="value" name="Sensor Value" />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};
