import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import {Box, IconButton, TextField, TextFieldProps} from '@mui/material';
import isNil from 'lodash/isNil';
import {ChangeEvent, useEffect, useState} from 'react';

type Props = Omit<TextFieldProps, 'value' | 'onChange' | 'type'> & {
  value?: number | null;
  min?: number;
  max?: number;
  step?: number;
  decimalPlaces?: number;
  onChange?: (value: number | null) => void;
};

const getNumberRegex = (decimalPlaces: number) => {
  if (decimalPlaces > 0) {
    return new RegExp(`^\\d+(\\.\\d{0,${decimalPlaces}})?$`);
  }
  return /^\d+$/;
};

const getStringValue = (v?: number | null) => v?.toString() || '';

const NumberTextField = ({
  value: initialValue,
  min,
  max,
  step = 1,
  decimalPlaces = 0,
  onChange,
  ...props
}: Props) => {
  const [value, setValue] = useState(() => getStringValue(initialValue));

  useEffect(() => {
    const v = getStringValue(initialValue);
    if (v !== value) {
      setValue(v);
    }
  }, [initialValue]);

  const numberRegex = getNumberRegex(decimalPlaces);

  const getIsValueInRange = (v: number) =>
    (min === undefined || v >= min) && (max === undefined || v <= max);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const stringValue = e.target.value;
    const numberValue =
      stringValue === '' || isNil(stringValue) ? null : +stringValue;

    if (numberValue === null) {
      setValue(stringValue);
      onChange?.(numberValue);
    } else if (numberRegex.test(stringValue)) {
      const isInRange = getIsValueInRange(numberValue);
      if (isInRange) {
        setValue(stringValue);
        onChange?.(numberValue);
      }
    }
  };

  const d = Math.pow(10, decimalPlaces);
  const s = d * step;
  const prevValue = ((initialValue ?? 0) * d - s) / d;
  const nextValue = ((initialValue ?? 0) * d + s) / d;
  const isPrevDisabled =
    !getIsValueInRange(prevValue) && (initialValue ?? 0) <= (min ?? 0);
  const isNextDisabled =
    !getIsValueInRange(nextValue) && (initialValue ?? 0) >= (max ?? 0);

  return (
    <TextField
      {...props}
      value={value}
      type="text"
      InputProps={{
        sx: {p: 0},
        endAdornment: (
          <Box
            display="flex"
            flexDirection="column"
            px={1}
            justifyContent="space-around"
          >
            <IconButton
              size="small"
              sx={{p: 0}}
              disabled={isNextDisabled}
              onClick={() => {
                onChange?.(nextValue);
              }}
            >
              <ArrowDropUpOutlinedIcon sx={{fontSize: 18}} />
            </IconButton>
            <IconButton
              size="small"
              sx={{p: 0}}
              disabled={isPrevDisabled}
              onClick={() => {
                onChange?.(prevValue);
              }}
            >
              <ArrowDropDownOutlinedIcon sx={{fontSize: 18}} />
            </IconButton>
          </Box>
        ),
      }}
      onChange={handleChange}
      onKeyDown={(e) => {
        if (e.key === 'ArrowUp' && !isNextDisabled) {
          onChange?.(nextValue);
        } else if (e.key === 'ArrowDown' && !isPrevDisabled) {
          onChange?.(prevValue);
        }
      }}
    />
  );
};

export default NumberTextField;
