import {Box, Button, ButtonGroup} from '@mui/material';
import {t} from 'i18next';
import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useEffect, useState} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import CheckoutStationDetailsReportChart, {
  CheckoutStationDetailsReportChartData,
  getCheckoutStationDetailsReportChartData,
} from './CheckoutStationDetailsReportChart';
import CheckoutStationDetailsReportGrid, {
  CheckoutStationDetailsReportGridData,
  getCheckoutStationDetailsReportGridData,
} from './CheckoutStationDetailsReportGrid';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export interface CheckoutStationDetailsReportData {
  viewType: 'grid' | 'chart';
  grid: CheckoutStationDetailsReportGridData;
  chart: CheckoutStationDetailsReportChartData;
}

const getCheckoutStationDetailsReportData =
  (): CheckoutStationDetailsReportData => ({
    viewType: 'grid',
    grid: getCheckoutStationDetailsReportGridData(),
    chart: getCheckoutStationDetailsReportChartData(),
  });

export const CheckoutStationDetailsReport = (props: Props) => {
  const [panel] = usePanel();
  const [config, setConfig] = useState(
    !isEmpty(props.value) ? props.value : getCheckoutStationDetailsReportData()
  );

  useEffect(() => {
    props.onUpdate?.({...config})
  }, [config]);

  return (
    <>
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>
      <Box position="absolute" top={8} left={8}>
        <ButtonGroup>
          <Button
            size="small"
            variant={config.viewType === 'grid' ? 'contained' : 'outlined'}
            onClick={() => {
              setConfig(
                update(config, {
                  viewType: {
                    $set: 'grid',
                  },
                })
              );
            }}
          >
            Grid
          </Button>

          <Button
            size="small"
            variant={config.viewType === 'chart' ? 'contained' : 'outlined'}
            onClick={() => {
              setConfig(
                update(config, {
                  viewType: {
                    $set: 'chart',
                  },
                })
              );
            }}
          >
            Graph
          </Button>
        </ButtonGroup>
      </Box>

      {config.viewType === 'grid' ? (
        <CheckoutStationDetailsReportGrid
          value={config.grid}
          onChange={(v) => {
            setConfig(
              update(config, {
                grid: {
                  $set: v,
                },
              })
            );
          }}
        />
      ) : config.viewType === 'chart' ? (
        <CheckoutStationDetailsReportChart
          value={config.chart}
          onChange={(v) => {
            setConfig(
              update(config, {
                chart: {
                  $set: v,
                },
              })
            );
          }}
        />
      ) : null}
    </>
  );
};
