// Source: http://www.danvk.org/hex2dec.html

// Adds two arrays for the given base (10 or 16), returning the result.
// This turns out to be the only "primitive" operation we need.
const add = (x: number[], y: number[], base: number) => {
  const z: number[] = [];
  const n = Math.max(x.length, y.length);
  let carry = 0;
  let i = 0;
  while (i < n || carry) {
    const xi = i < x.length ? x[i] : 0;
    const yi = i < y.length ? y[i] : 0;
    const zi = carry + xi + yi;
    z.push(zi % base);
    carry = Math.floor(zi / base);
    i++;
  }
  return z;
};

// Returns a*x, where x is an array of decimal digits and a is an ordinary
// JavaScript number. base is the number base of the array x.
const multiplyByNumber = (
  num: number,
  x: number[],
  base: number
): null | number[] => {
  if (num < 0) {
    return null;
  }

  if (num === 0) {
    return [];
  }

  let result: number[] = [];
  let power = x;
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-bitwise
    if (num & 1) {
      result = add(result, power, base);
    }
    // eslint-disable-next-line no-bitwise
    num = num >> 1;
    if (num === 0) {
      break;
    }

    power = add(power, power, base);
  }

  return result;
};

const parseToDigitsArray = (str: string, base: number) => {
  const digits = str.split('');
  const ary = [];
  for (let i = digits.length - 1; i >= 0; i--) {
    const n = parseInt(digits[i], base);
    if (isNaN(n)) {
      return null;
    }

    ary.push(n);
  }

  return ary;
};

export const convertBase = (str: string, fromBase: number, toBase: number) => {
  const digits: number[] | null = parseToDigitsArray(str, fromBase);

  if (digits === null) {
    return null;
  }

  let outArray: number[] | null = [];
  let power: number[] | null = [1];
  for (let i = 0; i < digits.length; i++) {
    // invariant: at this point, fromBase^i = power
    if (digits[i] !== null && power !== null) {
      const a: null | number[] = multiplyByNumber(digits[i], power, toBase);

      if (a) {
        outArray = add(outArray, a, toBase);
      }
      power = multiplyByNumber(fromBase, power, toBase);
    }
  }

  let out = '';
  for (let i = outArray.length - 1; i >= 0; i--) {
    out += outArray[i].toString(toBase);
  }
  return out;
};

export const decToHex = (decStr: string) => {
  const hex = convertBase(decStr, 10, 16);
  return hex ? hex : null;
};

export const hexToDec = (hexStr: string) => {
  if (hexStr.substring(0, 2) === '0x') {
    hexStr = hexStr.substring(2);
  }

  hexStr = hexStr.toLowerCase();
  return convertBase(hexStr, 16, 10);
};
