import {Button} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {Zone} from '../../interfaces/Zone';
import ModalFixed from '../common/ModalFixed';
import ZoneItemUpsert from './ZoneItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: Zone;
  prefetch?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
}

const ZoneItemUpsertButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  onSubmitted,
  ...props
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component {...componentProps} onClick={() => setIsOpened(true)}>
        {children}
      </Component>

      {isOpened && (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <ZoneItemUpsert
            {...props}
            onSubmitted={() => {
              onSubmitted?.();
              setIsOpened(false);
            }}
            onClose={() => setIsOpened(false)}
          />
        </ModalFixed>
      )}
    </>
  );
};

export default ZoneItemUpsertButton;
