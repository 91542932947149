import {Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useParams} from 'react-router-dom';

import {CloseSnackbarButton} from '../../components/common/CloseSnackbarButton';
import {
  DashboardProvider,
  useDashboard,
} from '../../components/dashboards/DashboardContext';
import {DashboardEntityProvider} from '../../components/dashboards/entities/DashboardEntityContext';
import DashboardEntityItem from '../../components/dashboards/entities/DashboardEntityItem';
import {
  DashboardEntity,
  DashboardUpsertInputBody,
} from '../../interfaces/Dashboard';
import {DashboardLayout} from '../../layouts/DashboardLayout';
import {PanelCode} from '../../utils/panels';

const DashboardEntitList = ({onOpenHistory}: any) => {
  const [dashboard, dispatchDashboard] = useDashboard();

  const handleChangeEntity = (entity: DashboardEntity) => {
    dispatchDashboard({
      type: 'SetEntity',
      payload: entity,
    });
  };

  const handleDeleteEntity = (id: string) => {
    dispatchDashboard({
      type: 'DeleteEntity',
      payload: id,
    });
  };

  const [filter, setFilter] = useState({
    params: {},
  });

  return (
    <>
      {dashboard.data?.entities?.map((item) => {
        return (
          <Box key={item.id} data-id={item.id} height="100%" overflow="auto">
            <DashboardEntityProvider
              value={item}
              onChange={handleChangeEntity}
              filter={{filter, setFilter}}
            >
              <DashboardEntityItem
                value={item}
                disabled
                onDelete={() => handleDeleteEntity(item.id)}
                onUpdate={handleChangeEntity}
                onOpenHistory={onOpenHistory}
              />
            </DashboardEntityProvider>
          </Box>
        );
      })}
    </>
  );
};

const DashboardPanelItemPage = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const {code} = useParams();
  const [input, setInput] = useState<DashboardUpsertInputBody>(() => ({
    name: null,
    data: {
      entities: [
        {
          id: '1',
          name: 'Fake',
          type: 'panel',
          panel: {
            code: code as PanelCode,
          },
          grid: {
            x: 0,
            y: 0,
            w: 12,
            h: 4,
          },
        },
      ],
    },
  }));

  const handleOpenHistory = () => {
    const message = (
      <div>
        <div>Some features of this panel open additional panels/views.</div>
        <div>
          To properly, review and work with this panel, please place it in a
          dashboard.
        </div>
      </div>
    );
    enqueueSnackbar(message, {
      variant: 'error',
      action: (key) => (
        <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
      ),
    });
  };

  const handleDrilldown = () => {
    const message = (
      <div>
        <div>Some features of this panel open additional panels/views.</div>
        <div>
          To properly, review and work with this panel, please place it in a
          dashboard.
        </div>
      </div>
    );
    enqueueSnackbar(message, {
      variant: 'error',
      action: (key) => (
        <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
      ),
    });
  };

  const [filter, setFilter] = useState({});

  return (
    <DashboardLayout>
      <Box display="flex" flexDirection="column" height="100%">
        <DashboardProvider
          value={input}
          onChange={setInput}
          filter={{filter, setFilter}}
          drilldown={handleDrilldown}
        >
          <DashboardEntitList onOpenHistory={handleOpenHistory} />
        </DashboardProvider>
      </Box>
    </DashboardLayout>
  );
};

export default DashboardPanelItemPage;
