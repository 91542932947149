import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from '@mui/material';
import {SnackbarAction, useSnackbar} from 'notistack';

interface CloseSnackbarProps {
  onClick?: Function;
}

export const CloseSnackbarButton: React.FC<CloseSnackbarProps> = ({
  onClick,
}) => {
  return (
    <IconButton size="small" color="inherit" onClick={() => onClick?.()}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};

export const CloseSnackbarAction: SnackbarAction = (key) => {
  const {closeSnackbar} = useSnackbar();
  return <CloseSnackbarButton onClick={() => closeSnackbar(key)} />;
};
