import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {t} from 'i18next';
import {useEffect, useRef, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import useDebounce from '../../../hooks/useDebounce';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {MCronQuery, MCronStatusResponse} from '../../../interfaces/MCron';
import {processStatusIcons} from '../../../utils/event-icons';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import NumberTextField from '../../common/NumberTextField';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export const MCronLogs = (props: Props) => {
  const [panel] = usePanel();
  const logsRef = useRef<HTMLTextAreaElement>(null);

  /*********/
  /* fetch */
  /*********/
  const formik = useFormik<MCronQuery>({
    initialValues: {
      lines: props.value?.params?.lines || 100,
    },
    onSubmit: () => {},
  });

  const debouncedFormikValues = useDebounce(formik.values);

  const [fetchedData, setFetchedData] = useState<MCronStatusResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<MCronStatusResponse>(
        `${apiBaseUrl}/m-cron/status`,
        {
          params: debouncedFormikValues,
        }
      );
      setFetchedData(resp.data);
      setTimeout(() => {
        if (logsRef.current) {
          logsRef.current.scrollTop = logsRef.current.scrollHeight;
        }
      }, 100);
    } catch (error: any) {
      setFetchedErrors(error.response.data?.errors ?? []);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (debouncedFormikValues) {
      fetchData();
      props?.onUpdate?.({
        ...props.value,
        params: debouncedFormikValues
      });
    }
  }, [debouncedFormikValues]);

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    fetchData,
    props.value?.refreshInterval ?? 10000
  );

  useEffect(() => {
    props?.onUpdate?.({...props.value, refreshInterval});
  }, [refreshInterval]);

  const [storedInterval, setStoredInterval] = useState(props.value?.refreshInterval);
  useEffect(() => {
    if (!fetchedData?.running) {
      setFetchedErrors([]);
      setStoredInterval(refreshInterval);
      setRefreshInterval(null);
    } else {
      setRefreshInterval(storedInterval);
      fetchData();
    }
  }, [fetchedData?.running]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Box display="flex" gap={2} py={1} justifyContent="space-between">
        <Box display="flex" gap={2}>
          <AutoRefreshSelect
            value={refreshInterval ?? null}
            variant="outlined"
            size="small"
            onChange={(value) => setRefreshInterval(value)}
          />

          <NumberTextField
            value={formik.values.lines}
            min={1}
            label="Num. Lines"
            size="small"
            name="lines"
            sx={{width: 100}}
            onChange={(v) => formik.setFieldValue('lines', v)}
          />

          <TextField
            value={fetchedData?.logPath ?? ''}
            label="Path"
            name="path"
            size="small"
            sx={{width: 400}}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <IconButton disabled={fetchedInProgress} onClick={() => fetchData()}>
          <RefreshIcon />
        </IconButton>
      </Box>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box>
        {fetchedErrors.map((error, idx) => (
          <Alert
            key={`fetched-error-${idx}`}
            severity="error"
            onClose={() => fetchData()}
          >
            {error}
          </Alert>
        ))}
      </Box>

      <Box height="100%">
        <TextField
          inputRef={logsRef}
          value={fetchedData?.log ?? ''}
          fullWidth
          multiline
          disabled={!fetchedData?.running}
          InputProps={{
            readOnly: true,
            sx: {
              height: '100%',
            },
          }}
          inputProps={{
            sx: {
              height: '100% !important',
              overflow: 'scroll !important',
            },
          }}
          sx={{
            height: '100%',
          }}
        />
      </Box>
      <Box display="flex" sx={{'margin-top': '10px'}}>
        {processStatusIcons[fetchedData?.running ? 'running' : 'stopped']}
        {`Status: ${fetchedData?.running ? 'running' : 'stopped'}`}
      </Box>
    </Box>
  );
};
