import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, IconButton} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {CommunicationNode} from '../../interfaces/CommunicationNodeV2';
import ModalFixed from '../common/ModalFixed';
import CommunicationNodeBroadcastReportFrequency from './CommunicationNodeBroadcastReportFrequency';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: (item: CommunicationNode) => void;
  onClose?: () => void;
}

const CommunicationNodeBroadcastReportFrequencyButton = <
  T extends ComponentType,
>({
  component,
  componentProps,
  children,
  onSubmitted,
  onClose,
  ...props
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component {...componentProps} onClick={() => setIsOpened(true)}>
        {children}
      </Component>

      {isOpened ? (
        <ModalFixed
          width={500}
          open={isOpened}
          onClose={() => setIsOpened(false)}
        >
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box
                className="dragHandle"
                display="flex"
                alignItems="center"
                width="100%"
                gap={1.5}
              >
                <EditIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Broadcast Report Frequency</Box>
              </Box>
              {onClose ? (
                <IconButton onClick={() => onClose()}>
                  <CloseIcon />
                </IconButton>
              ) : null}
            </Box>
            <CommunicationNodeBroadcastReportFrequency
              {...props}
              onSubmitted={(item) => {
                onSubmitted?.(item);
                setIsOpened(false);
              }}
              onCancel={() => setIsOpened(false)}
            />
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default CommunicationNodeBroadcastReportFrequencyButton;
