import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {ActivationReasonType} from '../../interfaces/AlarmLogNode';
import reduxSelectors from '../../redux/selectors';
import {alarmShortCodeSchema} from '../../scheme/yup/alarm-module';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  type: 'warn' | 'evacuate';
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  preSelected?: number[];
  onClose?: () => void;
  onSubmitted?: () => void;
  messageId?: number;
}

type AlarmStatusInputBody = {
  type: 'enable' | 'disable';
  mode: 'alarm' | 'warning';
  module: 'all';
  reason: ActivationReasonType['key'] | null;
  reason_note?: string | null;
  module_all?: boolean;
  message_id?: number;
};

const AlarmShortcodeForm = <T extends ComponentType>({
  type,
  onClose,
  onSubmitted,
  messageId,
}: Props<T>) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const alarm_logs = assets.alarm_logs;
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const activationReason: ActivationReasonType[] =
    type === 'warn'
      ? [
          {
            key: 'emergency',
            label: 'Warning',
          },
          {
            key: 'other',
            label: 'Other',
          },
        ]
      : [
          {
            key: 'evacuate',
            label: 'Evacuate',
          },
          {
            key: 'other',
            label: 'Other',
          },
        ];
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const formik = useFormik<AlarmStatusInputBody>({
    initialValues: {
      type: 'enable',
      mode: 'alarm',
      module: 'all',
      reason: null,
      reason_note: null,
      message_id: messageId,
    },
    validationSchema: alarmShortCodeSchema,
    onSubmit: async (data) => {
      setSubmittedInProgress(true);
      try {
        const payload = {
          alarm: false,
          module_all: false,
          module_ids: alarm_logs?.map((it) => it?.id),
          silent: false,
          reason: data.reason,
          reason_note: data.reason_note ?? null,
        };
        const resp = await API.patch(
          `${apiBaseUrl}/alarm-module/alarm/enable`,
          payload
        );
        const successMessage =
          resp?.data?.message ?? 'Succeed in enabling module.';
        enqueueSnackbar(successMessage, {
          variant: 'success',
          action: (key) => (
            <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
          ),
        });

        onSubmitted?.();
        onClose?.();
      } catch (error) {
        const messages = getMessagesFromApiError(error);
        setSubmitErrors(messages);
      } finally {
        setSubmittedInProgress(false);
      }
    },
  });

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h6" component="h2" flex={1}>
          {type === 'warn' ? 'Warn All' : 'Evacuate All'}
        </Typography>

        <IconButton
          onClick={() => {
            onClose?.();
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {submitErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Autocomplete
        value={
          activationReason.find((i) => i.key === formik.values.reason) ?? null
        }
        fullWidth
        options={activationReason}
        isOptionEqualToValue={(option, value) => option.key === value?.key}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Reason"
            size="small"
            error={!!formik.touched.reason && !!formik.errors.reason}
            helperText={formik.touched.reason && formik.errors.reason}
          />
        )}
        onChange={(event, value) => formik.setFieldValue('reason', value?.key)}
      />
      {formik.values.reason === 'other' && (
        <TextField
          value={formik.values.reason_note}
          label="Description"
          size="small"
          name="reason_note"
          multiline
          rows={3}
          fullWidth
          error={!!formik.touched.reason_note && !!formik.errors.reason_note}
          helperText={formik.touched.reason_note && formik.errors.reason_note}
          onChange={formik.handleChange}
        />
      )}
      <Box display="flex" justifyContent="end" gap={0.5}>
        <Button
          onClick={() => {
            onClose?.();
          }}
        >
          Cancel
        </Button>

        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ml: 1}}
          >
            Apply
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AlarmShortcodeForm;
