import {Button} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {AssetMachine} from '../../interfaces/AssetMachine';
import ModalDraggable from '../common/ModalDraggable';
import AssetMachineItemUpsert from './AssetMachineItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: AssetMachine;
  mode?: 'view' | 'update';
  prefetch?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: () => void;
  onOpen?: () => void;
  onClose?: Function;
}

const AssetMachineItemUpsertButton = <T extends ComponentType = typeof Button>({
  pk,
  item,
  mode,
  prefetch,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
  onOpenHistory,
  onOpen,
  onClose,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component
        {...componentProps}
        onClick={() => {
          setIsOpened(true);
          onOpen?.();
        }}
      >
        {children}
      </Component>

      {isOpened ? (
        <ModalDraggable open={isOpened}>
          <AssetMachineItemUpsert
            pk={pk}
            item={item}
            mode={mode}
            prefetch={prefetch}
            onClose={() => {
              setIsOpened(false);
              onClose?.();
            }}
            onSubmitted={() => {
              onSubmitted?.();
              if (!pk) {
                setIsOpened(false);
                onClose?.();
              }
            }}
            onPurged={onPurged}
            onOpenHistory={onOpenHistory}
          />
        </ModalDraggable>
      ) : null}
    </>
  );
};

export default AssetMachineItemUpsertButton;
