import {Grid, Typography} from '@mui/material';
import React from 'react';

import {BaseMetric, BaseMetricProps} from './BaseMetric';

interface Props extends BaseMetricProps {
  value: number | string;
  label?: string;
}

export const SingleMetric = ({value, label, ...rest}: Props) => {
  return (
    <BaseMetric {...rest}>
      <Grid item xs={12} textAlign="center">
        <Typography variant="h2" fontWeight="500">
          {value}
        </Typography>
      </Grid>
      <Grid item xs={12} textAlign="center">
        <Typography variant="caption">{label}</Typography>
      </Grid>
    </BaseMetric>
  );
};
