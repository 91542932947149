import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {LoadingButton} from '@mui/lab';
import {enqueueSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useConfiguration} from '../../hooks/configuration';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {ConfigurationResponse} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {getMergedConfigurations} from '../../utils/configuration';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

const themeConfigSection = 'global';
const themeConfigName = 'theme';

const MyDarkModeSwitcher = () => {
  const reduxDispatch = useAppDispatch();
  const myConfigurations = useAppSelector(
    (state) => state.app.myConfigurations
  );
  const themeConfiguration = useConfiguration(
    themeConfigSection,
    themeConfigName
  );
  const isDarkMode = themeConfiguration?.value !== 'light';

  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const handleToggleIsDarkMode = async () => {
    setSubmittedInProgress(true);
    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        {
          section: ['global'],
          configuration: [
            {
              name: themeConfigName,
              section: themeConfigSection,
              value: isDarkMode ? 'light' : 'dark',
            },
          ],
        }
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  return (
    <LoadingButton
      color="primary"
      loading={submittedInProgress}
      sx={{minWidth: 'auto', borderRadius: '50%', aspectRatio: '1'}}
      onClick={handleToggleIsDarkMode}
    >
      {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
    </LoadingButton>
  );
};

export default MyDarkModeSwitcher;
