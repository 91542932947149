import {Box, Checkbox} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';

import {HumanEmployee, HumanGroupEmployee} from '../../interfaces/HumanGroup';
import DataGrid, {DataGridColumn} from '../common/DataGrid';

type TablePropsType = {
  employees: number[];
  allEmployees: HumanGroupEmployee[];
  setFieldValue: (field: string, value: any) => void;
};
export const HumanGroupTable = React.memo(
  ({employees, setFieldValue, allEmployees}: TablePropsType) => {
    const [employeeList, setEmployeeList] = useState<HumanGroupEmployee[]>([]);

    const columns: DataGridColumn<HumanEmployee>[] = [
      {
        field: 'checked',
        headerName: 'Selected',
        sortable: true,
        renderCell: ({row}) => (
          <Checkbox
            color="primary"
            checked={employees.includes(row.id)}
            onChange={() => fieldHandle(row.id)}
          />
        ),
      },
      {field: 'id', headerName: 'ID', sortable: true},
      {
        field: 'employee_asset_id',
        headerName: 'Employee Asset Id',
        sortable: true,
      },
      {field: 'first_name', headerName: 'First Name', sortable: true},
      {field: 'last_name', headerName: 'Last Name', sortable: true},
      {field: 'nickname', headerName: 'Nickname', sortable: true},
      {field: 'network_id', headerName: 'Network Id', sortable: true},
      {field: 'type', headerName: 'Type', sortable: true},
      {field: 'section', headerName: 'Section', sortable: true},
    ];

    useEffect(() => {
      setEmployeeList(allEmployees);
    }, [allEmployees]);

    useEffect(() => {
      if (employees.length > 0) {
        const updateEmployees = JSON.parse(JSON.stringify(allEmployees));
        updateEmployees.forEach((item: HumanEmployee) => {
          item.checked = employees.includes(item.id);
        });

        setEmployeeList(updateEmployees);
      }
    }, [employees]);

    const fieldHandle = useCallback(
      (itemId: number) => {
        if (employees.includes(itemId)) {
          setFieldValue(
            'employees',
            employees.filter((el: number) => el !== itemId)
          );
        } else {
          setFieldValue('employees', [...employees, itemId]);
        }
      },
      [employees]
    );

    return (
      <Box width="100%" height={500} overflow="hidden">
        <DataGrid
          rows={employeeList}
          columns={columns}
          pagination
          size="small"
        />
      </Box>
    );
  }
);
