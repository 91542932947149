import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {AssetMachine} from '../../interfaces/AssetMachine';
import {
  CommtracNode,
  CommutracNodeAssetAcknowledgeInputBody,
} from '../../interfaces/CommtracNode';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import {commtracNodeAssetAcknowledgeInputSchema} from '../../scheme/yup/commtrac-node';
import {maxProximityId} from '../../scheme/yup/utils';
import {
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsProximityFieldsVisible,
} from '../../utils/commtrac-nodes';
import AssetMachineSelect from '../asset-machine/AssetMachineSelect';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import {MachineTypeSelect} from '../selectors/MachineTypeSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  item: CommtracNode;
  onCancel?: Function;
  onSubmitted?: (item: CommtracNode) => void;
}

type AcknowledgeInputBody = CommutracNodeAssetAcknowledgeInputBody & {
  proximity_enabled: boolean | undefined;
  wifi_enabled: boolean | null;
  asset_machine_id: number | null;
  assign_to_machine: boolean | null;
  is_proximity_enabled: boolean;
};

// eslint-disable-next-line complexity
const CommtracNodeItemAssetAcknowledge = ({
  item,
  onCancel,
  onSubmitted,
}: Props) => {
  const reduxDispatch = useAppDispatch();

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState<
    AssetMachine | null | undefined
  >(null);
  const company = useAppSelector(({assets}) => assets.company);

  const assets = useAppSelector(reduxSelectors.assets.getAssets);
  const assetMachines = useMemo(
    () => assets.asset_machines,
    [assets.asset_machines]
  );

  const submitData = async (data: AcknowledgeInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/commtrac-node/${item.id}/asset/acknowledge`;
      const resp = await API.patch<CommtracNode>(endpoint, {
        ...data,
        wifi_enabled: undefined,
        min_voltage: isMinVoltageVisible ? data.min_voltage : undefined,
        max_voltage: isMaxVoltageVisible ? data.max_voltage : undefined,
      });
      const message = `Asset has been acknowledged`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAssetMachines);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const zones = useAppSelector(({assets}) => assets.zones);
  const machineTypes = useAppSelector(({assets}) => assets.machine_types);
  const getFormikValues = (item: CommtracNode): AcknowledgeInputBody => ({
    proximity_enabled: company?.proximity_enabled,
    assign_to_machine: null,
    asset_machine_id: null,
    wifi_enabled: !!item.wifi_enabled,
    is_proximity_enabled: false,
    name: item?.name ?? null,
    type_id: item?.m_type_id
      ? machineTypes.find((i) => i.id === item.m_type_id)?.id ?? null
      : null,
    zone_id: item?.commtrac_current_zone_id
      ? zones.find((i) => i.id === item.commtrac_current_zone_id)?.id ?? null
      : null,
    status: item?.status ?? null,
    external_id: item?.external_id ?? null,
    events_flag: item?.m_events_flag ?? null,
    min_voltage: item?.min_voltage ?? null,
    max_voltage: item?.max_voltage ?? null,
    input_1_type: item?.m_input_1_type ?? null,
    input_2_type: item?.m_input_2_type ?? null,
    input_3_type: item?.m_input_3_type ?? null,
    input_4_type: item?.m_input_4_type ?? null,
    input_5_type: item?.m_input_5_type ?? null,
  });

  const formik = useFormik<AcknowledgeInputBody>({
    initialValues: getFormikValues(item),
    validationSchema: commtracNodeAssetAcknowledgeInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  const displayNewMachineFields = useMemo(
    () =>
      formik.values.assign_to_machine !== null &&
      !formik.values.assign_to_machine,
    [formik.values]
  );

  const displayAssignMachineFields = useMemo(
    () =>
      formik.values.assign_to_machine !== null &&
      formik.values.assign_to_machine,
    [formik.values]
  );

  const displayProximityFields = getIsProximityFieldsVisible(
    formik.values.assign_to_machine,
    formik.values.is_proximity_enabled,
    company?.proximity_enabled
  );

  const displayProximityIdField = useMemo(
    () =>
      company?.proximity_enabled &&
      ((formik.values.assign_to_machine &&
        selectedAsset &&
        !selectedAsset?.external_id) ||
        (!formik.values.assign_to_machine &&
          formik.values.is_proximity_enabled)),
    [formik.values, selectedAsset]
  );

  const machineInputs = useAppSelector(({assets}) => assets.machine_inputs);

  const isMinVoltageVisible = getIsMinVoltageVisible(
    true,
    formik.values.wifi_enabled
  );

  const isMaxVoltageVisible = getIsMaxVoltageVisible(
    true,
    formik.values.wifi_enabled
  );

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        {formik.values.wifi_enabled ? (
          <TextField
            value={item.mac_address}
            label="Mac Address"
            size="small"
            name="name"
            fullWidth
            disabled
          />
        ) : (
          <TextField
            value={item.commtrac_external_id}
            label="Network ID"
            size="small"
            name="name"
            fullWidth
            disabled
          />
        )}

        <FormControl>
          <FormLabel id="demo-form-control-label-placement">
            Assign this Network ID to an existing machine?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              label="Yes"
              control={
                <Radio
                  checked={
                    formik.values.assign_to_machine !== null &&
                    formik.values.assign_to_machine
                  }
                  onChange={() => {
                    formik.setFieldValue('assign_to_machine', true);
                  }}
                  value="true"
                  name="radio-buttons"
                  inputProps={{'aria-label': 'Yes'}}
                />
              }
            />
            <FormControlLabel
              label="No"
              control={
                <Radio
                  checked={
                    formik.values.assign_to_machine !== null &&
                    !formik.values.assign_to_machine
                  }
                  onChange={() => {
                    formik.setFieldValue('assign_to_machine', false);
                  }}
                  value="true"
                  name="radio-buttons"
                  inputProps={{'aria-label': 'No'}}
                />
              }
            />
          </RadioGroup>
          <FormHelperText error>
            {formik.errors.assign_to_machine ? 'Field is required' : ''}
          </FormHelperText>
        </FormControl>

        {displayAssignMachineFields ? (
          <AssetMachineSelect
            value={formik.values.asset_machine_id}
            size="small"
            label="Select a Machine"
            fullWidth
            nullLabel=""
            assetMachines={assetMachines}
            error={
              !!formik.touched.asset_machine_id &&
              !!formik.errors.asset_machine_id
            }
            onChange={(_, item) => {
              formik.setFieldValue('asset_machine_id', item?.id ?? null);
              setSelectedAsset(item);
            }}
          />
        ) : null}

        {displayNewMachineFields ? (
          <TextField
            value={formik.values.name}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={(e) =>
              formik.setFieldValue(e.target.name, e.target.value || null)
            }
          />
        ) : null}

        {displayNewMachineFields ? (
          <MachineTypeSelect
            value={formik.values.type_id}
            label="Machine Type"
            size="small"
            fullWidth
            error={!!formik.touched.type_id && !!formik.errors.type_id}
            helperText={formik.touched.type_id && formik.errors.type_id}
            onChange={(v) => formik.setFieldValue('type_id', v)}
          />
        ) : null}

        {displayNewMachineFields ? (
          <ZoneSelect
            value={formik.values.zone_id}
            label="Assigned Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />
        ) : null}

        {displayNewMachineFields ? (
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />
        ) : null}

        {formik.values.assign_to_machine !== null && isMinVoltageVisible ? (
          <NumberTextField
            value={
              formik.values.min_voltage
                ? formik.values.min_voltage / 10
                : formik.values.min_voltage
            }
            decimalPlaces={1}
            min={0}
            max={10}
            step={0.1}
            label="Min Voltage"
            size="small"
            name="min_voltage"
            fullWidth
            error={!!formik.touched.min_voltage && !!formik.errors.min_voltage}
            helperText={formik.touched.min_voltage && formik.errors.min_voltage}
            onChange={(v) =>
              formik.setFieldValue('min_voltage', v ? v * 10 : v)
            }
          />
        ) : null}

        {formik.values.assign_to_machine !== null && isMaxVoltageVisible ? (
          <NumberTextField
            value={
              formik.values.max_voltage
                ? formik.values.max_voltage / 10
                : formik.values.max_voltage
            }
            decimalPlaces={1}
            min={0}
            max={10}
            step={0.1}
            label="Max Voltage"
            size="small"
            name="max_voltage"
            fullWidth
            error={!!formik.touched.max_voltage && !!formik.errors.max_voltage}
            helperText={formik.touched.max_voltage && formik.errors.max_voltage}
            onChange={(v) =>
              formik.setFieldValue('max_voltage', v ? v * 10 : v)
            }
          />
        ) : null}

        {displayNewMachineFields && company?.proximity_enabled ? (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">
              Is this a Proximity enabled Asset?
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={formik.values.is_proximity_enabled}
                    onChange={() => {
                      formik.setFieldValue('is_proximity_enabled', true);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.is_proximity_enabled}
                    onChange={() => {
                      formik.setFieldValue('is_proximity_enabled', false);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        {displayProximityIdField ? (
          <NumberTextField
            value={formik.values.external_id}
            min={1}
            max={maxProximityId}
            label="Proximity ID"
            size="small"
            name="external_id"
            fullWidth
            error={!!formik.touched.external_id && !!formik.errors.external_id}
            helperText={formik.touched.external_id && formik.errors.external_id}
            onChange={(v) => formik.setFieldValue('external_id', v)}
          />
        ) : null}

        {displayProximityFields ? (
          <TextField
            value={formik.values.events_flag}
            label="Monitor Events"
            size="small"
            name="events_flag"
            select
            fullWidth
            error={!!formik.touched.events_flag && !!formik.errors.events_flag}
            helperText={formik.touched.events_flag && formik.errors.events_flag}
            onChange={formik.handleChange}
          >
            {[
              {value: 'YES', name: 'Yes'},
              {value: 'NO', name: 'No'},
            ].map((i) => (
              <MenuItem key={i.name} value={i.value}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {displayProximityFields ? (
          <TextField
            value={formik.values.input_1_type ?? ''}
            fullWidth
            name="input_1_type"
            label="Machine Input 1"
            size="small"
            select
            error={
              !!formik.touched.input_1_type && !!formik.errors.input_1_type
            }
            helperText={
              formik.touched.input_1_type && formik.errors.input_1_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {displayProximityFields ? (
          <TextField
            value={formik.values.input_2_type ?? ''}
            fullWidth
            name="input_2_type"
            label="Machine Input 2"
            size="small"
            select
            error={
              !!formik.touched.input_2_type && !!formik.errors.input_2_type
            }
            helperText={
              formik.touched.input_2_type && formik.errors.input_2_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {displayProximityFields ? (
          <TextField
            value={formik.values.input_3_type ?? ''}
            fullWidth
            name="input_3_type"
            label="Machine Input 3"
            size="small"
            select
            error={
              !!formik.touched.input_3_type && !!formik.errors.input_3_type
            }
            helperText={
              formik.touched.input_3_type && formik.errors.input_3_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {displayProximityFields ? (
          <TextField
            value={formik.values.input_4_type ?? ''}
            fullWidth
            name="input_4_type"
            label="Machine Input 4"
            size="small"
            select
            error={
              !!formik.touched.input_4_type && !!formik.errors.input_4_type
            }
            helperText={
              formik.touched.input_4_type && formik.errors.input_4_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {displayProximityFields ? (
          <TextField
            value={formik.values.input_5_type ?? ''}
            fullWidth
            name="input_5_type"
            label="Machine Input 5"
            size="small"
            select
            error={
              !!formik.touched.input_5_type && !!formik.errors.input_5_type
            }
            helperText={
              formik.touched.input_5_type && formik.errors.input_5_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </Box>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Acknowledge
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CommtracNodeItemAssetAcknowledge;
