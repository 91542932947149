import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Paper,
} from '@mui/material';
import {useFormik} from 'formik';
import {
  forwardRef,
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  MachineInput,
  MachineInputListQuery,
  MachineInputListResponse,
} from '../../interfaces/MachineInput';
import reduxSelectors from '../../redux/selectors';
import AccessControl from '../common/AccessControl';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
import MachineInputItemDeleteButton from './MachineInputItemDeleteButtont';
import MachineInputItemUpsertButton from './MachineInputItemUpsertButton';

interface Props {}

export interface MachineInputListRef {
  fetch?: () => void;
  dataGridRef: MutableRefObject<DataGridRef | null>;
}

const MachineInputList = forwardRef<MachineInputListRef, Props>((__, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
    dataGridRef,
  }));

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);
  const paperBg = isDarkMode ? '#222222' : '#FFF';

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<MachineInputListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: MachineInputListQuery) => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<MachineInputListResponse>(
        `${apiBaseUrl}/machine-input`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<MachineInput>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'name',
      sortable: true,
    },
    {
      field: 'status',
      sortable: true,
      renderCell: ({row}) => {
        return row.status === 'active' ? (
          <ToggleOnIcon color="success" />
        ) : (
          <ToggleOffIcon color="error" />
        );
      },
    },
    {
      field: 'allow_filter',
      sortable: true,
      renderCell: ({row}) => {
        return row.allow_filter ? (
          <CheckIcon color="success" />
        ) : (
          <CloseIcon color="error" />
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      sxHeader: {textAlign: 'right'},
      sxCell: {textAlign: 'right'},
      renderCell: ({row}) => {
        return (
          <Box display="flex" gap={1} justifyContent="end">
            <AccessControl permissions={['patch::/shift/:id']}>
              <MachineInputItemUpsertButton
                pk={row.id}
                item={row}
                prefetch
                component={IconButton}
                componentProps={{color: 'primary', size: 'small'}}
                onSubmitted={() => fetchData(formik.values)}
              >
                <EditIcon />
              </MachineInputItemUpsertButton>
            </AccessControl>

            <AccessControl permissions={['delete::/shift/:id']}>
              <MachineInputItemDeleteButton
                item={row}
                component={IconButton}
                componentProps={{color: 'error', size: 'small'}}
                onDeleted={() => fetchData(formik.values)}
              >
                <DeleteOutlineOutlinedIcon />
              </MachineInputItemDeleteButton>
            </AccessControl>
          </Box>
        );
      },
    },
  ];

  const formik = useFormik<MachineInputListQuery>({
    initialValues: {
      page: 0,
      limit: 25,
      order: 'id',
      dir: 'DESC',
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  return (
    <Paper
      sx={{
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        p: 3,
        bgcolor: paperBg,
        backgroundImage: 'none',
      }}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}
      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        pagination
        paginationMode="server"
        sx={{
          backgroundColor: 'transparent',
          th: {
            bgcolor: paperBg,
          },
        }}
        size="small"
        sortBy={{
          field: formik.values.order,
          dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
        }}
        sortingMode="server"
        page={formik.values.page}
        pageSize={formik.values.limit}
        rowCount={fetchedData?.count}
        onPageChange={(v) => formik.setFieldValue('page', v)}
        onPageSizeChange={(v) => {
          formik.setFieldValue('limit', v);
          formik.setFieldValue('page', 0);
        }}
        onSort={(v) => {
          if (v) {
            formik.setFieldValue('order', v.field);
            formik.setFieldValue('dir', v.dir.toUpperCase());
          }
        }}
      />
    </Paper>
  );
});

export default MachineInputList;
