import {AssetHuman} from '../../interfaces/AssetHuman';
import AssetHumanItem from './AssetHumanItem';
import AssetHumanItemCreate from './AssetHumanItemCreate';

interface Props {
  pk?: number;
  item?: AssetHuman;
  mode?: 'view' | 'update' | 'update_from_info' | 'chat';
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (item: AssetHuman) => void;
}

const AssetHumanItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <AssetHumanItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      onClose={onClose}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
      onOpenHistory={onOpenHistory}
    />
  ) : (
    <AssetHumanItemCreate onCancel={onClose} onSubmitted={onSubmitted} />
  );
};

export default AssetHumanItemUpsert;
