import {MenuItem, TextField, TextFieldProps} from '@mui/material';
import last from 'lodash/last';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
> & {
  value?: number[];
  options: {
    code: number;
    name: string;
  }[];
  type?: string;
  onChange?: (value: number[]) => void;
};

const MessageTypeSelect = ({
  value,
  options,
  type,
  onChange,
  ...props
}: Props) => {
  const handleChange: TextFieldProps['onChange'] = (event: any) => {
    const isSelectAll = last(event.target.value) === 'all';
    const newValue = isSelectAll
      ? []
      : event.target.value.filter((i: any) => i !== 'all');
    onChange?.(newValue);
  };

  return (
    <TextField
      fullWidth
      label={type === 'message_type' ? 'Message Types' : 'Event Types'}
      {...props}
      select
      SelectProps={{
        multiple: true,
      }}
      value={!value?.length ? ['all'] : value}
      onChange={handleChange}
    >
      <MenuItem value="all">
        {type === 'message_type' ? 'All Messages' : 'All Events'}
      </MenuItem>

      {options.map((option) => (
        <MenuItem key={option.code ?? ''} value={option.code ?? ''}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default MessageTypeSelect;
