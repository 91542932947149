import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {COLORED_ICONS} from '../../utils/icons';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import {ColorSelect} from '../selectors/ColorSelect';
import {SoundSelect} from '../selectors/SoundSelect';

interface ConfigurationVentilationValues {
  [key: string]: string | number | null;
}

const FIELDS_BY_SECTIONS = {
  fanSpeedLevels: [
    'ventilation.ventilation_speed_c1',
    'ventilation.ventilation_speed_c2',
    'ventilation.ventilation_speed_c3',
    'ventilation.ventilation_speed_c4',
    'ventilation.ventilation_speed_c5',
  ],

  powerManagment: ['ventilation.ventilation_maximum_allowed_power'],

  noEnvMessages: ['ventilation.ventilation_no_env_messages_period'],

  soundGlobal: [
    'ventilation-sound.ventilation_sound_global_new',
    'ventilation-sound.ventilation_sound_interval',
  ],

  sound: [
    'ventilation-sound.ventilation_sound_801',
    'ventilation-sound.ventilation_sound_802',
    'ventilation-sound.ventilation_sound_803',
    'ventilation-sound.ventilation_sound_804',
    'ventilation-sound.ventilation_sound_805',
    'ventilation-sound.ventilation_sound_806',
    'ventilation-sound.ventilation_sound_807',
    'ventilation-sound.ventilation_sound_808',
  ],

  color: [
    'ventilation-color.ventilation_color_801',
    'ventilation-color.ventilation_color_802',
    'ventilation-color.ventilation_color_803',
    'ventilation-color.ventilation_color_804',
    'ventilation-color.ventilation_color_805',
    'ventilation-color.ventilation_color_806',
    'ventilation-color.ventilation_color_807',
    'ventilation-color.ventilation_color_808',
  ],
};

const FIELDS = _.flatMap(FIELDS_BY_SECTIONS);

const getFormikValues = (
  item?: ConfigurationResponse
): ConfigurationVentilationValues => ({
  ..._.chain(FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse
): ConfigurationVentilationValues => ({
  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

export interface AdminVentilationConfigProps {
  disabled?: boolean;
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface AdminVentilationConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const AdminVentilationConfig = forwardRef<
  AdminVentilationConfigRef,
  React.PropsWithChildren<AdminVentilationConfigProps>
>(({disabled, onChangeSubmittedInProgress, onChangeResetInProgress}, ref) => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => formik.handleSubmit(),
    reset: () => handleResetData(),
    clear: () => formik.setValues(getFormikValuesClear(fetchedData)),
  }));

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<ConfigurationResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>();
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const params = {
        section: JSON.stringify([
          'ventilation',
          'ventilation-sound',
          'ventilation-color',
        ]),
      };
      const resp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        {params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      setFetchedErrors(error.data);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();

    return () => {
      setFetchedData(undefined);
      setFetchedErrors(undefined);
    };
  }, []);

  /**********/
  /* submit */
  /**********/
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);

    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully updated', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  /**********/
  /* reset */
  /**********/
  const [resetInProgress, setResetInProgress] = useState(false);

  const resetData = async (data: ConfigurationInput) => {
    setResetInProgress(true);

    try {
      const resp = await API.patch<ConfigurationResponse>(
        `${apiBaseUrl}/configuration`,
        data
      );
      setFetchedData(resp.data);
      reduxDispatch(reduxActions.app.fetchMyConfigurations);
      enqueueSnackbar('Configuration successfully reset', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setResetInProgress(false);
  };

  const handleResetData = async () => {
    const input: ConfigurationInput = {
      section: ['ventilation', 'ventilation-sound', 'ventilation-color'],
      configuration: _.map(formik.values, (value, key) => ({
        section: key.split('.')[0],
        name: key.split('.')[1],
        value,
      })),
    };
    await resetData(input);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object();

  const formik = useFormik({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      const input: ConfigurationInput = {
        section: ['ventilation', 'ventilation-sound', 'ventilation-color'],
        configuration: _.map(values, (value, key) => ({
          section: key.split('.')[0],
          name: key.split('.')[1],
          value,
        })),
      };
      await submitData(input);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  /************/
  /* keysData */
  /************/
  const keysData = useMemo(
    () => ({
      ..._.chain(fetchedData)
        .keyBy((i) => `${i.section}.${i.name}`)
        .mapValues()
        .value(),
    }),
    [fetchedData]
  );

  // other
  useEffect(() => {
    onChangeSubmittedInProgress?.(submittedInProgress);
  }, [submittedInProgress]);

  useEffect(() => {
    onChangeResetInProgress?.(resetInProgress);
  }, [resetInProgress]);

  return (
    <Box position="relative">
      <Backdrop open={fetchedInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          Fan Speed Levels
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.fanSpeedLevels.map((name) => (
            <Grid key={name} item xs={12} lg={6}>
              <TextField
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? ''}
                name={name}
                type="number"
                size="small"
                disabled={disabled}
                onChange={(event) => {
                  const value =
                    event.target.value === '' ? null : event.target.value;
                  formik.setValues({...formik.values, [name]: value});
                }}
              >
                {[null, 10, 15, 30, 60, 120].map((i) => (
                  <MenuItem key={i ?? ''} value={i ?? ''}>
                    {i ?? 'None'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          Power Managment
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.powerManagment.map((name) => (
            <Grid key={name} item xs={12} lg={6}>
              <TextField
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? ''}
                name={name}
                type="number"
                size="small"
                disabled={disabled}
                onChange={(event) => {
                  const value =
                    event.target.value === '' ? null : event.target.value;
                  formik.setValues({...formik.values, [name]: value});
                }}
              >
                {[null, 10, 15, 30, 60, 120].map((i) => (
                  <MenuItem key={i ?? ''} value={i ?? ''}>
                    {i ?? 'None'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          No Env Messages
        </Box>

        <Grid container spacing={4}>
          {FIELDS_BY_SECTIONS.noEnvMessages.map((name) => (
            <Grid key={name} item xs={12} lg={6}>
              <TextField
                fullWidth
                label={t(keysData[name]?.label)}
                value={formik.values[name] ?? ''}
                name={name}
                type="number"
                size="small"
                disabled={disabled}
                onChange={(event) => {
                  const value =
                    event.target.value === '' ? null : event.target.value;
                  formik.setValues({...formik.values, [name]: value});
                }}
              >
                {[null, 10, 15, 30, 60, 120].map((i) => (
                  <MenuItem key={i ?? ''} value={i ?? ''}>
                    {i ?? 'None'}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          ))}
        </Grid>
      </Paper>

      <Grid container spacing={4} mb={3}>
        <Grid item xs={12} lg={8}>
          <Paper sx={{p: 3, mb: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={2}>
              Sound
            </Box>

            <Box fontSize={14} lineHeight={1.6} mb={2}>
              Global
            </Box>

            <Grid container spacing={4} mb={6}>
              <Grid item xs={12} lg={6}>
                {FIELDS_BY_SECTIONS.soundGlobal.slice(0, 1).map((name) => (
                  <SoundSelect
                    key={name}
                    fullWidth
                    label={t(keysData[name]?.label)}
                    value={formik.values[name] ?? ''}
                    name={name}
                    size="small"
                    disabled={disabled}
                    onChange={(event) =>
                      formik.setValues({
                        ...formik.values,
                        [FIELDS_BY_SECTIONS.soundGlobal[0]]: event.target.value,
                      })
                    }
                  />
                ))}
              </Grid>

              <Grid item xs={12} lg={6}>
                {FIELDS_BY_SECTIONS.soundGlobal.slice(1, 2).map((name) => (
                  <TextField
                    key={name}
                    fullWidth
                    label={t(keysData[name]?.label)}
                    value={formik.values[name] ?? 'None'}
                    name={name}
                    type="number"
                    select
                    size="small"
                    disabled={disabled}
                    onChange={(event) => {
                      const value =
                        event.target.value === 'None'
                          ? null
                          : event.target.value;
                      formik.setValues({...formik.values, [name]: value});
                    }}
                  >
                    {['None', 10, 15, 30, 60, 120].map((i) => (
                      <MenuItem key={i ?? ''} value={i ?? ''}>
                        {i ?? 'None'}
                      </MenuItem>
                    ))}
                  </TextField>
                ))}
              </Grid>
            </Grid>

            <Grid container spacing={4} mb={3}>
              {FIELDS_BY_SECTIONS.sound.map((name) => (
                <Grid key={name} item xs={12} lg={4}>
                  <SoundSelect
                    fullWidth
                    label={t(keysData[name]?.label)}
                    value={formik.values[name] ?? ''}
                    name={name}
                    size="small"
                    disabled={disabled}
                    onChange={(event) =>
                      formik.setValues({
                        ...formik.values,
                        [name]: event.target.value,
                      })
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={4}>
          <Paper sx={{p: 3, mb: 3}}>
            <Box fontSize={20} lineHeight={1.6} mb={2}>
              Color
            </Box>

            <Grid container spacing={4} mb={3}>
              {FIELDS_BY_SECTIONS.color.map((name) => (
                <Grid key={name} item xs={12} lg={6}>
                  <ColorSelect
                    fullWidth
                    label={t(keysData[name]?.label)}
                    size="small"
                    value={formik.values[name] ?? ''}
                    name={name}
                    showHealthy
                    disabled={disabled}
                    icons={COLORED_ICONS.fans}
                    onChange={(event) =>
                      formik.setValues({
                        ...formik.values,
                        [name]: event.target.value,
                      })
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});
