import ColorLensIcon from '@mui/icons-material/ColorLens';
import {MenuItem, TextField, TextFieldProps} from '@mui/material';
import {Box} from '@mui/system';
import {useSelector} from 'react-redux';

import reduxSelectors from '../../redux/selectors';
import TruncatedText from '../common/TruncantedText';

type Props = TextFieldProps & {
  showHealthy?: boolean;
  icons?: {
    [k: string]: any;
  };
};

export const ColorSelect = ({showHealthy, icons, ...props}: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const options = [
    ...(showHealthy ? [{code: 'healthy', name: 'None'}] : []),
    ...assets.color,
  ];

  return (
    <TextField label="Color" {...props} value={props.value ?? ''} select>
      {options.map((option) => (
        <MenuItem key={option.code ?? ''} value={option.code ?? ''}>
          <Box display="flex" alignItems="center" gap={1}>
            {icons ? (
              <Box component="img" src={icons[option.code] ?? ''} height={24} />
            ) : (
              <ColorLensIcon sx={{color: option.code}} />
            )}
            <TruncatedText>{option.name}</TruncatedText>
          </Box>
        </MenuItem>
      ))}
    </TextField>
  );
};
