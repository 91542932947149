import {MenuItem, TextField, TextFieldProps} from '@mui/material';
import {useMemo} from 'react';

import {HazardPanelListQuery} from '../../interfaces/CommtracNode';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: HazardPanelListQuery['warnings'];
  nullLabel?: string;
  onChange?: (value?: HazardPanelListQuery['warnings']) => void;
};

export const SafeyeNodeStatusSelect = ({
  value,
  disabled,
  fullWidth,
  size,
  onChange,
  ...props
}: Props) => {
  // Constants
  const statusOptions: {
    value: HazardPanelListQuery['warnings'];
    label: string;
  }[] = useMemo(
    () => [
      {value: 'all', label: 'All'},
      {value: 'active', label: 'Active'},
      {value: 'inactive', label: 'Inactive'},
      {value: 'unacknowledged', label: 'Unacknowledged'},
    ],
    []
  );

  return disabled ? (
    <TextField
      value={value || ''}
      name="Status"
      label="Status"
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
      {...props}
    />
  ) : (
    <TextField
      value={value ?? 'all'}
      name="Status"
      label="Status"
      select
      sx={{width: 130}}
      size="small"
      onChange={(e) => {
        const value = e?.target?.value as HazardPanelListQuery['warnings'];

        onChange?.(value);
      }}
    >
      {statusOptions.map(({value, label}) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </TextField>
  );
};
