export const signalBytesToDbm = (rssiByteValue: number | null) => {
  const minDbm = -100;
  const maxDbm = -30;
  const rssiByteRange = 24;
  const rssiSpan = maxDbm - minDbm;
  if (rssiByteValue) {
    return `${Math.floor((rssiByteValue / rssiByteRange) * rssiSpan + minDbm)}`;
  } else {
    return '';
  }
};
