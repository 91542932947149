import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {saveFile} from '../../utils/file';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import ExportFieldForm from '../common/ExportFieldForm';
import ModalFixed from '../common/ModalFixed';
import SnackbarMessages from '../common/SnackbarMessages';
import {
  CommtracNodesReportData,
  CommtracNodesReportDataExportFields,
} from '../dashboard-panels/ConnectView/CommtracNodesReport';

interface Props {
  value?: CommtracNodesReportData | CommtracNodesReportDataExportFields;
}

const AssetHumanExportExcel = ({value}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);
    try {
      const params = {
        ...value?.params,
        export: 'excel',
        node_type: ['miner'],
        fields,
      };
      const resp = await API.get(`${apiBaseUrl}/commtrac-node`, {
        params,
        responseType: 'blob',
      });
      saveFile(resp.data, `employees.xlsx`);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => checkFields()}
        >
          Export to Excel
        </LoadingButton>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={value?.exportFields}
                loading={isFetching}
                onChange={fetchData}
                close={() => setIsOpened(false)}
              />
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default AssetHumanExportExcel;
