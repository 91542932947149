import {Box, Checkbox, FormHelperText, Paper} from '@mui/material';
import {useEffect, useMemo, useRef, useState} from 'react';

import {useAppSelector} from '../../hooks/redux';
import {AlarmGroup} from '../../interfaces/AlarmLogNode';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';
interface Props {
  formik?: any;
  onSelectItems?: (ids: number[]) => void;
}

const AlarmUpdateStatusGroupList = ({onSelectItems, formik}: Props) => {
  const dataGridRef = useRef<DataGridRef>(null);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);
  const alarm_groups = useAppSelector(({assets}) => assets.alarm_groups);
  const rows = alarm_groups?.filter((it) => it.status === 'active');

  useEffect(() => {
    if (onSelectItems) {
      onSelectItems(selectedItems);
    }
  }, [selectedItems]);

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems.includes(i.id)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (selectedItems?.includes(id)) {
      setSelectedItems(selectedItems.filter((i) => i !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const selectAll = () => {
    setSelectedItems(rows?.map((i) => i.id));
  };
  const unselectAll = () => {
    setSelectedItems([]);
  };
  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };
  const columns: DataGridColumn<AlarmGroup>[] = [
    {
      field: 'select',
      type: 'select',
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'Id',
    },
    {
      field: 'name',
      headerName: 'Group Name',
      renderCell: ({row}) => row.name,
    },
    {
      field: 'description',
      headerName: 'Description',
    },
    {
      field: 'count',
      headerName: 'Alarms Count',
      renderCell: ({row}) => {
        return row.alarm_modules?.filter((it) => it.status === 'active')
          ?.length;
      },
    },
  ];

  return (
    <Paper sx={{p: 2, px: 3, mb: 2}}>
      <Box fontSize={16} mb={2}>
        Select the groups you want apply:
      </Box>
      <FormHelperText error={!!formik.touched.groups && !!formik.errors.groups}>
        {formik.touched.groups && formik.errors.groups}
      </FormHelperText>
      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        size="small"
        pagination
        sx={{
          td: {
            padding: '16px',
          },
        }}
      />
    </Paper>
  );
};

export default AlarmUpdateStatusGroupList;
