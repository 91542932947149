const degreesToRadians = (degrees: number): number => {
  return (degrees * Math.PI) / 180;
};

// Function to calculate the Haversine distance between two points
const haversineDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371e3; // Earth radius in meters
  const φ1 = degreesToRadians(lat1);
  const φ2 = degreesToRadians(lat2);
  const Δφ = degreesToRadians(lat2 - lat1);
  const Δλ = degreesToRadians(lon2 - lon1);

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c;
};

// Function to calculate the minimum bounding circle radius
export const calculateMinimumBoundingCircleRadius = (
  coordinates: [number, number][]
): number => {
  // Find the centroid (center of mass) of the coordinates
  const centroid = coordinates
    .reduce(
      (acc, coord) => {
        acc[0] += coord[0];
        acc[1] += coord[1];
        return acc;
      },
      [0, 0]
    )
    .map((val) => val / coordinates.length);

  // Find the point farthest away from the centroid
  let maxDistance = 0;
  for (const coord of coordinates) {
    const distance = haversineDistance(
      centroid[0],
      centroid[1],
      coord[0],
      coord[1]
    );
    if (distance > maxDistance) {
      maxDistance = distance;
    }
  }

  return maxDistance;
};

export const getDistanceFromLatLngInMeters = (
  lat1: number,
  lng1: number,
  lat2: number,
  lng2: number
) => {
  const earthRadius = 6371000; // Radius of the earth in meters
  const radLat1 = (Math.PI * lat1) / 180;
  const radLat2 = (Math.PI * lat2) / 180;
  const deltaLat = (Math.PI * (lat2 - lat1)) / 180;
  const deltaLng = (Math.PI * (lng2 - lng1)) / 180;

  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(radLat1) *
      Math.cos(radLat2) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
};

export const pixelsToMeters = (
  radiusInPixels: number,
  latitude: number,
  zoom: number
): number => {
  // Calculate the length of one meter in pixels at the given latitude and zoom level
  const metersPerPixel =
    (156543.03392 * Math.cos((latitude * Math.PI) / 180)) / Math.pow(2, zoom);

  // Convert radius from pixels to meters
  const radiusInMeters = radiusInPixels * metersPerPixel;

  return radiusInMeters;
};
