import React, {useMemo, useRef} from 'react';
import {Outlet} from 'react-router-dom';

import {EditIndicatorPanelDialog} from '../components/modals/EditIndicatorPanelDialog';
import * as Mock from '../data';
import {IndicatorPanel, WidgetLibrary} from '../types';

type IndicatorModals = {
  indicatorPanel: boolean;
  createWidget: boolean;
  widgetLibrary: boolean;
};

type IndicatorPanelContextType = {
  editMode: boolean;
  modals: IndicatorModals;
  library?: WidgetLibrary;
  panels?: IndicatorPanel[];
  selectedPanel?: IndicatorPanel;
  panelDraftRef?: React.MutableRefObject<IndicatorPanel | null>;
  updateModalValues: (payload: Partial<IndicatorModals>) => void;
  updateContextValue: (
    payload: Partial<Omit<IndicatorPanelContextType, 'updateContextValue'>>
  ) => void;
  createPanel: (name: string) => void;
  savePanel: () => void;
  cancelEditPanel: () => void;
};

const defaultIndicatorPanelContextValue: IndicatorPanelContextType = {
  editMode: false,
  modals: {
    indicatorPanel: false,
    createWidget: false,
    widgetLibrary: false,
  },
  panels: Mock.sampleIndicatorPanels,
  selectedPanel: Mock.sampleIndicatorPanels[0],
  library: {items: Mock.samplePanels},
  // panelDraftRef: null,
  updateModalValues: () => {},
  updateContextValue: () => {},
  createPanel: () => {},
  savePanel: () => {},
  cancelEditPanel: () => {},
};

export const IndicatorPanelContext = React.createContext(
  defaultIndicatorPanelContextValue
);

export const IndicatorPanelContextProvider = () => {
  const [contextValue, setContextValue] =
    React.useState<IndicatorPanelContextType>(
      defaultIndicatorPanelContextValue
    );
  const panelDraftRef = useRef<IndicatorPanel | null>(null);

  const updateContextValue = (
    payload: Partial<
      Omit<IndicatorPanelContextType, 'updateContextValue' | 'projectActions'>
    >
  ) => setContextValue((prev) => ({...prev, ...payload}));

  const updateModalValues = (payload: Partial<IndicatorModals>) =>
    setContextValue((prev) => ({
      ...prev,
      modals: {...prev.modals, ...payload},
    }));

  const createPanel = (name: string) => {
    const newPanel: IndicatorPanel = {
      id: contextValue.panels?.length! || 0,
      name,
      widgets: [],
    };
    setContextValue((prev) => ({
      ...prev,
      panels: [...prev.panels!, newPanel],
      selectedPanel: newPanel,
    }));
  };

  const savePanel = () => {
    setContextValue((prev) => ({
      ...prev,
      panels: prev?.panels?.map((item) =>
        item.id === panelDraftRef?.current?.id
          ? panelDraftRef?.current || item
          : item
      ),
      selectedPanel: panelDraftRef?.current || prev.selectedPanel,
      editMode: false,
    }));
  };

  const cancelEditPanel = () => {
    if (panelDraftRef) {
      panelDraftRef.current = contextValue.selectedPanel!;
    }
    setContextValue((prev) => ({...prev, editMode: false}));
  };

  const value = useMemo(
    () => ({
      ...contextValue,
      panelDraftRef,
      updateContextValue,
      updateModalValues,
      createPanel,
      savePanel,
      cancelEditPanel,
    }),
    [contextValue]
  );

  return (
    <IndicatorPanelContext.Provider value={value}>
      <Outlet />
      <EditIndicatorPanelDialog
        open={!!value?.modals?.indicatorPanel}
        onClose={() => updateModalValues({indicatorPanel: false})}
      />
    </IndicatorPanelContext.Provider>
  );
};

export const useIndicatorPanel = () => {
  return React.useContext(IndicatorPanelContext);
};
