import {Box} from '@mui/material';
import {t} from 'i18next';
import {useRef} from 'react';

import {usePrint} from '../../../hooks/print';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {MachineInteractionReportData} from './data';
import MachineInteractionReportBar from './MachineInteractionReportBar';
import MachineInteractionReportGantt from './MachineInteractionReportGantt';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export const MachineInteractionReport = (props: Props) => {
  const [panel] = usePanel();

  /*********/
  /* print */
  /*********/
  const printRef = useRef<HTMLDivElement>();
  const [printPanel] = usePrint(printRef.current);

  return (
    <Box
      ref={printRef}
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="auto"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Box display="flex" height="70%" minHeight={500}>
        <MachineInteractionReportGantt
          value={props.value as MachineInteractionReportData}
          onUpdate={props.onUpdate}
          onPrint={() => printPanel()}
        />
      </Box>

      <Box display="flex" height="30%" overflow="hidden" minHeight={150}>
        <MachineInteractionReportBar
          value={props.value as MachineInteractionReportData}
        />
      </Box>
    </Box>
  );
};
