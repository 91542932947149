import * as yup from 'yup';

import {macAddressValidator} from './utils';

export const getAlarmCreateSchema = (
  isLocalButtonPressActionDisabled: boolean
) => {
  return yup.object().shape({
    name: yup.string().nullable().required('Field is required'),
    mac_address: yup
      .string()
      .nullable()
      .required('Field is required')
      .matches(
        macAddressValidator.regex,
        macAddressValidator.message.replace('%1', 'mac-address')
      ),
    zone_id: yup.number().nullable().required('Field is required'),
    latitude: yup
      .number()
      .nullable()
      .required('Field is required')
      .min(-90)
      .max(90),
    longitude: yup
      .number()
      .nullable()
      .required('Field is required')
      .min(-180)
      .max(180),
    local_button_press_action: !isLocalButtonPressActionDisabled
      ? yup
          .string()
          .nullable()
          .required('Local Button Press Action is required')
      : yup.string().nullable(),
    local_button_group_zone_id: !isLocalButtonPressActionDisabled
      ? yup
          .number()
          .nullable()
          .when('local_button_press_action', {
            is: (action: string) =>
              action === 'activate_warning_group' ||
              action === 'activate_alarm_group',
            then: (schema) => schema.required('Field is required'),
          })
      : yup.number().nullable(),
  });
};

export const alarmModulEnableDisableValidationSchema = yup.object().shape({
  type: yup.string(),
  mode: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: string) => type === 'enable',
      then: (schema) => schema.required('Field is requied'),
    }),
  module: yup.string(),
  zones: yup
    .array()
    .of(yup.number().integer())
    .when('module', {
      is: (module: string) => module === 'section',
      then: (schema) => schema.min(1, 'Sections should be selected.'),
    }),
  groups: yup
    .array()
    .of(yup.number().integer())
    .when('module', {
      is: (module: string) => module === 'group',
      then: (schema) => schema.min(1, 'Groups should be selected.'),
    }),
  alarm_modules: yup
    .array()
    .of(yup.number().integer())
    .when('module', {
      is: (module: string) => module === 'selected' || module === 'all',
      then: (schema) =>
        schema
          .min(1, 'Alarm modules should be selected.')
          .required('Alarm modules should be selected.'),
    }),
  reason: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: string) => type === 'enable',
      then: (schema) => schema.required('Field is requied'),
    }),
  reason_note: yup
    .string()
    .nullable()
    .when('reason', {
      is: (reason: string) => reason === 'other',
      then: (schema) =>
        schema
          .required('Field is requied')
          .max(128, 'Reason note should be not be longer than 128 chars.'),
    }),
});

export const alarmShortCodeSchema = yup.object().shape({
  type: yup.string(),
  reason: yup
    .string()
    .nullable()
    .when('type', {
      is: (type: string) => type === 'enable',
      then: (schema) => schema.required('Field is requied'),
    }),
  reason_note: yup
    .string()
    .nullable()
    .when('reason', {
      is: (reason: string) => reason === 'other',
      then: (schema) =>
        schema
          .required('Field is requied')
          .max(128, 'Reason note should be not be longer than 128 chars.'),
    }),
});
