/* eslint-disable no-bitwise */
import CropSquareIcon from '@mui/icons-material/CropSquare';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import Lightbulb from '@mui/icons-material/Lightbulb';
import NetworkWifi1BarOutlinedIcon from '@mui/icons-material/NetworkWifi1BarOutlined';
import NetworkWifi2BarOutlinedIcon from '@mui/icons-material/NetworkWifi2BarOutlined';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import {Box, Button, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import {useState} from 'react';

import {useConfiguration} from '../../../hooks/configuration';
import {useAppSelector} from '../../../hooks/redux';
import {
  amsSensorAddressMask,
  GasMonitoringNode,
} from '../../../interfaces/GasMonitoringNode';
import {
  getAmsBatteryIconColor,
  getAMSBatteryIconType,
  getAmsBatteryPercent,
} from '../../../utils/ams';
import {AMSBatteryIcon} from '../../common/AMSBatteryIcon';

interface Props {
  item: GasMonitoringNode;
  items?: GasMonitoringNode[];
  onOpenItem?: (id: number, type: 'ams') => void;
  onOpenHistory?: (id: number, type: 'amsShortTerm' | 'amsLongTerm') => void;
}

const AMSSensorTooltipContent = ({
  item,
  items,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const amsNodeLowBattery = useConfiguration('ams', 'ams_node_low_battery');
  const ventilationFailure = useConfiguration('ams', 'ventilation_failure');

  const generate_ICON_AMS_LOW_BATTERY = (item: GasMonitoringNode) => {

    const externalVoltage = item?.external_voltage;
    const batteryVoltage = item?.battery_voltage;
    const isOnExternalCharger = item?.is_on_external_charger;

    if (!externalVoltage && !batteryVoltage) {
      return (
        <></>
      );
    }

    const tooltipContent =
      !isOnExternalCharger || !externalVoltage || externalVoltage < 2
        ? getAmsBatteryPercent(batteryVoltage)
        : `${externalVoltage} v`;
    const iconColor = getAmsBatteryIconColor({
      batteryVoltage,
      externalVoltage,
      isOnExternalCharger,
      amsNodeLowBatteryValue: amsNodeLowBattery?.value,
      isLowBatteryEvent: item?.e_405,
      isLowExternalChargeEvent: item?.e_409,
    });
    const iconType = getAMSBatteryIconType({
      batteryVoltage,
      externalVoltage,
      isOnExternalCharger,
    });
    const iconColorProp: {color?: 'error'} = iconColor
      ? {color: iconColor}
      : {};

    return (
      <Tooltip title={tooltipContent}>
        {iconType === 'power' ? (
          <PowerSettingsNewOutlinedIcon {...iconColorProp} />
        ) : (
          <div>
            <AMSBatteryIcon iconType={iconType} {...iconColorProp} />
          </div>
        )}
      </Tooltip>
    )
  }
  const generate_ICON_Events = (item: GasMonitoringNode) => {
    if (item.disable_event) {
      return (
        <Tooltip title="Events are Disabled">
          <DoDisturbIcon />
        </Tooltip>
      );
    }
  }
  const generate_ICON_Alert_Alarm = (item: GasMonitoringNode) => {
    if (
      (item.e_402 || item.e_452 || item.e_462 || item.e_474 || item.e_484) &&
      item.disable_event === 0
    ) {
      return (
        <Tooltip title="Alarm">
          <WarningIcon color="error" />
        </Tooltip>
      );
    } else if (
      (item.e_403 || item.e_453 || item.e_463 || item.e_475 || item.e_483) &&
      item.disable_event === 0
    ) {
      return (
        <Tooltip title="Alert">
          <WarningIcon color="warning" />
        </Tooltip>
      );
    } else {
      return <></>
    }
  }
  const generate_ICON_Heartbeat = (item: GasMonitoringNode) => {
    if (item.e_407) {
      return (
        <Tooltip title="AMS Sensors many ENV messages">
          <ForumOutlinedIcon />
        </Tooltip>
      );
    } else if (item.e_404) {
      return (
        <Tooltip title="AMS Sensors no ENV messages">
          <NetworkWifi1BarOutlinedIcon color="error" />
        </Tooltip>
      );
    } else if (item.e_406) {
      return (
        <Tooltip title="AMS Sensors few ENV messages">
          <NetworkWifi2BarOutlinedIcon color="warning" />
        </Tooltip>
      );
    }
  }
  const generate_ICON_AVA = (item: GasMonitoringNode) => {
    if (item.ava) {
      const avaStatusAck = item?.ava_status_ack;
      const avaStatus = item?.ava_status;

      const AvaIcon =
        avaStatus === avaStatusAck
          ? FiberManualRecordIcon
          : FiberManualRecordOutlinedIcon;

      if (avaStatus === 402) {
        return (
          <Tooltip title="Alarm">
            <AvaIcon color="error" />
          </Tooltip>
        );
      }

      if (avaStatus === 403) {
        return (
          <Tooltip title="Alert">
            <AvaIcon color="warning" />
          </Tooltip>
        );
      }

      return (
        <Tooltip title="Ok">
          <AvaIcon color="success" />
        </Tooltip>
      );
    }
  }
  const generate_ICON_Ventilation = (item: GasMonitoringNode) => {
    if (
      ventilationFailure?.value &&
      !item?.intrinsically_safe &&
      item?.ventilation_status
    ) {
      return item?.ventilation_status_ack ? (
        <Tooltip title="ACK">
          <SquareRoundedIcon color="error" />
        </Tooltip>
      ) : (
        <Tooltip title="SENT">
          <CropSquareIcon color="error" />
        </Tooltip>
      );
    }
  }

  const generate_ICON_New_Sensor_Detected = (item: GasMonitoringNode) => {
    if ( item.ack === '0' ) {
      return (
        <Tooltip title="New sensor detected">
          <Lightbulb sx={{ color: 'black', fontSize: 25 }}/>
        </Tooltip>
      )
    }
  }

  if ((items?.length ?? 0) > 1) {
    return (
      <>
        {items?.map((it, no) => {
          const {type_id} = it;

          return (
            <Box key={it.id}>
              {no + 1}. SN: {it.sensor_serial_number}, Type:{' '}
              {typeIdLabels ? typeIdLabels[type_id ?? 255] : '-'}
            </Box>
          );
        })}
      </>
    );
  } else if ((items?.length ?? 0) < 2) {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box>Name: {item?.name ?? '-'}</Box>
        <Box>AMS Sensor SN: {item.sensor_serial_number}</Box>
        <Box>
          Type: {typeIdLabels ? typeIdLabels[item?.type_id ?? 255] : '-'}
        </Box>
        <Box>
          Network ID:{' '}
          {item?.commtrac_external_id
            ? item?.commtrac_external_id & amsSensorAddressMask
            : '--'}
        </Box>
        <Box>Calibration Date: {item.callibration_date ?? '-'}</Box>
        <Box display="flex" alignItems="center">
          {(item.e_408 && (
            <Tooltip title="e-Module Calibration Date Expired">
              <TimerOutlinedIcon color="error" />
            </Tooltip>
          ))}
          {(item.e_410 && (
            <Tooltip title="e-Module Calibration Date Expired">
              <TheatersOutlinedIcon />
            </Tooltip>
          ))}
        </Box>
        <Box display="flex" alignItems="center">
          {generate_ICON_New_Sensor_Detected(item)}
          {generate_ICON_AMS_LOW_BATTERY(item)}
          {generate_ICON_Events(item)}
          {generate_ICON_Alert_Alarm(item)}
          {generate_ICON_Heartbeat(item)}
          {generate_ICON_AVA(item)}
          {generate_ICON_Ventilation(item)}
        </Box>
        <Box display="flex" my={1}>
          <Box position="relative">
            <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
              History
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onBackdropClick={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  onOpenHistory?.(item?.id, 'amsShortTerm');
                  setAnchorEl(null);
                }}
              >
                Short Term History Report
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onOpenHistory?.(item?.id, 'amsLongTerm');
                  setAnchorEl(null);
                }}
              >
                Long Term History Report
              </MenuItem>
            </Menu>
          </Box>
          <IconButton
            sx={{
              marginLeft: 'auto',
            }}
            size="small"
            onClick={() => onOpenItem?.(item.id, 'ams')}
          >
            <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
          </IconButton>
        </Box>
      </Box>
    );
  }
  return null;
};

export default AMSSensorTooltipContent;
