import {Box} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import AccessControl from '../../components/common/AccessControl';
import DashboardItemUpsertButton from '../../components/dashboards/DashboardItemUpsertButton';
import DashboardList from '../../components/dashboards/DashboardList';
import {DashboardLayout} from '../../layouts/DashboardLayout';

const DashboardListPage = () => {
  const navigate = useNavigate();

  return (
    <DashboardLayout>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        overflow="hidden"
        height="100%"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
        >
          <Box fontSize={24} display="flex" alignItems="center">
            Dashboards
          </Box>

          <AccessControl permissions={['post::/dashboard']}>
            <DashboardItemUpsertButton
              componentProps={{variant: 'outlined'}}
              onSubmitted={(item) => navigate(`/dashboards/${item.id}`)}
            >
              Add Dashboard
            </DashboardItemUpsertButton>
          </AccessControl>
        </Box>

        <DashboardList />
      </Box>
    </DashboardLayout>
  );
};

export default DashboardListPage;
