import {InputProps, MenuItem, TextField, TextFieldProps} from '@mui/material';
import _ from 'lodash';
import {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import reduxSelectors from '../../redux/selectors';

interface OptionType {
  machine_id: number;
  machine_name: string;
  safeye_node_id: string;
  id: number;
}

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'SelectProps'
  | 'sx'
> & {
  value?: number | number[] | null;
  multiple?: boolean;
  nullLabel?: string;
  allOptionDisabled?: boolean;
  onChange?: (v: number | number[]) => void;
};

export const SafeyeNodeSelector = (props: Props) => {
  // This variable is needed only for multiple mode
  const [selected, setSelected] = useState<number[]>(props.multiple ? props.value as number[] : []);

  const assets = useSelector(reduxSelectors.assets.getAssets);

  const options = useMemo(() => {
    const machinOptions: OptionType[] = [];
    if (assets?.safeye_nodes?.length > 0 && assets.asset_machines?.length > 0) {
      assets?.safeye_nodes?.forEach((it) => {
        const matchedMachine = assets?.asset_machines?.find(
          (_it) => `${_it?.safeye_node_id ?? ''}` === `${it.safeye_node_id}`
        );

        if (matchedMachine) {
          machinOptions?.push({
            machine_id: matchedMachine?.id,
            machine_name: matchedMachine?.name ?? '',
            safeye_node_id: matchedMachine.safeye_node_id ?? '',
            id: it.id,
          });
        }
      });
    }

    return [...machinOptions];
  }, [assets.safeye_nodes, assets.asset_machines]);

  const selectedValue = props.multiple
    ? (selected?.length > 0 ? selected : (props.allOptionDisabled ? [] : ['all']))
    : (props.value || (props.allOptionDisabled ? null : 'all'));

  const defaultLabel = props.multiple
    ? (props.allOptionDisabled && !selected?.length ? 'Select machines' : 'Machines')
    : (props.allOptionDisabled && !selectedValue ? 'Select machine' : 'Machine');

  const handleChange: InputProps['onChange'] = (event: any) => {
    // For single mode we return value immediately
    if (!props.multiple) {
      return props.onChange?.(
        event.target.value === 'all'
          ? null
          : event.target.value
      );
    }

    // For multiple mode we need to update array of selected items
    // and return them later when dropdown is closed
    const isSelectAll = _.last(event.target.value) === 'all';
    setSelected(
      isSelectAll
        ? []
        : event.target.value.filter((i: any) => i !== 'all')
    );
  };

  const handleClose = () => {
    // For multiple mode we return value only after dropdown is closed
    if (props.multiple) {
      props.onChange?.(selected);
    }
  };

  return (
    <TextField
      {...props}
      label={props.label || defaultLabel}
      value={selectedValue}
      select
      SelectProps={{
        multiple: props.multiple,
        onClose: handleClose,
        MenuProps: {
          sx: {maxHeight: '500px'},
        },
      }}
      onChange={handleChange}
    >
      {!props.allOptionDisabled && (
        <MenuItem value="all">
          {props.nullLabel ?? 'All Machines' }
        </MenuItem>
      )}

      {options.map((option) => (
        <MenuItem
          key={option.id ?? ''}
          value={option.id ?? ''}
        >
          {option.machine_name}
        </MenuItem>
      ))}
    </TextField>
  );
};
