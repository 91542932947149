import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {CommtracNode} from '../../../interfaces/CommtracNode';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item?: CommtracNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onOpenItem?: Function;
  onOpenHistory?: Function;
  onDone?: () => void;
}

const CommtracNodeItemsEditButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onOpenItem,
  onOpenHistory,
}: // onDone,
Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <MenuItem
          onClick={() => {
            onOpenItem?.(
              item?.employee_asset_id,
              item?.type === 'miner' ? 'employee' : 'asset',
              'update'
            );
            setMenuAnchorEl(undefined);
          }}
        >
          {`Edit ${item?.type === 'miner' ? 'Employee' : 'Asset'}`}
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenItem?.(
              item?.employee_asset_id,
              item?.type === 'miner' ? 'employee' : 'asset'
            );
            setMenuAnchorEl(undefined);
          }}
        >
          Info & Actions
        </MenuItem>
        <MenuItem
          onClick={() => {
            onOpenHistory?.(
              item?.id,
              item?.type === 'miner' ? 'employee' : 'asset'
            );
            setMenuAnchorEl(undefined);
          }}
        >
          View History
        </MenuItem>
      </Menu>
    </>
  );
};

export default CommtracNodeItemsEditButton;
