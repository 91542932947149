import {MenuItem, TextField, TextFieldProps} from '@mui/material';

import {WIFI_POINT_TYPES} from '../../constants/node';

type Props = TextFieldProps & {
  value?: string | null;
};

const WifiPointTypeSelect = (props: Props) => {
  return (
    <TextField {...props} value={props.value ?? ''} select>
      {WIFI_POINT_TYPES.map((i) => (
        <MenuItem key={i.key} value={i.key}>
          {i.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default WifiPointTypeSelect;
