export enum SAFEYE_NANO_EVENT_TYPES {
  SAFEYE_NANO_NO_HEARTBEAT = 801,
  SAFEYE_NANO_NEW_HAZARD_AI_SYSTEM = 802,
  SAFEYE_NANO_CAMERA_IMPAIRED = 803,
  SAFEYE_NANO_CAMERA_DISCONNECTED = 804,
  SAFEYE_NANO_SPEED_LIMIT_EXCEEDED = 805,
  SAFEYE_NANO_GPS_FAILURE = 806,
}

export const SAFEYE_NANO_EVENT_COLORS: Record<
  (typeof SAFEYE_NANO_EVENT_TYPES)[keyof typeof SAFEYE_NANO_EVENT_TYPES],
  string | null
> = {
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_NO_HEARTBEAT]:
    'hazard-ai-color.ha_color_801',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_NEW_HAZARD_AI_SYSTEM]:
    'hazard-ai-color.ha_color_802',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_CAMERA_IMPAIRED]:
    'hazard-ai-color.ha_color_803',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_CAMERA_DISCONNECTED]:
    'hazard-ai-color.ha_color_804',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_SPEED_LIMIT_EXCEEDED]:
    'hazard-ai-color.ha_color_805',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_GPS_FAILURE]:
    'hazard-ai-color.ha_color_806',
};

export const SAFEYE_NANO_EVENT_SOUNDS: Record<
  (typeof SAFEYE_NANO_EVENT_TYPES)[keyof typeof SAFEYE_NANO_EVENT_TYPES],
  string
> = {
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_NO_HEARTBEAT]:
    'hazard-ai-sound.am_sound_801',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_NEW_HAZARD_AI_SYSTEM]:
    'hazard-ai-sound.am_sound_802',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_CAMERA_IMPAIRED]:
    'hazard-ai-sound.am_sound_803',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_CAMERA_DISCONNECTED]:
    'hazard-ai-sound.am_sound_804',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_SPEED_LIMIT_EXCEEDED]:
    'hazard-ai-sound.am_sound_805',
  [SAFEYE_NANO_EVENT_TYPES.SAFEYE_NANO_GPS_FAILURE]:
    'hazard-ai-sound.am_sound_806',
} as const;
