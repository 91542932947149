import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import {useFormik} from 'formik';
import {cloneDeep, isEqual, omit} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {SafeyeNode, SafeyeNodeAckInputBody} from '../../interfaces/SafeyeNode';
import reduxSelectors from '../../redux/selectors';
import {safeyeNodeAckInputSchema} from '../../scheme/yup/safeye-node';
import AssetMachineSelect from '../asset-machine/AssetMachineSelect';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../common/HazardMap';
import SnackbarMessages from '../common/SnackbarMessages';
import {MachineTypeSelect} from '../selectors/MachineTypeSelect';
import {SafeyeNodeTypeSelect} from '../selectors/SafeyeNodeTypeSelect';
import {SitesSelect} from '../selectors/SitesSelect';
import StatusSelect from '../selectors/StatusSelect';

interface Props {
  pk: number;
  item?: SafeyeNode;
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: Function;
  onSubmitted?: (item: SafeyeNode) => void;
}

const SafeyeNodeItemAck = ({
  pk,
  item,
  prefetch,
  locationCoordinates,
  isActiveModal,
  onCancel,
  onSubmitted,
}: Props) => {
  console.log({locationCoordinates, isActiveModal});
  const assets = useAppSelector(reduxSelectors.assets.getAssets);
  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;

  const assetMachines = useMemo(
    () =>
      !hazard_ai_enabled
        ? assets.asset_machines.filter((i) => i.external_id)
        : assets.asset_machines,
    [assets.asset_machines, hazard_ai_enabled]
  );

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<SafeyeNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.patch<SafeyeNode>(
        `${apiBaseUrl}/safeye-nano-node/${pk}`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: SafeyeNodeAckInputBody) => {
    console.log('submit from ack', {data});
    setSubmittedInProgress(true);
    try {
      const normalizedData = {
        ...omit(data, [
          'is_existing_node',
          ...(isExistingNode
            ? ['type_id', 'machine_name', 'site_id', 'speed_limit']
            : ['asset_machine_id']),
        ]),
      };

      const endpoint = `${apiBaseUrl}/safeye-nano-node/${pk}/acknowledge`;
      const resp = await API.patch<SafeyeNode>(endpoint, normalizedData);
      const message = `Safeye Node successfully acknowledged`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (item?: SafeyeNode): SafeyeNodeAckInputBody => ({
    name: item?.name ?? '',
    site_id: item?.site_id ?? null,
    status: item?.status ?? 'active',
    type: item?.type ?? null,
    is_existing_node: '',
    type_id: item?.type_id ?? null,
    asset_machine_id: item?.asset_machine_id ?? null,
    machine_name: '',
  });

  const formik = useFormik<SafeyeNodeAckInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: safeyeNodeAckInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  const isExistingNode = formik.values.is_existing_node === 'YES';

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <SafeyeNodeTypeSelect
          value={formik.values.type}
          label="Safeye Node Type"
          size="small"
          fullWidth
          error={!!formik.touched.type && !!formik.errors.type}
          helperText={formik.touched.type && formik.errors.type}
          onChange={(v) => {
            formik.setFieldValue('type', v);
          }}
        />
        <StatusSelect
          defaultValue="Active"
          value={fetchedData?.status}
          fullWidth
          label="Status"
          size="small"
          disabled
        />

        <TextField
          value={formik.values.is_existing_node}
          label="Should this be assigned to an existing machine?"
          size="small"
          name="is_existing_node"
          select
          fullWidth
          error={
            !!formik.touched.is_existing_node &&
            !!formik.errors.is_existing_node
          }
          helperText={
            formik.touched.is_existing_node && formik.errors.is_existing_node
          }
          onChange={formik.handleChange}
        >
          {[
            {value: 'YES', name: 'Yes'},
            {value: 'NO', name: 'No'},
          ].map((i) => (
            <MenuItem key={i.name} value={i.value}>
              {i.name}
            </MenuItem>
          ))}
        </TextField>

        {formik.values.is_existing_node === 'YES' ? (
          <AssetMachineSelect
            value={formik.values.asset_machine_id}
            size="small"
            label="Select a Machine"
            fullWidth
            nullLabel=""
            assetMachines={assetMachines}
            error={
              !!formik.touched.asset_machine_id &&
              !!formik.errors.asset_machine_id
            }
            onChange={(_, item) => {
              console.log('item?.asset_machine_id', item?.id);
              formik.setFieldValue('asset_machine_id', item?.id ?? null);
            }}
          />
        ) : null}
        {formik.values.is_existing_node === 'NO' ? (
          <>
            <Typography marginTop="14px">Create a new Machine:</Typography>
            <TextField
              value={formik.values.machine_name}
              label="Machine Name"
              size="small"
              name="machine_name"
              fullWidth
              error={
                !!formik.touched.machine_name && !!formik.errors.machine_name
              }
              helperText={
                formik.touched.machine_name && formik.errors.machine_name
              }
              onChange={formik.handleChange}
            />
            <MachineTypeSelect
              value={formik.values.type_id}
              label="Machine Type"
              size="small"
              fullWidth
              error={!!formik.touched.type_id && !!formik.errors.type_id}
              helperText={formik.touched.type_id && formik.errors.type_id}
              onChange={(v) => formik.setFieldValue('type_id', v)}
            />
            <SitesSelect
              value={formik.values.site_id}
              size="small"
              label="Sites"
              fullWidth
              error={!!formik.touched.site_id && !!formik.errors.site_id}
              helperText={formik.touched.site_id && formik.errors.site_id}
              onChange={(v) => formik.setFieldValue('site_id', v)}
            />
          </>
        ) : null}
      </Box>
      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel && fetchedData?.acknowledge ? (
          <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            color="warning"
          >
            Acknowledge
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SafeyeNodeItemAck;
