import {Box, Checkbox, FormControlLabel, Grid, Paper} from '@mui/material';
import React from 'react';

import {SelectedGroupParams} from '../../interfaces/HumanGroup';

type HumaGroupSectionsPropsType = {
  sections: number[];
  sectionsList: SelectedGroupParams[];
  fieldHandle: (fieldName: string, itemId: number) => void;
};
export const HumaGroupSections = React.memo(
  ({sectionsList, sections, fieldHandle}: HumaGroupSectionsPropsType) => {
    return (
      <Paper sx={{p: 2, mb: 2}}>
        <Box fontSize={20} lineHeight={1.6} mb={2}>
          Select the sections you want included:
        </Box>
        <Grid container sx={{maxHeight: 300, overflowY: 'auto'}}>
          {sectionsList &&
            !!sectionsList.length &&
            sectionsList.map((item: SelectedGroupParams) => (
              <Grid item xs={4} key={item.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={sections.includes(item.id)}
                      onChange={() => fieldHandle('sections', item.id)}
                    />
                  }
                  label={item.info}
                />
              </Grid>
            ))}
        </Grid>
      </Paper>
    );
  }
);
