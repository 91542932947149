import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import {
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import useSound from 'use-sound';

import reduxSelectors from '../../redux/selectors';
import {SOUNDS} from '../../utils/sounds';
type Props = TextFieldProps;

export const SoundSelect = (props: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const options = assets.sound;
  const file = !props.value ? SOUNDS.alarm_1 : SOUNDS[`${props.value}`];
  const [playSound, exposedData] = useSound(file);

  useEffect(() => {
    return () => {
      exposedData.stop();
    };
  }, [exposedData.sound]);

  return (
    <TextField
      fullWidth
      label="Sound"
      {...props}
      value={props.value ?? ''}
      select
      SelectProps={{IconComponent: () => null}}
      InputProps={{
        endAdornment: exposedData.sound ? (
          <InputAdornment position="end">
            <IconButton onClick={() => playSound()}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.code ?? ''} value={option.code ?? ''}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
