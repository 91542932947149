import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {Alert, Backdrop, Box, CircularProgress} from '@mui/material';
import dayjs from 'dayjs';
import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {Company, CompanyListResponse} from '../../interfaces/Company';
import DataGrid, {DataGridColumn} from '../common/DataGrid';
import {CompanyActionsMenu} from './CompanyActionsMenu';

interface Props {}

export interface CompanyListRef {
  fetch?: Function;
}

export const CompanyList = forwardRef<CompanyListRef, PropsWithChildren<Props>>(
  (__, ref) => {
    /*******/
    /* ref */
    /*******/
    useImperativeHandle(ref, () => ({
      fetch: () => fetchData(),
    }));

    /*********/
    /* fetch */
    /*********/
    const [fetchedData, setFetchedData] = useState<CompanyListResponse>();
    const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
    const [fetchedInProgress, setFetchedInProgress] = useState(false);

    const fetchData = async () => {
      setFetchedInProgress(true);

      try {
        const resp = await API.get<CompanyListResponse>(
          `${apiBaseUrl}/company`
        );
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }

      setFetchedInProgress(false);
    };

    /*************/
    /* data grid */
    /*************/
    const rows = fetchedData?.items ?? [];
    const columns: DataGridColumn<Company>[] = [
      {field: 'id', headerName: 'ID'},
      {field: 'name'},
      {
        field: 'status',
        renderCell: ({row}) => {
          return row.status === 'active' ? (
            <ToggleOnIcon color="success" />
          ) : (
            <ToggleOffIcon color="error" />
          );
        },
      },
      {field: 'mine_id', headerName: 'Mine ID'},
      {
        field: 'added_date',
        valueFormatter: ({row}) => dayjs(row.added_date).format('MMM DD, YYYY'),
      },
      {
        field: 'actions',
        type: 'actions',
        sxHeader: {textAlign: 'right'},
        sxCell: {textAlign: 'right'},
        renderCell: ({row}) => {
          return (
            <CompanyActionsMenu
              item={row}
              onSubmitted={() => fetchData()}
              onDeleted={() => fetchData()}
            />
          );
        },
      },
    ];

    useEffect(() => {
      fetchData();
    }, []);

    return (
      <Box position="relative">
        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, index) => (
          <Alert key={index} severity="error" sx={{mb: 2}}>
            {error}
          </Alert>
        ))}

        <DataGrid
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          size="small"
        />
      </Box>
    );
  }
);
