import EditIcon from '@mui/icons-material/Edit';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {Box, IconButton, Tooltip} from '@mui/material';

import {useGetCommtracNodeBatteryPercentage} from '../../../hooks/get-commtrac-node-battery-percentage';
import {CommtracNode} from '../../../interfaces/CommtracNode';
import {eventIconsLegacy} from '../../../utils/event-icons';
import {BatteryIcon} from '../BatteryIcon';

interface Props {
  item: CommtracNode;
  disableEditMode?: boolean;
  onOpenItem?: () => void;
}

const CommtracNodeTooltipContent = ({
  item,
  disableEditMode,
  onOpenItem,
}: Props) => {
  const getCommtracNodeBatteryPercentage =
    useGetCommtracNodeBatteryPercentage();
  const batteryPercentage = getCommtracNodeBatteryPercentage(item);

  if (item.type === 'miner') {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box>Name: {item.name}</Box>
        <Box>Timestampt of last location: {item.commtrac_date ?? '-'}</Box>
        <Box display="flex" gap={1} justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            {item.e_201 ? (
              <Box>
                <Tooltip title="Employee Alarm">
                  {eventIconsLegacy.commtracNode.alarm}
                </Tooltip>
              </Box>
            ) : null}

            {typeof batteryPercentage === 'number' ? (
              <Tooltip title={`${batteryPercentage}%`}>
                <Box>
                  <BatteryIcon value={batteryPercentage} />
                </Box>
              </Tooltip>
            ) : null}

            {item.e_233 ? (
              item.motion_sensor_value === 0 ? (
                <Box>
                  <Tooltip title="Employee is in Not Moving State (Underground)">
                    {eventIconsLegacy.commtracNode.minerNotMoving}
                  </Tooltip>
                </Box>
              ) : item.motion_sensor_value === 1 ? (
                <Box>
                  <Tooltip title="Employee is in Not Moving State (Surface)">
                    {eventIconsLegacy.commtracNode.minerNotMoving}
                  </Tooltip>
                </Box>
              ) : item.motion_sensor_value === 2 ? (
                <Box>
                  <Tooltip title="Moving State Ok (Surface)">
                    {eventIconsLegacy.commtracNode.minerOkMoving}
                  </Tooltip>
                </Box>
              ) : item.motion_sensor_value === 3 ? (
                <Box>
                  <Tooltip title="Moving State Ok (Underground)">
                    {eventIconsLegacy.commtracNode.minerOkMoving}
                  </Tooltip>
                </Box>
              ) : null
            ) : null}

            {item.e_221 ? (
              <Box>
                <Tooltip title="Many Location">
                  {eventIconsLegacy.commtracNode.manyLocation}
                </Tooltip>
              </Box>
            ) : item.e_202 ? (
              <Box>
                <Tooltip title="No Location">
                  {eventIconsLegacy.commtracNode.noLocation}
                </Tooltip>
              </Box>
            ) : item.e_226 ? (
              <Box>
                <Tooltip title="Location Derived">
                  {eventIconsLegacy.commtracNode.derivedLocation}
                </Tooltip>
              </Box>
            ) : null}
          </Box>
          {!disableEditMode ? (
            <IconButton size="small" onClick={() => onOpenItem?.()}>
              <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    );
  } else if (item.type === 'asset') {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box>Name: {item.name}</Box>
        <Box>Timestampt of last location: {item.commtrac_date ?? '-'}</Box>
        <Box display="flex" gap={1} justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            {item.e_302 ? (
              typeof batteryPercentage === 'number' ? (
                <Tooltip title={`${batteryPercentage}%`}>
                  <Box>
                    <BatteryIcon value={batteryPercentage} />
                  </Box>
                </Tooltip>
              ) : (
                <Tooltip title="Unknown">
                  <QuestionMarkIcon />
                </Tooltip>
              )
            ) : null}

            {item.e_321 ? (
              <Box>
                <Tooltip title="Many Location">
                  {eventIconsLegacy.commtracNode.manyLocation}
                </Tooltip>
              </Box>
            ) : item.e_301 ? (
              <Box>
                <Tooltip title="No Location">
                  {eventIconsLegacy.commtracNode.noLocation}
                </Tooltip>
              </Box>
            ) : item.e_326 ? (
              <Box>
                <Tooltip title="Location Derived">
                  {eventIconsLegacy.commtracNode.derivedLocation}
                </Tooltip>
              </Box>
            ) : null}
          </Box>
          {!disableEditMode ? (
            <IconButton size="small" onClick={() => onOpenItem?.()}>
              <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    );
  }
  return null;
};

export default CommtracNodeTooltipContent;
