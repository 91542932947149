import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  SafeyeNode,
  SafeyeNodeUpdateInputBody,
} from '../../interfaces/SafeyeNode';
import {safeyeNodeUpdateInputSchema} from '../../scheme/yup/safeye-node';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../common/HazardMap';
import SnackbarMessages from '../common/SnackbarMessages';
import {SafeyeNodeTypeSelect} from '../selectors/SafeyeNodeTypeSelect';
import StatusSelect from '../selectors/StatusSelect';

interface Props {
  pk: number;
  item?: SafeyeNode;
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: Function;
  onSubmitted?: (item: SafeyeNode) => void;
}
const SafeyeNodeItemUpdate = ({
  pk,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  // Fetch
  const [fetchedData, setFetchedData] = useState<SafeyeNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<SafeyeNode>(
        `${apiBaseUrl}/safeye-nano-node/${pk}`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  // Submit
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: SafeyeNodeUpdateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/safeye-nano-node/${pk}`;
      const resp = await API.patch<SafeyeNode>(endpoint, data);
      const message = `Sefeye Node successfully updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  // input
  const getFormikValues = (item?: SafeyeNode): SafeyeNodeUpdateInputBody => ({
    name: item?.name ?? '',
    status: item?.status ?? 'inactive',
    type: item?.type ?? null,
    safeye_node_id: item?.safeye_node_id ?? '',
    description: item?.description ?? '',
  });

  const formik = useFormik<SafeyeNodeUpdateInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: safeyeNodeUpdateInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <SafeyeNodeTypeSelect
          value={formik.values.type}
          label="Safeye Node Type"
          size="small"
          fullWidth
          error={!!formik.touched.type && !!formik.errors.type}
          helperText={formik.touched.type && formik.errors.type}
          onChange={(v) => {
            formik.setFieldValue('type', v);
          }}
        />
        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.safeye_node_id}
          label="Safeye Node Id"
          size="small"
          name="safeye_node_id"
          rows={3}
          fullWidth
          error={
            !!formik.touched.safeye_node_id && !!formik.errors.safeye_node_id
          }
          helperText={
            formik.touched.safeye_node_id && formik.errors.safeye_node_id
          }
          onChange={formik.handleChange}
        />

        <TextField
          value={formik.values.description}
          label="Description"
          size="small"
          name="description"
          multiline
          rows={3}
          fullWidth
          error={!!formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
          onChange={formik.handleChange}
        />
      </Box>
      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default SafeyeNodeItemUpdate;
