import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {t} from 'i18next';
import {enqueueSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import useDebounce from '../../../hooks/useDebounce';
import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  MProcessorQuery,
  MProcessorResponse,
  MProcessorStatusResponse,
} from '../../../interfaces/MProcessor';
import {processStatusIcons} from '../../../utils/event-icons';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import NumberTextField from '../../common/NumberTextField';
import {usePanel} from '../../dashboards/entities/DashboardEntityContext';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

export const MProcessorLogs = (props: Props) => {
  const [panel] = usePanel();
  const logsRef = useRef<HTMLTextAreaElement>(null);

  const [type, setType] = useState<'stdOut' | 'stdError'>(
    props.value?.type ?? 'stdOut'
  );

  /*****************/
  /* submit status */
  /*****************/
  const [statusErrors, setStatusErrors] = useState<string[]>([]);
  const [statusProgress, setStatusProgress] = useState(false);

  const submitStatus = async () => {
    setStatusProgress(true);
    setStatusErrors([]);
    try {
      const action = isRunning ? 'stop' : 'start';
      const resp = await API.get<MProcessorStatusResponse>(
        `${apiBaseUrl}/m-processor/${action}`
      );
      enqueueSnackbar(`${resp.data.message}`, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      fetchData();
    } catch (error: any) {
      setStatusErrors(error.response.data?.errors ?? []);
    }

    setStatusProgress(false);
  };

  /*********/
  /* fetch */
  /*********/
  const formik = useFormik<MProcessorQuery>({
    initialValues: {
      lines: props.value?.params?.lines || 100,
    },
    onSubmit: () => {},
  });

  const debouncedFormikValues = useDebounce(formik.values);

  const [fetchedData, setFetchedData] = useState<MProcessorResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const isRunning = fetchedData?.running;

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<MProcessorResponse>(
        `${apiBaseUrl}/m-processor/get-logs`,
        {
          params: debouncedFormikValues,
        }
      );
      setFetchedData(resp.data);
      setTimeout(() => {
        if (logsRef.current) {
          logsRef.current.scrollTop = logsRef.current.scrollHeight;
        }
      }, 100);
    } catch (error: any) {
      setFetchedErrors(error.response.data?.errors ?? []);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();
    props?.onUpdate?.({
      ...props.value,
      params: debouncedFormikValues,
    });
  }, [debouncedFormikValues]);

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    fetchData,
    props.value?.refreshInterval ?? 10000
  );

  useEffect(() => {
    props?.onUpdate?.({...props.value, type, refreshInterval});
  }, [type, refreshInterval]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      <DashboardPanelTitleSlot>
        {t(`panels.${panel?.code}`)}
      </DashboardPanelTitleSlot>

      <Box display="flex" gap={2} py={1} justifyContent="space-between">
        <Box display="flex" gap={2}>
          <LoadingButton
            variant="outlined"
            startIcon={
              isRunning ? (
                <DoDisturbOnOutlinedIcon />
              ) : (
                <PlayCircleOutlineIcon />
              )
            }
            loading={statusProgress}
            sx={{width: 110}}
            onClick={() => submitStatus()}
          >
            {isRunning ? 'Stop' : 'Start'}
          </LoadingButton>

          <AutoRefreshSelect
            value={refreshInterval ?? null}
            variant="outlined"
            size="small"
            onChange={(value) => setRefreshInterval(value)}
          />

          <NumberTextField
            value={formik.values.lines}
            min={1}
            label="Num. Lines"
            size="small"
            name="lines"
            sx={{width: 100}}
            onChange={async (v) => formik.setFieldValue('lines', v)}
          />

          <TextField
            value={
              (type === 'stdOut'
                ? fetchedData?.stdOutFilePath
                : fetchedData?.stdErrorFilePath) ?? ''
            }
            label="Path"
            name="path"
            size="small"
            disabled={!isRunning}
            sx={{width: 400}}
            InputProps={{
              readOnly: true,
            }}
          />
        </Box>

        <Box display="flex" gap={2}>
          <IconButton disabled={fetchedInProgress} onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>

          <ButtonGroup size="small">
            <Button
              variant={type === 'stdOut' ? 'contained' : 'outlined'}
              sx={{
                textTransform: 'none',
              }}
              onClick={() => setType('stdOut')}
            >
              StdOut
            </Button>

            <Button
              variant={type === 'stdError' ? 'contained' : 'outlined'}
              sx={{
                textTransform: 'none',
              }}
              onClick={() => setType('stdError')}
            >
              StdError
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box>
        {fetchedErrors.map((error, idx) => (
          <Alert
            key={`fetched-error-${idx}`}
            severity="error"
            onClose={() => {}}
          >
            {error}
          </Alert>
        ))}
        {statusErrors.map((error, idx) => (
          <Alert
            key={`status-error-${idx}`}
            severity="error"
            onClose={() => {}}
          >
            {error}
          </Alert>
        ))}
      </Box>

      <Box height="100%">
        <TextField
          inputRef={logsRef}
          value={
            type === 'stdOut' ? fetchedData?.stdOut : fetchedData?.stdError
          }
          fullWidth
          multiline
          disabled={!isRunning}
          InputProps={{
            readOnly: true,
            sx: {
              height: '100%',
            },
          }}
          inputProps={{
            sx: {
              height: '100% !important',
              overflow: 'scroll !important',
            },
          }}
          sx={{
            height: '100%',
          }}
        />
      </Box>
      <Box display="flex" sx={{'margin-top': '10px'}}>
        {processStatusIcons[fetchedData?.running ? 'running' : 'stopped']}
        {`Status: ${fetchedData?.status}. ID: ${fetchedData?.short_id}`}
      </Box>
    </Box>
  );
};
