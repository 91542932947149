import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, IconButton, Tooltip} from '@mui/material';
import capitalize from 'lodash/capitalize';

import {Node} from '../../../interfaces/Node';
import {eventIconsLegacy} from '../../../utils/event-icons';
import {signalBytesToDbm} from '../../../utils/node';
import {getBatteryPercentage} from '../../../utils/nodes';
import {BatteryIcon} from '../BatteryIcon';

interface Props {
  item: Node;
  disableEditMode?: boolean;
  onOpenItem?: () => void;
  onOpenHistory?: () => void;
}

const NodeTooltipContent = ({
  item,
  disableEditMode,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  if (item.node_type === 'communication_node') {
    const batteryPercentage = item.cn_battery_voltage
      ? getBatteryPercentage(item.cn_battery_voltage)
      : null;
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box>Name: {item.name}</Box>
        <Box>ID: {item.cn_external_id}</Box>
        <Box>Updated: {item.cn_last_update ?? '-'}</Box>
        <Box>Parent: {item.cn_routing_parent_address ?? '-'}</Box>
        <Box>RSSI: {item.cn_routing_parent_rssi ? signalBytesToDbm(item.cn_routing_parent_rssi) : "-"}</Box>
        <Box display="flex" my={1}>
          <Button
            size="small"
            variant="text"
            sx={{color: (theme) => theme.palette.primary.dark}}
            onClick={() => onOpenHistory?.()}
          >
            View Employee/Asset History
          </Button>
        </Box>
        <Box display="flex" gap={1} justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={1}>
            {item.e_103 ? (
              <Box>
                <BatteryIcon value={batteryPercentage ?? 0} />
              </Box>
            ) : null}

            {item.e_121 ? (
              <Box>
                <Tooltip title="Communication Node Many Heartbeat">
                  {eventIconsLegacy.nodes.cnManyHeartbeat}
                </Tooltip>
              </Box>
            ) : item.e_102 ? (
              <Box>
                <Tooltip title="Communication Node No Heartbeat">
                  {eventIconsLegacy.nodes.cnNoHeartbeat}
                </Tooltip>
              </Box>
            ) : item.e_104 ? (
              <Box>
                <Tooltip title="Communication Node Few Heartbeat">
                  {eventIconsLegacy.nodes.cnFewHeartbeat}
                </Tooltip>
              </Box>
            ) : null}

            {item.e_101 ? (
              <Box>
                <Tooltip title="Assigned Master">
                  {eventIconsLegacy.nodes.cnAssignedMaster}
                </Tooltip>
              </Box>
            ) : item.e_112 ? (
              <Box>
                <Tooltip title="Unregistered Network">
                  {eventIconsLegacy.nodes.cnUnregisteredNetwork}
                </Tooltip>
              </Box>
            ) : null}

            {item.e_122 ? (
              <Box>
                <Tooltip title="Configuration Message Failed">
                  {eventIconsLegacy.nodes.cnConfigurationMessageFailed}
                </Tooltip>
              </Box>
            ) : null}
            {item.e_110 || item.e_111 ? (
              <Box>
                <Tooltip title="Version Changed">
                  {eventIconsLegacy.nodes.cnVersionChanged}
                </Tooltip>
              </Box>
            ) : null}
          </Box>
          {!disableEditMode ? (
            <IconButton size="small" onClick={() => onOpenItem?.()}>
              <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
            </IconButton>
          ) : null}
        </Box>
      </Box>
    );
  } else if (item.node_type === 'wifi_point') {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box>Name: {item.name}</Box>
        <Box>IP: {item.wifi_ip}</Box>
        <Box>Last response: {item.wifi_last_response ?? '-'}</Box>
        <Box>Status: {capitalize(item.wifi_connection_status ?? '-')}</Box>
        <Box display="flex" alignItems="center" gap={1}>
          {item.wifi_connection_status === 'disconnected' ? (
            <Box>
              <Tooltip title="Disconnected">
                {eventIconsLegacy.nodes.wifiDisconnected}
              </Tooltip>
            </Box>
          ) : null}
        </Box>
      </Box>
    );
  }
  return null;
};

export default NodeTooltipContent;
