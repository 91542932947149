/* eslint-disable react/no-unused-prop-types */
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {SafeyeNode} from '../../interfaces/SafeyeNode';
import AccessControl from '../common/AccessControl';
import {SafeyeNodeTypeSelect} from '../selectors/SafeyeNodeTypeSelect';
import {SitesSelect} from '../selectors/SitesSelect';
import StatusSelect from '../selectors/StatusSelect';

interface Props {
  pk: number;
  item?: SafeyeNode;
  prefetch?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onAck?: () => void;
  onPurged?: () => void;
  onFetched?: (item: SafeyeNode) => void;
  onOpenHistory?: (item: SafeyeNode) => void;
  onSubmitted?: () => void;
}

const SafeyeNodeItemView = ({pk, item, prefetch, onCancel, onEdit}: Props) => {
  // Fetch
  const [fetchedData, setFetchedData] = useState<SafeyeNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<SafeyeNode>(
        `${apiBaseUrl}/safeye-nano-node/${pk}`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={fetchedData?.name ?? ''}
          label="Name"
          size="small"
          disabled
          fullWidth
          InputProps={{
            endAdornment: (
              <AccessControl
                permissions={['patch::/safeye-nano-node/:id(\\d+)']}
              >
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => onEdit?.()}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </AccessControl>
            ),
          }}
        />
        <SafeyeNodeTypeSelect
          value={fetchedData?.type}
          label="Safeye Node Type"
          size="small"
          disabled
          fullWidth
          InputProps={{
            endAdornment: (
              <AccessControl
                permissions={['patch::/safeye-nano-node/:id(\\d+)']}
              >
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => onEdit?.()}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </AccessControl>
            ),
          }}
        />

        <StatusSelect
          value={fetchedData?.status}
          fullWidth
          label="Status"
          size="small"
          disabled
          InputProps={{
            endAdornment: (
              <AccessControl
                permissions={['patch::/safeye-nano-node/:id(\\d+)']}
              >
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => onEdit?.()}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </AccessControl>
            ),
          }}
        />

        <SitesSelect
          value={fetchedData?.site_id}
          size="small"
          label="Sites"
          fullWidth
          disabled
        />

        <TextField
          value={fetchedData?.safeye_node_id || ''}
          label="Safeye Node Id"
          size="small"
          name="safeye_node_id"
          rows={3}
          fullWidth
          disabled
          InputProps={{
            endAdornment: (
              <AccessControl
                permissions={['patch::/safeye-nano-node/:id(\\d+)']}
              >
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => onEdit?.()}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </AccessControl>
            ),
          }}
        />

        <TextField
          value={fetchedData?.description || ''}
          label="Description"
          size="small"
          name="description"
          multiline
          rows={3}
          fullWidth
          disabled
          InputProps={{
            endAdornment: (
              <AccessControl
                permissions={['patch::/safeye-nano-node/:id(\\d+)']}
              >
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => onEdit?.()}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </AccessControl>
            ),
          }}
        />

        <TextField
          value={fetchedData?.date_activated ?? ''}
          label="Activated TimeStamp"
          size="small"
          disabled
          fullWidth
        />
        <TextField
          value={fetchedData?.date_deactivated ?? ''}
          label="DeActivated TimeStamp"
          size="small"
          disabled
          fullWidth
        />
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Box display="flex" justifyContent="end" gap={0.5}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <AccessControl permissions={['patch::/safeye-nano-node/:id(\\d+)']}>
              <Button onClick={() => onEdit()}>Edit</Button>
            </AccessControl>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default SafeyeNodeItemView;
