export const SOUNDS: {
  [k: string]: any;
} = {
  alarm_1: require('../sounds/alarm_1.wav'),
  alarm_2: require('../sounds/alarm_2.wav'),
  alarm_clock: require('../sounds/alarm_clock.wav'),
  attention: require('../sounds/attention.wav'),
  beep: require('../sounds/beep.wav'),
  boing: require('../sounds/boing.wav'),
  camera_clicks: require('../sounds/camera_clicks.wav'),
  computer: require('../sounds/computer.wav'),
  crash: require('../sounds/crash.wav'),
  electric_wrench: require('../sounds/electric_wrench.wav'),
  got_a_message: require('../sounds/got_a_message.wav'),
  jackhammer_drill: require('../sounds/jackhammer_drill.wav'),
  laser: require('../sounds/laser.wav'),
  machinery: require('../sounds/machinery.wav'),
  metal_clang: require('../sounds/metal_clang.wav'),
  none: require('../sounds/none.wav'),
  something_wrong: require('../sounds/something_wrong.wav'),
  somethingwrong: require('../sounds/somethingwrong.wav'),
};
