import {TextFieldProps} from '@mui/material';
import {DesktopDatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import {useEffect, useMemo, useRef, useState} from 'react';

interface Props {
  label?: string;
  value?: Date | null;
  maxDate?: Date | null;
  disabled?: boolean;
  renderInput?: Omit<TextFieldProps, 'variant'>;
  onChange?: (value: Date | null) => any;
}

export const DateSelect = ({
  label,
  value,
  maxDate,
  disabled,
  renderInput,
  onChange,
}: Props) => {
  const date = useMemo(() => (value ? dayjs(value) : null), [value]);
  const dateRef = useRef(date);
  const [tmpDate, setTmpDate] = useState(date);

  useEffect(() => {
    setTmpDate(date);
    dateRef.current = date;
  }, [date]);

  const [open, setOptn] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        open={open}
        value={tmpDate}
        label={label}
        disabled={disabled}
        format="YYYY-MM-DD"
        slotProps={{
          textField: {
            ...renderInput,
            inputProps: {
              readOnly: true,
            },
            onClick: () => setOptn(true),
          },
        }}
        maxDate={maxDate ? dayjs(maxDate) : null}
        sx={{input: {py: 1.1}}}
        onChange={(v) => {
          onChange?.(v?.toDate() ?? null);
          setTmpDate(v);
          setTimeout(() => {
            setTmpDate(dateRef.current);
          });
        }}
        onClose={() => setOptn(false)}
      />
    </LocalizationProvider>
  );
};
