import "leaflet.heat";

import L from "leaflet";
import { useEffect, useRef } from "react";

interface HeatMapLayerProps {
  id: string;
  data: Array<[number, number, number]> | null;
  options: any;
  map: any;
}

export const HeatMapLayer: React.FC<HeatMapLayerProps> = ({
  data,
  options,
  map,
}) => {
  const heatLayerRef = useRef(null);
  useEffect(() => {
    if (map) {
      heatLayerRef.current = (L as any).heatLayer(data, options).addTo(map);
    }
    return () => {
      if (heatLayerRef.current) {
        map.removeLayer(heatLayerRef.current);
      }
    };
  }, [data, options]);

  return null;
};
