import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {WifiPoint} from '../../interfaces/WifiPoint';
import AccessControl from '../common/AccessControl';
import WifiPointItemActivationModal from './WifiPointItemActivationModal';
import WifiPointItemPurgeModal from './WifiPointItemPurgeModal';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: WifiPoint;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
}

const WifiItemActionsButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
}: Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const [action, setAction] = useState<
    'activation' | 'purgeDevice' | 'purgeData'
  >();

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <AccessControl permissions={['patch::/node/:id/wifi-point']}>
          <MenuItem
            onClick={() => {
              setAction('activation');
              setAnchorEl(null);
            }}
          >
            {item.status === 'active' ? (
              <>
                <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                DeActivate
              </>
            ) : (
              <>
                <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                Activate
              </>
            )}
          </MenuItem>
        </AccessControl>
        <AccessControl permissions={['post::/purge']}>
          <MenuItem
            onClick={() => {
              setAction('purgeData');
              setAnchorEl(null);
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
            Purge Transactions
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAction('purgeDevice');
              setAnchorEl(null);
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
            Purge Device
          </MenuItem>
        </AccessControl>
      </Menu>

      {action === 'activation' ? (
        <WifiPointItemActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : action && ['purgeDevice', 'purgeData'].includes(action) ? (
        <WifiPointItemPurgeModal
          item={item}
          action={action === 'purgeData' ? 'data' : 'device'}
          open={['purgeDevice', 'purgeData'].includes(action)}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}
    </>
  );
};

export default WifiItemActionsButton;
