import {Box, Grid, Typography} from '@mui/material';

import DashboardPanelList from '../../components/dashboard-panels/DashboardPanelList';
import {DashboardLayout} from '../../layouts/DashboardLayout';

const DashboardPanelListPage = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box p={2} display="flex" flexDirection="column">
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  id="modal-modal-title"
                  component="h1"
                  variant="h5"
                  sx={{mb: 2}}
                >
                  Panels
                </Typography>
              </Grid>
            </Grid>

            <DashboardPanelList />
          </Box>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default DashboardPanelListPage;
