import {Node} from '../interfaces/Node';

export const getNodeKey = (item: Node) => {
  const prefix = item.node_type === 'wifi_point' ? 'wifi' : 'cn';
  const id = item.node_type === 'wifi_point' ? item.wifi_id : item.cn_id;
  return `${prefix}-${id}`;
};

export const getIsWifiIpVisible = (type: string | null) => {
  return ['A', 'D', null].includes(type);
};

export const getIsWifiMacSwitchVisible = (type: string | null) => {
  return ['A', 'B', null].includes(type);
};

export const getIsWifiMacRadio1Visible = (type: string | null) => {
  return ['A', 'D', null].includes(type);
};

export const getIsWifiMacRadio2Visible = (type: string | null) => {
  return ['A', null].includes(type);
};

export const getBatteryPercentage = (batteryVoltage: number) => {
  const value = batteryVoltage / 10;
  if (value >= 6) {
    return 100;
  } else if (value >= 5.8) {
    return 90;
  } else if (value >= 5.7) {
    return 80;
  } else if (value >= 5.6) {
    return 70;
  } else if (value >= 5.4) {
    return 60;
  } else if (value >= 5.3) {
    return 50;
  } else if (value >= 5.1) {
    return 40;
  } else if (value >= 5) {
    return 30;
  } else if (value >= 4.7) {
    return 20;
  } else if (value >= 4.5) {
    return 10;
  }
  return 0;
};

export const findTimingOrRoutingGroup = (
  nodes: Node[],
  type: 'routing' | 'timing'
) => {
  const getParentChain = (objId: number | null, chain: Node[] = []): Node[] => {
    const currentObject = nodes.find((obj) => obj.cn_external_id === objId);

    if (currentObject) {
      if (
        chain.some((obj) => obj.cn_external_id === currentObject.cn_external_id)
      ) {
        return chain;
      }

      return getParentChain(
        type === 'routing'
          ? currentObject.cn_routing_parent_address
          : currentObject.cn_timing_parent_address,
        [currentObject, ...chain]
      );
    }

    return chain;
  };

  const isSubarrayOf = (subarray: Node[], array: Node[]): boolean =>
    array.some((_, i) =>
      subarray.every(
        (value, j) => array[i + j]?.cn_external_id === value.cn_external_id
      )
    );

  const uniqueGroups = new Set();

  nodes.forEach((obj, index) => {
    const chain = getParentChain(obj.cn_external_id);
    if (
      !nodes.some(
        (otherObj, otherIndex) =>
          index !== otherIndex &&
          isSubarrayOf(chain, getParentChain(otherObj.cn_external_id))
      )
    ) {
      if (chain?.length > 1) {
        uniqueGroups.add(chain);
      }
    }
  });

  return Array.from(uniqueGroups) as Node[][];
};

export enum CN_EVENT_TYPES {
  CN_ASSIGNED_MASTER = 101,
  CN_NO_HEARTBEAT = 102,
  CN_LOW_BATTERY = 103,
  CN_FEW_HEARTBEATS = 104,
  CN_NEW_CN_DETECTED = 109,
  CN_PIC_VERSION_HAS_CHANGED = 110,
  CN_CC1110_VERSION_HAS_CHANGED = 111,
  CN_UNREGISTERED_NETWORK = 112,
  CN_TOO_MANY_HEARTBEATS = 121,
  CN_CONFIGURATION_FAILED = 122,
  CN_SHUTDOWN_CONFIGURATION_FAILED = 130,
  CN_STOP_SHUTDOWN_CONFIGURATION_FAILED = 131,
  CN_CONFIGURATION_ACK = 132,
}

export const CN_EVENT_SOUNDS: {
  [value in CN_EVENT_TYPES]: string | null;
} = {
  [CN_EVENT_TYPES.CN_ASSIGNED_MASTER]: 'commtrac.sound_comm_assigned_master',
  [CN_EVENT_TYPES.CN_NO_HEARTBEAT]: 'commtrac.sound_comm_hbno_msgs',
  [CN_EVENT_TYPES.CN_LOW_BATTERY]: 'commtrac.sound_comm_low_battery',
  [CN_EVENT_TYPES.CN_FEW_HEARTBEATS]: 'commtrac.sound_comm_few_heartbeat',
  [CN_EVENT_TYPES.CN_NEW_CN_DETECTED]: 'commtrac.sound_comm_new_detected',
  [CN_EVENT_TYPES.CN_PIC_VERSION_HAS_CHANGED]:
    'commtrac.sound_comm_version_changed',
  [CN_EVENT_TYPES.CN_CC1110_VERSION_HAS_CHANGED]:
    'commtrac.sound_comm_version_changed',
  [CN_EVENT_TYPES.CN_UNREGISTERED_NETWORK]:
    'commtrac.sound_comm_unregistered_network',
  [CN_EVENT_TYPES.CN_TOO_MANY_HEARTBEATS]: 'commtrac.sound_comm_many_heartbeat',
  [CN_EVENT_TYPES.CN_CONFIGURATION_FAILED]: 'commtrac.sound_comm_config_failed',
  [CN_EVENT_TYPES.CN_SHUTDOWN_CONFIGURATION_FAILED]: null,
  [CN_EVENT_TYPES.CN_STOP_SHUTDOWN_CONFIGURATION_FAILED]: null,
  [CN_EVENT_TYPES.CN_CONFIGURATION_ACK]: null,
};

export const CN_EVENT_COLORS: {
  [value in CN_EVENT_TYPES]: string | null;
} = {
  [CN_EVENT_TYPES.CN_ASSIGNED_MASTER]:
    'commtrac-color.color_communication_node_assigned_master',
  [CN_EVENT_TYPES.CN_NO_HEARTBEAT]:
    'commtrac-color.color_communication_node_no_hb_no_msgs',
  [CN_EVENT_TYPES.CN_LOW_BATTERY]:
    'commtrac-color.color_communication_node_low_battery',
  [CN_EVENT_TYPES.CN_FEW_HEARTBEATS]:
    'commtrac-color.color_communication_node_few_heartbeat',
  [CN_EVENT_TYPES.CN_NEW_CN_DETECTED]:
    'commtrac-color.color_communication_node_new_detected',
  [CN_EVENT_TYPES.CN_PIC_VERSION_HAS_CHANGED]:
    'commtrac-color.color_communication_node_version_changed',
  [CN_EVENT_TYPES.CN_CC1110_VERSION_HAS_CHANGED]:
    'commtrac-color.color_communication_node_version_changed',
  [CN_EVENT_TYPES.CN_UNREGISTERED_NETWORK]:
    'commtrac-color.color_communication_node_unregistered_network',
  [CN_EVENT_TYPES.CN_TOO_MANY_HEARTBEATS]:
    'commtrac-color.color_communication_node_many_heartbeat',
  [CN_EVENT_TYPES.CN_CONFIGURATION_FAILED]: null,
  [CN_EVENT_TYPES.CN_SHUTDOWN_CONFIGURATION_FAILED]: null,
  [CN_EVENT_TYPES.CN_STOP_SHUTDOWN_CONFIGURATION_FAILED]: null,
  [CN_EVENT_TYPES.CN_CONFIGURATION_ACK]: null,
};
