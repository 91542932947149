import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {useFormik} from 'formik';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useGetMinerNetworkId} from '../../hooks/get-miner-network-id';
import {useAppSelector} from '../../hooks/redux';
import {
  AssetHuman,
  AssetHumanListQuery,
  AssetHumanListResponse,
} from '../../interfaces/AssetHuman';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';

interface Props {
  onClose?: () => void;
  onOpenItem?: (id: number) => void;
}

export interface AssetHumanUnassignedListRef {
  fetch?: () => void;
}

const AssetHumanUnassignedList = forwardRef<AssetHumanUnassignedListRef, Props>(
  ({onClose, onOpenItem}, ref) => {
    /*******/
    /* ref */
    /*******/
    useImperativeHandle(ref, () => ({
      fetch: () => fetchData(formik.values),
    }));

    /*********/
    /* fetch */
    /*********/
    const [fetchedData, setFetchedData] = useState<AssetHumanListResponse>();
    const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
    const [fetchedInProgress, setFetchedInProgress] = useState(false);
    const getMinerNetworkId = useGetMinerNetworkId();

    const fetchData = async (params: AssetHumanListQuery) => {
      setFetchedInProgress(true);
      try {
        const endpoint = `${apiBaseUrl}/asset-human`;
        const resp = await API.get<AssetHumanListResponse>(endpoint, {
          params: {
            ...params,
            filter: JSON.stringify({
              type: 'and',
              filter: [
                {
                  field: 'mac_address',
                  op: 'nl',
                },
                {
                  field: 'commtrac_external_id',
                  op: 'nl',
                },
              ],
            }),
          },
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    };

    /*************/
    /* data grid */
    /*************/
    const assetHumanTypes = useAppSelector(
      ({assets}) => assets.asset_human_types
    );
    const dataGridRef = useRef<DataGridRef>(null);
    const rows = fetchedData?.items ?? [];
    console.log(fetchedData?.items ?? []);
    const columns: DataGridColumn<AssetHuman>[] = [
      {
        field: 'id',
        headerName: 'ID',
        sortable: true,
      },
      {
        field: 'nickname',
        sortable: true,
      },
      {
        field: 'first_name',
        sortable: true,
      },
      {
        field: 'last_name',
        sortable: true,
      },
      {
        field: 'type_id',
        headerName: 'Job Type',
        sortable: true,
        valueGetter: ({row}) =>
          assetHumanTypes.find((i) => row.type_id === i.id)?.name,
      },
      {
        field: 'commtrac_external_id',
        headerName: 'Network ID',
        sortable: true,
        valueGetter: ({row}) => {
          if (row.commtrac_external_id) {
            if (row.type_id === 2) {
              return getMinerNetworkId(row.commtrac_external_id);
            }
            return row.commtrac_external_id;
          }
        },
      },
      {
        field: 'external_id',
        headerName: 'Proximity ID',
        sortable: true,
      },
      {
        field: 'mac_address',
        headerName: 'Mac Address',
        sortable: true,
      },
      {
        field: 'actions',
        type: 'actions',
        sxHeader: {textAlign: 'right'},
        sxCell: {textAlign: 'right'},
        renderCell: ({row}) => {
          return (
            <IconButton
              color="primary"
              size="small"
              onClick={() => onOpenItem?.(row.id)}
            >
              <EditIcon />
            </IconButton>
          );
        },
      },
    ];

    const formik = useFormik<AssetHumanListQuery>({
      initialValues: {
        page: 0,
        limit: 10,
        order: 'id',
        dir: 'DESC',
      },
      onSubmit: () => {},
    });

    useEffect(() => {
      fetchData(formik.values);
    }, [formik.values]);

    return (
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        gap={3}
        p={3}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            className="dragHandle"
            display="flex"
            alignItems="center"
            width="100%"
            gap={1.5}
            sx={{cursor: 'grab'}}
          >
            <PersonIcon sx={{color: 'primary.main'}} />
            <Box fontSize={24}>Unassigned Employees</Box>
          </Box>
          {onClose ? (
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {fetchedErrors.map((error, index) => (
          <Alert key={index} severity="error">
            {error}{' '}
          </Alert>
        ))}
        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          pagination
          paginationMode="server"
          size="small"
          sortBy={{
            field: formik.values.order,
            dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
          }}
          sortingMode="server"
          page={formik.values.page}
          pageSize={formik.values.limit}
          rowCount={fetchedData?.count}
          onPageChange={(v) => formik.setFieldValue('page', v)}
          onPageSizeChange={(v) => {
            formik.setFieldValue('limit', v);
          }}
          onSort={(v) => {
            if (v) {
              formik.setFieldValue('order', v.field);
              formik.setFieldValue('dir', v.dir.toUpperCase());
            }
          }}
        />
      </Box>
    );
  }
);

export default AssetHumanUnassignedList;
