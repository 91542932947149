export const amsSensorAddressMask = 0x0fffff;
export interface GasMonitoringNode {
  id: number;
  commtrac_external_id: number;
  company_id: number;
  avas?: GasMonitoringNode[];
  status: 'active' | 'inactive' | null;
  date: string | null;
  commtrac_env_date: string | null;
  value: number;
  sensor_status: number | null;
  battery_voltage: number | null;
  external_voltage: number | null;
  type: string;
  type_id: number | null;
  unit_id: number | null;
  units: string;
  callibration_date: string;
  sensor_serial_number: number;
  alarm: '1' | '0';
  pos_lon: number;
  pos_lat: number;
  pos_set_by_admin: '1' | '0';
  zone_id: number;
  name: string;
  module_absent: '1' | '0';
  module_warning: '1' | '0';
  point1_tripped: '1' | '0';
  point2_tripped: '1' | '0';
  stel_alarm: '1' | '0';
  twa_alarm: '1' | '0';
  fault: '1' | '0';
  pellister_over: '1' | '0';
  input1: '1' | '0';
  input2: '1' | '0';
  output1: '1' | '0';
  output2: '1' | '0';
  power_source: 'battery' | 'external';
  ack: '1' | '0';
  disable_event: 1 | 0;
  disable_input_event: 1 | 0;
  intrinsically_safe: 1 | 0;
  ava: 1 | 0;
  ava_status: number;
  ava_status_ack: number;
  ventilation_status_ack: number;
  non_sensing: number;
  ventilation_status: number;
  event_delay: number | null;
  suppress_event_date: string | null;
  delayed_status: number | null;
  atest: '1' | '0';
  type2: string;
  type_2: string;
  units2: string;
  value2: number | null;
  event_delay2: number | null;
  suppress_event_date2: string;
  delayed_status2: number | null;
  sensor_status2: number | null;
  checked_in: number;
  sensors_number: number;
  type3: string | null;
  units3: string | null;
  value3: number | null;
  event_delay3: number | null;
  suppress_event_date3: string;
  delayed_status3: number | null;
  sensor_status3: number | null;
  type4: string | null;
  units4: string | null;
  value4: number | null;
  event_delay4: number | null;
  suppress_event_date4: string | null;
  delayed_status4: number | null;
  sensor_status4: number | null;
  conf_status: 1 | 2 | 3 | '';
  e_401: boolean;
  e_402: boolean;
  e_403: boolean;
  e_404: boolean;
  e_405: boolean;
  e_406: boolean;
  e_407: boolean;
  e_408: boolean;
  e_409: boolean;
  e_410: boolean;
  e_411: boolean;
  e_412: boolean;
  e_413: boolean;
  e_420: boolean;
  e_430: boolean;
  e_440: boolean;
  e_441: boolean;
  e_442: boolean;
  e_452: boolean;
  e_453: boolean;
  e_454: boolean;
  e_456: boolean;
  e_458: boolean;
  e_462: boolean;
  e_463: boolean;
  e_464: boolean;
  e_466: boolean;
  e_468: boolean;
  e_474: boolean;
  e_475: boolean;
  e_476: boolean;
  e_477: boolean;
  e_478: boolean;
  e_482: boolean;
  e_483: boolean;
  e_484: boolean;
  e_486: boolean;
  e_488: boolean;
  is_on_external_charger: boolean;
  event_could_be_disabled: boolean;
}

export interface AMSNodeBaseInputBody {
  name: string | null;
  pos_lon: number | null;
  pos_lat: number | null;
  status: 'active' | 'inactive' | null;
  zone_id: number | null;
  commtrac_external_id: number | null;
  re_commtrac_external_id: number | null;
  intrinsically_safe: number | null;
  ava: number | null;
  non_sensing: number | null;
}

export interface AMSSensorExtendInputBody extends AMSNodeBaseInputBody{
  sensor_serial_number: number | null;
}

export interface AMSNodeNetworkdIDInputBody {
  commtrac_external_id: number | null;
}

export interface GasMonitoringNodeListResponse {
  current_page: number;
  items_per_page: number;
  pages: number;
  count: number;
  total: {
    all: number;
    filter: number;
    active: number;
  };
  items: GasMonitoringNode[];
}

export interface GasMonitoringNodeListQuery {
  page: number;
  limit: number;
  standby?: boolean;
  status: 'active' | 'inactive' | 'unacknowledged' | 'all';
  section_ids?: number[];
  date_start: string;
  date_end: string;
  order: string;
  dir: 'ASC' | 'DESC';
}

export interface GasMonitoringNodeLongTermEvent {
  id: number;
  date: string;
  max: number;
  min: number;
  med: number;
  avg: number;
}
