import {ListItem, Modal, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {ReactNode, useMemo, useState} from 'react';

import {Subscription} from '../../../interfaces/Subscription';
import {SubscriptionReport} from '../../../interfaces/SubscriptionReport';
import {SubscriptionReportItemUpsert} from '../SubscriptionReportItemUpsert';

interface SubscriptionReportItemUpsertButtonProps {
  pk?: number;
  item?: SubscriptionReport;
  prefetch?: boolean;
  component?: React.FC;
  componentProps?: any;
  onSubmitted?: Function;
  children?: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 2,
  overflow: 'auto',
  maxHeight: '100%',
};

export const SubscriptionReportItemUpsertButton: React.FC<
  SubscriptionReportItemUpsertButtonProps
> = ({
  pk,
  item,
  component = ListItem,
  componentProps = {},
  children,
  onSubmitted,
}) => {
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);
  const isCreated = useMemo(() => !!item?.id, [item]);
  const title = useMemo(
    () => `${isCreated ? 'Edit' : 'Create'} Subscription Report`,
    [isCreated]
  );

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Modal
        open={isOpened}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
            }}
          >
            {title}
          </Typography>

          <SubscriptionReportItemUpsert
            pk={pk}
            item={item}
            onCancel={closeModal}
            onSubmitted={(e: Subscription) => {
              onSubmitted?.(e);
              closeModal();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
