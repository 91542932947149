import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {getMergedConfigurations} from '../../utils/configuration';
import {COLORED_ICONS} from '../../utils/icons';
import {tweakLabelForMiner} from '../../utils/macAddress';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import {ColorSelect} from '../selectors/ColorSelect';
import {SoundSelect} from '../selectors/SoundSelect';

interface EventsConfigurationConnectValues {
  [key: string]: string | number | null;
}

const FIELDS_BY_SECTIONS = {
  soundGlobal: ['commtrac.sound_new_event', 'commtrac.commtrac_sound_interval'],

  soundNodes: [
    'commtrac.sound_comm_new_detected',
    'commtrac.sound_comm_assigned_master',
    'commtrac.sound_comm_hbno_msgs',
    'commtrac.sound_comm_low_battery',
    'commtrac.sound_comm_few_heartbeat',
    'commtrac.sound_comm_many_heartbeat',
    'commtrac.sound_comm_version_changed',
    'commtrac.sound_comm_unregistered_network',
    'commtrac.sound_comm_config_failed',
  ],

  soundMiners: [
    'commtrac.sound_miner_new_detected',
    'commtrac.sound_miner_alarm',
    'commtrac.sound_miner_no_location',
    'commtrac.sound_miner_location_derived',
    'commtrac.sound_miner_many_location',
    'commtrac.sound_miner_low_battery',
    'commtrac.sound_miner_new_message',
    'commtrac.sound_miner_config_failed',
    'commtrac.sound_miner_not_moving_state',
  ],

  soundAssets: [
    'commtrac.sound_asset_new_detected',
    'commtrac.sound_asset_no_location',
    'commtrac.sound_asset_location_derived',
    'commtrac.sound_asset_many_location',
    'commtrac.sound_asset_low_battery',
  ],

  soundAlarms: [
    'alarm-sound.am_sound_701',
    'alarm-sound.am_sound_702',
    'alarm-sound.am_sound_703',
    'alarm-sound.am_sound_704',
    'alarm-sound.am_sound_705',
    'alarm-sound.am_sound_706',
  ],

  colorNodes: [
    'commtrac-color.color_communication_node_assigned_master',
    'commtrac-color.color_communication_node_no_hb_no_msgs',
    'commtrac-color.color_communication_node_low_battery',
    'commtrac-color.color_communication_node_few_heartbeat',
    'commtrac-color.color_communication_node_many_heartbeat',
    'commtrac-color.color_communication_node_version_changed',
    'commtrac-color.color_communication_node_unregistered_network',
  ],

  colorMiners: [
    'commtrac-color.color_miner_node_alarm',
    'commtrac-color.color_miner_node_new_detected',
    'commtrac-color.color_miner_node_no_location',
    'commtrac-color.color_miner_node_location_derived',
    'commtrac-color.color_miner_node_many_location',
    'commtrac-color.color_miner_node_low_battery',
    'commtrac-color.color_miner_node_new_message',
    'commtrac-color.color_miner_not_moving_state',
  ],

  colorAssets: [
    'commtrac-color.color_asset_node_new_detected',
    'commtrac-color.color_asset_node_no_location',
    'commtrac-color.color_asset_node_location_derived',
    'commtrac-color.color_asset_node_many_location',
    'commtrac-color.color_asset_node_low_battery',
  ],

  colorAlarms: [
    'alarm-color.am_color_701',
    'alarm-color.am_color_702',
    'alarm-color.am_color_703',
    'alarm-color.am_color_704',
    'alarm-color.am_color_705',
    'alarm-color.am_color_706',
  ],
};

const FIELDS = _.flatMap(FIELDS_BY_SECTIONS);

const getFormikValues = (
  item?: ConfigurationResponse | null
): EventsConfigurationConnectValues => ({
  ..._.chain(FIELDS)
    .map((name) => ({name, value: null}))
    .keyBy('name')
    .mapValues('value')
    .value(),

  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('value')
    .value(),
});

const getFormikValuesClear = (
  item?: ConfigurationResponse | null
): EventsConfigurationConnectValues => ({
  ..._.chain(item)
    .filter((i) => FIELDS.includes(`${i.section}.${i.name}`))
    .keyBy((i) => `${i.section}.${i.name}`)
    .mapValues('default')
    .value(),
});

export interface Props {
  onChangeSubmittedInProgress?: (value: boolean) => any;
  onChangeResetInProgress?: (value: boolean) => any;
}

export interface MyCommtracConfigRef {
  fetch?: Function;
  submit?: Function;
  reset?: Function;
  clear?: Function;
}

export const MyCommtracConfig = forwardRef<
  MyCommtracConfigRef,
  React.PropsWithChildren<Props>
>(({onChangeSubmittedInProgress, onChangeResetInProgress}, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => formik.handleSubmit(),
    reset: () => handleResetData(),
    clear: () => formik.setValues(getFormikValuesClear(myConfigurations)),
  }));

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const reduxDispatch = useDispatch();
  const myConfigurations = useAppSelector(({app}) => app.myConfigurations);

  /*********/
  /* fetch */
  /*********/

  const [fetchedErrors, setFetchedErrors] = useState<string[]>();
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const fetchData = async () => {
    setFetchedInProgress(true);
    try {
      const params = {
        section: JSON.stringify([
          'commtrac',
          'commtrac-color',
          'alarm-color',
          'alarm-sound',
        ]),
      };
      const resp = await API.get<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        {params}
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    fetchData();
    return () => {
      setFetchedErrors(undefined);
    };
  }, []);

  /**********/
  /* submit */
  /**********/

  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);
    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        data
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
      enqueueSnackbar('Configuration successfully updated', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /**********/
  /* reset */
  /**********/

  const [resetInProgress, setResetInProgress] = useState(false);
  const resetData = async (data: ConfigurationInput) => {
    setResetInProgress(true);
    try {
      const resp = await API.patch<ConfigurationResponse>(
        `${apiBaseUrl}/user-configuration`,
        data
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(myConfigurations, resp.data),
      });
      enqueueSnackbar('Configuration successfully reset', {
        variant: 'success',
        action: CloseSnackbarAction,
      });
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setResetInProgress(false);
  };

  const handleResetData = async () => {
    const input: ConfigurationInput = {
      section: ['commtrac', 'commtrac-color', 'alarm-sound', 'alarm-color'],
      configuration: _.map(formik.values, (value, key) => ({
        section: key.split('.')[0],
        name: key.split('.')[1],
        value,
      })),
    };
    await resetData(input);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object();

  const formik = useFormik({
    initialValues: getFormikValues(myConfigurations),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      const input: ConfigurationInput = {
        section: ['commtrac', 'commtrac-color', 'alarm-sound', 'alarm-color'],
        configuration: _.map(values, (value, key) => ({
          section: key.split('.')[0],
          name: key.split('.')[1],
          value,
        })),
      };
      await submitData(input);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(myConfigurations);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(newInput);
    }
  }, [myConfigurations]);

  /************/
  /* keysData */
  /************/
  const keysData = useMemo(
    () => ({
      ..._.chain(myConfigurations)
        .keyBy((i) => `${i.section}.${i.name}`)
        .mapValues()
        .value(),
    }),
    [myConfigurations]
  );

  // other
  useEffect(() => {
    onChangeSubmittedInProgress?.(submittedInProgress);
  }, [submittedInProgress]);

  useEffect(() => {
    onChangeResetInProgress?.(resetInProgress);
  }, [resetInProgress]);

  return (
    <Box position="relative">
      <Backdrop open={fetchedInProgress}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors?.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Paper sx={{p: 2}}>
            <Box fontSize={18} lineHeight={1.6} mb={1.5} fontWeight={600}>
              Event Sounds
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={1.5}>
                Global
              </Box>

              <Grid container spacing={3}>
                <Grid item xs={12} lg={6}>
                  {FIELDS_BY_SECTIONS.soundGlobal.slice(0, 1).map((name) => (
                    <Box key={name} mb={2}>
                      <SoundSelect
                        fullWidth
                        label={t(keysData[name]?.label)}
                        value={formik.values[name] ?? ''}
                        name={name}
                        size="small"
                        onChange={(event) =>
                          formik.setValues({
                            ...formik.values,
                            [name]: event.target.value,
                          })
                        }
                      />
                    </Box>
                  ))}
                </Grid>

                <Grid item xs={12} lg={6}>
                  {FIELDS_BY_SECTIONS.soundGlobal.slice(1).map((name) => (
                    <Box key={name} mb={2}>
                      <TextField
                        fullWidth
                        label={t(keysData[name]?.label)}
                        value={formik.values[name] ?? 'None'}
                        name={name}
                        type="number"
                        size="small"
                        select
                        onChange={(event) => {
                          const value =
                            event.target.value === 'None'
                              ? null
                              : event.target.value;
                          formik.setValues({...formik.values, [name]: value});
                        }}
                      >
                        {['None', 10, 15, 30, 60, 120].map((i) => (
                          <MenuItem key={i ?? ''} value={i ?? ''}>
                            {i ?? 'None'}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={1.5}>
                Communication/Gateway Nodes
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundNodes.map((name) => (
                  <Grid key={name} item xs={12} lg={6}>
                    <SoundSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={1.5}>
                Employees
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundMiners.map((name) => (
                  <Grid key={name} item xs={12} lg={6}>
                    <SoundSelect
                      fullWidth
                      label={tweakLabelForMiner(t(keysData[name]?.label))}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={1.5}>
                Assets
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundAssets.map((name) => (
                  <Grid key={name} item xs={12} lg={6}>
                    <SoundSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Alarm Module
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.soundAlarms.map((name) => (
                  <Grid key={name} item xs={6}>
                    <SoundSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={6}>
          <Paper sx={{p: 2}}>
            <Box fontSize={18} lineHeight={1.6} mb={1.5} fontWeight={600}>
              Event Colors
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={1.5}>
                Communication/Gateway Nodes
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorNodes.map((name) => (
                  <Grid key={name} item xs={12} lg={6}>
                    <ColorSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      icons={COLORED_ICONS.communicationNodes}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={1.5}>
                Employees
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorMiners.map((name) => (
                  <Grid key={name} item xs={12} lg={6}>
                    <ColorSelect
                      fullWidth
                      label={tweakLabelForMiner(t(keysData[name]?.label))}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      icons={COLORED_ICONS.miners}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box mb={2}>
              <Box fontSize={16} lineHeight={1.6} mb={1.5}>
                Assets
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorAssets.map((name) => (
                  <Grid key={name} item xs={12} lg={6}>
                    <ColorSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      icons={COLORED_ICONS.assetEvents}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box mb={3}>
              <Box fontSize={16} lineHeight={1.6} mb={3}>
                Alarm Module
              </Box>

              <Grid container spacing={3}>
                {FIELDS_BY_SECTIONS.colorAlarms.map((name) => (
                  <Grid key={name} item xs={6}>
                    <ColorSelect
                      fullWidth
                      label={t(keysData[name]?.label)}
                      value={formik.values[name] ?? ''}
                      name={name}
                      size="small"
                      icons={COLORED_ICONS.alarm}
                      onChange={(event) =>
                        formik.setValues({
                          ...formik.values,
                          [name]: event.target.value,
                        })
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});
