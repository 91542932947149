import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {isEqual} from 'lodash';
import {useSnackbar} from 'notistack';
import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {useDispatch} from 'react-redux';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  Company,
  CompanyInputBody,
  CompanyItemResponse,
} from '../../interfaces/Company';
import reduxActions from '../../redux/actions';
import {phpTimezoneOptions} from '../../utils/intl';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  disabled?: boolean;
}

export interface AdminCompanyEditRef {
  fetch?: () => void;
  submit?: () => void;
}

const inputValidationSchema = yup.object().shape({
  timezone: yup.string().nullable().required('Field is required'),
  minx: yup.number().nullable().required('Field is required').min(-90).max(90),
  miny: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
  maxx: yup.number().nullable().required('Field is required').min(-90).max(90),
  maxy: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
});

const getFormikValues = (item?: Company): Partial<CompanyInputBody> => ({
  timezone: item?.timezone ?? null,
  minx: item?.minx ?? null,
  maxx: item?.maxx ?? null,
  miny: item?.miny ?? null,
  maxy: item?.maxy ?? null,
});

const AdminCompanyEdit = forwardRef<
  AdminCompanyEditRef,
  React.PropsWithChildren<Props>
>(({disabled}, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(),
    submit: () => submitData(formik.values),
  }));

  /*********/
  /* fetch */
  /*********/
  const dispatch = useDispatch();
  const fetchedData = useAppSelector(({assets}) => assets.company ?? undefined);
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<CompanyItemResponse>(
        `${apiBaseUrl}/company/current`
      );
      reduxActions.assets.setAssets(dispatch, {company: resp.data});
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [, setSubmittedInProgress] = useState(false);

  const submitData = async (data: Partial<CompanyInputBody>) => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/company/${fetchedData?.id}`;
      const resp = await API.patch(endpoint, data);
      const message = `Company has been updated`;
      reduxActions.assets.setAssets(dispatch, {company: resp.data});
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const formik = useFormik<Partial<CompanyInputBody>>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newFormikValues = getFormikValues(fetchedData);
    if (!isEqual(newFormikValues, formik.values)) {
      formik.setValues(newFormikValues);
    }
  }, [fetchedData]);

  return (
    <Box>
      <Paper sx={{p: 3, mb: 3}}>
        <Box
          component="form"
          position="relative"
          onSubmit={formik.handleSubmit}
        >
          <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {fetchedErrors.map((error, index) => (
            <Alert key={index} severity="error" sx={{my: 2}}>
              {error}
            </Alert>
          ))}

          <Box my={2}>
            <Autocomplete
              value={
                phpTimezoneOptions.find(
                  (i) => i.name === formik.values.timezone
                ) ?? null
              }
              disabled={disabled}
              fullWidth
              options={phpTimezoneOptions}
              isOptionEqualToValue={(option, value) =>
                option.name === value?.name
              }
              getOptionLabel={(option) => option.fullName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Timezone"
                  size="small"
                  error={!!formik.touched.timezone && !!formik.errors.timezone}
                  helperText={formik.touched.timezone && formik.errors.timezone}
                />
              )}
              onChange={(event, value) =>
                formik.setFieldValue('timezone', value?.name)
              }
            />
          </Box>
        </Box>
      </Paper>
      <Paper sx={{p: 3, mb: 3}}>
        <Box fontSize={20} lineHeight={1.6} mb={3}>
          Company Range
        </Box>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <TextField
              value={formik.values.minx ?? ''}
              label="Min Latitude"
              size="small"
              name="minx"
              type="number"
              fullWidth
              error={!!formik.touched.minx && !!formik.errors.minx}
              helperText={formik.touched.minx && formik.errors.minx}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              value={formik.values.maxx ?? ''}
              label="Max Latitude"
              size="small"
              name="maxx"
              type="number"
              fullWidth
              error={!!formik.touched.maxx && !!formik.errors.maxx}
              helperText={formik.touched.maxx && formik.errors.maxx}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              value={formik.values.miny ?? ''}
              label="Min Longitude"
              size="small"
              name="miny"
              type="number"
              fullWidth
              error={!!formik.touched.miny && !!formik.errors.miny}
              helperText={formik.touched.miny && formik.errors.miny}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              value={formik.values.maxy ?? ''}
              label="Max Longitude"
              size="small"
              name="maxy"
              type="number"
              fullWidth
              error={!!formik.touched.maxy && !!formik.errors.maxy}
              helperText={formik.touched.maxy && formik.errors.maxy}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
});

export default AdminCompanyEdit;
