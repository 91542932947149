/* eslint-disable @typescript-eslint/no-unused-vars */
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {Box, Checkbox, FormControlLabel, Grid, IconButton} from '@mui/material';
import {useFormik} from 'formik';
import {ChangeEvent, useState} from 'react';

import {ExportField} from '../../interfaces/Export';

enum ColumnsCount {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  SIX = 6,
  TWELVE = 12,
}

interface Props {
  exportFields?: ExportField[];
  loading?: boolean;
  onChange?: (value: string[]) => void;
  close?: () => void;
  columns?: ColumnsCount;
  height?: number;
}

const xsVsColumns: {
  [value in ColumnsCount]: number;
} = {
  [ColumnsCount.ONE]: 12,
  [ColumnsCount.TWO]: 6,
  [ColumnsCount.THREE]: 4,
  [ColumnsCount.FOUR]: 3,
  [ColumnsCount.SIX]: 2,
  [ColumnsCount.TWELVE]: 1,
};

const AMSExportFieldForm = ({
  exportFields,
  loading,
  onChange,
  close,
  columns = 3,
  height = 300,
}: Props) => {
  const [fields, setFields] = useState<string[]>(
    exportFields
      ? exportFields
          .filter((field) => !field.hidden)
          .map((field) => field.field)
      : []
  );

  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    field: string
  ) => {
    if (checked) {
      fields.push(field);
      setFields([...fields]);
    } else {
      const index = fields.indexOf(field);
      fields.splice(index, 1);
      setFields([...fields]);
    }
  };

  const formik = useFormik<string[]>({
    initialValues: fields,
    onSubmit: async () => {
      onChange?.(fields);
    },
  });

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" width="100%" gap={1.5}>
          <Box fontSize={20}>Select Fields to Export</Box>
        </Box>
        {/* <IconButton onClick={() => close?.()}>
          <CloseIcon />
        </IconButton> */}
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <Grid container sx={{p: 1, height, overflowY: 'auto'}}>
          {!!exportFields?.length &&
            exportFields.map((item: ExportField) => (
              <Grid item xs={xsVsColumns[columns]} key={item.field}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={fields.indexOf(item.field) !== -1}
                      onChange={(event, checked) => {
                        handleChange(event, checked, item.field);
                      }}
                    />
                  }
                  label={item.label}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="end" gap={0.5}>
        <Box mt={2}>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={loading}
            sx={{ml: 1}}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AMSExportFieldForm;
