import {configureStore} from '@reduxjs/toolkit';

import reduxRedusers from './reducers';

export const store = configureStore({
  reducer: reduxRedusers,
});

export type ReduxState = ReturnType<typeof store.getState>;
export type ReduxDispatch = typeof store.dispatch;
