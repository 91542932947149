import AddIcon from '@mui/icons-material/Add';
import BarChartIcon from '@mui/icons-material/BarChart';
import CloseIcon from '@mui/icons-material/Close';
import {
  Divider,
  Drawer,
  DrawerProps,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';

import {useIndicatorPanel} from '../../contexts';
import {Widget} from '../../types';

interface Props extends DrawerProps {
  addWidget?: (widget: Widget) => void;
}

export const WidgetLibraryPanel = ({
  addWidget = () => {},
  onClose,
  ...rest
}: Props) => {
  const {library} = useIndicatorPanel();

  return (
    <Drawer variant="temporary" anchor="right" {...rest} onClose={onClose}>
      <Toolbar />
      <Grid container width={500}>
        <Grid container item>
          <Grid item container p={2} alignItems="center">
            <Grid item xs>
              <Typography variant="h5">Add widget from library</Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                size="small"
                onClick={() => onClose?.({}, 'backdropClick')}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid container item overflow="auto" spacing={1} p={1}>
          {library?.items?.map((item, index) => (
            <Grid item xs={12} key={index}>
              <ListItem
                disablePadding
                secondaryAction={
                  <IconButton size="small">
                    <AddIcon fontSize="small" />
                  </IconButton>
                }
                onClick={() => addWidget(item)}
              >
                <ListItemButton>
                  <ListItemIcon sx={{minWidth: 36}}>
                    <BarChartIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
              <Divider sx={{opacity: 0.4}} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Drawer>
  );
};
