import {MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React from 'react';

import {useIndicatorPanel} from '../contexts';

export const PanelSelector = () => {
  const {
    panels,
    selectedPanel,
    editMode,
    updateModalValues,
    updateContextValue,
  } = useIndicatorPanel();

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === undefined) {
      updateModalValues({indicatorPanel: true});
      return;
    }
    updateContextValue({
      selectedPanel: panels?.find(
        (panel) => panel.id === Number(event.target.value)
      ),
    });
  };

  return (
    <Select
      value={selectedPanel?.id?.toString()}
      size="small"
      onChange={handleChange}
      autoWidth
      disabled={editMode}
    >
      {panels?.map((panel) => (
        <MenuItem value={panel.id} key={panel.id}>
          {panel.name}
        </MenuItem>
      ))}
      <MenuItem value={undefined}>+ Create a new panel</MenuItem>
    </Select>
  );
};
