import ApartmentIcon from '@mui/icons-material/Apartment';
import {Box, Button, Grid, Modal} from '@mui/material';
import {useRef} from 'react';

import AccessControl from '../common/AccessControl';
import {CompanyItemUpsertButton} from './buttons/CompanyItemUpsertButton';
import {CompanyList, CompanyListRef} from './CompanyList';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  border: '1px solid #9b9b9b',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
};

export const CompanyListModal = ({open = false, onClose}: Props) => {
  const listRef = useRef<CompanyListRef | null>(null);

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={style}>
          <Box py={0} display="flex" flexDirection="column">
            <Grid container mb={3}>
              <Grid item xs={9}>
                <Box fontSize={24} display="flex" alignItems="center">
                  <ApartmentIcon sx={{mr: 1}} />
                  Companies
                </Box>
              </Grid>

              <AccessControl permissions={['post::/company']}>
                <Grid item xs={3}>
                  <Box textAlign="right">
                    <CompanyItemUpsertButton
                      component={Button}
                      componentProps={{variant: 'outlined'}}
                      onSubmitted={() => listRef?.current?.fetch?.()}
                    >
                      Add Company
                    </CompanyItemUpsertButton>
                  </Box>
                </Grid>
              </AccessControl>
            </Grid>

            <CompanyList ref={listRef} />
          </Box>
        </Box>
      </Modal>
    </>
  );
};
