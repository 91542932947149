import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AssessmentIcon from '@mui/icons-material/Assessment';
import DeleteIcon from '@mui/icons-material/Delete';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Avatar,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useEffect} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import {apiBaseUrl} from '../../api/urls';
import {useCrudItem} from '../../hooks/useCridItem';
import {Subscription, SubscriptionInput} from '../../interfaces/Subscription';
import reduxSelectors from '../../redux/selectors';
import {SubscriptionReportSelectTableButton} from './buttons/SubscriptionReportSelectTableButton';

const periodOptions = [
  {value: 'endOfShift', label: 'End of Shift'},
  {value: 'daily', label: 'Daily'},
  {value: 'weekly', label: 'Weekly'},
  {value: 'monthly', label: 'Monthly'},
];

const inputValidationSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required'),
  reports: yup
    .array()
    .of(yup.object({id: yup.number().integer()}))
    .required()
    .test(
      'has-reports',
      'Reports cannot be empty. Add at least one report to the list',
      (reports) => reports.length >= 1
    ),
  users: yup
    .array()
    .of(yup.number().integer())
    .required()
    .test(
      'has-recipients',
      'Recipients cannot be empty. Add at least one user, role or email recipient',
      (users, context) =>
        users.length +
          context.parent.roles.length +
          context.parent.emails.length >=
        1
    ),
  roles: yup
    .array()
    .of(yup.number().integer())
    .required()
    .test(
      'has-recipients',
      'Recipients cannot be empty. Add at least one user, role or email recipient',
      (roles, context) =>
        roles.length +
          context.parent.users.length +
          context.parent.emails.length >=
        1
    ),
  emails: yup
    .array()
    .of(yup.string().email())
    .required()
    .test(
      'has-recipients',
      'Recipients cannot be empty. Add at least one user, role or email recipient',
      (email, context) =>
        email.length +
          context.parent.users.length +
          context.parent.roles.length >=
        1
    ),
});

const emailInputValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Field is required')
    .email('Please enter valid email address'),
});

const getInput = (item?: Subscription): SubscriptionInput => ({
  name: item?.name ?? '',
  period: item?.period ?? 'daily',
  emails: item?.emails ?? [],
  roles: item?.roles ?? [],
  users: item?.users ?? [],
  reports:
    item?.reports?.map((i) => ({
      id: i.id,
      name: i.name,
    })) ?? [],
});

interface SubscriptionItemUpsertProps {
  pk?: number;
  item?: Subscription;
  onCancel?: Function;
  onSubmitted?: Function;
}

export const SubscriptionItemUpsert: React.FC<SubscriptionItemUpsertProps> = ({
  pk,
  item,
  onCancel,
  onSubmitted,
}) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const crud = useCrudItem<Subscription>({
    pk,
    item,
    endpointBase: `${apiBaseUrl}/subscription`,
    onSubmitted,
  });

  const formik = useFormik({
    initialValues: getInput(item),
    validationSchema: inputValidationSchema,
    onSubmit: (values) => {
      formik.setTouched({});
      crud.onSubmit(values);
    },
  });

  const emailFormik = useFormik({
    initialValues: {email: ''},
    validationSchema: emailInputValidationSchema,
    onSubmit: ({email}) => {
      formik.setFieldValue('emails', [...formik.values.emails, email]);
      emailFormik.setFieldValue('email', '');
      emailFormik.setTouched({});
    },
  });

  useEffect(() => {
    const newInput = getInput?.(crud.item);
    if (!_.isEqual(newInput, formik.values)) {
      formik.setValues(getInput?.(crud.item));
    }
  }, [crud.item]);

  return (
    <Box>
      <Backdrop open={crud.isFetching}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {crud.errors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Box mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              sx={{my: 2}}
              value={formik.values.name}
              fullWidth
              name="name"
              label="Name"
              type="text"
              error={!!formik.touched.name && !!formik.errors.name}
              helperText={formik.touched.name && formik.errors.name}
              onChange={formik.handleChange}
            />
          </Grid>

          <Grid item xs={3}>
            <Box>
              <TextField
                sx={{my: 2}}
                value={formik.values.period}
                fullWidth
                select
                name="period"
                label="Period"
                type="text"
                error={!!formik.touched.period && !!formik.errors.period}
                helperText={formik.touched.period && formik.errors.period}
                onChange={formik.handleChange}
              >
                {periodOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Divider sx={{mb: 3}}>Reports</Divider>

      {!!formik.errors.reports && !!formik.touched.reports && (
        <Alert severity="error" sx={{mb: 2}}>
          {formik.errors.reports as string}
        </Alert>
      )}

      <Paper variant="outlined" sx={{p: 2, pt: 0, mb: 3}}>
        <List dense>
          {formik.values.reports.map((report) => (
            <ListItem
              key={report.id}
              sx={{px: 0}}
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    formik.setFieldValue(
                      'reports',
                      formik.values.reports.filter((i) => i.id !== report.id)
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <AssessmentIcon />
                </Avatar>
              </ListItemAvatar>

              <ListItemText primary={report.name ?? `Report ${report.id}`} />
            </ListItem>
          ))}
        </List>

        <SubscriptionReportSelectTableButton
          value={formik.values.reports}
          component={Button}
          componentProps={{
            fullWidth: true,
            variant: 'outlined',
            size: 'large',
          }}
          onSelect={(report) => {
            if (!formik.values.reports.map((i) => i.id).includes(report.id)) {
              formik.setFieldValue('reports', [
                ...formik.values.reports,
                {
                  id: report.id,
                  name: report.name,
                },
              ]);
            }
          }}
        >
          Add Report
        </SubscriptionReportSelectTableButton>
      </Paper>

      <Divider sx={{mb: 2}}>Role Recipients</Divider>

      <Box>
        <TextField
          sx={{my: 2}}
          value={formik.values.roles}
          fullWidth
          select
          name="roles"
          label="Roles"
          type="text"
          error={!!formik.touched.roles && !!formik.errors.roles}
          helperText={formik.touched.roles && formik.errors.roles}
          SelectProps={{
            multiple: true,
            MenuProps: {
              sx: {maxHeight: '300px'},
            },
          }}
          onChange={formik.handleChange}
        >
          {assets.roles.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Divider sx={{mb: 2}}>User Recipients</Divider>

      <Box>
        <TextField
          sx={{my: 2}}
          value={formik.values.users}
          fullWidth
          select
          name="users"
          label="Users"
          type="text"
          error={!!formik.touched.users && !!formik.errors.users}
          helperText={formik.touched.users && formik.errors.users}
          SelectProps={{
            multiple: true,
            MenuProps: {
              sx: {maxHeight: '300px'},
            },
          }}
          onChange={formik.handleChange}
        >
          {assets.users.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Divider sx={{my: 3}}>Email Recipients</Divider>

      {!!formik.errors.emails && !!formik.touched.emails && (
        <Alert severity="error" sx={{mb: 2}}>
          {formik.errors.emails}
        </Alert>
      )}

      <Paper variant="outlined" sx={{p: 2, pt: 0, mb: 3}}>
        <List>
          {formik.values.emails.map((email, index) => (
            <ListItem
              key={index}
              sx={{px: 0}}
              secondaryAction={
                <IconButton
                  edge="end"
                  onClick={() => {
                    formik.setFieldValue(
                      'emails',
                      formik.values.emails.filter((i) => i !== email)
                    );
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemIcon>
                <AlternateEmailIcon />
              </ListItemIcon>

              <ListItemText primary={email} />
            </ListItem>
          ))}
        </List>

        <Box component="form" onSubmit={emailFormik.handleSubmit}>
          <TextField
            value={emailFormik.values.email}
            fullWidth
            name="email"
            type="text"
            error={!!emailFormik.touched.email && !!emailFormik.errors.email}
            helperText={emailFormik.touched.email && emailFormik.errors.email}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" type="submit">
                    Add Email
                  </Button>
                </InputAdornment>
              ),
            }}
            onChange={emailFormik.handleChange}
          />
        </Box>
      </Paper>

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>

        <LoadingButton
          variant="contained"
          loading={formik.isSubmitting}
          sx={{ml: 1}}
          onClick={() => formik.handleSubmit()}
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};
