import DeleteIcon from '@mui/icons-material/Delete';
import {Grid, IconButton, Stack, Typography} from '@mui/material';
import React, {PropsWithChildren} from 'react';

export interface BaseMetricProps extends PropsWithChildren {
  title: string;
  onDelete?: () => void;
}

export const BaseMetric = ({title, onDelete, children}: BaseMetricProps) => {
  return (
    <Grid
      container
      p={1}
      position="relative"
      sx={{
        cursor: 'pointer',
      }}
    >
      <Grid item container alignItems="center">
        <Grid item xs>
          <Typography variant="caption" fontWeight="600">
            {title}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Stack spacing={1} direction="row" alignItems="center">
            {!!onDelete && (
              <IconButton size="small" sx={{p: 0}} onClick={onDelete}>
                <DeleteIcon fontSize="small" sx={{color: 'gray'}} />
              </IconButton>
            )}
          </Stack>
        </Grid>
      </Grid>
      {children}
    </Grid>
  );
};
