import {useSelector} from 'react-redux';

import reduxSelectors from '../../redux/selectors';
import {SingleMultiSelect, SingleMultiSelectProps} from './SingleMultiSelect';

type Props = Omit<SingleMultiSelectProps, 'options'>;

export const ZonesSelect = (props: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const options = assets.zones.map((z) => ({ id: z.id, name: z.name }));

  const label = props.label ?? 'Sections';
  const nullLabel = props.nullLabel ?? 'All Sections';

  return (
    <SingleMultiSelect
      {...props}
      label={label}
      nullLabel={nullLabel}
      options={options}
    />
  );
};
