export const name = {
  regex: /^[а-яА-ЯёЁӘәҒғҚқӨөҰұҺһІіa-zA-Z0-9_.' \-#\(\)]+$/,
  message:
    "Field should only contain letters, numbers, spaces and special characters: .'-_#()",
};

export const ip_address = {
  regex:
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
  message: 'Field should be valid ip address',
};

export const container_name = {
  regex: /^[A-Za-z0-9_]+$/,
  message: 'Field should only contain letters, numbers and _',
};
