import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import {enqueueSnackbar} from 'notistack';
import {memo, useCallback, useEffect, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  Backhauler,
  BackhaulerSummaryResponse,
} from '../../../interfaces/Backhauler';
import {processStatusIcons} from '../../../utils/event-icons';
import {BackHaulersDeleteButton} from '../../backHaulers/buttons/BackHaulersDeleteButton';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import NumberTextField from '../../common/NumberTextField';

type Props = {
  value: Backhauler;
  onDeleted?: () => void;
};

export const BackHaulersTabItem = memo(({value, onDeleted}: Props) => {
  const [fetchedData, setFetchedData] = useState<BackhaulerSummaryResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const [lines, setLines] = useState<number | null>(100);
  const [info, setInfo] = useState<boolean>(true);

  const fetchData = useCallback(async () => {
    if (fetchedInProgress) {
      return;
    }

    setFetchedInProgress(true);

    try {
      const resp = await API.get<BackhaulerSummaryResponse>(
        `${apiBaseUrl}/backhauler/${value.id}/summary`,
        {
          params: {
            lines,
          },
        }
      );
      setFetchedData(resp.data);
      setFetchedErrors([]);
    } catch (error: any) {
      setFetchedErrors(error.response.data?.errors ?? []);
    }

    setFetchedInProgress(false);
  }, [lines]);

  const submitStatus = async () => {
    setFetchedInProgress(true);
    try {
      const action = fetchedData?.running ? 'stop' : 'start';
      const resp = await API.patch<any>(
        `${apiBaseUrl}/backhauler/${value.id}/${action}`
      );
      enqueueSnackbar(`${resp.data.message}`, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      fetchData();
    } catch (error: any) {
      enqueueSnackbar(`${error.response.data?.errors}`, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setFetchedInProgress(false);
  };

  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    () => fetchData(),
    10000
  );

  useEffect(() => {
    if (refreshInterval) {
      fetchData();
    }
  }, [refreshInterval]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
      position="relative"
    >
      <Box display="flex" gap={2} py={1} justifyContent="space-between">
        <Box display="flex" gap={2}>
          <LoadingButton
            variant="outlined"
            startIcon={
              fetchedData?.running ? (
                <DoDisturbOnOutlinedIcon />
              ) : (
                <PlayCircleOutlineIcon />
              )
            }
            loading={fetchedInProgress}
            sx={{width: 110}}
            onClick={() => submitStatus()}
          >
            {fetchedData?.running ? 'Stop' : 'Start'}
          </LoadingButton>

          <AutoRefreshSelect
            value={refreshInterval ?? null}
            variant="outlined"
            //disabled={!fetchedData?.running}
            size="small"
            onChange={(v) => setRefreshInterval(v)}
          />

          <NumberTextField
            value={lines}
            min={1}
            label="Num. Lines"
            size="small"
            name="lines"
            sx={{width: 100}}
            onChange={(v) => setLines(v)}
          />

          <TextField
            value={
              (info ? fetchedData?.infoFilePath : fetchedData?.errorFilePath) ??
              ''
            }
            label="Path"
            name="path"
            size="small"
            sx={{width: 600}}
            fullWidth
          />
        </Box>

        <Box display="flex" gap={2}>
          <IconButton disabled={fetchedInProgress} onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>

          <ButtonGroup size="small">
            <Button
              variant={info ? 'contained' : 'outlined'}
              sx={{
                textTransform: 'none',
              }}
              onClick={() => setInfo(true)}
            >
              StdOut
            </Button>

            <Button
              variant={!info ? 'contained' : 'outlined'}
              sx={{
                textTransform: 'none',
              }}
              onClick={() => setInfo(false)}
            >
              StdError
            </Button>
          </ButtonGroup>

          {!!value?.id && (
            <BackHaulersDeleteButton
              id={value.id}
              prefetch
              component={IconButton}
              componentProps={{
                color: 'error',
                size: 'small',
              }}
              onDeleted={onDeleted}
            >
              <DeleteOutlineOutlinedIcon />
            </BackHaulersDeleteButton>
          )}
        </Box>
      </Box>

      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <Box height="100%" overflow="hidden">
        {fetchedData?.info ? (
          <TextField
            value={info ? fetchedData.info : fetchedData.error}
            fullWidth
            multiline
            disabled={!fetchedData?.running}
            InputProps={{
              readOnly: true,
              sx: {
                height: '100%',
                overflow: 'hidden',
              },
            }}
            inputProps={{
              sx: {
                height: '100% !important',
                overflow: 'scroll !important',
              },
            }}
            sx={{
              height: '100%',
              overflow: 'hidden',
            }}
          />
        ) : (
          <Box
            height="100%"
            alignItems="center"
            justifyContent="center"
            display="flex"
          >
            {fetchedInProgress ? 'Loading...' : 'No data'}
          </Box>
        )}
      </Box>
      <Box display="flex" sx={{'margin-top': '10px'}}>
        {processStatusIcons[fetchedData?.running ? 'running' : 'stopped']}
        {`Status: ${fetchedData?.status}. ID: ${fetchedData?.short_id}`}
      </Box>
    </Box>
  );
});
