import {Box, Modal, ModalProps} from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import {ReactNode, useEffect, useState} from 'react';
import Draggable from 'react-draggable';

import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import reduxActions from '../../redux/actions';

interface Props extends Omit<ModalProps, 'children'> {
  children?:
    | ModalProps['children']
    | ((params: {isActive: boolean}) => ReactNode | ReactNode[]);
}

const ModalDraggable = ({children, ...props}: Props) => {
  const [modalId] = useState<number>(() => +uniqueId());
  const activeDraggableModal = useAppSelector(
    ({app}) => app.activeDraggableModal
  );

  const reduxDispatch = useAppDispatch();

  const setActive = () => {
    reduxDispatch((state) =>
      reduxActions.app.setApp(state, {
        activeDraggableModal: modalId,
      })
    );
  };

  useEffect(() => {
    setActive();
  }, []);

  const isActive = modalId === activeDraggableModal;

  return (
    <Draggable handle=".dragHandle">
      <Modal
        hideBackdrop
        disableScrollLock
        closeAfterTransition={true}
        {...props}
        sx={{
          position: 'absolute',
          top: '30px',
          left: 'calc(50% - 350px)',
          right: 'auto',
          bottom: 'auto',
          transform: 'translate(-50%, -50%)',
          width: 700,
          bgcolor: 'background.paper',
          border: '1px solid #9b9b9b',
          boxShadow: 24,
          overflow: 'auto',
          maxHeight: '85%',
          zIndex: isActive ? 1202 : 1201,
          ...props.sx,
        }}
      >
        <Box onMouseDown={setActive}>
          {typeof children === 'function' ? children({isActive}) : children}
          <Box
            className="dragHandle"
            position="absolute"
            width="100%"
            bottom={0}
            left={0}
            height={10}
            sx={{cursor: 'grab'}}
          />
        </Box>
      </Modal>
    </Draggable>
  );
};

export default ModalDraggable;
