import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {keys} from 'lodash';
import Image from 'mui-image';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import EModuleIcon from '../../../images/ams/emodule/icon_emodule.svg';
import {AMSEModuleInputBody} from '../../../interfaces/AMSEModule';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import {amsEModuleInputSchema} from '../../../scheme/yup/ams-node';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';
import {DateSelect} from '../../selectors/DateSelect';
import StatusSelect from '../../selectors/StatusSelect';

interface Props {
  onCancel?: () => void;
  onSubmitted?: () => void;
}

const AMSEModuleItemCreate = ({onCancel, onSubmitted}: Props) => {
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const ams_nodes = useAppSelector(({assets}) => assets.ams_nodes);
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  /**********/
  /* submit */
  /**********/
  const submitData = async (data: AMSEModuleInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams/emodule`;
      await API.post<AMSEModuleNode>(endpoint, data);
      const message = 'e-Module successfully created';
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });

      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (): AMSEModuleInputBody => ({
    serial_number: null,
    date_calibration: null,
    calibration_value: null,
    commtrac_external_id: null,
    date_installation: null,
    type: null,
    status: null,
  });

  const formik = useFormik<AMSEModuleInputBody>({
    initialValues: getFormikValues(),
    validationSchema: amsEModuleInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <Image src={EModuleIcon} alt="heat map" width={30} height={30} />
          <Box fontSize={24}>Create eModule</Box>
        </Box>
        {onCancel ? (
          <IconButton onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.serial_number ?? ''}
            label="e-Module Serial Number"
            size="small"
            name="serial_number"
            fullWidth
            error={
              !!formik.touched.serial_number && !!formik.errors.serial_number
            }
            helperText={
              formik.touched.serial_number && formik.errors.serial_number
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <DateSelect
            value={formik.values.date_calibration}
            renderInput={{
              label: 'Calibration Date',
              size: 'small',
              fullWidth: true,
              error:
                !!formik.touched.date_calibration &&
                !!formik.errors.date_calibration,
              helperText:
                formik.touched.date_calibration &&
                formik.errors.date_calibration,
            }}
            onChange={(value) =>
              formik.setFieldValue(
                'date_calibration',
                dayjs(value ?? undefined).format('YYYY-MM-DD')
              )
            }
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.calibration_value ?? ''}
            label="Calibration Value"
            size="small"
            name="calibration_value"
            fullWidth
            error={
              !!formik.touched.calibration_value &&
              !!formik.errors.calibration_value
            }
            helperText={
              formik.touched.calibration_value &&
              formik.errors.calibration_value
            }
            onChange={formik.handleChange}
          />
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            fullWidth
            label="Associated Sensor"
            select
            size="small"
            SelectProps={{
              multiple: false,
            }}
            value={formik.values.commtrac_external_id}
            onChange={(el) => {
              formik.setFieldValue('commtrac_external_id', el.target.value);
            }}
            error={
              !!formik.touched.commtrac_external_id &&
              !!formik.errors.commtrac_external_id
            }
            helperText={
              formik.touched.commtrac_external_id &&
              formik.errors.commtrac_external_id
            }
          >
            {ams_nodes
              ?.map((it) => ({
                id: it?.commtrac_external_id,
                name: it?.name,
              }))
              .map((option) => (
                <MenuItem key={option.id ?? ''} value={option.id ?? ''}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <DateSelect
            value={formik.values.date_installation}
            renderInput={{
              label: 'Installation Date',
              size: 'small',
              fullWidth: true,
              error:
                !!formik.touched.date_installation &&
                !!formik.errors.date_installation,
              helperText:
                formik.touched.date_installation &&
                formik.errors.date_installation,
            }}
            onChange={(value) =>
              formik.setFieldValue(
                'date_installation',
                dayjs(value ?? undefined).format('YYYY-MM-DD')
              )
            }
          />
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            fullWidth
            label="Type"
            select
            size="small"
            SelectProps={{
              multiple: false,
            }}
            value={formik.values.type}
            onChange={(el) => {
              formik.setFieldValue('type', el.target.value);
            }}
            error={!!formik.touched.type && !!formik.errors.type}
            helperText={formik.touched.type && formik.errors.type}
          >
            {keys(typeIdLabels)?.map((it: string, index) => (
              <MenuItem key={index} value={it}>
                {typeIdLabels?.[Number(it)] ?? ''}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AMSEModuleItemCreate;
