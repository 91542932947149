import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import NodeItemsPurgeEventsButton from './NodeItemsPurgeEventsButton';
import NodeItemsPurgeNodeButton from './NodeItemsPurgeNodeButton';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  cnIds?: number[];
  wifiIds?: number[];
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const NodeItemsPurgeButton = <T extends ComponentType = typeof Button>({
  cnIds,
  wifiIds,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const menuOpen = !!menuAnchorEl;

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onBackdropClick={() => setMenuAnchorEl(undefined)}
      >
        <NodeItemsPurgeNodeButton
          cnIds={cnIds}
          wifiIds={wifiIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Devices
        </NodeItemsPurgeNodeButton>

        <NodeItemsPurgeEventsButton
          cnIds={cnIds}
          wifiIds={wifiIds}
          component={MenuItem}
          onDone={() => {
            setMenuAnchorEl(undefined);
            onDone?.();
          }}
        >
          Transactions
        </NodeItemsPurgeEventsButton>
      </Menu>
    </>
  );
};

export default NodeItemsPurgeButton;
