import {Box} from '@mui/material';
import update from 'immutability-helper';
import {useSelector} from 'react-redux';

import {
  AmsEmoduleInstallationHistoryItem,
} from "../../../interfaces/AmsEmoduleInstallationHistory";
import reduxSelectors from '../../../redux/selectors';
import {Map, MapLayerId} from '../../common/Map';
import {AmsEmoduleInstallationHistoryConfig} from './index';

interface AmsEmoduleInstallationHistoryMapProps {
  data?: AmsEmoduleInstallationHistoryItem[];
  config?: AmsEmoduleInstallationHistoryConfig;
  onChange?: (value: AmsEmoduleInstallationHistoryConfig) => void;
}

export const AmsEmoduleInstallationHistoryMap = (props: AmsEmoduleInstallationHistoryMapProps) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);

  const config = {
    ...{mapLayers: ['street', 'ams_emodule_installation_history']},
    ...props.config,
  };

  return (
    <Box
      bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
      height="100%"
    >
      <Map
        panel="ams_emodule_installation_history"
        selectedMapLayers={config.mapLayers as MapLayerId[]}
        selectedLevel={config.mapLevel}
        amsEmoduleInstallationHistoryData={props.data}
        minWidth={250}
        availableMapLayers={[
          'street',
          'mine',
          'nodes',
          'alarms',
          'ams_emodule_installation_history',
        ]}
        onSelectMapLayers={(v) => {
          props.onChange?.(
            update(config, {
              mapLayers: {
                $set: v,
              },
            })
          );
        }}
        onSelectLevel={(v) => {
          props.onChange?.(
            update(config, {
              mapLevel: {
                $set: v as number,
              },
            })
          );
        }}
      />
    </Box>
  );
};
