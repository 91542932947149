export const getAmsBatteryPercent = (val: number | null) => {
  if (!val) {
    return 'Unknown';
  }
  if (val > 6) {
    return '100%';
  }
  if (val >= 5.8) {
    return '90%';
  }
  if (val >= 5.7) {
    return '80%';
  }
  if (val >= 5.6) {
    return '70%';
  }
  if (val >= 5.4) {
    return '60%';
  }
  if (val >= 5.3) {
    return '50%';
  }
  if (val >= 5.1) {
    return '40%';
  }
  if (val >= 5) {
    return '30%';
  }
  if (val >= 4.7) {
    return '20%';
  }
  if (val >= 4.5) {
    return '10%';
  }

  return '0%';
};

export const getAMSBatteryIconType = ({
  batteryVoltage,
  externalVoltage,
  isOnExternalCharger,
}: {
  batteryVoltage: number | null;
  externalVoltage: number | null;
  isOnExternalCharger: boolean;
}) => {
  const amsBatteryFull = 6;
  const amsBatteryHigh = 5.5;
  const amsrBatteryMiddle = 5;
  const amsBatteryLow = 4.5;

  if (isOnExternalCharger && externalVoltage && externalVoltage >= 2) {
    return 'power';
  } else if (batteryVoltage && batteryVoltage > amsBatteryFull) {
    return 'batteryFull';
  } else if (batteryVoltage && batteryVoltage >= amsBatteryHigh) {
    return 'batteryHigh';
  } else if (batteryVoltage && batteryVoltage >= amsrBatteryMiddle) {
    return 'batteryMid';
  } else if (batteryVoltage && batteryVoltage >= amsBatteryLow) {
    return 'batteryLow';
  } else {
    return 'batteryEmpty';
  }
};

export const getAmsBatteryIconColor = ({
  batteryVoltage,
  externalVoltage,
  amsNodeLowBatteryValue = '',
  isOnExternalCharger,
  isLowExternalChargeEvent = false,
  isLowBatteryEvent = false,
}: {
  batteryVoltage: number | null;
  externalVoltage: number | null;
  amsNodeLowBatteryValue: string | number | undefined;
  isOnExternalCharger: boolean;
  isLowExternalChargeEvent?: boolean;
  isLowBatteryEvent?: boolean;
}) => {
  const amsExternalNormal = 9;

  const batteryFrom =
    isOnExternalCharger && externalVoltage && externalVoltage >= 2
      ? 'external'
      : 'battery';

  if (batteryFrom === 'external') {
    if (
      (externalVoltage && externalVoltage < amsExternalNormal) ||
      isLowExternalChargeEvent
    ) {
      return 'error';
    }
  }

  if (batteryFrom === 'battery') {
    const isBatteryVoltageLowerThanSpecified =
      batteryVoltage &&
      amsNodeLowBatteryValue &&
      !Number.isNaN(parseInt(String(amsNodeLowBatteryValue))) &&
      batteryVoltage <= parseInt(String(amsNodeLowBatteryValue));

    if (isBatteryVoltageLowerThanSpecified || isLowBatteryEvent) {
      return 'error';
    }
  }

  return '';
};

export enum AMS_EVENT_TYPES {
  AMS_NEW_DETECTED = 401,
  AMS_ALARM = 402,
  AMS_ALERT = 403,
  AMS_SD_NO_ENVS_MESSAGE = 404,
  AMS_LOW_BATTERY = 405,
  AMS_FEW_ENV_MESSAGES = 406,
  AMS_MANY_ENV_MESSAGES = 407,
  AMS_CALIBRATION_DATE_EXPIRED = 408,
  AMS_LOW_EXTERNAL_CHARGE = 409,
  AMS_EMODULE_UNREGISTERED = 410,
  AMS_ENV_MISSING_SN = 411,
  AMS_FAILED_AVA_NOTICE = 412,
  AMS_FAILED_VENTILATION_NOTICE = 413,
  AMS_SENSOR_VENTILATION = 420,
  AMS_AVA_TEST = 440,
  AMS_AVA_STOP_TEST = 441,
  AMS_AVA_CANCEL_TEST = 442,
}

export const AMS_COLORS: {
  [value in AMS_EVENT_TYPES]: string | null;
} = {
  // 401
  [AMS_EVENT_TYPES.AMS_NEW_DETECTED]: 'ams-color.color_ams_new_detected',
  // 402
  [AMS_EVENT_TYPES.AMS_ALARM]: 'ams-color.color_ams_alarm',
  // 403
  [AMS_EVENT_TYPES.AMS_ALERT]: 'ams-color.color_ams_alert',
  // 404
  [AMS_EVENT_TYPES.AMS_SD_NO_ENVS_MESSAGE]:
    'ams-color.color_ams_sd_no_envs_message',
  // 405
  [AMS_EVENT_TYPES.AMS_LOW_BATTERY]: 'ams-color.color_ams_low_battery',
  // 406
  [AMS_EVENT_TYPES.AMS_FEW_ENV_MESSAGES]:
    'ams-color.color_ams_few_env_messages',
  // 407
  [AMS_EVENT_TYPES.AMS_MANY_ENV_MESSAGES]:
    'ams-color.color_ams_many_env_messages',
  // 408
  [AMS_EVENT_TYPES.AMS_CALIBRATION_DATE_EXPIRED]:
    'ams-color.color_ams_calibration_date_expired',
  // 409
  [AMS_EVENT_TYPES.AMS_LOW_EXTERNAL_CHARGE]: 'ams-color.color_ams_low_battery',
  // 410
  [AMS_EVENT_TYPES.AMS_EMODULE_UNREGISTERED]:
    'ams-color.color_ams_emodule_unregistered',
  // 411
  [AMS_EVENT_TYPES.AMS_ENV_MISSING_SN]: 'ams-color.color_ams_env_missing_sn',
  // 412
  [AMS_EVENT_TYPES.AMS_FAILED_AVA_NOTICE]: 'ams-color.no_color',
  // 413
  [AMS_EVENT_TYPES.AMS_FAILED_VENTILATION_NOTICE]: 'ams-color.no_color',
  // 420
  [AMS_EVENT_TYPES.AMS_SENSOR_VENTILATION]: 'ams-color.no_color',
  // 440
  [AMS_EVENT_TYPES.AMS_AVA_TEST]: 'ams-color.color_ams_ava_test',
  // 441
  [AMS_EVENT_TYPES.AMS_AVA_STOP_TEST]: 'ams-color.no_color',
  // 442
  [AMS_EVENT_TYPES.AMS_AVA_CANCEL_TEST]: 'ams-color.no_color',
  //[AMS_EVENT_TYPES.AMS_INPUT_STATE_CHANGE]: 'ams-color.color_ams_input_state_change',
};

export const AMS_SOUNDS: {
  [value in AMS_EVENT_TYPES]: string | null;
} = {
  // 401
  [AMS_EVENT_TYPES.AMS_NEW_DETECTED]: 'ams.sound_ams_new_detected',
  // 402
  [AMS_EVENT_TYPES.AMS_ALARM]: 'ams.sound_ams_alarm',
  // 403
  [AMS_EVENT_TYPES.AMS_ALERT]: 'ams.sound_ams_alert',
  // 404
  [AMS_EVENT_TYPES.AMS_SD_NO_ENVS_MESSAGE]: 'ams.sound_ams_sd_no_envs_message',
  // 405
  [AMS_EVENT_TYPES.AMS_LOW_BATTERY]: 'ams.sound_ams_low_battery',
  // 406
  [AMS_EVENT_TYPES.AMS_FEW_ENV_MESSAGES]: 'ams.sound_ams_few_env_messages',
  // 407
  [AMS_EVENT_TYPES.AMS_MANY_ENV_MESSAGES]: 'ams.sound_ams_many_env_messages',
  // 408
  [AMS_EVENT_TYPES.AMS_CALIBRATION_DATE_EXPIRED]:
    'ams.sound_ams_calibration_date_expired',
  // 409
  [AMS_EVENT_TYPES.AMS_LOW_EXTERNAL_CHARGE]: 'ams.sound_ams_low_battery',
  // 410
  [AMS_EVENT_TYPES.AMS_EMODULE_UNREGISTERED]:
    'ams.sound_ams_emodule_unregistered',
  // 411
  [AMS_EVENT_TYPES.AMS_ENV_MISSING_SN]: 'ams.sound_ams_env_missing_sn',
  // 412
  [AMS_EVENT_TYPES.AMS_FAILED_AVA_NOTICE]: 'ams.no_sound',
  // 413
  [AMS_EVENT_TYPES.AMS_FAILED_VENTILATION_NOTICE]: 'ams.no_sound',
  // 420
  [AMS_EVENT_TYPES.AMS_SENSOR_VENTILATION]: 'ams.no_sound',
  // 440
  [AMS_EVENT_TYPES.AMS_AVA_TEST]: 'ams.sound_ams_ava_test',
  // 441
  [AMS_EVENT_TYPES.AMS_AVA_STOP_TEST]: 'ams.no_sound',
  // 442
  [AMS_EVENT_TYPES.AMS_AVA_CANCEL_TEST]: 'ams.no_sound',
  //[AMS_EVENT_TYPES.AMS_INPUT_STATE_CHANGE]: 'ams.sound_ams_input_state_change',
};

export const AMS_TYPE = {
  Ambient: 'AMB',
  CL2: 'CL2',
  CO: 'CO',
  H2: 'H2',
  H2S: 'H2S',
  NO: 'NO',
  NO2: 'NO2',
  O2: 'O2',
  SO2: 'SO2',
  'Analog 4 - 20 mA': 'ANA',
  'Analog 0.4 - 2 V': 'ANA',
  'PEL-CH4': 'CH4',
  'IR-CH4': 'CH4',
  'SW Namur': 'SW',
  'SW Monitored': 'SW',
  'Air Flow': 'FLOW',
};

export const getAMSIconNameByType = (type: string) => {
  if (Object.keys(AMS_TYPE).includes(type)) {
    return AMS_TYPE[type as keyof typeof AMS_TYPE];
  }
  return 'amsUnknown';
};
