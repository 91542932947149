import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Tab} from '@mui/material';
import {Dispatch, SetStateAction, useMemo} from 'react';

import {Backhauler} from '../../../interfaces/Backhauler';
import {BackHaulersTabItem} from './BackHaulersTabItem';

interface Props {
  value?: Backhauler[];
  activeId?: number | null;
  setActiveId?: Dispatch<SetStateAction<number | null>>;
  onDeleted?: () => void;
}

export const BackHaulersTabs = ({
  value,
  activeId,
  setActiveId,
  onDeleted,
}: Props) => {
  const tabs: Backhauler[] | undefined = useMemo(() => {
    return value;
  }, [value]);

  const activeIdString = useMemo<string>(() => {
    return activeId
      ? `${activeId}`
      : tabs && tabs.length
        ? `${tabs[0].id}`
        : '=';
  }, [activeId, tabs]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      {tabs?.length ? (
        <TabContext value={activeIdString}>
          <TabList
            variant="scrollable"
            onChange={(_event, v) => setActiveId?.(v)}
          >
            {tabs?.map((tab) => (
              <Tab
                key={`${tab.id}`}
                value={`${tab.id}`}
                label={
                  <Box display="flex" alignItems="center">
                    {tab?.name ?? 'Undefined'}
                  </Box>
                }
              />
            ))}
          </TabList>

          <Box width="100%" height="100%" overflow="hidden">
            {tabs?.map((tabPanel) => (
              <TabPanel
                key={tabPanel.id}
                value={`${tabPanel?.id}`}
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  p: 0,
                  pt: 2,
                }}
              >
                <BackHaulersTabItem onDeleted={onDeleted} value={tabPanel} />
              </TabPanel>
            ))}
          </Box>
        </TabContext>
      ) : null}
    </Box>
  );
};
