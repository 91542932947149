import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  AssetMachine,
  AssetMachineUpdateInputBody,
} from '../../interfaces/AssetMachine';
import reduxActions from '../../redux/actions';
import {assetMachineUpdateInputSchema} from '../../scheme/yup/asset-machine';
import {maxProximityId} from '../../scheme/yup/utils';
import {
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsWifiEnabledVisible,
} from '../../utils/commtrac-nodes';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import {MachineTypeSelect} from '../selectors/MachineTypeSelect';
import {SiteSelect} from '../selectors/SiteSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  pk?: number;
  mode?: 'view' | 'update' | 'chat' | 'update_from_info';
  siteId?: number | null; // Should be removed after updating get endpoint by roman.
  item?: AssetMachine;
  prefetch?: boolean;
  onCancel?: () => void;
  onSubmitted?: (item: AssetMachine) => void;
}

type UpdateInputBody = AssetMachineUpdateInputBody & {
  hazard_ai_enabled: boolean | undefined;
  is_commtrac: boolean | null;
  wifi_enabled: boolean | null;
  is_proximity: boolean | null;
  proximity_enabled: boolean;
  commtrac_enabled: boolean;
};

// eslint-disable-next-line complexity
const AssetMachineItemUpdate = ({
  pk,
  mode,
  siteId,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  const reduxDispatch = useAppDispatch();
  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AssetMachine>(
        `${apiBaseUrl}/asset-machine/${pk}`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;

  const submitData = async (data: UpdateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/asset-machine/${pk}`;
      const resp = await API.patch<AssetMachine>(endpoint, {
        ...data,
        is_commtrac: undefined,
        wifi_enabled: getIsWifiEnabledVisible(data.is_commtrac)
          ? data.wifi_enabled
          : undefined,
        min_voltage: getIsMinVoltageVisible(data.is_commtrac, data.wifi_enabled)
          ? data.min_voltage
          : undefined,
        max_voltage: getIsMaxVoltageVisible(data.is_commtrac, data.wifi_enabled)
          ? data.max_voltage
          : undefined,
      });
      const message = `Asset has been updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAssetMachines);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const zones = useAppSelector(({assets}) => assets.zones);
  const sites = useAppSelector(({assets}) => assets.sites);
  const machineTypes = useAppSelector(({assets}) => assets.machine_types);
  const getFormikValues = (item?: AssetMachine): UpdateInputBody => ({
    proximity_enabled: !!company?.proximity_enabled,
    hazard_ai_enabled: company?.hazard_ai_enabled,
    commtrac_enabled: !!company?.commtrac_enabled,
    is_commtrac: !!item?.commtrac_external_id || !!item?.mac_address,
    is_proximity: !!item?.external_id,
    wifi_enabled: !!item?.wifi_enabled,
    name: item?.name ?? null,
    type_id: item?.type_id
      ? machineTypes.find((i) => i.id === item.type_id)?.id ?? null
      : null,
    zone_id: item?.zone_id
      ? zones.find((i) => i.id === item.zone_id)?.id ?? null
      : null,
    // site_id: item?.site_id
    //   ? sites.find((i) => i.id === item.site_id)?.id ?? null
    //   : null,
    site_id: siteId ? sites?.find((i) => i.id === siteId)?.id ?? null : null,
    status: item?.status ?? null,
    external_id: item?.external_id ?? null,
    events_flag: item?.events_flag ?? null,
    min_voltage: item?.min_voltage ?? null,
    max_voltage: item?.max_voltage ?? null,
    input_1_type: item?.input_1_type ?? null,
    input_2_type: item?.input_2_type ?? null,
    input_3_type: item?.input_3_type ?? null,
    input_4_type: item?.input_4_type ?? null,
    input_5_type: item?.input_5_type ?? null,
  });

  const formik = useFormik<UpdateInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: assetMachineUpdateInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  const machineInputs = useAppSelector(({assets}) => assets.machine_inputs);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}

      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name || ''}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={(e) =>
            formik.setFieldValue(e.target.name, e.target.value || null)
          }
        />

        <MachineTypeSelect
          value={formik.values.type_id}
          label="Machine Type"
          size="small"
          fullWidth
          error={!!formik.touched.type_id && !!formik.errors.type_id}
          helperText={formik.touched.type_id && formik.errors.type_id}
          onChange={(v) => formik.setFieldValue('type_id', v)}
        />
        {hazard_ai_enabled && (
          <SiteSelect
            value={formik.values.site_id}
            size="small"
            label="Assigned Site"
            fullWidth
            error={!!formik.touched.site_id && !!formik.errors.site_id}
            helperText={formik.touched.site_id && formik.errors.site_id}
            onChange={(v) => formik.setFieldValue('site_id', v)}
          />
        )}
        {company?.commtrac_enabled || company?.proximity_enabled ? (
          <ZoneSelect
            value={formik.values.zone_id}
            label="Assigned Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />
        ) : null}

        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />

        {getIsMinVoltageVisible(
          formik.values.is_commtrac,
          formik.values.wifi_enabled
        ) ? (
          <NumberTextField
            value={
              formik.values.min_voltage
                ? formik.values.min_voltage / 10
                : formik.values.min_voltage
            }
            decimalPlaces={1}
            min={0}
            max={10}
            step={0.1}
            label="Min Voltage"
            size="small"
            name="min_voltage"
            fullWidth
            error={!!formik.touched.min_voltage && !!formik.errors.min_voltage}
            helperText={formik.touched.min_voltage && formik.errors.min_voltage}
            onChange={(v) =>
              formik.setFieldValue('min_voltage', v ? v * 10 : v)
            }
          />
        ) : null}

        {getIsMaxVoltageVisible(
          formik.values.is_commtrac,
          formik.values.wifi_enabled
        ) ? (
          <NumberTextField
            value={
              formik.values.max_voltage
                ? formik.values.max_voltage / 10
                : formik.values.max_voltage
            }
            decimalPlaces={1}
            min={0}
            max={10}
            step={0.1}
            label="Max Voltage"
            size="small"
            name="max_voltage"
            fullWidth
            error={!!formik.touched.max_voltage && !!formik.errors.max_voltage}
            helperText={formik.touched.max_voltage && formik.errors.max_voltage}
            onChange={(v) =>
              formik.setFieldValue('max_voltage', v ? v * 10 : v)
            }
          />
        ) : null}

        {formik.values.proximity_enabled ? (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">
              Proximity enabled employee
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.is_proximity}
                    onChange={() => {
                      formik.setFieldValue('is_proximity', true);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.is_proximity}
                    onChange={() => {
                      formik.setFieldValue('is_proximity', false);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <NumberTextField
            value={formik.values.external_id}
            min={1}
            max={maxProximityId}
            label="Proximity ID"
            size="small"
            name="external_id"
            fullWidth
            error={!!formik.touched.external_id && !!formik.errors.external_id}
            helperText={formik.touched.external_id && formik.errors.external_id}
            onChange={(v) => formik.setFieldValue('external_id', v)}
          />
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <TextField
            value={formik.values.events_flag}
            label="Monitor Events"
            size="small"
            name="events_flag"
            select
            fullWidth
            error={!!formik.touched.events_flag && !!formik.errors.events_flag}
            helperText={formik.touched.events_flag && formik.errors.events_flag}
            onChange={formik.handleChange}
          >
            {[
              {value: 'YES', name: 'Yes'},
              {value: 'NO', name: 'No'},
            ].map((i) => (
              <MenuItem key={i.name} value={i.value}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <TextField
            value={formik.values.input_1_type ?? ''}
            fullWidth
            name="input_1_type"
            label="Machine Input 1"
            size="small"
            select
            error={
              !!formik.touched.input_1_type && !!formik.errors.input_1_type
            }
            helperText={
              formik.touched.input_1_type && formik.errors.input_1_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <TextField
            value={formik.values.input_2_type ?? ''}
            fullWidth
            name="input_2_type"
            label="Machine Input 2"
            size="small"
            select
            error={
              !!formik.touched.input_2_type && !!formik.errors.input_2_type
            }
            helperText={
              formik.touched.input_2_type && formik.errors.input_2_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <TextField
            value={formik.values.input_3_type ?? ''}
            fullWidth
            name="input_3_type"
            label="Machine Input 3"
            size="small"
            select
            error={
              !!formik.touched.input_3_type && !!formik.errors.input_3_type
            }
            helperText={
              formik.touched.input_3_type && formik.errors.input_3_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <TextField
            value={formik.values.input_4_type ?? ''}
            fullWidth
            name="input_4_type"
            label="Machine Input 4"
            size="small"
            select
            error={
              !!formik.touched.input_4_type && !!formik.errors.input_4_type
            }
            helperText={
              formik.touched.input_4_type && formik.errors.input_4_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {formik.values.proximity_enabled && formik.values.is_proximity ? (
          <TextField
            value={formik.values.input_5_type ?? ''}
            fullWidth
            name="input_5_type"
            label="Machine Input 5"
            size="small"
            select
            error={
              !!formik.touched.input_5_type && !!formik.errors.input_5_type
            }
            helperText={
              formik.touched.input_5_type && formik.errors.input_5_type
            }
            onChange={formik.handleChange}
          >
            <MenuItem value={0}>Unassigned</MenuItem>
            {machineInputs.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}
      </Box>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          mode === 'update_from_info' ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : (
            <Button onClick={() => onCancel()}>Cancel</Button>
          )
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AssetMachineItemUpdate;
