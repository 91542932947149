import {createSelector} from 'reselect';

import {findConfiguration} from '../../utils/configuration';
import {AppState} from './types';

const appSelectors = {
  getApp: createSelector([(state: any) => state.app], (app: AppState) => app),
  getIsDarkMode: createSelector(
    [(state: any) => state.app],
    (app: AppState) => {
      if (app.printTheme) {
        return false;
      }
      if (app.myConfigurations) {
        const themeConfig = findConfiguration(
          app.myConfigurations,
          'global',
          'theme'
        );
        if (themeConfig) {
          return themeConfig.value !== 'light';
        }
      }
      return true;
    }
  ),
  getIsCompactMode: createSelector(
    [(state: any) => state.app],
    (app: AppState) => {
      if (app.printTheme) {
        return false;
      }
      if (app.myConfigurations) {
        const themeConfig = findConfiguration(
          app.myConfigurations,
          'global',
          'compact_theme'
        );
        if (themeConfig) {
          return themeConfig.value === '1';
        }
      }
      return false;
    }
  ),
};

export default appSelectors;
