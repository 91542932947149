import {LoadingButton} from '@mui/lab';
import {Box, MenuItem, TextField} from '@mui/material';
import dayjs from 'dayjs';
import last from 'lodash/last';
import map from 'lodash/map';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {MachineInput} from '../../interfaces/MachineInput';
import {saveFile} from '../../utils/file';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

const FIELD_LABELS: {
  [key in keyof MachineInput]: string;
} = {
  id: 'ID',
  name: 'Name',
  status: 'Status',
  description: 'Description',
  company_id: 'Company ID',
  allow_filter: 'Allow Filter',
};

const FIELDS = map(FIELD_LABELS, (label, value) => ({label, value}));

const MachineInputExport = () => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [fields, setFields] = useState<string[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const fetchData = async () => {
    setIsFetching(true);
    try {
      const resp = await API.get(`${apiBaseUrl}/machine-input.csv`, {
        params: {fields},
        responseType: 'blob',
      });
      saveFile(resp.data, `machine-inputs-${dayjs().format('YYYY-MM-DD')}.csv`);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <TextField
        label="Fields"
        value={!fields.length ? ['all'] : fields}
        select
        SelectProps={{
          multiple: true,
        }}
        onChange={(event) => {
          const v = event.target.value as unknown as string[];
          const isSelectAll = last(v) === 'all';
          setFields(isSelectAll ? [] : v.filter((i) => i !== 'all'));
        }}
      >
        <MenuItem value="all">All Fields</MenuItem>

        {FIELDS.map((field) => (
          <MenuItem key={field.value} value={field.value}>
            {field.label}
          </MenuItem>
        ))}
      </TextField>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          variant="contained"
          loading={isFetching}
          onClick={() => fetchData()}
        >
          Export
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default MachineInputExport;
