import {Box, Button} from '@mui/material';
import {useMemo} from 'react';

import {useAppSelector} from '../../hooks/redux';
import {EventSummaryProductValue} from '../../interfaces/Event';
import AccessControl from '../common/AccessControl';
import DataGrid, {DataGridColumn} from '../common/DataGrid';
import EventStatAckButton from './button/EventStatAckButton';

interface Props {
  productValue?: EventSummaryProductValue;
  eventDescription?: string;
  onDone?: () => void;
}

export interface EventSummaryRow {
  text: string;
  product: EventSummaryProductValue | null;
  ackAll: boolean;
}

const EventSummaryList = ({eventDescription, productValue, onDone}: Props) => {
  const myPermissions = useAppSelector(({app}) => app.me?.permissions);

  const rows = useMemo(() => {
    const rows = [];
    if (!!eventDescription && !!productValue) {
      rows.push({text: eventDescription, product: productValue, ackAll: false});
    }
    if (myPermissions?.includes('patch::/event/acknowledge-all')) {
      rows.push({text: 'ACK ALL', product: null, ackAll: true});
    }

    return rows;
  }, [eventDescription, productValue]);

  const columns: DataGridColumn<EventSummaryRow>[] = [
    {
      field: 'text',
      renderCell: ({row}) =>
        row.ackAll ? (
          <AccessControl permissions={['patch::/event/acknowledge-all']}>
            <EventStatAckButton
              onDone={onDone}
              event={row}
              component={Button}
              componentProps={{
                sx: {
                  textAlign: 'left',
                  textTransform: 'none',
                  minWidth: 'auto',
                },
              }}
            >
              {row.text}
            </EventStatAckButton>
          </AccessControl>
        ) : (
          <AccessControl
            permissions={['patch::/event/acknowledge']}
            fallback={<>{row.text}</>}
          >
            <EventStatAckButton
              onDone={onDone}
              event={row}
              component={Button}
              componentProps={{
                sx: {
                  textAlign: 'left',
                  textTransform: 'none',
                  minWidth: 'auto',
                },
              }}
            >
              {row.text}
            </EventStatAckButton>
          </AccessControl>
        ),
    },
  ];

  return (
    <Box position="relative" width={500}>
      <DataGrid rows={rows} columns={columns} showHeader={false} size="small" />
    </Box>
  );
};

export default EventSummaryList;
