import {Actions} from './actions';
import {AuthAction, AuthState} from './types';

const fetchStateFromLocalstorage = () => {
  return {
    accessToken: window.localStorage.getItem('accessToken'),
    refreshToken: window.localStorage.getItem('refreshToken'),
    passwordToken: window.localStorage.getItem('passwordToken'),
  };
};

const initialState: AuthState = {
  ...fetchStateFromLocalstorage(),
};

const authReducers = (state?: AuthState, action?: AuthAction) => {
  state = state ?? initialState;

  switch (action?.type) {
    case Actions.SetAuthTokens:
      return {...state, ...action.data};

    default:
      return state;
  }
};

export default authReducers;
