import {ReactInstance, useState} from 'react';
import {useReactToPrint} from 'react-to-print';

import reduxActions from '../redux/actions';
import {useAppDispatch} from './redux';

export const usePrint = (
  content?: ReactInstance | null
): [() => void, boolean] => {
  const reduxDispatch = useAppDispatch();
  const [isPrinting, setIsPrinting] = useState(false);

  const startPrint = () => {
    if (content) {
      reduxActions.app.setApp(reduxDispatch, {printTheme: true});
      setTimeout(() => print(), 100);
    }
  };

  const print = useReactToPrint({
    content: () => content ?? null,
    copyStyles: true,
    onBeforeGetContent: () => setIsPrinting(true),
    onBeforePrint: () => {
      reduxActions.app.setApp(reduxDispatch, {printTheme: false});
    },
    onAfterPrint: () => setIsPrinting(false),
    onPrintError: () => setIsPrinting(false),
  });

  return [startPrint, isPrinting];
};
