import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {Box, Button, Divider, IconButton, Tab, Tooltip} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useMemo,
  useState,
} from 'react';

import ModalFixed from '../common/ModalFixed';
import CommunicationNodeExport from '../communication-node/CommunicationNodeExport';
import CommunicationNodeExportExcel from '../communication-node/CommunicationNodeExportExcel';
import CommunicationNodeImport from '../communication-node/CommunicationNodeImport';
import {NodesReportData} from '../dashboard-panels/ConnectView/NodesReport';
import WifiPointExport from '../wifi-point/WifiPointExport';
import WifiPointImport from '../wifi-point/WifiPointImport';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  value?: NodesReportData;
  onSubmitted?: () => void;
}

const TABS = [
  {
    value: 'cn',
    label: 'Communicatioin Nodes',
  },
  {
    value: 'wifi',
    label: 'WiFi Points',
  },
] as const;

type TabValue = (typeof TABS)[number]['value'];

const NodeImportExportButton = <T extends ComponentType>({
  component,
  componentProps,
  children,
  value,
  onSubmitted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);
  const [activeTab, setActiveTab] = useState<TabValue>('cn');
  const selectedTab = useMemo(
    () => TABS.find((tab) => tab.value === activeTab),
    [activeTab]
  );

  return (
    <>
      <Tooltip title="Import/Export">
        <Component {...componentProps} onClick={() => setIsOpened(true)}>
          {children}
        </Component>
      </Tooltip>

      {isOpened ? (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" width="100%" gap={1.5}>
                <ImportExportIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Import/Export {selectedTab?.label}</Box>
              </Box>
              <IconButton onClick={() => setIsOpened(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <TabContext value={activeTab}>
              <Box>
                <TabList variant="fullWidth">
                  {TABS.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      value={tab.value}
                      onClick={() => setActiveTab(tab.value)}
                    />
                  ))}
                </TabList>
              </Box>

              <TabPanel value="cn" sx={{p: 0}}>
                <Box
                  display="flex"
                  flexDirection="column"
                  position="relative"
                  gap={3}
                >
                  <Divider>Export</Divider>
                  <CommunicationNodeExport />
                  <CommunicationNodeExportExcel value={value} />
                  <Divider>Import</Divider>
                  <CommunicationNodeImport
                    onSubmitted={() => {
                      setIsOpened(false);
                      onSubmitted?.();
                    }}
                  />
                </Box>
              </TabPanel>

              <TabPanel value="wifi" sx={{p: 0}}>
                <Box
                  display="flex"
                  flexDirection="column"
                  position="relative"
                  gap={3}
                >
                  <Divider>Export</Divider>
                  <WifiPointExport value={value} />
                  <Divider>Import</Divider>
                  <WifiPointImport
                    onSubmitted={() => {
                      setIsOpened(false);
                      onSubmitted?.();
                    }}
                  />
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default NodeImportExportButton;
