import {Autocomplete, TextField, TextFieldProps} from '@mui/material';
import isNil from 'lodash/isNil';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';

import {AssetHuman} from '../../interfaces/AssetHuman';
import reduxSelectors from '../../redux/selectors';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'name'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: number | null;
  nullLabel?: string;
  assetHumans?: AssetHuman[];
  onChange?: (value?: number | null, item?: AssetHuman | null) => void;
};

const AssetHumanSelect = ({
  value,
  disabled,
  fullWidth,
  size,
  nullLabel,
  assetHumans,
  onChange,
  ...props
}: Props) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const nullValue = -1;

  const options = useMemo(() => {
    if (nullLabel) {
      return [
        {
          id: nullValue,
          name: nullLabel,
        },
        ...(assetHumans ?? assets.asset_humans),
      ];
    }
    return assetHumans ?? assets.asset_humans;
  }, [assetHumans, assets.asset_humans, nullLabel]);

  const selectedOptionId = isNil(value) ? nullValue : value;
  const selectedOption = options.find((i) => i.id === selectedOptionId) ?? null;

  return disabled ? (
    <TextField
      {...props}
      value={selectedOption?.name ?? ''}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
    />
  ) : (
    <Autocomplete
      value={selectedOption ?? null}
      disabled={disabled}
      fullWidth={fullWidth}
      options={options}
      size={size}
      disableClearable={selectedOptionId === nullValue}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={(o) => o.name ?? ''}
      renderInput={(params) => (
        <TextField label="Employee" {...props} {...params} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      onChange={(_, option) => {
        const v =
          !option || isNil(option.id) || option.id === nullValue
            ? null
            : option.id;
        const item = v
          ? assets.asset_humans.find((i) => i.id === v) ?? null
          : null;
        onChange?.(v, item);
      }}
    />
  );
};

export default AssetHumanSelect;
