import {Box, Container, Typography} from '@mui/material';
import {useEffect, useState} from 'react';

import {useIndicatorPanel} from '../contexts';
import {Widget} from '../types';
import {WidgetLibraryPanel} from './drawers';
import {WidgetContainer} from './widgets/WidgetContainer';

export const MainPanel = () => {
  const {
    editMode,
    modals: {widgetLibrary},
    selectedPanel,
    panelDraftRef,
    updateModalValues,
    updateContextValue,
  } = useIndicatorPanel();
  const [widgets, setWidgets] = useState(selectedPanel?.widgets || []);
  const [layout, setLayout] = useState(selectedPanel?.layout || []);

  const onAddWidget = (widget: Widget) => {
    updateContextValue({editMode: true});
    setWidgets((prev) => [...prev, {...widget, id: Date.now()}]);
  };

  const onDeleteWidget = (id: number) => {
    setWidgets(widgets.filter((widget) => widget.id !== id));
  };

  useEffect(() => {
    if (panelDraftRef) {
      panelDraftRef.current = null;
    }
    setWidgets(selectedPanel?.widgets || []);
    setLayout(selectedPanel?.layout || []);
  }, [selectedPanel?.id]);

  useEffect(() => {
    if (panelDraftRef) {
      panelDraftRef.current = {
        ...selectedPanel!,
        widgets: widgets || [],
        layout: layout || [],
      };
    }
  }, [selectedPanel, widgets, layout]);

  useEffect(() => {
    if (!editMode) {
      setWidgets(selectedPanel?.widgets || []);
      setLayout(selectedPanel?.layout || []);
    }
  }, [editMode]);

  return (
    <Container
      disableGutters
      sx={{py: 2, display: 'flex', flex: 1}}
      maxWidth={false}
    >
      {!!widgets?.length && (
        <Box flex={1} overflow="auto">
          <WidgetContainer
            editMode={editMode}
            widgets={widgets}
            layout={layout}
            onLayoutChanged={setLayout}
            onDeleteWidget={onDeleteWidget}
          />
        </Box>
      )}
      {!widgets?.length && (
        <Box
          justifyContent="center"
          alignItems="center"
          display="flex"
          flex={1}
        >
          <Typography>No widgets.</Typography>
        </Box>
      )}
      <WidgetLibraryPanel
        open={widgetLibrary}
        addWidget={onAddWidget}
        onClose={() => updateModalValues({widgetLibrary: false})}
      />
    </Container>
  );
};
