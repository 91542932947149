/* eslint-disable no-bitwise */

import EditIcon from '@mui/icons-material/Edit';
import TheatersOutlinedIcon from '@mui/icons-material/TheatersOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import {Box, Button, IconButton, Menu, MenuItem, Tooltip} from '@mui/material';
import {useState} from 'react';

import {useAppSelector} from '../../../hooks/redux';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import {amsSensorAddressMask} from '../../../interfaces/GasMonitoringNode';

interface Props {
  item: AMSEModuleNode;
  items?: AMSEModuleNode[];
  onOpenItem?: (id: number, type: 'e-module') => void;
  onOpenHistory?: (
    id: number,
    type:
      | 'amsEmoduleInstallationHistory'
      | 'amsEmoduleSensorHistory'
      | 'amsEmoduleCalibration'
  ) => void;
}

const EModuleTooltipContent = ({
  item,
  items,
  onOpenItem,
  onOpenHistory,
}: Props) => {
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  if ((items?.length ?? 0) > 1) {
    return (
      <>
        {items?.map((it, no) => {
          return (
            <Box key={it.id}>
              {no + 1}. SN: {it.serial_number}, Type:{' '}
              {typeIdLabels?.[it?.type ?? 255] ?? '-'}
            </Box>
          );
        })}
      </>
    );
  } else if ((items?.length ?? 0) < 2) {
    return (
      <Box display="flex" flexDirection="column" gap={0.5}>
        <Box>eModule SN: {item.serial_number}</Box>
        <Box>Type: {typeIdLabels?.[item?.type ?? 255] ?? '-'}</Box>
        <Box>Sentro 1 Sensor Name: {item.ams?.name ?? '-'}</Box>
        <Box>
          Sentro 1 Network ID:{' '}
          {item?.ams?.commtrac_external_id
            ? item?.ams?.commtrac_external_id & amsSensorAddressMask
            : ''}
        </Box>
        <Box>Installed Date: {item.date_installation ?? '-'}</Box>
        <Box>Calibration Date: {item.date_calibration ?? '-'}</Box>
        <Box>Calibration Value: {item.calibration_value ?? '-'}</Box>
        {(item.e_410 || item.e_408) && (
          <Box display="flex" alignItems="center">
            EModule Conf:
            {item.e_410 ? (
              <Tooltip title="e-Module Unregistered">
                <TheatersOutlinedIcon />
              </Tooltip>
            ) : item.e_408 ? (
              <Tooltip title="e-Module Calibration Date Expired">
                <TimerOutlinedIcon color="error" />
              </Tooltip>
            ) : (
              ' Ok'
            )}
          </Box>
        )}

        <Box display="flex" my={1}>
          <Box position="relative">
            <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
              History
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onBackdropClick={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => {
                  onOpenHistory?.(item?.id, 'amsEmoduleSensorHistory');
                  setAnchorEl(null);
                }}
              >
                View Installation History
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onOpenHistory?.(item?.id, 'amsEmoduleSensorHistory');
                  setAnchorEl(null);
                }}
              >
                View Sensor History
              </MenuItem>
              <MenuItem
                onClick={() => {
                  onOpenHistory?.(item?.id, 'amsEmoduleSensorHistory');
                  setAnchorEl(null);
                }}
              >
                View Calibration History
              </MenuItem>
            </Menu>
          </Box>
          <IconButton
            sx={{
              marginLeft: 'auto',
            }}
            size="small"
            onClick={() => onOpenItem?.(item.id, 'e-module')}
          >
            <EditIcon sx={{color: (theme) => theme.palette.primary.dark}} />
          </IconButton>
        </Box>
      </Box>
    );
  }
  return null;
};

export default EModuleTooltipContent;
