import {Box, Grid, Typography} from '@mui/material';
import {FC} from 'react';

import {AdminGeoFence} from '../../components/admin/AdminGeoFence';
import {DashboardLayout} from '../../layouts/DashboardLayout';

export const AdminGeoFencePage: FC = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <Box p={2} display="flex" flexDirection="column">
            <Typography
              id="modal-modal-title"
              component="h1"
              variant="h5"
              sx={{
                mb: 2,
              }}
            >
              Administration - Geo Fence
            </Typography>

            <AdminGeoFence />
          </Box>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};
