import {InputProps, MenuItem, TextField, TextFieldProps} from '@mui/material';
import _ from 'lodash';
import {useMemo} from 'react';

type Props = TextFieldProps & {
  entity?: string;
};

export type MessageType =
  | 'location'
  | 'mo_message'
  | 'mt_message'
  | 'check_in_out'
  | 'check_in_out_ack'
  | 'configuration'
  | 'network_ack'
  | 'message_read'
  | 'broadcast_response';

const employeeMessageTypes = [
  {value: 'location', label: 'Location'},
  {value: 'mo_message', label: 'Employee Originated Text'},
  {value: 'mt_message', label: 'Employee Terminated Text'},
  {value: 'check_in_out', label: 'Check In/Out'},
  {value: 'check_in_out_ack', label: 'Check In/Out Ack'},
  {value: 'configuration', label: 'Configuration'},
  {value: 'network_ack', label: 'Network Ack'},
  {value: 'message_read', label: 'Message Read'},
  {value: 'broadcast_response', label: 'Broadcast Response'},
];

const assetMessageType = [
  {value: 'location', label: 'Location'},
  {value: 'configuration', label: 'Configuration'},
  {value: 'network_ack', label: 'Network Ack'},
];

const nodeMessageType = [
  {value: 'heartbeat', label: 'Heartbeat'},
  {value: 'configuration', label: 'Configuration'},
  {value: 'network_ack', label: 'Network Ack'},
];

const employeeAssetByNodeMessageType = [
  {value: 'location', label: 'Location'},
  {value: 'check_in_out', label: 'Check In/Out'},
];

export const MessageTypeSelectLegacy = ({entity, ...props}: Props) => {
  const handleChange: InputProps['onChange'] = (event: any) => {
    const isSelectAll = _.last(event.target.value) === 'all';
    event.target.value = isSelectAll
      ? []
      : event.target.value.filter((i: any) => i !== 'all');
    props.onChange?.(event);
  };

  const options = useMemo(() => {
    if (entity === 'asset') {
      return assetMessageType;
    } else if (entity === 'node') {
      return nodeMessageType;
    } else if (entity === 'employeeAssetByNode') {
      return employeeAssetByNodeMessageType;
    }
    return employeeMessageTypes;
  }, [entity]);

  return (
    <TextField
      fullWidth
      label="Event Types"
      {...props}
      select
      SelectProps={{
        multiple: true,
      }}
      value={!(props.value as string[]).length ? ['all'] : props.value}
      onChange={handleChange}
    >
      <MenuItem value="all">All Events</MenuItem>

      {options.map((option) => (
        <MenuItem key={option.value ?? ''} value={option.value ?? ''}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
