import {Box, ListItem, Modal, Typography} from '@mui/material';
import {ReactNode, useState} from 'react';

import {PanelCode} from '../../utils/panels';
import DashboardPanelSelectInline from './DashboardPanelSelectInline';

interface Propx {
  component?: React.FC;
  componentProps?: any;
  onUpdate?: (value: PanelCode) => void;
  children?: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 0,
  overflow: 'auto',
  maxHeight: '100%',
};

export const DashboardPanelSelectButton = ({
  component = ListItem,
  componentProps = {},
  onUpdate,
  children,
}: Propx) => {
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Modal
        open={isOpened}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{
              mb: 2,
              px: 2,
              pt: 2,
            }}
          >
            Select Panel
          </Typography>

          <DashboardPanelSelectInline
            onUpdate={(v) => {
              onUpdate?.(v);
              closeModal();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
