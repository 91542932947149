import {useAppSelector} from './redux';

export const useGetMinerNetworkId = () => {
  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );

  const getMinerNetworkId = (commtracExternalId?: number | null) => {
    if (commtracExternalId && minerAddressMask) {
      // eslint-disable-next-line no-bitwise
      return commtracExternalId & minerAddressMask;
    }
  };
  return getMinerNetworkId;
};
