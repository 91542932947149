import {Box} from '@mui/material';

import {SafeyeNode} from '../../../interfaces/SafeyeNode';

const TooltipRow = ({
  label,
  value = '',
}: {
  label: string;
  value?: string | number | null;
}) => {
  return (
    <Box>
      <Box component="span" sx={{fontWeight: 600}}>
        {label}:
      </Box>{' '}
      {value}
    </Box>
  );
};

interface Props {
  item: SafeyeNode;
}

const SafeyeNodeTooltipContent = ({item}: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      {item?.asset_name ? (
        <TooltipRow label="Name" value={item.asset_name} />
      ) : (
        <TooltipRow label="Camera Name" value={item.name} />
      )}
      <TooltipRow label="Last report" value={item.timestamp} />
      <TooltipRow
        label="Location"
        value={`lat:${item.latitude} lon:${item.longitude}`}
      />
      <TooltipRow label="Speed" value={item.speed} />
      <TooltipRow
        label="Hazards"
        value={`People: ${item.hazard_person}; Machines: ${item.hazard_vehicle}`}
      />
      <TooltipRow
        label="Warnings"
        value={`People: ${item.warning_person}; Machines: ${item.warning_vehicle}`}
      />
    </Box>
  );
};

export default SafeyeNodeTooltipContent;
