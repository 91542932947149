import LanguageIcon from '@mui/icons-material/Language';
import {LoadingButton} from '@mui/lab';
import {Box, Card, CardContent, Link, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  PasswordTokenInputBody,
  PasswordTokenResponse,
} from '../../interfaces/PasswordToken';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  onSubmitted?: () => void;
}

export const PasswordResetRequest = ({onSubmitted}: Props) => {
  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async () => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/password/token`;
      await API.post<PasswordTokenResponse>(endpoint, formik.values);

      const message = `Please check your email to reset your password`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      username: yup.string().nullable().required('Username is required'),
    };

    return yup.object().shape(fields);
  }, []);

  const getFormikValues = (): Partial<PasswordTokenInputBody> => ({
    username: null,
  });

  const formik = useFormik<Partial<PasswordTokenInputBody>>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async () => {
      await submitData();
    },
  });

  return (
    <Box
      component="form"
      maxWidth={410}
      width="100%"
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardContent>
          <Box textAlign="center">
            <Box>
              <LanguageIcon sx={{fontSize: 40}} />
            </Box>

            <Box fontSize={24}>Reset Password</Box>

            <Box color="text.secondary">Reset your password by username</Box>
          </Box>

          <Box my={2}>
            <TextField
              value={formik.values.username ?? ''}
              fullWidth
              name="username"
              label="Username"
              error={!!formik.touched.username && !!formik.errors.username}
              helperText={formik.touched.username && formik.errors.username}
              onChange={formik.handleChange}
            />
          </Box>

          <Box mt={2}>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={submittedInProgress}
              type="submit"
            >
              Reset Password
            </LoadingButton>

            <Box mt={1.5}>
              <Link component={RouterLink} underline="hover" to="/auth/signin">
                Back to Log In
              </Link>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
