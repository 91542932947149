/* eslint-disable @typescript-eslint/no-unused-vars */
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {Box, Button, IconButton, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import AMSEmoduleItemPurgeModal from '../../ams-emodule/AMSEmoduleItemPurgeModal';
import AccessControl from '../../common/AccessControl';
import AMSEModuleActivationModal from './AMSEModuleActivationModal';
type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: AMSEModuleNode;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onClose?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'amsEmoduleSensorHistory' | 'amsEmoduleCalibration'
  ) => void;
}

const PurgeActionTypes = {
  purgeDevice: 'device',
  purgeData: 'data',
} as const;

type Action =
  | 'checkin'
  | 'activation'
  | 'purgeDevice'
  | 'purgeData'
  | 'purgeChat'
  | 'checkInOut';

const AMSEModuleActionsButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onSubmitted,
  onPurged,
  onOpenHistory,
  onClose,
}: Props<T>) => {
  const [action, setAction] = useState<Action>();
  const isPurgeAction = action === 'purgeDevice' || action === 'purgeData';
  const purgeType = isPurgeAction ? PurgeActionTypes[action] : null;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const [anchorElMore, setAnchorElMore] = useState<null | HTMLElement>(null);
  const menuOpenMore = Boolean(anchorElMore);

  return (
    <>
      <Box display="flex" justifyContent="start" gap={0.5}>
        <Box position="relative">
          <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
            History
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onBackdropClick={() => setAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                onOpenHistory?.(item?.id, 'amsEmoduleSensorHistory');
                onClose?.();
              }}
            >
              View Sensor History
            </MenuItem>
            <MenuItem
              onClick={() => {
                onOpenHistory?.(item?.id, 'amsEmoduleCalibration');
                onClose?.();
              }}
            >
              View Calibration History
            </MenuItem>
          </Menu>
        </Box>
        <IconButton
          onClick={(event) => setAnchorElMore(event.currentTarget)}
          size="small"
          color="primary"
        >
          <MoreHorizIcon />
        </IconButton>
        <Menu
          anchorEl={anchorElMore}
          open={menuOpenMore}
          onBackdropClick={() => setAnchorElMore(null)}
        >
          <AccessControl permissions={['patch::/ams/emodule/:id(\\d+)']}>
            <MenuItem
              onClick={() => {
                setAction('activation');
                setAnchorEl(null);
              }}
            >
              {item?.status === 'active' ? (
                <>
                  <CloseIcon fontSize="small" sx={{mr: 1.5}} />
                  DeActivate
                </>
              ) : (
                <>
                  <CheckIcon fontSize="small" sx={{mr: 1.5}} />
                  Activate
                </>
              )}
            </MenuItem>
          </AccessControl>
          <MenuItem
            onClick={() => {
              setAction('purgeDevice');
              setAnchorEl(null);
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
            Purge Device
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAction('purgeData');
              setAnchorEl(null);
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
            Purge Transaction
          </MenuItem>
        </Menu>
      </Box>
      {action === 'activation' ? (
        <AMSEModuleActivationModal
          item={item}
          open={action === 'activation'}
          onClose={() => setAction(undefined)}
          onSubmitted={onSubmitted}
        />
      ) : purgeType ? (
        <AMSEmoduleItemPurgeModal
          item={item}
          action={purgeType}
          open={!!purgeType}
          onClose={() => setAction(undefined)}
          onSubmitted={onPurged}
        />
      ) : null}
    </>
  );
};

export default AMSEModuleActionsButton;
