import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import update from 'immutability-helper';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
} from 'react';

import {PanelFilterParams} from '../../../interfaces/Dashboard';

export interface MenuState {
  visible: boolean;
  mouseX: number;
  mouseY: number;
  params?: PanelFilterParams;
}

interface MenuContextInterface {
  menuState: MenuState;
  setMenuState: Dispatch<SetStateAction<MenuState>> | null;
}

const MenuContext = createContext<MenuContextInterface>({
  menuState: {visible: false, mouseX: 0, mouseY: 0},
  setMenuState: null,
});

interface Props extends PropsWithChildren {
  onItemClick: (
    state: MenuState,
    type: 'history' | 'interactions' | 'summary'
  ) => void;
  menuState: MenuState;
  setMenuState: Dispatch<SetStateAction<MenuState>> | null;
}

export default function DrilldownMenu({
  children,
  onItemClick,
  menuState,
  setMenuState,
}: Props) {
  const handleClick = (type: 'history' | 'interactions' | 'summary') => {
    onItemClick(menuState, type);
    handleClose();
  };

  const handleClose = () => {
    setMenuState?.(
      update(menuState, {
        visible: {
          $set: false,
        },
      })
    );
  };

  return (
    <>
      <MenuContext.Provider value={{menuState, setMenuState}}>
        {children}
        <Menu
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={{top: menuState.mouseY, left: menuState.mouseX}}
          open={menuState.visible}
        >
          <MenuItem onClick={() => handleClick('history')}>
            View Machine History
          </MenuItem>
          <MenuItem onClick={() => handleClick('interactions')}>
            View Machine Interactions
          </MenuItem>
          <MenuItem onClick={() => handleClick('summary')}>
            View Machine Summary
          </MenuItem>
        </Menu>
      </MenuContext.Provider>
    </>
  );
}

export const useDrilldownMenu = () => useContext(MenuContext);
