import * as yup from 'yup';

import {
  //getIsEmployeeAtestVisible,
  getIsEmployeeHasMotionSensorVisible,
  getIsEmployeeMc2FlagVisible,
  //getIsEmployeeSensorNumberVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsProximityFieldsVisible as getIsProximityFieldVisible,
} from '../../utils/commtrac-nodes';
import {
  getMaxLengthMessage,
  getMaxMessage,
  getMinMessage,
  nameMessage,
} from './consts';
import {
  maxDescriptionLength,
  maxNameLength,
  maxPersonIdLength,
  maxProximityId,
  nameValidator,
} from './utils';

export const commtracNodeMinerAcknowledgeInputSchema = yup.object().shape({
  is_proximity: yup.boolean().required(),
  assign_to_human: yup.boolean().required(),
  asset_human_id: yup
    .number()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => assign_to_human,
      then: (schema) => schema.required('Field is required'),
    }),
  wifi_enabled: yup
    .boolean()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => !assign_to_human,
      then: (schema) => schema.required('Field is required'),
    }),
  nickname: yup
    .string()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => !assign_to_human,
      then: (schema) =>
        schema
          .matches(nameValidator.regex, nameMessage)
          .required('Field is required')
          .min(1)
          .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
    }),
  first_name: yup
    .string()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => !assign_to_human,
      then: (schema) =>
        schema
          .matches(nameValidator.regex, nameMessage)
          .required('Field is required')
          .min(1)
          .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
    }),
  last_name: yup
    .string()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => !assign_to_human,
      then: (schema) =>
        schema
          .matches(nameValidator.regex, nameMessage)
          .required('Field is required')
          .min(1)
          .max(maxNameLength, getMaxLengthMessage(maxNameLength)),
    }),
  birth_date: yup.date().typeError('Field should be valid date').nullable(),
  description: yup
    .string()
    .nullable()
    .min(1)
    .max(maxDescriptionLength, getMaxLengthMessage(maxDescriptionLength)),
  type_id: yup
    .number()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => !assign_to_human,
      then: (schema) => schema.required('Field is required'),
    }),
  zone_id: yup
    .number()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => !assign_to_human,
      then: (schema) => schema.required('Field is required'),
    }),
  shift_id: yup
    .number()
    .nullable()
    .when('assign_to_human', {
      is: (assign_to_human: boolean) => !assign_to_human,
      then: (schema) => schema.nullable().required('Field is required'),
    }),
  status: yup.string().when('assign_to_human', {
    is: (assign_to_human: boolean) => !assign_to_human,
    then: (schema) => schema.required('Field is required'),
  }),
  personal_id: yup
    .string()
    .nullable()
    .min(1)
    .max(maxPersonIdLength, getMaxLengthMessage(maxPersonIdLength)),
  external_id: yup
    .number()
    .nullable()
    .when(['assign_to_human', 'is_proximity'], {
      is: (assign_to_human: boolean, is_proximity: boolean) =>
        !assign_to_human && is_proximity,
      then: (schema) =>
        schema
          .required()
          .integer()
          .min(1, getMinMessage(1))
          .max(maxProximityId, getMaxMessage(maxProximityId)),
    }),
  mc2_flag: yup
    .number()
    .integer()
    .min(0)
    .max(1)
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsEmployeeMc2FlagVisible(true, wifi_enabled),
      then: (schema) => schema.required('Field is required'),
    }),
  /*atest: yup
    .number()
    .integer()
    .min(0)
    .max(1)
    .nullable()
    .when('wifi_enabled', {
      is: (wifi_enabled: boolean | null) =>
        getIsEmployeeAtestVisible(true, wifi_enabled),
      then: (schema) => schema.required('Field is required'),
    }),
  sensors_number: yup
    .number()
    .nullable()
    .when(['wifi_enabled', 'atest'], {
      is: (wifi_enabled: boolean | null, atest: 0 | 1 | null) =>
        getIsEmployeeSensorNumberVisible(true, wifi_enabled, atest),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(4, getMaxMessage(4))
          .nullable()
          .required('Field is required'),
    }),*/
  has_motion_sensor: yup
    .number()
    .integer()
    .min(0)
    .max(1)
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsEmployeeHasMotionSensorVisible(true, wifi_enabled),
      then: (schema) => schema.required('Field is required'),
    }),
  min_voltage: yup
    .number()
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsMinVoltageVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMinMessage(100 / 10))
          .required('Field is required'),
    }),
  max_voltage: yup
    .number()
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsMaxVoltageVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMinMessage(100 / 10))
          .required('Field is required'),
    }),
});

export const commtracNodeAssetAcknowledgeInputSchema = yup.object().shape({
  proximity_enabled: yup.boolean().required(),
  wifi_enabled: yup.boolean().nullable(),
  assign_to_machine: yup.boolean().required(),
  is_proximity_enabled: yup.boolean().required(),
  asset_machine_id: yup
    .number()
    .nullable()
    .when(['assign_to_machine'], {
      is: true,
      then: (schema) =>
        schema.integer().positive().required('Field is required'),
    }),
  name: yup
    .string()
    .nullable()
    .when(['assign_to_machine'], {
      is: false,
      then: (schema) =>
        schema
          .min(1)
          .matches(nameValidator.regex, nameMessage)
          .max(maxNameLength, getMaxLengthMessage(maxNameLength))
          .required('Field is required'),
    }),
  type_id: yup
    .number()
    .nullable()
    .when(['assign_to_machine'], {
      is: false,
      then: (schema) => schema.required('Field is required'),
    }),
  zone_id: yup
    .number()
    .nullable()
    .when(['assign_to_machine'], {
      is: false,
      then: (schema) => schema.required('Field is required'),
    }),
  status: yup
    .string()
    .nullable()
    .when(['assign_to_machine'], {
      is: false,
      then: (schema) => schema.required('Field is required'),
    }),
  external_id: yup
    .number()
    .nullable()
    .integer()
    .min(1, getMinMessage(1))
    .max(maxProximityId, getMaxMessage(maxProximityId)),
  events_flag: yup
    .string()
    .nullable()
    .when(['assign_to_machine', 'is_proximity_enabled', 'proximity_enabled'], {
      is: (
        assign_to_machine: boolean | null,
        is_proximity_enabled: boolean,
        proximity_enabled: boolean
      ) =>
        getIsProximityFieldVisible(
          assign_to_machine,
          is_proximity_enabled,
          proximity_enabled
        ),
      then: (schema) =>
        schema.oneOf(['YES', 'NO']).required('Field is required'),
    }),
  min_voltage: yup
    .number()
    .nullable()
    .when('wifi_enabled', {
      is: (wifi_enabled: boolean | null) =>
        getIsMinVoltageVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMinMessage(100 / 10))
          .required('Field is required'),
    }),
  max_voltage: yup
    .number()
    .nullable()
    .when(['wifi_enabled'], {
      is: (wifi_enabled: boolean | null) =>
        getIsMaxVoltageVisible(true, wifi_enabled),
      then: (schema) =>
        schema
          .integer()
          .min(0, getMinMessage(0))
          .max(100, getMinMessage(100 / 10))
          .required('Field is required'),
    }),
  input_1_type: yup
    .number()
    .nullable()
    .when(['assign_to_machine', 'is_proximity_enabled', 'proximity_enabled'], {
      is: (
        assign_to_machine: boolean | null,
        is_proximity_enabled: boolean,
        proximity_enabled: boolean
      ) =>
        getIsProximityFieldVisible(
          assign_to_machine,
          is_proximity_enabled,
          proximity_enabled
        ),
      then: (schema) => schema.integer().min(0).required('Field is required'),
    }),
  input_2_type: yup
    .number()
    .nullable()
    .when(['assign_to_machine', 'is_proximity_enabled', 'proximity_enabled'], {
      is: (
        assign_to_machine: boolean | null,
        is_proximity_enabled: boolean,
        proximity_enabled: boolean
      ) =>
        getIsProximityFieldVisible(
          assign_to_machine,
          is_proximity_enabled,
          proximity_enabled
        ),
      then: (schema) => schema.integer().min(0).required('Field is required'),
    }),
  input_3_type: yup
    .number()
    .nullable()
    .when(['assign_to_machine', 'is_proximity_enabled', 'proximity_enabled'], {
      is: (
        assign_to_machine: boolean | null,
        is_proximity_enabled: boolean,
        proximity_enabled: boolean
      ) =>
        getIsProximityFieldVisible(
          assign_to_machine,
          is_proximity_enabled,
          proximity_enabled
        ),
      then: (schema) => schema.integer().min(0).required('Field is required'),
    }),
  input_4_type: yup
    .number()
    .nullable()
    .when(['assign_to_machine', 'is_proximity_enabled', 'proximity_enabled'], {
      is: (
        assign_to_machine: boolean | null,
        is_proximity_enabled: boolean,
        proximity_enabled: boolean
      ) =>
        getIsProximityFieldVisible(
          assign_to_machine,
          is_proximity_enabled,
          proximity_enabled
        ),
      then: (schema) => schema.integer().min(0).required('Field is required'),
    }),
  input_5_type: yup
    .number()
    .nullable()
    .when(['assign_to_machine', 'is_proximity_enabled', 'proximity_enabled'], {
      is: (
        assign_to_machine: boolean | null,
        is_proximity_enabled: boolean,
        proximity_enabled: boolean
      ) =>
        getIsProximityFieldVisible(
          assign_to_machine,
          is_proximity_enabled,
          proximity_enabled
        ),
      then: (schema) => schema.integer().min(0).required('Field is required'),
    }),
});

export const commtracNodeMinerReassignInputSchema = yup.object().shape({
  asset_human_id: yup.number().nullable().required().positive().integer(),
});

export const commtracNodeAssetReassignInputSchema = yup.object().shape({
  asset_machine_id: yup.number().nullable().required().positive().integer(),
});
