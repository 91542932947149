export const LOGO: {
  [k: string]: any;
} = {
  black: require('../asset/logo/Strata-SafeSite-Logo.png'),
  white: require('../asset/logo/Strata-SafeSite-Logo-white.png'),
  black_new: require('../asset/logo/Strata-SafeSite-Logo-new.png'),
  white_new: require('../asset/logo/Strata-SafeSite-Logo-white-new.png'),
  black_top: require('../asset/logo/Strata-SafeSite-Logo-top.png'),
  white_top: require('../asset/logo/Strata-SafeSite-Logo-top-white.png'),
};
