import {ReactNode} from 'react';

import {Permission} from '../../../utils/acl';
import {PanelProductCode} from '../../../utils/panels';
import {useGetHasAccess} from './utils';

interface Props {
  roles?: number[];
  products?: PanelProductCode[];
  permissions?: Permission[];
  accessChecker?: () => boolean;
  fallback?: ReactNode;
  children?: ReactNode;
}

const AccessControl = ({
  roles,
  products,
  permissions,
  accessChecker,
  fallback,
  children,
}: Props) => {
  const getHasAccess = useGetHasAccess();
  const hasAccess = getHasAccess({roles, products, permissions, accessChecker});
  if (!hasAccess) {
    return <>{fallback}</>;
  }
  return <>{children}</>;
};

export default AccessControl;
