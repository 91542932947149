/* eslint-disable complexity */
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import Image from 'mui-image';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import SensorIcon from '../../../images/ams/sensors/icon_sensor.svg';
import {
  AMSSensorExtendInputBody,
  GasMonitoringNode,
} from '../../../interfaces/GasMonitoringNode';
import reduxActions from '../../../redux/actions';
import reduxSelectors from '../../../redux/selectors';
import {amsNodeBaseInputSchema} from '../../../scheme/yup/ams-node';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import SnackbarMessages from '../../common/SnackbarMessages';
import StatusSelect from '../../selectors/StatusSelect';
import {ZoneSelect} from '../../selectors/ZoneSelect';

interface Props {
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: () => void;
  onSubmitted?: (item: GasMonitoringNode) => void;
}

const AMSSensorItemCreate = ({
  locationCoordinates,
  isActiveModal,
  onCancel,
  onSubmitted,
}: Props) => {
  /**********/
  /* submit */
  /**********/

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const ams_nodes = assets.ams_nodes;
  const ams_emodules = assets.ams_emodules;
  const ams_emoduled_enable_selected = useMemo(
    () =>
      ams_emodules.map((it) => ({id: it.id, type: it.type, commtrac_external_id: it.commtrac_external_id, serial_number: it.serial_number}))
      .filter((item) => ams_nodes.filter((sn) => sn.sensor_serial_number === item.serial_number && sn.commtrac_external_id === item.commtrac_external_id).length === 0
      ),
    [ams_emodules, ams_nodes]
  );
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);

  const ava_nodes = useMemo(() => {
    return ams_nodes?.filter((it) => !!it?.ava);
  }, [ams_nodes]);

  const [assignAVAID, setAssignAVAID] = useState<string[] | undefined>([]);

  const reduxDispatch = useDispatch();
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: AMSSensorExtendInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams`;
      const resp = await API.post<GasMonitoringNode>(endpoint, data);
      const message = 'Sensor Node successfully created';
      if (!data?.ava && !data?.intrinsically_safe) {
        // Assign AVA
        if (assignAVAID?.length) {
          await API.patch<GasMonitoringNode>(
            `${apiBaseUrl}/ams/${resp.data.id}/ava`,
            {
              ava_commtrac_external_id: assignAVAID,
              assign: true,
            }
          );
        }
      }
      await reduxActions.assets.fetchGasMonitorNodes(reduxDispatch);
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (): AMSSensorExtendInputBody => ({
    name: null,
    pos_lat: locationCoordinates?.lat
      ? +locationCoordinates.lat.toFixed(6)
      : null,
    pos_lon: locationCoordinates?.lng
      ? +locationCoordinates.lng.toFixed(6)
      : null,
    status: 'active',
    sensor_serial_number: null,
    zone_id: null,
    commtrac_external_id: null,
    re_commtrac_external_id: null,
    intrinsically_safe: 0,
    ava: 0,
    non_sensing: 0,
  });

  const formik = useFormik<AMSSensorExtendInputBody>({
    initialValues: getFormikValues(),
    validationSchema: amsNodeBaseInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    if (isActiveModal && locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.pos_lat !== newPosLat ||
        formik.values.pos_lon !== newPosLong
      ) {
        formik.setFieldValue('pos_lat', newPosLat);
        formik.setFieldValue('pos_lon', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <Image src={SensorIcon} alt="heat map" width={26} height={18} />
          <Box fontSize={24}>Create Sensor</Box>
        </Box>
        {onCancel ? (
          <IconButton onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.name ?? ''}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <ZoneSelect
            value={formik.values.zone_id}
            label="Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            fullWidth
            label="Associated eModule"
            select
            size="small"
            SelectProps={{
              multiple: false,
            }}
            value={formik.values.sensor_serial_number}
            onChange={(el) => {
              formik.setFieldValue('sensor_serial_number', el.target.value);
            }}
            error={
              !!formik.touched.sensor_serial_number &&
              !!formik.errors.sensor_serial_number
            }
            helperText={
              formik.touched.sensor_serial_number &&
              formik.errors.sensor_serial_number
            }
          >
            {ams_emoduled_enable_selected
              ?.map((it) => ({
                id: it.id,
                serial_number: it?.serial_number,
                type: it?.type,
              }))
              .map((item) => (
                <MenuItem key={item.id ?? ''} value={item.serial_number ?? ''}>
                  {`${item.serial_number}-${!_.isNull(item.type) ? typeIdLabels?.[item.type] : 'None'}`}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          {' '}
          <TextField
            value={formik.values.commtrac_external_id ?? ''}
            label="Network ID"
            size="small"
            name="commtrac_external_id"
            fullWidth
            error={
              !!formik.touched.commtrac_external_id &&
              !!formik.errors.commtrac_external_id
            }
            helperText={
              formik.touched.commtrac_external_id &&
              formik.errors.commtrac_external_id
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          {' '}
          <TextField
            value={formik.values.re_commtrac_external_id ?? ''}
            label="Confirm Network ID"
            size="small"
            name="re_commtrac_external_id"
            fullWidth
            error={
              !!formik.touched.re_commtrac_external_id &&
              !!formik.errors.re_commtrac_external_id
            }
            helperText={
              formik.touched.re_commtrac_external_id &&
              formik.errors.re_commtrac_external_id
            }
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.pos_lat ?? ''}
            label="Latitude (click on map or enter manually)"
            size="small"
            name="pos_lat"
            type="number"
            fullWidth
            error={!!formik.touched.pos_lat && !!formik.errors.pos_lat}
            helperText={formik.touched.pos_lat && formik.errors.pos_lat}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.pos_lon ?? ''}
            label="Longitude (click on map or enter manually)"
            size="small"
            name="pos_lon"
            type="number"
            fullWidth
            error={!!formik.touched.pos_lon && !!formik.errors.pos_lon}
            helperText={formik.touched.pos_lon && formik.errors.pos_lon}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <FormControl disabled={formik.values.ava === 1}>
            <FormLabel id="demo-form-control-label-placement">
              Intrinsically Safe
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.intrinsically_safe}
                    onChange={() => {
                      formik.setFieldValue('ava', 0);
                      formik.setFieldValue('non_sensing', 0);
                      formik.setFieldValue('intrinsically_safe', 1);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.intrinsically_safe}
                    onChange={() => {
                      formik.setFieldValue('intrinsically_safe', 0);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <FormControl disabled={formik.values.intrinsically_safe === 1}>
            <FormLabel id="ava-label-placement">AVA</FormLabel>
            <RadioGroup
              row
              aria-labelledby="ava-label-placement"
              name="ava"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.ava}
                    onChange={() => {
                      formik.setFieldValue('ava', 1);
                      formik.setFieldValue('intrinsically_safe', 0);
                    }}
                    value="true"
                    name="ava-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.ava}
                    onChange={() => {
                      formik.setFieldValue('ava', 0);
                    }}
                    value="true"
                    name="ava-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <FormControl
            disabled={
              formik.values.intrinsically_safe === 1 || formik.values.ava === 0
            }
          >
            <FormLabel id="non-sensing-label-placement">Non Sensing</FormLabel>
            <RadioGroup
              row
              aria-labelledby="non-sensing-label-placement"
              name="non-sensing"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.non_sensing}
                    onChange={() => {
                      formik.setFieldValue('non_sensing', 1);
                    }}
                    value="true"
                    name="non_sensing-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.non_sensing}
                    onChange={() => {
                      formik.setFieldValue('non_sensing', 0);
                    }}
                    value="true"
                    name="non_sensing-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        {!formik.values.ava && !formik.values.intrinsically_safe && (
          <Grid item xs={12} lg={12}>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                label="AVAs"
                select
                size="small"
                value={assignAVAID}
                SelectProps={{
                  multiple: true,
                }}
                onChange={(el) => {
                  const v = el.target.value as unknown as string[];
                  setAssignAVAID(v);
                }}
              >
                {ava_nodes
                  ?.map((it) => ({
                    id: it?.commtrac_external_id,
                    name: it?.name,
                  }))
                  .map((option) => (
                    <MenuItem key={option.id ?? ''} value={option.id ?? ''}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
          </Grid>
        )}
      </Grid>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AMSSensorItemCreate;
