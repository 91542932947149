import {Button} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {Shift} from '../../interfaces/Shift';
import ModalFixed from '../common/ModalFixed';
import ShiftItemUpsert from './ShiftItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: Shift;
  prefetch?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: (item: Shift) => void;
}

const ShiftItemUpsertButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  onSubmitted,
  ...props
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component {...componentProps} onClick={() => setIsOpened(true)}>
        {children}
      </Component>

      {isOpened && (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <ShiftItemUpsert
            {...props}
            onSubmitted={(item) => {
              onSubmitted?.(item);
              setIsOpened(false);
            }}
            onClose={() => setIsOpened(false)}
          />
        </ModalFixed>
      )}
    </>
  );
};

export default ShiftItemUpsertButton;
