import AddIcon from '@mui/icons-material/Add';
import LibraryIcon from '@mui/icons-material/DragIndicatorOutlined';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import SaveIcon from '@mui/icons-material/SaveOutlined';
import {Button, Divider, Grid} from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import {useNavigate} from 'react-router-dom';

import {useIndicatorPanel} from '../contexts';
import {DateRangePicker} from './DateRangePicker';
import {PanelSelector} from './PanelSelector';

export const TopBar = () => {
  const navigate = useNavigate();
  const {editMode, savePanel, updateModalValues, updateContextValue} =
    useIndicatorPanel();

  return (
    <Grid container spacing={1}>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-end"
        spacing={2}
      >
        <Grid item xs>
          <PanelSelector />
        </Grid>
        <Grid item display={editMode ? 'flex' : 'none'}>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{textTransform: 'none'}}
            onClick={() => savePanel()}
          >
            Save
          </Button>
        </Grid>
        <Grid item display={editMode ? 'flex' : 'none'}>
          <Button
            variant="outlined"
            sx={{textTransform: 'none'}}
            onClick={() => updateContextValue({editMode: false})}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item display={!editMode ? 'flex' : 'none'}>
          <Button
            variant="outlined"
            startIcon={<EditIcon />}
            sx={{textTransform: 'none'}}
            onClick={() => updateContextValue({editMode: true})}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <Divider sx={{width: '100%'}} />
      </Grid>
      <Grid item container spacing={2} xs={6}>
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{textTransform: 'none'}}
            onClick={() => navigate('/indicator/widget')}
          >
            Create Widget
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            startIcon={<LibraryIcon />}
            sx={{textTransform: 'none'}}
            onClick={() => updateModalValues({widgetLibrary: true})}
          >
            Add from library
          </Button>
        </Grid>
      </Grid>
      <Grid item container justifyContent="flex-end" spacing={2} xs={6}>
        <Grid item>
          <DateRangePicker
            onChange={() => {}}
            date={{
              startDate: dayjs().subtract(4, 'D').toDate(),
              endDate: new Date(),
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            sx={{textTransform: 'none'}}
          >
            Refresh
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <Divider sx={{width: '100%'}} />
      </Grid>
    </Grid>
  );
};
