import appSelectors from './app/selectors';
import assetsSelectors from './assets/selectors';
import authSelectors from './auth/selectors';

const reduxSelectors = {
  app: appSelectors,
  assets: assetsSelectors,
  auth: authSelectors,
};

export default reduxSelectors;
