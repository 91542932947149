import {Company, GetCompanyProduct} from '../interfaces/Company';
import {Dashboard} from '../interfaces/Dashboard';
import {Me} from '../interfaces/Me';

type Action =
  | 'EDIT'
  | 'DELETE'
  | 'SHARE';

export const product_options: Record<string, string> = {
  proximity: 'proximity_enabled',
  ams: 'ams_enabled',
  belt: 'belt_enabled',
  connect: 'commtrac_enabled',
  shaft_clearence: 'shaft_enabled',
  ventilation: 'ventilation_enabled',
  hazard_ai: 'hazard_ai_enabled',
  alarm: 'alarm_enabled'
}

const getCompanyProductStatus = (company: GetCompanyProduct | null, key: string | null) => {
  if (company && key) {
    return company[product_options[key]];
  } else {
    return false;
  }
}

export const checkPrivilegeAction = (user: Me | null, dashboard: Dashboard | undefined, action: Action) => {
  if ((action === 'EDIT' || action === 'DELETE') && dashboard) {
    if (user?.id === dashboard?.user_id) {  // dashboard that they themselves create or delete.
      return true;
    } else if (user?.type_id === 1 && user?.type_id < dashboard?.user_owner?.type_id) { //superUser can edit or delete others dashboard
      return true;
    } else if (user?.type_id === 2 && user?.type_id < dashboard?.user_owner?.type_id) { //strataManger can edit or delete others dashboard
      return true;
    } else if (user?.type_id === 3 && user?.type_id < dashboard?.user_owner?.type_id) { //clientManger can edit or delete others dashboard
      return true;
    }
  } else if (action === 'SHARE' && dashboard) {
    if (dashboard?.product !== null) {
      return false;
    } else if (user?.id  === dashboard?.user_id || ((user?.type_id ?? 0) < dashboard?.user_owner?.type_id)) {  // dashboard that they share
      return true;
    }
  }
  return false;
}

export const makeViewableDashboardList = (user: Me | null, company: Company | null, list: Dashboard[] | undefined): Dashboard[] | undefined => {
  return list?.filter((dashboard) => {
    if (!dashboard.product) {
      return true;
    } else if (dashboard.product && getCompanyProductStatus(company, dashboard.product)) {
      return true;
    } else {
      return false;
    }
  });
}
