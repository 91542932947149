import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import {EventSummaryRow} from '../EventSummaryList';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  event: EventSummaryRow;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDone?: () => void;
}

const EventStatAckButton = <T extends ComponentType = typeof Button>({
  event,
  component,
  componentProps,
  children,
  onDone,
}: Props<T>) => {
  const Component = component ?? Button;

  const {enqueueSnackbar} = useSnackbar();

  /*********/
  /* submit */
  /*********/
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitAckEvent = useCallback(async () => {
    setSubmittedInProgress(true);
    const payload = event.ackAll
      ? ['connect', 'ams', 'alarm']
      : event?.product?.recent?.id;
    const params = event.ackAll
      ? {product: payload}
      : event?.product?.recent?.product === 'connect'
        ? {ids: {connect: [payload]}}
        : event?.product?.recent?.product === 'ams'
          ? {ids: {ams: [payload]}}
          : {ids: {alarm: [payload]}};
    try {
      const endpoint = `${apiBaseUrl}/event/${event.ackAll ? 'acknowledge-all' : 'acknowledge'}`;

      await API.patch(endpoint, params);
      enqueueSnackbar('Event(s) was successfully marked as acknowledged.', {
        variant: 'success',
        action: CloseSnackbarAction,
      });

      onDone?.();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  }, [event]);

  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Mark Event</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {event.ackAll
              ? 'Are you sure you want to mark these events as acknowledge?'
              : 'Are you sure you want to mark this event as acknowledge?'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setIsOpened(false)}>Cancel</Button>

          <LoadingButton
            variant="contained"
            loading={submittedInProgress}
            autoFocus
            onClick={submitAckEvent}
            color="error"
          >
            {event.ackAll ? 'ACK ALL' : 'ACK'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EventStatAckButton;
