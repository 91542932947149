import SettingsIcon from '@mui/icons-material/Settings';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import {LoadingButton, TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Tab,
} from '@mui/material';
import {Box} from '@mui/system';
import {useMemo, useRef, useState} from 'react';

import {CONFIGURATION_SCOPES} from '../../constants/settings';
import {useAppSelector} from '../../hooks/redux';
import {AdminHazardAIConfig} from '../admin/AdminHazardAIConfig';
import {MyAmsConfig} from './MyAmsConfig';
import {MyCommtracConfig, MyCommtracConfigRef} from './MyCommtracConfig';
import {MyPermissionsConfig} from './MyPermissionsConfig';

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  border: '1px solid #9b9b9b',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
  height: '100%',
};

export const ProfileSettingsModal = ({open, onClose}: Props) => {
  const company = useAppSelector((i) => i.assets.company);
  const [isShownResetDialog, setIsShownResetDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const connectRef = useRef<MyCommtracConfigRef | null>(null);

  const tabs = [
    {
      label: 'Connect',
      value: 'connect',
      shown: !!company?.commtrac_enabled,
      component: (
        <MyCommtracConfig
          ref={connectRef}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: connectRef,
    },

    {
      label: 'AMS',
      value: 'ams',
      // component: null,
      shown: !!company?.ams_enabled,
      component: (
        <MyAmsConfig
          ref={connectRef}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: connectRef,
    },

    {
      label: 'HazardAI',
      value: 'hazard_ai',
      shown: !!company?.hazard_ai_enabled,
      component: (
        <AdminHazardAIConfig
          ref={connectRef}
          scope={CONFIGURATION_SCOPES.USER}
          onChangeSubmittedInProgress={setIsSubmitting}
          onChangeResetInProgress={setIsResetting}
        />
      ),
      ref: connectRef,
    },

    {
      label: 'Permissions',
      value: 'permissions',
      shown: true,
      component: <MyPermissionsConfig />,
      ref: null,
    },
  ] as const;

  const enabledTabs = tabs.filter((i) => i.shown);

  const [activeTab, setActiveTab] = useState('connect');
  const selectedTab = useMemo(
    () => enabledTabs.find((i) => i.value === activeTab) || enabledTabs[0],
    [enabledTabs, activeTab]
  );

  return (
    <>
      <Modal open={open ?? false} onClose={onClose}>
        <Box sx={style}>
          <Box py={0} display="flex" flexDirection="column" gap={2}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Box fontSize={24} display="flex" alignItems="center">
                <SettingsIcon sx={{mr: 1}} />
                Settings
              </Box>

              {selectedTab?.ref ? (
                <Box display="flex" gap={1}>
                  <Box>
                    <LoadingButton
                      variant="text"
                      loading={isResetting}
                      onClick={() => setIsShownResetDialog(true)}
                      sx={{mr: 2, px: 0, minWidth: 'auto'}}
                    >
                      <SettingsBackupRestoreIcon />
                    </LoadingButton>

                    <Dialog
                      open={isShownResetDialog}
                      onClose={() => setIsShownResetDialog(false)}
                    >
                      <DialogTitle>Load Factory Defaults</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          Are you sure you want to load factory defaults?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setIsShownResetDialog(false)}>
                          Cancel
                        </Button>
                        <LoadingButton
                          loading={isResetting}
                          onClick={async () => {
                            await selectedTab?.ref?.current?.reset?.();
                            setIsShownResetDialog(false);
                          }}
                        >
                          Reset
                        </LoadingButton>
                      </DialogActions>
                    </Dialog>
                  </Box>

                  <LoadingButton
                    variant="text"
                    onClick={() => selectedTab?.ref?.current?.clear?.()}
                    sx={{mr: 2, px: 0, minWidth: 'auto'}}
                  >
                    <SettingsSuggestIcon />
                  </LoadingButton>

                  <LoadingButton
                    variant="contained"
                    loading={isSubmitting}
                    onClick={() => selectedTab?.ref?.current?.submit?.()}
                  >
                    Save All Changes
                  </LoadingButton>
                </Box>
              ) : null}
            </Box>

            <Box>
              <TabContext value={selectedTab?.value}>
                <Box>
                  <TabList variant="fullWidth">
                    {enabledTabs.map((tab) => (
                      <Tab
                        key={tab.value}
                        label={tab.label}
                        value={tab.value}
                        onClick={() => setActiveTab(tab.value)}
                      />
                    ))}
                  </TabList>
                </Box>

                {enabledTabs.map((tab) => (
                  <TabPanel key={tab.value} value={tab.value} sx={{px: 0}}>
                    {tab.component}
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
