import {Box, BoxProps} from '@mui/material';

const TruncatedText = (props: BoxProps) => {
  return (
    <Box
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      display="block"
      overflow="hidden"
      {...props}
    />
  );
};

export default TruncatedText;
