import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  AssetMachine,
  AssetMachineUpdateNetworkInputBody,
} from '../../interfaces/AssetMachine';
import reduxActions from '../../redux/actions';
import {assetMachineUpdateNetworkInputSchema} from '../../scheme/yup/asset-machine';
import {maxNetworkId} from '../../scheme/yup/utils';
import {getBooleanValue} from '../../utils/boolean';
import {
  getIsCommtracExternalIdVisible,
  getIsMacAddressVisible,
} from '../../utils/commtrac-nodes';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import AssetMachineReassignDialog from './AssetMachineReassignDialog';

interface Props {
  pk: number;
  item?: AssetMachine;
  prefetch?: boolean;
  onCancel?: () => void;
  onSubmitted?: (item: AssetMachine) => void;
}

type UpdateNetworkInputBody = AssetMachineUpdateNetworkInputBody & {
  wifi_enabled: boolean | null;
};

const AssetMachineItemUpdateNetwork = ({
  pk,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  const reduxDispatch = useAppDispatch();

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AssetMachine>(
        `${apiBaseUrl}/asset-machine/${pk}`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
      reduxDispatch(reduxActions.assets.fetchAssetMachines);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: UpdateNetworkInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/asset-machine/${pk}/update_network_id`;
      const resp = await API.patch<AssetMachine>(endpoint, {
        ...data,
        wifi_enabled: undefined,
        commtrac_external_id: isCommtracExternalIdVisible
          ? data.commtrac_external_id
          : undefined,
        mac_address: isMacAddressVisible ? data.mac_address : undefined,
      });
      const message = `Asset has been updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const getFormikValues = (item?: AssetMachine): UpdateNetworkInputBody => ({
    wifi_enabled: !!item?.wifi_enabled,
    commtrac_external_id: item?.commtrac_external_id ?? null,
    mac_address: item?.mac_address ?? null,
  });

  const formik = useFormik<UpdateNetworkInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: assetMachineUpdateNetworkInputSchema,
    onSubmit: async (values) => {
      if (commtracNodeToReassign) {
        setIsReassignDialogOpened(true);
      } else {
        await submitData(values);
      }
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  const isCommtracExternalIdVisible = getIsCommtracExternalIdVisible(
    true,
    formik.values.wifi_enabled
  );
  const isMacAddressVisible = getIsMacAddressVisible(
    true,
    formik.values.wifi_enabled
  );

  /*********************/
  /* commtrac reassign */
  /*********************/

  const [isReassignDialogOpened, setIsReassignDialogOpened] = useState(false);
  const commtracNodes = useAppSelector(({assets}) => assets.commtrac_nodes);

  const commtracNodeToReassign = useMemo(() => {
    if (
      isCommtracExternalIdVisible &&
      formik.values.commtrac_external_id &&
      formik.values.commtrac_external_id !== fetchedData?.commtrac_external_id
    ) {
      return commtracNodes.find(
        (i) => i.commtrac_external_id === formik.values.commtrac_external_id
      );
    } else if (
      isMacAddressVisible &&
      formik.values.mac_address &&
      formik.values.mac_address !== fetchedData?.mac_address
    ) {
      return commtracNodes.find(
        (i) => i.mac_address === formik.values.mac_address
      );
    }
  }, [
    formik.values.commtrac_external_id,
    formik.values.mac_address,
    isCommtracExternalIdVisible,
    isMacAddressVisible,
    fetchedData?.mac_address,
    fetchedData?.commtrac_external_id,
  ]);

  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        position="relative"
        gap={3}
        onSubmit={formik.handleSubmit}
      >
        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {fetchedErrors.map((error, index) => (
          <Alert key={index} severity="error">
            {error}{' '}
          </Alert>
        ))}
        <Box display="flex" flexDirection="column" gap={3}>
          <TextField
            value={getBooleanValue(formik.values.wifi_enabled, 0, 1)}
            label="WiFi Enabled"
            size="small"
            name="wifi_enabled"
            select
            fullWidth
            error={
              !!formik.touched.wifi_enabled && !!formik.errors.wifi_enabled
            }
            helperText={
              formik.touched.wifi_enabled && formik.errors.wifi_enabled
            }
            onChange={(e) =>
              formik.setFieldValue(
                e.target.name,
                getBooleanValue(e.target.value, false, true)
              )
            }
          >
            {[
              {value: 1, name: 'Yes'},
              {value: 0, name: 'No'},
            ].map((i) => (
              <MenuItem key={i.name} value={i.value}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>

          {isCommtracExternalIdVisible ? (
            <NumberTextField
              value={formik.values.commtrac_external_id}
              min={1}
              max={maxNetworkId}
              label="Network ID"
              size="small"
              name="commtrac_external_id"
              fullWidth
              error={
                !!formik.touched.commtrac_external_id &&
                !!formik.errors.commtrac_external_id
              }
              helperText={
                formik.touched.commtrac_external_id &&
                formik.errors.commtrac_external_id
              }
              onChange={(v) => formik.setFieldValue('commtrac_external_id', v)}
            />
          ) : null}

          {isCommtracExternalIdVisible && commtracNodeToReassign ? (
            <Alert color="warning">
              Network ID {formik.values.commtrac_external_id} is assigned to
              another {commtracNodeToReassign.type}. Are you sure you want to
              reassign it to this asset?
            </Alert>
          ) : null}

          {isMacAddressVisible ? (
            <TextField
              value={formik.values.mac_address}
              label="Mac Address"
              size="small"
              name="mac_address"
              fullWidth
              error={
                !!formik.touched.mac_address && !!formik.errors.mac_address
              }
              helperText={
                formik.touched.mac_address && formik.errors.mac_address
              }
              onChange={formik.handleChange}
            />
          ) : null}

          {isMacAddressVisible && commtracNodeToReassign ? (
            <Alert color="warning">
              Mac Address {formik.values.mac_address} is assigned to another{' '}
              {commtracNodeToReassign.type}. Are you sure you want to reassign
              it to this asset?
            </Alert>
          ) : null}
        </Box>

        <Box display="flex" justifyContent="end" gap={1.5}>
          {onCancel ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : null}
          <Box>
            <LoadingButton
              variant="contained"
              color={commtracNodeToReassign ? 'warning' : 'primary'}
              type="submit"
              loading={submittedInProgress}
            >
              {commtracNodeToReassign ? 'Reassign' : 'Update'}
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      {isReassignDialogOpened && (
        <AssetMachineReassignDialog
          open={isReassignDialogOpened}
          wifiEnabled={formik.values.wifi_enabled}
          macAddress={formik.values.mac_address}
          commtracExternalId={formik.values.commtrac_external_id}
          onClose={() => setIsReassignDialogOpened(false)}
          onConfirm={() => {
            submitData(formik.values);
            setIsReassignDialogOpened(false);
          }}
        />
      )}
    </>
  );
};

export default AssetMachineItemUpdateNetwork;
