import {useMemo} from 'react';

import {useAppSelector} from '../../hooks/redux';
import {BaseObjectSelect, BaseObjectSelectProps} from './BaseObjectSelect';

type Props = Pick<BaseObjectSelectProps, 'nullLabel' | 'onChange'>;

export const AmsEmoduleObjectSelect = (props: Props) => {
  const amsEmodules = useAppSelector(({assets}) => assets.ams_emodules);
  const amsEmoduleList = useMemo(() => {
    return amsEmodules.map((m) => ({
      id: m.id,
      name: m.ams?.name || `E-module serial #${m.serial_number}`
    }));
  }, [amsEmodules]);

  return (
    <BaseObjectSelect
      {...props}
      items={amsEmoduleList}
      nullLabel={props.nullLabel || 'Select e-module object'}
    />
  );
};
