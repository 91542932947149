import {LoadingButton} from '@mui/lab';
import {Autocomplete, Box, Button, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useConfiguration} from '../../hooks/configuration';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {AlarmLogNode} from '../../interfaces/AlarmLogNode';
import reduxActions from '../../redux/actions';
import {getAlarmCreateSchema} from '../../scheme/yup/alarm-module';
import {
  tweakAlarmNameWithMacAddress,
  tweakAlarmNameWithNetworkId,
} from '../../utils/macAddress';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../common/Map';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  item?: AlarmLogNode;
  locationCoordinates?: MapLatLangCoordinates;
  onCancel?: () => void;
  onSubmitted?: (item: AlarmLogNode) => void;
}

type AcknowledgeInputBody = {
  name: string | null;
  mac_address: string | null;
  status: 'active' | 'inactive';
  zone_id: number | null;
  latitude: number | null;
  longitude: number | null;
  description: string | null;
  local_button_press_action: string | null;
  local_button_press_action_group_id: number | null;
  local_button_press_action_zone_id: number | null;
  local_button_group_zone_id: number | null;
};

// eslint-disable-next-line complexity
const AlarmAcknowledge = ({
  item,
  onCancel,
  onSubmitted,
  locationCoordinates,
}: Props) => {
  const reduxDispatch = useAppDispatch();
  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const localButtonPressAction = useAppSelector(
    ({assets}) => assets.localButtonPressAction
  );
  const controlRoomMonitored = useConfiguration(
    'global',
    'control_room_is_monitored'
  );
  const isLocalButtonPressActionDisabled = useMemo(() => {
    return controlRoomMonitored?.value
      ? +controlRoomMonitored?.value === 0
      : false;
  }, [controlRoomMonitored]);

  const zones = useAppSelector(({assets}) => assets.zones);
  const alarm_groups = useAppSelector(({assets}) => assets.alarm_groups);

  const zoneGroupList = useMemo(() => {
    const groupList = alarm_groups?.map((it, index) => ({
      id: index,
      v_id: it.id,
      name: it.name,
      type: 'group',
    }));

    const zoneList = zones?.map((it, index) => ({
      id: index + groupList.length,
      name: it.name,
      v_id: it.id,
      type: 'zone',
    }));
    return [...groupList, ...zoneList]?.sort(
      (a, b) => -b.type.localeCompare(a.type)
    );
  }, [zones, alarm_groups]);

  const submitData = async (data: AcknowledgeInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/alarm-module/${item?.id}/acknowledge`;
      const resp = await API.patch<AlarmLogNode>(
        endpoint,
        Object.fromEntries(
          Object.entries(data).filter(
            ([k, v]) =>
              v != null &&
              k !== 'local_button_group_zone_id' &&
              k !== 'mac_address'
          )
        )
      );
      const message = `Alarm module has been acknowledged`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAlarms);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const getFormikValues = (item?: AlarmLogNode): AcknowledgeInputBody => ({
    name: tweakAlarmNameWithMacAddress(
      item?.name || '',
      item?.mac_address ?? ''
    ),
    mac_address: item?.mac_address || null,
    zone_id: item?.zone_id || null,
    status: 'active',
    latitude: item?.latitude || null,
    longitude: item?.longitude || null,
    description: item?.description || null,
    local_button_press_action: item?.local_button_press_action || null,
    local_button_press_action_group_id:
      item?.local_button_press_action_group_id || null,
    local_button_press_action_zone_id:
      item?.local_button_press_action_zone_id || null,
    local_button_group_zone_id: item?.local_button_press_action_group_id
      ? zoneGroupList?.find(
          (it) =>
            it.v_id === item?.local_button_press_action_group_id &&
            it.type === 'group'
        )?.id || null
      : item?.local_button_press_action_zone_id
        ? zoneGroupList?.find(
            (it) =>
              it.v_id === item?.local_button_press_action_zone_id &&
              it.type === 'zone'
          )?.id || null
        : null,
  });

  const formik = useFormik<AcknowledgeInputBody>({
    initialValues: getFormikValues(item),
    validationSchema: getAlarmCreateSchema(isLocalButtonPressActionDisabled),
    onSubmit: async (values) => {
      await submitData({
        ...values,
        name: tweakAlarmNameWithNetworkId(
          values?.name ?? '',
          item?.mac_address ?? ''
        ),
      });
    },
  });

  useEffect(() => {
    if (locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.latitude !== newPosLat ||
        formik.values.longitude !== newPosLong
      ) {
        formik.setFieldValue('latitude', newPosLat);
        formik.setFieldValue('longitude', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <Box display="flex" flexDirection="column" gap={3}>
          <TextField
            value={formik.values.name}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />

          <TextField
            value={formik.values.mac_address}
            label="Mac Address"
            size="small"
            name="mac_address"
            fullWidth
            disabled
            error={!!formik.touched.mac_address && !!formik.errors.mac_address}
            helperText={formik.touched.mac_address && formik.errors.mac_address}
            onChange={formik.handleChange}
          />

          <ZoneSelect
            value={formik.values.zone_id}
            label="Assigned Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />

          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />

          <NumberTextField
            value={formik.values.latitude}
            label="Latitude"
            size="small"
            name="latitude"
            fullWidth
            decimalPlaces={2}
            error={!!formik.touched.latitude && !!formik.errors.latitude}
            helperText={formik.touched.latitude && formik.errors.latitude}
            onChange={(v) => formik.setFieldValue('latitude', v)}
          />
          <NumberTextField
            value={formik.values.longitude}
            label="Longitude"
            size="small"
            name="longitude"
            fullWidth
            decimalPlaces={2}
            error={!!formik.touched.longitude && !!formik.errors.longitude}
            helperText={formik.touched.longitude && formik.errors.longitude}
            onChange={(v) => formik.setFieldValue('longitude', v)}
          />

          <TextField
            value={formik.values.description}
            label="Description"
            size="small"
            name="description"
            multiline
            rows={3}
            fullWidth
            error={!!formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
            onChange={formik.handleChange}
          />
        </Box>
        <Autocomplete
          value={
            localButtonPressAction.find(
              (i) => i.key === formik.values.local_button_press_action
            ) ?? null
          }
          disabled={isLocalButtonPressActionDisabled}
          fullWidth
          options={localButtonPressAction}
          isOptionEqualToValue={(option, value) => option.key === value?.key}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Action"
              size="small"
              error={
                !!formik.touched.local_button_press_action &&
                !!formik.errors.local_button_press_action
              }
              helperText={
                formik.touched.local_button_press_action &&
                formik.errors.local_button_press_action
              }
            />
          )}
          onChange={(event, value) =>
            formik.setFieldValue('local_button_press_action', value?.key)
          }
        />
        {(formik.values.local_button_press_action ===
          'activate_warning_group' ||
          formik.values.local_button_press_action ===
            'activate_alarm_group') && (
          <>
            <Autocomplete
              value={
                zoneGroupList.find(
                  (i) => i.id === formik.values.local_button_group_zone_id
                ) ?? null
              }
              disabled={isLocalButtonPressActionDisabled}
              fullWidth
              options={zoneGroupList}
              groupBy={(option) => option.type}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              getOptionLabel={(option) => option?.name ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Group/Zone selector"
                  size="small"
                  error={
                    !!formik.touched.local_button_group_zone_id &&
                    !!formik.errors.local_button_group_zone_id
                  }
                  helperText={
                    formik.touched.local_button_group_zone_id &&
                    formik.errors.local_button_group_zone_id
                  }
                />
              )}
              onChange={(event, value) => {
                console.log(value);
                if (value?.type === 'group') {
                  formik.setFieldValue(
                    'local_button_press_action_group_id',
                    value?.v_id
                  );
                  formik.setFieldValue(
                    'local_button_press_action_zone_id',
                    null
                  );
                } else {
                  formik.setFieldValue(
                    'local_button_press_action_group_id',
                    null
                  );
                  formik.setFieldValue(
                    'local_button_press_action_zone_id',
                    value?.v_id
                  );
                }
                formik.setFieldValue('local_button_group_zone_id', value?.id);
              }}
            />
          </>
        )}
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="end" gap={1}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
            >
              Acknowledge
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AlarmAcknowledge;
