import 'leaflet-rotatedmarker';

import {forwardRef, useEffect, useRef} from 'react';
import {Marker} from 'react-leaflet';

export const RotatedMarker = forwardRef(
  ({children, ...props}: any, forwardRef: any) => {
    const markerRef = useRef<any>();

    const {rotationAngle, rotationOrigin} = props;
    useEffect(() => {
      const marker = markerRef.current;
      if (marker) {
        marker.setRotationAngle(rotationAngle);
        marker.setRotationOrigin(rotationOrigin);
      }
    }, [rotationAngle, rotationOrigin]);

    return (
      <Marker
        ref={(ref) => {
          markerRef.current = ref;
          if (forwardRef) {
            forwardRef.current = ref;
          }
        }}
        {...props}
      >
        {children}
      </Marker>
    );
  }
);
