import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {
  fetchEmployees,
  fetchHumanGroups,
  fetchSelectedHumanTypes,
  fetchSelectedSections,
} from './actions';
import {AdminPageState, LoadingStatus, RejectError} from './types';

const initialState: AdminPageState = {
  humanGroupTab: {
    error: [],
    groupList: [],
    employees: [],
    selectedTypes: [],
    selectedSections: [],
    loading: 'idle',
  },
};

const slice = createSlice({
  name: 'adminPage',
  initialState,
  reducers: {
    setLoadingStatus: (
      state,
      action: PayloadAction<{status: LoadingStatus}>
    ) => {
      state.humanGroupTab.loading = action.payload.status;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchHumanGroups.pending, (state) => {
        state.humanGroupTab.loading = 'pending';
      })
      .addCase(fetchHumanGroups.fulfilled, (state, action) => {
        state.humanGroupTab.groupList = action.payload?.groups ?? [];
        state.humanGroupTab.loading = 'succeeded';
      })
      .addCase(fetchHumanGroups.rejected, (state, action) => {
        if (action.payload) {
          const errorMsg = action.payload as RejectError;
          state.humanGroupTab.error = [errorMsg.errorMessage];
          state.humanGroupTab.loading = 'failed';
        }
      })
      .addCase(fetchSelectedHumanTypes.pending, (state) => {
        state.humanGroupTab.loading = 'pending';
      })
      .addCase(fetchSelectedHumanTypes.fulfilled, (state, action) => {
        state.humanGroupTab.selectedTypes = action.payload.selectedTypes ?? [];
        state.humanGroupTab.loading = 'succeeded';
      })
      .addCase(fetchSelectedHumanTypes.rejected, (state, action) => {
        if (action.payload) {
          const errorMsg = action.payload as RejectError;
          state.humanGroupTab.error = [errorMsg.errorMessage];
          state.humanGroupTab.loading = 'failed';
        }
      })
      .addCase(fetchSelectedSections.pending, (state) => {
        state.humanGroupTab.loading = 'pending';
      })
      .addCase(fetchSelectedSections.fulfilled, (state, action) => {
        state.humanGroupTab.selectedSections = action.payload.sections ?? [];
        state.humanGroupTab.loading = 'succeeded';
      })
      .addCase(fetchSelectedSections.rejected, (state, action) => {
        if (action.payload) {
          const errorMsg = action.payload as RejectError;
          state.humanGroupTab.error = [errorMsg.errorMessage];
          state.humanGroupTab.loading = 'failed';
        }
      })
      .addCase(fetchEmployees.pending, (state) => {
        state.humanGroupTab.loading = 'pending';
      })
      .addCase(fetchEmployees.fulfilled, (state, action) => {
        state.humanGroupTab.employees = action.payload.employees ?? [];
        state.humanGroupTab.loading = 'succeeded';
      })
      .addCase(fetchEmployees.rejected, (state, action) => {
        if (action.payload) {
          const errorMsg = action.payload as RejectError;
          state.humanGroupTab.error = [errorMsg.errorMessage];
          state.humanGroupTab.loading = 'failed';
        }
      });
  },
});

export const actions = slice.actions;

export default slice.reducer;
