import {
  Box,
  Grid,
  Menu,
  MenuItem,
  TextField,
  TextFieldProps,
} from '@mui/material';
import {useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {AlertType} from '../../interfaces/AlertType';
import {HumanZone} from '../../interfaces/HumanZone';
import reduxSelectors from '../../redux/selectors';

interface Props
  extends Pick<
    TextFieldProps,
    'size' | 'label' | 'fullWidth' | 'sx' | 'InputProps'
  > {
  value?: Value;
  showMachine?: boolean;
  showHuman?: boolean;
  onChangeHuman?: (value: number[] | null) => void;
  onChangeMachine?: (value: number[] | null) => void;
}

interface Value {
  machine?: number[] | null;
  human?: number[] | null;
}

export const HumanMachineZoneSelect = ({
  value,
  showMachine = true,
  showHuman = true,
  onChangeHuman,
  onChangeMachine,
  ...props
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const humanOptions = useMemo<HumanZone[]>(
    () => assets.humanAlertType,
    [assets.humanAlertType]
  );
  const machineOptions = useMemo<AlertType[]>(
    () => assets.machineAlertType,
    [assets.machineAlertType]
  );

  return (
    <>
      <TextField
        fullWidth
        label="Zones"
        {...props}
        value={[
          ...(showHuman
            ? [
                ...(!value?.human?.length
                  ? [showMachine ? 'All Human' : 'All Zones']
                  : []),
                ...humanOptions
                  .filter((i) => value?.human?.includes(+i.id))
                  .map((i) => i.name),
              ]
            : []),

          ...(showMachine
            ? [
                ...(!value?.machine?.length
                  ? [showHuman ? 'All Machine' : 'All Zones']
                  : []),
                ...machineOptions
                  .filter((i) => value?.machine?.includes(+i.id))
                  .map((i) => i.name),
              ]
            : []),
        ].join(', ')}
        InputProps={{
          ...props?.InputProps,
          readOnly: true,
          sx: {
            ...props?.InputProps?.sx,
            input: {
              cursor: 'pointer',
            },
          },
        }}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <Grid container spacing={3}>
          {showHuman && (
            <Grid item sm={6}>
              <Box width={160}>
                {showMachine && (
                  <Box px={2} mb={1} fontWeight="bold" fontSize={18}>
                    Human
                  </Box>
                )}

                <MenuItem
                  selected={!value?.human?.length}
                  onClick={() => onChangeHuman?.(null)}
                >
                  All Zones
                </MenuItem>

                {humanOptions.map((i) => (
                  <MenuItem
                    key={i.id}
                    selected={value?.human?.includes(+i.id)}
                    onClick={() => {
                      if (value?.human?.includes(+i.id)) {
                        onChangeHuman?.(
                          value?.human?.filter((a) => a !== +i.id)
                        );
                      } else {
                        onChangeHuman?.([...(value?.human ?? []), +i.id]);
                      }
                    }}
                  >
                    {i.name}
                  </MenuItem>
                ))}
              </Box>
            </Grid>
          )}

          {showMachine && (
            <Grid item sm={6}>
              <Box width={160}>
                {showHuman && (
                  <Box px={2} mb={1} fontWeight="bold" fontSize={18}>
                    Machine
                  </Box>
                )}

                <MenuItem
                  selected={!value?.machine?.length}
                  onClick={() => onChangeMachine?.(null)}
                >
                  All Zones
                </MenuItem>

                {machineOptions.map((i) => (
                  <MenuItem
                    key={i.id}
                    selected={value?.machine?.includes(+i.id)}
                    onClick={() => {
                      if (value?.machine?.includes(+i.id)) {
                        onChangeMachine?.(
                          value?.machine?.filter((a) => a !== +i.id)
                        );
                      } else {
                        onChangeMachine?.([...(value?.machine ?? []), +i.id]);
                      }
                    }}
                  >
                    {i.name}
                  </MenuItem>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Menu>
    </>
  );
};
