import {Box, Checkbox, FormControlLabel, Grid, Paper} from '@mui/material';
import React from 'react';

import {SelectedGroupParams} from '../../interfaces/HumanGroup';

type HumaGroupTypesPropsType = {
  types: number[];
  typesList: SelectedGroupParams[];
  fieldHandle: (fieldName: string, itemId: number) => void;
};
export const HumaGroupTypes = React.memo(
  ({typesList, types, fieldHandle}: HumaGroupTypesPropsType) => {
    return (
      <Paper sx={{p: 2, mb: 2}}>
        <Box fontSize={20} lineHeight={1.6} mb={2}>
          Select the job types you want included:
        </Box>
        <Grid container sx={{p: 1, height: 300, overflowY: 'auto'}}>
          {!!typesList.length &&
            typesList.map((item: SelectedGroupParams) => (
              <Grid item xs={4} key={item.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={types.includes(item.id)}
                      onChange={() => fieldHandle('types', item.id)}
                    />
                  }
                  label={item.info}
                />
              </Grid>
            ))}
        </Grid>
      </Paper>
    );
  }
);
