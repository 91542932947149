import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {Box} from '@mui/system';
import {useFormik} from 'formik';
import _ from 'lodash';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import {useSelector} from 'react-redux';

import {apiBaseUrl} from '../../api/urls';
import {useCrudList} from '../../hooks/useCrudList';
import {
  SubscriptionListQuery,
  SubscriptionListResponse,
} from '../../interfaces/Subscription';
import reduxSelectors from '../../redux/selectors';
import AccessControl from '../common/AccessControl';
import {SubscriptionItemDeleteButton} from './buttons/SubscriptionItemDeleteButton';
import {SubscriptionItemUpsertButton} from './buttons/SubscriptionItemUpsertButton';

interface SubscriptionListProps {
  queryParams?: Partial<SubscriptionListQuery>;
  onUpdate?: Function;
}

export interface SubscriptionListRef {
  fetch?: Function;
}

export const SubscriptionList = forwardRef<
  SubscriptionListRef,
  React.PropsWithChildren<SubscriptionListProps>
>(({queryParams, onUpdate}, ref) => {
  const assets = useSelector(reduxSelectors.assets.getAssets);

  const crud = useCrudList<SubscriptionListResponse>({
    endpointBase: `${apiBaseUrl}/subscription`,
  });

  const listParams = useMemo<SubscriptionListQuery>(
    () => ({
      limit: queryParams?.limit ?? 100,
      page: queryParams?.page ?? 0,
    }),
    [queryParams]
  );

  const formik = useFormik({
    initialValues: listParams,
    onSubmit: (values) => onUpdate?.(values),
  });

  useImperativeHandle(ref, () => ({
    fetch: () => crud.onFetch(listParams),
  }));

  useEffect(() => {
    if (!_.isEqual(listParams, formik.values)) {
      formik.setValues(listParams);
    }

    crud.onFetch(listParams);
  }, [listParams]);

  useEffect(() => {
    if (!_.isEqual(listParams, formik.values)) {
      formik.handleSubmit();
    }
  }, [formik.values]);

  const displayPeriod = (
    period: 'daily' | 'weekly' | 'monthly' | 'endOfShift'
  ) => {
    return (period === 'endOfShift' ? 'end of shift' : period).toUpperCase();
  };

  return (
    <TableContainer component={Paper}>
      <Backdrop
        open={crud.isFetching}
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {crud.errorsFetch.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      {crud.data && (
        <Box display="flex" width="100%" justifyContent="end">
          <TablePagination
            component={Box}
            labelRowsPerPage="Items per page"
            rowsPerPageOptions={[2, 10, 20, 50, 100]}
            colSpan={3}
            count={crud.data.count}
            rowsPerPage={formik.values.limit ?? 10}
            page={formik.values.page ?? 0}
            onPageChange={(event, page) => {
              formik.setFieldValue('page', page);
            }}
            onRowsPerPageChange={(event) => {
              formik.setFieldValue('limit', +event?.target.value);
              formik.setFieldValue('page', 0);
            }}
          />
        </Box>
      )}

      <Table sx={{minWidth: 650}}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Period</TableCell>
            <TableCell align="right">Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {crud.data?.items.map((item) => (
            <TableRow
              key={item.id}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              <TableCell component="th">
                <Box mb={1}>
                  <AccessControl
                    permissions={['patch::/subscription/:id']}
                    fallback={
                      <Typography color="primary.main">{item.name}</Typography>
                    }
                  >
                    <SubscriptionItemUpsertButton
                      pk={item.id}
                      item={item}
                      component={Typography}
                      componentProps={{
                        sx: {
                          cursor: 'pointer',
                          color: 'primary.main',
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {item.name}
                    </SubscriptionItemUpsertButton>
                  </AccessControl>
                </Box>

                <Box>
                  <Box component="span" mr={1}>
                    Reports:
                  </Box>
                  <Box component="span">
                    {item.reports
                      ?.map((i) => i.name ?? `Report ${i.id}`)
                      .join(', ') ?? '-'}
                  </Box>
                </Box>

                <Box>
                  <Box component="span" mr={1}>
                    Recipients:
                  </Box>
                  <Box component="span">
                    {[
                      ...item.roles.map(
                        (i) => assets.roles.find((r) => +r.id === i)?.name
                      ),
                      ...item.users.map(
                        (i) => assets.users.find((r) => +r.id === i)?.name
                      ),
                      ...item.emails,
                    ].join(', ')}
                  </Box>
                </Box>
              </TableCell>

              <TableCell component="th" sx={{minWidth: 135}}>
                {displayPeriod(item.period)}
              </TableCell>

              <TableCell component="th" align="right">
                <AccessControl permissions={['patch::/subscription/:id']}>
                  <SubscriptionItemUpsertButton
                    pk={item.id}
                    item={item}
                    component={Button}
                    componentProps={{sx: {p: 0, minWidth: 'auto'}}}
                    onSubmitted={() => crud.onFetch(listParams)}
                  >
                    <EditIcon />
                  </SubscriptionItemUpsertButton>
                </AccessControl>

                <AccessControl permissions={['delete::/subscription/:id']}>
                  <SubscriptionItemDeleteButton
                    item={item}
                    component={Button}
                    componentProps={{
                      color: 'error',
                      sx: {p: 0, ml: 1, minWidth: 'auto'},
                    }}
                    onDeleted={() => crud.onFetch(listParams)}
                  >
                    <DeleteIcon />
                  </SubscriptionItemDeleteButton>
                </AccessControl>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {crud.data && (
        <Box display="flex" width="100%" justifyContent="end">
          <TablePagination
            component={Box}
            labelRowsPerPage="Items per page"
            rowsPerPageOptions={[10, 20, 50, 100]}
            colSpan={3}
            count={crud.data.count}
            rowsPerPage={formik.values.limit ?? 10}
            page={formik.values.page ?? 0}
            onPageChange={(event, page) => {
              formik.setFieldValue('page', page);
            }}
            onRowsPerPageChange={(event) => {
              formik.setFieldValue('limit', +event?.target.value);
              formik.setFieldValue('page', 0);
            }}
          />
        </Box>
      )}
    </TableContainer>
  );
});
