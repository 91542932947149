import {Dispatch} from 'redux';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  AlarmGroupListResponse,
  AlarmLogNodeListResponse,
} from '../../interfaces/AlarmLogNode';
import {AMSEModuleNode} from '../../interfaces/AMSEModuleNode';
import {AssetHumanListResponse} from '../../interfaces/AssetHuman';
import {AssetHumanGroupListResponse} from '../../interfaces/AssetHumanGroup';
import {AssetHumanTypeListResponse} from '../../interfaces/AssetHumanType';
import {AssetMachineListResponse} from '../../interfaces/AssetMachine';
import {Assets} from '../../interfaces/Assets';
import {CommtracNodeListResponse} from '../../interfaces/CommtracNode';
import {Company} from '../../interfaces/Company';
import {GasMonitoringNodeListResponse} from '../../interfaces/GasMonitoringNode';
import {LayerListResponse} from '../../interfaces/Layer';
import {MachineInputListResponse} from '../../interfaces/MachineInput';
import {MachineTypeListResponse} from '../../interfaces/MachineType';
import {MapListResponse} from '../../interfaces/Map';
import {Node, NodeListResponse} from '../../interfaces/Node';
import {SafeyeNodeListResponse} from '../../interfaces/SafeyeNode';
import {ShiftListResponse} from '../../interfaces/Shift';
import {SiteListResponse} from '../../interfaces/Site';
import {UserListResponse} from '../../interfaces/User';
import {ZoneListResponse} from '../../interfaces/Zone';
import {assetsInitialState} from './reducers';
import {AssetsState} from './types';

export enum Actions {
  SetAssets = 'SetAssets',
}

const assetsActions = {
  setAssets: (dispatch: Dispatch, data: Partial<AssetsState>) => {
    return dispatch({type: Actions.SetAssets, data});
  },

  fetchAssets: async (dispatch: Dispatch) => {
    await Promise.all([
      assetsActions.fetchAssetHumanGroups(dispatch),
      assetsActions.fetchAssetHumanTypes(dispatch),
      assetsActions.fetchAssetHumans(dispatch),
      assetsActions.fetchAssetMachines(dispatch),
      assetsActions.fetchCompany(dispatch),
      assetsActions.fetchLayers(dispatch),
      assetsActions.fetchMachineInputs(dispatch),
      assetsActions.fetchMachineTypes(dispatch),
      assetsActions.fetchMaps(dispatch),
      assetsActions.fetchShifts(dispatch),
      assetsActions.fetchStaticAssets(dispatch),
      assetsActions.fetchZones(dispatch),
      assetsActions.fetchSites(dispatch),
      assetsActions.fetchCommtracNodes(dispatch),
      assetsActions.fetchNodes(dispatch),
      assetsActions.fetchUsers(dispatch),
      assetsActions.fetchAlarms(dispatch),
      assetsActions.fetchAlarmGroups(dispatch),
      assetsActions.fetchSafeyeNodes(dispatch),
      assetsActions.fetchGasMonitoring(dispatch),
      assetsActions.fetchGasMonitorNodes(dispatch),
      assetsActions.fetchAmsEmodules(dispatch),
    ]);
    return assetsActions.setAssets(dispatch, {isFetched: true});
  },

  fetchStaticAssets: async (dispatch: Dispatch) => {
    const assets = (await API.get<Assets>(`${apiBaseUrl}/asset`)).data;
    return assetsActions.setAssets(dispatch, assets);
  },

  fetchCompany: async (dispatch: Dispatch) => {
    const company = (await API.get<Company>(`${apiBaseUrl}/company/current`))
      .data;
    return assetsActions.setAssets(dispatch, {company});
  },

  fetchZones: async (dispatch: Dispatch) => {
    const zones = (
      await API.get<ZoneListResponse>(`${apiBaseUrl}/zone`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {zones});
  },

  fetchSites: async (dispatch: Dispatch) => {
    const sites = (
      await API.get<SiteListResponse>(`${apiBaseUrl}/site`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {sites});
  },

  fetchShifts: async (dispatch: Dispatch) => {
    const shifts = (
      await API.get<ShiftListResponse>(`${apiBaseUrl}/shift`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {shifts});
  },

  fetchMachineTypes: async (dispatch: Dispatch) => {
    const machine_types = (
      await API.get<MachineTypeListResponse>(`${apiBaseUrl}/machine-type`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {machine_types});
  },

  fetchMachineInputs: async (dispatch: Dispatch) => {
    const machine_inputs = (
      await API.get<MachineInputListResponse>(`${apiBaseUrl}/machine-input`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {machine_inputs});
  },

  fetchAssetHumanTypes: async (dispatch: Dispatch) => {
    const asset_human_types = (
      await API.get<AssetHumanTypeListResponse>(
        `${apiBaseUrl}/asset-human-type`,
        {
          params: {
            limit: 2000,
            order: 'id',
            dir: 'ASC',
          },
        }
      )
    ).data.items;
    return assetsActions.setAssets(dispatch, {asset_human_types});
  },

  fetchAssetMachines: async (dispatch: Dispatch) => {
    const asset_machines = (
      await API.get<AssetMachineListResponse>(`${apiBaseUrl}/asset-machine`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {asset_machines});
  },

  fetchSafeyeNodes: async (dispatch: Dispatch) => {
    const safeye_nodes = (
      await API.get<SafeyeNodeListResponse>(`${apiBaseUrl}/safeye-nano-node`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {safeye_nodes});
  },

  fetchAssetHumans: async (dispatch: Dispatch) => {
    const asset_humans = (
      await API.get<AssetHumanListResponse>(`${apiBaseUrl}/asset-human`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {asset_humans});
  },

  fetchAssetHumanGroups: async (dispatch: Dispatch) => {
    const asset_human_groups = (
      await API.get<AssetHumanGroupListResponse>(
        `${apiBaseUrl}/asset-human-group`,
        {
          params: {
            limit: 2000,
            order: 'id',
            dir: 'ASC',
          },
        }
      )
    ).data.items;
    return assetsActions.setAssets(dispatch, {asset_human_groups});
  },

  fetchMaps: async (dispatch: Dispatch) => {
    const maps = (
      await API.get<MapListResponse>(`${apiBaseUrl}/map`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {maps});
  },

  fetchLayers: async (dispatch: Dispatch) => {
    const layers = (
      await API.get<LayerListResponse>(`${apiBaseUrl}/layer`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {layers});
  },

  fetchCommtracNodes: async (dispatch: Dispatch) => {
    const commtrac_nodes = (
      await API.get<CommtracNodeListResponse>(`${apiBaseUrl}/commtrac-node`, {
        params: {
          limit: 2000,
          miner_status: 'all',
          asset_status: 'all',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {commtrac_nodes});
  },

  fetchAlarms: async (dispatch: Dispatch) => {
    const params = {};
    const alarm_logs = (
      await API.get<AlarmLogNodeListResponse>(`${apiBaseUrl}/alarm-module`, {
        params,
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {alarm_logs});
  },

  fetchAlarmGroups: async (dispatch: Dispatch) => {
    const params = {};
    const alarm_groups = (
      await API.get<AlarmGroupListResponse>(
        `${apiBaseUrl}/alarm-module-group`,
        {
          params,
        }
      )
    ).data.items;
    return assetsActions.setAssets(dispatch, {alarm_groups});
  },

  fetchNodes: async (dispatch: Dispatch) => {
    const nodes = (
      await API.get<NodeListResponse>(`${apiBaseUrl}/node`, {
        params: {
          limit: 2000,
          communication_node_status: 'all',
          wifi_point_status: 'all',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {nodes});
  },

  setAssetNodes: (dispatch: Dispatch, nodes: Node[]) => {
    return assetsActions.setAssets(dispatch, {nodes});
  },

  fetchGasMonitoring: async (dispatch: Dispatch) => {
    const gas_monitorin_nodes = (
      await API.get<GasMonitoringNodeListResponse>(`${apiBaseUrl}/ams`, {
        params: {
          limit: 2000,
          status: 'all',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {gas_monitorin_nodes});
  },

  fetchUsers: async (dispatch: Dispatch) => {
    const users = (
      await API.get<UserListResponse>(`${apiBaseUrl}/user`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {users});
  },

  fetchGasMonitorNodes: async (dispatch: Dispatch) => {
    const ams_nodes = (
      await API.get(`${apiBaseUrl}/ams`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
          status: 'all',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {ams_nodes});
  },

  fetchAmsEmodules: async (dispatch: Dispatch) => {
    const ams_emodules = (
      await API.get(`${apiBaseUrl}/ams/emodule`, {
        params: {
          limit: 2000,
          order: 'id',
          dir: 'ASC',
          status: 'all',
        },
      })
    ).data.items;
    return assetsActions.setAssets(dispatch, {ams_emodules});
  },

  setAmsEmodules: (dispatch: Dispatch, ams_emodules: AMSEModuleNode[]) => {
    return assetsActions.setAssets(dispatch, {ams_emodules});
  },

  clearAssets: (dispatch: Dispatch) => {
    const data: AssetsState = {
      ...assetsInitialState,
    };
    return dispatch({type: Actions.SetAssets, data});
  },
};

export default assetsActions;
