import {Box, Checkbox} from '@mui/material';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useRef} from 'react';
import {useSelector} from 'react-redux';

import usePrevious from '../../../hooks/usePrevious';
import {
  AmsEmoduleSensorHistoryItem,
  AmsEmoduleSensorHistoryResponse,
} from "../../../interfaces/AmsEmoduleSensorHistory";
import {ExportField} from '../../../interfaces/Export';
import reduxSelectors from '../../../redux/selectors';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {AmsEmoduleSensorHistoryTabConfig} from './index';

interface AmsEmoduleSensorHistoryGridProps {
  data?: AmsEmoduleSensorHistoryResponse;
  config?: AmsEmoduleSensorHistoryTabConfig;
  onChange?: (value: AmsEmoduleSensorHistoryTabConfig) => void;
}

export const AmsEmoduleSensorHistoryGrid = forwardRef((props: AmsEmoduleSensorHistoryGridProps, ref) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);

  const dataGridRef = useRef<DataGridRef>(null);
  useImperativeHandle(ref, () => ({
    print: () => {
      dataGridRef.current?.printTable()
    }
  }));

  const defaultConfig = {
    selectedIds: [],
    shownFields: [
      'id',
      'serial_number',
      'name',
      'value',
      'date',
    ],
  };
  const config = useMemo<AmsEmoduleSensorHistoryTabConfig>(() => ({
    ...defaultConfig,
    ...props.config,
  }), [props.config]);

  /*************/
  /* data grid */
  /*************/
  const rows = props.data?.items || [];

  const cols: DataGridColumn<AmsEmoduleSensorHistoryItem>[] = [
    {
      field: 'select',
      type: 'select',
      doNotExport: true,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={(config.selectedIds ?? []).length > 0 && selectedAll}
          indeterminate={(config.selectedIds ?? []).length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={(config.selectedIds ?? []).includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'serial_number',
      headerName: 'Serial Number',
      sortable: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
    },
  ];

  const getExportFields = (shownFields: string[]): ExportField[] => {
    return cols
      .filter((col) => !col.doNotExport)
      .map((col) => ({
        field: col.field,
        label: col.headerName,
        hidden: !(shownFields ?? []).includes(col.field),
      }));
  }

  const handleFieldUpdates = (shownFields?: string[]) => {
    if (config.id) {
      shownFields = shownFields ?? config.shownFields ?? [];
      const exportFields = config.exportFields ?? getExportFields(shownFields);
      props.onChange?.({
        ...config,
        shownFields,
        exportFields,
      });
    }
  };

  useEffect(() => {
    handleFieldUpdates();
  }, [config.id]);

  /*******************/
  /* multiple select */
  /*******************/
  const selectedRows = useMemo(() => (
    rows.filter((i) => (config.selectedIds ?? []).includes(i.id))
  ), [rows, config.selectedIds]);

  const selectedAll = useMemo(() => (
    rows.length === selectedRows.length
  ), [rows, selectedRows]);

  const toggleSelectItem = (id: number) => {
    const selectedIds = (config.selectedIds ?? []).includes(id)
      ? config.selectedIds?.filter((i) => i !== id)
      : [...(config.selectedIds || []), id];
    props.onChange?.({
      ...config,
      selectedIds,
    });
  };

  const selectAll = () => {
    props.onChange?.({
      ...config,
      selectedIds: rows?.map((i) => i.id) || [],
    });
  };

  const unselectAll = () => {
    props.onChange?.({
      ...config,
      selectedIds: [],
    });
  };

  const toggleSelectAllItems = () => {
    if ((config.selectedIds ?? []).length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  return (
    <Box
      bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
      height="100%"
      minWidth={400}
    >
      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={cols}
        size="small"
        pagination
        paginationMode="server"
        page={config.params?.page}
        pageSize={config.params?.limit}
        rowCount={props.data?.count}
        shownFields={config.shownFields}
        sxFooter={{
          bgcolor: () => isDarkMode ? '#2E2E2E' : '#FFF',
        }}
        footerStart={
          <Box display="flex" alignItems="center" gap={3} px={1}>
            <Box
              display="flex"
              gap={0.5}
              alignItems="center"
              height="100%"
              whiteSpace="nowrap"
            >
              {config.selectedIds?.length || 0} selected
            </Box>
          </Box>
        }
        onShownFieldsChange={handleFieldUpdates}
        onPageChange={(v) => {
          props.onChange?.({
            ...config,
            params: {
              ...config.params,
              page: v,
            },
          });
        }}
        onPageSizeChange={(v) => {
          props.onChange?.({
            ...config,
            params: {
              ...config.params,
              limit: v,
            },
          });
        }}
      />
    </Box>
  );
});
