import {LoadingButton} from '@mui/lab';
import {Box, Button} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  CommtracNode,
  CommutracNodeAssetReassignInputBody,
} from '../../interfaces/CommtracNode';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import {commtracNodeAssetReassignInputSchema} from '../../scheme/yup/commtrac-node';
import AssetMachineSelect from '../asset-machine/AssetMachineSelect';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  item: CommtracNode;
  onCancel?: () => void;
  onSubmitted?: (item: CommtracNode) => void;
}

type ReassignInputBody = CommutracNodeAssetReassignInputBody;

// eslint-disable-next-line complexity
const CommtracNodeItemAssetReassign = ({
  item,
  onCancel,
  onSubmitted,
}: Props) => {
  const reduxDispatch = useAppDispatch();

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: ReassignInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/commtrac-node/${item.id}/asset/reassign`;
      const resp = await API.patch<CommtracNode>(endpoint, data);
      const message = `Commtrac node has been reassigned`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAssetHumans);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const assets = useAppSelector(reduxSelectors.assets.getAssets);
  const assetMachines = useMemo(
    () => assets.asset_machines,
    [assets.asset_machines]
  );

  const getFormikValues = (item: CommtracNode): ReassignInputBody => ({
    asset_machine_id: item.employee_asset_id,
  });

  const formik = useFormik<ReassignInputBody>({
    initialValues: getFormikValues(item),
    validationSchema: commtracNodeAssetReassignInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <AssetMachineSelect
          value={formik.values.asset_machine_id}
          size="small"
          label="Select Machine"
          fullWidth
          nullLabel=""
          assetMachines={assetMachines}
          error={
            !!formik.touched.asset_machine_id &&
            !!formik.errors.asset_machine_id
          }
          onChange={(_, item) => {
            formik.setFieldValue('asset_machine_id', item?.id ?? null);
          }}
        />
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="end" gap={1}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
              disabled={!formik.values.asset_machine_id}
            >
              Reassign
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommtracNodeItemAssetReassign;
