export interface SubscriptionReportType {
  code: string;
  name: string;
}

export enum SubscriptionReportCode {
  MACHINE_TYPE_BAR = 'machineTypeBar',
  MACHINE_TYPE_LINE = 'machineTypeLine',
  MACHINE_DETAILS_BAR = 'machineDetailsBar',
  MACHINE_DETAILS_LINE = 'machineDetailsLine',
  MACHINE_INTERACTION_V2 = 'machineInteractionV2',
  MACHINE_HISTORY = 'machineHistory',
  MACHINE_SUMMARY = 'machineSummary',
  HUMAN = 'human',
  HUMAN_HISTORY = 'humanHistory',
  DAILY_INTERACTION = 'dailyInteractionReport',
  TOP_BOTTOM_INTERACTION = 'topBottomInteraction',
  CONNECT_COMMUNICATION_NODE = 'commtracCommunicationNodeGrid',
  CONNECT_MINER = 'commtracEmployeeGrid',
  CONNECT_ASSET = 'commtracAssetGrid',
  CONNECT_EVENT = 'commtracEventGrid',
  CONNECT_NETWORK_DIAGNOSTICS_GENERAL = 'commtracNetworkDiagnosticsGeneral',
  CONNECT_NETWORK_DIAGNOSTICS_ROUTING = 'commtracNetworkDiagnosticsRouting',
  CONNECT_NETWORK_DIAGNOSTICS_TIMING = 'commtracNetworkDiagnosticsTiming',
  AMS_SENSOR = 'amsSensorGrid',
  AMS_EVENT = 'amsEventGrid',
  AMS_VENTILATION_GRID = 'ventilationGrid',
  COS_SUMMARY_BAR = 'cosSummaryBar',
  COS_SUMMARY_GRID = 'checkoutStationSummary',
  COS_DETAILED_BAR = 'cosDetailsBar',
  COS_DETAILED_GRID = 'checkoutStationDetailed',
  SHAFT_CLEARANCE_MINER = 'shaftClearanceMiner',
  SHAFT_CLEARANCE_MINER_OUT = 'shaftClearanceMinerOut',
  SHAFT_CLEARANCE_MINER_NOT_OUT = 'shaftClearanceMinerNotOut',
  SHAFT_CLEARANCE_ASSET = 'shaftClearanceAsset',
}

export const SubscriptionReportFilter = {
  [SubscriptionReportCode.MACHINE_TYPE_BAR]: ['shift_id', 'zone_id'],
  [SubscriptionReportCode.MACHINE_TYPE_LINE]: ['shift_id', 'zone_id'],
  [SubscriptionReportCode.MACHINE_DETAILS_BAR]: ['shift_id', 'zone_id', 'type_id'],
  [SubscriptionReportCode.MACHINE_DETAILS_LINE]: ['shift_id', 'zone_id', 'type_id'],
  [SubscriptionReportCode.MACHINE_INTERACTION_V2]: ['zone_id'],
  [SubscriptionReportCode.MACHINE_HISTORY]: ['shift_id', 'external_id', 'zone_change'],
  [SubscriptionReportCode.MACHINE_SUMMARY]: ['shift_id', 'external_id'],
  [SubscriptionReportCode.HUMAN]: ['shift_id', 'external_id'],
  [SubscriptionReportCode.HUMAN_HISTORY]: ['shift_id', 'external_id'],
  [SubscriptionReportCode.DAILY_INTERACTION]: ['zone_id'],
  [SubscriptionReportCode.TOP_BOTTOM_INTERACTION]: ['zone_id', 'human_type_report'],
  [SubscriptionReportCode.CONNECT_COMMUNICATION_NODE]: ['section_ids', 'communication_node_status', 'wifi_point_status'],
  [SubscriptionReportCode.CONNECT_MINER]: ['section_ids', 'miner_status'],
  [SubscriptionReportCode.CONNECT_ASSET]: ['section_ids', 'asset_status'],
  [SubscriptionReportCode.CONNECT_EVENT]: ['type', 'acknowledged', 'cleared'],
  [SubscriptionReportCode.CONNECT_NETWORK_DIAGNOSTICS_GENERAL]: ['section_ids'],
  [SubscriptionReportCode.CONNECT_NETWORK_DIAGNOSTICS_ROUTING]: ['section_ids'],
  [SubscriptionReportCode.CONNECT_NETWORK_DIAGNOSTICS_TIMING]: ['section_ids'],
  [SubscriptionReportCode.AMS_SENSOR]: ['section_ids', 'status'], // ams_sensor_status
  [SubscriptionReportCode.AMS_EVENT]: ['type', 'acknowledged', 'cleared'],
  [SubscriptionReportCode.AMS_VENTILATION_GRID]: ['status'], // ams_ventilation_status
  [SubscriptionReportCode.COS_SUMMARY_BAR]: ['shift_id'],
  [SubscriptionReportCode.COS_SUMMARY_GRID]: ['shift_id'],
  [SubscriptionReportCode.COS_DETAILED_BAR]: ['shift_id'],
  [SubscriptionReportCode.COS_DETAILED_GRID]: ['shift_id'],
  [SubscriptionReportCode.SHAFT_CLEARANCE_MINER]: ['shift_id'],
  [SubscriptionReportCode.SHAFT_CLEARANCE_MINER_OUT]: ['shift_id'],
  [SubscriptionReportCode.SHAFT_CLEARANCE_MINER_NOT_OUT]: ['shift_id'],
  [SubscriptionReportCode.SHAFT_CLEARANCE_ASSET]: ['shift_id', 'zone_id', 'on_surface'],
};
