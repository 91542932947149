import {Button} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {User} from '../../interfaces/User';
import ModalFixed from '../common/ModalFixed';
import UserItemUpsert from './UserItemUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: User;
  prefetch?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: () => void;
}

const UserItemUpsertButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  onSubmitted,
  ...props
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component {...componentProps} onClick={() => setIsOpened(true)}>
        {children}
      </Component>

      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <UserItemUpsert
          {...props}
          onSubmitted={() => {
            onSubmitted?.();
            setIsOpened(false);
          }}
          onClose={() => setIsOpened(false)}
        />
      </ModalFixed>
    </>
  );
};

export default UserItemUpsertButton;
