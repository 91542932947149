import {Autocomplete, TextField, TextFieldProps} from '@mui/material';
import isNil from 'lodash/isNil';
import {useMemo} from 'react';

import {SafeyeNodeType} from '../../interfaces/SafeyeNode';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: SafeyeNodeType | null;
  onChange?: (value?: string | null) => void;
};

export const SafeyeNodeTypeSelect = ({
  value,
  disabled,
  fullWidth,
  size,
  onChange,
  ...props
}: Props) => {
  const nullValue = -1;

  const safeyeNodeType = [
    {id: 0, name: 'controller'},
    {id: 1, name: 'display'},
    {id: 2, name: 'hub'},
  ] as const;

  const options = useMemo(() => {
    return [...safeyeNodeType];
  }, []);

  const selectedOptionId = isNil(value)
    ? nullValue
    : options.find((option) => option.name === value)?.id;
  const selectedOption =
    options.find((item) => item.id === selectedOptionId) ?? null;

  return disabled ? (
    <TextField
      {...props}
      value={options.find((option) => option.name === value)?.name ?? ''}
      disabled={disabled}
      fullWidth={fullWidth}
      size={size}
    />
  ) : (
    <Autocomplete
      value={selectedOption ?? null}
      disabled={disabled}
      fullWidth={fullWidth}
      options={options}
      size={size}
      disableClearable={selectedOptionId === nullValue}
      isOptionEqualToValue={(o, v) => o.id === v.id}
      getOptionLabel={(o) => o.name ?? ''}
      renderInput={(params) => (
        <TextField label="Safeye Node Type" {...props} {...params} />
      )}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      onChange={(_, option) => {
        const v = !option ? null : option.name;
        onChange?.(v);
      }}
    />
  );
};
