export const normalizeIntensity = (intensity: number, maxIntensity: number) => {
  return intensity / maxIntensity;
};

export const defaultHeatmapConfig = () => {
  return {
    max: 0.01,
    color_gradients: [
      {
        intensity: 0.4,
        color: 'blue',
      },
      {
        intensity: 0.6,
        color: 'cyan',
      },
      {
        intensity: 0.7,
        color: 'lime',
      },
      {
        intensity: 0.8,
        color: 'yellow',
      },
      {
        intensity: 1,
        color: 'red',
      },
    ],
    radius: 20,
    blur: 15,
  };
};

export const generatePickColorSet = (
  colors: {
    intensity: number;
    color: string;
  }[]
) => {
  const convertedGradient: {[intensity: number]: string} = colors.reduce(
    (acc: any, cur) => {
      acc[cur.intensity] = cur.color;
      return acc;
    },
    {} as {[intensity: number]: string}
  ); // Type assertion

  return convertedGradient;
};

export const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371; // Radius of the Earth in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180; // Convert degrees to radians
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers
  return distance;
};
