import {Box, Paper, Typography} from '@mui/material';
import {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link as RouterLink} from 'react-router-dom';

import {usePanelCodes} from '../../utils/panel';
import {PanelCode, PanelProductCode, PANELS_DATA} from '../../utils/panels';
import DataGrid, {DataGridColumn} from '../common/DataGrid';
import DashboardPanelProductSelect from './DashboardPanelProductSelect';

const DashboardPanelList = () => {
  const {t} = useTranslation();
  const [productCode, setSetProductCode] = useState<PanelProductCode | null>(
    null
  );
  const panelCodes = usePanelCodes();

  const options = useMemo(() => {
    if (productCode) {
      return panelCodes.filter((i) =>
        PANELS_DATA[i].products.includes(productCode)
      );
    }

    return panelCodes;
  }, [productCode]);

  /*************/
  /* data grid */
  /*************/
  const rows = options.map((code) => ({code}));
  const columns: DataGridColumn<{code: PanelCode}>[] = [
    {
      field: 'name',
      renderCell: ({row}) => (
        <>
          <Typography
            component={RouterLink}
            color="primary"
            fontSize={18}
            sx={{textDecoration: 'none'}}
            to={`/panels/${row.code}`}
          >
            {t(`panels.${row.code}`)}
          </Typography>

          <Typography color="gray">
            {PANELS_DATA[row.code].products
              .map((i) => t(`products.${i}`))
              .join(', ')}
          </Typography>
        </>
      ),
    },
  ];

  return (
    <Paper sx={{position: 'relative'}}>
      <Box p={2}>
        <DashboardPanelProductSelect
          value={productCode}
          onChange={(event) =>
            setSetProductCode(event.target.value as PanelProductCode | null)
          }
        />
      </Box>

      <DataGrid rows={rows} columns={columns} pagination size="small" />
    </Paper>
  );
};

export default DashboardPanelList;
