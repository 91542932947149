import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import {Box, Button, Divider, IconButton, Tooltip} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {saveFile} from '../../../utils/file';
import AMSExportFieldForm from '../../common/AMSExportFieldForm';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';
import {GasMonitoringReportData} from '../ConnectView/GasMonitoringReport';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  value?: GasMonitoringReportData;
}

const AMSImportExportButton = <T extends ComponentType>({
  component,
  componentProps,
  children,
  value,
}: Props<T>) => {
  const Component = component ?? Button;
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const fetchData = async (fields: string[]) => {
    setIsFetching(true);
    try {
      const resp = await API.get(`${apiBaseUrl}/ams`, {
        params: {
          ...value?.params,
          export: 'excel',
          fields,
        },
        responseType: 'blob',
      });
      const filename =
        resp?.headers['content-disposition']?.split('filename=')?.[1] ||
        'ams_node_list.xlsx';
      saveFile(resp.data, filename);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsFetching(false);
  };

  return (
    <>
      <Tooltip title="Export">
        <Component {...componentProps} onClick={() => setIsOpened(true)}>
          {children}
        </Component>
      </Tooltip>

      {isOpened ? (
        <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
          <Box
            display="flex"
            flexDirection="column"
            position="relative"
            gap={3}
            p={3}
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" alignItems="center" width="100%" gap={1.5}>
                <ImportExportIcon sx={{color: 'primary.main'}} />
                <Box fontSize={24}>Export Sensors</Box>
              </Box>
              <IconButton onClick={() => setIsOpened(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              position="relative"
              gap={3}
            >
              <Divider>Export</Divider>
              <AMSExportFieldForm
                exportFields={value?.exportFields}
                loading={isFetching}
                onChange={fetchData}
              />
            </Box>
          </Box>
        </ModalFixed>
      ) : null}
    </>
  );
};

export default AMSImportExportButton;
