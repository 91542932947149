import WarningIcon from '@mui/icons-material/Warning';
import {Box, Button, Grid, Modal} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {BroadcastMessage} from '../../../interfaces/BroadcastMessage';
import BroadcastMessageUpsert from '../BroadcastMessageUpsert';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk?: number;
  item?: BroadcastMessage;
  prefetch?: boolean;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: (item: BroadcastMessage) => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
};

const BroadcastMessageUpsertButton = <T extends ComponentType = typeof Button>({
  pk,
  item,
  prefetch,
  component,
  componentProps,
  children,
  onSubmitted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);
  const title = 'Emergency Broadcast';

  return (
    <>
      <Component {...componentProps} onClick={() => setIsOpened(true)}>
        {children}
      </Component>

      <Modal open={isOpened} onClose={() => setIsOpened(false)}>
        <Box sx={style}>
          <Grid container mb={3}>
            <Grid item>
              <Box display="flex" alignItems="center" gap={2}>
                <WarningIcon fontSize="large" sx={{color: 'primary.main'}} />
                <Box fontSize={24}>{title}</Box>
              </Box>
            </Grid>
          </Grid>

          <BroadcastMessageUpsert
            pk={pk}
            item={item}
            prefetch={prefetch}
            onCancel={() => setIsOpened(false)}
            onSubmitted={(v) => {
              onSubmitted?.(v);
              setIsOpened(false);
            }}
          />
        </Box>
      </Modal>
    </>
  );
};

export default BroadcastMessageUpsertButton;
