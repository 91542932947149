import CellTowerIcon from '@mui/icons-material/CellTower';
import {LoadingButton} from '@mui/lab';
import {Box, Button, Grid, MenuItem, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import React, {useMemo, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  Backhauler,
  BackhaulerUpsertInputBody,
} from '../../interfaces/Backhauler';
import {container_name, ip_address, name} from '../../utils/regex';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  onCancel?: Function;
  onSubmitted?: () => void;
}

const BackHaulersItemUpsert: React.FC<Props> = ({onCancel, onSubmitted}) => {
  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: any) => {
    setSubmittedInProgress(true);
    try {
      console.log('submitData', data);

      const endpoint = `${apiBaseUrl}/backhauler`;
      await API.post<Backhauler>(endpoint, data);
      const message = `Backhauler has been created`;

      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      ip_address: yup
        .string()
        .nullable()
        .required('Field is required')
        .matches(ip_address.regex, ip_address.message),
      name: yup
        .string()
        .required('Field is required')
        .min(1, 'The minimum value for this field is 1')
        .matches(name.regex, name.message),
      container_name: yup
        .string()
        .required('Field is required')
        .matches(container_name.regex, container_name.message),
      port: yup.number().nullable().required('Field is required'),
    };

    return yup.object().shape(fields);
  }, []);

  const getFormikValues = (): BackhaulerUpsertInputBody => ({
    name: '',
    container_name: '',
    ip_address: '',
    port: null,
    check_in_gateway: false,
  });

  const formik = useFormik<any>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  return (
    <Box
      component="form"
      position="relative"
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Grid container mb={3}>
        <Grid item>
          <Box display="flex" alignItems="center" gap={2}>
            <CellTowerIcon fontSize="large" sx={{color: 'primary.main'}} />
            <Box fontSize={24}>Create BackHauler</Box>
          </Box>
        </Grid>
      </Grid>

      <Box>
        <Box my={4}>
          <TextField
            value={formik.values.name ?? ''}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={
              formik.touched.name &&
              formik.errors.name &&
              String(formik.errors.name)
            }
            onChange={formik.handleChange}
          />
        </Box>

        <Box my={4}>
          <TextField
            value={formik.values.container_name ?? ''}
            label="Container Name"
            size="small"
            name="container_name"
            fullWidth
            error={
              !!formik.touched.container_name && !!formik.errors.container_name
            }
            helperText={
              formik.touched.container_name &&
              formik.errors.container_name &&
              String(formik.errors.container_name)
            }
            onChange={formik.handleChange}
          />
        </Box>

        <Box my={4}>
          <TextField
            value={formik.values.ip_address ?? ''}
            label="IP Address"
            size="small"
            name="ip_address"
            rows={3}
            fullWidth
            onChange={formik.handleChange}
            error={!!formik.touched.ip_address && !!formik.errors.ip_address}
            helperText={
              formik.touched.ip_address &&
              formik.errors.ip_address &&
              String(formik.errors.ip_address)
            }
          />
        </Box>

        <Box my={4}>
          <TextField
            value={formik.values.port ?? ''}
            label="Port"
            size="small"
            name="port"
            type="number"
            rows={3}
            fullWidth
            onChange={formik.handleChange}
            error={!!formik.touched.port && !!formik.errors.port}
            helperText={
              formik.touched.port &&
              formik.errors.port &&
              String(formik.errors.port)
            }
          />
        </Box>

        <Box my={4}>
          <TextField
            value={formik.values.check_in_gateway ? 1 : 0}
            fullWidth
            name="check_in_gateway"
            label="Use for Checkin"
            select
            size="small"
            error={
              !!formik.touched.check_in_gateway &&
              !!formik.errors.check_in_gateway
            }
            helperText={formik.touched.check_in_gateway && ''}
            onChange={formik.handleChange}
          >
            {[
              {value: 0, name: 'No'},
              {value: 1, name: 'Yes'},
            ].map((i) => (
              <MenuItem key={i.value} value={i.value}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Box display="flex" justifyContent="space-between">
        <Button onClick={() => formik.resetForm()}>Reset</Button>
        <Box display="flex" justifyContent="end">
          <Button onClick={() => onCancel?.()}>Cancel</Button>

          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ml: 1}}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BackHaulersItemUpsert;
