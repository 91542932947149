import {AssetMachine} from '../../interfaces/AssetMachine';
import AssetMachineItem from './AssetMachineItem';
import AssetMachineItemCreate from './AssetMachineItemCreate';

interface Props {
  pk?: number;
  item?: AssetMachine;
  mode?: 'view' | 'update' | 'update_from_info' | 'chat';
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (item: AssetMachine) => void;
}

const AssetMachineItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  return pk ? (
    <AssetMachineItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      onClose={onClose}
      onSubmitted={onSubmitted}
      onPurged={onPurged}
      onOpenHistory={onOpenHistory}
    />
  ) : (
    <AssetMachineItemCreate onCancel={onClose} onSubmitted={onSubmitted} />
  );
};

export default AssetMachineItemUpsert;
