import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Tab,
  TextField,
} from '@mui/material';
import {capitalize} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {WifiPoint} from '../../interfaces/WifiPoint';
import AccessControl from '../common/AccessControl';
import StatusSelect from '../selectors/StatusSelect';
import WifiPointTypeSelect from '../selectors/WifiPointTypeSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import WifiPointItemActionsButton from './WifiPointItemActionsButton';
import WifiPointItemHistoryButton from './WifiPointItemHistoryButton';

interface Props {
  pk: number;
  item?: WifiPoint;
  prefetch?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onAck?: () => void;
  onPurged?: () => void;
  onFetched?: (item: WifiPoint) => void;
  onSubmitted?: () => void;
  onOpenHistory?: (type: 'wifi' | 'wifiLongTerm') => void;
}

const tabs = [
  {value: 'general', label: 'General'},
  {value: 'connection', label: 'Connection'},
  {value: 'state', label: 'State'},
];

const WifiPointItemView = ({
  pk,
  item,
  prefetch,
  onCancel,
  onEdit,
  onAck,
  onPurged,
  onFetched,
  onOpenHistory,
  onSubmitted,
}: Props) => {
  const [activeTab, setActiveTab] = useState('general');

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<WifiPoint | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<WifiPoint>(
        `${apiBaseUrl}/node/${pk}/wifi-point`
      );
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.name ?? ''}
              label="Name"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/node/:id/wifi-point']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <WifiPointTypeSelect
              value={fetchedData?.type}
              label="Node Type"
              size="small"
              disabled
              fullWidth
              SelectProps={{
                inputProps: {IconComponent: () => null},
              }}
            />
            <StatusSelect
              value={fetchedData?.status}
              fullWidth
              label="Status"
              size="small"
              disabled
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/node/:id/wifi-point']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <ZoneSelect
              value={fetchedData?.zone}
              label="Section"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/node/:id/wifi-point']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <TextField
              value={fetchedData?.latitude ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/node/:id/wifi-point']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
            <TextField
              value={fetchedData?.longitude ?? ''}
              label="Longitude"
              size="small"
              disabled
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <AccessControl permissions={['patch::/node/:id/wifi-point']}>
              //       <IconButton
              //         color="primary"
              //         size="small"
              //         onClick={() => onEdit?.()}
              //       >
              //         <EditIcon fontSize="small" />
              //       </IconButton>
              //     </AccessControl>
              //   ),
              // }}
            />
          </Box>
        </TabPanel>
        <TabPanel value="connection" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.ip ?? ''}
              label="IP"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.serial_number ?? ''}
              label="Serial Number"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.mac_switch ?? ''}
              label="Switch"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.mac_radio_1 ?? ''}
              label="Radio 1"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.mac_radio_2 ?? ''}
              label="Radio 2"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="state" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={capitalize(fetchedData?.connection_status ?? '')}
              label="Connection Status"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.last_ping ?? ''}
              label="Last Ping"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.last_response ?? ''}
              label="Last Response"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.last_latency ?? ''}
              label="Last Latency"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
      </TabContext>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="start" gap={0.5}>
          {onOpenHistory ? (
            <WifiPointItemHistoryButton onOpen={onOpenHistory}>
              History
            </WifiPointItemHistoryButton>
          ) : null}
          {fetchedData ? (
            <>
              {onAck && fetchedData?.ack !== '1' ? (
                <AccessControl
                  permissions={['patch::/node/:id/wifi-point/acknowledge']}
                >
                  <Button onClick={() => onAck()}>Acknowledge</Button>
                </AccessControl>
              ) : null}
              <AccessControl permissions={['patch::/node/:id/wifi-point']}>
                <WifiPointItemActionsButton
                  item={fetchedData}
                  component={IconButton}
                  componentProps={{size: 'small', color: 'primary'}}
                  onSubmitted={() => {
                    fetchData();
                    onSubmitted?.();
                  }}
                  onPurged={onPurged}
                >
                  <MoreHorizIcon />
                </WifiPointItemActionsButton>
              </AccessControl>
            </>
          ) : null}
        </Box>
        <Box display="flex" justifyContent="end" gap={0.5}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <AccessControl permissions={['patch::/node/:id/wifi-point']}>
              <Button onClick={() => onEdit()}>Edit Wifi</Button>
            </AccessControl>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default WifiPointItemView;
