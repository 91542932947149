import axios, {AxiosResponse} from 'axios';

import {RefreshTokenResponse} from '../interfaces/RefreshToken';
import reduxActions from '../redux/actions';
import reduxSelectors from '../redux/selectors';
import {store} from '../redux/store';
import {sleep} from '../utils/sleep';
import {apiBaseUrl} from './urls';

export const getMessagesFromApiError = (error: any) =>
  (error?.response?.data?.errors as string[]) ?? ['There is an error'];

const API = axios.create({
  baseURL: apiBaseUrl,
});

let refreshStartedAt: number | null = null;

const checkIsUnauthResponse = (response?: AxiosResponse) =>
  response?.status === 401 &&
  response?.data?.errors?.includes('authentication required');

const checkIsPassExpiredResponse = (response?: AxiosResponse) =>
  response?.status === 401 &&
  response?.data?.errors?.includes('password reset required');

API.interceptors.request.use(async (config) => {
  const state = store.getState();
  const accessToken = state.auth.accessToken;
  const companyId = state.app.companyId;

  if (accessToken && config?.headers) {
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  if (companyId && config?.headers) {
    config.headers['X-Company-ID'] = companyId;
  }

  return config;
});

API.interceptors.response.use(
  async (response) => {
    const isUnauthResponse = checkIsUnauthResponse(response);
    if (isUnauthResponse) {
      reduxActions.auth.clearAuthTokens(store.dispatch);
    }
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    // Refresh token
    const isUnauthResponse = checkIsUnauthResponse(error.response);
    if (isUnauthResponse) {
      const {refreshToken} = reduxSelectors.auth.getAuth(store.getState());

      if (!originalRequest._retry && refreshToken) {
        if (!refreshStartedAt) {
          refreshStartedAt = Date.now();
          try {
            const resp = await API.post<RefreshTokenResponse>(
              '/auth/refresh-token',
              {refresh_token: refreshToken}
            );

            reduxActions.auth.setAuthTokens(store.dispatch, {
              accessToken: resp.data.token,
              refreshToken: resp.data.refresh_token,
            });
          } catch (e) {
            reduxActions.auth.clearAuthTokens(store.dispatch);
          }
          refreshStartedAt = null;
        } else {
          while (Date.now() - refreshStartedAt < 60000) {
            await sleep(1000);
          }
        }

        const {accessToken} = reduxSelectors.auth.getAuth(store.getState());

        if (accessToken) {
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          originalRequest._retry = true;
          return API(originalRequest);
        }
      }

      reduxActions.auth.clearAuthTokens(store.dispatch);
    }

    const isPassExpiredResponse = checkIsPassExpiredResponse(error.response);
    if (isPassExpiredResponse) {
      reduxActions.auth.clearAuthTokens(store.dispatch);
      reduxActions.auth.setAuthTokens(store.dispatch, {
        passwordToken: error.response.data.metadata.token,
      });
    }

    throw error;
  }
);

export default API;
