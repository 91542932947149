import {CopyAllRounded} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {SnackbarKey, useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {Dashboard, DashboardUpsertInputBody} from '../../interfaces/Dashboard';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  pk?: number;
  item?: Dashboard;
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: (item: Dashboard) => void;
}

const DashboardItemAssignItemModal = ({
  pk,
  item,
  prefetch,
  onClose,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const company = useAppSelector(({assets}) => assets.company);

  const product_options: {
    id: number;
    value:
      | 'proximity'
      | 'ams'
      | 'belt'
      | 'connect'
      | 'shaft_clearence'
      | 'ventilation'
      | 'hazard_ai'
      | 'alarm';
    name: string;
    is_active: boolean;
  }[] = useMemo(
    () => [
      {
        id: 0,
        value: 'proximity',
        name: 'Proximity',
        is_active: !!company?.proximity_enabled,
      },
      {
        id: 1,
        value: 'ams',
        name: 'Gas Monitoring',
        is_active: !!company?.ams_enabled,
      },
      {
        id: 2,
        value: 'belt',
        name: 'Belt Monitoring',
        is_active: !!company?.belt_enabled,
      },
      {
        id: 3,
        value: 'connect',
        name: 'Connect',
        is_active: !!company?.commtrac_enabled,
      },
      {
        id: 4,
        value: 'shaft_clearence',
        name: 'Shaft Clearence',
        is_active: !!company?.shaft_enabled,
      },
      {
        id: 5,
        value: 'ventilation',
        name: 'Ventilation',
        is_active: !!company?.ventilation_enabled,
      },
      {
        id: 6,
        value: 'hazard_ai',
        name: 'Hazard AI',
        is_active: !!company?.hazard_ai_enabled,
      },
      {
        id: 7,
        value: 'alarm',
        name: 'Alarm Module',
        is_active: !!company?.alarm_enabled,
      },
    ],
    [company]
  );

  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<{
    id: number;
    value:
      | 'proximity'
      | 'ams'
      | 'belt'
      | 'connect'
      | 'shaft_clearence'
      | 'ventilation'
      | 'hazard_ai'
      | 'alarm';
    name: string;
  } | null>(null);

  const fetchData = async () => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<Dashboard>(`${apiBaseUrl}/dashboard/${pk}`);
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: DashboardUpsertInputBody) => {
    setSubmittedInProgress(true);
    try {
      const payload = {
        name: data?.name,
        product: data?.product,
        data: item?.data,
      };
      console.log(payload);
      const endpoint = `${apiBaseUrl}/dashboard/${pk}`;
      const resp = await API.patch<Dashboard>(endpoint, payload);
      const message = `Dashboard successfully assigned to Product [${selectedProduct?.name}].`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });

      onSubmitted?.(resp.data);
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key: SnackbarKey | undefined) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object().shape({
    name: yup.string().nullable().required('Field is required'),
    product: yup.string().nullable().required('Field is required'),
  });

  const getFormikValues = (item?: Dashboard): DashboardUpsertInputBody => ({
    name: item?.name ?? null,
    product: item?.product,
  });

  const formik = useFormik<DashboardUpsertInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" width="100%" gap={1.5}>
          <CopyAllRounded sx={{color: 'primary.main'}} />
          <Box fontSize={24}>Assign the Dashboard to Product</Box>
        </Box>
        {onClose ? (
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <Autocomplete
          value={selectedProduct}
          options={product_options?.filter((it) => !!it?.is_active)}
          isOptionEqualToValue={(o, v) => o.id === v.id}
          getOptionLabel={(o) => o.name ?? ''}
          filterOptions={(options) => options}
          renderInput={(params) => (
            <TextField
              label="Select a product"
              {...params}
              error={!!formik.touched.product && !!formik.errors.product}
              helperText={formik.touched.product && formik.errors.product}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.id}>
                {option.name}
              </li>
            );
          }}
          onChange={(_, option) => {
            console.log(option?.value);
            formik.setFieldValue('product', option?.value);
            setSelectedProduct(option);
          }}
        />
      </Box>
      <Box display="flex" justifyContent="end" gap={0.5}>
        {onClose ? <Button onClick={() => onClose()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ml: 1}}
          >
            Assign To Product
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardItemAssignItemModal;
