import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {GasMonitoringNode} from '../../../interfaces/GasMonitoringNode';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  item?: GasMonitoringNode;
  onSubmitted?: (item: GasMonitoringNode) => void;
  onClose?: () => void;
  action: 'disable_events' | 'enable_events';
}

const AMSSensorItemDisableEventsModal = ({
  item,
  onSubmitted,
  onClose,
  action,
  ...props
}: Props) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      const payload = {
        disable: action === 'disable_events',
      };

      const resp = await API.patch<GasMonitoringNode>(
        `${apiBaseUrl}/ams/${pk}/disable_event`,
        payload
      );
      const message = `Events has been ${action === 'disable_events' ? 'disabled' : 'enabled'} successfully.`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.(resp.data);
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>
        {action === 'disable_events' ? 'Disable Events' : 'Enable Events'}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to{' '}
          {action === 'disable_events' ? 'Disable Events' : 'Enable Events'} on
          this sensor?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(item?.id ?? -1)}
          color={action === 'disable_events' ? 'warning' : 'success'}
        >
          {action === 'disable_events' ? 'Disable Events' : 'Enable Events'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AMSSensorItemDisableEventsModal;
