import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import {t} from 'i18next';
import update from 'immutability-helper';
import _ from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {
  AmsEmoduleSensorHistoryQuery,
  AmsEmoduleSensorHistoryResponse,
} from "../../../interfaces/AmsEmoduleSensorHistory";
import {
  DashboardEntity,
  DashboardPanelData
} from '../../../interfaces/Dashboard';
import {ExportField} from '../../../interfaces/Export';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {ResizableColumns} from '../../common/ResizableColumns';
import TabLabel from '../../common/TabLabel';
import {
  usePanel,
} from '../../dashboards/entities/DashboardEntityContext';
import {AmsEmoduleObjectSelect} from '../../selectors/AmsEmoduleObjectSelect';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {AmsEmoduleSensorHistoryChart} from './AmsEmoduleSensorHistoryChart';
import {AmsEmoduleSensorHistoryExportButton} from './AmsEmoduleSensorHistoryExportButton';
import {AmsEmoduleSensorHistoryExportModal} from './AmsEmoduleSensorHistoryExportModal';
import {AmsEmoduleSensorHistoryGrid} from './AmsEmoduleSensorHistoryGrid';

export interface AmsEmoduleSensorHistoryTabConfig {
  id?: number;
  refreshInterval?: number;
  selectedIds?: number[];
  shownFields?: string[];
  exportFields?: ExportField[];
  params?: AmsEmoduleSensorHistoryQuery;
}

interface AmsEmoduleSensorHistoryConfig {
  activeId?: number;
  openedItems?: AmsEmoduleSensorHistoryTabConfig[];
}

interface AmsEmoduleSensorHistoryData {
  viewType: 'amsEmoduleSensorHistory';
  amsEmoduleSensorHistory: AmsEmoduleSensorHistoryConfig;
}

const getDefaultAmsEmoduleSensorHistoryData = (): AmsEmoduleSensorHistoryData => {
  return {
    viewType: 'amsEmoduleSensorHistory',
    amsEmoduleSensorHistory: {},
  };
};

interface Props {
  value?: DashboardPanelData;
  entities?: DashboardEntity[];
  onUpdate?: (value?: DashboardPanelData) => void;
}

export const AmsEmoduleSensorHistory = (props: Props) => {
  const isOpenAwayFromConnectView = !props.entities?.map((e) => e.name).includes('Connect View');
  const gridRef = useRef();
  const amsEmodules = useAppSelector(({assets}) => assets.ams_emodules);

  const [panel] = usePanel();

  const [fetchedData, setFetchedData] =
    useState<AmsEmoduleSensorHistoryResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [exportModalOpened, setExportModalOpened] = useState(false);

  const viewData = _.isEmpty(props.value)
    ? getDefaultAmsEmoduleSensorHistoryData()
    : props.value as AmsEmoduleSensorHistoryData;
  const [config, setConfig] = useState<AmsEmoduleSensorHistoryConfig>({
    ...viewData.amsEmoduleSensorHistory,
  });

  // We need to track activeId change passed from ConnectView->onOpenHistory handler
  useEffect(() => {
    viewData.amsEmoduleSensorHistory.activeId &&
    viewData.amsEmoduleSensorHistory.openedItems?.length &&
    viewData.amsEmoduleSensorHistory.openedItems?.length > 1 &&
      openTab(viewData.amsEmoduleSensorHistory.activeId);
  }, [viewData.amsEmoduleSensorHistory.activeId]);

  const defaultTabConfig: AmsEmoduleSensorHistoryTabConfig = {
    params: {
      date_start: dayjs().format('YYYY-MM-DD'),
      date_end: dayjs().format('YYYY-MM-DD'),
      limit: 50,
      page: 0,
    }
  };
  const openedItem = useMemo(() => (!config.activeId ? undefined : {
    ...defaultTabConfig,
    ...config.openedItems?.find((it) => it.id === config.activeId)
  }), [config.openedItems, config.activeId]);

  const openedItemIndex = useMemo(() => (
    config.openedItems?.findIndex((i) => i.id === openedItem?.id) ?? -1
  ), [openedItem]);

  useEffect(() => {
    if (openedItem?.id) {
      fetchData();
    }
  }, [openedItem?.id, openedItem?.params]);

  useEffect(() => {
    if (openedItem?.id) {
      props?.onUpdate?.(
        update(viewData, {
          amsEmoduleSensorHistory: {
            $set: config,
          },
        })
      );
    }
  }, [openedItem]);

  const fetchData = useCallback(async () => {
    if (!openedItem?.id) {
      return;
    }

    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<AmsEmoduleSensorHistoryResponse>(
        `${apiBaseUrl}/ams/emodule/${openedItem.id}/sensor-history`,
        {params: openedItem.params}
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  }, [openedItem?.id, openedItem?.params]);

  const openTab = (id: number) => {
    const tabs = [...config.openedItems ?? []];
    const existingTab = tabs.find((t) => t.id === id);
    if (!existingTab) {
      tabs.push({id});
    }
    setConfig(
      update(config, {
        activeId: {
          $set: id,
        },
        openedItems: {
          $set: tabs,
        }
      })
    );
  };

  const removeTab = (id: number) => {
    const tabs = [...config.openedItems ?? []];
    if (tabs.length <= 1) {
      return;
    }

    const tabIdx = tabs.findIndex((t) => t.id === id);
    if (tabIdx > -1) {
      tabs.splice(tabIdx, 1);
    }
    const activeTab = tabs.find((t) => t.id === config.activeId);
    setConfig(
      update(config, {
        activeId: {
          $set: activeTab?.id ?? tabs[0].id,
        },
        openedItems: {
          $set: tabs,
        }
      })
    );
  };

  const getAmsEmoduleName = (id?: number) => {
    const amsModule = amsEmodules.find((m) => m.id === id);
    return amsModule?.ams?.name ?? `E-module #${amsModule?.id}`;
  };

  useRefreshInterval(fetchData, openedItem?.refreshInterval);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        height="calc(100% - 38px)"
      >
        <DashboardPanelTitleSlot>
          {t(`panels.${panel?.code}`)}
        </DashboardPanelTitleSlot>

        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {/* Errors */}
        {fetchedErrors.map((error, idx) => (
          <Alert
            key={idx}
            severity="error"
            onClose={() => openedItem && fetchData()}
          >
            {error}
          </Alert>
        ))}

        {/* Filters */}
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          py={1}
        >
          {isOpenAwayFromConnectView && (
            <Box>
              <AmsEmoduleObjectSelect
                onChange={openTab}
              />
            </Box>
          )}
          <Box pb={2}>
            {config.openedItems?.length && (
              <Tabs
                value={openedItem?.id}
                variant="scrollable"
                onChange={(_event, v) => {
                  const tab = config.openedItems?.find((t) => t.id === v);
                  if (tab?.id) {
                    openTab(tab.id);
                  }
                }}
              >
                {config.openedItems.map((t) => (
                  <Tab
                    key={t.id}
                    value={t.id}
                    label={
                      <TabLabel
                        name={getAmsEmoduleName(t.id)}
                        onClose={() => t.id && removeTab(t.id)}
                      />
                    }
                  />
                ))}
              </Tabs>
            )}
            {!config.openedItems?.length && (
              <Alert severity="warning">No e-module object selected</Alert>
            )}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box maxWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(openedItem?.params?.date_start).toDate(),
                  dayjs(openedItem?.params?.date_end).toDate(),
                ]}
                disabled={!openedItem}
                onChange={(v) => {
                  if (openedItem) {
                    setConfig(
                      update(config, {
                        openedItems: {
                          [openedItemIndex]: {
                            $set: {
                              ...openedItem,
                              params: {
                                ...openedItem.params,
                                date_start: v?.[0]
                                  ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                  : undefined,
                                date_end: v?.[0]
                                  ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                  : undefined,
                              },
                            },
                          },
                        },
                      })
                    );
                  }
                }}
              />
            </Box>

            <Box display="flex">
              <ButtonGroup disabled={!openedItem}>
                <AmsEmoduleSensorHistoryExportButton
                  size="small"
                  disabled={!openedItem}
                  onClick={() => setExportModalOpened(true)}
                />

                <Button
                  size="small"
                  onClick={fetchData}
                >
                  <RefreshIcon />
                </Button>

                <AutoRefreshSelect
                  value={openedItem?.refreshInterval ?? null}
                  onChange={(v) => {
                    if (openedItem) {
                      setConfig(
                        update(config, {
                          openedItems: {
                            [openedItemIndex]: {
                              $set: {
                                ...openedItem,
                                refreshInterval: v as number,
                              },
                            },
                          },
                        })
                      );
                    }
                  }}
                />

                <Button
                  size="small"
                  onClick={() => {
                    // @ts-ignore
                    gridRef.current.print();
                  }}
                >
                  <PrintIcon />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>

        <ResizableColumns
          left={(
            <Box
              pr="24px"
              height="100%"
            >
              {/* Grid */}
              <AmsEmoduleSensorHistoryGrid
                ref={gridRef}
                data={fetchedData}
                config={openedItem}
                onChange={(v) => {
                  setConfig(
                    update(config, {
                      openedItems: {
                        [openedItemIndex]: {
                          $set: v,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
          )}
        >
          {/* Chart */}
          <AmsEmoduleSensorHistoryChart
            data={fetchedData}
            config={openedItem}
          />
        </ResizableColumns>
      </Box>

      {exportModalOpened && (
        <AmsEmoduleSensorHistoryExportModal
          value={openedItem}
          opened={exportModalOpened}
          onClose={() => setExportModalOpened(false)}
          onSubmit={(exportFields) => {
            if (openedItem) {
              setConfig(
                update(config, {
                  openedItems: {
                    [openedItemIndex]: {
                      $set: {
                        ...openedItem,
                        exportFields,
                      }
                    }
                  },
                })
              );
            }
            setExportModalOpened(false);
          }}
        />
      )}
    </>
  );
};
