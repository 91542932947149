import {InputProps, MenuItem, TextField} from '@mui/material';
import _ from 'lodash';
import {useState} from 'react';

import {
  ASSET_TYPES
} from '../../constants/node';
import {
  ASSET_STATUSES,
  AssetStatus
} from '../../constants/status';
import {
  SingleMultiSelectProps
} from './SingleMultiSelect';

type Props = Omit<SingleMultiSelectProps, 'options' | 'value'> & {
  value: [string, string]
};

export const AssetsTypeStatusSelect = (props: Props) => {
  const [selected, setSelected] = useState<string[]>(props.value as string[]);

  const typeOptions = [
    { id: 'typeAll', name: 'All' },
    ...ASSET_TYPES.map((o) => ({
      id: o.key,
      name: o.name
    }))
  ];

  const statusOptions = ASSET_STATUSES.map((o) => ({
    id: o.key,
    name: o.name,
  }));

  const getTypeLabel = (id: string) => typeOptions.find((o) => o.id === id)?.name;

  const getStatusLabel = (id: string) => statusOptions.find((o) => o.id === id)?.name;

  const handleChange: InputProps['onChange'] = (e) => {
    const v = _.last(e.target.value) as string;

    const types = typeOptions.map((o) => o.id);
    const statuses = statusOptions.map((o) => o.id);

    selected[0] = types.includes(v) ? v : selected[0];
    selected[1] = statuses.includes(v as AssetStatus) ? v : selected[1];

    setSelected([...selected]);
  };

  const handleClose = () => {
    props.onChange?.(selected);
  };

  return (
    <TextField
      {...props}
      label={props.label || 'Employees/Assets'}
      value={selected}
      select
      SelectProps={{
        multiple: true,
        renderValue: (v) => {
          const type = getTypeLabel((v as string[])[0]);
          const status = getStatusLabel((v as string[])[1]);
          return <>Type: {type}, Status: {status}</>;
        },
        onClose: handleClose,
      }}
      onChange={handleChange}
    >
      <MenuItem disabled>
        TYPE
      </MenuItem>

      {typeOptions.map((option) => (
        <MenuItem
          key={option.id ?? ''}
          value={option.id ?? ''}
        >
          {option.name}
        </MenuItem>
      ))}

      <MenuItem divider />

      <MenuItem disabled>
        STATUS
      </MenuItem>

      {statusOptions.map((option) => (
        <MenuItem
          key={option.id ?? ''}
          value={option.id ?? ''}
        >
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
