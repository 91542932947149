/* eslint-disable no-bitwise */
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  amsSensorAddressMask,
  GasMonitoringNode,
} from '../../../interfaces/GasMonitoringNode';
import reduxSelectors from '../../../redux/selectors';
import DataGrid, {DataGridColumn} from '../../common/DataGrid';

interface Props {
  pk: number;
  item?: GasMonitoringNode;
  onFetched?: () => void;
}

const AMSSensorNodeItemAVA = ({pk, item, onFetched}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const ams_nodes = assets.ams_nodes;
  const rows = item?.avas ?? [];
  const ava_nodes = useMemo(() => {
    const assigned_ava_ids = item?.avas?.map((it) => it?.id);
    return ams_nodes
      ?.filter((it) => !!it?.ava)
      ?.filter((it) => !assigned_ava_ids?.includes(it?.id));
  }, [ams_nodes, item]);

  // Assign AVA
  const [assignAVAID, setAssignAVAID] = useState<string | undefined>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);

  const submitAssign = useCallback(async () => {
    setIsSubmitting(true);
    try {
      await API.patch<GasMonitoringNode>(`${apiBaseUrl}/ams/${pk}/ava`, {
        ava_commtrac_external_id: [assignAVAID],
        assign: true,
      });
      onFetched?.();
    } catch (err: any) {
      const messages = getMessagesFromApiError(err);
      setFetchedErrors(messages);
    }
    setIsSubmitting(false);
  }, [assignAVAID]);

  const [isUnassiginSubmitting, setIsUnassiginSubmitting] =
    useState<boolean>(false);
  const submitUnAssign = async (ava_id: number) => {
    setIsUnassiginSubmitting(true);
    try {
      await API.patch<GasMonitoringNode>(`${apiBaseUrl}/ams/${pk}/ava`, {
        ava_commtrac_external_id: [ava_id],
        assign: false,
      });
      onFetched?.();
    } catch (err: any) {
      const messages = getMessagesFromApiError(err);
      setFetchedErrors(messages);
    }
    setIsUnassiginSubmitting(false);
  };

  const columns: DataGridColumn<GasMonitoringNode>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      valueGetter: ({row}) => row.id,
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => row.name,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => row?.commtrac_external_id & amsSensorAddressMask,
    },
    {
      field: 'actions',
      headerName: 'UnAssign',
      type: 'actions',
      doNotExport: true,
      sxHeader: {textAlign: 'center', p: 0.5},
      sxCell: {textAlign: 'center', p: 0.5},
      renderCell: ({row}) => (
        <IconButton
          color="error"
          onClick={() => {
            submitUnAssign(row.commtrac_external_id);
          }}
        >
          <RemoveCircleIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={isUnassiginSubmitting} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}

      <Box display="flex" flexDirection="column" gap={5}>
        {rows?.length > 0 && (
          <DataGrid
            rows={rows}
            columns={columns}
            loading={false}
            pagination
            size="small"
          />
        )}
        <Box display="flex" alignItems="center">
          <TextField
            fullWidth
            label="AVAs"
            select
            size="small"
            value={assignAVAID}
            SelectProps={{
              multiple: false,
            }}
            onChange={(el) => {
              setAssignAVAID(el.target.value);
            }}
          >
            {ava_nodes
              ?.map((it) => ({
                id: it?.commtrac_external_id,
                name: it?.name,
              }))
              .map((option) => (
                <MenuItem key={option.id ?? ''} value={option.id ?? ''}>
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
          <LoadingButton
            onClick={submitAssign}
            loading={isSubmitting}
            sx={{
              width: '100px',
              height: '40px',
              border: '1px solid',
              marginLeft: '10px',
            }}
          >
            Assign
          </LoadingButton>
        </Box>
      </Box>

      {/* <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="start" gap={0.5}>
          <Box position="relative">
            <Button onClick={(event) => setAnchorEl(event.currentTarget)}>
              History
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={menuOpen}
              onBackdropClick={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => {}}>Long Term History Report</MenuItem>
              <MenuItem onClick={() => {}}>Short Term History Reports</MenuItem>
              <MenuItem onClick={() => {}}>Location Report</MenuItem>
            </Menu>
          </Box>
          <IconButton
            onClick={(event) => setAnchorElMore(event.currentTarget)}
            size="small"
            color="primary"
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElMore}
            open={menuOpenMore}
            onBackdropClick={() => setAnchorElMore(null)}
          >
            <MenuItem onClick={() => {}}>
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Device
            </MenuItem>
            <MenuItem onClick={() => {}}>
              <RemoveCircleOutlineIcon fontSize="small" sx={{mr: 1.5}} />
              Purge Transaction
            </MenuItem>
          </Menu>

          {fetchedData ? (
            <>
              {onAck && fetchedData?.ack !== '1' ? (
                <Button onClick={() => onAck()}>Acknowledge</Button>
              ) : null}
            </>
          ) : null}
        </Box>
        <Box display="flex" justifyContent="end" gap={0.5}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <Button onClick={() => onEdit()}>Edit Sensor</Button>
          ) : null}
        </Box>
      </Box> */}
    </Box>
  );
};

export default AMSSensorNodeItemAVA;
