import {Button, ButtonGroup} from '@mui/material';
import {Box} from '@mui/system';
import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';
import {
  getNetworkDiagnosticsGeneralData,
  NetworkDiagnosticsGeneral,
  NetworkDiagnosticsGeneralData,
} from './NetworkDiagnosticsGeneral';
import {
  getNetworkDiagnosticsRoutingData,
  NetworkDiagnosticsRouting,
  NetworkDiagnosticsRoutingData,
} from './NetworkDiagnosticsRouting';
import {
  getNetworkDiagnosticsTimingData,
  NetworkDiagnosticsTiming,
  NetworkDiagnosticsTimingData,
} from './NetworkDiagnosticsTiming';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

export interface NetworkDiagnosticsData extends DashboardPanelData {
  viewType: 'general' | 'routing' | 'timing';
  title: string;
  general: NetworkDiagnosticsGeneralData;
  routing: NetworkDiagnosticsRoutingData;
  timing: NetworkDiagnosticsTimingData;
}

export const getNetworkDiagnosticsData = (): NetworkDiagnosticsData => ({
  viewType: 'general',
  title: 'General Network Diagnostics',
  general: getNetworkDiagnosticsGeneralData(),
  timing: getNetworkDiagnosticsTimingData(),
  routing: getNetworkDiagnosticsRoutingData(),
});

export const NetworkDiagnostics = ({value, onUpdate}: Props) => {
  const location = useLocation();

  /****************/
  /* panel config */
  /****************/
  const config = useMemo<NetworkDiagnosticsData>(
    () =>
      !isEmpty(value)
        ? (value as NetworkDiagnosticsData)
        : getNetworkDiagnosticsData(),
    [value]
  );

  const viewType = useMemo<'general' | 'routing' | 'timing'>(
    () => config?.viewType,
    [config?.viewType]
  );

  const isOpenFromPanel = useMemo(() => {
    return location.pathname.includes('/panels/');
  }, [location]);

  return (
    <>
      <DashboardPanelTitleSlot>
        {config?.title}
      </DashboardPanelTitleSlot>

      <Box position="absolute" top={8} left={8}>
        <ButtonGroup>
          <Button
            size="small"
            variant={viewType === 'general' ? 'contained' : 'outlined'}
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'general',
                  },
                  title: {
                    $set: 'General Network Diagnostics',
                  },
                })
              );
            }}
          >
            General
          </Button>

          <Button
            size="small"
            variant={viewType === 'routing' ? 'contained' : 'outlined'}
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'routing',
                  },
                  title: {
                    $set: 'Routing Network Diagnostics',
                  },
                })
              );
            }}
          >
            Routing
          </Button>

          <Button
            size="small"
            variant={viewType === 'timing' ? 'contained' : 'outlined'}
            onClick={() => {
              onUpdate?.(
                update(config, {
                  viewType: {
                    $set: 'timing',
                  },
                  title: {
                    $set: 'Timing Network Diagnostics',
                  },
                })
              );
            }}
          >
            Timing
          </Button>
        </ButtonGroup>
      </Box>

      <Box
        width="100%"
        height="100%"
        sx={{pt: isOpenFromPanel ? 6 : 0}}
        overflow="hidden"
      >
        {viewType === 'general' ? (
          <NetworkDiagnosticsGeneral
            value={config.general}
            onUpdate={(v) => {
              onUpdate?.(
                update(config, {
                  general: {
                    $set: v ?? getNetworkDiagnosticsGeneralData(),
                  },
                })
              );
            }}
          />
        ) : viewType === 'routing' ? (
          <NetworkDiagnosticsRouting
            value={config.routing}
            onUpdate={(v) => {
              onUpdate?.(
                update(config, {
                  routing: {
                    $set: v ?? getNetworkDiagnosticsRoutingData(),
                  },
                })
              );
            }}
          />
        ) : viewType === 'timing' ? (
          <NetworkDiagnosticsTiming
            value={config.timing}
            onUpdate={(v) => {
              onUpdate?.(
                update(config, {
                  timing: {
                    $set: v ?? getNetworkDiagnosticsTimingData(),
                  },
                })
              );
            }}
          />
        ) : null}
      </Box>
    </>
  );
};
