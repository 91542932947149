import {Alert, useTheme} from '@mui/material';
import {Box} from '@mui/system';
import dayjs from 'dayjs';
import _ from 'lodash';
import {useEffect, useMemo, useState} from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  MachineInteractionBarItem,
  MachineInteractionBarQuery,
  MachineInteractionBarResponse,
} from '../../../interfaces/MachineInteraction';
import {usePanelFilter} from '../../dashboards/entities/DashboardEntityContext';
import {MachineInteractionReportData} from './data';

interface Props {
  value?: MachineInteractionReportData;
}

const MachineInteractionReportBar = ({value}: Props) => {
  const {filter: config} = usePanelFilter();

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<MachineInteractionBarItem[]>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: MachineInteractionBarQuery) => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<MachineInteractionBarResponse>(
        `${apiBaseUrl}/report/machine-interaction-chart`,
        {params}
      );
      setFetchedData(resp.data?.results || []);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  const params = useMemo<MachineInteractionBarQuery>(
    () => ({
      date_start:
        config.params?.date_start ??
        value?.params?.date_start ??
        dayjs().format('YYYY-MM-DD'),
      date_end:
        config.params?.date_end ??
        value?.params?.date_end ??
        dayjs().format('YYYY-MM-DD'),
      external_id: config.params?.machines?.[0] ?? value?.params?.machines?.[0],
      humans: config.params?.humans ?? value?.params?.humans ?? [],
      zone_id: config.params?.section_id ?? value?.params?.section_id ?? null,
      input: JSON.stringify(config.params?.input ?? value?.params?.input),
      exclude_miners:
        config.params?.exclude_miners ?? value?.params?.exclude_miners ?? 1,
    }),
    [config]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  /*********/
  /* chart */
  /*********/
  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };
  const theme = useTheme();

  const dataset = useMemo(() => {
    return _.map(
      fetchedData,
      ({hazard_total, remote_total, warning_total, period, date}) => ({
        period,
        date,
        hazards: hazard_total,
        remote_stops: remote_total,
        warnings: warning_total,
      })
    );
  }, [fetchedData]);

  const bars = [
    {key: 'warnings', label: 'Warning', color: 'yellow'},
    {key: 'hazards', label: 'Hazard', color: 'red'},
    {key: 'remote_stops', label: 'Remote Stop', color: 'purple'},
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      width="100%"
      overflow="hidden"
    >
      {fetchedErrors.map((error, idx) => (
        <Alert key={idx} severity="error" onClose={() => fetchData(params)}>
          {error}
        </Alert>
      ))}

      {fetchedData && !fetchedInProgress ? (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={dataset}>
              <XAxis dataKey="period" />
              <YAxis />
              <Tooltip
                labelStyle={{
                  color: theme.palette.text.secondary,
                }}
                contentStyle={{
                  backgroundColor: theme.palette.background.default,
                }}
                cursor={false}
              />
              <Legend onClick={(item) => toggleKey(item.dataKey)} />

              {bars.map(({key, label, color}) => (
                <Bar
                  key={key}
                  dataKey={key}
                  name={label}
                  hide={hiddenKeys.includes(key)}
                  label={{
                    position: 'top',
                    fill: theme.palette.text.secondary,
                  }}
                  fill={color}
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => {}}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </Box>
      ) : (
        <Box
          display="flex"
          height={50}
          alignItems="center"
          justifyContent="center"
        >
          {fetchedInProgress ? 'Loading...' : 'No data to display'}
        </Box>
      )}
    </Box>
  );
};

export default MachineInteractionReportBar;
