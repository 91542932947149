import {SxProps, Theme} from '@mui/material';

export const boxStat: SxProps<Theme> = {
  bgcolor: (theme) =>
    theme.palette.mode === 'dark' ? 'background.default' : 'primary.main',
  color: (theme) => (theme.palette.mode === 'dark' ? 'primary.main' : '#FFF'),
  borderColor: (theme) =>
    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.200',
  height: '100%',
  px: 1,
  py: 2,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  fontSize: (theme) => theme.typography.h2.fontSize,
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  cursor: 'pointer',
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderLeftStyle: 'solid',
  borderLeftWidth: '1px',
};

export const boxStatActive: SxProps<Theme> = {
  ...boxStat,
  bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'),
  color: 'primary.main',
};
