import {InputProps, MenuItem, TextField, TextFieldProps} from '@mui/material';

import {ASSET_STATUSES} from '../../constants/status';

export const AssetStatusSelect: React.FC<TextFieldProps> = (props) => {
  const handleChange: InputProps['onChange'] = (event: any) => {
    const value = event.target.value !== 'all' ? event.target.value : undefined;
    props.onChange?.(value);
  };

  return (
    <TextField
      {...props}
      label={props.label ?? 'Status'}
      value={props.value ?? 'all'}
      select
      SelectProps={{
        MenuProps: {
          sx: {maxHeight: '300px'},
        },
      }}
      onChange={handleChange}
    >
      {ASSET_STATUSES.map((i) => (
        <MenuItem key={i.key} value={i.key}>
          {i.name}
        </MenuItem>
      ))}
    </TextField>
  );
};
