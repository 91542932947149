import ArrowDropDownTwoToneIcon from '@mui/icons-material/ArrowDropDownTwoTone';
import ArrowDropUpTwoToneIcon from '@mui/icons-material/ArrowDropUpTwoTone';
import {IconButton} from '@mui/material';

type TableColumnSortByValue = 'ASC' | 'DESC' | null;

type TableColumnSortByButtonProps = {
  value?: 'ASC' | 'DESC' | null;
  onChange?: (value: TableColumnSortByValue) => void;
};

export const TableColumnSortByButton: React.FC<
  TableColumnSortByButtonProps
> = ({value, onChange}) => {
  return (
    <IconButton
      sx={{minWidth: 'auto', p: 0.5, ml: 0.5}}
      color="primary"
      onClick={() => {
        onChange?.(value === 'ASC' ? 'DESC' : 'ASC');
      }}
    >
      {value === 'DESC' ? (
        <ArrowDropDownTwoToneIcon fontSize="small" />
      ) : (
        <ArrowDropUpTwoToneIcon fontSize="small" />
      )}
    </IconButton>
  );
};
