import DatasetLinkedOutlinedIcon from '@mui/icons-material/DatasetLinkedOutlined';
import {Box, Typography} from '@mui/material';

export const EmptyWidgetPreviewPanel = () => {
  return (
    <Box
      sx={{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DatasetLinkedOutlinedIcon
        color="secondary"
        sx={{mb: 4, fontSize: 120}}
      />
      <Typography color="secondary" variant="h6">
        Select some fields to start
      </Typography>
    </Box>
  );
};
