import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Grid,
  Tooltip,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import update from 'immutability-helper';
import _, {isEmpty} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  NetworkDiagnosticsTimingItem,
  NetworkDiagnosticsTimingResponse,
  TimingQueryParams,
} from '../../../interfaces/CommunicationNode';
import {ExportField} from '../../../interfaces/Export';
import reduxSelectors from '../../../redux/selectors';
import {saveFile} from '../../../utils/file';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {SectionMultipleSelect} from '../../selectors/SectionSelect';

interface Props {
  value?: NetworkDiagnosticsTimingData;
  onUpdate?: (value?: NetworkDiagnosticsTimingData) => void;
}

export interface NetworkDiagnosticsTimingData {
  params: TimingQueryParams;
  selectedIds?: number[];
  exportFields: ExportField[];
}

export const getNetworkDiagnosticsTimingData =
  (): NetworkDiagnosticsTimingData => {
    return {
      params: {
        date_start: dayjs().format('YYYY-MM-DD'),
        date_end: dayjs().format('YYYY-MM-DD'),
        limit: 10,
        page: 0,
        order: 'external_id',
        dir: 'ASC',
        section_ids: undefined,
      },
      selectedIds: undefined,
      exportFields: [],
    };
  };

export const NetworkDiagnosticsTiming = ({value, onUpdate}: Props) => {
  const isDarkMode = useSelector(reduxSelectors.app.getIsDarkMode);

  const config = useMemo<NetworkDiagnosticsTimingData>(
    () => (!isEmpty(value) ? value : getNetworkDiagnosticsTimingData()),
    [value]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const [exportInProgress, setExportInProgress] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [fetchedData, setFetchedData] =
    useState<NetworkDiagnosticsTimingResponse>();

  const fetchData = useCallback(async (params: TimingQueryParams) => {
    setFetchedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/communication-node/network-timing-grid/`;
      const resp = await API.get<NetworkDiagnosticsTimingResponse>(endpoint, {
        params,
      });
      setFetchedData(resp.data);
    } catch (error: any) {
      const errors = error.response.data?.message
        ? [error.response.data?.message]
        : [];
      setFetchedErrors(errors);
    }
    setFetchedInProgress(false);
  }, []);

  const params = useMemo(
    () => ({
      date_start: config?.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
      date_end: config?.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      page: config.params.page ?? 0,
      limit: config?.params?.limit ?? 10,
      order: config?.params?.order ?? 'external_id',
      dir: config?.params?.dir ?? 'ASC',
      section_ids: config?.params?.section_ids ?? undefined,
    }),
    [config?.params]
  );

  const formik = useFormik({
    initialValues: params,
    onSubmit: (v) => {
      onUpdate?.(
        update(config, {
          params: {$set: v},
        })
      );
    },
  });

  const handleFetchData = async () => {
    await fetchData(params);
  };

  useEffect(() => {
    if (!_.isEqual(params, formik.values)) {
      formik.setValues(params);
    }
    handleFetchData();
  }, [params]);

  useEffect(() => {
    if (!_.isEqual(params, formik.values)) {
      formik.handleSubmit();
    }
  }, [formik.values]);

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);

  const rows = useMemo<NetworkDiagnosticsTimingItem[]>(
    () => fetchedData?.items ?? [],
    [fetchedData]
  );

  /**********/
  /* export */
  /**********/
  const handleExport = async (fields: string[]) => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/communication-node/network-timing-grid-export`;
      const resp = await API.get(endpoint, {
        params: {...params, fields},
        responseType: 'blob',
      });
      const filename =
        resp?.headers['content-disposition']?.split('filename=')?.[1] ||
        'network_timing_report.xlsx';
      saveFile(resp.data, filename);
      setShowExportModal(false);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setExportInProgress(false);
  };

  const handleShownFieldsChange = (fields: string[]) => {
    onUpdate?.(
      update(config, {
        exportFields: {
          $set: collectExportFields(fields),
        },
      })
    );
  };

  const collectExportFields = (fields?: string[]) => {
    return columns
      .filter((col) => !!col.headerName)
      .map((col) => ({
        field: col.field,
        label: col.headerName,
        hidden: !!fields && !fields.includes(col.field),
      }));
  };

  /*******************/
  /* multiple select */
  /*******************/

  const [newSelectedItemIds, setNewSelectedItemIds] = useState(
    value?.selectedIds ?? []
  );

  const columns: DataGridColumn<NetworkDiagnosticsTimingItem>[] = useMemo(
    () => [
      {
        headerName: '',
        field: 'select',
        type: 'select',
        sortable: true,
        renderHeader: () => (
          <Checkbox
            color="primary"
            disabled={fetchedData?.items.length === 0}
            indeterminate={
              newSelectedItemIds.length > 0 &&
              newSelectedItemIds.length < rows.length
            }
            checked={
              rows.length > 0 && newSelectedItemIds.length === rows.length
            }
            onChange={toogleSelectAllItemIds}
          />
        ),
        renderCell: ({row}) => (
          <Checkbox
            color="primary"
            checked={newSelectedItemIds.includes(row.external_id)}
            onChange={() => toogleSelectItemId(row.external_id)}
          />
        ),
      },
      {
        field: 'external_id',
        headerName: 'Network ID',
        sortable: true,
        valueGetter: ({row}) => +row.external_id,
      },
      {field: 'name', headerName: 'Name', sortable: true},
      {
        field: 'timing_parent_1_address',
        headerName: 'Parent 1 Address',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_1_address,
      },
      {
        field: 'timing_parent_1_rssi',
        headerName: 'Parent 1 Rssi',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_1_rssi,
      },
      {
        field: 'timing_parent_1_percentage',
        headerName: 'Parent 1 Percentage',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_1_percentage,
      },
      {
        field: 'timing_parent_2_address',
        headerName: 'Parent 2 Address',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_2_address,
      },
      {
        field: 'timing_parent_2_rssi',
        headerName: 'Parent 2 Rssi',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_2_rssi,
      },
      {
        field: 'timing_parent_2_percentage',
        headerName: 'Parent 2 Percentage',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_2_percentage,
      },
      {
        field: 'timing_parent_3_address',
        headerName: 'Parent 3 Address',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_3_address,
      },
      {
        field: 'timing_parent_3_rssi',
        headerName: 'Parent 3 Rssi',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_3_rssi,
      },
      {
        field: 'timing_parent_3_percentage',
        headerName: 'Parent 3 Percentage',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_3_percentage,
      },
      {
        field: 'timing_parent_4_address',
        headerName: 'Parent 4 Address',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_4_address,
      },
      {
        field: 'timing_parent_4_rssi',
        headerName: 'Parent 4 Rssi',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_4_rssi,
      },
      {
        field: 'timing_parent_4_percentage',
        headerName: 'Parent 4 Percentage',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_4_percentage,
      },
      {
        field: 'timing_parent_5_address',
        headerName: 'Parent 5 Address',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_5_address,
      },
      {
        field: 'timing_parent_5_rssi',
        headerName: 'Parent 5 Rssi',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_5_rssi,
      },
      {
        field: 'timing_parent_5_percentage',
        headerName: 'Parent 5 Percentage',
        sortable: true,
        valueGetter: ({row}) => row.timing_parent_5_percentage,
      },
      {
        field: 'timing_min_hops',
        headerName: 'Min Hops',
        sortable: true,
        valueGetter: ({row}) => row.timing_min_hops,
      },
      {
        field: 'timing_max_hops',
        headerName: 'Max Hops',
        sortable: true,
        valueGetter: ({row}) => row.timing_max_hops,
      },
      {
        field: 'timing_mean_hops',
        headerName: 'Mean Hops',
        sortable: true,
        valueGetter: ({row}) => row.timing_mean_hops,
      },
      {
        field: 'timing_lost_parent_count',
        headerName: 'Lost Parent Count',
        sortable: true,
        valueGetter: ({row}) => row.timing_lost_parent_count,
      },
      {
        field: 'timing_changed_parent_count',
        headerName: 'Changed Parent Count',
        sortable: true,
        valueGetter: ({row}) => row.timing_changed_parent_count,
      },
    ],
    [fetchedData, rows, newSelectedItemIds]
  );

  const toogleSelectAllItemIds = () => {
    if (newSelectedItemIds?.length === rows.length) {
      setNewSelectedItemIds([]);
    } else {
      setNewSelectedItemIds(rows.map((_i) => _i.external_id));
    }
  };

  const toogleSelectItemId = (id: number) => {
    if (newSelectedItemIds.includes(id)) {
      setNewSelectedItemIds(newSelectedItemIds.filter((i) => i !== id));
    } else {
      setNewSelectedItemIds([...newSelectedItemIds, id]);
    }
  };

  useEffect(() => {
    const newValue = newSelectedItemIds ?? [];
    if (!_.isEqual(newValue, value?.selectedIds)) {
      onUpdate?.(
        update(value, {
          selectedIds: {
            $set: newValue,
          },
        })
      );
    }
  }, [newSelectedItemIds, value]);

  useEffect(() => {
    const newValue = value?.selectedIds ?? [];
    if (!_.isEqual(newSelectedItemIds, newValue)) {
      setNewSelectedItemIds?.(newValue);
    }
  }, [value?.selectedIds]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      overflow="hidden"
      width="100%"
    >
      <Grid container spacing={1} p={2} minWidth={800}>
        <Grid item xs={4}>
          <DateRangeSelect
            value={
              value
                ? [
                    dayjs(value.params.date_start).toDate(),
                    dayjs(value.params.date_end).toDate(),
                  ]
                : undefined
            }
            size="small"
            disabled={fetchedInProgress}
            onChange={(value) => {
              formik.setFieldValue(
                'date_start',
                dayjs(value[0] ?? undefined).format('YYYY-MM-DD')
              );
              formik.setFieldValue(
                'date_end',
                dayjs(value[1] ?? undefined).format('YYYY-MM-DD')
              );
            }}
          />
        </Grid>

        <Grid item xs={4}>
          <SectionMultipleSelect
            value={formik.values.section_ids}
            size="small"
            onChange={(v) => formik.setFieldValue('section_ids', v)}
          />
        </Grid>

        <Grid item xs={4} display="flex" justifyContent="end">
          <ButtonGroup>
            <Button size="small" onClick={() => handleFetchData()}>
              <RefreshIcon />
            </Button>

            <Tooltip title="Export to Excel">
              <Button
                size="small"
                variant="outlined"
                onClick={() => setShowExportModal(true)}
              >
                <FileDownloadIcon />
              </Button>
            </Tooltip>

            <Button
              size="small"
              onClick={() => dataGridRef.current?.printTable()}
            >
              <PrintIcon />
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <Backdrop
        open={fetchedInProgress}
        sx={{
          position: 'absolute',
          zIndex: 1001,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{mb: 2}}>
          {error}
        </Alert>
      ))}

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        size="small"
        pagination
        paginationMode="server"
        sortingMode="server"
        rowCount={fetchedData?.count ?? params.page * params.limit}
        page={formik.values.page}
        pageSize={formik.values.limit}
        sxFooter={{bgcolor: isDarkMode ? '#2E2E2E' : '#FFF'}}
        onPageChange={(v) => formik.setFieldValue('page', v)}
        onPageSizeChange={(v) => {
          formik.setFieldValue('limit', v);
          formik.setFieldValue('page', 0);
        }}
        onShownFieldsChange={handleShownFieldsChange}
        onSort={(v) => {
          formik.setFieldValue('order', v?.field);
          formik.setFieldValue('dir', v?.dir.toUpperCase());
        }}
      />

      <ModalFixed
        open={showExportModal}
        onClose={() => setShowExportModal(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <ExportFieldForm
            exportFields={
              config.exportFields.length > 0
                ? config.exportFields
                : collectExportFields()
            }
            loading={exportInProgress}
            onChange={handleExport}
            close={() => setShowExportModal(false)}
          />
        </Box>
      </ModalFixed>
    </Box>
  );
};
