/*enum Label {
  MACHINE = 'machine',
}*/

import {useAppSelector} from './redux';

export const UseGetLabel = ({
  label,
  plural = false,
  uc_first = true,
  uc = false,
}: {
  label: string;
  plural?: boolean;
  uc_first?: boolean;
  uc?: boolean;
}) => {
  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;

  switch (label) {
    case 'machine':
      const v = hazard_ai_enabled
        ? plural
          ? 'machines'
          : 'machine'
        : plural
          ? 'assets'
          : 'asset';

      if (uc) {
        return v.toUpperCase();
      } else if (uc_first) {
        return v.charAt(0).toUpperCase() + v.slice(1);
      }

      return v;
    default:
      return label;
  }
};
