import {orderBy} from 'lodash';

import {TIMEZONES} from '../constants/timezones';
import dayjs from '../utils/dayjs';

interface IIntl {
  supportedValuesOf: (i: string) => string[];
}

export type TimezoneOption = {
  name: string;
  offset: number;
  fullName: string;
};

export const timezones = (Intl as unknown as IIntl).supportedValuesOf(
  'timeZone'
);

const getTzOffset = (timeZone = 'UTC', date = new Date()) => {
  const utcDate = new Date(date.toLocaleString('en-US', {timeZone: 'UTC'}));
  const tzDate = new Date(date.toLocaleString('en-US', {timeZone}));
  return tzDate.getTime() - utcDate.getTime();
};

const getOffsetString = (offset: number) => {
  const prefix = offset < 0 ? '-' : '+';
  return `${prefix}${dayjs.duration(Math.abs(offset)).format('HH:mm')}`;
};

export const timezoneOptions = orderBy(
  timezones.map((name) => {
    const offset = getTzOffset(name);
    const offsetString = getOffsetString(offset);
    const fullName = `(GTM${offsetString}) ${name}`;
    return {name, offset, fullName};
  }),
  'offset'
);

export const phpTimezoneOptions = orderBy(
  TIMEZONES.map((item) => {
    const offset = getTzOffset(item.id);
    const offsetString = getOffsetString(offset);
    const fullName = `(GTM${offsetString}) ${item.name.split(') ')[1]}`;
    return {name: item.id, offset, fullName};
  }),
  'offset'
);
