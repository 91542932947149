import {createAsyncThunk} from '@reduxjs/toolkit';

import API from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  HumanGroupEmployeeResponse,
  HumanGroupGridResponse,
  SelectedGroupParamsResponse,
} from '../../interfaces/HumanGroup';
import assetsActions from '../assets/actions';

const fetchHumanGroups = createAsyncThunk(
  'admin/fetchHumanGroups',
  async (_, thunkApi) => {
    try {
      //TODO: need change url after deploy human group API;
      const response = await API.get<HumanGroupGridResponse>(
        `${apiBaseUrl}/asset-human-group-grid`
      );
      return thunkApi.fulfillWithValue({groups: response.data});
    } catch (error: any) {
      //TODO: add error handler
      console.error('fetchHumanGroups', error);
      thunkApi.rejectWithValue({errorMessage: error.message});
    }
  }
);

const fetchEmployees = createAsyncThunk<any, void | number, any>(
  'admin/fetchEmployees',
  async (humanGroupId, thunkApi) => {
    try {
      //TODO: need change url after deploy human group API;
      const response = await API.get<HumanGroupEmployeeResponse>(
        `${apiBaseUrl}/commtrac-node-grid`,
        {
          params: {group_id: humanGroupId},
        }
      );
      return thunkApi.fulfillWithValue({employees: response.data});
    } catch (error: any) {
      console.error('fetchEmployees', error);
      thunkApi.rejectWithValue({errorMessage: error.message});
    }
  }
);

const fetchSelectedHumanTypes = createAsyncThunk<any, void | number, any>(
  'admin/fetchSelectedHumanTypes',
  async (humanGroupId, thunkApi) => {
    try {
      //TODO: need change url after deploy human group API;
      const response = await API.get<SelectedGroupParamsResponse>(
        `${apiBaseUrl}/asset-human-group-types`,
        {
          params: {group_id: humanGroupId},
        }
      );
      return thunkApi.fulfillWithValue({selectedTypes: response.data});
    } catch (error: any) {
      console.error('fetchSelectedHumanTypes', error);
      thunkApi.rejectWithValue({errorMessage: error.message});
    }
  }
);

const fetchSelectedSections = createAsyncThunk<any, void | number, any>(
  'admin/fetchSelectedSections',
  async (humanGroupId, thunkApi) => {
    try {
      //TODO: need change url after deploy human group API;
      const response = await API.get<SelectedGroupParamsResponse>(
        `${apiBaseUrl}/zone-by-group-id`,
        {
          params: {group_id: humanGroupId},
        }
      );
      return thunkApi.fulfillWithValue({sections: response.data});
    } catch (error: any) {
      console.error('fetchSelectedSections', error);
      thunkApi.rejectWithValue({errorMessage: error.message});
    }
  }
);

const createHumanGroup = createAsyncThunk<
  any,
  {
    name: string;
    sections: number[];
    types: number[];
    employees: number[];
  },
  any
>('admin/createHumanGroup', async (data, thunkApi) => {
  try {
    //TODO: need change url after deploy human group API;
    const response = await API.post(`${apiBaseUrl}/asset-human-group`, {
      name: data.name,
      sections: data.sections,
      types: data.types,
      employees: data.employees,
      status: 'active',
      updated_at: new Date().toISOString(),
    });
    assetsActions.fetchAssetHumanGroups(thunkApi.dispatch);
    return thunkApi.fulfillWithValue({sections: response.data});
  } catch (error: any) {
    console.error('createHumanGroup', error);
    thunkApi.rejectWithValue({errorMessage: error.message});
  }
});
const updateHumanGroup = createAsyncThunk<
  any,
  {
    groupId: number;
    formikValues: {
      name: string;
      sections: number[];
      types: number[];
      employees: number[];
    };
  },
  any
>('admin/updateHumanGroup', async ({groupId, formikValues}, thunkApi) => {
  try {
    //TODO: need change url after deploy human group API;
    const response = await API.patch(
      `${apiBaseUrl}/asset-human-group/${groupId}`,
      {
        name: formikValues.name,
        sections: formikValues.sections,
        types: formikValues.types,
        employees: formikValues.employees,
        updated_at: new Date().toISOString(),
      },
      {method: 'patch'}
    );
    return thunkApi.fulfillWithValue({sections: response.data});
  } catch (error: any) {
    console.error('fetchSelectedSections', error);
    thunkApi.rejectWithValue({errorMessage: error.message});
  }
});

export {
  createHumanGroup,
  fetchEmployees,
  fetchHumanGroups,
  fetchSelectedHumanTypes,
  fetchSelectedSections,
  updateHumanGroup,
};
