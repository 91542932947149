import {Box} from '@mui/material';
import {useRef} from 'react';

import AccessControl from '../common/AccessControl';
import CommtracNodeMessageCreate from './CommtracNodeMessageCreate';
import CommtracNodeMessageList, {
  CommtracNodeMessageListRef,
} from './CommtracNodeMessageList';

interface Props {
  pk: number;
}

const CommtracNodeChat = ({pk}: Props) => {
  const listRef = useRef<CommtracNodeMessageListRef>(null);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <CommtracNodeMessageList ref={listRef} pk={pk} />
      <AccessControl permissions={['post::/commtrac-node/:id/message']}>
        <CommtracNodeMessageCreate
          pk={pk}
          onSubmitted={() => listRef?.current?.fetch()}
        />
      </AccessControl>
    </Box>
  );
};

export default CommtracNodeChat;
