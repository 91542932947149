import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import {useFormik} from 'formik';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useGetMinerNetworkId} from '../../hooks/get-miner-network-id';
import {useAppSelector} from '../../hooks/redux';
import {
  AssetMachine,
  AssetMachineListQuery,
  AssetMachineListResponse,
} from '../../interfaces/AssetMachine';
import DataGrid, {DataGridColumn, DataGridRef} from '../common/DataGrid';

interface Props {
  onClose?: () => void;
  onOpenItem?: (id: number) => void;
}

export interface AssetMachineUnassignedListRef {
  fetch?: () => void;
}

const AssetMachineUnassignedList = forwardRef<
  AssetMachineUnassignedListRef,
  Props
>(({onClose, onOpenItem}, ref) => {
  /*******/
  /* ref */
  /*******/
  useImperativeHandle(ref, () => ({
    fetch: () => fetchData(formik.values),
  }));

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<AssetMachineListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: AssetMachineListQuery) => {
    setFetchedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/asset-machine`;
      const resp = await API.get<AssetMachineListResponse>(endpoint, {
        params: {
          ...params,
          filter: JSON.stringify({
            type: 'and',
            filter: [
              {
                field: 'mac_address',
                op: 'nl',
              },
              {
                field: 'commtrac_external_id',
                op: 'nl',
              },
            ],
          }),
        },
      });
      setFetchedData(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /*************/
  /* data grid */
  /*************/
  const machineTypes = useAppSelector(({assets}) => assets.machine_types);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const getMinerNetworkId = useGetMinerNetworkId();

  const columns: DataGridColumn<AssetMachine>[] = [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'name',
      sortable: true,
    },
    {
      field: 'type_id',
      headerName: 'Machine Type',
      sortable: true,
      valueGetter: ({row}) =>
        machineTypes.find((i) => row.type_id === i.id)?.name,
    },
    {
      field: 'commtrac_external_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.commtrac_external_id) {
          if (row.type_id === 2) {
            return getMinerNetworkId(row.commtrac_external_id);
          }
          return row.commtrac_external_id;
        }
      },
    },
    {
      field: 'external_id',
      headerName: 'Proximity ID',
      sortable: true,
    },
    {
      field: 'mac_address',
      headerName: 'Mac Address',
      sortable: true,
    },
    {
      field: 'actions',
      type: 'actions',
      sxHeader: {textAlign: 'right'},
      sxCell: {textAlign: 'right'},
      renderCell: ({row}) => {
        return (
          <IconButton
            color="primary"
            size="small"
            onClick={() => onOpenItem?.(row.id)}
          >
            <EditIcon />
          </IconButton>
        );
      },
    },
  ];

  const formik = useFormik<AssetMachineListQuery>({
    initialValues: {
      page: 0,
      limit: 10,
      order: 'id',
      dir: 'DESC',
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    fetchData(formik.values);
  }, [formik.values]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <LocalShippingIcon sx={{color: 'primary.main'}} />
          <Box fontSize={24}>Unassigned Assets</Box>
        </Box>
        {onClose ? (
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={fetchedInProgress}
        pagination
        paginationMode="server"
        size="small"
        sortBy={{
          field: formik.values.order,
          dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
        }}
        sortingMode="server"
        page={formik.values.page}
        pageSize={formik.values.limit}
        rowCount={fetchedData?.count}
        onPageChange={(v) => formik.setFieldValue('page', v)}
        onPageSizeChange={(v) => {
          formik.setFieldValue('limit', v);
          formik.setFieldValue('page', 0);
        }}
        onSort={(v) => {
          if (v) {
            formik.setFieldValue('order', v.field);
            formik.setFieldValue('dir', v.dir.toUpperCase());
          }
        }}
      />
    </Box>
  );
});

export default AssetMachineUnassignedList;
