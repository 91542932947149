import {PlayCircle} from '@mui/icons-material';
import {Box, IconButton} from '@mui/material';

import {HazardEventLog} from '../../../interfaces/HazardAILog';

interface Props {
  item: HazardEventLog;
  onClickMedia?: (id?: number) => void;
}

const HazardAIDetectionLogTooltipContent = ({item, onClickMedia}: Props) => {
  return (
    <Box display="flex" flexDirection="column" gap={0.5}>
      <Box>Timestamp: {item.timestamp}</Box>
      <Box textTransform="capitalize">Zone: {item.zone}</Box>
      <Box textTransform="capitalize">Object: {item.object}</Box>
      <Box textTransform="capitalize">Camera Position: {item.cam_id}</Box>
      <Box textTransform="capitalize">Speed: {item.speed}</Box>
      <Box textTransform="capitalize">
        Detection Video:{' '}
        <IconButton
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.4)',
            padding: '0px',
            '&:hover': {
              backgroundColor: 'rgb(0, 0, 0)',
            },
          }}
          onClick={() => {
            onClickMedia?.(item.id);
          }}
        >
          <PlayCircle />
        </IconButton>
      </Box>
    </Box>
  );
};

export default HazardAIDetectionLogTooltipContent;
