import {Box, Button, Grid, ListItem, Modal, Typography} from '@mui/material';
import {ReactNode, useState} from 'react';

import {SubscriptionReport} from '../../../interfaces/SubscriptionReport';
import SubscriptionReportSelectTable from '../SubscriptionReportSelectTable';
import {SubscriptionReportItemUpsertButton} from './SubscriptionItemReportUpsertButton';

interface SubscriptionReportSelectTableButtonProps {
  value?: {
    id: number;
    name: string;
  }[];
  component?: React.FC;
  componentProps?: any;
  onSelect?: (item: SubscriptionReport) => any;
  children?: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 2,
  overflow: 'auto',
  maxHeight: '100%',
};

export const SubscriptionReportSelectTableButton: React.FC<
  SubscriptionReportSelectTableButtonProps
> = ({value, component = ListItem, componentProps, children, onSelect}) => {
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);
  const [isNewReportAdded, setIsNewReportAdded] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Modal
        open={isOpened}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box p={2} display="flex" flexDirection="column">
            <Grid container>
              <Grid item xs={9}>
                <Typography
                  id="modal-modal-title"
                  component="h1"
                  variant="h5"
                  sx={{mb: 2}}
                >
                  Subscription Reports
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Box textAlign="right">
                  <SubscriptionReportItemUpsertButton
                    component={Button}
                    componentProps={{
                      variant: 'outlined',
                    }}
                    onSubmitted={() => setIsNewReportAdded(true)}
                  >
                    New Report
                  </SubscriptionReportItemUpsertButton>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <SubscriptionReportSelectTable
            value={value}
            updateRequired={isNewReportAdded}
            onUpdate={() => setIsNewReportAdded(false)}
            onSelect={(e: SubscriptionReport) => {
              onSelect?.(e);
              closeModal();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
