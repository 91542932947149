import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import orderBy from 'lodash/orderBy';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {
  GasMonitoringNodeListQuery,
  GasMonitoringNodeListResponse,
  //GasMonitoringNodeLongTermEvent,
} from '../../../interfaces/GasMonitoringNode';
import reduxSelectors from '../../../redux/selectors';
import {CHART_COLORS} from '../../../utils/colors';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {ResizableColumns} from '../../common/ResizableColumns';
import TabLabel from '../../common/TabLabel';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: AMSLongTermHistoryReportData;
  onUpdate?: (value?: AMSLongTermHistoryReportData) => void;
}

const DEFAULT_SHOWN_FIELDS = ['timestamp', 'min', 'max', 'med', 'avg'];

interface AMSLongTermHistoryReportDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: string[];
  selectAll?: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
    status?: 'active' | 'inactive' | 'unacknowledged' | 'all';
  };
}

export interface AMSLongTermHistoryReportData {
  activeId?: number;
  openedItems: AMSLongTermHistoryReportDataTab[];
}

export const getAMSLongTermHistoryReportData =
  (): AMSLongTermHistoryReportData => ({
    activeId: undefined,
    openedItems: [],
  });

export const getAMSLongTermHistoryReportDataTab = (
  id: number
): AMSLongTermHistoryReportDataTab => ({
  id,
  selectAll: true,
});

export const AMSLongTermHistoryReport = ({value, onUpdate}: Props) => {
  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config = useMemo(() => {
    const v = value ?? getAMSLongTermHistoryReportData();
    return {
      ...v,
    };
  }, [value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex]
          ? config.openedItems[openedItemIndex]
          : getAMSLongTermHistoryReportDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const ams_nodes_all = useAppSelector(({assets}) => assets.ams_nodes);
  const ams_nodes = useAppSelector(({assets}) => assets.ams_nodes_selected);

  const tabNames = useMemo(
    () =>
      config.openedItems.map(
        (o) => ams_nodes.find((i) => Number(i.id) === Number(o.id))?.name
      ),
    [config.openedItems, ams_nodes]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] =
    useState<GasMonitoringNodeListResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo<GasMonitoringNodeListQuery | null>(
    () =>
      openedItem?.id
        ? {
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
            status: openedItem.params?.status ?? 'all',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: GasMonitoringNodeListQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/ams/${config.activeId}/long-term-history`;
        const resp = await API.get<GasMonitoringNodeListResponse>(endpoint, {
          params,
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/

  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*********/
  /* grid */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<any>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(`${row.id}`)}
          onChange={() => toggleSelectItem(`${row.id}`)}
        />
      ),
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => row.date,
    },
    {
      field: 'min',
      headerName: 'Minimum',
      sortable: true,
      valueGetter: ({row}) => row.min,
    },
    {
      field: 'max',
      headerName: 'Maximum',
      sortable: true,
      valueGetter: ({row}) => row.max,
    },
    {
      field: 'med',
      headerName: 'Median',
      sortable: true,
      valueGetter: ({row}) => row.med,
    },
    {
      field: 'avg',
      headerName: 'Average',
      sortable: true,
      valueGetter: ({row}) => row.avg,
    },
  ];

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = openedItem?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems?.includes(`${i.id}`)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i) => `${i.id}`) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  const dateTimeFormatter = (timestamp: string) => {
    return dayjs(timestamp).format('ddd, MMM D, YYYY');
  };

  const isDarkMode = useAppSelector(reduxSelectors.app.getIsDarkMode);

  const lines = [
    {
      key: 'min',
      label: 'Min',
    },
    {
      key: 'max',
      label: 'Max',
    },
    {
      key: 'med',
      label: 'Med',
    },
    {
      key: 'avg',
      label: 'Avg',
    },
  ];

  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);

  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  const chartData = useMemo(
    () => orderBy(selectedRows, (i) => i.date),
    [selectedRows]
  );

  useEffect(() => {
    if (
      fetchedData &&
      openedItem?.selectedIds &&
      openedItem.selectedIds.length !== selectedRows.length
    ) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: selectedRows.map((i) => `${i}`),
              },
            },
          },
        })
      );
    }
  }, [openedItem?.selectedIds, fetchedData]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  return (
    <>
      <DashboardPanelTitleSlot>
        AMS Long Term History Report
      </DashboardPanelTitleSlot>

      <ResizableColumns
        left={
          <Box
            width="100%"
            height="100%"
            minWidth={400}
            pt={2}
            pb={2}
            pr={6}
            bgcolor={isDarkMode ? 'background.default' : 'grey.100'}
          >
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={chartData} margin={{left: 20, top: 40}}>
                <XAxis dataKey="date" />
                <YAxis>
                  <Label value="Latency (ms)" dx={-30} angle={-90} />
                </YAxis>
                <RechartsTooltip
                  labelStyle={{color: 'black'}}
                  labelFormatter={dateTimeFormatter}
                />
                <CartesianGrid opacity={0.3} />
                <Legend onClick={(item) => toggleKey(item.dataKey)} />
                {lines?.map(({key, label}, idx) => (
                  <Line
                    key={key}
                    dataKey={key}
                    name={label}
                    hide={hiddenKeys.includes(key)}
                    stroke={CHART_COLORS[idx]}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
          </Box>
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            py={1.5}
            bgcolor={(theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
            }
          >
            {isOpenAwayFromConnectView && (
              <Box px={1.5}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button variant="outlined" {...bindTrigger(popupState)}>
                        Select an Object
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {ams_nodes_all?.map((it) => (
                          <MenuItem
                            key={it.id}
                            onClick={() => {
                              const updateObject: any = {
                                activeId: {
                                  $set: it.id ?? undefined,
                                },
                              };
                              // if item is not already in openedItems add it
                              if (
                                !config.openedItems.find(
                                  (itm) => itm.id === it.id
                                )
                              ) {
                                updateObject.openedItems = {
                                  $set: [
                                    ...config.openedItems,
                                    {
                                      id: it.id ?? -1,
                                    } as AMSLongTermHistoryReportDataTab,
                                  ],
                                };
                              }
                              onUpdate?.(update(value, updateObject));
                              popupState.close();
                            }}
                          >
                            {it.name}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {config.openedItems.length ? (
              <Box px={2}>
                <Tabs
                  value={openedItem?.id}
                  variant="scrollable"
                  onChange={(_event, v) => {
                    if (v) {
                      onUpdate?.(
                        update(config, {
                          activeId: {
                            $set: v,
                          },
                        })
                      );
                    }
                  }}
                >
                  {config.openedItems.map((i, idx) => (
                    <Tab
                      key={i.id}
                      value={i.id}
                      label={
                        <TabLabel
                          name={tabNames?.[idx] ?? 'Undefined'}
                          onClose={() => {
                            onUpdate?.(
                              update(value, {
                                activeId: {
                                  $set:
                                    config.activeId === i.id
                                      ? config.openedItems[0].id
                                      : config.activeId,
                                },
                                openedItems: {
                                  $set:
                                    config.openedItems.filter(
                                      (o) => o.id && o.id !== i.id
                                    ) ?? [],
                                },
                              })
                            );
                          }}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <Box minWidth={400} px={1.5}>
                <Alert severity="warning">No Assets Opened</Alert>
              </Box>
            )}

            <Box display="flex" flexDirection="column" px={1.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <Box minWidth={400}>
                    <DateRangeSelect
                      value={
                        openedItem
                          ? [
                              dayjs(openedItem.params?.date_start).toDate(),
                              dayjs(openedItem.params?.date_end).toDate(),
                            ]
                          : undefined
                      }
                      size="small"
                      disabled={!openedItem}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    selectAll: true,
                                    params: {
                                      ...openedItem.params,
                                      date_start: v?.[0]
                                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                        : undefined,
                                      date_end: v?.[0]
                                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                        : undefined,
                                    },
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <ButtonGroup disabled={!openedItem}>
                    {/*
                    This will be the AWSLongTermHistoryExportButton

                    <Button size="small">
                      <CommtracNodeHistoryExportButton
                        componentProps={{
                          ...value,
                          id: value?.activeId,
                          date_start: openedItem?.params?.date_start,
                          date_end: openedItem?.params?.date_end,
                        }}
                      />
                    </Button> */}

                    <Button
                      size="small"
                      onClick={() => params && fetchData(params)}
                    >
                      <RefreshIcon />
                    </Button>

                    <AutoRefreshSelect
                      value={openedItem?.refreshInterval ?? null}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    refreshInterval: v,
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />

                    <Button
                      size="small"
                      onClick={() => dataGridRef.current?.printTable()}
                    >
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <Backdrop
            open={fetchedInProgress}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors.map((error, idx) => (
            <Alert
              key={idx}
              severity="error"
              onClose={() => params && fetchData(params)}
            >
              {error}
            </Alert>
          ))}

          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            loading={fetchedInProgress}
            shownFields={shownFields}
            pagination
            paginationMode="server"
            size="small"
            sortBy={
              params?.order
                ? {
                    field: params?.order,
                    dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                  }
                : null
            }
            sortingMode="server"
            page={params?.page}
            pageSize={params?.limit}
            rowCount={fetchedData?.count}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            onPageChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onPageSizeChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onSort={(v) => {
              if (v && openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          params: {
                            ...openedItem.params,
                            order: v.field,
                            dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onShownFieldsChange={setShownFields}
          />
        </Box>
      </ResizableColumns>
    </>
  );
};
