import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tooltip,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import {forEach, isEmpty} from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as TooltipRecharts,
  XAxis,
  YAxis,
} from 'recharts';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {usePrint} from '../../../hooks/print';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import {DashboardPanelData, PanelFilterParams} from '../../../interfaces/Dashboard';
import {
  MachineDetailLineReportQuery,
  MachineDetailLineReportResponse,
} from '../../../interfaces/MachineDetailLineReport';
import reduxSelectors from '../../../redux/selectors';
import {CHART_COLORS} from '../../../utils/colors';
import {getDaysArrayBetween} from '../../../utils/datetime';
import {saveFile} from '../../../utils/file';
import {isPresent} from '../../../utils/type-guards';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import {useDashboardDrilldown} from '../../dashboards/DashboardContext';
import {usePanelFilter} from '../../dashboards/entities/DashboardEntityContext';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {MachineInputSelectV2} from '../../selectors/MachineInputSelectV2';
import {MachineTypeSelect} from '../../selectors/MachineTypeSelect';
import {ShiftSelect} from '../../selectors/ShiftSelect';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import DrilldownMenu, {MenuState} from './DrilldownMenu';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value: DashboardPanelData) => void;
}

const MachineDetailsReportLine = (props: Props) => {
  const {filter, setFilter} = usePanelFilter();

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] =
    useState<MachineDetailLineReportResponse>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const isEmptyResults = isEmpty(fetchedData?.results);

  const params = useMemo<MachineDetailLineReportQuery>(
    () => ({
      type_id: filter.params?.type_id ?? props.value?.params?.type_id ?? null,
      shift_id: filter.params?.shift_id ?? props.value?.params?.shift_id ?? null,
      zone_id: filter.params?.zone_id ?? props.value?.params?.zone_id ?? null,
      date_start: filter.params?.date_start ?? props.value?.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
      date_end: filter.params?.date_end ?? props.value?.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
      input: filter.params?.input ?? props.value?.params?.input ?? {},
    }),
    [filter]
  );

  const fetchData = useCallback(
    async (params: MachineDetailLineReportQuery) => {
      setFetchedInProgress(true);
      setFetchedErrors([]);
      try {
        const endpoint = `${apiBaseUrl}/report/machine-details-line`;
        const resp = await API.get<MachineDetailLineReportResponse>(endpoint, {
          params: {
            ...params,
            input: JSON.stringify(params.input),
          },
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }
      setFetchedInProgress(false);
    },
    [params]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
      props.onUpdate?.({...props.value, chartType: 'line', params});
    }
  }, [params]);

  /****************/
  /* auto refresh */
  /****************/
  const [refreshInterval, setRefreshInterval] = useRefreshInterval(
    () => fetchData(params),
    props.value?.refreshInterval
  );

  useEffect(() => {
    props.onUpdate?.({...props.value, chartType: 'line', refreshInterval});
  }, [refreshInterval]);

  /*********/
  /* chart */
  /*********/
  const theme = useTheme();

  const lines = useMemo(() => {
    const labels = fetchedData?.metadata?.labels || {};
    return Object.keys(labels).map((externalId) => ({
      key: externalId,
      label: fetchedData?.metadata?.labels[externalId],
    }));
  }, [fetchedData]);

  const [hiddenKeys, setHiddenKeys] = useState<string[]>([]);
  const toggleKey = (key: string) => {
    setHiddenKeys(
      hiddenKeys.includes(key)
        ? hiddenKeys.filter((i) => i !== key)
        : [...hiddenKeys, key]
    );
  };

  const dataset = useMemo(() => {
    const defaultValue: any = {};
    const rawApiData = fetchedData?.results?.map((item) => {
      forEach(Object.keys(item), (key) => {
        defaultValue[key] = 0;
      });
      return {
        ...item,
        name: item?.period,
        period: undefined,
      };
    });
    const daylist = getDaysArrayBetween(params.date_start!, params.date_end!);
    return daylist.map((day) => ({
      name: day,
      ...defaultValue,
      ...rawApiData?.find((item) => String(item.name) === day),
    }));
  }, [fetchedData, params]);

  /*********/
  /* print */
  /*********/
  const chartRef = useRef<HTMLDivElement>();
  const [printChart] = usePrint(chartRef.current);
  const printTheme = useAppSelector(({app}) => app.printTheme);

  /**********/
  /* export */
  /**********/
  const [exportInProgress, setExportInProgress] = useState(false);

  const {
    shifts,
    zones,
    machine_types: machineTypes,
    machine_inputs: machineInputs,
  } = useAppSelector(reduxSelectors.assets.getAssets);

  const submitExport = async (params: MachineDetailLineReportQuery) => {
    setExportInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/report/machine-details-export`;
      const resp = await API.get(endpoint, {
        params: {
          ...params,
          input: JSON.stringify(params.input),
          export_type: 'line',
        },
        responseType: 'blob',
      });

      const fileNameParts = [
        'machineDetailsLine',
        `${params.date_start}__${params.date_end}`,
        shifts.find((i) => i.id === params.shift_id)?.name?.replace(' ', '-'),
        zones.find((i) => i.id === params.zone_id)?.name?.replace(' ', '-'),
        machineTypes
          .find((i) => i.id === params.type_id)
          ?.name?.replace(' ', '-'),

        machineInputs
          .map((i) => {
            const v = params.input?.[i.id];
            if (v === 1) {
              return `${i.name.replace(' ', '-')}-is-on`;
            } else if (v === 0) {
              return `${i.name.replace(' ', '-')}-is-off`;
            }
          })
          .filter(isPresent)
          .join('_'),
      ].filter(isPresent);

      const fileName = `${fileNameParts.join('__')}.csv`;

      saveFile(resp.data, fileName);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setExportInProgress(false);
  };

  const drilldown = useDashboardDrilldown();
  const [menuState, setMenuState] = useState<MenuState>({
    visible: false,
    mouseX: 0,
    mouseY: 0,
  });

  // Click on active dot doesn't return proper mouse position so we need to track it manually
  const [mousePos, setMousePos] = useState<any>({});
  useEffect(() => {
    const handleMouseMove = (event: any) =>
      setMousePos({x: event.clientX, y: event.clientY});
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const openDrilldown = (externalId: number, event: any) => {
    setMenuState?.(
      update(menuState, {
        visible: {$set: true},
        mouseY: {$set: event.clientY || mousePos.y},
        mouseX: {$set: event.clientX || mousePos.x},
        params: {
          $set: {
            ...params,
            machines: [externalId],
            external_id: externalId,
            section_id: params.zone_id,
          },
        },
      })
    );
  };

  const handleDrilldown = (
    state: MenuState,
    type: 'history' | 'interactions' | 'summary'
  ) => {
    if (type === 'history') {
      drilldown({
        code: 'MachineHistoryReport',
        chartType: 'bar',
        filter: state.params as PanelFilterParams,
      });
    } else if (type === 'interactions') {
      drilldown({
        code: 'MachineInteractionReport',
        chartType: 'bar',
        filter: state.params as PanelFilterParams,
      });
    } else {
      drilldown({
        code: 'MachineSummaryReport',
        chartType: 'bar',
        filter: state.params as PanelFilterParams,
      });
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      gap={1}
      width="100%"
      overflow="hidden"
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box display="flex" flexDirection="column">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          gap={1}
        >
          <Box display="flex" overflow="auto" py={1} gap={1}>
            <MachineTypeSelect
              value={params.type_id}
              size="small"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setFilter(
                  update(filter, {
                    params: {
                      type_id: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />

            <Box minWidth={400}>
              <DateRangeSelect
                value={[
                  dayjs(params.date_start).toDate(),
                  dayjs(params.date_end).toDate(),
                ]}
                size="small"
                onChange={(v) => {
                  setFilter(
                    update(filter, {
                      params: {
                        date_start: {
                          $set: v?.[0]
                            ? dayjs(v?.[0]).format('YYYY-MM-DD')
                            : undefined,
                        },
                        date_end: {
                          $set: v?.[1]
                            ? dayjs(v?.[1]).format('YYYY-MM-DD')
                            : undefined,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>

            <ShiftSelect
              value={params?.shift_id}
              size="small"
              nullLabel="All Shifts"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setFilter(
                  update(filter, {
                    params: {
                      shift_id: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />

            <ZoneSelect
              value={params?.zone_id}
              size="small"
              nullLabel="All Sections"
              sx={{minWidth: 200}}
              onChange={(v) => {
                setFilter(
                  update(filter, {
                    params: {
                      zone_id: {
                        $set: v,
                      },
                    },
                  })
                );
              }}
            />

            {/* <MachineInputFilterSelect
              value={filter.params?.input}
              size="small"
              fullWidth
              onChange={(v) => {
                onChange?.(
                  update(filter, {
                    params: {
                      input: {
                        $set: v,
                      }
                    },
                  })
                )
              }}
            /> */}

            <Box width={250}>
              <MachineInputSelectV2
                value={params?.input}
                label="Inputs"
                size="small"
                onChange={(v) => {
                  setFilter(
                    update(filter, {
                      params: {
                        input: {
                          $set: v,
                        },
                      },
                    })
                  );
                }}
              />
            </Box>
          </Box>

          <Box display="flex" py={1}>
            <ButtonGroup size="small" sx={{height: 40}}>
              <Button onClick={() => params && fetchData(params)}>
                <RefreshIcon />
              </Button>

              <AutoRefreshSelect
                value={props.value?.refreshInterval}
                onChange={setRefreshInterval}
              />

              <Tooltip title="Export to Excel">
                <LoadingButton
                  size="small"
                  loading={exportInProgress}
                  variant="outlined"
                  onClick={() => submitExport(params)}
                >
                  <FileDownloadIcon />
                </LoadingButton>
              </Tooltip>

              <Tooltip title="Print">
                <Button onClick={() => printChart()}>
                  <PrintIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      {fetchedErrors.map((error, idx) => (
        <Alert
          key={idx}
          severity="error"
          onClose={() => params && fetchData(params)}
        >
          {error}
        </Alert>
      ))}

      <Box
        ref={chartRef}
        overflow="hidden"
        position="relative"
        display="flex"
        flexDirection="column"
        gap={3}
        alignItems="center"
        justifyContent="center"
        height="100%"
        width="100%"
      >
        <Box display="none" displayPrint="block">
          Machine Stoppages for Machine Types by Period - {params.date_start} to{' '}
          {params.date_end}
        </Box>

        <Box
          height={printTheme ? '500px' : '100%'}
          width={printTheme ? '700px' : '100%'}
        >
          <DrilldownMenu
            onItemClick={handleDrilldown}
            menuState={menuState}
            setMenuState={setMenuState}
          >
            {!isEmptyResults ? (
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={dataset}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <TooltipRecharts
                    shared={false}
                    labelStyle={{
                      color: theme.palette.text.secondary,
                    }}
                    contentStyle={{
                      backgroundColor: theme.palette.background.default,
                    }}
                    cursor={false}
                  />
                  <Legend onClick={(item) => toggleKey(item.dataKey)} />

                  {lines.map(({key, label}, index) => (
                    <Line
                      onClick={(_data, event) =>
                        openDrilldown(parseInt(key), event)
                      }
                      key={key}
                      dataKey={key}
                      name={label}
                      hide={hiddenKeys.includes(key)}
                      stroke={CHART_COLORS[index]}
                      isAnimationActive={!printTheme}
                      activeDot={{
                        onClick: (_data, event) =>
                          openDrilldown(parseInt(key), event),
                      }}
                    />
                  ))}
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                {fetchedInProgress ? 'Loading...' : 'No data to display'}
              </Box>
            )}
          </DrilldownMenu>
        </Box>
      </Box>
    </Box>
  );
};

export default MachineDetailsReportLine;
