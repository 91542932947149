export const CHART_COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#fe00ee',
  '#7bad7e',
  '#6551a9',
  '#3745bf',
  '#24AC99',
  '#98fb98',
  '#ee82ee',
  '#ffa07a',
  '#ff1493',
  '#f0e68c',
  '#a52a2a',
  '#ffa500',
  '#ff4500',
  '#00ff00',
];
