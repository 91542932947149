import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import Image from 'mui-image';
import {useSnackbar} from 'notistack';
import {useCallback, useMemo, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {SigninInputBody, SigninReponse} from '../../interfaces/Signin';
import reduxActions from '../../redux/actions';
import {LOGO} from '../../utils/logo';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  onSubmitted?: () => void;
}

export const Singin = ({onSubmitted}: Props) => {
  const reduxDispatch = useAppDispatch();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const toggleIsPasswordVisible = useCallback(() => {
    setIsPasswordVisible((visible) => !visible);
  }, []);

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async () => {
    setSubmittedInProgress(true);

    try {
      const endpoint = `${apiBaseUrl}/auth/login`;
      const resp = await API.post<SigninReponse>(endpoint, formik.values);

      reduxDispatch((state) =>
        reduxActions.auth.setAuthTokens(state, {
          accessToken: resp.data.token,
          refreshToken: resp.data.refresh_token,
        })
      );

      const message = `You have been logged in successfully`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      username: yup.string().nullable().required('Username is required'),
      password: yup.string().nullable().required('Password is required'),
      logout_sessions: yup.boolean(),
    };

    return yup.object().shape(fields);
  }, []);

  const getFormikValues = (): Partial<SigninInputBody> => ({
    username: null,
    password: null,
    logout_sessions: false,
  });

  const formik = useFormik<Partial<SigninInputBody>>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async () => {
      await submitData();
    },
  });

  return (
    <Box
      component="form"
      maxWidth={410}
      width="100%"
      onSubmit={formik.handleSubmit}
    >
      <Card>
        <CardContent>
          <Box textAlign="center">
            {/* <Box fontSize={24}>Log In</Box> */}

            <Box
              sx={{mt: 1, mb: 2, display: 'flex'}}
              alignItems="center"
              justifyContent="center"
            >
              <Image height="auto" width="150px" src={LOGO.white_new}></Image>
            </Box>
          </Box>

          <Box my={2}>
            <TextField
              value={formik.values.username ?? ''}
              fullWidth
              name="username"
              label="Username"
              error={!!formik.touched.username && !!formik.errors.username}
              helperText={formik.touched.username && formik.errors.username}
              onChange={formik.handleChange}
            />
          </Box>

          <Box my={2}>
            <TextField
              value={formik.values.password ?? ''}
              fullWidth
              name="password"
              label="Password"
              type={isPasswordVisible ? 'text' : 'password'}
              error={!!formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                endAdornment: (
                  <IconButton
                    size="small"
                    type="button"
                    onClick={toggleIsPasswordVisible}
                  >
                    {isPasswordVisible ? (
                      <VisibilityIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    ) : (
                      <VisibilityOffIcon
                        className="passwordEye"
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                ),
              }}
              onChange={formik.handleChange}
            />
          </Box>

          <Box my={2}>
            <FormControlLabel
              label="Logout of another session"
              name="logout_sessions"
              value={formik.values.logout_sessions}
              onChange={(e, value) =>
                formik.setFieldValue('logout_sessions', value)
              }
              control={
                <Checkbox checked={formik.values.logout_sessions ?? false} />
              }
            />
          </Box>

          <Box mt={2}>
            <LoadingButton
              fullWidth
              size="large"
              variant="contained"
              loading={submittedInProgress}
              sx={{
                textTransform: 'initial',
                display: 'flex',
                alignItems: 'center',
                img: {
                  marginTop: '-1px',
                },
              }}
              type="submit"
            >
              <Box sx={{marginTop: '2px'}} component="span">
                LOG into&nbsp;
              </Box>
              {!submittedInProgress && (
                <Image height="auto" width="90px" src={LOGO.black}></Image>
              )}
            </LoadingButton>

            <Box mt={1.5}>
              <Link
                component={RouterLink}
                underline="hover"
                to="/auth/password-reset"
              >
                Forgot password?
              </Link>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
