import {ListItem, Modal, Typography} from '@mui/material';
import {Box} from '@mui/system';
import {ReactNode, useMemo, useState} from 'react';

import {Layer} from '../../../interfaces/Layer';
import {LayerItemUpsert} from '../LayerItemUpsert';

interface LayerItemUpsertButtonProps {
  pk?: Layer['id'];
  item?: Layer;
  prefetch?: boolean;
  component?: React.FC;
  componentProps?: any;
  onSubmitted?: () => void;
  children?: ReactNode;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
};

export const LayerItemUpsertButton: React.FC<LayerItemUpsertButtonProps> = ({
  pk,
  item,
  component = ListItem,
  prefetch,
  componentProps = {},
  children,
  onSubmitted,
}) => {
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);
  const isCreated = useMemo(() => !!pk, [pk]);
  const title = useMemo(
    () => `${isCreated ? 'Edit' : 'Create'} Layer`,
    [isCreated]
  );

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Modal open={isOpened} onClose={closeModal}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{mb: 2}}>
            {title}
          </Typography>

          <LayerItemUpsert
            pk={pk}
            item={item}
            prefetch={prefetch}
            onCancel={closeModal}
            onSubmitted={() => {
              onSubmitted?.();
              closeModal();
            }}
          />
        </Box>
      </Modal>
    </>
  );
};
