/* eslint-disable complexity */
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ShareIcon from '@mui/icons-material/Share';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';
import update from 'immutability-helper';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  Dashboard,
  DashboardDrilldownAction,
  DashboardEntity,
  DashboardFilter,
  DashboardUpsertInputBody,
} from '../../interfaces/Dashboard';
import reduxActions from '../../redux/actions';
import {Role} from '../../utils/acl';
import {checkPrivilegeAction} from '../../utils/dashboard';
import {PanelCode} from '../../utils/panels';
import AccessControl from '../common/AccessControl';
import {CloseSnackbarButton} from '../common/CloseSnackbarButton';
import ModalFixed from '../common/ModalFixed';
import SnackbarMessages from '../common/SnackbarMessages';
import {DashboardHistoryReportType} from '../dashboard-panels/DashboardPanelItem';
import {DashboardPanelSelectButton} from '../dashboard-panels/DashboardPanelSelectButton';
import {DashboardProvider} from './DashboardContext';
import DashboardItemShareModal from './DashboardItemShareModal';
import DashboardEntityList from './entities/DashboardEntityList';

interface Props {
  pk: number;
  primary: boolean;
  item?: Dashboard;
  prefetch?: boolean;
  onSubmitted?: (item: Dashboard) => void;
}

const getDashboardInput = (item?: Dashboard): DashboardUpsertInputBody => {
  return {
    name: item?.name ?? null,
    data: {
      entities:
        item?.data.entities?.map((i) => ({
          ...i,
          id: i.id ?? Math.random().toString(16).substring(2, 14),
        })) ?? [],
    },
  };
};

const getDashboardFilter = (
  input?: DashboardUpsertInputBody
): DashboardFilter => {
  const filter: DashboardFilter = {};

  input?.data?.entities?.forEach((el) => {
    filter[el.id] = {
      chartType: 'bar',
      params: {},
    };
  });

  return filter;
};

const DashboardItem = ({
  pk,
  primary,
  item,
  prefetch = true,
  onSubmitted,
}: Props) => {
  const {t} = useTranslation();
  const me = useAppSelector(({app}) => app.me);
  const reduxDispatch = useAppDispatch();
  const [action, setAction] = useState<boolean>(false);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<Dashboard | undefined>(
    _.cloneDeep(item)
  );

  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<Dashboard>(
        `${apiBaseUrl}/dashboard/${primary ? 'primary' : pk}`
      );
      if (!_.isEqual(item, resp.data)) {
        setFilter(getDashboardFilter(resp.data));
        setFetchedData(resp.data);
        setInput(getDashboardInput(resp.data));
        updateRoles(resp.data);
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);

  useEffect(() => {
    reduxActions.app.setApp(reduxDispatch, {
      inSubmittingInSavingDashboard: submittedInProgress,
    });
  }, [submittedInProgress]);

  useEffect(() => {
    reduxActions.app.setApp(reduxDispatch, {
      activedDashboard: fetchedData,
    });
  }, [fetchedData]);

  useEffect(() => {
    return () => {
      reduxActions.app.setApp(reduxDispatch, {
        activedDashboard: null,
        inSubmittingInSavingDashboard: false,
      });
    };
  }, []);

  const updateRoles = (resp: Dashboard) => {
    setSelectedRoles((selectedRoles) => [
      ...selectedRoles,
      ...(resp.visible_to_client_manager ? [Role.clientManager] : []),
      ...(resp.visible_to_client_viewer ? [Role.clientViewer] : []),
    ]);
  };

  const submitData = async (data: DashboardUpsertInputBody) => {
    const checkedShareData = {
      ...data,
      visible_to_client_viewer: _.includes(selectedRoles, Role.clientViewer),
      visible_to_client_manager: _.includes(selectedRoles, Role.clientManager),
      visible_to_strata_manager: _.includes(selectedRoles, Role.strataManager),
      visible_to_super_user: _.includes(selectedRoles, Role.superUser),
    };
    setSubmittedInProgress(true);
    try {
      const endpoint = pk
        ? `${apiBaseUrl}/dashboard/${pk}`
        : `${apiBaseUrl}/dashboard`;
      const resp = pk
        ? await API.patch<Dashboard>(endpoint, checkedShareData)
        : await API.post<Dashboard>(endpoint, checkedShareData);
      const message = `Dashboard successfully ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      if (!_.isEqual(item, resp.data)) {
        setFetchedData(resp.data);
        setInput(getDashboardInput(resp.data));
      }
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  const [updatedDashboardInProgress, setUpdatedDashboardInProgress] =
    useState(false);
  const updateDashboard = async (data: DashboardUpsertInputBody) => {
    // Save dashboard data in background to update panel filters
    setUpdatedDashboardInProgress(true);
    try {
      if (pk) {
        await API.patch<Dashboard>(`${apiBaseUrl}/dashboard/${pk}`, data);
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setUpdatedDashboardInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const [input, setInput] = useState(() => getDashboardInput(fetchedData));
  const [filter, setFilter] = useState(() => getDashboardFilter(input));

  const addDataEntity = (code: PanelCode) => {
    const id = Math.random().toString(16).substring(2, 14);

    setFilter({
      ...filter,
      [id]: {
        chartType: 'bar',
        params: {},
      },
    });

    setInput({
      ...input,
      data: {
        ...input.data,
        entities: [
          ...(input.data?.entities ?? []),
          {
            id,
            type: 'panel',
            name: t(`panels.${code}`),
            panel: {
              code,
              data: {},
            },
            grid: {
              x: 0,
              y: (_.maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) + 1,
              w: 12,
              h: 4,
            },
          },
        ],
      },
    });
    setTimeout(() => {
      const section = document.querySelector(`[data-id="${id}"]`);
      section?.scrollIntoView({behavior: 'smooth', block: 'start'});
    }, 100);

    return id;
  };

  const getDataEntityDefaults = (
    panelCode: PanelCode,
    viewType: DashboardHistoryReportType | 'assets' | 'employees',
    id: number | string
  ): DashboardEntity => ({
    id: Math.random().toString(16).substring(2, 14),
    type: 'panel',
    name: t(`panels.${panelCode}`),
    panel: {
      code: panelCode,
      data: {
        viewType,
        [viewType]: {
          activeId: id,
          openedItems: [{id}],
        },
      },
    },
    grid: {
      x: 0,
      y: (_.maxBy(input.data?.entities, 'grid.y')?.grid.y ?? 0) + 1,
      w: 12,
      h: 4,
    },
  });

  const handleDrillDown = (action: DashboardDrilldownAction) => {
    const {code} = action;
    const entity = input.data?.entities?.filter(
      (el) => el.panel?.code === code
    )[0];
    const exists = !!entity;

    if (exists) {
      setFilter({
        ...filter,
        [entity.id]: {
          ...filter[entity.id],
          chartType: action.chartType,
          params: {
            ...filter[entity.id].params,
            ...action.filter,
          },
        },
      });

      setTimeout(() => {
        const section = document.querySelector(`[data-id="${entity.id}"]`);
        section?.scrollIntoView({behavior: 'smooth', block: 'start'});
      }, 100);
    } else {
      // add
      const id = addDataEntity(code);

      setFilter({
        ...filter,
        [id]: {
          chartType: action.chartType,
          params: {
            ...action.filter,
          },
        },
      });
    }
  };

  const handleOpenEntityHistory = (
    id: number | string | number[],
    type: DashboardHistoryReportType
  ) => {
    setTimeout(() => {
      let panelCode: PanelCode = 'CommtracNodeTrackingReports';

      if (['cn', 'commtracNodeByCn', 'wifi', 'wifiLongTerm'].includes(type)) {
        panelCode = 'NodeTrackingReports';
      } else if (['networkDiagnostics'].includes(type)) {
        panelCode = 'NetworkDiagnostics';
      } else if (['alarm'].includes(type)) {
        panelCode = 'AlarmHistoryReports';
      } else if (['alarm_log'].includes(type)) {
        panelCode = 'AlarmLogReports';
      } else if (['hazard_ai_detection_log'].includes(type)) {
        panelCode = 'HazardAIDectionLog';
      } else if (['hazard_ai_heatmap'].includes(type)) {
        panelCode = 'HazardHeatMapReports';
      } else if (
        ['amsShortTerm', 'amsLongTerm', 'amsLocation'].includes(type)
      ) {
        panelCode = 'GasMonitoringReport';
      } else if (
        ['amsEmoduleSensorHistory', 'amsEmoduleInstallationHistory'].includes(
          type
        )
      ) {
        panelCode = _.upperFirst(type) as PanelCode;
      } else if (['amsEmoduleCalibration'].includes(type)) {
        panelCode = 'AMSEModuleCalibration';
      }

      const reportedElement = document.querySelector(
        `[data-panel-code="${panelCode}"]`
      );
      if (reportedElement) {
        reportedElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }
    }, 100);
    if (['asset', 'employee'].includes(type)) {
      const viewType = type === 'asset' ? 'assets' : 'employees';
      const panelCode: PanelCode = 'CommtracNodeTrackingReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        const panel = getDataEntityDefaults(panelCode, viewType, id as number);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (
      ['cn', 'commtracNodeByCn', 'wifi', 'wifiLongTerm'].includes(type)
    ) {
      const viewType = type;
      const panelCode: PanelCode = 'NodeTrackingReports';
      const panelIndex =
        input?.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        const panel = getDataEntityDefaults(panelCode, type, id as number);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['networkDiagnostics'].includes(type)) {
      const viewType = 'general';
      const panelCode: PanelCode = 'NetworkDiagnostics';
      const panelIndex =
        input?.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        addDataEntity(panelCode);
      } else if (input.data?.entities?.[panelIndex].panel?.data?.[viewType]) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          ...(input.data?.entities?.[panelIndex].panel?.data?.[
                            viewType
                          ] ?? {}),
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['alarm'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'AlarmHistoryReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;

      if (panelIndex === -1) {
        const panel = getDataEntityDefaults(panelCode, type, id as number);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['alarm_log'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'AlarmLogReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      if (panelIndex === -1) {
        const panel = getDataEntityDefaults(panelCode, type, id as number);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['hazard_ai_detection_log'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'HazardAIDectionLog';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      const panel = getDataEntityDefaults(panelCode, type, id as number);
      if (panelIndex === -1) {
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === id)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: id,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['hazard_ai_heatmap'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'HazardHeatMapReports';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;
      const uniqId = (_.isArray(id) ? [...id] : [id])?.join('-');
      if (panelIndex === -1) {
        const panel = getDataEntityDefaults(panelCode, type, uniqId as string);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === uniqId)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: uniqId,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id: uniqId},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['amsShortTerm', 'amsLongTerm', 'amsLocation'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'GasMonitoringReport';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;

      const uniqId = (_.isArray(id) ? [...id] : [id])?.join('-');
      if (panelIndex === -1) {
        const panel = getDataEntityDefaults(panelCode, type, uniqId as string);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === uniqId)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: uniqId,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id: uniqId},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (['amsEmoduleCalibration'].includes(type)) {
      const viewType = type;
      const panelCode: PanelCode = 'AMSEModuleCalibration';
      const panelIndex =
        input.data?.entities?.findIndex((i) => i.panel?.code === panelCode) ??
        -1;

      const uniqId = (_.isArray(id) ? [...id] : [id])?.join('-');
      if (panelIndex === -1) {
        const panel = getDataEntityDefaults(panelCode, type, uniqId as string);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...(input.data?.entities ?? []), panel],
              },
            },
          })
        );
      } else if (
        input.data?.entities?.[panelIndex].panel?.data?.[
          viewType
        ]?.openedItems?.find((i: any) => i?.id === uniqId)
      ) {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                    },
                  },
                },
              },
            },
          })
        );
      } else {
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  panel: {
                    data: {
                      viewType: {
                        $set: viewType,
                      },
                      [viewType]: {
                        $set: {
                          activeId: uniqId,
                          openedItems: [
                            ...(input.data?.entities?.[panelIndex].panel
                              ?.data?.[viewType]?.openedItems ?? []),
                            {id: uniqId},
                          ],
                        },
                      },
                    },
                  },
                },
              },
            },
          })
        );
      }
    } else if (
      ['amsEmoduleSensorHistory', 'amsEmoduleInstallationHistory'].includes(
        type
      )
    ) {
      const panels = input.data?.entities || [];
      const panelCode = _.upperFirst(type) as PanelCode;
      const panelIndex = panels?.findIndex((i) => i.panel?.code === panelCode);

      if (panelIndex === -1) {
        // Add panel if it doesn't exist
        const panel = getDataEntityDefaults(panelCode, type, id as number);
        setInput(
          update(input, {
            data: {
              entities: {
                $set: [...panels, panel],
              },
            },
          })
        );
      } else {
        // Check if tab/item already added and add new tab
        // if it doesn't exist or activate existing tab
        const openedItems =
          panels[panelIndex].panel?.data?.[type]?.openedItems || [];
        const openedItem = openedItems.find((i: any) => i?.id === id);
        const panel = update(panels[panelIndex], {
          panel: {
            data: {
              viewType: {
                $set: type,
              },
              [type]: {
                $set: {
                  activeId: id,
                  openedItems: !openedItem
                    ? [...openedItems, {id}]
                    : openedItems,
                },
              },
            },
          },
        });
        setInput(
          update(input, {
            data: {
              entities: {
                [panelIndex]: {
                  $set: panel,
                },
              },
            },
          })
        );
      }
      // } else if (['amsEmoduleInstallationHistory'].includes(type)) {
      //   const panels = input.data?.entities || [];
      //   const panelCode = _.upperFirst(type) as PanelCode;
      //   const panelIndex = panels?.findIndex((i) => i.panel?.code === panelCode);

      //   if (panelIndex === -1) {
      //     const panel = getDataEntityDefaults(panelCode, type, id as number);
      //     setInput(
      //       update(input, {
      //         data: {
      //           entities: {
      //             $set: [...panels, panel],
      //           },
      //         },
      //       })
      //     );
      //   } else {
      //     const openedItems =
      //       panels[panelIndex].panel?.data?.[type]?.openedItems || [];
      //     const openedItem = openedItems.find((i: any) => i?.id === id);
      //     const panel = update(panels[panelIndex], {
      //       panel: {
      //         data: {
      //           viewType: {
      //             $set: type,
      //           },
      //           [type]: {
      //             $set: {
      //               activeId: id,
      //               openedItems: !openedItem
      //                 ? [...openedItems, {id}]
      //                 : openedItems,
      //             },
      //           },
      //         },
      //       },
      //     });
      //     setInput(
      //       update(input, {
      //         data: {
      //           entities: {
      //             [panelIndex]: {
      //               $set: panel,
      //             },
      //           },
      //         },
      //       })
      //     );
      //   }
    }
  };

  const handleEntityUpdate = (entity: DashboardEntity) => {
    // Megre new panel into the old one to update panel filters
    if (checkPrivilegeAction(me, fetchedData, 'EDIT')) {
      const entities =
        (input.data?.entities || []).map((e) =>
          e.id === entity.id ? {...e, ...entity} : e
        ) || [];
      updateDashboard({...input, data: {entities}});
    }
  };

  return (
    <DashboardProvider
      value={input}
      onChange={setInput}
      filter={{filter, setFilter}}
      drilldown={handleDrillDown}
    >
      <Box
        position="relative"
        height="100%"
        sx={{
          display: input.data?.entities?.length === 1 ? 'flex' : 'block',
          flexDirection: 'column',
        }}
        id="entity-container"
      >
        <Backdrop
          open={fetchedInProgress}
          sx={{position: 'absolute', zIndex: 1001}}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, index) => (
          <Alert key={index} sx={{mb: 2}} severity="error">
            {error}
          </Alert>
        ))}

        {fetchedData && (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
                position: 'absolute',
                width: '0px',
                overflow: 'hidden',
              }}
            >
              <Typography component="h1" variant="h5">
                {fetchedData.name}
              </Typography>

              <Box>
                <AccessControl
                  accessChecker={() =>
                    checkPrivilegeAction(me, fetchedData, 'EDIT')
                  }
                >
                  <DashboardPanelSelectButton
                    component={Button}
                    componentProps={{
                      sx: {minWidth: 'auto'},
                      id: 'btn-add-new-item',
                    }}
                    onUpdate={addDataEntity}
                  >
                    <AddIcon />
                  </DashboardPanelSelectButton>
                </AccessControl>

                <AccessControl
                  accessChecker={() =>
                    checkPrivilegeAction(me, fetchedData, 'EDIT')
                  }
                >
                  <Button
                    sx={{minWidth: 'auto'}}
                    id="btn-share-to-role"
                    onClick={() => {
                      setAction(!action);
                    }}
                  >
                    <ShareIcon />
                  </Button>
                </AccessControl>

                <AccessControl
                  permissions={['patch::/dashboard/:id(\\d+)']}
                  accessChecker={() =>
                    checkPrivilegeAction(me, fetchedData, 'EDIT')
                  }
                >
                  <LoadingButton
                    sx={{minWidth: 'auto'}}
                    loading={submittedInProgress || updatedDashboardInProgress}
                    id="btn-save-dashboard"
                    onClick={() => submitData(input)}
                  >
                    <SaveIcon />
                  </LoadingButton>
                </AccessControl>
              </Box>
            </Box>

            <DashboardEntityList
              disabled={!checkPrivilegeAction(me, fetchedData, 'EDIT')}
              entities={input.data?.entities ?? []}
              onUpdate={(v) =>
                setInput(
                  update(input, {
                    data: {
                      entities: {
                        $set: v,
                      },
                    },
                  })
                )
              }
              onEntityUpdate={handleEntityUpdate}
              onOpenHistory={handleOpenEntityHistory}
            />
          </>
        )}
      </Box>
      <ModalFixed open={action} onClose={() => setAction(false)}>
        <DashboardItemShareModal
          item={fetchedData}
          isEditing={true}
          onSelectRole={(roles: number[]) => setSelectedRoles(roles)}
          onClose={() => setAction(false)}
        />
      </ModalFixed>
    </DashboardProvider>
  );
};

export default DashboardItem;
