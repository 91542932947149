import adminReducer from './admin/reducer';
import appReducers from './app/reducers';
import assetsReducers from './assets/reducers';
import authReducers from './auth/reducers';

const reduxRedusers = {
  app: appReducers,
  auth: authReducers,
  assets: assetsReducers,
  admin: adminReducer,
};

export default reduxRedusers;
