import {Button, Menu, MenuItem} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  setViewType: Function;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
}

const AlarmItemHistoryButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  setViewType,
}: // onPurged,
Props<T>) => {
  const Component = component ?? Button;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  return (
    <>
      <Component
        {...componentProps}
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        {children}
      </Component>

      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onBackdropClick={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setViewType('alarm');
            setAnchorEl(null);
          }}
        >
          View Alarm History
        </MenuItem>
        <MenuItem
          onClick={() => {
            setViewType('alarm_log');
            setAnchorEl(null);
          }}
        >
          View Alarm Log
        </MenuItem>
      </Menu>
    </>
  );
};

export default AlarmItemHistoryButton;
