import {MenuItem, TextField, TextFieldProps} from '@mui/material';

type Props = Pick<
  TextFieldProps,
  | 'size'
  | 'name'
  | 'disabled'
  | 'fullWidth'
  | 'label'
  | 'error'
  | 'helperText'
  | 'InputProps'
  | 'sx'
> & {
  value?: boolean | 'all';
  onChange?: (value?: boolean) => void;
};

export const ClearedTypeSelect = (props: Props) => {
  return (
    <TextField
      {...props}
      value={props.value ?? 'all'}
      label="Cleared"
      name="cleared"
      select
      onChange={(e) => {
        const v =
          e.target.value === 'all'
            ? undefined
            : e.target.value === 'true';
        props.onChange?.(v);
      }}
    >
      <MenuItem value="all">All</MenuItem>
      <MenuItem value="true">Cleared</MenuItem>
      <MenuItem value="false">Not Cleared</MenuItem>
    </TextField>
  )
};
