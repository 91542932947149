import * as yup from 'yup';

export const amsNodeBaseInputSchema = yup.object().shape({
  name: yup.string().nullable().required('Field is required').min(1).max(50),
  status: yup.string().nullable().required('Field is required'),
  zone_id: yup.number().nullable().required('Field is required'),
  commtrac_external_id: yup.number().nullable().required('Field is required'),
  re_commtrac_external_id: yup
    .number()
    .nullable()
    .oneOf([yup.ref('commtrac_external_id'), null], 'NetworkID must match')
    .required('Required matched NetwordID'),
  pos_lat: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-90)
    .max(90),
  pos_lon: yup
    .number()
    .nullable()
    .required('Field is required')
    .min(-180)
    .max(180),
});

export const amsNodeNetworkdIDInputSchema = yup.object().shape({
  commtrac_external_id: yup.number().nullable().required('Field is required'),
});

export const amsEModuleInputSchema = yup.object().shape({
  serial_number: yup.number().nullable().required('Field is required'),
  date_calibration: yup.string().nullable().required('Field is required'),
  calibration_value: yup.string().nullable().required('Field is required'),
  type: yup.string().nullable().required('Field is required'),
  status: yup.string().nullable().required('Field is required'),
});

export const amsEModuleEditSchema = yup.object().shape({
  date_calibration: yup.string().nullable().required('Field is required'),
  calibration_value: yup.string().nullable().required('Field is required'),
  date_installation: yup.string().nullable().required('Field is required'),
  status: yup.string().nullable().required('Field is required'),
});
